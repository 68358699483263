import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Hidden } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import dayjs from "dayjs";
import { apiGet } from "../../utils/api_service";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { imageUrl } from "../../config/Url";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const SalesPersonCheckInImage = ({ handleCloses, open, showLoador, showNotification, columns, rows, id, itemObject }) => {

    const [dataList, setDataList] = React.useState([])
    const [dataToSet, setDataToSet] = React.useState([])
    const [popUpOpenFlag, setPopUpOpenFlag] = React.useState(false)

    const handleClose = () => {
        handleCloses();
    };

    React.useEffect(() => {
        apiMethod();
    }, [open, id, itemObject.date]);


    const apiMethod = async () => {

        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiGet({
                url: endpoint.salesTeamCheckInImage + "/by-sales-team-id?salesTeamId=" + id + "&date=" + itemObject.date,
            }).then(({ data, success }) => {
                showLoador({ loador: false });

                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    console.log(data.data);
                    setDataList(data.data);

                    // const dataItem = data.data.length !== 0 ? data.data[0] : {};

                    data.data.map((dataObj) => {
                        const DataToSetList = [
                            {
                                xs: 12,
                                sm: 3.5,
                                md: 3.5,
                                lg: 3.5,
                                label: "Date",
                                value:
                                    dataObj.date !== null
                                        ? dataObj.date
                                        : "",
                            },
                            {
                                xs: 12,
                                sm: 3.5,
                                md: 3.5,
                                lg: 3.5,
                                label: "Name",
                                value:
                                    dataObj.name !== null
                                        ? dataObj.name
                                        : "",
                            },
                            {
                                xs: 12,
                                sm: 5,
                                md: 5,
                                lg: 5,
                                label: "Stock Holder Type",
                                value:
                                    dataObj.stockStakeHolderType !== null
                                        ? dataObj.stockStakeHolderType.name
                                        : "",
                            },

                        ]
                        setDataToSet(DataToSetList);
                    });

                }
            });

            popUpFlagMethod();

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    const popUpFlagMethod = () => {
        setPopUpOpenFlag(true);
    }


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={popUpOpenFlag}
                fullWidth
                maxWidth="lg"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Sales Team Details
                </BootstrapDialogTitle>
                <DialogContent style={{ overflowX: "Hidden" }} dividers>
                    <DynamicDetails DataToSet={dataToSet} />
                    <br />

                    {dataList.map((dataObj, index) => (

                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                            <img
                                src={imageUrl + dataObj.image}
                                style={{ width: '250px', height: 'auto' }}
                            />
                        </div>
                    ))}
                </DialogContent>

            </BootstrapDialog>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    showLoador,
    showNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesPersonCheckInImage);
