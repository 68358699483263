import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { ConvertAmount } from "../components/Comman/ConvertValueWithDenominator";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  targetNew: [],
  notInsertedTargetNew: [],
  headerListForTarget: [],
  targetByAreaAndMonth: [],
  attendanceTargetAchievmentForRegion: [],
  attendanceTargetAchievmentForState: [],
  attendanceTargetAchievmentForZone: [],
  attendanceTargetAchievmentForDistrict: [],
  attendanceTargetAchievmentForTaluka: [],
  attendanceTargetAchievmentForArea: [],
  attendanceTargetAchievmentForRegionTotal: 0,
  attendanceTargetAchievmentForStateTotal: 0,
  attendanceTargetAchievmentForZoneTotal: 0,
  attendanceTargetAchievmentForDistrictTotal: 0,
  attendanceTargetAchievmentForTalukaTotal: 0,
  attendanceTargetAchievmentForAreaTotal: 0,
  packagingTypeAmountsByFlag: {},
};
let URL = endpoints.target;
const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    targetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        targetNew: row,
      };
    },
    notInsertedTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        notInsertedTargetNew: row,
      };
    },

    headerListForTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        headerListForTarget: row,
      };
    },

    attendanceTargetAchievmentForRegionSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      console.log(total);
      return {
        ...state,
        attendanceTargetAchievmentForRegion: row,
        attendanceTargetAchievmentForRegionTotal: total,
      };
    },

    attendanceTargetAchievmentForStateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        attendanceTargetAchievmentForState: row,
        attendanceTargetAchievmentForStateTotal: total,
      };
    },

    attendanceTargetAchievmentForZoneSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        attendanceTargetAchievmentForZone: row,
        attendanceTargetAchievmentForZoneTotal: total,
      };
    },

    attendanceTargetAchievmentForDistrictSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        attendanceTargetAchievmentForDistrict: row,
        attendanceTargetAchievmentForDistrictTotal: total,
      };
    },

    attendanceTargetAchievmentForTalukaSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        attendanceTargetAchievmentForTaluka: row,
        attendanceTargetAchievmentForTalukaTotal: total,
      };
    },

    attendanceTargetAchievmentForAreaSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        attendanceTargetAchievmentForArea: row,
        attendanceTargetAchievmentForAreaTotal: total,
      };
    },

    targetByAreaAndMonthSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        targetByAreaAndMonth: row,
      };
    },

    packagingTypeAmountsByFlagSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        packagingTypeAmountsByFlag: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  targetSuccess,
  notInsertedTargetSuccess,
  headerListForTargetSuccess,
  targetByAreaAndMonthSuccess,
  attendanceTargetAchievmentForRegionSuccess,
  attendanceTargetAchievmentForStateSuccess,
  attendanceTargetAchievmentForZoneSuccess,
  attendanceTargetAchievmentForDistrictSuccess,
  attendanceTargetAchievmentForTalukaSuccess,
  attendanceTargetAchievmentForAreaSuccess,
  packagingTypeAmountsByFlagSuccess,
  resetState,
} = targetSlice.actions;

export default targetSlice.reducer;

export const getTargetByStakeHolderTypeIdAndYearId =
  ({ stockStakeHolderTypeId, yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-type-and-year-and-freez?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",

              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTargetByStakeHolderTypeIdAndYearIdWithFilter =
  ({ stockStakeHolderTypeId, yearId, isFreez }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-type-and-year-and-is-freez?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId +
          "&isFreez=" +
          isFreez,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",
              regionName:
                targetObject.regionName !== null ? targetObject.regionName : "",
              stateName:
                targetObject.stateNames !== null ? targetObject.stateNames : "",
              zoneName:
                targetObject.zoneNames !== null ? targetObject.zoneNames : "",
              areaName:
                targetObject.areaNames !== null ? targetObject.areaNames : "",
              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTargetByStakeHolderTypeIdAndYearIdWithFilterPost =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL + "/by-stock-stake-holder-type-and-year-and-is-freez-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",
              regionName:
                targetObject.regionName !== null ? targetObject.regionName : "",
              stateName:
                targetObject.stateNames !== null ? targetObject.stateNames : "",
              zoneName:
                targetObject.zoneNames !== null ? targetObject.zoneNames : "",
              areaName:
                targetObject.areaNames !== null ? targetObject.areaNames : "",
              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearId =
  ({ stockStakeHolderTypeId, yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/with-not-inserted-by-stock-stake-holder-type-and-year?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.id
                  ? notInsertedTargetObject.salesTeam.id
                  : "",

              name:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.name
                  ? notInsertedTargetObject.salesTeam.name
                  : "",

              whatsAppNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.whatsAppNo
                  ? notInsertedTargetObject.salesTeam.whatsAppNo
                  : "",
              contactNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.contactNo
                  ? notInsertedTargetObject.salesTeam.contactNo
                  : "",
              address:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.address
                  ? notInsertedTargetObject.salesTeam.address
                  : "",

              employeeCode:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.employeeCode
                  ? notInsertedTargetObject.salesTeam.employeeCode
                  : "",

              emailId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.emailId
                  ? notInsertedTargetObject.salesTeam.emailId
                  : "",

              region:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.region
                  ? notInsertedTargetObject.salesTeam.region.name
                  : "",

              zoneIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.zoneIds
                  ? notInsertedTargetObject.salesTeam.zoneIds
                  : "",

              areaIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.areaIds
                  ? notInsertedTargetObject.salesTeam.areaIds
                  : "",

              accessRoleId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.accessRoleId
                  ? notInsertedTargetObject.salesTeam.accessRoleId
                  : "",

              isActive:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.isActive
                  ? notInsertedTargetObject.salesTeam.isActive
                  : "",

              insertDateTime:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.insertDateTime
                  ? notInsertedTargetObject.salesTeam.insertDateTime
                  : "",

              yearName:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.name
                  ? notInsertedTargetObject.yearMaster.name
                  : "",
              year:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.id
                  ? notInsertedTargetObject.yearMaster.id
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,

              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,

              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,

              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",

              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,

              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));

          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearIdPost =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL +
          "/with-not-inserted-by-stock-stake-holder-type-and-year-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.id
                  ? notInsertedTargetObject.salesTeam.id
                  : "",

              name:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.name
                  ? notInsertedTargetObject.salesTeam.name
                  : "",

              whatsAppNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.whatsAppNo
                  ? notInsertedTargetObject.salesTeam.whatsAppNo
                  : "",
              contactNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.contactNo
                  ? notInsertedTargetObject.salesTeam.contactNo
                  : "",
              address:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.address
                  ? notInsertedTargetObject.salesTeam.address
                  : "",

              employeeCode:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.employeeCode
                  ? notInsertedTargetObject.salesTeam.employeeCode
                  : "",

              emailId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.emailId
                  ? notInsertedTargetObject.salesTeam.emailId
                  : "",

              region:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.region
                  ? notInsertedTargetObject.salesTeam.region.name
                  : "",

              zoneIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.zoneIds
                  ? notInsertedTargetObject.salesTeam.zoneIds
                  : "",

              areaIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.areaIds
                  ? notInsertedTargetObject.salesTeam.areaIds
                  : "",

              accessRoleId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.accessRoleId
                  ? notInsertedTargetObject.salesTeam.accessRoleId
                  : "",

              isActive:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.isActive
                  ? notInsertedTargetObject.salesTeam.isActive
                  : "",

              insertDateTime:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.insertDateTime
                  ? notInsertedTargetObject.salesTeam.insertDateTime
                  : "",

              yearName:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.name
                  ? notInsertedTargetObject.yearMaster.name
                  : "",
              year:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.id
                  ? notInsertedTargetObject.yearMaster.id
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,

              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,

              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,

              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",

              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,

              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));

          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL +
          "/get-with-not-inserted-by-stock-stake-holder-type-and-year-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeamId !== null
                  ? notInsertedTargetObject.salesTeamId
                  : "",
              name:
                notInsertedTargetObject.salesTeamName !== null
                  ? notInsertedTargetObject.salesTeamName
                  : "",
              region:
                notInsertedTargetObject.regionName !== null
                  ? notInsertedTargetObject.regionName
                  : "",
              year:
                notInsertedTargetObject.yearId !== null
                  ? notInsertedTargetObject.yearId
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,
              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,
              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,
              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",
              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,
              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setNotInsertedTarget =
  ({ row }) =>
  async (dispatch) => {
    dispatch(notInsertedTargetSuccess({ row }));
  };

export const getTargetByAreaIdsAndMonth =
  ({ monthYear, areaIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/sales-team-by-areas-with-monthly-target-and-achieved-amt?monthYear=" +
          monthYear +
          "&areaIds=" +
          areaIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              userName:
                targetObject.userName !== null ? targetObject.userName : "",
              name: targetObject.name !== null ? targetObject.name : "",
              stockStakeHolderType:
                targetObject.stockStakeHolderType !== null
                  ? targetObject.stockStakeHolderType
                  : "",
              stockStakeHolderTypeName:
                targetObject.stockStakeHolderType !== null
                  ? targetObject.stockStakeHolderType.name
                  : "",
              stockStakeHolderTypeId:
                targetObject.stockStakeHolderType !== null
                  ? targetObject.stockStakeHolderType.id
                  : "",
              month: targetObject.month !== null ? targetObject.month : "",
              targetAmt:
                targetObject.targetAmt !== null
                  ? targetObject.targetAmt.toFixed(2)
                  : "",
              achievedAmt:
                targetObject.achievedAmt !== null
                  ? targetObject.achievedAmt.toFixed(2)
                  : "",
              whatsAppNo:
                targetObject.whatsAppNo !== null ? targetObject.whatsAppNo : "",
              contactNo:
                targetObject.contactNo !== null ? targetObject.contactNo : "",
              address:
                targetObject.address !== null ? targetObject.address : "",
              employeeCode:
                targetObject.employeeCode !== null
                  ? targetObject.employeeCode
                  : "",
              emailId:
                targetObject.emailId !== null ? targetObject.emailId : "",
              reportingManagerTypeId:
                targetObject.reportingManagerTypeId !== null
                  ? targetObject.reportingManagerTypeId
                  : "",
              reportingManagerId:
                targetObject.reportingManagerId !== null
                  ? targetObject.reportingManagerId
                  : "",
              regionId:
                targetObject.regionId !== null ? targetObject.regionId : "",
              zoneIds:
                targetObject.zoneIds !== null ? targetObject.zoneIds : "",
              areaIds:
                targetObject.areaIds !== null ? targetObject.areaIds : "",
              accessRoleId:
                targetObject.accessRoleId !== null
                  ? targetObject.accessRoleId
                  : "",
              type: targetObject.type !== null ? targetObject.type : "",
              isActive:
                targetObject.isActive !== null ? targetObject.isActive : "",
              deviceId:
                targetObject.deviceId !== null ? targetObject.deviceId : "",
              insertDateTime:
                targetObject.insertDateTime !== null
                  ? targetObject.insertDateTime
                  : "",
            };
            return targetData;
          });
          dispatch(targetByAreaAndMonthSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForRegion =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/attendence-target-achievement-for-regions?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForRegionSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForState =
  ({ month, year, regionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/states-with-attendence-target-achievement-by-region?month=" +
          month +
          "&year=" +
          year +
          "&regionId=" +
          regionId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              shortName:
                targetObject.shortName === null ? "" : targetObject.shortName,
              code: targetObject.code === null ? "" : targetObject.code,
              regionId:
                targetObject.regionId === null ? "" : targetObject.regionId,
              sortOrderNo:
                targetObject.sortOrderNo === null
                  ? ""
                  : targetObject.sortOrderNo,
              isActive:
                targetObject.isActive === null ? "" : targetObject.isActive,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForStateSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForZone =
  ({ month, year, stateId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/zones-with-attendence-target-achievement-by-state?month=" +
          month +
          "&year=" +
          year +
          "&stateId=" +
          stateId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              shortName:
                targetObject.shortName === null ? "" : targetObject.shortName,
              stateId:
                targetObject.stateId === null ? "" : targetObject.stateId,
              sortOrderNo:
                targetObject.sortOrder === null ? "" : targetObject.sortOrder,
              isActive:
                targetObject.isActive === null ? "" : targetObject.isActive,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForZoneSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForDistrict =
  ({ month, year, zoneId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/districts-with-attendence-target-achievement-by-zone?zoneId=" +
          zoneId +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              shortName:
                targetObject.shortName === null ? "" : targetObject.shortName,
              zoneId: targetObject.zoneId === null ? "" : targetObject.zoneId,
              sortOrderNo:
                targetObject.sortOrder === null ? "" : targetObject.sortOrder,
              isActive:
                targetObject.isActive === null ? "" : targetObject.isActive,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForDistrictSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForTaluka =
  ({ month, year, districtId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/talukas-with-attendence-target-achievement-by-district?districtId=" +
          districtId +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              shortName:
                targetObject.shortName === null ? "" : targetObject.shortName,
              districtId:
                targetObject.districtId === null ? "" : targetObject.districtId,
              sortOrderNo:
                targetObject.sortOrder === null ? "" : targetObject.sortOrder,
              isActive:
                targetObject.isActive === null ? "" : targetObject.isActive,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForTalukaSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAttendanceTargetAchievmentForArea =
  ({ month, year, talukaId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/areas-with-attendence-target-achievement-by-taluka?talukaId=" +
          talukaId +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((dataOb) => {
            finalTotal = finalTotal + dataOb.totalAchieve;
          });
          const row = data.data.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name: targetObject.name === null ? "" : targetObject.name,
              shortName:
                targetObject.shortName === null ? "" : targetObject.shortName,
              talukaId:
                targetObject.talukaId === null ? "" : targetObject.talukaId,
              sortOrderNo:
                targetObject.sortOrder === null ? "" : targetObject.sortOrder,
              isActive:
                targetObject.isActive === null ? "" : targetObject.isActive,
              totalSalesTeam:
                targetObject.totalSalesTeam === null
                  ? ""
                  : targetObject.totalSalesTeam,
              totalPresentSalesTeam:
                targetObject.totalPresentSalesTeam === null
                  ? ""
                  : targetObject.totalPresentSalesTeam,
              target: 5000000,
              totalAchieve:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffAchieveTarget: 5000000 - targetObject.totalAchieve,
              primaryTotalAmt:
                targetObject.primaryTotalAmt === null
                  ? ""
                  : targetObject.primaryTotalAmt,
              secondaryTotalAmt:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve,
              diffPrimarySecond:
                targetObject.primaryTotalAmt !== null &&
                targetObject.totalAchieve !== null
                  ? targetObject.primaryTotalAmt - targetObject.totalAchieve
                  : "",
              finalTotal: finalTotal,
              contribution:
                targetObject.totalAchieve === null
                  ? ""
                  : targetObject.totalAchieve == 0
                  ? "-"
                  : ((+targetObject.totalAchieve / +finalTotal) * 100).toFixed(
                      0
                    ),
            };
            return targetData;
          });
          dispatch(
            attendanceTargetAchievmentForAreaSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPackagingTypeAmountsByFlag =
  ({ flag, month, year, id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-packaging-type-amts-by-flag?flag=" +
          flag +
          "&month=" +
          month +
          "&year=" +
          year +
          "&id=" +
          id,
      }).then(({ data, success }) => {
        if (success) {
          const objectData = data.data;

          let responseDataObj = {
            entityList:
              objectData.entityList === null
                ? ""
                : objectData.entityList.map((entityListObj, index) => {
                    let entityListData = {
                      index: index + 1,
                      id: entityListObj.id === null ? "" : entityListObj.id,
                      name:
                        entityListObj.name === null ? "" : entityListObj.name,
                      type:
                        entityListObj.type === null ? "" : entityListObj.type,
                      upperEntityId:
                        entityListObj.upperEntityId === null
                          ? ""
                          : entityListObj.upperEntityId,
                      shortName:
                        entityListObj.shortName === null
                          ? ""
                          : entityListObj.shortName,
                    };
                    return entityListData;
                  }),

            packagingTypes:
              objectData.packagingTypes === null
                ? ""
                : objectData.packagingTypes.map((packagingTypesObj, index) => {
                    let packagingTypesData = {
                      index: index + 1,
                      id:
                        packagingTypesObj.id === null
                          ? ""
                          : packagingTypesObj.id,
                      name:
                        packagingTypesObj.name === null
                          ? ""
                          : packagingTypesObj.name,
                      shortName:
                        packagingTypesObj.shortName === null
                          ? ""
                          : packagingTypesObj.shortName,
                      description:
                        packagingTypesObj.description === null
                          ? ""
                          : packagingTypesObj.description,
                      sortOrderNo:
                        packagingTypesObj.sortOrderNo === null
                          ? ""
                          : packagingTypesObj.sortOrderNo,
                      insertDateTime:
                        packagingTypesObj.insertDateTime === null
                          ? ""
                          : packagingTypesObj.insertDateTime,
                      productCount:
                        packagingTypesObj.productCount === null
                          ? ""
                          : packagingTypesObj.productCount,
                    };
                    return packagingTypesData;
                  }),

            packagingTypesWithAmts:
              objectData.packagingTypesWithAmts === null
                ? ""
                : objectData.packagingTypesWithAmts.map(
                    (packagingTypesWithAmtsObj, index) => {
                      let packagingTypesWithAmtsData = {
                        index: index + 1,
                        id:
                          packagingTypesWithAmtsObj.id === null
                            ? ""
                            : packagingTypesWithAmtsObj.id,
                        entityId:
                          packagingTypesWithAmtsObj.entityId === null
                            ? ""
                            : packagingTypesWithAmtsObj.entityId,
                        packagingTypeId:
                          packagingTypesWithAmtsObj.packagingTypeId === null
                            ? ""
                            : packagingTypesWithAmtsObj.packagingTypeId,
                        packagingTypeName:
                          packagingTypesWithAmtsObj.packagingTypeName === null
                            ? ""
                            : packagingTypesWithAmtsObj.packagingTypeName,
                        amt:
                          packagingTypesWithAmtsObj.amt === null
                            ? ""
                            : packagingTypesWithAmtsObj.amt,
                      };
                      return packagingTypesWithAmtsData;
                    }
                  ),
          };
          dispatch(packagingTypeAmountsByFlagSuccess({ row: responseDataObj }));
          return { response: responseDataObj, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
