import endpoint from "../config/endpoints";

export const AccessRoleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Remarks",
    name: "empTypeRemarks",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const RoomColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Floor Details",
    name: "floorName",
    formDataKey: "floor",
    tableDataKey: "floor",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Room Capacity",
    name: "roomCapacity",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Exam Capacity",
    name: "examCapacity",
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const DocumentColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "docName",
    formDataKey: "docName",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    showInExcel: true,
    canSearch: true,
  },
  { name: "action", title: "Action" },
];

export const UomColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const vehicleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Transporter",
    name: "transporterName",
    formDataKey: "transporterName",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Vehicle Number",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const ZoneColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "Name",
    name: "zoneName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    width: 200,
    isFrize: true,
  },
  {
    title: "State",
    name: "stateName",
    formDataKey: "state",
    tableDataKey: "state",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "District Count",
    name: "districtCount",
    formDataKey: "districtCount",
    tableDataKey: "districtCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.district + "/by-zone?zoneId=",
  },
  {
    title: "Taluka Count",
    name: "talukaCount",
    formDataKey: "talukaCount",
    tableDataKey: "talukaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    // url: endpoint.taluka + "/by-zone?zoneId=",
    url: endpoint.taluka + "/list-by-zone?zoneId=",

  },
  {
    title: "Area Count",
    name: "areaCount",
    formDataKey: "areaCount",
    tableDataKey: "areaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    // url: endpoint.area + "/list-by-zone?zoneId=",
    url: endpoint.area + "/get-by-zone?zoneId=",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const StateColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    width: 200,
    isFrize: true,
  },
  {
    title: "Code",
    name: "code",
    formDataKey: "code",
    tableDataKey: "code",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region Name",
    name: "regionName",
    formDataKey: "regionName",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone Count",
    name: "zoneCount",
    formDataKey: "zoneCount",
    tableDataKey: "zoneCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.zone + "/by-state?stateId=",
  },
  {
    title: "District Count",
    name: "districtCount",
    formDataKey: "districtCount",
    tableDataKey: "districtCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.district + "/by-state?stateId=",
  },
  {
    title: "Taluka Count",
    name: "talukaCount",
    formDataKey: "talukaCount",
    tableDataKey: "talukaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.taluka + "/by-state?stateId=",
  },
  {
    title: "Area Count",
    name: "areaCount",
    formDataKey: "areaCount",
    tableDataKey: "areaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    // url: endpoint.area + "/by-state?stateId=",
    url: endpoint.area + "/list-by-state?stateId=",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const TalukaColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "District",
    name: "districtName",
    formDataKey: "districtName",
    tableDataKey: "districtName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area Count",
    name: "areaCount",
    formDataKey: "areaCount",
    tableDataKey: "areaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.area + "/by-taluka?talukaId=",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.beat + "/by-taluka?talukaId=",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.outlet + "/list-by-taluka?talukaId=",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const TaxColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Tax Name",
    name: "taxName",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "SGST",
    name: "sgst",
    formDataKey: "sgst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgst",
    formDataKey: "cgst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "IGST",
    name: "igst",
    formDataKey: "igst",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CESS",
    name: "cess",
    formDataKey: "cess",
    showInExcel: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "HSN Code",
    name: "hsnCode",
    formDataKey: "hsnCode",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action" },
];

export const CategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "categoryName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Sub-Category Count",
    name: "subCategoryCount",
    formDataKey: "subCategoryCount",
    tableDataKey: "subCategoryCount",
    canSearch: true,
    showInExcel: true,
    align: "center",
    linkOpen: true,
    passId: "id",
    url: endpoint.subCategory + "/by-category?categoryId=",
  },

  {
    title: "Product Count",
    name: "productCount",
    formDataKey: "productCount",
    tableDataKey: "productCount",
    canSearch: true,
    showInExcel: true,
    align: "center",
    linkOpen: true,
    passId: "id",
    url: endpoint.product + "/list-by-category?categoryId=",
  },

  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const SubCategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "subCategoryName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    width: 250,
  },
  {
    title: "Category",
    name: "categoryName",
    formDataKey: "category",
    tableDataKey: "category",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Product Count",
    name: "productCount",
    formDataKey: "productCount",
    tableDataKey: "productCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.product + "/by-sub-category?subCategoryId=",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const SubCategoryColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

// change /get-area mapping to /get-active-by-area by akash - 01-08-2024
export const AreaColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Taluka",
    name: "taluka",
    formDataKey: "taluka",
    tableDataKey: "taluka",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.beat + "/get-active-by-area?areaId=",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.outlet + "/list-by-area?areaId=",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const SalesExecutiveColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInscreen: true,
    isFrize: true,
    showInExcel: true,
    align: "center",
  },

  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    tableDataKey: "userName",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    showInscreen: true,
  },
  {
    title: "Person Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    tableDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "region",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "State",
    name: "stateNames",
    formDataKey: "stateNames",
    tableDataKey: "stateNames",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "Zone",
    name: "zoneNames",
    formDataKey: "zone",
    tableDataKey: "zoneNames",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "area",
    tableDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "Email Id",
    name: "emailId",
    showInExcel: true,
    canSearch: true,
    align: "left",
    showInscreen: true,
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  {
    name: "action",
    showInscreen: true,
    title: "Action",
    align: "center",
    width: 130,
  },
];

export const ProductColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    width: 250,
  },
  {
    title: "Product Code",
    name: "code",
    formDataKey: "code",
    tableDataKey: "code",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "UOM Conversion Value",
    name: "uomConversionValue",
    formDataKey: "uomConversionValue",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "Product Shelf Life",
    name: "selfLife",
    formDataKey: "selfLife",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Product Weight",
    name: "weight",
    formDataKey: "weight",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center", width: 170 },
];
export const ProductColumnsForPopUp = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Product Code",
    name: "code",
    formDataKey: "code",
    tableDataKey: "code",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Shelf Life (In Month)",
    name: "selfLife",
    formDataKey: "selfLife",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Product Weight",
    name: "weight",
    formDataKey: "weight",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];
export const NoticeMsgColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Notice Headline",
    name: "noticeHeadline",
    formDataKey: "noticeHeadline",
    tableDataKey: "noticeHeadline",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Description",
    name: "description",
    formDataKey: "description",
    tableDataKey: "description",
    canSearch: true,
    align: "left",
    showInExcel: true,
  },
  {
    title: "Type",
    name: "typeName",
    tableDataKey: "typeName",
    formDataKey: "typeName",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "From Date",
    name: "fromdate",
    tableDataKey: "fromdate",
    formDataKey: "fromdate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "todate",
    tableDataKey: "todate",
    formDataKey: "todate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const RegionColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    width: 150,
    isFrize: true,
  },
  {
    title: "Country",
    name: "countryName",
    formDataKey: "countryName",
    tableDataKey: "countryName",
    canSearch: true,
    showInExcel: true,
    width: 100,
  },
  {
    title: "State Count",
    name: "stateCount",
    formDataKey: "stateCount",
    tableDataKey: "stateCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.state + "/by-region?regionId=",
  },
  {
    title: "Zone Count",
    name: "zoneCount",
    formDataKey: "zoneCount",
    tableDataKey: "zoneCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.zone + "/list-by-region?regionId=",
  },
  {
    title: "District Count",
    name: "districtCount",
    formDataKey: "districtCount",
    tableDataKey: "districtCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.district + "/by-region?regionId=",
  },
  {
    title: "Taluka Count",
    name: "talukaCount",
    formDataKey: "talukaCount",
    tableDataKey: "talukaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    // url: endpoint.taluka + "/by-region?regionId=",
    url: endpoint.taluka + "/list-by-region?regionId=",
  },
  {
    title: "Area Count",
    name: "areaCount",
    formDataKey: "areaCount",
    tableDataKey: "areaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    // url: endpoint.area + "/by-region?regionId=",
    url: endpoint.area + "/list-by-region?regionId=",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const SingleMrpConfigurationByItemListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "MRP",
    name: "mrp",
    showInscreen: true,
    positionCenter: false,
    alignCenter: "right",
  },
  {
    title: "Date",
    name: "publishDate",
    showInscreen: true,
    positionCenter: true,
  },
  {
    name: "action",
    title: "Action",
    align: "center",
    showInscreen: true,
    positionCenter: false,
  },
];

export const StakeHolderColumns = [
  {
    title: "Sr.No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    align: "center",
    width: 100,
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    width: 200,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Order To",
    name: "orderTo",
    formDataKey: "orderTo",
    canSearch: true,
    showInExcel: true,
    width: 150,
  },
  {
    title: "State",
    name: "stateName",
    formDataKey: "stateName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
    width: 200,
  },
  {
    title: "SH-Code",
    name: "shCode",
    formDataKey: "shCode",
    canSearch: true,
    showInExcel: true,
    align: "center",
    width: 120,
  },
  {
    title: "SPOC",
    name: "contactPersonName",
    formDataKey: "contactPersonName",
    canSearch: true,
    showInExcel: true,
    width: 150,
  },
  {
    title: "SPOC Number",
    name: "contactPersonNo",
    formDataKey: "contactPersonNo",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Email Id",
    name: "emailId",
    showInExcel: true,
    canSearch: true,
    align: "left",
  },
  {
    title: "Stock Holder",
    name: "stockStakeHolderTypeName",
    formDataKey: "stockStakeHolderType",
    tableDataKey: "stockStakeHolderType",
    canSearch: true,
    chipCompo: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center", width: 150 },
];

export const ManagerColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "User Name",
    name: "userName",
    formDataKey: "userName",
    tableDataKey: "userName",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    width: 120,
  },
  {
    title: "Person Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    width: 150,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    tableDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "region",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone",
    name: "zoneNames",
    formDataKey: "zone",
    tableDataKey: "zoneNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaNames",
    formDataKey: "area",
    tableDataKey: "areaNames",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "EmailId",
    name: "emailId",
    formDataKey: "emailId",
    tableDataKey: "emailId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contact Number",
    name: "contactNo",
    formDataKey: "contactNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Whatsapp Number",
    name: "whatsAppNo",
    formDataKey: "whatsAppNo",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center", width: 110 },
];
export const OrderConfigurationColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Order To Name",
    name: "orderToName",
    formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];
export const Top20ProdCol = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Product Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Product Code",
    name: "code",
    formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Acheived Amount",
    name: "achievedAmount",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "% Contribution",
    name: "contribution",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
];

export const Top20OutletCol = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Outlet Name",
    name: "outletName",
    formDataKey: "outletName",
    tableDataKey: "outletName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area Name",
    name: "areaName",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sales Executive Name",
    name: "salesExecutiveName",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Acheived",
    name: "achieved",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Contribution",
    name: "contribution",
    // formDataKey: "orderToName",
    canSearch: true,
    showInExcel: true,
  },
];

export const PublishRateConfigColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "StakeHolder Type",
    name: "stockStakeHolderTypeName",
    formDataKey: "stockStakeHolderType",
    tableDataKey: "stockStakeHolderType",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Category",
    name: "category",
    formDataKey: "category",
    tableDataKey: "category",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Configuration",
    name: "rateConfiguration",
    formDataKey: "rateConfiguration",
    tableDataKey: "rateConfiguration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Publication Date",
    name: "publicationDate",
    formDataKey: "publicationDate",
    tableDataKey: "publicationDate",
    canSearch: true,
    showInExcel: true,
  },
];

export const RateConfigurationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Configuration Name",
    name: "name",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Stock Holder Type",
    name: "stockStakeHolderTypeName",
    positionCenter: false,
    canSearch: true,
    alignCenter: "right",
  },
  {
    title: "Category Name",
    name: "categoryName",
    positionCenter: false,
    canSearch: true,
  },

  {
    title: "Reference Date",
    name: "dateForMrpRate",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const OutletRateStructureColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    align: "center",
  },

  {
    title: "Configuration Name",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Category Name",
    name: "categoryName",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },

  { name: "action", title: "Action", align: "center" },
];

export const accessRoleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Stock Holder Type",
    name: "type",
    formDataKey: "type",
    showInExcel: true,
    canSearch: true,
  },
  // { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];
export const RangeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Maximum Range",
    name: "maxRange",
    formDataKey: "maxRange",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Minimum Range",
    name: "minRange",
    formDataKey: "minRange",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const GrievanceCategoryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "categoryName",
    formDataKey: "categoryName",
    tableDataKey: "categoryName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const OutletTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    align: "right",
    canSearch: true,
    showInExcel: true,
    linkOpen: true,
    passId: "id",
    url: endpoint.outlet + "/list-by-outlet-type?outletTypeId=",

  },
  { name: "action", title: "Action", align: "center" },
];

export const BeatColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "beatName",
    formDataKey: "beatName",
    tableDataKey: "beatName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area",
    name: "areaName",
    formDataKey: "area",
    tableDataKey: "area",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    align: "right",
    canSearch: true,
    showInExcel: true,
    linkOpen: true,
    passId: "id",
    isActiveStatic: true,
    isActiveURL: endpoint.outlet + "/update-is-active",
    url: endpoint.outlet + "/list-by-beat?beatId=",
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    tableDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const YearColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "yearName",
    formDataKey: "yearName",
    tableDataKey: "yearName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    tableDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    tableDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const OutletColumns = [
  {
    title: "Sr.No.",
    name: "index",
    isFrize: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
    isFrize: true,
    width: 200,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "State",
  //   name: "stateName",
  //   formDataKey: "stateName",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  {
    title: "Area",
    name: "areaName",
    formDataKey: "areaName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    formDataKey: "beat",
    tableDataKey: "beat",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Lattitude",
    name: "latitude",
    formDataKey: "latitude",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Longitude",
    name: "longitude",
    formDataKey: "longitude",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Mobile Number",
    name: "mobileNo",
    formDataKey: "mobileNo",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Outlet Type",
    name: "outLetTypeName",
    formDataKey: "outLetTypeName",
    tableDataKey: "outLetTypeName",
    canSearch: true,
    showInExcel: true,
    width: 150,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const TargetColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Jan",
    name: "jan",
    formDataKey: "jan",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Feb",
    name: "feb",
    formDataKey: "feb",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "March",
    name: "march",
    formDataKey: "march",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "April",
    name: "april",
    formDataKey: "april",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "May",
    name: "may",
    formDataKey: "may",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "Jun",
    name: "jun",
    formDataKey: "jun",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },

  {
    title: "July",
    name: "july",
    formDataKey: "july",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Aug",
    name: "aug",
    formDataKey: "aug",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sep",
    name: "sep",
    formDataKey: "sep",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Oct",
    name: "oct",
    formDataKey: "oct",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Nov",
    name: "nov",
    formDataKey: "nov",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Dec",
    name: "dec",
    formDataKey: "dec",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Total",
    name: "totalTarget",
    formDataKey: "totalTarget",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
];

export const AuditColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Audit / Survey",
    name: "isAuditSurvey",
    formDataKey: "isAuditSurvey",
    tableDataKey: "isAuditSurvey",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Audit/Survey For",
    name: "type",
    formDataKey: "type",
    tableDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const dayWiseBitAssignColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Person Name",
    name: "salesTeamName",
    formDataKey: "yearName",
    tableDataKey: "yearName",
    canSearch: true,
  },
  {
    title: "Number of Beat",
    name: "noOfBeat",
    formDataKey: "remark",
    tableDataKey: "remark",
    canSearch: true,
    align: "right",
  },
  {
    title: "Number of Outlet",
    name: "noOfOutLet",
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const RegionAndTargetColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Region",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jan",
    name: "jan",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Feb",
    name: "feb",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "March",
    name: "march",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "April",
    name: "april",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "May",
    name: "may",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Jun",
    name: "jun",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "July",
    name: "july",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Aug",
    name: "aug",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Sep",
    name: "sep",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Oct",
    name: "oct",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Nov",
    name: "nov",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Dec",
    name: "dec",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
  },
];
export const GrievanceColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Category",
    name: "categoryName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Complaint By",
    name: "userName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Complaint Description",
    name: "remark",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Date",
    name: "insertDateTime",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const CompanyTargetColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    isFrize: true,
  },
  {
    title: "Year",
    name: "name",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    isFrize: true,
  },
  {
    title: "Jan",
    name: "jan",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Feb",
    name: "feb",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "March",
    name: "march",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "April",
    name: "april",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "May",
    name: "may",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Jun",
    name: "jun",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "July",
    name: "july",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Aug",
    name: "aug",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Sep",
    name: "sep",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Oct",
    name: "oct",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Nov",
    name: "nov",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Dec",
    name: "dec",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    showInscreen: true,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];

export const OrderHistoryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    title: "Outlet Name",
    name: "outletName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PaymentColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    align: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: true,
    canSearch: true,
    align: "center",
    width: 150,
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
    align: "left",
    width: 250,
  },
  {
    title: "Outlet Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
    width: 250,
  },
  {
    title: "Payment Date",
    name: "date",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
    width: 50,
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: true,
    canSearch: true,
    align: "center",
    width: 50,
  },
  {
    title: "Remark",
    name: "remark",
    positionCenter: false,
    canSearch: true,
    align: "left",
    width: 150,
  },
];
export const BillingHistoryColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const CreditNoteColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },

  {
    title: "Beat",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Outlet",
    name: "outletName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Total Quantity",
    name: "totalQty",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];

export const requestGRNColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    align: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: true,
    canSearch: true,
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: true,
    canSearch: true,
  },
  {
    title: "GRN To",
    name: "toStockStakeHolderFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Total Quantity",
    name: "totalQty",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Value",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const GenratedBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const OrderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "stockHolder",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "SH Code",
    name: "fromOrderShCode",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "State Name",
    name: "fromOrderStateName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Area Name",
    name: "fromOrderAreaNames",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Total Quantity",
    name: "totalQty",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "IGST",
    name: "igstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const GeneratedBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "ownerAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "State Name",
    name: "toBillStateName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Area Name",
    name: "toBillAreaNames",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "IGST",
    name: "igstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedName",
    positionCenter: true,
    canSearch: true,
  },
  {
    title: "Status",
    name: "statusForColumn",
    positionCenter: true,
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const PlaceOrderColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: false,
    align: "center",
    canSearch: true,
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Total Quantity",
    name: "totalQty",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    formDataKey: "status",
    chipCompo: true,
    chipColor: "",
    chipClick: 0,
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const ShowMyBillListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedStatus",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const historyOfCreditNoteColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    align: "center",
  },
  {
    title: "Purchase Return Number",
    name: "creditNoteNo",
    positionCenter: false,
    canSearch: true,
    alignCenter: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    alignCenter: "center",
  },
  {
    title: "Firm Name",
    name: "toStockStakeHolderFirmName",
    positionCenter: false,
    canSearch: true,
    alignCenter: "left",
  },
  {
    title: "State Name",
    name: "toStockStakeHolderStateName",
    positionCenter: false,
    canSearch: true,
    alignCenter: "left",
  },
  {
    title: "Area Name",
    name: "toStockStakeHolderAreaNames",
    positionCenter: false,
    canSearch: true,
    alignCenter: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    alignCenter: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    alignCenter: "right",
  },
  { name: "action", title: "Action", align: "center" },
];

export const creditNoteHeaderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Credit Note No",
    name: "creditNoteNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },

  { name: "action", title: "Action", align: "center" },
];

export const grnHistoryListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "fromStockStakeHolderOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "State Name",
    name: "fromStockStakeHolderStateName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Area Name",
    name: "fromStockStakeHolderAreaNames",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PendingGrnListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "GRN Number",
    name: "grnGvnNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "GRN Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "fromStockStakeHolderFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "State Name",
    name: "fromStockStakeHolderStateName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Area Name",
    name: "fromStockStakeHolderAreaNames",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  { name: "action", title: "Action", align: "center" },
];
export const PaymentListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Firm Name",
    name: "fromStockStakeHolderFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Transaction No",
    name: "transactionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Settle Remaining Amount",
    name: "settleRemainingAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const MangeStockListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },

  { name: "action", title: "Action", align: "center" },
];

export const BatchwiseOutstockColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const stockConsumptionHeaderColums = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Product Name",
    name: "productName",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "left",
  },
  {
    title: "Opening Quantity",
    name: "openingQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Purchase Quantity",
    name: "purchaseQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Distributor Sell Quantity",
    name: "distributorSellQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Outlet Sell Quantity",
    name: "outletSellQty",
    canSearch: true,
    showInExcel: true,
    align: "right",
    width: 200,
  },
  {
    title: "GRN Quantity",
    name: "grnQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Credit Note Quantity",
    name: "crnQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "In Stock Quantity",
    name: "inStockQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Out Stock Quantity",
    name: "outStockQty",
    canSearch: true,
    width: 200,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Closing Quantity",
    name: "closingQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "left",
  },
];
export const stockBetweenDateColumns = [
  {
    title: "Sr.No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    align: "center",
  },
  {
    title: "Product Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: 200,
    canSearch: true,
  },
  {
    title: "UOM",
    name: "stakeHolderUomName",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "center",
    canSearch: true,
  },
  {
    title: "Opening Quantity",
    name: "openingQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },

  {
    title: "Purchase Quantity",
    name: "purchaseQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Distributor Quantity",
    name: "distributorSellQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Outlet Sell Quantity",
    name: "outletSellQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Grn Quantity",
    name: "grnQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },

  {
    title: "Credit Note Quantity",
    name: "crnQty",
    canSearch: true,
    showInExcel: true,
    width: 200,
    align: "right",
  },
  {
    title: "Instock Quantity",
    name: "inStockQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Outstock Quantity",
    name: "outStockQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
    canSearch: true,
  },
  {
    title: "Closing Quantity",
    name: "closingQty",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    positionCenter: false,
    showInscreen: true,
    width: 200,
    align: "left",
    canSearch: true,
  },
];

export const BatchWiseOutletBillingListColumns = [
  { title: "Sr.No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const BatchwiseStakeholderListColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Order from",
    name: "toBillOwnerName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Bill Received",
    name: "isRecievedName",
    positionCenter: true,
    canSearch: true,
  },

  { name: "action", title: "Action", align: "center" },
];

export const BatchwiseMangeStockColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Consumption Number",
    name: "consumptionNo",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Type",
    name: "type",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const paymentDetailsTableColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Amount",
    name: "amt",
    positionCenter: false,
    canSearch: true,
    align: "right",
  },
  {
    title: "Date",
    name: "date",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Pay Type",
    name: "payType",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
  {
    title: "Stock Holder",
    name: "toStakeHolderNameAndFirmName",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },

  { name: "action", title: "Action", align: "center" },
];

export const InactiveOutletColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    alignCenter: "center",
  },
  {
    title: "Outlet",
    name: "outLetType",
    positionCenter: false,
    canSearch: true,
    align: "left",
  },
  {
    title: "Status",
    name: "status",
    positionCenter: false,
    canSearch: true,
    align: "center",
  },
];
export const OfferColumnsCurrent = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Offer Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Offer For",
    name: "offerFor",
    formDataKey: "offerFor",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  { name: "action", title: "Action", align: "center", showInscreen: true },
];

export const OfferColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Offer Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    alignCenter: "center",
    showInscreen: true,
  },
  {
    title: "Offer For",
    name: "offerFor",
    formDataKey: "offerFor",
    canSearch: true,
    showInExcel: true,
    showInscreen: true,
  },
  { name: "action", title: "Action", align: "center", showInscreen: true },
];

export const ReportInfoColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Report Type",
    name: "reportType",
    formDataKey: "reportType",
    canSearch: true,
  },
  {
    title: "Report Name",
    name: "reportName",
    formDataKey: "reportName",
    canSearch: true,
  },
  {
    title: "Is PDF",
    name: "isPdf",
    formDataKey: "isPdf",
    canSearch: true,
  },
  {
    title: "Is EXCEL",
    name: "isExcel",
    formDataKey: "isExcel",
    canSearch: true,
  },
  {
    title: "Is Graph",
    name: "isGraph",
    formDataKey: "isGraph",
    canSearch: true,
  },
  {
    title: "Is Subreport",
    name: "isSubreport",
    formDataKey: "isSubreport",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const TypeColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Type Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const ComapnayCategoryConfigurationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    align: "center",
    canSearch: true,
  },

  {
    title: "Category Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
  },
  {
    title: "Type",
    name: "typeForColumn",
    formDataKey: "typeForColumn",
    canSearch: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    formDataKey: "isActive",
    canSearch: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const StateColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "stateName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

export const ZoneColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "zoneName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

export const DistrictColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "districtName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

export const TalukaColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "talukaName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

export const AreaColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "areaName",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];
export const BeatColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "beatName",
    formDataKey: "beatName",
    tableDataKey: "beatName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];
export const OutletCountStatusColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Mobile No",
    name: "mobileNo",
    formDataKey: "mobileNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];


export const OutletCountColumnsForOutletTypePopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Area",
    name: "areaName",
    formDataKey: "areaName",
    tableDataKey: "areaName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Mobile No",
    name: "mobileNo",
    formDataKey: "mobileNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];

export const OutletCountColumnsForPopup = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Mobile No",
    name: "mobileNo",
    formDataKey: "mobileNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
];
export const PackagingTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Packaging Type Name",
    name: "packagingName",
    formDataKey: "packagingName",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action" },
];
export const DistrictColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone",
    name: "zoneName",
    formDataKey: "zone",
    tableDataKey: "zoneName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Taluka Count",
    name: "talukaCount",
    formDataKey: "talukaCount",
    tableDataKey: "talukaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.taluka + "/by-district?districtId=",
  },
  {
    title: "Area Count",
    name: "areaCount",
    formDataKey: "areaCount",
    tableDataKey: "areaCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
    linkOpen: true,
    passId: "id",
    url: endpoint.area + "/by-district?districtId=",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    tableDataKey: "beatCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Outlet Count",
    name: "outletCount",
    formDataKey: "outletCount",
    tableDataKey: "outletCount",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const BrandColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const TagColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "isActive",
    formDataKey: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const CountryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];
export const GroupProductColumns = [
  {
    title: "Sr. No.",
    name: "index",
    align: "center",
    canSearch: true,
  },
  {
    title: "Group Name",
    name: "groupProductName",
    formDataKey: "groupProductName",
    canSearch: true,
  },
  {
    title: "Group Code",
    name: "code",
    formDataKey: "code",
    canSearch: true,
    align: "center",
  },
  {
    title: "Category",
    name: "categoryName",
    formDataKey: "categoryName",
    canSearch: true,
  },
  {
    title: "Brand",
    name: "brandName",
    formDataKey: "brandName",
    canSearch: true,
  },
  {
    title: "Product Count",
    name: "productCount",
    formDataKey: "productCount",
    align: "center",
    canSearch: true,
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    align: "center",
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];
export const RateConfigurationEditingForStockholderColumns = [
  {
    title: "Sr. No.",
    name: "index",
    align: "center",
    canSearch: true,
  },
  {
    title: "Firm Name",
    name: "stockStakeHolderFirmName",
    formDataKey: "stockStakeHolderFirmName",
    canSearch: true,
  },
  {
    title: "Stock Holder Type",
    name: "stockStakeHolderTypeName",
    formDataKey: "stockStakeHolderTypeName",
    canSearch: true,
  },
  {
    title: "Product Count",
    name: "productCount",
    formDataKey: "productCount",
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const ProductRateStructureColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  {
    title: "Structure Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    align: "left",
    showInscreen: true,
  },
  {
    title: "Structure Date",
    name: "structureDate",
    formDataKey: "structureDate",
    tableDataKey: "structureDate",
    canSearch: true,
    align: "center",
    showInscreen: true,
  },
  {
    title: "Total Assign Count",
    name: "assignCount",
    formDataKey: "assignCount",
    tableDataKey: "assignCount",
    canSearch: true,
    align: "center",
    linkOpen: true,
    showInscreen: true,
    // passId: "id",
    // url:
    //   endpoint.productRateStructureAssignation +
    //   "/by-product-rate-structure?productRateStructureId=",
  },
  {
    title: "Total Publish Count",
    name: "publishCount",
    formDataKey: "publishCount",
    tableDataKey: "publishCount",
    canSearch: true,
    align: "center",
    linkOpen: true,
    showInscreen: true,
    // linkOpen: true,
    // passId: "id",
    // url:
    //   endpoint.stackholder +
    //   "/get-pubish-by-product-rate-structure?productRateStructureId=",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  {
    name: "action",
    showInscreen: true,
    title: "Action",
    align: "center",
    width: 130,
  },
];


export const ProductRateStructureOptimisedColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  {
    title: "Structure Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    align: "left",
    showInscreen: true,
  },
  {
    title: "Structure Date",
    name: "structureDate",
    formDataKey: "structureDate",
    tableDataKey: "structureDate",
    canSearch: true,
    align: "center",
    showInscreen: true,
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  {
    name: "action",
    showInscreen: true,
    title: "Action",
    align: "center",
    width: 130,
  },
];


export const ProductRateStructurePublishDistributorColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Distributor Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    align: "left",
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    align: "left",
  },
];
export const ProductRateStructureAssignDistributorColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Distributor Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    align: "left",
  },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    align: "left",
  },
];

export const StockHolderOrderConfigurationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Brand Name",
    name: "brandName",
    formDataKey: "brandName",
    tableDataKey: "brandName",
    canSearch: true,
    align: "left",
  },
  {
    title: "Firm Name",
    name: "stockStakeHolderFirm",
    formDataKey: "stockStakeHolderFirm",
    tableDataKey: "stockStakeHolderFirm",
    canSearch: true,
    align: "left",
  },
  {
    title: "From Time",
    name: "fromTime",
    formDataKey: "fromTime",
    tableDataKey: "fromTime",
    canSearch: true,
    align: "center",
  },
  {
    title: "To Time",
    name: "toTime",
    formDataKey: "fromTime",
    tableDataKey: "fromTime",
    canSearch: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const OutletMovingColumns = [
  {
    title: "Sr.No.",
    name: "index",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Outlet Name",
    name: "outlet",
    formDataKey: "outlet",
    canSearch: true,
  },
];
export const beatTargetColumns = [
  {
    title: "Sr. No.",
    name: "index",
    align: "center",
    canSearch: true,
  },
  {
    title: "Year Name",
    name: "yearName",
    formDataKey: "yearName",
    canSearch: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    align: "center",
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    align: "center",
  },
  {
    title: "Beat Count",
    name: "beatCount",
    formDataKey: "beatCount",
    canSearch: true,
    align: "center",
    linkOpen: true,
    passId: "yearId",
    url: endpoint.beatTarget + "/list-by-year?yearId=",
  },
  { name: "action", title: "Action", align: "center" },
];
export const beatNameColumns = [
  {
    title: "Sr. No.",
    name: "index",
    align: "center",
    canSearch: true,
  },
  {
    title: "Region Name",
    name: "regionName",
    formDataKey: "regionName",
    tableDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "State Name",
    name: "stateName",
    formDataKey: "stateName",
    tableDataKey: "stateName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Zone Name",
    name: "zoneName",
    formDataKey: "zoneName",
    tableDataKey: "zoneName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area Name",
    name: "areaName",
    formDataKey: "areaName",
    tableDataKey: "areaName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Beat Name",
    name: "beat.beatName",
    formDataKey: "beat.beatName",
    tableDataKey: "beat.beatName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Target",
    name: "totalTarget",
    formDataKey: "totalTarget",
    tableDataKey: "totalTarget",
    align: "right",
    canSearch: true,
    showInExcel: true,
  },
];

export const LadgerColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Date",
    name: "date",
    formDataKey: "date",
    tableDataKey: "date",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Description",
    name: "remark",
    formDataKey: "remark",
    tableDataKey: "remark",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Voucher No",
    name: "type",
    formDataKey: "type",
    tableDataKey: "type",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Debit Amount",
    name: "debitAmt",
    formDataKey: "debitAmt",
    tableDataKey: "debitAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Credit Amount",
    name: "creditAmt",
    formDataKey: "creditAmt",
    tableDataKey: "creditAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Balance Amount",
    name: "balanceAmt",
    formDataKey: "balanceAmt",
    tableDataKey: "balanceAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
];

export const SubAdminUserColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Stock Holder",
    name: "stockStakeHolderType",
    formDataKey: "stockStakeHolderType",
    tableDataKey: "stockStakeHolderType",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Contact No",
    name: "contactNo",
    formDataKey: "contactNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Email",
    name: "emailId",
    formDataKey: "emailId",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    showInExcel: true,
    align: "center",
    showInscreen: true,
  },
  { name: "action", title: "Action", align: "center" },
];
export const orderClosingColumns = [
  {
    title: "Sr.No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    align: "center",
  },
  {
    title: "Outlet Name",
    name: "firmName",
    formDataKey: "firmName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: 150,
  },
  {
    title: "Sales Person Name",
    name: "salesTeamName",
    formDataKey: "salesTeamName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: 200,
  },
  {
    title: "Product Name",
    name: "productName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Order No",
    name: "orderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Order Quantity",
    name: "totalQty",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Taxable Amt",
    name: "taxableAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Total",
    name: "total",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
];
export const transporterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Region",
    name: "regionName",
    formDataKey: "regionName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Pincode",
    name: "pincode",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "SPOC",
    name: "spocName",
    formDataKey: "spocName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "SPOC Contact Number",
    name: "spocNo",
    formDataKey: "spocNo",
    showInExcel: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Transporter For",
    name: "transporterForData",
    formDataKey: "transporterForData",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "GST NO",
    name: "gstNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Sort Order Number",
    name: "sortOrder",
    formDataKey: "sortOrder",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const bankDetailsColums = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Firm Name",
    name: "firmName",
    formDataKey: "firmName",
    tableDataKey: "firmName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Owner Name",
    name: "ownerName",
    formDataKey: "ownerName",
    tableDataKey: "ownerName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Bank Name",
    name: "bankName",
    formDataKey: "bankName",
    tableDataKey: "bankName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Account Type",
    name: "accType",
    formDataKey: "accType",
    tableDataKey: "accType",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Account Number",
    name: "accNo",
    formDataKey: "accNo",
    tableDataKey: "accNo",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "IFSC Code",
    name: "ifscCode",
    formDataKey: "ifscCode",
    tableDataKey: "ifscCode",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "State",
    name: "state",
    formDataKey: "state",
    tableDataKey: "state",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "City",
    name: "city",
    formDataKey: "city",
    tableDataKey: "city",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const PaymentTermColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "paymentName",
    formDataKey: "paymentName",
    tableDataKey: "paymentName",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const timeSlotColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "From Time",
    name: "fromTime",
    formDataKey: "fromTime",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "To Time",
    name: "toTime",
    formDataKey: "toTime",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];


export const workingTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Other Task",
    name: "otherTaskName",
    formDataKey: "otherTaskName",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];


export const otherTaskColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },

  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];


export const ClaimTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Claim Type",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Claim Short Name",
    name: "shortName",
    formDataKey: "shortName",
    tableDataKey: "shortName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const ClaimStructureColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Claim Structure",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];


export const leaveTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Leave Type",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Leave Short Name",
    name: "shortName",
    formDataKey: "shortName",
    tableDataKey: "shortName",

    canSearch: true,
    showInExcel: true,



  },
  { name: "action", title: "Action", align: "center" },
];



export const leaveSummaryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Leave Summary",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Short Name",
    name: "shortName",
    formDataKey: "shortName",
    tableDataKey: "shortName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", align: "center" },
];

export const leaveStructureColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Leave Structure",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  { name: "action", title: "Action", align: "center" },
];



export const assetsColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Description",
    name: "description",
    formDataKey: "description",
    tableDataKey: "description",
    canSearch: true,
    showInExcel: true,
  },

  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];



export const venderColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "GST Applicable",
    name: "gstApplicableYesNo",
    formDataKey: "gstApplicableYesNo",
    tableDataKey: "gstApplicableYesNo",
    canSearch: true,
    showInExcel: true,
  },

  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const assetsChecklistColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Questions",
    name: "questions",
    formDataKey: "questions",
    tableDataKey: "questions",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Parameters",
    name: "assetsName",
    formDataKey: "assetsName",
    tableDataKey: "assetsName",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const productCatelogueColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Product",
    name: "productName",
    formDataKey: "productName",
    tableDataKey: "productName",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];

export const typeOfBusinessColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const investedAmountColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const annualTurnOverColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const noOfStaffColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];


export const weeklyOffColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Day",
    name: "dayName",
    formDataKey: "dayName",
    tableDataKey: "dayName",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action", align: "center" },
];