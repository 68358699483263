import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { leaveSummaryJSON } from "../../../DynamicFormsJson/MastersJSON/leaveSummary";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";

class LeaveSummaryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <DynamicForm
                    formPath={leaveSummaryJSON.formPath}
                    screenTitle={leaveSummaryJSON.screenTitle}
                    fieldMeta={leaveSummaryJSON.fieldMeta}
                    apiBaseURL={leaveSummaryJSON.apiBaseURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveSummaryForm);
