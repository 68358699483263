import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  region: [],
  zone: [],
  regionByCountry: [],
  regionNew: [],
};
let URL = endpoints.region;
let URL1 = endpoints.zone;
const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    regionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        region: row,
      };
    },
    regionNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        regionNew: row,
      };
    },

    zoneSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zone: row,
      };
    },
    regionByCountrySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        regionByCountry: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  regionSuccess,
  regionByCountrySuccess,
  zoneSuccess,
  resetState,
  regionNewSuccess,
} = regionSlice.actions;

export default regionSlice.reducer;

export const getRegion = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((regionObject, index) => {
          let regionData = {
            index: index + 1,
            id: regionObject.id === null ? "" : regionObject.id,
            name:
              regionObject.name !== null && regionObject.shortName !== null
                ? regionObject.name + " (" + regionObject.shortName + ")" : "",
            shortName:
              regionObject.shortName === null ? "" : regionObject.shortName,
            country:
              regionObject.country === null ? "" : regionObject.country.id,
            countryName:
              regionObject.country !== null && regionObject.country.name !== null ? regionObject.country.name : "",
            stateCount:
              regionObject.stateCount === null ? "" : regionObject.stateCount,
            zoneCount:
              regionObject.zoneCount === null ? "" : regionObject.zoneCount,
            districtCount:
              regionObject.districtCount === null
                ? ""
                : regionObject.districtCount,
            talukaCount:
              regionObject.talukaCount === null ? "" : regionObject.talukaCount,
            areaCount:
              regionObject.areaCount === null ? "" : regionObject.areaCount,
            beatCount:
              regionObject.beatCount === null ? "" : regionObject.beatCount,
            outletCount:
              regionObject.outletCount === null ? "" : regionObject.outletCount,

            sortOrder:
              regionObject.sortOrder === null ? "" : regionObject.sortOrder,
          };
          return regionData;
        });
        dispatch(regionSuccess({ row }));
        dispatch(regionNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getRegionNew = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((regionObject, index) => {
          let regionData = {
            index: index + 1,
            id: regionObject.id === null ? "" : regionObject.id,
            name:
              regionObject.name === null
                ? ""
                : regionObject.name + " (" + regionObject.shortName + ")",
            shortName:
              regionObject.shortName === null ? "" : regionObject.shortName,
            country:
              regionObject.countryId === null ? "" : regionObject.countryId,
            countryName:
              regionObject.countryName === null ? "" : regionObject.countryName,
            stateCount:
              regionObject.stateCount === null ? "" : regionObject.stateCount,
            zoneCount:
              regionObject.zoneCount === null ? "" : regionObject.zoneCount,
            districtCount:
              regionObject.districtCount === null
                ? ""
                : regionObject.districtCount,
            talukaCount:
              regionObject.talukaCount === null ? "" : regionObject.talukaCount,
            areaCount:
              regionObject.areaCount === null ? "" : regionObject.areaCount,
            beatCount:
              regionObject.beatCount === null ? "" : regionObject.beatCount,
            outletCount:
              regionObject.outletCount === null ? "" : regionObject.outletCount,
            sortOrder:
              regionObject.sortOrder === null ? "" : regionObject.sortOrder,
          };
          return regionData;
        });
        dispatch(regionSuccess({ row }));
        dispatch(regionNewSuccess({ row: data.data }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getRegionUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all" + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((regionObject, index) => {
          let regionData = {
            index: index + 1,
            id: regionObject.id === null ? "" : regionObject.id,
            name:
              regionObject.name === null
                ? ""
                : regionObject.name + " (" + regionObject.shortName + ")",
            shortName:
              regionObject.shortName === null ? "" : regionObject.shortName,
            country:
              regionObject.countryId === null ? "" : regionObject.countryId,
            countryName:
              regionObject.countryName === null ? "" : regionObject.countryName,
            stateCount:
              regionObject.stateCount === null ? "" : regionObject.stateCount,
            zoneCount:
              regionObject.zoneCount === null ? "" : regionObject.zoneCount,
            districtCount:
              regionObject.districtCount === null
                ? ""
                : regionObject.districtCount,
            talukaCount:
              regionObject.talukaCount === null ? "" : regionObject.talukaCount,
            areaCount:
              regionObject.areaCount === null ? "" : regionObject.areaCount,
            beatCount:
              regionObject.beatCount === null ? "" : regionObject.beatCount,
            outletCount:
              regionObject.outletCount === null ? "" : regionObject.outletCount,
            sortOrder:
              regionObject.sortOrder === null ? "" : regionObject.sortOrder,
          };
          return regionData;
        });
        dispatch(regionSuccess({ row }));
        dispatch(regionNewSuccess({ row: data.data }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setRegion =
  ({ row }) =>
    async (dispatch) => {
      dispatch(regionNewSuccess({ row }));
    };

export const getRegionByCountry =
  ({ countryIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-countrys?countryIds=" + countryIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((regionByCountry, index) => {
              let regionByCountryData = {
                index: index + 1,
                ...regionByCountry,
                id: regionByCountry.id === null ? "" : regionByCountry.id,
                name: regionByCountry.name === null ? "" : regionByCountry.name,
                shortName:
                  regionByCountry.shortName === null
                    ? ""
                    : regionByCountry.shortName,
                sortOrder:
                  regionByCountry.sortOrder === null
                    ? ""
                    : regionByCountry.sortOrder,
                countryId:
                  regionByCountry.name === null ? "" : regionByCountry.country.id,
                country:
                  regionByCountry.name === null
                    ? ""
                    : regionByCountry.country.name,
              };
              return regionByCountryData;
            });
            dispatch(regionByCountrySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };

export const getZoneByRegion =
  ({ regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + regionId + "/get-zone",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((zoneObject, index) => {
              let zoneData = {
                index: index + 1,
                id: zoneObject.id === null ? "" : zoneObject.id,
                name: zoneObject.name === null ? "" : zoneObject.name,
                zoneName: zoneObject.name + " ( " + zoneObject.shortName + " )",
                shortName:
                  zoneObject.shortName === null ? "" : zoneObject.shortName,
                sortOrder:
                  zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
              };
              return zoneData;
            });
            dispatch(zoneSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getZoneByRegionIds =
  ({ regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL1 + "/by-regions?regionIds=" + regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((zoneObject, index) => {
              let zoneData = {
                index: index + 1,
                id: zoneObject.id === null ? "" : zoneObject.id,
                name: zoneObject.name === null ? "" : zoneObject.name,
                zoneName: zoneObject.name + " ( " + zoneObject.shortName + " )",
                shortName:
                  zoneObject.shortName === null ? "" : zoneObject.shortName,
                sortOrder:
                  zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
              };
              return zoneData;
            });
            dispatch(zoneSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
