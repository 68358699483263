import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { getOutletAuditTransactionData } from "../../Slice/audit.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { cyanBlue } from "../../config/ColorObj";
import RemarkPopUp from "./RemarkPopUp";

class AuditDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            searchValue: "",
            dataList: [],
            openRemarkPopUp: false,
            remarkOnOptionClick: "",
            dataRow: "",
        };

    }

    async componentDidMount() {
        const { getOutletAuditTransactionData, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getOutletAuditTransactionData({
                    auditHeadId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        this.matrixData();
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    onBack = () => {
        this.props.navigate(-1);
    }





    matrixData = () => {
        const { auditList } = this.props;
        const { outlets, auditTransactions, auditQuestions } = auditList?.auditOutletTransaction;

        const matchedTransactions = outlets.map(outlet => {
            const matchedTransaction = auditTransactions.find(
                transaction => outlet.outletId === transaction.stockholderIdOutletId
            );

            if (matchedTransaction) {
                return {
                    ...matchedTransaction,
                    firmName: outlet.firmName,
                    ownerName: outlet.ownerName,
                    villageCity: outlet.villageCity,
                    beatName: outlet.beatName,
                    outletCode: outlet.outletCode,
                    stateName: outlet.stateName,
                    mobileNo: outlet.mobileNo,
                };
            }

            return null;
        }).filter(Boolean);


        const matchedQuestions = matchedTransactions.map(transaction => {
            const details = transaction.auditTransactionDetails.map(detail => {
                const matchedQuestion = auditQuestions.find(
                    question => question.auditQuestionId === detail.auditTransactionDetailsAuditQuestionId
                );

                if (matchedQuestion) {
                    let value = null;

                    let answerForMultiSelect = [];
                    if (detail.auditTransactionAuditQuestionOptionType == 2) {
                        const auditQuestionOptionValueList = detail.auditQuestionOptionValue.split(',');
                        answerForMultiSelect = matchedQuestion.auditQuestionOption
                            ?.filter(option => auditQuestionOptionValueList.includes(option.auditQuestionOptionId))
                            .map(option => option.auditQuestionOptionTitle) || [];
                    }

                    if (detail.auditTransactionAuditQuestionOptionType == 0) {
                        value = detail.auditQuestionOptionValue;
                    } else if (detail.auditTransactionAuditQuestionOptionType == 1) {
                        const matchedOption = matchedQuestion.auditQuestionOption.find(
                            option => option.auditQuestionOptionId === detail.auditQuestionOptionValue
                        );
                        value = matchedOption ? matchedOption.auditQuestionOptionTitle : "-";
                    } else if (detail.auditTransactionAuditQuestionOptionType == 2) {
                        value = answerForMultiSelect.length != 0 ? answerForMultiSelect.join(' , ') : "-";
                    }

                    return {
                        firmName: transaction.firmName,
                        ownerName: transaction.ownerName,
                        villageCity: transaction.villageCity,
                        beatName: transaction.beatName,
                        outletCode: transaction.outletCode,
                        stateName: transaction.stateName,
                        mobileNo: transaction.mobileNo,
                        remark: detail.remark || "-",
                        question: matchedQuestion.auditQuestion,
                        value: value,
                        questionId: matchedQuestion.auditQuestionId,
                    };
                }
                return null;
            }).filter(Boolean);

            console.log(details);

            return details;
        }).flat();

        console.log(matchedQuestions);

        this.setState({ dataList: matchedQuestions });
    };

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["firmName"] &&
                    currentRow["firmName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };


    outletInfoWithRemark = (auditQuestionsObj, rowData) => {
        console.log(rowData);

        this.setState({
            remarkOnOptionClick: rowData.remark[auditQuestionsObj.auditQuestionId],
            openRemarkPopUp: true,
            dataRow: rowData
        })
    }

    toggle = () => {
        this.setState({ openRemarkPopUp: false })
    }


    render() {
        const { auditList } = this.props;
        const { dataList, openRemarkPopUp, remarkOnOptionClick, dataRow } = this.state;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >


                    <DynamicFormWithoutSave
                        showBackToList={true}
                        showSaveBtn={auditJson.showSaveBtn}
                        showTitle={true}
                        screenTitle={"Audit Details"}
                        fieldMeta={[]}
                        showCancel={auditJson.showCancel}
                        apiBaseURL={auditJson.apiBaseURL}
                        showSaveNextBtn={auditJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />


                    {Object.keys(auditList?.auditOutletTransaction).length !== 0 && (
                        <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ minWidth: "70px" }}>Sr. No.</TableCell>
                                        <TableCell align="center" style={{ minWidth: "150px" }}>Firm Name</TableCell>
                                        {auditList?.auditOutletTransaction.auditQuestions.map((auditQuestionsObj) => (
                                            <TableCell key={auditQuestionsObj.auditQuestionId} align="center" style={{ minWidth: "150px" }}>
                                                {auditQuestionsObj.auditQuestion}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.getFilteredTableData(
                                        dataList.reduce((rows, dataObj) => {
                                            let row = rows.find(row => row.firmName === dataObj.firmName);

                                            if (!row) {
                                                row = {
                                                    firmName: dataObj.firmName,
                                                    ownerName: dataObj.ownerName,
                                                    beatName: dataObj.beatName,
                                                    outletCode: dataObj.outletCode,
                                                    villageCity: dataObj.villageCity,
                                                    stateName: dataObj.stateName,
                                                    mobileNo: dataObj.mobileNo,
                                                    values: {},
                                                    remark: {},
                                                };
                                                rows.push(row);
                                            }
                                            row.values[dataObj.questionId] = dataObj.value;
                                            row.remark[dataObj.questionId] = dataObj.remark;

                                            return rows;
                                        }, [])
                                    ).map((rowData, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell align="center">{rowIndex + 1}</TableCell>
                                            <TableCell align="left">{rowData.firmName}</TableCell>
                                            {auditList?.auditOutletTransaction.auditQuestions.map((auditQuestionsObj) => (
                                                <TableCell key={auditQuestionsObj.auditQuestionId}
                                                    align={rowData.values[auditQuestionsObj.auditQuestionId] ? "left" : "center"}
                                                >
                                                    {rowData.values[auditQuestionsObj.auditQuestionId] ? (
                                                        <div
                                                            onClick={() => this.outletInfoWithRemark(auditQuestionsObj, rowData)}
                                                            style={{
                                                                color: cyanBlue,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {rowData.values[auditQuestionsObj.auditQuestionId]}
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                    }

                    {openRemarkPopUp && (
                        <RemarkPopUp
                            open={openRemarkPopUp}
                            remarkOnOptionClick={remarkOnOptionClick}
                            dataRow={dataRow}
                            handleCloses={this.toggle}
                        />
                    )}


                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getOutletAuditTransactionData,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuditDetails);
