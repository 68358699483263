import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  beat: [],
  beatNew: [],
  beatIds: [],
  beatByUserType: [],
  beatFromSalesTeamId: [],
};
let URL = endpoints.beat;
const beatSlice = createSlice({
  name: "beat",
  initialState,
  reducers: {
    beatSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beat: row,
      };
    },

    beatNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatNew: row,
      };
    },

    beatIdsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatIds: row,
      };
    },

    beatFromSalesTeamSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatFromSalesTeamId: row,
      };
    },

    beatByUserTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatByUserType: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  beatSuccess,
  beatNewSuccess,
  beatByUserTypeSuccess,
  beatIdsSuccess,
  beatFromSalesTeamSuccess,
  resetState,
} = beatSlice.actions;

export default beatSlice.reducer;

export const getBeat = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrderNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((beatObject, index) => {
          let beatData = {
            index: index + 1,
            id: beatObject.id === null ? "" : beatObject.id,
            beatName: beatObject.beatName === null ? "" : beatObject.beatName,
            name: beatObject.beatName === null ? "" : beatObject.beatName,
            area:
              beatObject.area !== null && beatObject.area.id
                ? beatObject.area.id
                : "",
            areaName:
              beatObject.area !== null && beatObject.area.name
                ? beatObject.area.name
                : "",
            outletCount:
              beatObject.outletCount === null ? "" : beatObject.outletCount,
            sortOrderNo:
              beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
            isActive: beatObject.isActive === null ? "" : beatObject.isActive,
          };
          return beatData;
        });
        dispatch(beatSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBeatUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((beatObject, index) => {
          let beatData = {
            index: index + 1,
            id: beatObject.id === null ? "" : beatObject.id,
            beatName: beatObject.beatName === null ? "" : beatObject.beatName,
            name: beatObject.beatName === null ? "" : beatObject.beatName,
            area: beatObject.areaId !== null ? beatObject.areaId : "",
            areaName: beatObject.areaName !== null ? beatObject.areaName : "",
            outletCount:
              beatObject.outletCount === null ? "" : beatObject.outletCount,
            sortOrderNo:
              beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
            isActive: beatObject.isActive === null ? "" : beatObject.isActive,
          };
          return beatData;
        });
        dispatch(beatSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setBeat =
  ({ row }) =>
    async (dispatch) => {
      dispatch(beatNewSuccess({ row }));
    };

export const getBeatBySalesTeamId =
  ({ salesTeamId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL + "/with-outlet-count-by-sales-team?salesTeamId=" + salesTeamId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                beatId: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                noOfOutlet:
                  beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getBeatBySalesTeamIdAndLoginPersonAreas =
  ({ salesTeamId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL + "/with-outlet-count-by-sales-team-and-login-areas?salesTeamId=" + salesTeamId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                beatId: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                noOfOutlet:
                  beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };




export const getBeatBySalesTeamIdAndAreaId =
  ({ salesTeamId, areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL + "/with-outlet-count-by-sales-team-and-area?salesTeamId=" + salesTeamId + "&areaId=" + areaId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                beatId: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                noOfOutlet:
                  beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBeatBySalesTeamIdAreaId =
  ({ salesTeamId, areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/with-outlet-count-and-assigned-to-by-area?salesTeamId=" +
            salesTeamId +
            "&areaId=" +
            areaId,
        }).then(({ data, success }) => {
          if (success) {
            let selectedList = [];
            const row = data.data.map((beatObject, index) => {
              if (beatObject.flag == 0) {
                selectedList.push(beatObject.id);
              }
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                salesTeamName:
                  beatObject.salesTeamName === null
                    ? ""
                    : beatObject.salesTeamName,
                noOfOutlet:
                  beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
                flag: beatObject.flag === null ? "" : beatObject.flag,
                checked: beatObject.flag == 0 ? true : false,
                // disabled: beatObject.flag == 2 ? true : false,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success, selectedList };
          }
          return { response: [], success, selectedList: [] };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBeatBySalesTeamIdAreaIdUpdated =
  ({ salesTeamId, areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/with-outlet-count-and-assigned-to-by-area-new?salesTeamId=" +
            salesTeamId +
            "&areaId=" +
            areaId,
        }).then(({ data, success }) => {
          if (success) {
            let selectedList = [];
            const row = data.data.map((beatObject, index) => {
              if (beatObject.flag == 0) {
                selectedList.push(beatObject.id);
              }
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area: beatObject.areaId !== null ? beatObject.areaId : "",
                areaName: beatObject.areaName !== null ? beatObject.areaName : "",
                salesTeamName:
                  beatObject.salesTeamName === null
                    ? ""
                    : beatObject.salesTeamName,
                noOfOutlet:
                  beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
                flag: beatObject.flag === null ? "" : beatObject.flag,
                checked: beatObject.flag == 0 ? true : false,
                // disabled: beatObject.flag == 2 ? true : false,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success, selectedList };
          }
          return { response: [], success, selectedList: [] };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBeatBySalesTeamIdForAssign =
  ({ salesTeamId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/assign-to-sales-team?salesTeamId=" + salesTeamId,
        }).then(({ data, success }) => {
          if (success) {
            let selectedList = [];
            const row = data.data
              .filter((rowData) => rowData.flag != 2)
              .map((beatObject, index) => {
                if (beatObject.flag == 0 || beatObject.flag == 2) {
                  selectedList.push(beatObject.id);
                }
                let beatData = {
                  index: index + 1,
                  id: beatObject.id === null ? "" : beatObject.id,
                  beatName:
                    beatObject.beatName === null ? "" : beatObject.beatName,
                  name: beatObject.beatName === null ? "" : beatObject.beatName,
                  area:
                    beatObject.area !== null && beatObject.area.id
                      ? beatObject.area.id
                      : "",
                  areaName:
                    beatObject.area !== null && beatObject.area.name
                      ? beatObject.area.name
                      : "",
                  salesTeamName:
                    beatObject.salesTeamName === null
                      ? ""
                      : beatObject.salesTeamName,
                  noOfOutlet:
                    beatObject.noOfOutlet === null ? "" : beatObject.noOfOutlet,
                  sortOrderNo:
                    beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                  isActive:
                    beatObject.isActive === null ? "" : beatObject.isActive,
                  flag: beatObject.flag === null ? "" : beatObject.flag,
                  checked: beatObject.flag == 0 ? true : false,
                  disabled: beatObject.flag == 2 ? true : false,
                };
                return beatData;
              });
            dispatch(beatSuccess({ row }));
            return { response: row, success, selectedList };
          }
          return { response: [], success, selectedList: [] };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setBeatBySalesTeamIdAreaId =
  ({ row }) =>
    async (dispatch) => {
      dispatch(beatSuccess({ row }));
    };


// change /get-area mapping to /get-active-by-area by akash - 01-08-2024
export const getBeatByArea =
  ({ areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/get-active-by-area?areaId=" + areaId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                languageMarathi: "",
                languageHindi: "",
                languageEnglish: "",
                languageMarathiName: "Marathi",
                languageHindiName: "Hindi",
                languageEnglishName: "English",
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            dispatch(beatNewSuccess({ row: data.data }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getActiveBeatByArea =
  ({ areaId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/active-by-area?areaId=" + areaId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area: beatObject.areaId !== null ? beatObject.areaId : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                languageMarathi: "",
                languageHindi: "",
                languageEnglish: "",
                languageMarathiName: "Marathi",
                languageHindiName: "Hindi",
                languageEnglishName: "English",
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            dispatch(beatNewSuccess({ row: data.data }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getChangeActiveBeat =
  ({ row }) =>
    async (dispatch) => {
      console.log(row);
      dispatch(beatSuccess({ row }));
    };

export const getBeatsByAreaIds =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-areas?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                outletCount:
                  beatObject.outletCount === null ? "" : beatObject.outletCount,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getUpdatedBeatsByAreaIds =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          // http://localhost:8088/api/distribution/v1/beat/list-by-areas-new
          url: URL + "/list-by-areas-new",
          postBody: areaIds,

        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.areaId !== null
                    ? beatObject.areaId
                    : "",
                areaName:
                  beatObject.areaName !== null
                    ? beatObject.areaName
                    : "",
                outletCount:
                  beatObject.outletCount === null ? "" : beatObject.outletCount,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getBeatByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((beat, index) => {
          let beatByUserTypeData = {
            index: index + 1,
            id: beat.id === null ? "" : beat.id,
            beatName: beat.beatName === null ? "" : beat.beatName,
            name: beat.beatName === null ? "" : beat.beatName,
            area: beat.area !== null && beat.area.id ? beat.area.id : "",
            areaName:
              beat.area !== null && beat.area.name ? beat.area.name : "",
            outletCount: beat.outletCount === null ? "" : beat.outletCount,
            sortOrderNo: beat.sortOrderNo === null ? "" : beat.sortOrderNo,
            isActive: beat.isActive === null ? "" : beat.isActive,
          };
          return beatByUserTypeData;
        });
        dispatch(beatByUserTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUpdatedBeatByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({

      // http://localhost:8088/api/distribution/v1/beat/list-by-user-type

      url: URL + "/list-by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((beat, index) => {
          let beatByUserTypeData = {
            index: index + 1,
            id: beat.id === null ? "" : beat.id,
            beatName: beat.beatName === null ? "" : beat.beatName,
            name: beat.beatName === null ? "" : beat.beatName,
            area: beat.areaId !== null ? beat.areaId : "",
            areaName:
              beat.areaName !== null ? beat.areaName : "",
            outletCount: beat.outletCount === null ? "" : beat.outletCount,
            sortOrderNo: beat.sortOrderNo === null ? "" : beat.sortOrderNo,
            isActive: beat.isActive === null ? "" : beat.isActive,
          };
          return beatByUserTypeData;
        });
        dispatch(beatByUserTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBeatFromSalesTeamId =
  ({ salesTeamIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/todays-assign-by-sales-team?salesTeamId=" + salesTeamIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatData = {
                index: index + 1,
                beatId: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                outletCount:
                  beatObject.outletCount === null ? "" : beatObject.outletCount,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };
              return beatData;
            });
            dispatch(beatFromSalesTeamSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getBeatsByAreasPost =
  ({ beatDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/list-by-areas",
          postBody: beatDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatPostData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area:
                  beatObject.area !== null && beatObject.area.id
                    ? beatObject.area.id
                    : "",
                areaName:
                  beatObject.area !== null && beatObject.area.name
                    ? beatObject.area.name
                    : "",
                outletCount:
                  beatObject.outletCount === null ? "" : beatObject.outletCount,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };

              return beatPostData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getBeatsByAreasPostUpdated =
  ({ beatDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/list-by-areas-new",
          postBody: beatDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((beatObject, index) => {
              let beatPostData = {
                index: index + 1,
                id: beatObject.id === null ? "" : beatObject.id,
                beatName: beatObject.beatName === null ? "" : beatObject.beatName,
                name: beatObject.beatName === null ? "" : beatObject.beatName,
                area: beatObject.areaId !== null ? beatObject.areaId : "",
                areaName: beatObject.areaName !== null ? beatObject.areaName : "",
                outletCount:
                  beatObject.outletCount === null ? "" : beatObject.outletCount,
                sortOrderNo:
                  beatObject.sortOrderNo === null ? "" : beatObject.sortOrderNo,
                isActive: beatObject.isActive === null ? "" : beatObject.isActive,
              };

              return beatPostData;
            });
            dispatch(beatSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
