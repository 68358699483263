import React from "react";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../../config/endpoints";
import { stackHolderJson } from "../../../DynamicFormsJson/MastersJSON/StackHolder";
import {
  getStockholderByAreaIdsForFilterPost,
  getUpdatedStackHolder,
} from "../../../Slice/StackHolder.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { StakeHolderColumns } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiGet } from "../../../utils/api_service";
import { withRouter } from "../../../components/withRouter";
import {
  sendCredentialMsg,
  generateCredentialMsg,
  FailTogenerateCredentialMsg,
  serverMsg,
  noInternetMsg,
  changeActive,
  statusmsg,
} from "../../../config/messageconstant";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../../Slice/area.slice";
class StackHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
      },
    };
  }
  async componentDidMount() {
    const { showNotification } = this.props;
    if (navigator.onLine) {
      await this.getList();
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }
  getList = async () => {
    const { getUpdatedStackHolder, showLoador, showNotification, getRegionNew } =
      this.props;
    showLoador({ loador: true });
    await getRegionNew().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    showLoador({ loador: true });
    await getUpdatedStackHolder().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };
  onSearchData = async (data) => {
    const { getStockholderByAreaIdsForFilterPost, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      let stockHolderJson = {
        areaIds: data.areaIds,
      }
      showLoador({ loador: true });
      await getStockholderByAreaIdsForFilterPost({ areaIds: stockHolderJson }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onClickApply = (rowData) => {
    const { showLoador, showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: sendCredentialMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiGet({
            url: endpoint.stackholder + "/" + rowData.id + "/password-reset",
          }).then(({ success }) => {
            if (success) {
              showLoador({ loador: false });
              showNotification({
                msg: generateCredentialMsg,
              });
            } else {
              showNotification({
                msg: FailTogenerateCredentialMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBodyUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsJson = {
          talukaIds: data.taluka
        }
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onChangeStatus = (rowData) => {
    console.log(rowData);
    if (rowData.isActive == 1) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.stackholder +
            "/make-active-inactive?stockStakeHolderId=" +
            rowData.id +
            "&areaId=" +
            "&flag=0",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: statusmsg });
            this.getList();
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      this.props.navigate("/stackholder-change-status/" + rowData.id);
    }
  };

  onClickTransfer = (rowData) => {
    const { showLoador, showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: "Do You Want To Active Status For Stock Holder ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  rowEdit = (rowData) => {
    this.props.navigate("/stock-holder-edit/" + rowData.id);
  };

  render() {
    const { stackHolderByAreaList } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          formPath={stackHolderJson.formPath}
          // formPathEdit={stackHolderJson.formPathEdit}
          getListById={this.getListById}
          onSearchData={this.onSearchData}
          searchList={stackHolderJson.searchList}
          screenTitle={stackHolderJson.screenTitle}
          fieldMeta={stackHolderJson.fieldMeta}
          showPdfDownload={false}
          changeExcelURL={stackHolderJson.excelUrl}
          showExtension={false}
          pdfFileName={stackHolderJson.pdfFileName}
          excelFileName={stackHolderJson.excelFileName}
          showAddButton={stackHolderJson.showAddButton}
          showDeleteIcon={stackHolderJson.showDeleteIcon}
          tableColumnsToFilter={stackHolderJson.tableColumnsToFilter}
          tableColumns={StakeHolderColumns}
          tableData={stackHolderByAreaList?.stackholder}
          getTableDataById={true}
          apiBaseURL={stackHolderJson.apiBaseURL}
          callApi={false}
          showAddButtonInTable={true}
          showAddTitle={"Add Document"}
          onClickAdd={this.onClickAdd}
          openFormPath={stackHolderJson.openFormPath}
          searchButtonGrid={stackHolderJson.searchButtonGrid}
          showApply={true}
          rowApply={this.onClickApply}
          isNote={true}
          isNoteValue={"Add Stock Holder & Details"}
          getTableData={this.getList}
          //  rowTransfer={this.onClickTransfer}
          isActiveURLFalse={true}
          isActiveURLFalseMethod={this.onChangeStatus}
          showTransferButtonInTable={true}
          onClickTransfer={this.onClickTransfer}
          openFormPathStatus={stackHolderJson.openFormPathStatus}
          rowEdit={this.rowEdit}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderByAreaList: state.stockholder,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getZoneByState,
  getRegionNew,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  getStockholderByAreaIdsForFilterPost,
  getStateByRegion,
  getUpdatedStackHolder,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StackHolder);
