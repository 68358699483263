import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { encodeData } from "../../components/Comman/Util/Base64";
import swal from "sweetalert";
import FormHelperText from "@mui/material/FormHelperText";
import { login, logOut } from "./login.slice";
import { Loading } from "../../components/Loader";
import { getAccessRoleForLeft } from "../../Slice/stockStakeHolderType.slice";
import { getNoticeMsgByUserType } from "../../Slice/noticeMsg.slice";
import { getSetting } from "../../Slice/setting.slice";
import { showNotification, showLoador } from "../Landing/Landing.slice";
import {
  alreadyLoggedIn,
  authenticationMsg,
  noInternetMsg,
  serverMsg,
  sessionMsg,
} from "../../config/messageconstant";
import { white, DimGrayColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { apiGet, apiPostWithoutHeader } from "../../utils/api_service";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import { getBeatByUserType } from "../../Slice/beat.slice";
const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  top: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  bottom: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  ellipse: {
    // position: "static",
    // width: "517px",
    // height: "350px",
    // left: "10px",
    // top: "10px",

    // background: "rgba(41, 151, 171, 0.2)",
    // flex: "none",
    // order: "0",
    // flexgrow: "0",
    justifyContent: "center",
  },
  loginBox: {
    display: "flex",
    border: `1px solid ${theme.palette.grey[400]}`,
    margin: "50px",
    width: "auto",
    zIndex: "1",
    backgroundColor: "white",
  },
  text: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "bold",
    fontsize: "32px",
    textAlign: "center",

    color: white,
  },
  bmn: {
    padding: "30px",
    justifyContent: "center",

    // alignSelf:'center',
  },
  left: {
    //  width: "50%",
  },
  hello: {
    textAlign: "center",
    top: "60px",
    left: "50px",
  },
  helper: {
    textAlign: "center",
    height: "56px",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "18px",
    lineheight: "28px",
    color: DimGrayColor,
  },
  field: {
    padding: "10px 50px",
    margin: "50px",
  },
  stay: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "14px",
    lineheight: "28px",
    paddingLeft: "20px",

    color: DimGrayColor,
  },
  ellipse2: {
    width: "504px",
    height: "504px",
    left: "-139px",
    top: "500px",

    background: white,
    border: "80px solid #2997AB",
    boxsizing: "border-box",
  },
  fogot: {
    left: "500px",
  },
  body: { backgroundColor: "#f5f5f5" },
});

const initState = { name: "", password: "" };

const Login = ({
  login,
  logOut,
  showLoador,
  showNotification,
  getAccessRoleForLeft,
  getNoticeMsgByUserType,
  getSetting,
  getActiveLanguage,
  getBeatByUserType,
}) => {
  const [auth, setAuth] = useState(initState);
  const [nameErr, setNameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [sessionExpire, showSessionExpire] = useState(true);

  useEffect(() => {
    const name = localStorage.getItem("token");
    const loginCheck = localStorage.getItem("loginCheck");
    if (name !== "" && name !== null) {
      window.location.replace("/welcome-page");
    }
    if (loginCheck !== null && loginCheck !== "" && loginCheck == "0") {
      showSessionError();
    }
  }, []);
  const showSessionError = () => {
    swal({
      title: sessionMsg,
      text: "You are Log out because your account is Logged in from another device",
      icon: "error",
      timer: 10000,
      showCancelButton: false,
      showConfirmButton: false,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.clear();
      }
      localStorage.clear();
    });
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const Login = () => {
    if (navigator.onLine) {
      if (auth.name !== "" && auth.password !== "") {
        showLoador({ loador: true });
        apiPostWithoutHeader({
          url:
            endpoint.webLogin +
            "?username=" +
            auth.name +
            "&password=" +
            auth.password,
        }).then(({ data, success }) => {
          console.log(data.errorMessage != "Exist");
          if (data.errorMessage != "Exist") {
            setPasswordErr("");
            setNameErr("");
            setLoginErr("");
            login({ username: auth.name, password: auth.password }).then(
              ({ success }) => {
                if (success) {
                  apiGet({
                    url: endpoint.userType + "/by-session-user",
                  }).then(({ data, success }) => {
                    if (success) {
                      localStorage.setItem("userTypeId", data.data.id);
                    } else {
                      showNotification({
                        msg: serverMsg,
                        severity: "error",
                      });
                    }
                  });
                  getNoticeMsgByUserType();
                  getShowPdf();
                  getActiveLanguage().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                      showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                      localStorage.setItem(
                        "activeLanguageList",
                        response.length == 0 ? "" : JSON.stringify(response)
                      );
                    }
                  });
                  getSetting().then(({ response, success }) => {
                    if (!success) {
                      showLoador({ loador: false });
                      showNotification({
                        msg: serverMsg,
                        severity: "error",
                      });
                    } else {
                      showLoador({ loador: true });
                      let isMaintainanceList = response.filter(
                        (row) => row.settingKey == "is_maintainance"
                      );

                      if (isMaintainanceList[0].settingValue == "1") {
                        window.location.replace("/maintainance");
                      } else {
                        let isFreezTargetList = response.filter(
                          (row) => row.settingKey == "is_freez_target"
                        );
                        localStorage.setItem(
                          "isFreezTarget",
                          isFreezTargetList.length == 0
                            ? ""
                            : isFreezTargetList[0].settingValue
                        );

                        let isQtyInBatchwiseList = response.filter(
                          (row) => row.settingKey == "is_qty_in_batchwise"
                        );
                        localStorage.setItem(
                          "isQtyInBatchWise",
                          isQtyInBatchwiseList.length == 0
                            ? ""
                            : isQtyInBatchwiseList[0].settingValue
                        );

                        let isClosingQtyClickableList = response.filter(
                          (row) => row.settingKey == "is_closing_qty_clickable"
                        );
                        localStorage.setItem(
                          "isClosingQtyClickable",
                          isClosingQtyClickableList.length == 0
                            ? ""
                            : isClosingQtyClickableList[0].settingValue
                        );

                        let showCompanyFlagList = response.filter(
                          (row) => row.settingKey == "show_excel_reports"
                        );
                        localStorage.setItem(
                          "showCompanyFlag",
                          showCompanyFlagList.length == 0
                            ? ""
                            : showCompanyFlagList[0].settingValue
                        );

                        let stockCalAtBillLis = response.filter(
                          (row) => row.settingKey == "stock_cal_at_bill"
                        );
                        localStorage.setItem(
                          "stockCalAtBill",
                          stockCalAtBillLis.length == 0
                            ? ""
                            : stockCalAtBillLis[0].settingValue
                        );

                        let lostOrderFromBillingList = response.filter(
                          (row) => row.settingKey == "lost_order_from_billing"
                        );
                        localStorage.setItem(
                          "lostOrderFromBilling",
                          lostOrderFromBillingList.length == 0
                            ? ""
                            : lostOrderFromBillingList[0].settingValue
                        );

                        let showFreeQtyPercetageList = response.filter(
                          (row) => row.settingKey == "show_free_qty_percentage"
                        );
                        localStorage.setItem(
                          "showFreeQtyPercetage",
                          showFreeQtyPercetageList.length == 0
                            ? ""
                            : showFreeQtyPercetageList[0].settingValue
                        );

                        let transporterInsertedList = response.filter(
                          (row) => row.settingKey == "transporter_inserted"
                        );
                        localStorage.setItem(
                          "transporterInserted",
                          transporterInsertedList.length == 0
                            ? ""
                            : transporterInsertedList[0].settingValue
                        );

                        let orderWithBillingForAdminList = response.filter(
                          (row) =>
                            row.settingKey == "order_with_billing_for_admin"
                        );
                        localStorage.setItem(
                          "orderWithBillingForAdmin",
                          orderWithBillingForAdminList.length == 0
                            ? ""
                            : orderWithBillingForAdminList[0].settingValue
                        );

                        let manualOrderWithBillingForPrimaryList =
                          response.filter(
                            (row) =>
                              row.settingKey ==
                              "manual_order_with_billing_for_primary"
                          );
                        localStorage.setItem(
                          "manualOrderWithBillingForPrimary",
                          manualOrderWithBillingForPrimaryList.length == 0
                            ? ""
                            : manualOrderWithBillingForPrimaryList[0]
                              .settingValue
                        );

                        let attendanceTargetAchievementFlagList =
                          response.filter(
                            (row) =>
                              row.settingKey ==
                              "show_attendance_target_achievement"
                          );
                        localStorage.setItem(
                          "showAttendanceTargetAchievement",
                          attendanceTargetAchievementFlagList.length == 0
                            ? ""
                            : attendanceTargetAchievementFlagList[0]
                              .settingValue
                        );

                        let salesTeamCompleteHierarchyList = response.filter(
                          (row) =>
                            row.settingKey ==
                            "show_complete_hierarchy_sales_team"
                        );
                        localStorage.setItem(
                          "showCompleteHierarchyToSalesTeam",
                          salesTeamCompleteHierarchyList.length == 0
                            ? ""
                            : salesTeamCompleteHierarchyList[0].settingValue
                        );

                        let staticDeleteToAreaList = response.filter(
                          (row) =>
                            row.settingKey == "show_static_delete_to_area"
                        );
                        localStorage.setItem(
                          "showStaticDeleteToArea",
                          staticDeleteToAreaList.length == 0
                            ? ""
                            : staticDeleteToAreaList[0].settingValue
                        );

                        let staticDeleteGroupProductList = response.filter(
                          (row) =>
                            row.settingKey ==
                            "show_static_delete_to_group_product"
                        );
                        localStorage.setItem(
                          "showStaticDeleteToGroupProduct",
                          staticDeleteGroupProductList.length == 0
                            ? ""
                            : staticDeleteGroupProductList[0].settingValue
                        );

                        let packagingTypeAmountsList = response.filter(
                          (row) =>
                            row.settingKey == "show_packaging_type_amounts"
                        );
                        localStorage.setItem(
                          "showPackagingTypeAmounts",
                          packagingTypeAmountsList.length == 0
                            ? ""
                            : packagingTypeAmountsList[0].settingValue
                        );

                        let checkInImageFlagList = response.filter(
                          (row) =>
                            row.settingKey == "is_check_in_image_mandatory"
                        );
                        localStorage.setItem(
                          "checkInImage",
                          checkInImageFlagList.length == 0
                            ? ""
                            : checkInImageFlagList[0].settingValue
                        );

                        let zeroOrderImageFlagList = response.filter(
                          (row) => row.settingKey == "is_add_zero_order_image"
                        );
                        localStorage.setItem(
                          "zeroOrderImage",
                          zeroOrderImageFlagList.length == 0
                            ? ""
                            : zeroOrderImageFlagList[0].settingValue
                        );

                        let timelineManagerLogsWiseList = response.filter(
                          (row) => row.settingKey == "show_timeline_manager_logs_wise"
                        );
                        localStorage.setItem(
                          "showTimelineManagerLogsWise",
                          timelineManagerLogsWiseList.length == 0
                            ? ""
                            : timelineManagerLogsWiseList[0].settingValue
                        );

                        let orderConfigurationTimeList = response.filter(
                          (row) => row.settingKey == "show_order_config_time"
                        );
                        localStorage.setItem(
                          "showOrderConfigurationByTime",
                          orderConfigurationTimeList.length == 0
                            ? ""
                            : orderConfigurationTimeList[0].settingValue
                        );


                        let showPastOrderInTimelineList = response.filter(
                          (row) => row.settingKey == "show_past_order_in_timeline"
                        );
                        localStorage.setItem(
                          "showPastOrderInTimeline",
                          showPastOrderInTimelineList.length == 0
                            ? ""
                            : showPastOrderInTimelineList[0].settingValue
                        );

                        let secondaryQtyWiseStockList = response.filter(
                          (row) => row.settingKey == "secondary_qty_wise_stock"
                        );
                        localStorage.setItem(
                          "secondaryQtyWiseStock",
                          secondaryQtyWiseStockList.length == 0
                            ? ""
                            : secondaryQtyWiseStockList[0].settingValue
                        );

                        getAccessRoleForLeft({ user: auth.name }).then(
                          ({ response, success }) => {
                            if (success) {
                              localStorage.setItem("userId", response.id);
                              apiGet({
                                url:
                                  endpoint.company +
                                  "/with-sessioned-person-info",
                              }).then(({ data, success }) => {
                                if (success) {
                                  if (
                                    data !== null &&
                                    data.data !== null &&
                                    !data.data.error &&
                                    data.data.isActiveFlag == 0
                                  ) {
                                    showNotification({
                                      msg: authenticationMsg,
                                      severity: "error",
                                    });
                                    localStorage.clear();
                                  } else {
                                    if (
                                      data !== null &&
                                      data.data !== null &&
                                      !data.data.errorMessage.error
                                    ) {
                                      localStorage.setItem(
                                        "firmName",
                                        data.data.name
                                      );
                                      localStorage.setItem(
                                        "compName",
                                        data.data.company.name
                                      );
                                      localStorage.setItem(
                                        "distributorName",
                                        data.data.name
                                      );
                                      localStorage.setItem(
                                        "compName",
                                        data.data.company.name
                                      );
                                      localStorage.setItem(
                                        "complogo",
                                        data.data.company.logo
                                      );
                                      localStorage.setItem(
                                        "upi",
                                        data.data.upi
                                      );
                                      localStorage.setItem(
                                        "compId",
                                        data.data.company.id
                                      );
                                      localStorage.setItem(
                                        "districtName",
                                        data.data.district === null
                                          ? ""
                                          : data.data.district.name
                                      );
                                    }

                                    if (
                                      response.stockStakeHolderType.userType
                                        .id == 1 ||
                                      response.stockStakeHolderType.userType
                                        .id == 6
                                    ) {
                                      window.location.replace("/welcome-page");
                                    } else if (
                                      response.stockStakeHolderType.userType
                                        .id == 3
                                    ) {
                                      window.location.replace("/welcome-page");
                                    } else if (
                                      response.stockStakeHolderType.userType
                                        .id == 2
                                    ) {
                                      getBeatByUserType().then(
                                        ({ response, success }) => {
                                          if (!success) {
                                            showNotification({
                                              msg: serverMsg,
                                              severity: "error",
                                            });
                                          } else {
                                            console.log(response);
                                            localStorage.setItem(
                                              "activeBeatList",
                                              response.length == 0
                                                ? ""
                                                : JSON.stringify(response)
                                            );
                                            window.location.replace(
                                              "/welcome-page"
                                            );
                                          }
                                        }
                                      );
                                    } else {
                                      window.location.replace("/welcome-page");
                                    }
                                  }
                                } else {
                                  showLoador({ loador: false });
                                }
                              });
                            } else {
                              showLoador({ loador: false });
                            }
                            return { response, success };
                          }
                        );
                      }
                    }
                  });
                } else {
                  showLoador({ loador: false });
                  setLoginErr("Invalid Credential");
                }
              }
            );
          } else {
            setShowLoading(false);
            swal({
              title: alreadyLoggedIn,
              text: "Do you want to log out other devices ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
              buttons: ["Cancel", "Confirm"],
            }).then((willDelete) => {
              if (willDelete) {
                setPasswordErr("");
                setNameErr("");
                setLoginErr("");
                showLoador({ loador: true });
                login({ username: auth.name, password: auth.password }).then(
                  ({ success }) => {
                    if (success) {
                      apiGet({
                        url: endpoint.userType + "/by-session-user",
                      }).then(({ data, success }) => {
                        if (success) {
                          localStorage.setItem("userTypeId", data.data.id);
                        } else {
                          showNotification({
                            msg: serverMsg,
                            severity: "error",
                          });
                        }
                      });
                      getNoticeMsgByUserType();
                      getShowPdf();
                      getActiveLanguage().then(({ response, success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                          showNotification({
                            msg: serverMsg,
                            severity: "error",
                          });
                        } else {
                          localStorage.setItem(
                            "activeLanguageList",
                            response.length == 0 ? "" : JSON.stringify(response)
                          );
                        }
                      });
                      getSetting().then(({ response, success }) => {
                        if (!success) {
                          showLoador({ loador: false });
                          showNotification({
                            msg: serverMsg,
                            severity: "error",
                          });
                        } else {
                          showLoador({ loador: true });
                          let isMaintainanceList = response.filter(
                            (row) => row.settingKey == "is_maintainance"
                          );

                          if (isMaintainanceList[0].settingValue == "1") {
                            window.location.replace("/maintainance");
                          } else {
                            let isFreezTargetList = response.filter(
                              (row) => row.settingKey == "is_freez_target"
                            );
                            localStorage.setItem(
                              "isFreezTarget",
                              isFreezTargetList.length == 0
                                ? ""
                                : isFreezTargetList[0].settingValue
                            );

                            let isQtyInBatchwiseList = response.filter(
                              (row) => row.settingKey == "is_qty_in_batchwise"
                            );
                            localStorage.setItem(
                              "isQtyInBatchWise",
                              isQtyInBatchwiseList.length == 0
                                ? ""
                                : isQtyInBatchwiseList[0].settingValue
                            );

                            let isClosingQtyClickableList = response.filter(
                              (row) =>
                                row.settingKey == "is_closing_qty_clickable"
                            );
                            localStorage.setItem(
                              "isClosingQtyClickable",
                              isClosingQtyClickableList.length == 0
                                ? ""
                                : isClosingQtyClickableList[0].settingValue
                            );

                            let showCompanyFlagList = response.filter(
                              (row) => row.settingKey == "show_excel_reports"
                            );
                            localStorage.setItem(
                              "showCompanyFlag",
                              showCompanyFlagList.length == 0
                                ? ""
                                : showCompanyFlagList[0].settingValue
                            );

                            let stockCalAtBillLis = response.filter(
                              (row) => row.settingKey == "stock_cal_at_bill"
                            );
                            localStorage.setItem(
                              "stockCalAtBill",
                              stockCalAtBillLis.length == 0
                                ? ""
                                : stockCalAtBillLis[0].settingValue
                            );

                            let lostOrderFromBillingList = response.filter(
                              (row) =>
                                row.settingKey == "lost_order_from_billing"
                            );
                            localStorage.setItem(
                              "lostOrderFromBilling",
                              lostOrderFromBillingList.length == 0
                                ? ""
                                : lostOrderFromBillingList[0].settingValue
                            );

                            let showFreeQtyPercetageList = response.filter(
                              (row) =>
                                row.settingKey == "show_free_qty_percentage"
                            );
                            localStorage.setItem(
                              "showFreeQtyPercetage",
                              showFreeQtyPercetageList.length == 0
                                ? ""
                                : showFreeQtyPercetageList[0].settingValue
                            );

                            let transporterInsertedList = response.filter(
                              (row) => row.settingKey == "transporter_inserted"
                            );
                            localStorage.setItem(
                              "transporterInserted",
                              transporterInsertedList.length == 0
                                ? ""
                                : transporterInsertedList[0].settingValue
                            );

                            let orderWithBillingForAdminList = response.filter(
                              (row) =>
                                row.settingKey == "order_with_billing_for_admin"
                            );
                            localStorage.setItem(
                              "orderWithBillingForAdmin",
                              orderWithBillingForAdminList.length == 0
                                ? ""
                                : orderWithBillingForAdminList[0].settingValue
                            );

                            let manualOrderWithBillingForPrimaryList =
                              response.filter(
                                (row) =>
                                  row.settingKey ==
                                  "manual_order_with_billing_for_primary"
                              );
                            localStorage.setItem(
                              "manualOrderWithBillingForPrimary",
                              manualOrderWithBillingForPrimaryList.length == 0
                                ? ""
                                : manualOrderWithBillingForPrimaryList[0]
                                  .settingValue
                            );

                            let secondaryQtyWiseStockList = response.filter(
                              (row) =>
                                row.settingKey == "secondary_qty_wise_stock"
                            );
                            localStorage.setItem(
                              "secondaryQtyWiseStock",
                              secondaryQtyWiseStockList.length == 0
                                ? ""
                                : secondaryQtyWiseStockList[0].settingValue
                            );

                            getAccessRoleForLeft({ user: auth.name }).then(
                              ({ response, success }) => {
                                if (success) {
                                  localStorage.setItem("userId", response.id);
                                  apiGet({
                                    url:
                                      endpoint.company +
                                      "/with-sessioned-person-info",
                                  }).then(({ data, success }) => {
                                    if (success) {
                                      if (
                                        data !== null &&
                                        data.data !== null &&
                                        !data.data.error &&
                                        data.data.isActiveFlag == 0
                                      ) {
                                        showNotification({
                                          msg: authenticationMsg,
                                          severity: "error",
                                        });
                                        localStorage.clear();
                                      } else {
                                        if (
                                          data !== null &&
                                          data.data !== null &&
                                          !data.data.errorMessage.error
                                        ) {
                                          localStorage.setItem(
                                            "firmName",
                                            data.data.name
                                          );
                                          localStorage.setItem(
                                            "compName",
                                            data.data.company.name
                                          );
                                          localStorage.setItem(
                                            "distributorName",
                                            data.data.name
                                          );
                                          localStorage.setItem(
                                            "compName",
                                            data.data.company.name
                                          );
                                          localStorage.setItem(
                                            "complogo",
                                            data.data.company.logo
                                          );
                                          localStorage.setItem(
                                            "upi",
                                            data.data.upi
                                          );
                                          localStorage.setItem(
                                            "compId",
                                            data.data.company.id
                                          );
                                          localStorage.setItem(
                                            "districtName",
                                            data.data.district === null
                                              ? ""
                                              : data.data.district.name
                                          );
                                        }

                                        if (
                                          response.stockStakeHolderType.userType
                                            .id == 1 ||
                                          response.stockStakeHolderType.userType
                                            .id == 6
                                        ) {
                                          window.location.replace(
                                            "/welcome-page"
                                          );
                                        } else if (
                                          response.stockStakeHolderType.userType
                                            .id == 3
                                        ) {
                                          window.location.replace(
                                            "/welcome-page"
                                          );
                                        } else if (
                                          response.stockStakeHolderType.userType
                                            .id == 2
                                        ) {
                                          getBeatByUserType().then(
                                            ({ response, success }) => {
                                              if (!success) {
                                                showNotification({
                                                  msg: serverMsg,
                                                  severity: "error",
                                                });
                                              } else {
                                                console.log(response);
                                                localStorage.setItem(
                                                  "activeBeatList",
                                                  response.length == 0
                                                    ? ""
                                                    : JSON.stringify(response)
                                                );
                                                window.location.replace(
                                                  "/welcome-page"
                                                );
                                              }
                                            }
                                          );
                                        } else {
                                          window.location.replace(
                                            "/welcome-page"
                                          );
                                        }
                                      }
                                    } else {
                                      showLoador({ loador: false });
                                    }
                                  });
                                } else {
                                  showLoador({ loador: false });
                                }
                                return { response, success };
                              }
                            );
                          }
                        }
                      });
                    } else {
                      showLoador({ loador: false });
                      setLoginErr("Invalid Credential");
                    }
                  }
                );
              } else {
                showLoador({ loador: false });
              }
            });
          }
        });
      } else {
        if (auth.name === "" && auth.password === "") {
          setNameErr("Username required");
          setPasswordErr("Password required");
        } else if (auth.name === "") {
          setNameErr("Username required");
          setPasswordErr("");
        } else if (auth.password === "") {
          setPasswordErr("Password required");
          setNameErr("");
        } else {
          setPasswordErr("");
          setNameErr("");
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const getShowPdf = () => {
    showLoador({ loador: true });
    apiGet({
      url: endpoint.setting + "/by-key?key=show_pdf",
    }).then((data) => {
      showLoador({ loador: false });
      if (data.data.error) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (data != null && Object.keys(data.data.data).length != 0) {
          if (data.data.data.settingValue) {
            localStorage.setItem("showPdf", data.data.data.settingValue);
          }
        }
      }
    });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth({
      ...auth,
      [name]: value,
    });
  };

  const onApiCalled = (e) => {
    //href="forgot-password-pre-login"
  };

  return (
    <>
      <div className="new_login_content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div className="new_logo_content"></div>
          <Grid className="new_title_content">
            Distribution Management System
          </Grid>
        </div>

        <div
          className="new_login_box3"
        // style={{
        //   marginLeft: "15%",
        //   marginRight: "15%",
        // }}
        >
          <div className="new_login_logo"></div>
          <div className="new_login_box">
            <div>
              <div className="new_login_welcome">Welcome</div>
            </div>
            <div style={{ alignItems: "flex-between" }}>
              <div className="user_lgn_new">
                <div className="new_user_lable">Username</div>
                <TextField
                  color="primary"
                  name="name"
                  size="small"
                  value={auth.name}
                  onChange={onChange}
                  onKeyPress={(e) => e.key === "Enter" && Login()}
                  fullWidth
                  helperText={nameErr ? nameErr : " "}
                  error={nameErr ? true : false}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #DEDEDE",
                    background: "#ECECEC",
                    width: "360px",
                    height: "41px",
                    flexShrink: "0",
                    marginBottom: "20px",
                    // borderRadius: "17px 17px 17px 17px",
                  }}
                />
                <div className="new_user_lable">Password</div>
                <OutlinedInput
                  color="primary"
                  name="password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        width: "18px",
                        height: "18px",
                        flexShrink: "0",
                        marginRight: "12px",
                      }}
                    >
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={auth.password}
                  onChange={onChange}
                  fullWidth
                  onKeyPress={(e) => e.key === "Enter" && Login()}
                  error={passwordErr ? true : false}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #DEDEDE",
                    background: "#ECECEC",
                    width: "360px",
                    height: "43px",
                    flexShrink: "0",

                    // borderRadius: "17px 17px 17px 17px",
                  }}
                />
                <FormHelperText error>
                  &nbsp;&nbsp;
                  {loginErr !== "" ? loginErr : " "}
                </FormHelperText>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div style={{ alignItems: "flex-between" }}>
                <button
                  type="button"
                  onClick={Login}
                  onKeyPress={(e) => e.key === "Enter" && Login()}
                  className="new_login_btn"
                  style={{
                    color: "#FFF",
                    /* button text poppins */
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  Login
                </button>
              </div>

              <div className="new_forgot_pswd" onClick={onApiCalled}>
                <a href="/forgotPassword" style={{ color: "#004969" }}>
                  Forgot Password
                </a>
              </div>
            </div>
            <div className="main_footer"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div className="new_footer">
                Powered by <div className="new_footer_logo"></div>
              </div>
              <div className="new_footer_link">
                <a
                  href="https://www.byteelephants.com/"
                  style={{ color: "#004969" }}
                >
                  BytesElephants.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoading && <Loading />}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state,
  common: state.common,
});

const mapDispatchToProps = {
  login,
  getAccessRoleForLeft,
  getNoticeMsgByUserType,
  logOut,
  showNotification,
  showLoador,
  getSetting,
  getActiveLanguage,
  getBeatByUserType,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));

{
  /* <>
      <div align="center">
        <div className="new_content login">
          <div className="dash_login">
            <Grid item container justifyContent="space-between">
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <div className="lgn_bx">
                  <h3 className="lgn_title">Login Form</h3>

                  <div className="lgn_container">
                    <div className="user_lgn_new">
                      <div className="user_label">Username</div>
                      <div className="user_field">
                        <TextField
                          color="primary"
                          name="name"
                          size="small"
                          value={auth.name}
                          onChange={onChange}
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          fullWidth
                          helperText={nameErr ? nameErr : " "}
                          error={nameErr ? true : false}
                        />
                      </div>
                    </div>

                    <div className="user_lgn_new">
                      <div className="user_label">Password</div>
                      <div className="user_field">
                        <OutlinedInput
                          color="primary"
                          name="password"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={auth.password}
                          onChange={onChange}
                          fullWidth
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          error={passwordErr ? true : false}
                        />
                        <FormHelperText error>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {passwordErr !== "" ? passwordErr : " "}
                        </FormHelperText>
                      </div>
                    </div>

                    <div style={{ marginTop: 20 }} className="lgn_btn">
                      <button
                        type="button"
                        onClick={Login}
                        onKeyPress={(e) => e.key === "Enter" && Login()}
                        className="register_btn"
                      >
                        Login
                      </button>
                    </div>
                    <FormHelperText error>
                      &nbsp;&nbsp;
                      {loginErr !== "" ? loginErr : " "}
                    </FormHelperText>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="user_label" onClick={onApiCalled}>
                        <a href="/forgotPassword">Forgot Password</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </div>
        </div>
      </div>
      {showLoading && <Loading />} */
}
