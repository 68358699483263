import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  outlet: [],
  outlets: [],
  outletByUserType: [],
  outletInactive: [],
  outletByBeatUpdated: [],
  outletListByBeats: [],
  outletsNew: [],
};
let URL = endpoints.outlet;
const outletSlice = createSlice({
  name: "outlet",
  initialState,
  reducers: {
    outletSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outlet: row,
        isFetch: true,
      };
    },
    outletsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outlets: row,
        isFetch: true,
      };
    },

    outletByUserTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletByUserType: row,
      };
    },

    outletsNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletsNew: row,
      };
    },

    outletInactiveSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletInactive: row,
      };
    },

    outletUpdatedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletByBeatUpdated: row,
      };
    },
    outletListByBeatsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletListByBeats: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  outletSuccess,
  outletsSuccess,
  outletsNewSuccess,
  outletByUserTypeSuccess,
  outletInactiveSuccess,
  outletUpdatedSuccess,
  outletListByBeatsSuccess,
  resetState,
} = outletSlice.actions;

export default outletSlice.reducer;

export const getOutlet = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((outletObject, index) => {
          let outletData = {
            index: index + 1,
            id: outletObject.id === null ? "" : outletObject.id,
            firmName:
              outletObject.firmName === null ? "" : outletObject.firmName,
            ownerName:
              outletObject.ownerName === null ? "" : outletObject.ownerName,
            state:
              outletObject.state !== null && outletObject.state.id
                ? outletObject.state.id
                : "",
            stateName:
              outletObject.state !== null && outletObject.state.name
                ? outletObject.state.name
                : "",
            address: outletObject.address === null ? "" : outletObject.address,
            latitude:
              outletObject.latitude === null ? "" : outletObject.latitude,
            longitude:
              outletObject.longitude === null ? "" : outletObject.longitude,
            mobileNo:
              outletObject.mobileNo === null ? "" : outletObject.mobileNo,
            emailId: outletObject.emailId === null ? "" : outletObject.emailId,
            status: outletObject.status === null ? "" : outletObject.status,
            beatName:
              outletObject.beat === null && outletObject.beat.beatName === null
                ? ""
                : outletObject.beat.beatName,
            beat:
              outletObject.beat === null && outletObject.beat.id === null
                ? ""
                : outletObject.beat.id,
            outletTypeName:
              outletObject.outLetType === null &&
                outletObject.outLetType.name === null
                ? ""
                : outletObject.outLetType.name,
            outLetType:
              outletObject.outLetType === null &&
                outletObject.outLetType.id === null
                ? ""
                : outletObject.outLetType.id,
            isActive:
              outletObject.isActive === null ? "" : outletObject.isActive,
          };

          return outletData;
        });
        dispatch(outletSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOutletByBeatId =
  ({ beatId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-beat?beatId=" + beatId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletObject, index) => {
              let outletData = {
                index: index + 1,
                id: outletObject.id === null ? "" : outletObject.id,
                firmName:
                  outletObject.firmName === null ? "" : outletObject.firmName,
                name:
                  outletObject.firmName === null
                    ? ""
                    : outletObject.firmName + " - " + outletObject.ownerName,
                ownerName:
                  outletObject.ownerName === null ? "" : outletObject.ownerName,
                areaName:
                  outletObject.beat !== null &&
                    outletObject.beat.area !== null &&
                    outletObject.beat.area.name !== null
                    ? outletObject.beat.area.name
                    : "",
                beatName:
                  outletObject.beat !== null &&
                    outletObject.beat.beatName !== null
                    ? outletObject.beat.beatName
                    : "",
                beat:
                  outletObject.beat === null && outletObject.beat.id === null
                    ? ""
                    : outletObject.beat.id,
                outletTypeName:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.name !== null
                    ? outletObject.outLetType.name
                    : "",
                address:
                  outletObject.address === null ? "" : outletObject.address,
                latitude:
                  outletObject.latitude === null ? "" : outletObject.latitude,
                longitude:
                  outletObject.longitude === null ? "" : outletObject.longitude,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                status: outletObject.status === null ? "" : outletObject.status,
                beatId:
                  outletObject.beat !== null && outletObject.beat.id !== null
                    ? outletObject.beat.id
                    : "",

                outLetType:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.id !== null
                    ? outletObject.outLetType.id
                    : "",
                sortOrder:
                  outletObject.sortOrder === null ? "" : outletObject.sortOrder,
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
                languageMarathi: "",
                languageHindi: "",
                languageEnglish: "",
                languageMarathiName: "Marathi",
                languageHindiName: "Hindi",
                languageEnglishName: "English",
              };

              return outletData;
            });
            dispatch(outletsSuccess({ row }));
            dispatch(outletsNewSuccess({ row: data.data }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOutletByBeatId =
  ({ row }) =>
    async (dispatch) => {
      dispatch(outletsNewSuccess({ row }));
    };

export const getOutletByBeatIds =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-beats?beatIds=" + beatIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletObject, index) => {
              let outletData = {
                index: index + 1,
                id: outletObject.id === null ? "" : outletObject.id,
                beatName:
                  outletObject.beat !== null &&
                    outletObject.beat.beatName !== null
                    ? outletObject.beat.beatName
                    : "",
                beat:
                  outletObject.beat === null && outletObject.beat.id === null
                    ? ""
                    : outletObject.beat.id,
                firmName:
                  outletObject.firmName === null ? "" : outletObject.firmName,
                name:
                  outletObject.firmName === null
                    ? ""
                    : outletObject.firmName + " - " + outletObject.ownerName,
                ownerName:
                  outletObject.ownerName === null ? "" : outletObject.ownerName,
                address:
                  outletObject.address === null ? "" : outletObject.address,
                latitude:
                  outletObject.latitude === null ? "" : outletObject.latitude,
                longitude:
                  outletObject.longitude === null ? "" : outletObject.longitude,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                status: outletObject.status === null ? "" : outletObject.status,
                beatId:
                  outletObject.beat !== null && outletObject.beat.id !== null
                    ? outletObject.beat.id
                    : "",

                outletTypeName:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.name !== null
                    ? outletObject.outLetType.name
                    : "",
                outLetType:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.id !== null
                    ? outletObject.outLetType.id
                    : "",
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
                languageMarathi: "",
                languageHindi: "",
                languageEnglish: "",
                languageMarathiName: "Marathi",
                languageHindiName: "Hindi",
                languageEnglishName: "English",
              };

              return outletData;
            });
            dispatch(outletsSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletByBeatIdsPost =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-list-by-beats",
          postBody: beatIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletObject, index) => {
              let outletData = {
                index: index + 1,
                id: outletObject.id === null ? "" : outletObject.id,
                beatName:
                  outletObject.beatName !== null
                    ? outletObject.beatName
                    : "",
                beat: outletObject.beatId === null ? "" : outletObject.beatId,
                firmName:
                  outletObject.firmName === null ? "" : outletObject.firmName,
                name:
                  outletObject.firmName === null
                    ? ""
                    : outletObject.firmName + " - " + outletObject.ownerName,
                ownerName:
                  outletObject.ownerName === null ? "" : outletObject.ownerName,
                latitude:
                  outletObject.latitude === null ? "" : outletObject.latitude,
                longitude:
                  outletObject.longitude === null ? "" : outletObject.longitude,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                villageCity:
                  outletObject.villageCity === null ? "" : outletObject.villageCity,
                address:
                  outletObject.address === null ? "" : outletObject.address,
                sortOrder: outletObject.sortOrder === null ? "" : outletObject.sortOrder,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                status: outletObject.status === null ? "" : outletObject.status,
                beatId:
                  outletObject.beatId !== null
                    ? outletObject.beatId
                    : "",

                outletTypeName:
                  outletObject.outletTypeName !== null
                    ? outletObject.outletTypeName
                    : "",
                outLetType:
                  outletObject.outLetTypeId !== null
                    ? outletObject.outLetTypeId
                    : "",
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
                languageMarathi: "",
                languageHindi: "",
                languageEnglish: "",
                languageMarathiName: "Marathi",
                languageHindiName: "Hindi",
                languageEnglishName: "English",
              };

              return outletData;
            });
            dispatch(outletsSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletByBeatIdsUpdated =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/with-payment-amt-by-beats?beatIds=" + beatIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletObject, index) => {
              let outletData = {
                index: index + 1,
                ...outletObject,
                id: outletObject.id === null ? "" : outletObject.id,
                outletId:
                  outletObject.outlet === null ? "" : outletObject.outlet.id,
                beatName:
                  outletObject.outlet.beat !== null &&
                    outletObject.outlet.beat.beatName !== null
                    ? outletObject.outlet.beat.beatName
                    : "",
                beat:
                  outletObject.outlet.beat === null &&
                    outletObject.outlet.beat.id === null
                    ? ""
                    : outletObject.outlet.beat.id,
                firmName:
                  outletObject.outlet.firmName === null
                    ? ""
                    : outletObject.outlet.firmName,
                name:
                  outletObject.outlet.firmName === null
                    ? ""
                    : outletObject.outlet.firmName,
                settleRemainingAmt:
                  outletObject.settleRemainingAmt === null
                    ? ""
                    : outletObject.settleRemainingAmt,
                date: outletObject.date === null ? "" : outletObject.date,
                ownerName:
                  outletObject.ownerName === null ? "" : outletObject.ownerName,
                address:
                  outletObject.address === null ? "" : outletObject.address,
                latitude:
                  outletObject.latitude === null ? "" : outletObject.latitude,
                longitude:
                  outletObject.longitude === null ? "" : outletObject.longitude,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                status: outletObject.status === null ? "" : outletObject.status,
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
              };

              return outletData;
            });
            dispatch(outletUpdatedSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOutletByBeatIdsUpdated =
  ({ row }) =>
    async (dispatch) => {
      dispatch(outletUpdatedSuccess({ row }));
    };

export const setOutletByBeatIds =
  ({ row }) =>
    async (dispatch) => {
      dispatch(outletsSuccess({ row }));
    };

export const getActiveOutletByBeatId =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-beat-and-is-active?beatId=" + beatIds + "&isActive=1",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletObject, index) => {
              let outletData = {
                index: index + 1,
                id: outletObject.id === null ? "" : outletObject.id,
                beatName:
                  outletObject.beat !== null &&
                    outletObject.beat.beatName !== null
                    ? outletObject.beat.beatName
                    : "",
                beat:
                  outletObject.beat === null && outletObject.beat.id === null
                    ? ""
                    : outletObject.beat.id,
                firmName:
                  outletObject.firmName === null ? "" : outletObject.firmName,
                name:
                  outletObject.firmName === null
                    ? ""
                    : outletObject.firmName + " - " + outletObject.ownerName,
                ownerName:
                  outletObject.ownerName === null ? "" : outletObject.ownerName,
                address:
                  outletObject.address === null ? "" : outletObject.address,
                latitude:
                  outletObject.latitude === null ? "" : outletObject.latitude,
                longitude:
                  outletObject.longitude === null ? "" : outletObject.longitude,
                mobileNo:
                  outletObject.mobileNo === null ? "" : outletObject.mobileNo,
                emailId:
                  outletObject.emailId === null ? "" : outletObject.emailId,
                status: outletObject.status === null ? "" : outletObject.status,
                beatId:
                  outletObject.beat !== null && outletObject.beat.id !== null
                    ? outletObject.beat.id
                    : "",

                outletTypeName:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.name !== null
                    ? outletObject.outLetType.name
                    : "",
                outLetType:
                  outletObject.outLetType !== null &&
                    outletObject.outLetType.id !== null
                    ? outletObject.outLetType.id
                    : "",
                isActive:
                  outletObject.isActive === null ? "" : outletObject.isActive,
              };

              return outletData;
            });
            dispatch(outletsSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((outlet, index) => {
          let outletByUserTypeData = {
            index: index + 1,
            id: outlet.id === null ? "" : outlet.id,
            firmName: outlet.firmName === null ? "" : outlet.firmName,
            name: outlet.firmName === null ? "" : outlet.firmName,
            ownerName: outlet.ownerName === null ? "" : outlet.ownerName,
            // state: outlet.state === null ? "" : outlet.state.id,
            stateName: outlet.state === null ? "" : outlet.state.name,
            areaName: outlet.beat.area === null ? "" : outlet.beat.area.name,
            beatName: outlet.beat === null ? "" : outlet.beat.beatName,
            beat: outlet.beat === null ? "" : outlet.beat.id,
            address: outlet.address === null ? "" : outlet.address,
            latitude: outlet.latitude === null ? "" : outlet.latitude,
            longitude: outlet.longitude === null ? "" : outlet.longitude,
            mobileNo: outlet.mobileNo === null ? "" : outlet.mobileNo,
            beatName: outlet.beat === null ? "" : outlet.beat.beatName,
            beat: outlet.beat === null ? "" : outlet.beat.id,
            outLetType: outlet.outLetType === null ? "" : outlet.outLetType.id,
            outLetTypeName:
              outlet.outLetType === null ? "" : outlet.outLetType.name,
            sortOrder: outlet.sortOrder === null ? "" : outlet.sortOrder,
            gstNo: outlet.gstNo === null ? "" : outlet.gstNo,
            panNo: outlet.panNo === null ? "" : outlet.panNo,
            gstType: outlet.gstType === null ? "" : outlet.gstType,
            fssiFdiNo: outlet.fssiFdiNo === null ? "" : outlet.fssiFdiNo,
            isActive: outlet.isActive === null ? "" : outlet.isActive,
            status: outlet.status === null ? "" : outlet.status,
            emailId: outlet.emailId === null ? "" : outlet.emailId,
          };
          return outletByUserTypeData;
        });
        dispatch(outletByUserTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOutletByArea =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/list-by-areas?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outlet, index) => {
              let outletByUserTypeData = {
                index: index + 1,
                id: outlet.id === null ? "" : outlet.id,
                firmName: outlet.firmName === null ? "" : outlet.firmName,
                name: outlet.firmName === null ? "" : outlet.firmName,
                ownerName: outlet.ownerName === null ? "" : outlet.ownerName,
                state: outlet.state === null ? "" : outlet.state.id,
                stateName: outlet.state === null ? "" : outlet.state.name,
                areaName: outlet.beat.area === null ? "" : outlet.beat.area.name,
                beatName: outlet.beat === null ? "" : outlet.beat.beatName,
                beat: outlet.beat === null ? "" : outlet.beat.id,
                address: outlet.address === null ? "" : outlet.address,
                latitude: outlet.latitude === null ? "" : outlet.latitude,
                longitude: outlet.longitude === null ? "" : outlet.longitude,
                mobileNo: outlet.mobileNo === null ? "" : outlet.mobileNo,
                outLetType:
                  outlet.outLetType === null ? "" : outlet.outLetType.id,
                outLetTypeName:
                  outlet.outLetType === null ? "" : outlet.outLetType.name,
                sortOrder: outlet.sortOrder === null ? "" : outlet.sortOrder,
                gstNo: outlet.gstNo === null ? "" : outlet.gstNo,

                panNo: outlet.panNo === null ? "" : outlet.panNo,
                gstType: outlet.gstType === null ? "" : outlet.gstType,
                fssiFdiNo: outlet.fssiFdiNo === null ? "" : outlet.fssiFdiNo,
                isActive: outlet.isActive === null ? "" : outlet.isActive,
                status: outlet.status === null ? "" : outlet.status,
                emailId: outlet.emailId === null ? "" : outlet.emailId,
              };
              return outletByUserTypeData;
            });
            dispatch(outletByUserTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOutletByAreaNew =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-by-areas",
          postBody: areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((dataObj, index) => {
              let outletByUserTypeData = {
                index: index + 1,
                id: dataObj.id === null ? "" : dataObj.id,
                firmName: dataObj.firmName === null ? "" : dataObj.firmName,
                name: dataObj.firmName === null ? "" : dataObj.firmName,
                ownerName: dataObj.ownerName === null ? "" : dataObj.ownerName,
                state: dataObj.stateId === null ? "" : dataObj.stateId,
                stateName: dataObj.stateName === null ? "" : dataObj.stateName,
                areaName: dataObj.areaName === null ? "" : dataObj.areaName,
                beatName: dataObj.beatName === null ? "" : dataObj.beatName,
                beat: dataObj.beatId === null ? "" : dataObj.beatId,
                address: dataObj.address === null ? "" : dataObj.address,
                latitude: dataObj.latitude === null ? "" : dataObj.latitude,
                longitude: dataObj.longitude === null ? "" : dataObj.longitude,
                mobileNo: dataObj.mobileNo === null ? "" : dataObj.mobileNo,
                outLetType:
                  dataObj.outLetTypeId === null ? "" : dataObj.outLetTypeId,
                outLetTypeName:
                  dataObj.outLetTypeName === null ? "" : dataObj.outLetTypeName,
                sortOrder: dataObj.sortOrder === null ? "" : dataObj.sortOrder,
                gstNo: dataObj.gstNo === null ? "" : dataObj.gstNo,
                panNo: dataObj.panNo === null ? "" : dataObj.panNo,
                gstType: dataObj.gstType === null ? "" : dataObj.gstType,
                fssiFdiNo: dataObj.fssiFdiNo === null ? "" : dataObj.fssiFdiNo,
                isActive: dataObj.isActive === null ? "" : dataObj.isActive,
                status: dataObj.status === null ? "" : dataObj.status,
                emailId: dataObj.emailId === null ? "" : dataObj.emailId,
              };
              return outletByUserTypeData;
            });
            dispatch(outletByUserTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getChangeActiveOutlet =
  ({ row }) =>
    async (dispatch) => {
      console.log(row);
      dispatch(outletsSuccess({ row }));
    };

export const getRequestedForInactive =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/requested-for-inactive?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletInactiveObject, index) => {
              let outletInactiveData = {
                index: index + 1,
                id:
                  outletInactiveObject.id === null ? "" : outletInactiveObject.id,
                outlet:
                  outletInactiveObject.firmName &&
                    outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.firmName +
                    "(" +
                    outletInactiveObject.ownerName +
                    ")",
                firmName:
                  outletInactiveObject.firmName === null
                    ? ""
                    : outletInactiveObject.firmName,
                ownerName:
                  outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.ownerName,
                beatName:
                  outletInactiveObject.beat === null
                    ? ""
                    : outletInactiveObject.beat.beatName,
                areaName:
                  outletInactiveObject.beat === null
                    ? ""
                    : outletInactiveObject.beat.area.name,
                outLetType:
                  outletInactiveObject.outLetType === null
                    ? ""
                    : outletInactiveObject.outLetType.name,
                address:
                  outletInactiveObject.address === null
                    ? ""
                    : outletInactiveObject.address,
                state:
                  outletInactiveObject.state === null
                    ? ""
                    : outletInactiveObject.state.name,
                latitude:
                  outletInactiveObject.latitude === null
                    ? ""
                    : outletInactiveObject.latitude,
                longitude:
                  outletInactiveObject.longitude === null
                    ? ""
                    : outletInactiveObject.longitude,
                mobileNo:
                  outletInactiveObject.mobileNo === null
                    ? ""
                    : outletInactiveObject.mobileNo,
                emailId:
                  outletInactiveObject.emailId === null
                    ? ""
                    : outletInactiveObject.emailId,
                gstNo:
                  outletInactiveObject.gstNo === null
                    ? ""
                    : outletInactiveObject.gstNo,
                panNo:
                  outletInactiveObject.panNo === null
                    ? ""
                    : outletInactiveObject.panNo,
                gstType:
                  outletInactiveObject.gstType === null
                    ? ""
                    : outletInactiveObject.gstType,
                fssiFdiNo:
                  outletInactiveObject.fssiFdiNo === null
                    ? ""
                    : outletInactiveObject.fssiFdiNo,
                isActive:
                  outletInactiveObject.isActive === null
                    ? ""
                    : outletInactiveObject.isActive,
                status:
                  outletInactiveObject.status === null
                    ? ""
                    : outletInactiveObject.status,
                insertDateTime:
                  outletInactiveObject.insertDateTime === null
                    ? ""
                    : outletInactiveObject.insertDateTime,
                remark: "",
              };
              return outletInactiveData;
            });
            dispatch(outletInactiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getRequestedForInactiveALL =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/requested-for-inactive?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletInactiveObject, index) => {
              let outletInactiveData = {
                index: index + 1,
                id:
                  outletInactiveObject.id === null ? "" : outletInactiveObject.id,
                outlet:
                  outletInactiveObject.firmName &&
                    outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.firmName +
                    "(" +
                    outletInactiveObject.ownerName +
                    ")",
                firmName:
                  outletInactiveObject.firmName === null
                    ? ""
                    : outletInactiveObject.firmName,
                ownerName:
                  outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.ownerName,
                beatName:
                  outletInactiveObject.beat === null
                    ? ""
                    : outletInactiveObject.beat.beatName,
                areaName:
                  outletInactiveObject.beat === null
                    ? ""
                    : outletInactiveObject.beat.area.name,
                outLetType:
                  outletInactiveObject.outLetType === null
                    ? ""
                    : outletInactiveObject.outLetType.name,
                address:
                  outletInactiveObject.address === null
                    ? ""
                    : outletInactiveObject.address,
                state:
                  outletInactiveObject.state === null
                    ? ""
                    : outletInactiveObject.state.name,
                latitude:
                  outletInactiveObject.latitude === null
                    ? ""
                    : outletInactiveObject.latitude,
                longitude:
                  outletInactiveObject.longitude === null
                    ? ""
                    : outletInactiveObject.longitude,
                mobileNo:
                  outletInactiveObject.mobileNo === null
                    ? ""
                    : outletInactiveObject.mobileNo,
                emailId:
                  outletInactiveObject.emailId === null
                    ? ""
                    : outletInactiveObject.emailId,
                gstNo:
                  outletInactiveObject.gstNo === null
                    ? ""
                    : outletInactiveObject.gstNo,
                panNo:
                  outletInactiveObject.panNo === null
                    ? ""
                    : outletInactiveObject.panNo,
                gstType:
                  outletInactiveObject.gstType === null
                    ? ""
                    : outletInactiveObject.gstType,
                fssiFdiNo:
                  outletInactiveObject.fssiFdiNo === null
                    ? ""
                    : outletInactiveObject.fssiFdiNo,
                isActive:
                  outletInactiveObject.isActive === null
                    ? ""
                    : outletInactiveObject.isActive,
                status:
                  outletInactiveObject.status === null
                    ? ""
                    : outletInactiveObject.status,
                insertDateTime:
                  outletInactiveObject.insertDateTime === null
                    ? ""
                    : outletInactiveObject.insertDateTime,
                remark: "",
              };
              return outletInactiveData;
            });
            dispatch(outletInactiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getRequestForInactiveOutletPost =
  ({ inactiveOutletData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/requested-for-inactive-by-areas",
          postBody: inactiveOutletData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outletInactiveObject, index) => {
              let inactivePostData = {
                index: index + 1,
                id:
                  outletInactiveObject.id === null ? "" : outletInactiveObject.id,
                outlet:
                  outletInactiveObject.firmName &&
                    outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.firmName +
                    "(" +
                    outletInactiveObject.ownerName +
                    ")",
                firmName:
                  outletInactiveObject.firmName === null
                    ? ""
                    : outletInactiveObject.firmName,
                ownerName:
                  outletInactiveObject.ownerName === null
                    ? ""
                    : outletInactiveObject.ownerName,
                beatName:
                  outletInactiveObject === null
                    ? ""
                    : outletInactiveObject.beatName,
                areaName:
                  outletInactiveObject.areaName === null
                    ? ""
                    : outletInactiveObject.areaName,
                outLetType:
                  outletInactiveObject.outLetType === null
                    ? ""
                    : outletInactiveObject.outLetType.name,
                address:
                  outletInactiveObject.address === null
                    ? ""
                    : outletInactiveObject.address,
                latitude:
                  outletInactiveObject.latitude === null
                    ? ""
                    : outletInactiveObject.latitude,
                longitude:
                  outletInactiveObject.longitude === null
                    ? ""
                    : outletInactiveObject.longitude,
                mobileNo:
                  outletInactiveObject.mobileNo === null
                    ? ""
                    : outletInactiveObject.mobileNo,
                emailId:
                  outletInactiveObject.emailId === null
                    ? ""
                    : outletInactiveObject.emailId,
                gstNo:
                  outletInactiveObject.gstNo === null
                    ? ""
                    : outletInactiveObject.gstNo,
                panNo:
                  outletInactiveObject.panNo === null
                    ? ""
                    : outletInactiveObject.panNo,
                gstType:
                  outletInactiveObject.gstType === null
                    ? ""
                    : outletInactiveObject.gstType,
                fssiFdiNo:
                  outletInactiveObject.fssiFdiNo === null
                    ? ""
                    : outletInactiveObject.fssiFdiNo,
                isActive:
                  outletInactiveObject.isActive === null
                    ? ""
                    : outletInactiveObject.isActive,
                status:
                  outletInactiveObject.status === null
                    ? ""
                    : outletInactiveObject.status,
                insertDateTime:
                  outletInactiveObject.insertDateTime === null
                    ? ""
                    : outletInactiveObject.insertDateTime,
                remark:
                  outletInactiveObject.remark === null
                    ? ""
                    : outletInactiveObject.remark,
              };

              return inactivePostData;
            });
            dispatch(outletInactiveSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setRequestedForInactive =
  ({ row }) =>
    async (dispatch) => {
      dispatch(outletInactiveSuccess({ row }));
    };
export const getOutletListByBeatIds =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/list-by-beats?beatIds=" + beatIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((outlet, index) => {
              let outletByUserTypeData = {
                index: index + 1,
                id: outlet.id === null ? "" : outlet.id,
                firmName: outlet.firmName === null ? "" : outlet.firmName,
                name: outlet.firmName === null ? "" : outlet.firmName,
                ownerName: outlet.ownerName === null ? "" : outlet.ownerName,
                state: outlet.state === null ? "" : outlet.state.id,
                stateName: outlet.state === null ? "" : outlet.state.name,
                areaName: outlet.beat.area === null ? "" : outlet.beat.area.name,
                beatName: outlet.beat === null ? "" : outlet.beat.beatName,
                beat: outlet.beat === null ? "" : outlet.beat.id,
                address: outlet.address === null ? "" : outlet.address,
                latitude: outlet.latitude === null ? "" : outlet.latitude,
                longitude: outlet.longitude === null ? "" : outlet.longitude,
                mobileNo: outlet.mobileNo === null ? "" : outlet.mobileNo,
                outLetType:
                  outlet.outLetType === null ? "" : outlet.outLetType.id,
                outLetTypeName:
                  outlet.outLetType === null ? "" : outlet.outLetType.name,
                sortOrder: outlet.sortOrder === null ? "" : outlet.sortOrder,
                gstNo: outlet.gstNo === null ? "" : outlet.gstNo,

                panNo: outlet.panNo === null ? "" : outlet.panNo,
                gstType: outlet.gstType === null ? "" : outlet.gstType,
                fssiFdiNo: outlet.fssiFdiNo === null ? "" : outlet.fssiFdiNo,
                isActive: outlet.isActive === null ? "" : outlet.isActive,
                status: outlet.status === null ? "" : outlet.status,
                emailId: outlet.emailId === null ? "" : outlet.emailId,
              };
              return outletByUserTypeData;
            });
            dispatch(outletByUserTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getUpdatedOutletListByBeatIds =
  ({ beatIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          // http://localhost:8088/api/distribution/v1/outlet/get-list-by-beats-new
          url: URL + "/get-list-by-beats-new",
          postBody: beatIds,
        }).then(({ data, success }) => {
          if (success) {

            const row = data.data.map((outlet, index) => {
              let outletByUserTypeData = {
                index: index + 1,
                id: outlet.id === null ? "" : outlet.id,
                firmName: outlet.firmName === null ? "" : outlet.firmName,
                name: outlet.firmName === null ? "" : outlet.firmName,
                ownerName: outlet.ownerName === null ? "" : outlet.ownerName,
                state: outlet.stateId === null ? "" : outlet.stateId,
                stateName: outlet.stateName === null ? "" : outlet.stateName,
                areaName: outlet.areaName && outlet.areaName === null ? "" : outlet.areaName,
                beatName: outlet.beatName === null ? "" : outlet.beatName,
                beat: outlet.beatId === null ? "" : outlet.beatId,
                address: outlet.address === null ? "" : outlet.address,
                latitude: outlet.latitude === null ? "" : outlet.latitude,
                longitude: outlet.longitude === null ? "" : outlet.longitude,
                mobileNo: outlet.mobileNo === null ? "" : outlet.mobileNo,
                outLetType:
                  outlet.outLetTypeId === null ? "" : outlet.outLetTypeId,
                outLetTypeName:
                  outlet.outLetTypeName === null ? "" : outlet.outLetTypeName,
                sortOrder: outlet.sortOrder === null ? "" : outlet.sortOrder,
                gstNo: outlet.gstNo === null ? "" : outlet.gstNo,

                panNo: outlet.panNo === null ? "" : outlet.panNo,
                gstType: outlet.gstType === null ? "" : outlet.gstType,
                fssiFdiNo: outlet.fssiFdiNo === null ? "" : outlet.fssiFdiNo,
                isActive: outlet.isActive === null ? "" : outlet.isActive,
                status: outlet.status === null ? "" : outlet.status,
                emailId: outlet.emailId === null ? "" : outlet.emailId,
              };
              return outletByUserTypeData;
            });
            dispatch(outletByUserTypeSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };