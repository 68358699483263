import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
import moment from "moment";
const initialState = {
    managerLogs: [],
    userSummary: [],

};
let URL = endpoints.managerLogs;
const managerLogsSlice = createSlice({
    name: "managerLogs",
    initialState,
    reducers: {
        managerLogsSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                managerLogs: row,
            };
        },

        userSummarySuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                userSummary: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    managerLogsSuccess,
    userSummarySuccess,
} = managerLogsSlice.actions;
export default managerLogsSlice.reducer;

export const getManagerLogs =
    ({ fromDate, toDate }) =>
        async (dispatch) => {
            try {
                const response = apiGet({
                    url: URL + "/get-between-dates?fromDate=" + fromDate + "&toDate=" + toDate,
                }).then(({ data, success }) => {
                    if (success) {
                        const row = data.data.map((managerLogHeaderObj, index) => {
                            const dateObject = moment(managerLogHeaderObj.date, "DD-MM-YYYY");
                            const formattedDate = dateObject.format("DD MMM");
                            let managerLogsData = {
                                index: index + 1,
                                id:
                                    managerLogHeaderObj.id === null
                                        ? ""
                                        : managerLogHeaderObj.id,
                                date:
                                    managerLogHeaderObj.date === null
                                        ? ""
                                        : managerLogHeaderObj.date,

                                day: formattedDate,
                                managerLogs:
                                    managerLogHeaderObj.managerLogs === null
                                        ? ""
                                        : managerLogHeaderObj.managerLogs.map((managerLogObj, index) => {
                                            let managerLogsListData = {
                                                index: index + 1,
                                                id: managerLogObj.id === null ? "" : managerLogObj.id,
                                                salesTeamId: managerLogObj.salesTeamId === null ? "" : managerLogObj.salesTeamId,
                                                workingTypeId: managerLogObj.workingType === null ? "" : managerLogObj.workingType.id,
                                                workingTypeName: managerLogObj.workingType === null ? "" : managerLogObj.workingType.name,
                                                areaId: managerLogObj.areaId === null ? "" : managerLogObj.areaId,
                                                areaName: managerLogObj.areaName === null ? "" : managerLogObj.areaName,
                                                outletId: managerLogObj.outletId === null ? "" : managerLogObj.outletId,
                                                entityName: managerLogObj.entityName === null ? "" : managerLogObj.entityName,
                                                stockStakeHolderId: managerLogObj.stockStakeHolderId === null ? "" : managerLogObj.stockStakeHolderId,
                                                description: managerLogObj.description === null ? "" : managerLogObj.description,
                                                fromTime: managerLogObj.fromTime === null ? "" : managerLogObj.fromTime,
                                                toTime: managerLogObj.toTime === null ? "" : managerLogObj.toTime,
                                                timeDifference: managerLogObj.timeDifference === null ? "" : managerLogObj.timeDifference,
                                                date: managerLogObj.date === null ? "" : managerLogObj.date,
                                                managerStartLat: managerLogObj.managerStartLat === null ? "" : managerLogObj.managerStartLat,
                                                managerStartLong: managerLogObj.managerStartLong === null ? "" : managerLogObj.managerStartLong,
                                                startAddress: managerLogObj.startAddress === null ? "" : managerLogObj.startAddress,
                                                managerEndLat: managerLogObj.managerEndLat === null ? "" : managerLogObj.managerEndLat,
                                                managerEndLong: managerLogObj.managerEndLong === null ? "" : managerLogObj.managerEndLong,
                                                endAddress: managerLogObj.endAddress === null ? "" : managerLogObj.endAddress,
                                                otherTaskId: managerLogObj.otherTask === null ? "" : managerLogObj.otherTask.id,
                                                otherTaskName: managerLogObj.otherTask === null ? "" : managerLogObj.otherTask.name,
                                                extra1: managerLogObj.extra1 === null ? "" : managerLogObj.extra1,
                                                extraIds: managerLogObj.extraIds === null ? "" : managerLogObj.extraIds,
                                                isVisitIsOrder: managerLogObj.isVisitIsOrder === null ? "" : managerLogObj.isVisitIsOrder,
                                                checkInOut: managerLogObj.checkInOut === null ? "" : managerLogObj.checkInOut,
                                                insertedUserId: managerLogObj.insertedUserId === null ? "" : managerLogObj.insertedUserId,
                                                insertDateTime: managerLogObj.insertDateTime === null ? "" : managerLogObj.insertDateTime,
                                                salesPersonName: managerLogObj.salesPersonName === null ? "" : managerLogObj.salesPersonName,
                                                salesTeamIsActiveFlag: managerLogObj.salesTeamIsActiveFlag === null ? "" : managerLogObj.salesTeamIsActiveFlag,

                                            };
                                            return managerLogsListData;
                                        }),

                            };

                            return managerLogsData;
                        });
                        dispatch(managerLogsSuccess({ row }));
                        return { response: row, success };
                    }
                    return { response: [], success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };

export const getManagerLogsBySalesTeamIds =
    ({ managerLogsJson }) =>
        async (dispatch) => {
            try {
                const response = apiPost({
                    url: URL + "/get-between-dates-for-sales-teams",
                    postBody: managerLogsJson,
                }).then(({ data, success }) => {
                    if (success) {
                        const row = data.data.map((managerLogHeaderObj, index) => {
                            const dateObject = moment(managerLogHeaderObj.date, "DD-MM-YYYY");
                            const formattedDate = dateObject.format("DD MMM");
                            let managerLogsData = {
                                index: index + 1,
                                id:
                                    managerLogHeaderObj.id === null
                                        ? ""
                                        : managerLogHeaderObj.id,
                                date:
                                    managerLogHeaderObj.date === null
                                        ? ""
                                        : managerLogHeaderObj.date,

                                day: formattedDate,

                                salesTeamId: managerLogHeaderObj.salesTeam === null
                                    ? ""
                                    : managerLogHeaderObj.salesTeam.id,

                                salesTeamName: managerLogHeaderObj.salesTeam === null
                                    ? ""
                                    : managerLogHeaderObj.salesTeam.name,

                                salesTeamType: managerLogHeaderObj.salesTeam !== null &&
                                    managerLogHeaderObj.salesTeam.stockStakeHolderType !== null
                                    ? managerLogHeaderObj.salesTeam.stockStakeHolderType.name
                                    : "",

                                beats: managerLogHeaderObj.beats === null
                                    ? ""
                                    : managerLogHeaderObj.beats.map((beatDataObj, index) => {
                                        let beatData = {
                                            index: index + 1,
                                            id: beatDataObj.id === null ? "" : beatDataObj.id,
                                            beatName: beatDataObj.beatName === null ? "" : beatDataObj.beatName,
                                        };
                                        return beatData;
                                    }),

                                managerLogs:
                                    managerLogHeaderObj.managerLogs === null
                                        ? ""
                                        : managerLogHeaderObj.managerLogs.map((managerLogObj, index) => {
                                            let managerLogsListData = {
                                                index: index + 1,
                                                id: managerLogObj.id === null ? "" : managerLogObj.id,
                                                salesTeamId: managerLogObj.salesTeamId === null ? "" : managerLogObj.salesTeamId,
                                                workingTypeId: managerLogObj.workingType === null ? "" : managerLogObj.workingType.id,
                                                workingTypeName: managerLogObj.workingType === null ? "" : managerLogObj.workingType.name,
                                                areaId: managerLogObj.areaId === null ? "" : managerLogObj.areaId,
                                                areaName: managerLogObj.areaName === null ? "" : managerLogObj.areaName,
                                                remoteOrder: managerLogObj.remoteOrder === null ? "" : managerLogObj.remoteOrder,
                                                outletId: managerLogObj.outletId === null ? "" : managerLogObj.outletId,
                                                entityName: managerLogObj.entityName === null ? "" : managerLogObj.entityName,
                                                stockStakeHolderId: managerLogObj.stockStakeHolderId === null ? "" : managerLogObj.stockStakeHolderId,
                                                description: managerLogObj.description === null ? "" : managerLogObj.description,
                                                fromTime: managerLogObj.fromTime === null ? "" : managerLogObj.fromTime,
                                                toTime: managerLogObj.toTime === null ? "" : managerLogObj.toTime,
                                                timeDifference: managerLogObj.timeDifference === null ? "" : managerLogObj.timeDifference,
                                                date: managerLogObj.date === null ? "" : managerLogObj.date,
                                                managerStartLat: managerLogObj.managerStartLat === null ? "" : managerLogObj.managerStartLat,
                                                managerStartLong: managerLogObj.managerStartLong === null ? "" : managerLogObj.managerStartLong,
                                                startAddress: managerLogObj.startAddress === null ? "" : managerLogObj.startAddress,
                                                managerEndLat: managerLogObj.managerEndLat === null ? "" : managerLogObj.managerEndLat,
                                                managerEndLong: managerLogObj.managerEndLong === null ? "" : managerLogObj.managerEndLong,
                                                endAddress: managerLogObj.endAddress === null ? "" : managerLogObj.endAddress,
                                                otherTaskId: managerLogObj.otherTask === null ? "" : managerLogObj.otherTask.id,
                                                otherTaskName: managerLogObj.otherTask === null ? "" : managerLogObj.otherTask.name,
                                                extra1: managerLogObj.extra1 === null ? "" : managerLogObj.extra1,
                                                extraIds: managerLogObj.extraIds === null ? "" : managerLogObj.extraIds,
                                                isVisitIsOrder: managerLogObj.isVisitIsOrder === null ? "" : managerLogObj.isVisitIsOrder,
                                                checkInOut: managerLogObj.checkInOut === null ? "" : managerLogObj.checkInOut,
                                                insertedUserId: managerLogObj.insertedUserId === null ? "" : managerLogObj.insertedUserId,
                                                insertDateTime: managerLogObj.insertDateTime === null ? "" : managerLogObj.insertDateTime,
                                                salesPersonName: managerLogObj.salesPersonName === null ? "" : managerLogObj.salesPersonName,
                                                salesTeamIsActiveFlag: managerLogObj.salesTeamIsActiveFlag === null ? "" : managerLogObj.salesTeamIsActiveFlag,

                                            };
                                            return managerLogsListData;
                                        }),

                            };

                            return managerLogsData;
                        });
                        dispatch(managerLogsSuccess({ row }));
                        return { response: row, success };
                    }
                    return { response: [], success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };

export const setManagerLogs = () => async (dispatch) => {
    dispatch(managerLogsSuccess({ row: [] }));
};




export const getUserWorkSummary =
    ({ date }) =>
        async (dispatch) => {
            try {
                const response = apiGet({
                    url: URL + "/user-work-summary?date=" + date,
                }).then(({ data, success }) => {
                    if (success) {
                        const row = data.data.map((userWorkSummaryObj, index) => {
                            let userWorkSummaryData = {
                                index: index + 1,
                                id: userWorkSummaryObj.id === null ? "" : userWorkSummaryObj.id,
                                name: userWorkSummaryObj.name === null ? "" : userWorkSummaryObj.name,
                                count: userWorkSummaryObj.count === null ? "" : userWorkSummaryObj.count,
                                isActive: userWorkSummaryObj.isActive === null ? "" : userWorkSummaryObj.isActive,
                                insertDateTime: userWorkSummaryObj.insertDateTime === null ? "" : userWorkSummaryObj.insertDateTime,
                            };
                            return userWorkSummaryData;
                        });
                        dispatch(userSummarySuccess({ row }));
                        return { response: row, success };
                    }
                    return { response: [], success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };