import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  outletType: [],
};
let URL = endpoints.outletType;
const outletTypeSlice = createSlice({
  name: "outletType",
  initialState,
  reducers: {
    outletTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletType: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { outletTypeSuccess, resetState } = outletTypeSlice.actions;

export default outletTypeSlice.reducer;

export const getOutletType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((outletTypeObject, index) => {
          let outletTypeData = {
            index: index + 1,
            id: outletTypeObject.id === null ? "" : outletTypeObject.id,
            name:
              outletTypeObject.name !== null && outletTypeObject.shortName !== null
                ? outletTypeObject.name +
                " (" +
                outletTypeObject.shortName +
                ")" : "",
            areaCount: outletTypeObject.areaCount === null
              ? ""
              : outletTypeObject.areaCount,
            shortName:
              outletTypeObject.shortName === null
                ? ""
                : outletTypeObject.shortName,
            outletCount:
              outletTypeObject.outletCount === null
                ? ""
                : outletTypeObject.outletCount,
          };
          return outletTypeData;
        });
        dispatch(outletTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setOutletType =
  ({ row }) =>
    async (dispatch) => {
      dispatch(outletTypeSuccess({ row }));
    };
