import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { tokenJson } from "../../DynamicFormsJson/Transaction/token";
import {
  getAreaByTalukaPostBody,
  getAreaByUserType,
} from "../../Slice/area.slice";
import { getBeatBySalesTeamId } from "../../Slice/beat.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getProjection } from "../../Slice/projection.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import swal from "sweetalert";
import {
  getActiveLoginUserUpdated,
  setActiveLoginUser,
} from "../../Slice/activeLoginUser.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import {
  alreadyCheckedInMsg,
  changeActive,
  checkedListEmptyMsg,
  clearToken,
  noInternetMsg,
  notCheckInYet,
  saveWarningMsg,
  savemsg,
  serverMsg,
  statusFailedMsg,
  statusmsg,
  tokenMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
class Token extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockStakeHolderTypeId: "",
      userId: "",
      areaIds: "",
      location: null,
      adminFlag: false,
      showTableList: false,
      selection: [],
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaByUserTypeDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getAreaByUserType,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });
      }

      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      if (userTypeId == 1 || userTypeId == 6) {
        showLoador({ loador: true });
        getStockStakeHolderType({ userTypeIds: "2,3,4,5" }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        showLoador({ loador: true });
        getStockStakeHolderType({ userTypeIds: "4" }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
    if (this.props.params.id) {
      this.getDataById(this.props.params.id);
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        areaIds: data.area,
      });

      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = async (data) => {
    const { getActiveLoginUserUpdated, showLoador, showNotification } =
      this.props;
    const { areaIds, stockStakeHolderTypeId } = this.state;

    this.setState({
      stockStakeHolderTypeId: data.stockStakeHolderType,
    });

    if (navigator.onLine) {
      let activeLoginUserJsonOnSearch = {
        areaIds: areaIds,
        stockStakeHolderTypeId: data.stockStakeHolderType,
      };

      showLoador({ loador: true });
      await getActiveLoginUserUpdated({
        activeLoginUserJson: activeLoginUserJsonOnSearch,
      }).then(({ response, success }) => {
        console.log(response);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({ showTableList: true });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchafterClear() {
    const { getActiveLoginUserUpdated, showLoador, showNotification } =
      this.props;
    const { areaIds, stockStakeHolderTypeId } = this.state;

    if (navigator.onLine) {
      let activeLoginUserOnClearJson = {
        areaIds: areaIds,
        stockStakeHolderTypeId: stockStakeHolderTypeId,
      };
      showLoador({ loador: true });
      getActiveLoginUserUpdated({
        activeLoginUserJson: activeLoginUserOnClearJson,
      }).then(({ response, success }) => {
        console.log(response);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getSelectedList = (e) => {
    const rowList = this.props.activeLoginUserList.activeLoginUser.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id === id);
        return { ...rowData, checked: filterData.length !== 0 };
      }
    );

    const userIds = rowList
      .filter((rowData) => rowData.checked)
      .map((rowData) => rowData.id);

    this.setState({
      selection: e,
      userId: userIds,
    });
    this.props.setActiveLoginUser({ row: rowList });
  };

  onApprove = () => {
    const { showNotification, showLoador, activeLoginUserList } = this.props;
    const { selection, formData, userId } = this.state;

    if (
      activeLoginUserList.activeLoginUser.filter((row) => row.checked)
        .length === 0
    ) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      swal({
        title: "Are you sure?",
        text: updateData,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
              url:
                endpoint.user +
                "/update-one-device-one-login-by-user-token?userTokenIds=" +
                userId,
            }).then(({ success }) => {
              if (success) {
                showLoador({ loador: false });
                showNotification({
                  msg: updateMsg,
                });
                this.onSearchafterClear();
              } else {
                showNotification({
                  msg: serverMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    }
  };

  onChangeStatus = (row) => {
    console.log(row);
    const { showLoador, showNotification, activeLoginUserList } = this.props;

    if (navigator.onLine) {

      const dataOfRow = activeLoginUserList.activeLoginUser.filter(
        (rowData) => rowData.id == row
      );

      console.log(dataOfRow);

      if (dataOfRow.length !== 0) {
        let dataToSave = {
          salesTeamIds: dataOfRow[0].personId,
        };

        console.log(dataToSave);

        if (dataOfRow[0].isActive == "Not check in yet") {
          showNotification({ msg: notCheckInYet, severity: "error" });
        } else if (dataOfRow[0].isActive == "InActive") {
          swal({
            title: "Are you sure?",
            text: changeActive,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              showLoador({ loador: true });
              apiPost({
                url: endpoint.checkin + "/update",
                postBody: dataToSave,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                  showNotification({ msg: statusmsg });
                  this.onSearchafterClear();
                } else {
                  showNotification({ msg: statusFailedMsg, severity: "error" });
                }
              });
            }
          });
        } else {
          showNotification({ msg: alreadyCheckedInMsg, severity: "error" });
        }
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  render() {
    const { dynamicMasterData, showTableList, selection, adminFlag } =
      this.state;
    const { activeLoginUserList, setActiveLoginUser } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={false}
            dynamicMasterData={dynamicMasterData}
            showTitle={tokenJson.showTitle}
            screenTitle={tokenJson.screenTitle}
            fieldMeta={
              adminFlag
                ? tokenJson.fieldMeta
                : tokenJson.fieldMetaForStockholder
            }
            showCancel={tokenJson.showCancel}
            apiBaseURL={tokenJson.apiBaseURL}
            showSaveNextBtn={tokenJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showSaveBtn={true}
            getListById={this.getListById}
            onSave={this.onSearch}
            isActiveURLFalse={true}
          />

          {showTableList && (
            <>
              <CheckBoxSelectionTable
                selection={selection}
                onSelectionChange={this.getSelectedList}
                rowStatus={this.onChangeStatus}
                columns={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: true,
                    align: "center",
                  },
                  {
                    title: "User Name",
                    name: "userName",
                    positionCenter: false,
                    canSearch: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Name",
                    name: "name",
                    positionCenter: false,
                    canSearch: true,
                    align: "left",
                  },
                  {
                    title: "Stock Holder",
                    name: "stockStakeHolderType",
                    positionCenter: false,
                    canSearch: true,
                    align: "left",
                  },
                  {
                    title: "Device Info",
                    name: "deviceInfo",
                    positionCenter: false,
                    canSearch: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Login Time",
                    name: "insertDateTime",
                    positionCenter: false,
                    canSearch: true,
                    align: "right",
                  },
                  {
                    title: "Status",
                    name: "isActive",
                    positionCenter: false,
                    chipCompo: true,
                    canSearch: true,
                    align: "right",
                  },
                ]}
                rows={activeLoginUserList.activeLoginUser}
                setList={setActiveLoginUser}
                isActionColActive={false}
              />
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name={"Clear"}
                  onClick={this.onApprove}
                />
              </Grid>
            </>
          )}
          <br />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
  stakeHolderTypeList: state.stockStakeHolderType,
  activeLoginUserList: state.activeLoginUser,
});
const mapDispatchToProps = {
  showLoador,
  getRegionNew,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  showNotification,
  getBeatBySalesTeamId,
  getProjection,
  getAreaByUserType,
  getStockStakeHolderType,
  setActiveLoginUser,
  getActiveLoginUserUpdated,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Token);
