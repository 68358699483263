import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Hidden } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import dayjs from "dayjs";
import { apiGet } from "../../utils/api_service";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { imageUrl } from "../../config/Url";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ZeroOrderImage = ({ handleCloses, open, showLoador, showNotification, columns, rows, itemObject }) => {

    const [dataList, setDataList] = React.useState({})
    const [popUpOpenFlag, setPopUpOpenFlag] = React.useState(false)
    const [imageLoaded, setImageLoaded] = React.useState(false);

    const handleClose = () => {
        handleCloses();
    };

    React.useEffect(() => {
        apiMethod();
    }, [open, itemObject.id]);


    const apiMethod = async () => {

        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiGet({
                url: endpoint.managerLogs + "/" + itemObject.id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    console.log(data.data);
                    setDataList(data.data);
                }
            });

            popUpFlagMethod();

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    const popUpFlagMethod = () => {
        setPopUpOpenFlag(true);
    }


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={popUpOpenFlag}
                fullWidth
                maxWidth={false}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "830px",
                        maxWidth: "none",
                    },
                }}
            >

                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Zero Order Image
                </BootstrapDialogTitle>

                <DialogContent style={{ display: 'flex', overflowX: 'hidden', width: '100%' }} dividers>

                    <div style={{
                        width: '85%'
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '35%'
                            }}>
                                {"Date :"}
                            </div>
                            <div>
                                {dataList.date}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '35%'
                            }}>
                                {"Outlet Name :"}
                            </div>
                            <div>
                                {dataList.entityName}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '35%'

                            }}>
                                {"Remark for zero order :"}
                            </div>
                            <div>
                                {dataList.description ? dataList.description : "-"}
                            </div>

                        </div>

                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                        height: '100%',
                        textAlign: 'right'
                    }}>
                        {dataList.image && (
                            <img
                                src={imageUrl + dataList.image}
                                onLoad={() => setImageLoaded(true)}
                                style={{
                                    display: imageLoaded ? 'block' : 'none',
                                    width: '280px',
                                    height: '380px',
                                }}
                            />
                        )}
                        {!imageLoaded && (
                            <div
                                style={{
                                    width: '280px',
                                    height: '380px',
                                    backgroundColor: '#f0f0f0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <span>Loading image...</span>
                            </div>
                        )}
                    </div>

                </DialogContent>
            </BootstrapDialog>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    showLoador,
    showNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ZeroOrderImage);
