import React, { useEffect, useState } from "react";
import "./billPDFCss.css";
import { langugae } from "../../config/languageJSON";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import QRCode from "qrcode.react";

const MyComponent = ({ showLoador, showNotification }) => {
  const data = "9579061161@ybl";
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let billPdfId = localStorage.getItem("billPdfId");
  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      billPdfId = localStorage.getItem("billPdfId");

      let billPdfData = {
        outletBillHeaderIds: billPdfId.split(","),
        languageId: lang,
      };
      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/report-by-ids",
        postBody: billPdfData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          setBill(data.data);
          setTimeout(function () {
            window.print();
          }, 1200);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
      {billList.map((dataToSet, index) => {
        let invoiceJson =
          dataToSet.invoiceJson != null
            ? JSON.parse(dataToSet.invoiceJson)
            : {};
        let toBill = dataToSet.outLet ? dataToSet.outLet : {};
        let taluka =
          toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
            ? toBill.beat.area.taluka
            : "";
        let talukaName = taluka != null ? taluka.name : "";
        let districtName =
          taluka != null && taluka.district != null ? taluka.district.name : "";
        let addr = toBill.address
          ? toBill.address + " - " + talukaName + " - " + districtName
          : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        let village = langugae[2].village ? langugae[2].village + " : " : "";
        var villageCity = toBill.villageCity
          ? village + toBill.villageCity
          : "";
        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let qtyTot = 0;
        let freeQtyTot = 0;
        let cgstTot = 0;
        let sgstTot = 0;
        let igstTot = 0;
        let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.outletBillDetails != null
            ? dataToSet.outletBillDetails
            : [];

        return (
          <>
            <div style={{ marginRight: "10px", marginLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    width: "55%",
                    textAlign: "end",
                  }}
                >
                  {dataToSet.stockStakeHolderGstType == 3
                    ? dataToSet.stockStakeHolderGstNo == toBill.gSTNo
                      ? langugae[2].taxInvoice
                      : langugae[2].taxInvoice
                    : langugae[2].deliveryNote}
                </div>
                <div
                  style={{
                    // position: "absolute",//
                    marginBottom: "0",
                    backgroundColor: "fff",
                    textAlign: "right",
                  }}
                >
                  {index + 1} of {billList.length}
                </div>
              </div>
              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                          verticalAlign: "top",
                        }}
                      >
                        <div>
                          <div style={{ fontWeight: 600, fontSize: "20px" }}>
                            {dataToSet.stockStakeHolderFirmName
                              ? dataToSet.stockStakeHolderFirmName
                              : "-"}
                          </div>
                          {langugae[2].ownerName
                            ? langugae[2].ownerName + " : "
                            : ""}{" "}
                          {dataToSet.stockStakeHolderOwnerName
                            ? dataToSet.stockStakeHolderOwnerName
                            : "-"}{" "}
                          <br />
                          {langugae[2].address
                            ? langugae[2].address + " : "
                            : ""}{" "}
                          {dataToSet.stockStakeHolderAddress
                            ? dataToSet.stockStakeHolderAddress
                            : "-"}
                          <br />
                          {langugae[2].mobileNo
                            ? langugae[2].mobileNo + " : "
                            : ""}{" "}
                          {dataToSet.stockStakeHolderContactPersonNo
                            ? dataToSet.stockStakeHolderContactPersonNo
                            : "-"}{" "}
                          , State :{" "}
                          {dataToSetState.name
                            ? " " + dataToSetState.name
                            : "-"}
                          <br />
                          {langugae[2].gSTNo
                            ? langugae[2].gSTNo + " : "
                            : ""}{" "}
                          {dataToSet.stockStakeHolderGstType == 2
                            ? dataToSet.stockStakeHolderGstNo
                            : dataToSet.stockStakeHolderGstType == 3
                            ? dataToSet.stockStakeHolderGstNo
                            : " - "}
                          ,{" "}
                          {langugae[2].FSSAINo
                            ? langugae[2].FSSAINo + " : "
                            : ""}{" "}
                          {dataToSet.stockStakeHolderFssiFdiNo
                            ? dataToSet.stockStakeHolderFssiFdiNo
                            : "-"}
                          <br />
                          Mode of Transport : By Road , Vehicle No.:{" "}
                          {dataToSet.vehicleNo ? dataToSet.vehicleNo : "-"}{" "}
                          <br />
                        </div>
                      </td>

                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                          verticalAlign: "top",
                        }}
                      >
                        <div>
                          <div style={{ fontWeight: 600, fontSize: "20px" }}>
                            {langugae[2].billTo ? (
                              <>{langugae[2].billTo} : </>
                            ) : (
                              ""
                            )}
                            {toBill.firmName ? toBill.firmName : ""}
                          </div>
                          {langugae[2].ownerName
                            ? langugae[2].ownerName + " : "
                            : ""}{" "}
                          {toBill.ownerName ? toBill.ownerName : "-"} <br />
                          {langugae[2].beatName
                            ? langugae[2].beatName + " : "
                            : ""}
                          {toBill.beat != null ? toBill.beat.beatName : "-"}{" "}
                          <br />
                          {DAddrText}
                          <br />
                          {villageCity}
                          <br />
                          {langugae[2].phoneNo
                            ? langugae[2].phoneNo + " : "
                            : ""}{" "}
                          {toBill.mobileNo ? toBill.mobileNo : ""} ,{" "}
                          {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""}{" "}
                          {toBill.gstNo != null ? toBill.gstNo : "-"} , <br />
                          Place Of Supply:{" "}
                          {toBillState.name
                            ? " " + toBillState.name
                            : ""} ,{" "}
                          {langugae[2].FSSAINo
                            ? langugae[2].FSSAINo + " : "
                            : ""}{" "}
                          {toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
                            ? toBill.fssiFdiNo
                            : "-"}
                        </div>
                      </td>
                    </tr>
                  </table>
                </tr>
              </table>

              <div
                style={{
                  borderCollapse: "collapse",
                  marginTop: "8px",
                }}
              >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <td colSpan={12}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  {langugae[2].billNO
                                    ? langugae[2].billNO + " : "
                                    : ""}
                                </div>
                                {dataToSet.billNo ? dataToSet.billNo : "-"}{" "}
                              </div>
                            </td>

                            <td align={"right"}>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  {langugae[2].date
                                    ? langugae[2].date + " : "
                                    : ""}
                                </div>
                                {dataToSet.billDate ? dataToSet.billDate : "-"}
                                {", "}
                                {langugae[2].time
                                  ? langugae[2].time + " : "
                                  : ""}
                                {insertDateTimeData}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <th className="thsmallcell">#</th>
                      {dataToSet.stockStakeHolderGstType == 3 && (
                        <>
                          <th className="thmedcell">
                            {langugae[2].HSN ? langugae[2].HSN : ""}
                          </th>
                        </>
                      )}
                      <th className="thbigcell">
                        {langugae[2].itemName ? langugae[2].itemName : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].freeQtyNew ? langugae[2].freeQtyNew : ""}
                      </th>
                      <th className="thmedcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>
                      {dataToSet.stockStakeHolderGstType == 3 && (
                        <>
                          {toBillState.id == dataToSetState.id ? (
                            <>
                              <th className="thmedcell">
                                {langugae[2].CGST ? langugae[2].CGST : ""} {"%"}
                              </th>
                              <th
                                style={{ width: "30px" }}
                                className="thmedcell"
                              >
                                {langugae[2].CGST ? langugae[2].CGST : ""} {"A"}
                              </th>

                              <th className="thmedcell">
                                {" "}
                                {langugae[2].SGST ? langugae[2].SGST : ""} {"%"}
                              </th>
                              <th
                                style={{ width: "30px" }}
                                className="thmedcell"
                              >
                                {langugae[2].SGST ? langugae[2].SGST : ""} {"A"}
                              </th>
                            </>
                          ) : (
                            <>
                              <th className="thmedcell">
                                {" "}
                                {langugae[2].IGST ? langugae[2].IGST : ""} {"%"}
                              </th>
                              <th className="thmedcell">
                                {langugae[2].IGST ? langugae[2].IGST : ""} {"A"}
                              </th>
                            </>
                          )}
                        </>
                      )}

                      <th className="thmedcell">
                        {langugae[2].amount ? langugae[2].amount : ""}
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {dataToSet.packagingTypes.map((packagingType) => {
                      return (
                        <>
                          <tr>
                            <td
                              align={"left"}
                              colSpan={
                                toBillState.id == dataToSetState.id
                                  ? dataToSet.stockStakeHolderGstType == 3
                                    ? 12
                                    : 7
                                  : 10
                              }
                              style={{ fontWeight: 600 }}
                              className="tdmedcellNew"
                            >
                              {packagingType.name}
                            </td>
                          </tr>

                          {stockStakeHolderBillDetails.map(
                            (stockDetails, indexData) => {
                              let packagingTypeDetails =
                                stockDetails.product != null &&
                                stockDetails.product.packagingType != null
                                  ? stockDetails.product.packagingType
                                  : {};

                              if (packagingType.id == packagingTypeDetails.id) {
                                let productData = [];
                                let product =
                                  stockDetails.product != null
                                    ? stockDetails.product
                                    : {};
                                qtyTot = qtyTot + stockDetails.qty;
                                freeQtyTot = freeQtyTot + stockDetails.freeQty;
                                cgstTot = cgstTot + stockDetails.cgstAmt;
                                sgstTot = sgstTot + stockDetails.sgstAmt;
                                igstTot = igstTot + stockDetails.igstAmt;
                                indexInfo = indexInfo + 1;
                                totalAmt = totalAmt + stockDetails.totalAmt;
                                return (
                                  <>
                                    <tr>
                                      <td className="tdmedcellNew">
                                        {indexInfo}
                                      </td>
                                      {dataToSet.stockStakeHolderGstType ==
                                        3 && (
                                        <>
                                          <td className="tdmedcellNew">
                                            {stockDetails.hsnCode}
                                          </td>
                                        </>
                                      )}
                                      <td
                                        align={"left"}
                                        className="tdmedcellNew"
                                      >
                                        {product.name}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdmedcellNew"
                                      >
                                        {stockDetails.uom != null
                                          ? stockDetails.uom.name
                                          : "-"}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdmedcellNew"
                                      >
                                        {stockDetails.qty}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdmedcellNew"
                                      >
                                        {stockDetails.freeQty}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdmedcellNew"
                                      >
                                        {dataToSet.stockStakeHolderGstType == 3
                                          ? (
                                              (stockDetails.rate * 100) /
                                              (100 +
                                                stockDetails.cgstPer +
                                                stockDetails.sgstPer +
                                                stockDetails.igstPer)
                                            ).toFixed(2)
                                          : stockDetails.rate.toFixed(2)}
                                      </td>

                                      {dataToSet.stockStakeHolderGstType ==
                                        3 && (
                                        <>
                                          {toBillState.id ==
                                          dataToSetState.id ? (
                                            <>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.cgstPer.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.cgstAmt.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.sgstPer.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.sgstAmt.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.igstPer.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td
                                                align={"right"}
                                                className="tdmedcellNew"
                                              >
                                                {stockDetails.igstAmt.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </>
                                          )}
                                        </>
                                      )}

                                      <td
                                        align={"right"}
                                        className="tdmedcellNew"
                                      >
                                        {stockDetails.totalAmt.toFixed(2)}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      <td className="tdmedcellNew"></td>
                      <td
                        align={"left"}
                        className="tdmedcellNew"
                        style={{ fontWeight: 600 }}
                      >
                        {langugae[2].total ? langugae[2].total : ""}
                      </td>
                      {dataToSet.stockStakeHolderGstType == 3 && (
                        <>
                          <td className="tdmedcellNew"></td>
                        </>
                      )}
                      <td className="tdmedcellNew"></td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdmedcellNew"
                      >
                        {qtyTot}
                      </td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdmedcellNew"
                      >
                        {freeQtyTot}
                      </td>
                      <td className="tdmedcellNew"></td>

                      {dataToSet.stockStakeHolderGstType == 3 && (
                        <>
                          {toBillState.id == dataToSetState.id ? (
                            <>
                              <td className="tdmedcellNew"></td>

                              <td
                                align={"right"}
                                style={{ fontWeight: 600 }}
                                className="tdmedcellNew"
                              >
                                {cgstTot.toFixed(2)}
                              </td>
                              <td className="tdmedcellNew"></td>
                              <td
                                align={"right"}
                                style={{ fontWeight: 600 }}
                                className="tdmedcellNew"
                              >
                                {sgstTot.toFixed(2)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="tdmedcellNew"></td>
                              <td
                                align={"right"}
                                style={{ fontWeight: 600 }}
                                className="tdmedcellNew"
                              >
                                {igstTot.toFixed(2)}
                              </td>
                            </>
                          )}
                        </>
                      )}

                      {/* Already Align In Above code/* }
                      
                      {/* {toBillState.id == dataToSetState.id ? (
                        <>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                        </>
                      ) : (
                        <>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                          <td className="tdmedcellNew"></td>
                        </>
                      )} */}

                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdmedcellNew"
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <th className="thsmallcell">
                      {langugae[2].fillingType ? langugae[2].fillingType : ""}
                    </th>
                    {dataToSet.categories.map((categoryData, index) => {
                      return (
                        <th className="thsmallcell">{categoryData.name}</th>
                      );
                    })}
                  </tr>
                  {dataToSet.packagingTypes.map((packagingTypeData) => {
                    return (
                      <tr>
                        <td align={"left"} className="tdmedcellNew">
                          {packagingTypeData.name}
                        </td>

                        {dataToSet.categories.map((categoryData) => {
                          let flag = 0;
                          return (
                            <>
                              {dataToSet.dtoCategoryWithPackagingTypes.map(
                                (rowData) => {
                                  if (
                                    categoryData.id == rowData.category.id &&
                                    rowData.packagingType.id ==
                                      packagingTypeData.id
                                  ) {
                                    flag = 1;
                                    return (
                                      <td
                                        align={"right"}
                                        className="tdmedcellNew"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {rowData.totalQty}
                                      </td>
                                    );
                                  }
                                }
                              )}
                              {flag == 0 && (
                                <td
                                  align={"left"}
                                  className="tdmedcellNew"
                                ></td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
                </table>

                <br />
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td align={"left"} colSpan={3} className="tdmedcellNew">
                      {langugae[2].note ? langugae[2].note + " : " : ""}
                      {dataToSet.orderRemarks != null
                        ? dataToSet.orderRemarks
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdmedcellNew">
                      1.{" "}
                      {langugae[2].orderBy ? langugae[2].orderBy + " : " : ""}
                      {dataToSet.salesTeamNames ? dataToSet.salesTeamNames : ""}
                      {"-"}
                      {dataToSet.salesTeamNo ? dataToSet.salesTeamNo : ""}
                    </td>
                    {localStorage.getItem("upi") != "" &&
                      localStorage.getItem("upi") != null && (
                        <td
                          align={"center"}
                          width={"25%"}
                          rowSpan={3}
                          className="tdmedcellNew"
                        >
                          <QRCode
                            value={`upi://pay?pa=${encodeURIComponent(
                              localStorage.getItem("upi")
                            )}`}
                          />
                        </td>
                      )}

                    <td
                      align={"left"}
                      className="tdmedcellNew"
                      style={{ borderBottom: "none" }}
                    >
                      {dataToSet.stockStakeHolderFirmName
                        ? dataToSet.stockStakeHolderFirmName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdmedcellNew">
                      2.{" "}
                      {langugae[2].goodsCheckBeforeReceived
                        ? langugae[2].goodsCheckBeforeReceived + " : "
                        : ""}
                    </td>
                    <td
                      align={"left"}
                      style={{ borderRight: "1px solid rgb(172, 172, 172)" }}
                    ></td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdmedcellNew">
                      3.{" "}
                      {langugae[2].subjectToJurisdiction
                        ? langugae[2].subjectToJurisdiction + " : "
                        : ""}
                      {localStorage.getItem("districtName")}
                    </td>
                    <td
                      align={"left"}
                      className="tdmedcellNew"
                      style={{ borderTop: "none" }}
                    >
                      {langugae[2].aurthorisedSign
                        ? langugae[2].aurthorisedSign
                        : ""}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            {Object.keys(invoiceJson).length !== 0 && (
              <>
                <div
                  style={{
                    padding: "22px",
                  }}
                >
                  E-Invoice ACK No :{" "}
                  {invoiceJson.ackNo ? invoiceJson.ackNo : "-"} {" , "}
                  E-Invoice ACK Date :{" "}
                  {invoiceJson.ackDate ? invoiceJson.ackDate : "-"} <br />
                </div>

                <td
                  style={{
                    textAlign: "left",
                    width: "50%",
                    borderCollapse: "collapse",
                    padding: "0 0 0 22px",
                  }}
                >
                  <QRCode
                    value={
                      invoiceJson.signedQRCode ? invoiceJson.signedQRCode : "-"
                    }
                    size={256}
                  />
                </td>
              </>
            )}

            {index + 1 != billList.length && (
              <>
                <div className="pagebreak"></div>
              </>
            )}
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);

// Define a functional component
// const QRCodeGenerator = () => {
//   // Data to be encoded in the QR code
//   const data = 'https://example.com';

//   return (
//     <div>
//       <h1>QR Code Generator</h1>
//       {/* Use the QRCode component and pass the data as a prop */}

//     </div>
//   );
// };

// // Export the component
// export default QRCodeGenerator;
