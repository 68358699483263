import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import NewMultipleSelect from "../Comman/NewMultipleSelect";
import dayjs from 'dayjs';
import TimePickerCompo from "../Comman/TimePicker";
import { labelColor } from "../../config/ColorObj";
class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  componentDidMount() { }

  onAutocompleteChange = (dataKey) => (name, newValue) => {
    this.props.onDataChange(dataKey, newValue);
  };
  
  // multiSelectHandler = (value, name) => {
  //   this.setState({
  //     ...this.state,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
    const filterData = dataValue.filter((rowData) => rowData == 0);
    if (filterData.length != 0) {
      dataValue = dynamicMasterData[masterName].map((rowData) => {
        return rowData.id;
      });
    }
    this.props.onDataChange(dataKey, dataValue);
  };
  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSave();
    }
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.onSubmitCheck();
    } else {
      return false;
    }
  };
  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];

          if (typeof dataKeyValue === "string") {
            const dateArr = dataKeyValue?.split("-");

            dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          if (typeof compareWithValue === "string") {
            const dateArr = compareWithValue?.split("-");

            compareWithValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          switch (compareCond) {
            case "l":
              {
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }
              }
              break;
            case "le":
              {
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }
              }
              break;
            case "g":
              {
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }
              }
              break;
            case "ge":
              {
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }
              }
              break;
            case "eq":
              {
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }
              }
              break;
          }

          if (isError) {
            const compareWithFieldLabel = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSaveAndNext = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveAndNext();
    }
  };
  render() {
    const {
      fieldMeta,
      dynamicMasterData = {},
      fieldData,
      buttonCenter,
      marginTop,
      resetData,
      searchButton = true,
      PDFButton = false,
      showReset = true,
      formErrors,
    } = this.props;

    return (
      <>
        <Grid container sx={{ marginTop: marginTop }} spacing={1}>
          {fieldMeta.map((currentField, index) => {
            return (
              <Grid
                item
                xs={currentField.xs}
                sm={currentField.sm}
                md={currentField.md}
                sx={{ marginTop: currentField.marginTop }}
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={currentField.label + " ( D" + index + " )"}
                />
                {currentField.controlType === "textfield" && (
                  <TextField
                    placeholder={currentField.placeHolder}
                    size="small"
                    color="primary"
                    name={currentField.label}
                    id={currentField.label}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    inputProps={{
                      maxLength:
                        currentField.maxLength && currentField.maxLength,
                    }}
                    type={
                      currentField.inputType ? currentField.inputType : "text"
                    }
                    onChange={this.onTextFieldDataChange(currentField.dataKey)}
                    fullWidth
                    error={
                      formErrors[currentField.dataKey]?.isError ? true : false
                    }
                    helperText={
                      formErrors[currentField.dataKey]?.isError
                        ? formErrors[currentField.dataKey].errorText
                        : " "
                    }
                  ></TextField>
                )}
                {currentField.controlType === "hideTextfield" &&
                  fieldData[currentField.tableDataKey] == currentField.open && (
                    <TextField
                      placeholder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey
                      )}
                      fullWidth
                      error={
                        formErrors[currentField.dataKey]?.isError ? true : false
                      }
                      helperText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : " "
                      }
                    ></TextField>
                  )}
                {currentField.controlType === "datepicker" && (
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : null
                    }
                    {...(currentField.isMAxDate && {
                      maxDate: dayjs(),
                    })}
                    {...(currentField.isMinDate && {
                      minDate: dayjs(),
                    })}
                    fullWidth
                    onChange={this.onDateChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                )}
                {currentField.controlType === "autocomplete" && (
                  <AutoComplete
                    keyColName={"id"}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    options={
                      dynamicMasterData[currentField["masterName"]]
                        ? dynamicMasterData[currentField["masterName"]]
                        : []
                    }
                    onChange={this.onAutocompleteChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                )}
                {currentField.controlType === "multiSelect" && (
                  <NewMultipleSelect
                    er={
                      formErrors[currentField.dataKey]?.errorText
                    }
                    errorText={formErrors[currentField.dataKey]?.errorText}
                    name1={currentField.dataKey}
                    value1={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : []
                    }
                    names={
                      dynamicMasterData[currentField["masterName"]] &&
                      dynamicMasterData[currentField["masterName"]].length !=
                        0
                        ? [
                            { id: 0, name: "All" },
                            ...dynamicMasterData[currentField["masterName"]],
                          ]
                        : []
                    }
                    //onChange={this.multiSelectHandler}
                    onChange={this.onMultiSelectChange(
                      currentField.dataKey,
                      currentField["masterName"]
                    )}
                  />
                )}
                {currentField.controlType === "timePicker" && (
                  <TimePickerCompo
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    onChange={this.timeChangeHandler(currentField.dataKey)}
                  />
                )}
                {currentField.controlType === "radiogroup" && (
                  <>
                    <RadioGroup
                      row
                      aria-label={currentField.label}
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      onChange={this.onRadioChange(currentField.dataKey)}
                      size="small"
                    >
                      {currentField.radioGroupItems.map((radioItem) => (
                        <FormControlLabel
                          value={radioItem.value}
                          control={<Radio />}
                          label={radioItem.label}
                        />
                      ))}
                    </RadioGroup>
                    {currentField.note &&
                      currentField.note.split("@").map((note) => {
                        return (
                          <>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 12, color: labelColor }}
                              label={note}
                            />
                            <br></br>
                          </>
                        );
                      })}
                  </>
                )}
              </Grid>
            );
          })}
        </Grid>
        {(showReset) && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >

            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Reset"
                fullWidth={true}
                onClick={resetData}
              />
            </Grid>


          </Grid>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
