import { Grid, Paper } from "@mui/material";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getMapData,
  getTimelineForSalesExecutive,
} from "../../Slice/location.slice";
import endpoint from "../../config/endpoints";
import { noInternetMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import "../Dashboard/styles.css";
const MapNetwork = ({
  showNotification,
  getMapData,

  location,
  showLoador,
  getTimelineForSalesExecutive,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k",
  });
  const params = useParams();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([
    {
      index: 1,
      id: "b9e5737d-0666-45f2-8210-c7fe838a9e7f",
      address: "Shreeram Enterprises",
      lat: 21.113979,
      lng: 79.131304,
      icon: "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",
      amount: "963.00",
    },
    {
      index: 2,
      id: "b9e5737d-0666-45f2-8210-c7fe838a9e7f",
      address: "Ekta Agency",
      lat: 21.191274,
      lng: 79.067072,
      icon: "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",
      amount: "963.00",
    },
  ]);
  const [polylines, setPolylines] = useState([]);
  const [polylines1, setPolylines1] = useState([]);

  useEffect(() => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.stackholder +
        "/with-beat-and-outlet-by-stock-stake-holder?stockStakeHolderId=04a54025-398d-4af0-9e74-be24e13b5bed",
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        const beatNetwork = data?.data?.dtoBeatWithOutlets?.filter(
          (beatObject) => beatObject.outlets.length != 0
        );
        let distributorAddress = {
          id: data.data.stockStakeHolderOpt.id,
          lat: Number(data?.data?.stockStakeHolderOpt?.latitude),
          lng: Number(data?.data?.stockStakeHolderOpt?.longitude),
        };
        let outletListForSave = [];
        let outletListForUpdated = [];
        let beatNetworkList =
          beatNetwork.length !== 0 &&
          beatNetwork.map((beatNetworkObject) => {
            Object.keys(beatNetworkObject).length !== 0 &&
              beatNetworkObject.outlets.length !== 0 &&
              beatNetworkObject.outlets.map((outletObject, index) => {
                outletObject = {
                  id: outletObject.id,
                  lat: Number(outletObject?.latitude),
                  lng: Number(outletObject?.longitude),
                  address: "",
                  amount: 0.0,
                  index: index,
                  ind: 0,
                };
                outletListForSave.push(outletObject);
              });

            return {
              beat: beatNetworkObject.beat,
              outlets: [distributorAddress, ...outletListForSave],
            };
          });

        setPolylines(beatNetworkList);
        // beatNetworkList.map((beatObject) => {
        //   beatObject.outlets.map((outletObject, index) => {
        //     outletListForUpdated.push(outletObject);
        //   });
        // });
        // setMarkers(outletListForUpdated);
      }
    });
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.stackholder +
        "/with-beat-and-outlet-by-stock-stake-holder?stockStakeHolderId=60e4e904-2c33-415b-ab4a-b22f94e72060",
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        const beatNetwork = data?.data?.dtoBeatWithOutlets?.filter(
          (beatObject) => beatObject.outlets.length != 0
        );
        let distributorAddress = {
          id: data.data.stockStakeHolderOpt.id,
          lat: Number(data?.data?.stockStakeHolderOpt?.latitude),
          lng: Number(data?.data?.stockStakeHolderOpt?.longitude),
        };
        let outletListForSave = [];
        let outletListForUpdated = [];
        let beatNetworkList =
          beatNetwork.length !== 0 &&
          beatNetwork.map((beatNetworkObject) => {
            Object.keys(beatNetworkObject).length !== 0 &&
              beatNetworkObject.outlets.length !== 0 &&
              beatNetworkObject.outlets.map((outletObject, index) => {
                outletObject = {
                  id: outletObject.id,
                  lat: Number(outletObject?.latitude),
                  lng: Number(outletObject?.longitude),
                  address: "",
                  amount: 0.0,
                  index: index,
                  ind: 0,
                };
                outletListForSave.push(outletObject);
              });

            return {
              beat: beatNetworkObject.beat,
              outlets: [distributorAddress, ...outletListForSave],
            };
          });

        setPolylines1(beatNetworkList);
        // beatNetworkList.map((beatObject) => {
        //   beatObject.outlets.map((outletObject, index) => {
        //     outletListForUpdated.push(outletObject);
        //   });
        // });
        // setMarkers(outletListForUpdated);
      }
    });
  }, []);

  const getMapList = (dataObj) => {
    if (navigator.onLine) {
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (index, id, lat, lng, address, mainId, amount) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ index, id, address, amount });
    setIsOpen(true);
  };

  const onDateChange = (dateObj) => {
    setDate(dateObj);
    getMapList(dateObj.format("DD-MM-YYYY"));
  };

  const tabValueHandler = (value) => {
    let filterDaylist = location.salesTeamTimeline.filter(
      (row) => row.index == value
    );
    if (filterDaylist.length !== 0) {
      getMapList(filterDaylist[0].date);
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 2,
      }}
      elevation={0}
    >
      <Grid
        style={{ height: "90vh" }}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                onLoad={onMapLoad}
                onClick={() => setIsOpen(false)}
              >
                {console.log(polylines)}
                {markers.map(
                  ({ index, address, lat, lng, id, icon, amount }, ind) => (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      onClick={() => {
                        handleMarkerClick(
                          index,
                          ind,
                          lat,
                          lng,
                          address,
                          id,
                          amount
                        );
                      }}
                      icon={{
                        url: icon,
                        scaledSize: new google.maps.Size(100, 100),
                      }}
                    >
                      {isOpen && infoWindowData?.id === ind && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <h3>{infoWindowData.address}</h3>
                        </InfoWindow>
                      )}
                      {polylines.map((polylinesObject) => {
                        return (
                          <>
                            <Polyline
                              path={polylinesObject.outlets}
                              geodesic={true}
                              options={{
                                strokeColor: "#eda427",
                                strokeOpacity: 0.75,
                                strokeWeight: 2,
                                icons: [
                                  {
                                    // icon:"https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                                    offset: "0",
                                    repeat: "20px",
                                  },
                                ],
                              }}
                            />
                            ;
                          </>
                        );
                      })}

                      {polylines1.map((polylinesObject) => {
                        return (
                          <>
                            <Polyline
                              path={polylinesObject.outlets}
                              geodesic={true}
                              options={{
                                strokeColor: "#00008B",
                                strokeOpacity: 0.75,
                                strokeWeight: 2,
                                icons: [
                                  {
                                    // icon:"https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                                    offset: "0",
                                    repeat: "20px",
                                  },
                                ],
                              }}
                            />
                            ;
                          </>
                        );
                      })}
                    </Marker>
                  )
                )}
              </GoogleMap>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  location: state.location,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMapData,
  getTimelineForSalesExecutive,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapNetwork);
