import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { OutletMovingJson } from "../../DynamicFormsJson/MastersJSON/outletMovingJson";
import { OutletMovingFooterJsons } from "../../DynamicFormsJson/MastersJSON/outletMovingFooterJson";
import { getRegionNew } from "../../Slice/region.slice";
import { OutletMovingColumns } from "../../tableColumns/table-columns";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import {
  sendCredentialMsg,
  generateCredentialMsg,
  FailTogenerateCredentialMsg,
  serverMsg,
  noInternetMsg,
  checkedListEmptyMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTaluka } from "../../Slice/area.slice";
import { getBeatByArea, getBeatByUserType } from "../../Slice/beat.slice";
import {
  getOutletByBeatIdsPost,
  setOutletByBeatIds,
} from "../../Slice/outlet.slice";
import { saveFailedMsg, savemsg } from "../../config/messageconstant";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { Grid, Paper } from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";
import OutletMovingPopUp from "./OutletMovingPopUp";
class OutletMoving extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
      },
      userType: "",
      openPopUp: false,
      selection: [],
      beatFooterIds: [],
      beatIds: [],
    };
  }
  async componentDidMount() {
    const { userType } = this.state;
    const userTypeId = localStorage.getItem("userTypeId");
    const { getRegionNew, getBeatByUserType, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      // showLoador({ loador: true });
      // apiGet({
      //     url:
      //         endpoint.userType + "/by-session-user",
      // }).then(({ data, success }) => {
      //     showLoador({ loador: false });
      //     if (!success) {
      //         showNotification({ msg: serverMsg, severity: "error" });
      //     } else {
      //         this.setState({
      //             userType: data.data.id,
      //         })
      //     }
      // });

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          userType: true,
        });
      }

      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getBeatByUserType().then(({ response, success }) => {
        console.log(response);
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    const { getOutletByBeatIdsPost, showLoador, showNotification } = this.props;
    this.setState({
      beatIds: data.beat.split(","),
    });
    if (navigator.onLine) {
      let outletByBeatIdsJson = {
        beatIds: data.beat.split(",")
      }
      showLoador({ loador: true });
      await getOutletByBeatIdsPost({ beatIds: outletByBeatIdsJson }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getBeatByArea,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.outletList?.outlets.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return { ...rowData, checked: filterData.length != 0 ? true : false };
    });
    this.props.setOutletByBeatIds({ row: rowList });
  };

  handleCloses = () => {
    this.setState({ openPopUp: false });
  };

  onApprove = (data) => {
    const { showNotification, outletList } = this.props;
    const { selection } = this.state;
    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      this.setState({ openPopUp: true, beatFooterIds: data.beatFooter });
      const rowList = outletList?.outlets.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      this.props.setOutletByBeatIds({ row: rowList });
    }
  };

  // getData = () => {
  //     const { beatFooterIds } = this.state;
  //     let beatList =
  //         this.props.outletList?.outlets.filter((row) => row.checked);
  //     let beatDataToSave = beatList.map(
  //         (beatDataObject) => {
  //             return beatDataObject.id;
  //         }
  //     );
  //     if (navigator.onLine) {
  //         showLoador({ loador: true });
  //         apiGet({
  //             url: endpoint.outlet + "/update?beatId=" + beatFooterIds + "&ids=" + beatDataToSave.join(),
  //         }).then(({ success }) => {
  //             if (success) {
  //                 this.handleCloses();
  //                 showLoador({ loador: false });
  //                 showNotification({
  //                     msg: savemsg,
  //                 });
  //             } else {
  //                 showNotification({
  //                     msg: serverMsg,
  //                     severity: "error",
  //                 });
  //                 showLoador({ loador: false });
  //             }
  //         });
  //     } else {
  //         showNotification({ msg: noInternetMsg, severity: "error" });
  //     }
  // }

  saveMovingOutlet = () => {
    const { showLoador, showNotification } = this.props;
    const { beatFooterIds } = this.state;
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let beatList = this.props.outletList?.outlets.filter(
          (row) => row.checked
        );
        let beatDataToSave = beatList.map((beatDataObject) => {
          return beatDataObject.id;
        });
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiGet({
            url:
              endpoint.outlet +
              "/update?beatId=" +
              beatFooterIds +
              "&ids=" +
              beatDataToSave.join(),
          }).then(({ success }) => {
            if (success) {
              this.handleCloses();
              showLoador({ loador: false });
              showNotification({
                msg: savemsg,
              });
              window.location.replace("/outlet-moving");
            } else {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  setDynamicMasterData = () => {
    const { beatIds, beatFooterIds } = this.state;
    console.log(beatIds);

    let beatDetail = this.state.dynamicMasterData.beatDetail
      ? this.state.dynamicMasterData.beatDetail
      : [];
    let list = [];
    beatDetail.map((rowData) => {
      let filterData = beatIds.filter((row) => row == rowData.id);
      if (filterData == 0) {
        list.push(rowData);
      }
    });
    return {
      beatDetail: list,
    };
  };


  rowStatus = () => {
  }

  render() {
    const { outletList } = this.props;
    const { dynamicMasterData, userType, selection, openPopUp } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={false}
            dynamicMasterData={dynamicMasterData}
            showTitle={true}
            getListById={this.getListById}
            screenTitle={OutletMovingJson.screenTitle}
            fieldMeta={
              userType == 1
                ? OutletMovingJson.fieldMeta
                : OutletMovingJson.fieldData
            }
            showSaveBtnMain={false}
            showSaveNextBtn={false}
            showSaveBtn={true}
            saveBtnText={"Search"}
            onSave={this.onSearchData}
            padding={true}
            paddingTop={true}
          />

          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={[
              {
                title: "Sr.No.",
                name: "index",
                showInExcel: true,
                align: "center",
              },
              {
                title: "Outlet Name",
                name: "name",
                formDataKey: "firmName",
                canSearch: true,
              },
              {
                title: "Mobile No.",
                name: "mobileNo",
                formDataKey: "mobileNo",
                canSearch: true,
                alignCenter: "center",
              },
              {
                title: "Village",
                name: "villageCity",
                formDataKey: "villageCity",
                canSearch: true,
              },
              {
                title: "Address",
                name: "address",
                formDataKey: "address",
                canSearch: true,
              },
              {
                title: "Sort Order No",
                name: "sortOrder",
                formDataKey: "sortOrder",
                canSearch: true,
                alignCenter: "center",
              },
              {
                title: "Status",
                name: "isActive",
                formDataKey: "isActive",
                canSearch: true,
              },
            ]}
            rows={outletList?.outlets}
            isActionColActive={false}
            rowStatus={this.rowStatus}
          />
          <br />
          {outletList?.outlets.length != 0 && (
            <>
              <br />
              <DynamicFormWithoutSave
                showBackToList={false}
                dynamicMasterData={this.setDynamicMasterData()}
                showSaveBtn={false}
                showTitle={OutletMovingFooterJsons.showTitle}
                screenTitle={OutletMovingFooterJsons.screenTitle}
                fieldMeta={OutletMovingFooterJsons.fieldMeta}
                showCancel={false}
                showSaveNextBtn={false}
                saveBtnText={"Proceed"}
                onSave={this.onApprove}
              />
            </>
          )}
        </Paper>
        {openPopUp && (
          <>
            <OutletMovingPopUp
              handleCloses={this.handleCloses}
              open={open}
              formData={{}}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  align: "center",
                  showInscreen: true,
                },
                {
                  title: "Stock Holder",
                  name: "name",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
              ]}
              onFormSave={this.saveMovingOutlet}
              rows={outletList?.outlets.filter((row) => row.checked)}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderByAreaList: state.stockholder,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
  outletList: state.outlet,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStateByRegion,
  getRegionNew,
  getBeatByArea,
  getOutletByBeatIdsPost,
  setOutletByBeatIds,
  getBeatByUserType,
};
export default connect(mapStateToProps, mapDispatchToProps)(OutletMoving);
