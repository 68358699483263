import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { titleColor } from "../../config/ColorObj";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const RemarkPopUp = ({
    handleCloses,
    open,
    remarkOnOptionClick,
    dataRow
}) => {
    const handleClose = () => {
        handleCloses();
    };

    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Outlet Remark
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            spacing={2}
                            padding={4}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "15px",
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Firm Name : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.firmName ? dataRow.firmName : "-"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Owner Name : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.ownerName ? dataRow.ownerName : "-"}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "15px",
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Outlet Code : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.outletCode ? dataRow.outletCode : "-"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Contact No : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.mobileNo ? dataRow.mobileNo : "-"}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "15px",
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Beat Name : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.beatName ? dataRow.beatName : "-"}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Village/City : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16 }}
                                                label={dataRow.villageCity ? dataRow.villageCity : "-"}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        spacing={2}
                                    >
                                        <Grid item xs={3.5} sm={3.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 18, color: titleColor }}
                                                label="Remark : "
                                            />
                                        </Grid>

                                        <Grid item xs={8.5} sm={8.5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 18 }}
                                                label={remarkOnOptionClick}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>

                        </Grid>

                        <br />
                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    showLoador,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemarkPopUp);
