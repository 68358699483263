import endpoint from "../../config/endpoints";

export const productCatelogueJson = {
    formPath: "/product-catelogue-form",
    apiBaseURL: endpoint.productCatelogue,
    screenTitle: "Product Catelogue",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Product",
            columnKeyName: "productName",
            isChecked: true,
        },

        {
            columnDisplayName: "Type",
            columnKeyName: "typeName",
            isChecked: true,
        },

        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Product",
            controlType: "autocomplete",
            placeHolder: "Select Product",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "productDetail",
            dataKey: "product",
            isRootLevelKey: true,
            isMandatory: true,
        },

        {
            label: "Product Name",
            controlType: "textfield",
            placeHolder: "Product Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "productName",
            isMandatory: true,
        },

        {
            label: "Product MRP",
            controlType: "textfield",
            placeHolder: "Product MRP",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "productMrp",
            isMandatory: true,
        },


        {
            label: "Product MRP",
            controlType: "textfield",
            placeHolder: "Product MRP",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "productMrp",
            isMandatory: true,
        },

        {
            label: "Product Retail Rate",
            controlType: "textfield",
            placeHolder: "Product Retail Rate",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "productRetailRate",
            isMandatory: true,
        },

    ]
};
