export const labelColor = "#808080";
export const titleColor = "#00bcd4";
export const redColor = "#ff0000";
export const white = "#fff";
export const DarkBlue = "#2196F3";
export const cyanBlue = "#2196f3";
export const blackColor = "#000";
export const checkboxColor = "#1976d2";
export const greyColor = "#808080";
export const darkgreyColor = "#5a5a5a";
export const yellowishBrownColor = "#E97451";
export const checkedColor = "#d4b69e";
export const DimGrayColor = "#666666";
export const LightYellowColor = "#F4D03F";
export const LightGreenColor = "#58D68D";
export const checkboxShadowColor = "#acacac";
export const yellowColor = "#fbff00";
export const figmaTitleColor = "#004969";
export const lightVioletColor = "#BA92F1";
export const smoothGreenColor = "#BDFB7F";



