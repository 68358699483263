import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
const initialState = {
  msg: "",
  severity: "",
  loador: false,
  loador1: false,
  isShowNotification: false,
};
const newsDocNotificationSlice = createSlice({
  name: "newsDocNotification",
  initialState,
  reducers: {
    showNotificationSuccess: (state = cloneDeep(initialState), action) => {
      const { msg, severity } = action.payload;
      return {
        ...state,
        msg: msg,
        severity: severity,
        isShowNotification: true,
      };
    },
    showNotificationFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        msg: "",
        severity: "",
        isShowNotification: false,
      };
    },
    showLoadorSuccess: (state = cloneDeep(initialState), action) => {
      const { loador } = action.payload;
      return {
        ...state,
        loador: loador,
      };
    },
    showLoador1Success: (state = cloneDeep(initialState), action) => {
      const { loador } = action.payload;
      return {
        ...state,
        loador1: loador,
      };
    },
  },
});

export const {
  showNotificationSuccess,
  showNotificationFail,
  showLoadorSuccess,
  showLoador1Success,
} = newsDocNotificationSlice.actions;

export default newsDocNotificationSlice.reducer;

export const showNotification =
  ({ msg, severity }) =>
  async (dispatch) => {
    try {
      let loginchecked = localStorage.getItem("loginCheck");
      console.log(loginchecked);
      let msgTest = loginchecked == "1" && loginchecked != null ? msg : "";
      console.log(msgTest);
      if (msgTest != "") {
        dispatch(showNotificationSuccess({ msg: msgTest, severity }));
      }
    } catch (e) {
      return console.error(e.message);
    }
  };

export const closeNotification = () => async (dispatch) => {
  try {
    dispatch(showNotificationFail());
  } catch (e) {
    return console.error(e.message);
  }
};
export const showLoador =
  ({ loador }) =>
  async (dispatch) => {
    try {
      dispatch(showLoadorSuccess({ loador }));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const showLoador1 =
  ({ loador }) =>
  async (dispatch) => {
    try {
      dispatch(showLoador1Success({ loador }));
    } catch (e) {
      return console.error(e.message);
    }
  };
