import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { ProductRateFooterJson } from "../../DynamicFormsJson/Transaction/productRateFooter";
import { getStructureAssignation, setStructureAssignation } from "../../Slice/productRateStructure.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  publishDateMsg,
  saveWarningMsg,
  savemsg,
  serverMsg
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { withRouter } from "../../components/withRouter";
import { compose } from "recompose";
import moment from "moment";

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Stock Holder",
    name: "stockStakeHolderFirmName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Structure",
    name: "productRateStructureName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },

  {
    title: "Publish Date",
    name: "publishDate",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
    canSearch: true,
  },
  // {
  //   title: "Action",
  //   name: "action",
  //   positionCenter: false,
  //   showInscreen: true,
  //   alignCell: "center",
  //   alignCenter: "left",
  // },
];
class StructureAssignation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesTeamId: "",
      setList: [],
      checkList: [],
      isError: false,
      areaName: "",
      jsonByStructureId: "",
      open: false,
      DataToSet: [],
      selection: [],
      dynamicMasterData: {
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getRegionNew, getStockStakeHolderType, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.productRateStructure + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              jsonByStructureId: data.data.json,
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    const {
      getStructureAssignation,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        let structureJsonData = {
          stockStakeHolderTypeId: data.stockStakeHolderType,
          productRateStructureId: this.props.params.id,
          areaIds: data.area.split(","),
        };
        showLoador({ loador: true });
        getStructureAssignation({
          structureData: structureJsonData,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.structureList.structureAssignation.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
    this.props.setStructureAssignation({ row: rowList });
  };

  saveAssignStructure = (data) => {
    const { jsonByStructureId, selection } = this.state;
    const { showNotification, showLoador, structureList } = this.props;

    let listOfCheckedStockIds = [];
    let publishStructure = {};
    let dtoStockHolderAndAssignations = [];




    let assignStructureList =
      structureList.structureAssignation.filter(
        (row) => row.checked
      );

    listOfCheckedStockIds = assignStructureList.map(
      (assignStructureObject) => assignStructureObject.stockStakeHolderId,
    );


    dtoStockHolderAndAssignations = assignStructureList.map((publishObject) => {
      return {
        productRateStructureAssignationId: publishObject.id,
        stockStakeHolderId: publishObject.stockStakeHolderId,
      };
    });

    publishStructure = {
      productRateStructureId: this.props.params.id,
      publishDate: data.publishDate,
      dtoStockHolderAndAssignations: dtoStockHolderAndAssignations,
    };

    //Old JSON (COMMENT BY TEJAS 15/11/24)
    // let assignStructureDataToSave = assignStructureList.map(
    //   (assignStructureObject) => {
    //     let assignStructureData = {
    //       id: assignStructureObject.id,
    //       productRateStructure: {
    //         id: this.props.params.id
    //       },
    //       stockStakeHolder: {
    //         id: assignStructureObject.stockStakeHolderId,
    //       },
    //       publishDate: data.publishDate,
    //       json: jsonByStructureId,

    //     };
    //     return assignStructureData;
    //   }
    // );

    if (
      structureList.structureAssignation.filter((row) => row.checked)
        .length === 0
    ) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.productRateStructureAssignation + "/by-stock-stake-holder-isd-and-publlish-date?stockStakeHolderIds=" + listOfCheckedStockIds + "&publishDate=" + data.publishDate,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                if (data.data.length !== 0) {
                  showNotification({
                    msg: publishDateMsg, severity: "error",
                  });
                } else {
                  showLoador({ loador: true });
                  apiPost({
                    url: endpoint.productRateStructureAssignation + "/save",
                    postBody: publishStructure,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (success) {
                      showNotification({
                        msg: savemsg,
                      });
                      this.props.navigate(-1);
                    } else {
                      showNotification({
                        msg: serverMsg,
                        severity: "error",
                      });
                      showLoador({ loador: false });
                    }
                  });
                }
              } else {
                showNotification({
                  msg: serverMsg,
                  severity: "error",
                });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    }
  };

  render() {
    const { structureList } = this.props;
    const { dynamicMasterData, setList, selection } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            showBackToList={true}
            getListById={this.getListById}
            showSaveBtn={false}
            showSaveBtnMain={true}
            showTitle={ProductRateStructureJson.showTitle}
            screenTitle={"Structure Assignation"}
            fieldMeta={ProductRateStructureJson.fieldData}
            showCancel={ProductRateStructureJson.showCancel}
            apiBaseURL={ProductRateStructureJson.apiBaseURL}
            showSaveNextBtn={ProductRateStructureJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />
          <CheckBoxSelectionTable
            disabled={false}
            selection={selection}
            rows={structureList.structureAssignation}
            columns={columns}
            setList={setStructureAssignation}
            showDetailsIcon={false}
            isActionColActive={false}
            onSelectionChange={this.getSelectedList}
          />
          {structureList.structureAssignation.length != 0 && (
            <>
              <br />
              <DynamicFormWithoutSave
                showBackToList={false}
                dynamicMasterData={dynamicMasterData}
                showSaveBtn={false}
                showTitle={ProductRateFooterJson.showTitle}
                screenTitle={ProductRateFooterJson.screenTitle}
                fieldMeta={ProductRateFooterJson.fieldMeta}
                showCancel={ProductRateFooterJson.showCancel}
                apiBaseURL={ProductRateFooterJson.apiBaseURL}
                showSaveNextBtn={false}
                saveBtnText={"Submit"}
                onSave={this.saveAssignStructure}
              />
            </>
          )}
          {/* {structureList.structureAssignation.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.saveAssignStructure}
                />
              </Grid>
            </>
          )} */}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stockStakeHolderType: state.stockStakeHolderType,
  structureList: state.productRateStructure,
});
const mapDispatchToProps = {
  getStateByRegion,
  getZoneByState,
  getStockStakeHolderType,
  getRegionNew,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  showLoador,
  showNotification,
  getStructureAssignation,
  setStructureAssignation,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StructureAssignation);