import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { ManualOrderAdminJson } from "../../DynamicFormsJson/Transaction/manualOrderAdminJson";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { getStockStackHolders, getStockholderByUser } from "../../Slice/StackHolder.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import ManualOrderAdminBillingWithOffer from "./ManualOrderAdminBillingWithOffer";
import ManualOrderAdminTable from "./ManualOrderAdminTable";
import ManualOrderPopup from "./ManualOrderPopup";
import dayjs from "dayjs";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class ManualOrderAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
      searchValue: "",
      formErrors: {},
      fieldData: { flag: "0" },
      DataToSet: [],
      value: 0,
      total: 0,
      totalQty: 0,
      totalAmt: 25.5,
      open: false,
      openNew: false,
      orderList: [],
      salesPersonId: "",
      flag: false,
      manualOrderAdminPrimary: false,
      offerBillData: {},
      orderWithBillingFlag: 0,
    };
  }

  setCatList = ({ row }) => {
    console.log(row);
    const { setCategoryAndItem } = this.props;
    const { flag, manualOrderAdminPrimary } = this.state;
    let dataTotal = 0;
    let totalQty = 0;

    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          totalQty = totalQty + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
      totalQty: totalQty,
    });
    if (flag) {
      let newList = row.map((rowData) => {
        let rowList = rowData.productWithMarginAndMrps.map((productData) => {
          console.log(productData);
          if (
            productData.productStock > 0 &&
            productData.productStock < +productData.qty &&
            manualOrderAdminPrimary
          ) {
            let productNewData = {
              ...productData,
              stockError: 1,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock < +productData.freeQty &&
            manualOrderAdminPrimary
          ) {
            let productNewData = {
              ...productData,
              stockError: 2,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock <
            +productData.qty + +productData.freeQty &&
            manualOrderAdminPrimary
          ) {
            let productNewData = {
              ...productData,
              stockError: 3,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock >= +productData.qty &&
            manualOrderAdminPrimary
          ) {
            let productNewData = {
              ...productData,
              stockError: 0,
            };
            return productNewData;
          } else {
            return productData;
          }
        });
        let rowNewList = {
          ...rowData,
          productWithMarginAndMrps: rowList,
        };
        return rowNewList;
      });
      setCategoryAndItem({ row: newList });
    } else {
      setCategoryAndItem({ row });
    }
  };

  async componentDidMount() {
    const { getStockStackHolders, getStockholderByUser, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 3) {
        showLoador({ loador: true });
        await getStockholderByUser().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showLoador({ loador: true });
        await getStockStackHolders().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }



      const orderWithBillingForAdmin = localStorage.getItem("orderWithBillingForAdmin");
      this.setState({
        orderWithBillingFlag: orderWithBillingForAdmin,
      });



      const stockCalAtBill = localStorage.getItem("stockCalAtBill");
      this.setState({
        flag: stockCalAtBill === "1" ? true : false,
      });


      const manualOrderWithBillingForPrimary = localStorage.getItem("manualOrderWithBillingForPrimary");
      this.setState({
        manualOrderAdminPrimary: manualOrderWithBillingForPrimary === "0" ? true : false,
      });


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandlerForProceed = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      const { flag } = this.state;
      let rowList = [];
      let filterData = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0 && row.stockError == 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        if (flag) {
          this.setState({
            orderList: rowList.map((rowData, index) => {
              return {
                index: index + 1,
                product: rowData.products !== null ? rowData.products : "",
                subCategory:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.id
                    ? rowData.products.group.subCategory.id
                    : "",
                category:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.id
                    ? rowData.products.group.subCategory.category.id
                    : "",
                categoryName:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.name
                    ? rowData.products.group.subCategory.category.name
                    : "",
                categoryShortName:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.shortName
                    ? rowData.products.group.subCategory.category.shortName
                    : "",
                categorySortOrder:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.sortOrder
                    ? rowData.products.group.subCategory.category.sortOrder
                    : "",
                isActive:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.isActive
                    ? rowData.products.group.subCategory.category.isActive
                    : "",
                insertDateTime:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.insertDateTime
                    ? rowData.products.group.subCategory.category.insertDateTime
                    : "",
                taxPercentage:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.id
                    ? rowData.products.taxPercentage.id
                    : "",
                taxName:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.name
                    ? rowData.products.taxPercentage.name
                    : "",
                taxSgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.sgst
                    ? rowData.products.taxPercentage.sgst
                    : "",
                taxCgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.cgst
                    ? rowData.products.taxPercentage.cgst
                    : "",
                taxIgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.igst
                    ? rowData.products.taxPercentage.igst
                    : "",
                taxCess:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.cess
                    ? rowData.products.taxPercentage.cess
                    : "",
                hsnCode:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.hsnCode
                    ? rowData.products.taxPercentage.hsnCode
                    : "",
                taxInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.insertDateTime
                    ? rowData.products.taxPercentage.insertDateTime
                    : "",
                stakeHolderUom:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.id
                    ? rowData.products.stakeHolderUom.id
                    : "",
                stakeHolderUom:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.id
                    ? rowData.products.stakeHolderUom.id
                    : "",
                productName:
                  rowData.products !== null && rowData.products.name
                    ? rowData.products.name
                    : "",
                outletName:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.name
                    ? rowData.products.stakeHolderUom.name
                    : "",
                outletisActive:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.isActive
                    ? rowData.products.stakeHolderUom.isActive
                    : "",
                outletInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.insertDateTime
                    ? rowData.products.stakeHolderUom.insertDateTime
                    : "",
                outletUom:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.id
                    ? rowData.products.outletUom.id
                    : "",
                outletUomName:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.name
                    ? rowData.products.outletUom.name
                    : "",
                outletUomIsActive:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.isActive
                    ? rowData.products.outletUom.isActive
                    : "",
                outletUomInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.insertDateTime
                    ? rowData.products.outletUom.insertDateTime
                    : "",
                uomConversionValue:
                  rowData.products !== null &&
                    rowData.products.uomConversionValue
                    ? rowData.products.uomConversionValue
                    : 0,
                productCode:
                  rowData.products !== null && rowData.products.code
                    ? rowData.products.code
                    : "",
                shortName:
                  rowData.products !== null && rowData.products.shortName
                    ? rowData.products.shortName
                    : "",
                weight:
                  rowData.products !== null && rowData.products.weight
                    ? rowData.products.weight
                    : "",
                description:
                  rowData.products !== null && rowData.products.description
                    ? rowData.products.description
                    : "",
                selfLife:
                  rowData.products !== null && rowData.products.selfLife
                    ? rowData.products.selfLife
                    : "",
                image:
                  rowData.products !== null && rowData.products.image
                    ? rowData.products.image
                    : "",
                sortOrder:
                  rowData.products !== null && rowData.products.sortOrder
                    ? rowData.products.sortOrder
                    : "",
                isActive:
                  rowData.products !== null && rowData.products.isActive
                    ? rowData.products.isActive
                    : "",
                insertDateTime:
                  rowData.products !== null && rowData.products.insertDateTime
                    ? rowData.products.insertDateTime
                    : "",
                mrp:
                  rowData.products !== null && rowData.products.mrp
                    ? rowData.products.mrp
                    : "",
                marginPercentage:
                  rowData.marginPercentage !== null
                    ? (+rowData.marginPercentage).toFixed(2)
                    : 0,
                mrp: rowData.mrp !== null ? (+rowData.mrp).toFixed(2) : 0,
                qty: rowData.qty !== null ? rowData.qty : 0,
                rate: rowData.rate !== null ? (+rowData.rate).toFixed(2) : 0,
                total: rowData.total.toFixed(2),
              };
            }),
          });
          this.toggle();
        } else {
          this.toggle();
        }
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };
  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      const { flag } = this.state;
      let rowList = [];
      let filterData = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0 && row.stockError == 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        if (flag) {
          this.setState({
            orderList: rowList.map((rowData, index) => {
              return {
                index: index + 1,
                product: rowData.products !== null ? rowData.products : "",
                subCategory:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.id
                    ? rowData.products.group.subCategory.id
                    : "",
                category:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.id
                    ? rowData.products.group.subCategory.category.id
                    : "",
                categoryName:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.name
                    ? rowData.products.group.subCategory.category.name
                    : "",
                categoryShortName:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.shortName
                    ? rowData.products.group.subCategory.category.shortName
                    : "",
                categorySortOrder:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.sortOrder
                    ? rowData.products.group.subCategory.category.sortOrder
                    : "",
                isActive:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.isActive
                    ? rowData.products.group.subCategory.category.isActive
                    : "",
                insertDateTime:
                  rowData.products !== null &&
                    rowData.products.group !== null &&
                    rowData.products.group.subCategory !== null &&
                    rowData.products.group.subCategory.category !== null &&
                    rowData.products.group.subCategory.category.insertDateTime
                    ? rowData.products.group.subCategory.category.insertDateTime
                    : "",
                taxPercentage:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.id
                    ? rowData.products.taxPercentage.id
                    : "",
                taxName:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.name
                    ? rowData.products.taxPercentage.name
                    : "",
                taxSgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.sgst
                    ? rowData.products.taxPercentage.sgst
                    : "",
                taxCgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.cgst
                    ? rowData.products.taxPercentage.cgst
                    : "",
                taxIgst:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.igst
                    ? rowData.products.taxPercentage.igst
                    : "",
                taxCess:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.cess
                    ? rowData.products.taxPercentage.cess
                    : "",
                hsnCode:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.hsnCode
                    ? rowData.products.taxPercentage.hsnCode
                    : "",
                taxInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.taxPercentage !== null &&
                    rowData.products.taxPercentage.insertDateTime
                    ? rowData.products.taxPercentage.insertDateTime
                    : "",
                stakeHolderUom:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.id
                    ? rowData.products.stakeHolderUom.id
                    : "",
                stakeHolderUom:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.id
                    ? rowData.products.stakeHolderUom.id
                    : "",
                productName:
                  rowData.products !== null && rowData.products.name
                    ? rowData.products.name
                    : "",
                outletName:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.name
                    ? rowData.products.stakeHolderUom.name
                    : "",
                outletisActive:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.isActive
                    ? rowData.products.stakeHolderUom.isActive
                    : "",
                outletInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.stakeHolderUom !== null &&
                    rowData.products.stakeHolderUom.insertDateTime
                    ? rowData.products.stakeHolderUom.insertDateTime
                    : "",
                outletUom:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.id
                    ? rowData.products.outletUom.id
                    : "",
                outletUomName:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.name
                    ? rowData.products.outletUom.name
                    : "",
                outletUomIsActive:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.isActive
                    ? rowData.products.outletUom.isActive
                    : "",
                outletUomInsertDateTime:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.insertDateTime
                    ? rowData.products.outletUom.insertDateTime
                    : "",
                uomConversionValue:
                  rowData.products !== null &&
                    rowData.products.uomConversionValue
                    ? rowData.products.uomConversionValue
                    : 0,
                productCode:
                  rowData.products !== null && rowData.products.code
                    ? rowData.products.code
                    : "",

                shortName:
                  rowData.products !== null && rowData.products.shortName
                    ? rowData.products.shortName
                    : "",
                weight:
                  rowData.products !== null && rowData.products.weight
                    ? rowData.products.weight
                    : "",
                description:
                  rowData.products !== null && rowData.products.description
                    ? rowData.products.description
                    : "",
                selfLife:
                  rowData.products !== null && rowData.products.selfLife
                    ? rowData.products.selfLife
                    : "",
                image:
                  rowData.products !== null && rowData.products.image
                    ? rowData.products.image
                    : "",
                sortOrder:
                  rowData.products !== null && rowData.products.sortOrder
                    ? rowData.products.sortOrder
                    : "",
                isActive:
                  rowData.products !== null && rowData.products.isActive
                    ? rowData.products.isActive
                    : "",
                insertDateTime:
                  rowData.products !== null && rowData.products.insertDateTime
                    ? rowData.products.insertDateTime
                    : "",
                mrp:
                  rowData.products !== null && rowData.products.mrp
                    ? rowData.products.mrp
                    : "",
                marginPercentage:
                  rowData.marginPercentage !== null
                    ? (+rowData.marginPercentage).toFixed(2)
                    : 0,
                mrp: rowData.mrp !== null ? (+rowData.mrp).toFixed(2) : 0,

                qty: rowData.qty !== null ? rowData.qty : 0,
                rate: rowData.rate !== null ? (+rowData.rate).toFixed(2) : 0,
                total: rowData.total.toFixed(2),
              };
            }),
          });
          this.getOrder(rowList);
        } else {
          this.getOrder(rowList);
        }
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };
  getListById = async (data) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({
        fieldData: data,
      });
      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.category +
          "/with-items-and-mrp-and-rate-and-stock-by-stake-holder?stockStakeHolderId=" +
          data.stakeholder,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success && data.data != null) {
          const rowData = data.data;
          if (rowData.errorMessage.error) {
            showNotification({
              msg: rowData.errorMessage.message,
              severity: "error",
            });
          } else {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.manageStockHeaderLatest + "/get-stock-by-current-date",
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                let stockList = data.data.productStocks.map(
                  (productStockObject) => {
                    let stockObject = {
                      productId: productStockObject.product.id,
                      stock: productStockObject.secondaryClosingQty,
                    };
                    return stockObject;
                  }
                );
                localStorage.setItem("stockList", JSON.stringify(stockList));
                let i;
                const row = rowData.categoryAndItems.map((rowObj) => {
                  return {
                    ...rowObj,
                    total: 0,
                    qty: 0,
                    productWithMarginAndMrps:
                      rowObj.productWithMarginAndMrps.map((product) => {
                        if (data.data.productStocks.length !== 0) {
                          for (i = 0; i < data.data.productStocks.length; i++) {
                            if (
                              product.products.id ==
                              data.data.productStocks[i].product.id
                            ) {
                              return {
                                ...product,
                                uomConversionValue:
                                  product.products.uomConversionValue,
                                qty: 0,
                                freeQty: 0,
                                itemDiscPer: 0,
                                productStock:
                                  data.data.productStocks[i].primaryClosingQty,
                                total: 0,
                                stockError: 0,
                              };
                            }
                          }
                        } else {
                          return {
                            ...product,
                            uomConversionValue:
                              product.products.uomConversionValue,
                            qty: 0,
                            freeQty: 0,
                            itemDiscPer: 0,
                            productStock: 0,
                            total: 0,
                            stockError: 0,
                          };
                        }
                      }),
                  };
                });
                this.setCatList({ row });
              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  checkValidationOnSubmit = () => {
    const { fieldData, flag } = this.state;
    const { category } = this.props;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: ManualOrderAdminJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: ManualOrderAdminJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = (rowList) => {
    const { flag, fieldData } = this.state;
    const { category } = this.props;
    let stockList = [];
    const stockStakeHolderBillDetails = rowList.map((orderData) => {
      return {
        product: {
          id: orderData.products.id,
        },
        qty: +orderData.qty,
      };
    });
    if (flag) {
      category.category.map((categoryObject) => {
        categoryObject.productWithMarginAndMrps.map((productObject) => {
          let productNewObject = {
            productId: productObject.products.id,
            stock:
              productObject.productStock -
              productObject.qty -
              productObject.freeQty,
          };
          stockList.push(productNewObject);
        });
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    }

    const dataToSave = {
      toBill: fieldData.stakeholder,
      billDiscountPercentage: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      stockStakeHolderBillDetails: stockStakeHolderBillDetails,
    };
    const dataToSaveWithStock = {
      stockStakeHolderBillHeader: dataToSave,
      productAndStocks: stockList,
    };

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSaveWithStock);
      }
    });
  };
  getOrderForProceed = () => {
    const { orderList, fieldData, comment } = this.state;
    const outletBillDetails = orderList.map((orderData) => {
      return {
        product: orderData.product,
        qty: orderData.qty,
      };
    });
    const dataToSave = {
      fromOrder: fieldData.stakeholder,
      orderDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: 0,
      stockStakeHolderOrderDetails: outletBillDetails,
      remark: "",
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSaveForProceed(dataToSave);
      }
    });
  };
  onSave = (dataToSaveWithStock) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      // this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.holderManualOrder + "/generate-with-applied-offer",
        postBody: dataToSaveWithStock,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          let newObjectToSave = {
            ...data.data.stockStakeHolderBillsAfterApplyingOffers,
            stockStakeHolderBillDetails:
              data.data.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                (orderObject) => {
                  let productWithoutBillObject =
                    data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails.filter(
                      (row) => row.id == orderObject.id
                    );
                  if (productWithoutBillObject.length !== 0) {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer:
                        productWithoutBillObject[0].taxableAmt,
                      totalAmtWithoutOffer:
                        productWithoutBillObject[0].totalAmt,
                    };
                    return orderObject;
                  } else {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer: 0.0,
                      totalAmtWithoutOffer: 0.0,
                    };
                    return orderObject;
                  }
                }
              ),
          };
          let newData = {
            ...data.data,
            stockStakeHolderBillsAfterApplyingOffers: newObjectToSave,
          };
          this.setState({ openNew: true, offerBillData: newData });
          // window.location.replace("/manual-order-for-admin-form");
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSaveForProceed = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderOrderHeader + "/manual",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          window.location.replace("/manual-order-for-admin-form");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    ManualOrderAdminJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
    localStorage.removeItem("stockList");
  };
  toggleNew = () => {
    this.setState({
      openNew: !this.state.openNew,
    });
    localStorage.removeItem("stockList");
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let outletUom =
        currentRow.products.outletUom != null
          ? currentRow.products.outletUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let productStock =
        currentRow.productStock != null ? currentRow.productStock : "-";
      if (
        productName &&
        productName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        outletUom &&
        outletUom.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        rate &&
        rate.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        productStock &&
        productStock
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      open,
      openNew,
      orderList,
      formErrors,
      fieldData,
      stockFlag,
      flag,
      manualOrderAdminPrimary,
      offerBillData,
      orderWithBillingFlag,
      totalQty,
    } = this.state;
    const { category, stockHolderAndProductConfiguration } = this.props;
    return (
      <>
        {orderWithBillingFlag == 1 || orderWithBillingFlag == 2 ? (
          !openNew && (
            <>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                }}
                elevation={0}
              >
                <DynamicFormWithoutValidation
                  getListById={this.getListById}
                  dynamicMasterData={dynamicMasterData}
                  showTitle={ManualOrderAdminJson.showTitle}
                  screenTitle={ManualOrderAdminJson.screenTitle}
                  fieldMeta={ManualOrderAdminJson.fieldMeta}
                  apiBaseURL={ManualOrderAdminJson.apiBaseURL}
                  formErrors={formErrors}
                  showSaveBtn={ManualOrderAdminJson.showSaveBtn}
                  showSaveBtnMain={false}
                  fieldData={fieldData}
                  onDataChange={this.onDataChange}
                  showBackToList={ManualOrderAdminJson.showBackToList}
                />
                <DynamicDetails DataToSet={DataToSet} />
                {category.category.length != 0 && (
                  <>
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      {category.category.map((catData) => {
                        return (
                          <Tab
                            label={
                              catData.category.name +
                              " ( " +
                              catData.productWithMarginAndMrps.length +
                              " )"
                            }
                          />
                        );
                      })}
                    </Tabs>
                    {category.category.map((catData, index) => {
                      return (
                        <TabPanel value={value} index={index}>
                          <LandingScreenHeader
                            screenTitle={""}
                            showBackButton={false}
                            showAddButton={false}
                            showTable={true}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            onSearch={this.onSearch}
                          />
                          <br />
                          <ManualOrderAdminTable
                            stockHolderAndProductConfiguration={
                              stockHolderAndProductConfiguration
                            }
                            tableHead={
                              flag && fieldData.flag == 1
                                ? [
                                  {
                                    title: "Sr.No.",
                                    name: "index",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Product Name",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "UOM",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Rate",
                                    name: "productStock",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "right",
                                  },
                                  {
                                    title: "Product Stock",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },

                                  {
                                    title: "Quantity",
                                    name: "target",
                                    placeHolder: "Enter Target",
                                    textFieldError: "targetErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },
                                  {
                                    title: " Free Quantity",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Discount %",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Total",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                ]
                                : flag && fieldData.flag != 1
                                  ? [
                                    {
                                      title: "Sr.No.",
                                      name: "index",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Product Name",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "UOM",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Rate",
                                      name: "productStock",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                    },
                                    {
                                      title: "Product Stock",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },

                                    {
                                      title: "Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    {
                                      title: "Total",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                  ]
                                  : !flag && fieldData.flag == 1
                                    ? [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: " Free Quantity",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Discount %",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                                    : [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                            }
                            data={this.getFilteredTableData(
                              catData.productWithMarginAndMrps
                            )}
                            rowList={category.category}
                            setList={this.setCatList}
                            flag={flag}
                            freeQtyFlag={fieldData.flag}
                            manualOrderAdminPrimary={manualOrderAdminPrimary}
                          />
                        </TabPanel>
                      );
                    })}
                    <br />
                    <DynamicDetails
                      DataToSet={[
                        {
                          xs: 12,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          label: "Total Amount",
                          value: total.toFixed(2),
                        },
                      ]}
                    />
                    {/* <Grid container spacing={1}>
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Proceed"}
                            onClick={this.submitHandler}
                          />
                        </Grid>
                      </Grid> */}
                    {/* {orderWithBillingFlag == 0 ||
                      orderWithBillingFlag == 2 ? (
                        <>
                          <Grid container spacing={1}>
                            <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{ justifyContent: "flex-end" }}
                            >
                              <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name={"Proceed"}
                                onClick={this.submitHandlerForProceed}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      <br />
                      {orderWithBillingFlag == 1 ||
                      orderWithBillingFlag == 2 ? (
                        <>
                          <Grid container spacing={1}>
                            <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{ justifyContent: "flex-end" }}
                            >
                              <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name={"Proceed For Billing"}
                                onClick={this.submitHandler}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )} */}

                    <Grid container spacing={1}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ justifyContent: "flex-end" }}
                      >
                        {orderWithBillingFlag == 0 ||
                          orderWithBillingFlag == 2 ? (
                          <>
                            <ButtonCompo
                              size="medium"
                              type="submit"
                              variant="contained"
                              name={"Proceed"}
                              onClick={this.submitHandlerForProceed}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        &nbsp; &nbsp;
                        {orderWithBillingFlag == 1 ||
                          orderWithBillingFlag == 2 ? (
                          <>
                            <ButtonCompo
                              size="medium"
                              type="submit"
                              variant="contained"
                              name={"Proceed For Billing"}
                              onClick={this.submitHandler}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>
            </>
          )
        ) : (
          <>
            <Paper
              sx={{
                p: 2,
                borderRadius: 2,
              }}
              elevation={0}
            >
              <DynamicFormWithoutValidation
                getListById={this.getListById}
                dynamicMasterData={dynamicMasterData}
                showTitle={ManualOrderAdminJson.showTitle}
                screenTitle={ManualOrderAdminJson.screenTitle}
                fieldMeta={ManualOrderAdminJson.fieldMeta}
                apiBaseURL={ManualOrderAdminJson.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={ManualOrderAdminJson.showSaveBtn}
                showSaveBtnMain={false}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showBackToList={ManualOrderAdminJson.showBackToList}
              />
              <DynamicDetails DataToSet={DataToSet} />
              {category.category.length != 0 && (
                <>
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    {category.category.map((catData) => {
                      return (
                        <Tab
                          label={
                            catData.category.name +
                            " ( " +
                            catData.productWithMarginAndMrps.length +
                            " )"
                          }
                        />
                      );
                    })}
                  </Tabs>
                  {category.category.map((catData, index) => {
                    return (
                      <TabPanel value={value} index={index}>
                        <LandingScreenHeader
                          screenTitle={""}
                          showBackButton={false}
                          showAddButton={false}
                          showTable={true}
                          showPdfDownload={false}
                          showExcelDownload={false}
                          onSearch={this.onSearch}
                        />
                        <br />
                        <ManualOrderAdminTable
                          stockHolderAndProductConfiguration={
                            stockHolderAndProductConfiguration
                          }
                          tableHead={
                            flag && fieldData.flag == 1
                              ? [
                                {
                                  title: "Sr.No.",
                                  name: "index",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                },
                                {
                                  title: "Product Name",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                },
                                {
                                  title: "UOM",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                },
                                {
                                  title: "Rate",
                                  name: "productStock",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "right",
                                },
                                {
                                  title: "Product Stock",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                },

                                {
                                  title: "Quantity",
                                  name: "target",
                                  placeHolder: "Enter Target",
                                  textFieldError: "targetErr",
                                  positionCenter: false,
                                  showInscreen: true,
                                },
                                {
                                  title: " Free Quantity",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                },
                                {
                                  title: "Discount %",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                },
                                {
                                  title: "Total",
                                  name: "name",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                },
                              ]
                              : flag && fieldData.flag != 1
                                ? [
                                  {
                                    title: "Sr.No.",
                                    name: "index",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Product Name",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "UOM",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Rate",
                                    name: "productStock",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "right",
                                  },
                                  {
                                    title: "Product Stock",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },

                                  {
                                    title: "Quantity",
                                    name: "target",
                                    placeHolder: "Enter Target",
                                    textFieldError: "targetErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },
                                  {
                                    title: "Total",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                ]
                                : !flag && fieldData.flag == 1
                                  ? [
                                    {
                                      title: "Sr.No.",
                                      name: "index",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Product Name",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "UOM",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Rate",
                                      name: "productStock",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    {
                                      title: " Free Quantity",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "Discount %",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "Total",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                  ]
                                  : [
                                    {
                                      title: "Sr.No.",
                                      name: "index",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Product Name",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "UOM",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Rate",
                                      name: "productStock",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    {
                                      title: "Total",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                  ]
                          }
                          data={this.getFilteredTableData(
                            catData.productWithMarginAndMrps
                          )}
                          rowList={category.category}
                          setList={this.setCatList}
                          flag={flag}
                          freeQtyFlag={fieldData.flag}
                          manualOrderAdminPrimary={manualOrderAdminPrimary}
                        />
                      </TabPanel>
                    );
                  })}
                  <br />
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Total Amount",
                        value: total.toFixed(2),
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Total Quantity",
                        value: totalQty,
                      },
                    ]}
                  />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      {orderWithBillingFlag == 0 ||
                        orderWithBillingFlag == 2 ? (
                        <>
                          <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Proceed"}
                            onClick={this.submitHandlerForProceed}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      &nbsp; &nbsp;
                      {orderWithBillingFlag == 1 ||
                        orderWithBillingFlag == 2 ? (
                        <>
                          <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Proceed For Billing"}
                            onClick={this.submitHandler}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
        {orderWithBillingFlag == 0 || orderWithBillingFlag == 2
          ? open && (
            <ManualOrderPopup
              formData={{
                total: total,
                totalQty: totalQty,
              }}
              columns={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                },
                {
                  title: "Product Name",
                  name: "productName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "UOM",
                  name: "outletName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },

                {
                  title: "Quantity",
                  name: "qty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },

                {
                  title: "Rate",
                  name: "rate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Total",
                  name: "total",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
              ]}
              open={open}
              handleCloses={this.toggle}
              rows={orderList}
              getOrderForProceed={this.getOrderForProceed}
            />
          )
          : ""}
        {orderWithBillingFlag == 1 || orderWithBillingFlag == 2
          ? openNew && (
            <>
              <ManualOrderAdminBillingWithOffer
                offerBillOutletData={offerBillData}
                onCancel={this.toggleNew}
                flag={flag}
                totalQty={totalQty}
              />
            </>
          )
          : ""}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
  stockHolderAndProductConfiguration: state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
  getStockStackHolders,
  getStockholderByUser,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManualOrderAdmin);
