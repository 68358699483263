import endpoint from "../../config/endpoints";

export const groupProductJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.group,
  changeExcel: endpoint.exportData,
  screenTitle: "Product",
  formPath: "/group-product-form",
  formPathView: "/group-product-view",
  isActiveURL: endpoint.group + "/update-is-active",
  showBackToList: true,
  showTitle: false,
  showAddButton: true,
  excelUrl: "group-data" + '?sort={"sortOrder": "ASC"}',

  fieldMeta: [
    {
      label: "Group Name",
      controlType: "textfield",
      placeHolder: "Enter Group Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Enter Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Code",
      controlType: "textfield",
      placeHolder: "Enter Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "code",
      isMandatory: true,
    },
    {
      label: "Description",
      controlType: "textfield",
      placeHolder: "Enter Description",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "description",
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      getListId: "category",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Sub-Category",
      controlType: "autocomplete",
      placeHolder: "Select Sub-Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subCategoryDetail",
      getListFrom: "category",
      dataKey: "subCategory",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Brand",
      controlType: "autocomplete",
      placeHolder: "Select Brand",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "brandDetail",
      dataKey: "brand",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Product Name",
      controlType: "textfield",
      placeHolder: "Product Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      isImageName: true,
      uniqueValidation: false,
    },
    {
      label: "Product Short Name",
      controlType: "textfield",
      placeHolder: "Enter Product Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
      maxLength: 10,
    },
    {
      label: "Product Code",
      controlType: "textfield",
      placeHolder: "Product Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "code",
      isMandatory: true,
    },
    {
      label: "Extra Product Code",
      controlType: "textfield",
      placeHolder: "Extra Product Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "productCode",
      isMandatory: false,
    },
    {
      label: "Product Description",
      controlType: "textfield",
      placeHolder: "Product Description",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "description",
      isMandatory: true,
    },
    {
      label: "Packaging Type",
      controlType: "autocomplete",
      placeHolder: "Select Packaging Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "packagingTypeDetail",
      dataKey: "packagingType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder UOM",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderUomDetail",
      dataKey: "stakeHolderUom",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext: true,
    },
    {
      label: "Outlet UOM",
      controlType: "autocomplete",
      placeHolder: "Select Outlet UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderUomDetail",
      dataKey: "outletUom",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Tax",
      controlType: "autocomplete",
      placeHolder: "Select Tax",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "taxDetail",
      dataKey: "taxPercentage",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Tag",
      controlType: "multiSelect",
      placeHolder: "Select Tag",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "tagDetail",
      dataKey: "tagIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "UOM Conversion Value",
      controlType: "textfield",
      placeHolder: "UOM Conversion Value",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      valid: true,
      validName: "zeroValue",
      isMandatory: true,
    },
    {
      label: "Product Shelf Life (In Month)",
      controlType: "textfield",
      placeHolder: "Product Shelf Life (In Month)",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "selfLife",
      spValid: "float",
      isMandatory: true,
      inputType: "number",
    },
    {
      label: "Product Weight",
      controlType: "textfield",
      placeHolder: "Enter Product weight  (In Gram)",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "weight",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: false,
    },
  ],
};
