import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { ExcelReportsJson } from "../../DynamicFormsJson/MastersJSON/excelReports";
import { getStakeHolderNameByStakeholderTypeAndAreaPost } from "../../Slice/StackHolder.slice";
import {
  getAreaByTalukaPostBodyUpdated,
  getAreaByUserType,
} from "../../Slice/area.slice";
import { getBeatsByAreasPostUpdated } from "../../Slice/beat.slice";
import { getCategory } from "../../Slice/category.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getActiveProductByCategoryForAllPost } from "../../Slice/product.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getSalesPersonByAreaPost } from "../../Slice/salesExecutive.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getOtherTask } from "../../Slice/otherTask.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import config from "../../config/config";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";

class ExcelReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaDetailForDealer: this.props.areaList?.area,
        beatDetail: this.props.beatList.beat,
        categoryDetail: this.props.categoryList.category,
        productDetail: this.props.productList.activeProductByCategory,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        salesPersonDetail: this.props.salesPersonList?.salesPersonByArea,
      },
      adminFlag: false,
      areaIds: "",
      companyFlag: "",
    };
  }

  async componentDidMount() {
    const {
      getRegionNew,
      getAreaByUserType,
      getStockStakeHolderType,
      getCategory,
      getOtherTask,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });
      }

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      this.setState({
        companyFlag: showCompanyFlag,
      });

      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({
        userTypeIds:
          showCompanyFlag == 2 && userTypeId == 2
            ? "2"
            : showCompanyFlag == 2
              ? "3,4"
              : " 2 ",
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      showLoador({ loador: true });
      await getOtherTask().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }

    if (this.props.salesPersonList !== nextProps.salesPersonList) {
      if (
        this.props.salesPersonList?.salesPersonByArea !==
        nextProps.salesPersonList?.salesPersonByArea
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesPersonList?.salesPersonByArea,
          },
        });
      }
    }

    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetailForDealer: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderNameFromType !==
        nextProps.stakeholderList?.stackholderNameFromType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderNameFromType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.activeProductByCategory !==
        nextProps.productList?.activeProductByCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.productList?.activeProductByCategory,
          },
        });
      }
    }
    if (this.props.otherTaskList !== nextProps.otherTaskList) {
      if (
        this.props.otherTaskList?.otherTask !==
        nextProps.otherTaskList?.otherTask
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            otherTaskDetail: nextProps.otherTaskList?.otherTask,
          },
        });
      }
    }


  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBodyUpdated,
      getActiveProductByCategoryForAllPost,
      getBeatsByAreasPostUpdated,
      getSalesPersonByAreaPost,
      showLoador,
      getStakeHolderNameByStakeholderTypeAndAreaPost,
      showNotification,
    } = this.props;
    const { areaIds, adminFlag } = this.state;
    if (navigator.onLine) {
      console.log(data);

      this.setState({
        fromDate: dayjs(data.fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(data.toDate).format("DD-MM-YYYY"),
      });

      if (data.area) {
        this.setState({
          areaIds: data.area,
        });
      }

      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        let beatJson = {
          areaIds: data.area,
        };
        showLoador({ loador: true });
        await getBeatsByAreasPostUpdated({ beatDataForPost: beatJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (!adminFlag) {
        if (data.area && data.area != "") {
          let areaIdsJson = {
            areaIds: data.area,
          };
          showLoador({ loador: true });
          await getSalesPersonByAreaPost({ areaIdsJson: areaIdsJson }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }

      if (data.category && data.category != "") {
        showLoador({ loador: true });
        await getActiveProductByCategoryForAllPost({
          categoryIds: data.category,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.stockStakeHolderTypeId && data.stockStakeHolderTypeId != "") {
        let jsonData = {
          stockStakeHolderTypeId: data.stockStakeHolderTypeId,
          areaIds: areaIds,
        };
        showLoador({ loador: true });
        await getStakeHolderNameByStakeholderTypeAndAreaPost({
          jsonData: jsonData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getExcel = (row, rowData) => {
    const { adminFlag, companyFlag } = this.state;
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {

      if (adminFlag && companyFlag == 2) {
        if (rowData.index == 1) {
          const dataToSave = {
            fromDate: row.fromDate,
            toDate: row.toDate,
            areaIds: row.area,
            stockStakeHolderTypeId: row.stockStakeHolderTypeId,
          };
          showLoador({ loador: true });
          downloadF({
            isGet: false,
            url: endpoint.salesExecutive + "/with-check-in-out-report-excel",
            postBody: dataToSave,
            ext: "xls",
            openNewTab: false,
          }).then((response) => {
            if (!response) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            }
            showLoador({ loador: false });
          });
        } else if (rowData.index == 2) {
          const dataToSave = {
            month: dayjs(row.month, "DD-MM-YYYY").format("MM-YYYY"),
            zoneIds: row.zone,
            otherTaskIds: row.otherTaskIds,
          };
          showLoador({ loador: true });
          downloadF({
            isGet: false,
            url: endpoint.reportForExcelReports +
              "/sales-team-monthly-activity-report-excel",
            postBody: dataToSave,
            ext: "xls",
            openNewTab: false,
          }).then((response) => {
            if (!response) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            }
            showLoador({ loador: false });
          });
        } else if (rowData.index == 3) {
          let dataJson = {
            month: dayjs(row.month, "DD-MM-YYYY").format("MM-YYYY"),
            districtIds: row.district,
          };
          showLoador({ loador: true });
          downloadF({
            isGet: false,
            url: endpoint.reportForExcelReports +
              "/sales-team-attendence-detailed-report-excel",
            postBody: dataJson,
            ext: "xls",
            openNewTab: false,
          }).then((response) => {
            if (!response) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            }
            showLoador({ loador: false });
          });
        } else if (rowData.index == 4) {
          let dataJson = {
            month: dayjs(row.month, "DD-MM-YYYY").format("MM-YYYY"),
            districtIds: row.district,
          };
          showLoador({ loador: true });
          downloadF({
            isGet: false,
            url: endpoint.reportForExcelReports +
              "/sales-team-attendence-summary-report-excel",
            postBody: dataJson,
            ext: "xls",
            openNewTab: false,
          }).then((response) => {
            if (!response) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            }
            showLoador({ loador: false });
          });
        }
      } else if (adminFlag && rowData.index == 3) {
        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url:
            endpoint.reportForExcelReports +
            "/product-sale-register?fromDate=" +
            row.fromDate +
            "&toDate=" +
            row.toDate,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      } else if (adminFlag && rowData.index == 5) {
        const dataToSave = {
          stateIds: row.state,
          fromDate: row.fromDate,
          toDate: row.toDate,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.stackholder + "/distributor-assign-excel-by-date",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      } else if (adminFlag && rowData.index == 6) {
        console.log(row);
        const dataToSave = {
          stockStakeHolderIds: row.stockStakeHolderIds,
          fromDate: row.fromDate,
          toDate: row.toDate,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url:
            endpoint.reportForExcelReports +
            "/product-sale-register-by-date-and-distributors",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      } else if (rowData.index == 1) {
        const dataToSave = {
          fromDate: row.fromDate,
          toDate: row.toDate,
          categoryIds: row.category,
          productIds: row.product,
          stockStakeHolderId: row.stockStakeHolder,
          beatIds: row.beat,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.monthlySalesAnalysis + "/new",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      } else if (rowData.index == 3) {
        const dataToSave = {
          fromDate: row.fromDate,
          toDate: row.toDate,
          salesTeamIds: row.salesPerson,
          beatIds: row.beat,
          categoryIds: row.category,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.exportDataNew + "/product-wise-sale-register",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      } else if (rowData.index == 4) {
        const dataToSave = {
          fromDate: row.fromDate,
          toDate: row.toDate,
          stockStakeHolderIds: row.stockStakeHolder,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.stackholder + "/bill-excel",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowData = [
    {
      index: 1,
      reportName: "Daily Stock Report (D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/stock-consumption-header/get-daily-stock-excel-by-date?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 2,
      reportName: "Material Inward Report (D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/material-inward?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 3,
      reportName: "Party Wise Sale (D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/party-wise-sales?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 4,
      reportName: "Product Wise sale (D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/product-wise-sales?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 5,
      reportName: "Invoice Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/invoice?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 6,
      reportName: "Credit Note Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/crn?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 7,
      reportName: "Pending Orders Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/product-wise-stock-holder-order?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 8,
      reportName: "Pending Orders Product Wise(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/order?fromDate={fromDate}&toDate={toDate}",
    },
  ];

  rowDataOfcProd = [
    {
      index: 1,
      reportName: "FG Production Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/fg-production?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 2,
      reportName: "Purchase Return Quantity Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/grn?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 3,
      reportName: "Invoice Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report/invoice?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 4,
      reportName: "Date Wise In-Stock Report(D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/report//month-wise-and-date-wise-stock-report?fromDate={fromDate}&toDate={toDate}&status=1",
    },
  ];

  rowDataGklAdm = [
    {
      isStatic: true,
      index: 1,
      reportName: "Monthly Sales Analysis Report (Admin - D0 to D12)",
      filter:
        "fromDate,toDate,region,state,zone,district,taluka,area,beat,category,product,stockStakeHolderTypeId,stockStakeHolder",
      url: "/api/distributionreport/v1/monthly-sales-analysis?fromDate={fromDate}&toDate={toDate}&categoryIds={category}&productIds={product}&salesTeamId={stockStakeHolder}&beatIds={beat}&flag=0",
    },
    {
      index: 2,
      reportName: "Distributor Report (Admin - D0 to D7, D11, D12)",
      filter:
        "fromDate,toDate,region,state,zone,district,taluka,area,stockStakeHolderTypeId,stockStakeHolder,",
      url: "/api/distributionreport/v1/monthly-sale-bill-analysis/new?fromDate={fromDate}&toDate={toDate}&distributorId={stockStakeHolder}",
    },
    {
      isStatic: true,
      index: 3,
      reportName: "SQL Dump (Admin - D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/api/distribution/v1/report/product-sale-register?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 4,
      reportName: "Purchase Bill (Admin - D0 to D1)",
      filter: "fromDate,toDate",
      url: "/api/distributionreport/v1/stock-stake-holder-bill-header/generated-bills-excel-by-date?fromDate={fromDate}&toDate={toDate}",
    },
    {
      isStatic: true,
      index: 5,
      reportName: "Distributor Assign Report (Admin - D0 to D3)",
      filter: "fromDate,toDate,region,state",
      url: "/api/distributionreport/v1/stock-stake-holder/distributor-assign-excel",
    },
    {
      isStatic: true,
      index: 6,
      reportName: "SQL Dump Stakeholder wise(Admin - D0 to D7, D11, D13)",
      filter: "fromDate,toDate,region,state,zone,district,taluka,area,stockStakeHolderTypeId,stockStakeHolderIds",
      url: "/api/distributionreport/v1/stock-stake-holder/product-sale-register",
    },
  ];

  rowDataGklDealer = [
    {
      isStatic: true,
      index: 1,
      reportName: "Monthly Sales Analysis Report ( Distributor - D0 to D7)",
      filter:
        "fromDate,toDate,region,state,zone,district,taluka,area,beat,category,product,stockStakeHolderTypeId,stockStakeHolder",
      url: "/api/distributionreport/v1/monthly-sales-analysis?fromDate={fromDate}&toDate={toDate}&categoryIds={category}&productIds={product}&salesTeamId={stockStakeHolder}&beatIds={beat}&flag=0",
    },
    {
      index: 2,
      reportName: "Distributor Report ( Distributor - D0 to D3, D6, D7)",
      filter:
        "fromDate,toDate,region,state,zone,district,taluka,area,stockStakeHolderTypeId,stockStakeHolder,",
      url: "/api/distributionreport/v1/monthly-sale-bill-analysis/new?fromDate={fromDate}&toDate={toDate}&distributorId={stockStakeHolder}",
    },
    {
      isStatic: true,
      index: 3,
      reportName: "Product Wise Sale Register (Distributor - D0 to D4, D8)",
      filter: "fromDate,toDate,area,beat,category,salesPerson,",
      url: "/api/distributionreport/v1/report/product-sale-register?fromDate={fromDate}&toDate={toDate}",
    },
    {
      isStatic: true,
      index: 4,
      reportName: "Stakeholder Bill (Distributor - D0, D1, D2, D6, D7)",
      filter: "fromDate,toDate,area,stockStakeHolderTypeId,stockStakeHolder",
      url: "/api/distributionreport/v1/stock-stake-holder/bill-excel",
    },
  ];

  rowDataForSundar = [
    {
      isStatic: true,
      index: 1,
      reportName:
        "Sales Team Report With Check In-Out Status Excel ( Admin - D0 to D7, D11)",
      filter:
        "fromDate,toDate,region,state,zone,district,taluka,area,stockStakeHolderTypeId",
    },
    {
      isStatic: true,
      index: 2,
      reportName:
        "Manager Activity Report( Admin - D2 to D5, D12)",
      filter:
        "month,region,state,zone,otherTaskIds",
    },
    {
      isStatic: true,
      index: 3,
      reportName:
        "Sales Team Attendance Detailed Report( Admin - D2 to D6)",
      filter:
        "month,region,state,zone,district",
    },
    {
      isStatic: true,
      index: 4,
      reportName:
        "Sales Team Attendance Summary Report( Admin - D2 to D6)",
      filter:
        "month,region,state,zone,district",
    },
  ];

  rowDataForSundarDealer = [
    {
      index: 1,
      reportName: "Outlet Wise Bill Report ( Distributor - D0 to D2, D6, D7)",
      filter: "fromDate,toDate,area,stockStakeHolderTypeId,stockStakeHolder,",
      url: "/api/distributionreport/v1/outlet-bill-header/get-outlet-wise-bill-excel?fromDate={fromDate}&toDate={toDate}&stockStakeHolderId={stockStakeHolder}",
    },
  ];

  render() {
    const { dynamicMasterData, adminFlag, companyFlag } = this.state;
    return (
      <>
        <DynamicMainScreen
          getExcel={this.getExcel}
          screenTitle={ExcelReportsJson.screenTitle}
          fieldMeta={
            adminFlag && companyFlag == 2
              ? ExcelReportsJson.fieldMetaForSundar
              : !adminFlag && companyFlag == 2
                ? ExcelReportsJson.fieldMetaForDistributor
                : adminFlag
                  ? ExcelReportsJson.fieldMeta
                  : ExcelReportsJson.fieldMetaForDistributor
          }
          buttonCenter={ExcelReportsJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={ExcelReportsJson.searchButton}
          PDFButton={ExcelReportsJson.PDFButton}
          EXCELButton={ExcelReportsJson.EXCELButton}
          baseIdColumn={ExcelReportsJson.baseIdColumn}
          apiBaseURL={ExcelReportsJson.apiBaseURL}
          marginTop={ExcelReportsJson.marginTop}
          openNewTab={true}
          rowData={
            adminFlag && companyFlag == 2
              ? this.rowDataForSundar
              : !adminFlag && companyFlag == 2
                ? this.rowDataForSundarDealer
                : companyFlag == 0
                  ? adminFlag
                    ? this.rowDataOfcProd
                    : this.rowData
                  : adminFlag
                    ? this.rowDataGklAdm
                    : this.rowDataGklDealer
          }
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatList: state.beat,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stockStakeHolderType: state.stockStakeHolderType,
  categoryList: state.category,
  stakeholderList: state.stockholder,
  productList: state.product,
  salesPersonList: state.salesExecutive,
  otherTaskList: state.otherTask,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegionNew,
  getZoneByState,
  getOtherTask,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  getAreaByUserType,
  getStateByRegion,
  getBeatsByAreasPostUpdated,
  getStockStakeHolderType,
  getCategory,
  getActiveProductByCategoryForAllPost,
  getStakeHolderNameByStakeholderTypeAndAreaPost,
  getSalesPersonByAreaPost,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExcelReports);
