import dayjs from "dayjs";
import endpoint from "../../config/endpoints";

export const StockBetweenDateJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Stock Between Date",
  changeExcel: endpoint.exportDataNew,
  //   formPath: "/manage-stock-form",
  //   formPathView: "/manage-stock-view",
  //   showBackToList: true,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      isMandatory: true,
    },
  ],
};
