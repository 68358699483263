import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { AuditColumns } from "../../tableColumns/table-columns";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { getAudit } from "../../Slice/audit.slice";
import { noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class AuditTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuditSurvey: "",
    };
  }


  onClickTransfer = (rowData) => {
    this.props.navigate(auditJson.openFormPathStatus + rowData.id);
  }

  rowEWay = (rowData) => {
    console.log(rowData);
    window.location.replace("/audit-details/" + rowData.id)
  }

  render() {
    const { auditList, getAudit } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={auditJson.formPath}
          formPathView={auditJson.formPathView}
          showViewButtonInTable={true}
          screenTitle={auditJson.screenTitle}
          fieldMeta={auditJson.fieldMeta}
          showPdfDownload={auditJson.showPdfDownload}
          showExcelDownload={auditJson.showExcelDownload}
          pdfFileName={auditJson.pdfFileName}
          excelFileName={auditJson.excelFileName}
          showAddButton={auditJson.showAddButton}
          tableColumnsToFilter={auditJson.tableColumnsToFilter}
          tableColumns={AuditColumns}
          tableData={auditList?.audit}
          getTableData={getAudit}
          apiBaseURL={auditJson.apiBaseURL}
          baseIdColumn={auditJson.baseIdColumn}
          showTransferButtonInTable={true}
          showTransferTitle={"Survey Data"}
          onClickTransfer={this.onClickTransfer}
          openFormPathStatus={auditJson.openFormPathStatus}
          showCopyIcon={true}
          copyConditionName="isAuditSurveyCond"
          copyConditionValue="1"
          editConditionName="editDelConditionStatus"
          editConditionValue="1"
          deleteConditionName="editDelConditionStatus"
          deleteConditionValue="1"
          transferConditionName="isAuditSurvey"
          transferConditionValue="Survey"
          showEditIcon={true}
          showEWay={true}
          rowEWay={this.rowEWay}
          showEWayTitle={"Audit Details"}
          eWayConditionName="isAuditSurvey"
          eWayConditionValue="Audit"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auditList: state.audit,
});
const mapDispatchToProps = {
  getAudit,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuditTable);
