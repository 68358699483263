import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  showLoador,
  showNotification,
} from "../../../Pages/Landing/Landing.slice";
import { getActiveLanguage } from "../../../Slice/multilingual.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompoWithoutValue";
import { id } from "date-fns/locale";
import { ButtonCompo } from "../../../components/Comman/Button";
import swal from "sweetalert";
import {
  languageNotAvailableMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import DynamicDetails from "../../../components/dynamicscreens/DynamicDetails";
const OutletLanguage = ({
  showLoador,
  showNotification,
  multilingualList,
  getActiveLanguage,
}) => {
  const params = useParams();
  const [langJson1, setLangJson1] = useState([]);
  const [langJson, setLangJson] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [dataPresentFlag, setDataPresentFlag] = useState(false);
  const [areaBeatId, setAreaBeatId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    showLoador({ loador: true });
    apiGet({
      url: endpoint.outlet + "/" + params.id,
    }).then(({ data, success }) => {
      let newBeatId = "";
      if (Object.keys(data).length != 0 && Object.keys(data.data).length != 0) {
        setDataById(data.data);
        setAreaBeatId(data.data.beat.id);
        newBeatId = data.data.beat.id;
      }

      apiGet({
        url:
          endpoint.multilingual +
          "-json/available-or-not-by-area-beat?areaBeatId=" +
          newBeatId,
      }).then(({ data, success }) => {
        if (
          success &&
          Object.keys(data).length != 0 &&
          Object.keys(data.data).length != 0
        ) {
          setDataPresentFlag(!data.data.error);
          apiGet({
            url:
              endpoint.multilingual +
              "-json/outlet-by-type-and-outlet?type=3&areaBeatId=" +
              newBeatId +
              "&outletId=" +
              params.id,
          }).then(({ data, success }) => {
            if (success) {
              showLoador({ loador: true });
              getActiveLanguage().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  setLangJson(
                    response.map((languageObject) => {
                      let languageList = data.data.filter(
                        (row) => row.language.id == languageObject.id
                      );
                      let objectData = {
                        id: params.id,
                        language:
                          languageList.length !== 0
                            ? languageList[0].language
                            : languageObject,
                        name:
                          languageList.length !== 0 ? languageList[0].name : "",
                        outletAddress:
                          languageList.length !== 0
                            ? languageList[0].outletAddress
                            : "",
                        outletAddress1:
                          languageList.length !== 0
                            ? languageList[0].outletAddress1
                            : "",
                        outletOwnerName:
                          languageList.length !== 0
                            ? languageList[0].outletOwnerName
                            : "",
                        villageCity:
                          languageList.length !== 0
                            ? languageList[0].villageCity
                            : "",
                      };
                      return objectData;
                    })
                  );
                  setLangJson1(
                    response.map((languageObject) => {
                      let languageList = data.data.filter(
                        (row) => row.language.id == languageObject.id
                      );
                      let objectData = {
                        id: params.id,
                        language:
                          languageList.length !== 0
                            ? languageList[0].language
                            : languageObject,
                        name:
                          languageList.length !== 0 ? languageList[0].name : "",
                        outletAddress:
                          languageList.length !== 0
                            ? languageList[0].outletAddress
                            : "",
                        outletAddress1:
                          languageList.length !== 0
                            ? languageList[0].outletAddress1
                            : "",
                        outletOwnerName:
                          languageList.length !== 0
                            ? languageList[0].outletOwnerName
                            : "",
                        villageCity:
                          languageList.length !== 0
                            ? languageList[0].villageCity
                            : "",
                      };
                      return objectData;
                    })
                  );
                }
              });
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
              showLoador({ loador: false });
            }
          });
        } else {
          setDataPresentFlag(false);
          showNotification({ msg: serverMsg, severity: "error" });
          showLoador({ loador: false });
        }
      });
    });
  }, []);
  const onCancel = () => {
    navigate(-1);
  };
  const onTextFieldDataChange = (dataKey, langId) => (event) => {
    const { value } = event.target;
    let newLangJson = langJson1.map((langObject) => {
      if (langObject.language.id == langId) {
        langObject = {
          ...langObject,
          [dataKey]: value,
        };
        return langObject;
      } else {
        return langObject;
      }
    });
    setLangJson1(newLangJson);
  };

  const onSubmit = () => {
    if (navigator.onLine) {
      if (dataPresentFlag) {
        onSubmitDataPresent();
      } else {
        let objectData = {
          type: "3",
          areaBeatId: dataById.beat.id,
          json: langJson1,
        };

        onSubmitDataListEmpty(objectData);
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onSubmitDataPresent = () => {
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.multilingual +
            "-json/update-json-by-type-and-outlet?type=3&areaBeatId=" +
            areaBeatId +
            "&outletId=" +
            params.id,
          postBody: langJson1,
        }).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          } else {
            navigate(-1);
          }
        });
        showLoador({ loador: false });
      }
    });
  };

  const onSubmitDataListEmpty = (objectData) => {
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.languageJson,
          postBody: objectData,
        }).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          } else {
            navigate(-1);
          }
        });
        showLoador({ loador: false });
      }
    });
    showLoador({ loador: false });
  };

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 0,
      }}
      elevation={0}
      style={{ width: "100%" }}
    >
      <LandingScreenHeader
        screenTitle={"Outlet Language"}
        showAddButton={false}
        showBackButton={true}
        onCancel={onCancel}
      />
      <br />
      <DynamicDetails
        DataToSet={[
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Firm Name",
            value:
              dataById !== null && dataById.firmName !== null
                ? dataById.firmName
                : "-",
          },
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Owner Name",
            value:
              dataById !== null && dataById.ownerName !== null
                ? dataById.ownerName
                : "-",
          },
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Address",
            value:
              dataById !== null && dataById.address !== null
                ? dataById.address
                : "-",
          },
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Address1",
            value:
              dataById !== null && dataById.address1 !== null
                ? dataById.address1
                : "-",
          },
          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Address2",
            value:
              dataById !== null && dataById.address2 !== null
                ? dataById.address2
                : "-",
          },

          {
            xs: 12,
            sm: 6,
            md: 6,
            lg: 6,
            label: "Village/City",
            value:
              dataById !== null && dataById.villageCity !== null
                ? dataById.villageCity
                : "-",
          },
        ]}
      />
      <br />

      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer sx={{ width: "maxContent" }}>
              <Table sx={{ minWidth: "maxContent" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center">Outlet Firmname</TableCell>
                    <TableCell align="center">Outlet Ownername</TableCell>
                    <TableCell align="center">Outlet Address</TableCell>
                    <TableCell align="center">Outlet Address1</TableCell>
                    <TableCell align="center">Outlet Vilage/City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {langJson.map((jsonObject, index) => (
                    <TableRow key={jsonObject.index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">
                        <div>{jsonObject.language.name}</div>
                      </TableCell>
                      <TableCell align="left" width="15%">
                        <div>{jsonObject.name}</div>
                        <div>
                          <TextFieldCompo
                            placeHolder={"Firm Name"}
                            size="small"
                            color="primary"
                            // value={jsonObject.name}
                            onChange={onTextFieldDataChange(
                              "name",
                              jsonObject.language.id
                            )}
                            fullWidth={true}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" width="15%">
                        <div>{jsonObject.outletOwnerName}</div>
                        <div>
                          <TextFieldCompo
                            placeHolder={"Owner Name"}
                            size="small"
                            color="primary"
                            onChange={onTextFieldDataChange(
                              "outletOwnerName",
                              jsonObject.language.id
                            )}
                            fullWidth={true}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" width="27.5% !important">
                        <div>{jsonObject.outletAddress}</div>
                        <div>
                          <TextFieldCompo
                            placeHolder={"Address"}
                            size="small"
                            color="primary"
                            onChange={onTextFieldDataChange(
                              "outletAddress",
                              jsonObject.language.id
                            )}
                            fullWidth={true}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" width="27.5%">
                        <div>{jsonObject.outletAddress1}</div>
                        <div>
                          <TextFieldCompo
                            placeHolder={"Address 1"}
                            size="small"
                            color="primary"
                            onChange={onTextFieldDataChange(
                              "outletAddress1",
                              jsonObject.language.id
                            )}
                            fullWidth={true}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" width="15%">
                        <div>{jsonObject.villageCity}</div>
                        <div>
                          <TextFieldCompo
                            placeHolder={"Village/City"}
                            size="small"
                            color="primary"
                            onChange={onTextFieldDataChange(
                              "villageCity",
                              jsonObject.language.id
                            )}
                            fullWidth={true}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      <br />
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
        container
        justifyContent="right"
      >
        <ButtonCompo
          size="medium"
          type="submit"
          variant="contained"
          name="Submit"
          onClick={onSubmit}
        />
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  multilingualList: state.multilingual,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getActiveLanguage,
};
export default connect(mapStateToProps, mapDispatchToProps)(OutletLanguage);
