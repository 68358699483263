import {
    Checkbox,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { WeeklyOffJson } from "../../../DynamicFormsJson/MastersJSON/weeklyOff";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { checkedListEmptyMsg, noInternetMsg, saveFailedMsg, savemsg, saveWarningMsg, serverMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { withRouter } from "../../../components/withRouter";
import { compose } from "recompose";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
import { apiGet, apiPost } from "../../../utils/api_service";
import { getWeeklyOff } from "../../../Slice/weeklyOff.slice";

class WeeklyOffForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
            },
            dataList: [
                { "day": 0, "dayName": "Monday", "isChecked": false },
                { "day": 1, "dayName": "Tuesday", "isChecked": false },
                { "day": 2, "dayName": "Wednesday", "isChecked": false },
                { "day": 3, "dayName": "Thursday", "isChecked": false },
                { "day": 4, "dayName": "Friday", "isChecked": false },
                { "day": 5, "dayName": "Saturday", "isChecked": false },
                { "day": 6, "dayName": "Sunday", "isChecked": false },
            ],
            allChecked: false,
        };
    }


    async componentDidMount() {
        const { showLoador, showNotification, getWeeklyOff } =
            this.props;
        const { dataList } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getWeeklyOff().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                } else {

                    const rowList = dataList.map((dataListObj) => {
                        const matchedDay = response.find(
                            (responseObj) => responseObj.day === dataListObj.day
                        );
                        return {
                            ...dataListObj,
                            isChecked: !!matchedDay,
                        };
                    });

                    this.setState({ dataList: rowList });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    handleAllCheckedClick = (event) => {
        const { checked } = event.target;
        const { dataList } = this.state;

        this.setState({ allChecked: checked });

        const updatedData = dataList.map((objData) => ({
            ...objData,
            isChecked: checked,
        }));
        this.setState({ dataList: updatedData });
    };


    handleClick = (e, day) => {
        const { dataList } = this.state;

        const updatedData = dataList.map((dataObj) =>
            dataObj.day === day
                ? { ...dataObj, isChecked: e.target.checked }
                : dataObj
        );

        this.setState({ dataList: updatedData });
    };


    onSubmitHandler = () => {
        const { dataList } = this.state;
        const { showLoador, showNotification } = this.props;


        const selectedData = dataList.filter((row) => row.isChecked);


        if (selectedData.length !== 0) {


            const saveList = selectedData.map((objData) => {
                if (this.props.params.id) {
                    return {
                        id: this.props.params.id,
                        day: objData.day,
                        dayName: objData.dayName,
                    }
                } else {
                    return {
                        day: objData.day,
                        dayName: objData.dayName,
                    }
                }

            });

            console.log(saveList);

            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    apiPost({
                        url: endpoint.weeklyOff + "/save-list",
                        postBody: saveList,
                    }).then(({ data, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            showNotification({ msg: savemsg });
                            window.location.replace("/weekly-off");
                        } else {
                            showNotification({ msg: saveFailedMsg, severity: "error" });
                        }
                    });
                }
            });
        } else {
            showNotification({ msg: checkedListEmptyMsg, severity: "error" });
        }

    }

    render() {
        const { dynamicMasterData, allChecked, dataList } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 3,
                        pt: 2,
                        borderRadius: 2
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        screenTitle={"Weekly Off"}
                        showSearchBox={false}
                        showTable={false}
                        showAddButton={false}
                        showFilter={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        showBackButton={true}
                        onCancel={() => this.props.navigate(-1)}
                    />
                    <br />
                    <TableContainer sx={{ maxHeight: 500 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="error"
                                            onClick={this.handleAllCheckedClick}
                                            checked={allChecked}
                                            inputProps={{
                                                "aria-label": "select all structures",
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Day</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataList.map((row, index) => (
                                    <TableRow key={row.day}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={(event) => this.handleClick(event, row.day)}
                                                checked={row.isChecked}
                                                inputProps={{
                                                    "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="left">{row.dayName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />

                    <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                        <Grid item>
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="Submit"
                                onClick={this.onSubmitHandler}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    getWeeklyOff,
    showNotification,
    showLoador,
};


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WeeklyOffForm);

