import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "@mui/material/Tooltip";
import { compose } from "@reduxjs/toolkit";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { serverMsg } from "../../config/messageconstant";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import MultipleSelect from "../Comman/MultipleSelect";
import { TextFieldCompo } from "../Comman/TextFieldCompo";
import TimePickerCompo from "../Comman/TimePicker";
import {
  phoneNo,
  onlyFloatNumber,
  onlyNumber,
  gstNo,
  validText,
  validEmail,
  panNo,
  validPoint,
  fssaiNo,
  validUdyamAadhar,
  upiNo,
  validTextNo,
} from "../Comman/Util/Validations";
import { withRouter } from "../withRouter";
import * as msgConstant from "../../config/messageconstant";
import dayjs from "dayjs";
import { labelColor } from "../../config/ColorObj";
import { titleColor, redColor } from "../../config/ColorObj";

class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formValidErrors: {},
      fieldData: {},
      fieldDataEdit: {},
      formErrors: {},
      editData: {},
    };
  }
  setDataToFieldData = (rowData) => {
    const {
      fieldMeta,
      dynamicMasterData,
      getDataForm = false,
      setDataToForm,
    } = this.props;
    if (getDataForm) {
      setDataToForm(rowData);
    }
    let fieldData = {};

    fieldMeta.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      console.log(currentField.controlType === "hideMultiSelect");
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey] != null && rowData[tableDataKey] !== "" ? rowData[tableDataKey].split(",") : [];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          rowData[currentField.tableDataKey] == currentField.open &&
          rowData[currentField.dataKey] !== null &&
          rowData[currentField.dataKey]
        ) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey].split(",");
          if (currentField.getListId) {
            const masterKeyList = currentField.getListId.split(",");
            masterKeyList.map((key) => {
              if (currentField.dataKey === key) {
                dataToSearch[key] = rowData[currentField.dataKey].split(",");
              } else if (fieldData[key]) {
                dataToSearch[key] = fieldData[key];
              }
              return null;
            });

            this.props.getListById(dataToSearch);
          }
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "hideAutocomplete" &&
        !currentField.isRootLevelKey
      ) {
        if (
          rowData[currentField.dataKey] &&
          rowData[currentField.dataKey] != null
        ) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[currentField.dataKey].id,
            },
          };
          if (currentField.getListId) {
            const masterKeyList = currentField.getListId.split(",");
            masterKeyList.map((key) => {
              if (currentField.dataKey === key) {
                dataToSearch[key] = rowData[currentField.dataKey];
              } else if (fieldData[key]) {
                dataToSearch[key] = fieldData[key];
              }
              return null;
            });

            this.props.getListById(dataToSearch);
          }
        }
      } else if (
        currentField.controlType === "hideAutocomplete" &&
        currentField.isRootLevelKey
      ) {
        if (
          rowData[currentField.dataKey] &&
          rowData[currentField.dataKey] != null
        ) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: rowData[currentField.dataKey],
          };
          if (currentField.getListId) {
            const masterKeyList = currentField.getListId.split(",");
            masterKeyList.map((key) => {
              if (currentField.dataKey === key) {
                dataToSearch[key] = rowData[currentField.dataKey];
              } else if (fieldData[key]) {
                dataToSearch[key] = fieldData[key];
              }
              return null;
            });

            this.props.getListById(dataToSearch);
          }
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfieldNotEqual" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] !== currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;
    console.log({
      ...rowData,
      ...fieldData,
    });
    this.setState({
      fieldData: fieldData,
      fieldDataEdit: fieldData,
      editData: {
        ...rowData,
        ...fieldData,
      },
    });
  };
  async componentDidMount() {
    const {
      fieldMeta = [],
      apiBaseURL,
      showLoador,
      showNotification,
      callGetByIdApi = true,
      getByIdApi,
    } = this.props;

    const dataToReset = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.defaultValue) {
        dataToReset[currentField.dataKey] = currentField.defaultValue;
      }
    });
    this.setState({
      fieldData: dataToReset,
    });
    if (this.props.params.id) {
      if (callGetByIdApi) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url: apiBaseURL + "/" + this.props.params.id,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (!data.error) {
                let rowData = data.data;

                this.setDataToFieldData(rowData);
              }
            }

            return success;
          });
        } else {
          this.props.showNotification({
            msg: msgConstant.noInternetMsg,
            severity: "error",
          });
        }
      } else {
        showLoador({ loador: true });
        getByIdApi({ id: this.props.params.id }).then(
          ({ response, success }) => {
            if (success) {
              this.setDataToFieldData(response);
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    }
  }

  //   showLoador({ loador: true });
  //   getByIdApi({id:this.props.params.id}).then(({ response, success }) => {
  // if(success){

  // } else{
  // showNotification({ msg: "Server Failed", severity: "error" });
  // }

  //   )}
  // }
  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    if (newValue != null) {
      let value = "";
      if (isRootLevelKey) {
        value = newValue;
      } else {
        value = {
          id: newValue,
        };
      }
      this.onDataChange(dataKey, value);
    } else {
      let value = "";
      this.onDataChange(dataKey, value);
    }
  };

  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
    const filterData = dataValue.filter((rowData) => rowData == 0);
    const filterDataWithoutAll = dataValue.filter((rowData) => rowData != 0);

    if (filterData.length != 0 &&
      dynamicMasterData[masterName].length != filterDataWithoutAll.length
    ) {
      dataValue = dynamicMasterData[masterName].map((rowData) => {
        return rowData.id;
      });
    } else if (filterData.length != 0) {
      dataValue = [];
    }
    this.onDataChange(dataKey, dataValue);
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  onTextFieldDataChange = (dataKey, inputType, spValid, valid) => (event) => {
    if (inputType == "number") {
      if (spValid == "float") {
        if (
          event.target.value === "" ||
          (event.target.value !== "e" && +event.target.value >= 0)
        ) {
          this.onDataChange(dataKey, event.target.value);
        }
        // else {
        //   this.onDataChange(dataKey, 0);
        // }
      } else {
        if (event.target.value === "" || onlyNumber.test(event.target.value)) {
          this.onDataChange(dataKey, event.target.value);
        }
      }
    } else if (inputType == "numberText") {
      if (event.target.value === "" || validTextNo.test(event.target.value)) {
        this.onDataChange(dataKey, event.target.value);
      }
    } else {
      if (
        event.target.value === "" ||
        validText.test(event.target.value) ||
        valid
      ) {
        this.onDataChange(dataKey, event.target.value);
      }
    }
  };

  onDateChange = (dataKey) => (date) => {
    this.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  onFormSave = (isToggle) => async () => {
    const checkValidationData = await this.checkValidationOnSubmit();
    if (checkValidationData) {
      const { fieldData, editData } = this.state;
      const {
        fieldMeta,
        type = "",
        getExtraData = false,
        getData,
        callBeforeSave = false,
      } = this.props;

      let dataToSave = {
        ...editData,
        isActive: fieldData.id ? fieldData.isActive : 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };
      if (type !== "") {
        dataToSave["formType"] = type;
      }
      if (this.props.params.id) {
        dataToSave["id"] = fieldData["id"];
      }

      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (
            fieldData[currentField.dataKey] &&
            fieldData[currentField.tableDataKey] == currentField.open
          ) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "hideAutocomplete") {
          if (
            fieldData[currentField.dataKey] &&
            fieldData[currentField.tableDataKey] === currentField.open
          ) {
            let autocompleteValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
            dataToSave[currentField.dataKey] = autocompleteValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (
          currentField.controlType === "hideTextfieldNotEqual" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] !== currentField.open) {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "textfield") {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].trim()
            : "";
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });
      if (callBeforeSave) {
        const response = await this.props.callBeforeSaveMethod(
          dataToSave,
          editData
        );
        if (response) {
          this.onSave(dataToSave, isToggle);
        }
      } else if (getExtraData) {
        const responseData = await getData();
        if (responseData != "") {
          dataToSave = {
            ...dataToSave,
            ...responseData,
          };
          this.onSave(dataToSave, isToggle);
        } else {
          if (responseData == "" && dataToSave.id != undefined) {
            this.onSave(dataToSave, isToggle);
          }
        }
      } else {
        this.onSave(dataToSave, isToggle);
      }
    }
  };
  onSave = (dataToSave, isToggle = true) => {
    const {
      apiBaseURL,
      formPath = "",
      isEditURL = "",
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      let url = apiBaseURL;
      if (isEditURL != "" && dataToSave.id) {
        url = isEditURL;
      }
      showLoador({ loador: true });
      apiPost({
        url: url,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });

          if (isToggle) {
            this.props.navigate(-1);
            this.setState({
              fieldData: {},
              fieldDataEdit: {},
              editData: {},
            });
          }
          if (!isToggle) {
            const {
              fieldMeta,
              getFormDataStatic = false,
              getFormData,
            } = this.props;

            if (getFormDataStatic) {
              getFormData(data);
            } else {
              let fieldData = {};
              fieldMeta.map((currentField) => {
                if (currentField.defaultValue) {
                  fieldData[currentField.dataKey] = currentField.defaultValue;
                }
                return null;
              });
              this.myTextField.focus();
              this.setState({
                fieldData: fieldData,
              });
            }
          }
        } else {
          this.props.showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "phoneNo" &&
          !phoneNo.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "fssaiNo" &&
          !fssaiNo.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "validUdyamAadhar" &&
          !validUdyamAadhar.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "panNo" &&
          !panNo.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "upiNo" &&
          fieldData[currentField.dataKey] !== undefined &&
          fieldData[currentField.dataKey] !== null &&
          fieldData[currentField.dataKey].toString().trim() !== "" &&
          !upiNo.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        // if (
        //   currentField.validName === "validPoint" &&
        //   !validPoint.test(fieldData[currentField.dataKey])
        // ) {
        //   formValid = false;
        //   formErrors = {
        //     ...formErrors,
        //     [currentField.dataKey]: {
        //       isError: true,
        //       errorText: `${currentField.label} must be between 1-10`,
        //     },
        //   };
        // }
        if (
          currentField.validName === "gstNo" &&
          !gstNo.test(fieldData[currentField.dataKey])
        ) {
          if (currentField.controlType != "hideTextfieldNotEqual") {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          } else if (
            currentField.controlType == "hideTextfieldNotEqual" &&
            fieldData[currentField.tableDataKey] !== currentField.open
          ) {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          }
        }

        if (
          currentField.validName === "zeroValue" &&
          fieldData[currentField.dataKey] == 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = async () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        let checkUniqueData = await this.checkUniqueValidation();
        if (checkUniqueData) {
          return this.onSubmitCheck();
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfieldNotEqual") {
        if (
          fieldData[currentField.tableDataKey] !== currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] == currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkUniqueValidation = async () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData, fieldDataEdit } = this.state;

    const filterList = fieldMeta.filter(
      (currentField) => currentField.uniqueValidation
    );
    if (filterList.length != 0) {
      if (
        (Object.keys(fieldDataEdit).length != 0 &&
          fieldDataEdit[filterList[0].dataKey] !==
          fieldData[filterList[0].dataKey].trim()) ||
        Object.keys(fieldDataEdit).length == 0
      ) {
        const { showLoador, apiBaseURL } = this.props;
        showLoador({ loador: true });
        await apiGet({
          url:
            apiBaseURL +
            '?filters={"' +
            filterList[0].dataKey +
            '":"' +
            fieldData[filterList[0].dataKey].trim() +
            '","_mode" : "EXACT"}',
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              if (data.data.content.length != 0) {
                formValid = false;
                this.setState({
                  formValidErrors: {
                    ...this.state.formValidErrors,
                    [filterList[0].dataKey]: {
                      isError: true,
                      errorText: `*${filterList[0].label} is already exist`,
                    },
                  },
                });
              } else {
                formValid = true;
                this.setState({
                  formValidErrors: {
                    ...this.state.formValidErrors,
                    [filterList[0].dataKey]: {
                      isError: false,
                      errorText: ``,
                    },
                  },
                });
              }
            }
          }

          return success;
        });
      }
    }
    return formValid;
  };
  handleChangeCheckbox = (e, currentData) => {
    this.setState({
      fieldData: {
        ...this.state.fieldData,
        [currentData.sameAsKey]: e.target.checked,
        [currentData.sameAs]: e.target.checked
          ? this.state.fieldData[currentData.dataKey]
          : "",
      },
    });
  };
  render() {
    const {
      fieldMeta = [],
      dynamicMasterData = {},
      showSaveBtn = true,
      showSaveNextBtn = true,
      screenTitle = "",
      showCancel = true,
      showBackToList = true,
      showAddButton = false,
      DataToSet = [],
      showTable = false,
      paddingTop = true,
      padding = true,
      showTitleAndBack = true,
    } = this.props;

    const { formErrors, fieldData, formValidErrors } = this.state;
    console.log(fieldMeta);
    return (
      <>
        <Paper
          sx={{
            p: padding ? 2 : 0,
            pt: paddingTop ? 2 : 0,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showTitleAndBack && (
            <LandingScreenHeader
              screenTitle={screenTitle}
              showTable={showTable}
              showBackButton={showBackToList}
              showAddButton={showAddButton}
              onCancel={this.onCancel}
            />
          )}

          {screenTitle != "" && <br />}

          {DataToSet.length !== 0 && (
            <Grid container sx={{ margin: 2 }} spacing={1}>
              {DataToSet.map((dropDownData) => (
                <Grid container xs={6} sm={6} md={6}>
                  <Grid item xs={4} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={dropDownData.label}
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={dropDownData.value}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}

          <Grid container spacing={1}>
            {fieldMeta.map((currentField) => (
              <>
                {" "}
                <Grid
                  container
                  xs={currentField.xs}
                  sm={currentField.sm}
                  md={currentField.md}
                  sx={{
                    marginTop: currentField.marginTop
                      ? currentField.marginTop
                      : 1,
                  }}
                //  rowSpacing={16}
                >
                  {currentField.toolTip && currentField.toolTip !== "" && (
                    <>
                      <Tooltip placement="top" title={currentField.toolTip}>
                        <InfoIcon color="disabled" />
                      </Tooltip>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={currentField.labelSM ? currentField.labelSM : 4}
                    md={currentField.labelMD ? currentField.labelMD : 4}
                    lg={currentField.labelLG ? currentField.labelLG : 4}
                  >
                    {currentField.controlType !== "hideMultiSelect" &&
                      currentField.controlType !== "hideDatepicker" &&
                      currentField.controlType !== "hideTextfield" &&
                      currentField.controlType !== "hideAutocomplete" &&
                      currentField.controlType !== "hideTextfieldNotEqual" && (
                        <>
                          &nbsp;&nbsp;
                          {currentField.isMandatory && (
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                          )}
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              color: currentField.isMandatory
                                ? titleColor
                                : titleColor,
                            }}
                            label={currentField.label + " :"}
                          />
                        </>
                      )}
                    {currentField.controlType == "hideTextfieldNotEqual" &&
                      fieldData[currentField.tableDataKey] !==
                      currentField.open && (
                        <>
                          &nbsp;&nbsp;
                          {currentField.isMandatory && (
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                          )}
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              color: currentField.isMandatory
                                ? titleColor
                                : titleColor,
                            }}
                            label={currentField.label + " :"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideDatepicker" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <>
                          &nbsp;&nbsp;
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              color: currentField.isMandatory
                                ? titleColor
                                : titleColor,
                            }}
                            label={currentField.label + " :"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideAutocomplete" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <>
                          &nbsp;&nbsp;
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              color: currentField.isMandatory
                                ? titleColor
                                : titleColor,
                            }}
                            label={currentField.label + " :"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideMultiSelect" &&
                      fieldData[currentField.tableDataKey] ==
                      currentField.open && (
                        <>
                          &nbsp;&nbsp;
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              color: currentField.isMandatory
                                ? titleColor
                                : titleColor,
                            }}
                            label={currentField.label + " :"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideTextfield" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <>
                          {currentField.showLabel ? (
                            <>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: currentField.isMandatory
                                    ? titleColor
                                    : titleColor,
                                }}
                                label={currentField.label + " :"}
                              />
                            </>
                          ) : (
                            <>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={"  "}
                              />
                            </>
                          )}
                        </>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={currentField.valueSM ? currentField.valueSM : 8}
                    md={currentField.valueMD ? currentField.valueMD : 8}
                    lg={currentField.valueLG ? currentField.valueLG : 8}
                  >
                    {currentField.controlType === "textfield" && (
                      <>
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          autoFocus={
                            currentField.autoFocus
                              ? currentField.autoFocus
                              : false
                          }
                          {...(currentField.autoFocus && {
                            inputRef: (el) => (this.myTextField = el),
                          })}
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : currentField.disableTrue
                                ? true
                                : false
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                            min: currentField.minNum && currentField.minNum,
                          }}
                          type={
                            currentField.inputTypeData
                              ? currentField.inputTypeData
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey,
                            currentField.inputType,
                            currentField.spValid,
                            currentField.valid
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : currentField.uniqueValidation &&
                                formValidErrors[currentField.dataKey]?.isError
                                ? formValidErrors[currentField.dataKey].errorText
                                : ""
                          }
                        />
                        {currentField.note &&
                          currentField.space &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{
                                    fontSize: 12,
                                    color: labelColor,
                                    marginLeft: 2,
                                  }}
                                  label={note}
                                />
                                {currentField.space === "br" ? (
                                  <br></br>
                                ) : currentField.space === "&nbsp;" ? (
                                  " "
                                ) : (
                                  ""
                                )}
                                {/* <br></br> */}
                              </>
                            );
                          })}
                        {currentField.sameAs && (
                          <>
                            <Checkbox
                              color="primary"
                              checked={
                                fieldData[currentField.sameAsKey]
                                  ? fieldData[currentField.sameAsKey]
                                  : false
                              }
                              onChange={(e) => {
                                this.handleChangeCheckbox(e, currentField);
                              }}
                              sx={{
                                padding: "0px",
                                paddingRight: "10px",
                              }}
                              inputProps={{
                                "aria-labelledby": `enhanced-table-checkbox-1`,
                              }}
                            />
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={currentField.sameAsLabel}
                            />
                          </>
                        )}
                      </>
                    )}
                    {currentField.controlType === "textarea" && (
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        multiline
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey,
                          currentField.inputType,
                          currentField.spValid,
                          currentField.valid
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                    )}
                    {currentField.controlType === "hideTextfield" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : currentField.disableTrue
                                ? true
                                : false
                          }
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                          }}
                          type={
                            currentField.inputType
                              ? currentField.inputType
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey,
                            currentField.inputType,
                            currentField.spValid,
                            currentField.valid
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      )}
                    {currentField.controlType === "hideTextfieldNotEqual" &&
                      fieldData[currentField.tableDataKey] !==
                      currentField.open && (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : currentField.disableTrue
                                ? true
                                : false
                          }
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                          }}
                          type={
                            currentField.inputType
                              ? currentField.inputType
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey,
                            currentField.inputType,
                            currentField.spValid,
                            currentField.valid
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      )}
                    {currentField.controlType === "datepicker" && (
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : null
                        }
                        {...(currentField.isMAxDate && {
                          maxDate: dayjs(),
                        })}
                        {...(currentField.isMinDate && {
                          minDate: dayjs(),
                        })}
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                              ? true
                              : false
                        }
                        fullWidth
                        onChange={this.onDateChange(currentField.dataKey)}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                    {currentField.controlType === "hideDatepicker" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <BasicDatePicker
                          color="primary"
                          name="dob"
                          size="small"
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : null
                          }
                          {...(currentField.isMAxDate && {
                            maxDate: dayjs(),
                          })}
                          {...(currentField.isMinDate && {
                            minDate: dayjs(),
                          })}
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={this.onDateChange(currentField.dataKey)}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={
                            formErrors[currentField.dataKey]?.errorText
                          }
                        />
                      )}
                    {currentField.controlType === "hideAutocomplete" &&
                      fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                        <AutoComplete
                          label={currentField.label}
                          placeHolder={currentField.placeHolder}
                          keyColName={"id"}
                          value={
                            currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : !currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey].id
                                : ""
                          }
                          options={
                            dynamicMasterData[currentField["masterName"]]
                              ? dynamicMasterData[currentField["masterName"]]
                              : []
                          }
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : false
                          }
                          onChange={this.onAutocompleteChange(
                            currentField.dataKey,
                            currentField.isRootLevelKey
                          )}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={
                            formErrors[currentField.dataKey]?.errorText
                          }
                        />
                      )}
                    {currentField.controlType === "autocomplete" && (
                      <>
                        <AutoComplete
                          label={currentField.label}
                          placeHolder={currentField.placeHolder}
                          keyColName={"id"}
                          value={
                            currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey] != undefined
                              ? fieldData[currentField.dataKey].toString()
                              : !currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey].id.toString()
                                : ""
                          }
                          options={
                            dynamicMasterData[currentField["masterName"]]
                              ? dynamicMasterData[currentField["masterName"]]
                              : []
                          }
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : currentField.disableTrue
                                ? true
                                : false
                          }
                          onChange={this.onAutocompleteChange(
                            currentField.dataKey,
                            currentField.isRootLevelKey
                          )}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={
                            formErrors[currentField.dataKey]?.errorText
                          }
                        />
                        {currentField.note &&
                          currentField.space &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 12, color: labelColor }}
                                  label={note}
                                />
                                {currentField.space === "br" ? (
                                  <br></br>
                                ) : currentField.space === "&nbsp;" ? (
                                  " "
                                ) : (
                                  ""
                                )}
                                {/* <br></br> */}
                              </>
                            );
                          })}
                      </>
                    )}
                    {currentField.controlType === "autocomplete/textfield" && (
                      <>
                        {fieldData["id"] ? (
                          <TextFieldCompo
                            placeHolder={currentField.placeHolder}
                            size="small"
                            color="primary"
                            name={currentField.label}
                            id={currentField.label}
                            value={
                              fieldData[currentField.dataKeyName] &&
                                fieldData[currentField.dataKeyName]
                                ? fieldData[currentField.dataKeyName]
                                : ""
                            }
                            disabled={true}
                            inputProps={{
                              maxLength:
                                currentField.maxLength &&
                                currentField.maxLength,
                              min: currentField.minNum && currentField.minNum,
                            }}
                            type={
                              currentField.inputType
                                ? currentField.inputType
                                : "text"
                            }
                            onChange={this.onTextFieldDataChange(
                              currentField.dataKey,
                              currentField.inputType,
                              currentField.spValid,
                              currentField.valid
                            )}
                            fullWidth
                            errorText={
                              formErrors[currentField.dataKey]?.isError
                                ? formErrors[currentField.dataKey].errorText
                                : ""
                            }
                          />
                        ) : (
                          <>
                            <AutoComplete
                              label={currentField.label}
                              placeHolder={currentField.placeHolder}
                              keyColName={"id"}
                              value={
                                currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey]
                                  : !currentField.isRootLevelKey &&
                                    fieldData[currentField.dataKey]
                                    ? fieldData[currentField.dataKey].id
                                    : ""
                              }
                              options={
                                dynamicMasterData[currentField["masterName"]]
                                  ? dynamicMasterData[
                                  currentField["masterName"]
                                  ]
                                  : []
                              }
                              disabled={
                                fieldData["id"] && currentField.disable
                                  ? true
                                  : false
                              }
                              onChange={this.onAutocompleteChange(
                                currentField.dataKey,
                                currentField.isRootLevelKey
                              )}
                              isError={
                                formErrors[currentField.dataKey]?.isError
                              }
                              errorText={
                                formErrors[currentField.dataKey]?.errorText
                              }
                            />
                            {currentField.note &&
                              currentField.space &&
                              currentField.note.split("@").map((note) => {
                                return (
                                  <>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 12,
                                        color: labelColor,
                                      }}
                                      label={note}
                                    />
                                    {currentField.space === "br" ? (
                                      <br></br>
                                    ) : currentField.space === "&nbsp;" ? (
                                      " "
                                    ) : (
                                      ""
                                    )}
                                    {/* <br></br> */}
                                  </>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                    {currentField.controlType === "multiSelect" && (
                      <MultipleSelect
                        label={currentField.placeHolder}
                        er={formErrors[currentField.dataKey]?.errorText}
                        name1={currentField.dataKey}
                        value1={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : []
                        }
                        names={
                          dynamicMasterData[currentField["masterName"]] &&
                            dynamicMasterData[currentField["masterName"]]
                              .length != 0
                            ? [
                              { id: 0, name: "All" },
                              ...dynamicMasterData[
                              currentField["masterName"]
                              ],
                            ]
                            : []
                        }
                        //onChange={this.multiSelectHandler}
                        onChange={this.onMultiSelectChange(
                          currentField.dataKey,
                          currentField["masterName"]
                        )}
                      />
                    )}

                    {currentField.controlType === "hideMultiSelect" &&
                      fieldData[currentField.tableDataKey] ==
                      currentField.open && (
                        <MultipleSelect
                          label={currentField.placeHolder}
                          er={formErrors[currentField.dataKey]?.errorText}
                          name1={currentField.dataKey}
                          value1={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : []
                          }
                          names={
                            dynamicMasterData[currentField["masterName"]] &&
                              dynamicMasterData[currentField["masterName"]]
                                .length != 0
                              ? [
                                { id: 0, name: "All" },
                                ...dynamicMasterData[
                                currentField["masterName"]
                                ],
                              ]
                              : []
                          }
                          //onChange={this.multiSelectHandler}
                          onChange={this.onMultiSelectChange(
                            currentField.dataKey,
                            currentField["masterName"]
                          )}
                        />
                      )}
                    {currentField.controlType === "timePicker" && (
                      <TimePickerCompo
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.timeChangeHandler(currentField.dataKey)}
                      />
                    )}
                    {currentField.controlType === "radiogroup" && (
                      <>
                        <RadioGroup
                          row
                          aria-label={currentField.label}
                          name={currentField.label}
                          id={currentField.label}
                          disabled={
                            fieldData["id"] && currentField.disable
                              ? true
                              : false
                          }
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          onChange={this.onRadioChange(currentField.dataKey)}
                          size="small"
                        >
                          {currentField.radioGroupItems.map((radioItem) => (
                            <FormControlLabel
                              disabled={
                                fieldData["id"] && currentField.disable
                                  ? true
                                  : false
                              }
                              value={radioItem.value}
                              control={<Radio />}
                              label={radioItem.label}
                            />
                          ))}
                        </RadioGroup>
                        {currentField.note &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 12, color: labelColor }}
                                  label={note}
                                />
                                <br></br>
                              </>
                            );
                          })}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <br />
          {/* <br></br> */}
          {(showSaveBtn || showSaveNextBtn || showCancel) && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              {showSaveBtn && (
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  // fullWidth={true}
                  onClick={this.onFormSave(true)}
                />
              )}

              {showSaveNextBtn && (
                <>
                  &nbsp; &nbsp;
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save and Next"
                    onClick={this.onFormSave(false)}
                  // fullWidth={true}
                  />
                </>
              )}

              {showCancel && (
                <>
                  &nbsp; &nbsp;
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={this.onCancel}
                  // fullWidth={true}
                  />
                </>
              )}
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicForm);
