import React from "react";
import { Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { RegionAndTargetColumns } from "../../tableColumns/table-columns";
import { regionAndTargetJson } from "../../DynamicFormsJson/Transaction/RegionAndTarget";
import { getRegionNew } from "../../Slice/region.slice";
import { getYearRegion } from "../../Slice/year.slice";
import { getMonthsFromYearId } from "../../Slice/regionAndTarget.slice";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import swal from "sweetalert";
import { saveFailedMsg, savemsg } from "../../config/messageconstant";
import { ButtonCompo } from "../../components/Comman/Button";
import { apiPost, apiGet } from "../../utils/api_service";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

class RegionAndTargetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dataToSave: {},
      janIndex: 0,
      febIndex: 0,
      marchIndex: 0,
      aprilIndex: 0,
      mayIndex: 0,
      junIndex: 0,
      julyIndex: 0,
      augIndex: 0,
      sepIndex: 0,
      octIndex: 0,
      novIndex: 0,
      decIndex: 0,
      totalPercentage: 0,
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        yearRegionDetail: this.props.yearList?.year,
      },
      regionAndTargetList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
            yearRegionDetail: this.props.yearList?.year,
          },
        });
      }
    }
    if (this.props.yearList !== nextProps.yearList) {
      if (this.props.yearList?.year !== nextProps.yearList?.year) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearRegionDetail: nextProps.yearList?.year,
          },
        });
      }
    }

    if (
      this.props.regionAndTargetDataList !== nextProps.regionAndTargetDataList
    ) {
      if (
        this.props.regionAndTargetDataList?.monthsFromYearId !==
        nextProps.regionAndTargetDataList?.monthsFromYearId
      ) {
        this.setState({
          ...this.state,
          regionAndTargetList:
            nextProps.regionAndTargetDataList?.monthsFromYearId,
        });
      }
    }
  }


  async componentDidMount() {
    const { getRegionNew, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSubmit = async () => {
    const {
      fieldData,
      regionAndTargetList,
      janIndex,
      febIndex,
      marchIndex,
      aprilIndex,
      mayIndex,
      junIndex,
      julyIndex,
      augIndex,
      sepIndex,
      octIndex,
      novIndex,
      decIndex,
    } = this.state;

    if (navigator.onLine) {
      let error = false;

      const totalPercentage = regionAndTargetList.reduce(
        (sum, item) => sum + Number(item.percentage),
        0
      );

      if (totalPercentage !== 100) {
        swal(
          "Validation Error",
          "Percentage total must be equal to 100 %",
          "warning"
        );
        return;
      }

      let dataToSave = {
        region: {
          id: fieldData.region,
        },
        yearMaster: {
          id: fieldData.year,
        },
        jan: regionAndTargetList[janIndex].percentage,
        feb: regionAndTargetList[febIndex].percentage,
        march: regionAndTargetList[marchIndex].percentage,
        april: regionAndTargetList[aprilIndex].percentage,
        may: regionAndTargetList[mayIndex].percentage,
        jun: regionAndTargetList[junIndex].percentage,
        july: regionAndTargetList[julyIndex].percentage,
        aug: regionAndTargetList[augIndex].percentage,
        sep: regionAndTargetList[sepIndex].percentage,
        oct: regionAndTargetList[octIndex].percentage,
        nov: regionAndTargetList[novIndex].percentage,
        dec: regionAndTargetList[decIndex].percentage,
      };

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = async (dataToSave) => {
    const { showLoador, setProductList, showNotification, category } =
      this.props;
    const { fieldData } = this.state;
    let error = false;
    let productList = [];
    if (!error) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiPost({
          url: endpoint.regionAndTarget,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: savemsg });
            this.props.navigate(-1);
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setProductList({ row: productList });
    }
  };

  onSearchFooter = async () => {
    const { fieldData } = this.state;
    const {
      showLoador,
      showNotification,
      getMonthsFromYearId,
      regionAndTargetDataList,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getMonthsFromYearId({ yearId: fieldData.year }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              regionAndTargetList: regionAndTargetDataList.monthsFromYearId,
            });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getYearRegion,
      getMonthsFromYearId,
      regionAndTargetDataList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region.id) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            region: data.region.id,
          },
        });

        showLoador({ loador: true });
        await getYearRegion({ regionId: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.yearName) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            year: data.yearName,
          },
        });

        await getYearRegion({ regionId: data.yearName }).then(({ success }) => {
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
        await getMonthsFromYearId({ yearId: data.yearName }).then(
          ({ success }) => {
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              this.setState({
                regionAndTargetList: regionAndTargetDataList.monthsFromYearId,
              });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  setRegionAndTargetList = ({ row }) => {

    let totalPercentage = 0;

    row.map((yearObject, index) => {

      totalPercentage = totalPercentage + +yearObject.percentage;
      this.setState({
        totalPercentage: totalPercentage,
      });

      if (yearObject.month == "January") {
        this.setState({
          janIndex: index,
        });
      } else if (yearObject.month == "February") {
        this.setState({
          febIndex: index,
        });
      } else if (yearObject.month == "March") {
        this.setState({
          marchIndex: index,
        });
      } else if (yearObject.month == "April") {
        this.setState({
          aprilIndex: index,
        });
      } else if (yearObject.month == "May") {
        this.setState({
          mayIndex: index,
        });
      } else if (yearObject.month == "June") {
        this.setState({
          junIndex: index,
        });
      } else if (yearObject.month == "July") {
        this.setState({
          julyIndex: index,
        });
      } else if (yearObject.month == "August") {
        this.setState({
          augIndex: index,
        });
      } else if (yearObject.month == "September") {
        this.setState({
          sepIndex: index,
        });
      } else if (yearObject.month == "October") {
        this.setState({
          octIndex: index,
        });
      } else if (yearObject.month == "November") {
        this.setState({
          novIndex: index,
        });
      } else {
        this.setState({
          decIndex: index,
        });
      }
    });

    this.setState({
      regionAndTargetList: row,
    });
  };
  render() {
    const { dynamicMasterData, regionAndTargetList, totalPercentage } = this.state;
    const { regionList, regionAndTargetDataList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            getListById={this.getListById}
            showBackToList={true}
            showSaveBtnMain={true}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={regionAndTargetJson.showSaveBtn}
            showTitle={regionAndTargetJson.showTitle}
            screenTitle={regionAndTargetJson.screenTitle}
            fieldMeta={regionAndTargetJson.fieldMeta}
            showCancel={regionAndTargetJson.showCancel}
            apiBaseURL={regionAndTargetJson.apiBaseURL}
            showSaveNextBtn={regionAndTargetJson.showSaveNextBtn}
            tableColumns={RegionAndTargetColumns}
            tableData={regionList?.targetRegion}
            saveBtnText={"Search"}
            onSave={this.onSearchFooter}
          />

          {regionAndTargetList.length !== 0 && (
            <>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Month",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Percentage",
                    name: "percentage",
                    placeHolder: "Enter Percentage",
                    textFieldError: "percentageErr",
                    error: "Percentage",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={regionAndTargetList}
                setList={this.setRegionAndTargetList}
                isActionColActive={false}
              />
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Percentage",
                    value: totalPercentage,
                  },
                ]}
              />
              <Grid container spacing={1}>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearList: state.year,
  regionList: state.region,
  regionAndTargetDataList: state.regionAndTarget,
});
const mapDispatchToProps = {
  getRegionNew,
  getYearRegion,
  showLoador,
  showNotification,
  getMonthsFromYearId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RegionAndTargetForm);