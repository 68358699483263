import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import MonthCalender from "../../components/MonthCalender";
import SearchBar from "../../components/Search";
import { cyanBlue } from "../../config/ColorObj";
import { serverMsg } from "../../config/messageconstant";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
    getAreaListFromTalukaYearAndMonth,
    getDistrictListFromYearAndMonth,
    getRegionListFromYearAndMonth,
    getStateRegionwiseListFromYearAndMonth,
    getTalukaListFromYearAndMonth,
    getZoneStatewiseListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import HierarchyPopUpOld from "./HierarchyPopUpOld";
class HierarchyDropDownOld extends Component {
    toggleDrawer = () => {
        this.props.onToggle();
    };
    state = {
        openFlag: false,
        showFeedback: false,
        showPreview: false,
        HierarchyPopUpFlag: false,
        date1: new Date(),
        newDate: "",
        currentDate: new Date(),
        curDate: new Date(),
        tableName: "",
        dob: "",
        dobDay: "",
        dobMonth: "",
        currenDate: "",
        currentMonth: "",
        timeTableList: [],
        regionId: "",
        subject: "",
        batch: "",
        yearDetail: "",
        semester: "",
        division: "",
        pieChartData: [],
        isLoading: false,
        showLoader: false,
        profile: "",
        nameFirstLetter: "",
        showZone: false,
        showArea: false,
        date: "",
        saleDate: "",
        productArea: "",
        productZone: "",
        outletArea: "",
        outletZone: "",
        outletDate: "",
        monthDate: "",
        yearDate: "",
        type: "",
        saleMonth: dayjs(),
        saleYear: "",
        month: "",
        year: "",
        searchValue: "",
        searchValueForZone: "",
        searchValueForSalesRegion: "",
        searchValueForZoneDetails: "",
        searchValueForAreaDetails: "",
        searchValuePersonWiseSales: "",
        searchValueStateList: "",
        searchValueForProduct: "",
        searchValueForOutlets: "",
        regionListTotal: 0,
        regionListFromYearAndMonthData: [],
        regionListFromYearAndMonthDataForZone: [],
        regionListFromYearAndMonthDataForSalesRegion: [],
        stateListFromYearAndMonth: [],
        zoneDetailsSalesData: [],
        talukaDetailsDistrictWiseData: [],
        stateDetailsData: [],
        zoneDetailsRegionwiseData: [],
        districtDetailsZoneWiseData: [],
        districtDetailsData: [],
        talukaDetailsData: [],
        areaDetailsListData: [],
        salesGraphData: [],
        salesMonthData: [],
        areaDetailsData: [],
        personWiseSalesData: [],
        topPerformingProductData: [],
        topPerformingOutletData: [],
        salesTeamData: [],
        formErrors: {},
        graphList: [],
        radioValue: [],
        lineGraphData: [],
        salesGraphList: [],
        areaDetailTalukaWiseData: [],
        salesRegionName: "",
        regionListFromYearAndMonthFlag: false,
        stateListFromYearAndMonthFlag: false,
        zoneByStateDataFlag: false,
        districtByZoneDataFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,

    };
    async componentDidMount() {
        const {
            showLoador,
            showNotification,
            getRegionListFromYearAndMonth,
            getZoneStatewiseListFromYearAndMonth,
            getStateRegionwiseListFromYearAndMonth,
            getDistrictListFromYearAndMonth,
            getTalukaListFromYearAndMonth,
            getAreaListFromTalukaYearAndMonth,
            dashboardData,
        } = this.props;
        if (navigator.onLine) {
            const regionListFromYearAndMonth = localStorage.getItem("regionListFromYearAndMonth");
            const regionListFromYearAndMonthLocalStorage = JSON.parse(regionListFromYearAndMonth);

            let regionTotalViaMap = 0;
            if (!regionListFromYearAndMonthLocalStorage) {
                getRegionListFromYearAndMonth({
                    month: dayjs().format("MM"),
                    year: dayjs().format("YYYY"),
                }).then(({ response, success }) => {
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {

                        const regionListFromYearAndMonthList = response.length !== 0 ? response : [];
                        localStorage.setItem("regionListFromYearAndMonth", JSON.stringify(regionListFromYearAndMonthList));
                        localStorage.setItem("regionId", response.length !== 0 ? response[0].id : "");

                        regionListFromYearAndMonthList.map((regionObj) => {
                            regionTotalViaMap = regionObj.finalTotal
                        })
                        localStorage.setItem("regionListFromYearAndMonthTotal", regionTotalViaMap);

                        this.setState({
                            regionListTotal: regionTotalViaMap,
                            regionListFromYearAndMonthData: response,
                            regionListFromYearAndMonthDataForZone: response,
                            regionListFromYearAndMonthDataForSalesRegion: response,
                            stateListFromYearAndMonth: response,
                            salesRegionName: response.length !== 0 ? response[0].name : "",
                        });
                        // getStateRegionwiseListFromYearAndMonth({
                        //   month: dayjs().format("MM"),
                        //   year: dayjs().format("YYYY"),
                        //   regionId: response[0].id,
                        // }).then(({ response, success }) => {
                        //   if (!success) {
                        //     showNotification({ msg: serverMsg, severity: "error" });
                        //   } else {
                        //     const stateListFromYearAndMonthList = response.length !== 0 ? response : [];
                        //     localStorage.setItem("stateListFromYearAndMonth", JSON.stringify(stateListFromYearAndMonthList));
                        //     localStorage.setItem("stateListFromYearAndMonthTotal", dashboardData?.stateListFromYearAndMonthListTotal);
                        //     this.setState({
                        //       stateListFromYearAndMonth:
                        //         response.length !== 0 ? response : [],
                        //     });
                        //     if (response.length !== 0) {
                        //       getZoneStatewiseListFromYearAndMonth({
                        //         month: dayjs().format("MM"),
                        //         year: dayjs().format("YYYY"),
                        //         stateId: response && response[0].id,
                        //       }).then(({ response, success }) => {
                        //         if (!success) {
                        //           showNotification({ msg: serverMsg, severity: "error" });
                        //         } else {

                        //           const zoneDetailsRegionwiseDataList = response.length !== 0 ? response : [];
                        //           localStorage.setItem("zoneDetailsRegionwiseData", JSON.stringify(zoneDetailsRegionwiseDataList));
                        //           localStorage.setItem("zoneListFromYearAndMonthTotal", dashboardData?.zoneListFromYearAndMonthTotal);

                        //           this.setState({
                        //             zoneDetailsRegionwiseData:
                        //               response.length !== 0 ? response : [],
                        //           });
                        //           getDistrictListFromYearAndMonth({
                        //             month: dayjs().format("MM"),
                        //             year: dayjs().format("YYYY"),
                        //             zoneId: response[0].id,
                        //           }).then(({ response, success }) => {
                        //             if (!success) {
                        //               showNotification({ msg: serverMsg, severity: "error" });
                        //             } else {
                        //               const districtDetailsZonewiseDataList = response.length !== 0 ? response : [];
                        //               localStorage.setItem("districtDetailsZoneWiseData", JSON.stringify(districtDetailsZonewiseDataList));
                        //               localStorage.setItem("districtListFromYearAndMonthTotal", dashboardData?.districtListFromYearAndMonthTotal);

                        //               this.setState({
                        //                 districtDetailsZoneWiseData:
                        //                   response.length !== 0 ? response : [],
                        //               });
                        //               getTalukaListFromYearAndMonth({
                        //                 month: dayjs().format("MM"),
                        //                 year: dayjs().format("YYYY"),
                        //                 districtId: response[0].id,
                        //               }).then(({ response, success }) => {
                        //                 if (!success) {
                        //                   showNotification({ msg: serverMsg, severity: "error" });
                        //                 } else {
                        //                   const talukaDetailsDistrictWiseList = response.length !== 0 ? response : [];
                        //                   localStorage.setItem("talukaDetailsDistrictWiseData", JSON.stringify(talukaDetailsDistrictWiseList));
                        //                   localStorage.setItem("talukaDetailsDistrictWiseTotal", dashboardData?.talukaListFromYearAndMonthTotal);

                        //                   this.setState({
                        //                     talukaDetailsDistrictWiseData:
                        //                       response.length !== 0 ? response : [],
                        //                   });

                        //                   getAreaListFromTalukaYearAndMonth({
                        //                     month: dayjs().format("MM"),
                        //                     year: dayjs().format("YYYY"),
                        //                     talukaId: response[0].id,
                        //                   }).then(({ response, success }) => {
                        //                     if (!success) {
                        //                       showNotification({ msg: serverMsg, severity: "error" });
                        //                     } else {
                        //                       const areaDetailTalukaWiseList = response.length !== 0 ? response : [];
                        //                       localStorage.setItem("areaDetailTalukaWiseData", JSON.stringify(areaDetailTalukaWiseList));
                        //                       localStorage.setItem("areaDetailTalukaWiseTotal", dashboardData?.talukaListFromYearAndMonthTotal);

                        //                       this.setState({
                        //                         areaDetailTalukaWiseData:
                        //                           response.length !== 0 ? response : [],
                        //                       });

                        //                     }
                        //                   });
                        //                 }
                        //               });


                        //             }
                        //           });

                        //         }
                        //       });
                        //     }

                        //   }
                        // });
                    }
                });
            }
        }
    }
    regionHandler = (e, regionId, flag) => {
        const {
            getStateRegionwiseListFromYearAndMonth,
            showLoador,
            showNotification,
            rigionIdHandler,
        } = this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {
            rigionIdHandler(regionId);
            showLoador({ loador: true });
            getStateRegionwiseListFromYearAndMonth({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                regionId: regionId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        tableName: "State",
                        regionId: regionId,
                        flag: flag,
                        HierarchyPopUpFlag: true,
                        stateListFromYearAndMonthFlag: true,
                        zoneByStateDataFlag: false,
                        districtByZoneDataFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaByTalukaDataFlag: false,
                        stateListFromYearAndMonth: response.length !== 0 ? response : [],
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    stateHandler = (e, stateId, flag) => {
        const {
            getZoneStatewiseListFromYearAndMonth,
            showLoador,
            showNotification,
            dashboardData
        } = this.props;
        if (e !== 0) {

            const { month, year, saleMonth } = this.state;
            if (navigator.onLine) {
                showLoador({ loador: true });
                getZoneStatewiseListFromYearAndMonth({
                    month: month !== "" ? month : saleMonth.format("MM"),
                    year: year !== "" ? year : saleMonth.format("YYYY"),
                    stateId: stateId,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        this.setState({
                            tableName: "Zone",
                            flag: flag,
                            zoneDetailsRegionwiseData: response.length !== 0 ? response : [],
                            zoneByStateDataFlag: true,
                            stateListFromYearAndMonthFlag: false,
                            districtByZoneDataFlag: false,
                            talukaByDistrictDataFlag: false,
                            areaByTalukaDataFlag: false,

                        });
                    }
                });
            } else {
                showNotification({ msg: noInternetMsg, severity: "error" });
            }
        } else {
            this.setState({
                tableName: "State",
                flag: 0,
                stateListFromYearAndMonth: dashboardData?.stateListFromYearAndMonthList,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: true,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,

            });
        }

    };

    zoneHandler = (e, zoneId, flag) => {
        const { getDistrictListFromYearAndMonth, showLoador, showNotification, dashboardData } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getDistrictListFromYearAndMonth({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                zoneId: zoneId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        tableName: "District",
                        flag: flag,
                        districtDetailsZoneWiseData: response.length !== 0 ? response : [],
                        districtByZoneDataFlag: true,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaByTalukaDataFlag: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    districtHandler = (e, districtId, flag) => {
        const { getTalukaListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getTalukaListFromYearAndMonth({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                districtId: districtId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        tableName: "Taluka",
                        flag: flag,
                        talukaDetailsData: response.length !== 0 ? response : [],
                        talukaByDistrictDataFlag: true,
                        districtByZoneDataFlag: false,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        areaByTalukaDataFlag: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    talukaHandler = (e, talukaId, flag) => {
        const { getAreaListFromTalukaYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getAreaListFromTalukaYearAndMonth({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                talukaId: talukaId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        tableName: "Area",
                        flag: flag,
                        areaByTalukaDataFlag: true,
                        districtByZoneDataFlag: false,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaDetailsListData: response.length !== 0 ? response : [],
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onDateChange = (date, name) => {
        this.setState({
            month: date.format("MM"),
            year: date.format("YYYY"),
            saleMonth: date,
            showZone: false,
            showArea: false,
        });
        const { type } = this.state;
        const { getRegionListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getRegionListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        regionListFromYearAndMonthFlag: true,
                    });
                }

            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onSearchForSalesRegion = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    }

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;

        if (!searchData || searchData.length === 0) {
            return [];
        }

        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                currentRow["name"] &&
                currentRow["name"]
                    .toString()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase())
            ) {
                isValid = true;
                return true;
            }
        });
    };



    onSearchForZoneDetails = (searchData) => {
        const { dashboardData } = this.props;
        const { zoneDetailsSalesData } = this.state;
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForZoneDetails: searchData,
            });
            const list = dashboardData.zoneListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                zoneDetailsSalesData: list,
            });
        } else {
            this.setState({
                searchValueForZoneDetails: "",
            });
            this.setState({
                zoneDetailsSalesData: dashboardData.zoneListFromYearAndMonth,
            });
        }
    };
    onSearchForAreaDetails = (searchData) => {
        const { dashboardData } = this.props;
        const { areaDetailsData } = this.state;
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForAreaDetails: searchData,
            });
            const list = dashboardData.areaListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                areaDetailsData: list,
            });
        } else {
            this.setState({
                searchValueForAreaDetails: "",
            });
            this.setState({
                areaDetailsData: dashboardData.areaListFromYearAndMonth,
            });
        }
    };

    onSearchForSalesState = (searchData) => {
        const { dashboardData } = this.props;
        const { stateListFromYearAndMonth } = this.state;
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueStateList: searchData,
            });
            const list = dashboardData.stateListFromYearAndMonthList.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                stateListFromYearAndMonth: list,
            });
        } else {
            this.setState({
                searchValueStateList: "",
            });
            this.setState({
                stateListFromYearAndMonth: dashboardData.stateListFromYearAndMonthList,
            });
        }
    };

    handleClosesHierarchyPopUp = () => {
        const { dashboardData } = this.props;
        const { stateListFromYearAndMonth, zoneDetailsRegionwiseData, flag, districtDetailsZoneWiseData, talukaDetailsData } = this.state;
        if (flag == 0) {
            this.setState({
                HierarchyPopUpFlag: false,
            });
        } else if (flag == 1) {
            this.setState({
                tableName: "State",
                flag: 0,
                stateListFromYearAndMonth: stateListFromYearAndMonth,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: true,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,

            });
        } else if (flag == 2) {
            this.setState({
                tableName: "Zone",
                flag: 1,
                zoneDetailsRegionwiseData: zoneDetailsRegionwiseData,
                zoneByStateDataFlag: true,
                stateListFromYearAndMonthFlag: false,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,
            });
        } else if (flag == 3) {
            this.setState({
                tableName: "District",
                flag: 2,
                districtDetailsZoneWiseData: districtDetailsZoneWiseData,
                districtByZoneDataFlag: true,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,
            });
        } else if (flag == 4) {
            this.setState({
                tableName: "Taluka",
                flag: 3,
                talukaDetailsData: talukaDetailsData,
                talukaByDistrictDataFlag: true,
                districtByZoneDataFlag: false,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: false,
                areaByTalukaDataFlag: false,
            });
        }
    };

    render() {
        const { dashboardData } = this.props;
        const {
            saleMonth,
            regionListFromYearAndMonthDataForSalesRegion,
            stateListFromYearAndMonth,
            zoneDetailsSalesData,
            zoneDetailsRegionwiseData,
            districtDetailsData,
            districtDetailsZoneWiseData,
            talukaDetailsDistrictWiseData,
            talukaDetailsData,
            areaDetailsListData,
            searchValueForSalesRegion,
            searchValueForZoneDetails,
            searchValueForAreaDetails,
            searchValueStateList,
            regionListFromYearAndMonthFlag,
            stateListFromYearAndMonthFlag,
            zoneByStateDataFlag,
            districtByZoneDataFlag,
            talukaByDistrictDataFlag,
            areaByTalukaDataFlag,
            areaDetailTalukaWiseData,
            HierarchyPopUpFlag,
            tableName,
            flag,
            regionId,
            regionListTotal,
        } = this.state;

        const regionListFromYearAndMonth = localStorage.getItem("regionListFromYearAndMonth");
        const regionListFromYearAndMonthLocalStorage = JSON.parse(regionListFromYearAndMonth);

        const stateListFromYearAndMonthData = localStorage.getItem("stateListFromYearAndMonth");
        const stateListFromYearAndMonthLocalStorage = JSON.parse(stateListFromYearAndMonthData);

        const zoneListFromYearAndMonthData = localStorage.getItem("zoneDetailsRegionwiseData");
        const zoneListFromYearAndMonthLocalStorage = JSON.parse(zoneListFromYearAndMonthData);

        const districtDetailsListZoneWiseData = localStorage.getItem("districtDetailsZoneWiseData");
        const districtListFromYearAndMonthLocalStorage = JSON.parse(districtDetailsListZoneWiseData);

        const talukaDetailsListDistrictWiseData = localStorage.getItem("talukaDetailsDistrictWiseData");
        const talukaDetailsDistrictWiseLocalStorage = JSON.parse(talukaDetailsListDistrictWiseData);

        const areaDetailsTalukaListWiseData = localStorage.getItem("areaDetailTalukaWiseData");
        const areaDetailTalukaWiseLocalStorage = JSON.parse(areaDetailsTalukaListWiseData);

        const regionListFromYearAndMonthTotalLocalStorage = localStorage.getItem("regionListFromYearAndMonthTotal");
        const stateListFromYearAndMonthTotalLocalStorage = localStorage.getItem("stateListFromYearAndMonthTotal");
        const zoneListFromYearAndMonthTotalLocalStorage = localStorage.getItem("zoneListFromYearAndMonthTotal");
        const districtListFromYearAndMonthTotalLocalStorage = localStorage.getItem("districtListFromYearAndMonthTotal");
        const talukaDetailsDistrictWiseTotalLocalStorage = localStorage.getItem("talukaDetailsDistrictWiseTotal");
        const areaDetailTalukaWiseTotalLocalStorage = localStorage.getItem("areaDetailTalukaWiseTotal");

        return (
            <>
                <div
                    id="layout-wrapper"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="vertical-overlay" />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Region
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForSalesRegion={
                                                                searchValueForSalesRegion
                                                            }
                                                            filteredRows={this.onSearchForSalesRegion}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                padding: 0,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "8%", color: "#000" }}
                                                        >
                                                            Region Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Contribution in total(%)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {regionListFromYearAndMonthFlag
                                                        ? dashboardData.regionListFromYearAndMonth.length !== 0 &&
                                                        this.getFilteredTableData(dashboardData.regionListFromYearAndMonth).map(
                                                            (regionObject, index) => (
                                                                <TableRow key={regionObject.id}>
                                                                    <TableCell align="center">{index + 1}</TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) => this.regionHandler(e, regionObject.id)}
                                                                            style={{ color: cyanBlue, cursor: "pointer" }}
                                                                        >
                                                                            {regionObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right">{regionObject.total}</TableCell>
                                                                    <TableCell align="right">{regionObject.contribution}</TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                        : this.getFilteredTableData(
                                                            regionListFromYearAndMonthLocalStorage &&
                                                            regionListFromYearAndMonthLocalStorage
                                                        ).map((regionObject, index) => (
                                                            <TableRow key={regionObject.id}>
                                                                <TableCell align="center">{index + 1}</TableCell>
                                                                <TableCell width="2%">
                                                                    <div
                                                                        onClick={(e) => this.regionHandler(e, regionObject.id, 0)}
                                                                        style={{ color: cyanBlue, cursor: "pointer" }}
                                                                    >
                                                                        {regionObject.name}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="right">{regionObject.total}</TableCell>
                                                                <TableCell align="right">{regionObject.contribution}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={2}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {regionListFromYearAndMonthTotalLocalStorage ? regionListFromYearAndMonthTotalLocalStorage : regionListTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* Sales Table 1 end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {HierarchyPopUpFlag && (
                    <HierarchyPopUpOld
                        HierarchyPopUpFlag={HierarchyPopUpFlag}
                        handleCloses={this.handleClosesHierarchyPopUp}
                        popUpData={flag == 0 ? stateListFromYearAndMonth
                            : flag == 1 ? zoneDetailsRegionwiseData
                                : flag == 2 ? districtDetailsZoneWiseData
                                    : flag == 3 ? talukaDetailsData
                                        : dashboardData?.areaListFromYearAndMonth}
                        flag={flag}
                        stateHandler={this.stateHandler}
                        zoneHandler={this.zoneHandler}
                        districtHandler={this.districtHandler}
                        talukaHandler={this.talukaHandler}
                        dashboardData={dashboardData}
                        tableName={tableName}
                        stateListFromYearAndMonthFlag={stateListFromYearAndMonthFlag}
                        zoneByStateDataFlag={zoneByStateDataFlag}
                        districtByZoneDataFlag={districtByZoneDataFlag}
                        talukaByDistrictDataFlag={talukaByDistrictDataFlag}
                        areaByTalukaDataFlag={areaByTalukaDataFlag}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    common: state.common,
    dashboardData: state.adminDashboard,
    stockStakeHolderTypeList: state.stockStakeHolderType,
    zoneList: state.zone,
    areaList: state.area,
});

const mapDispatchToProps = {
    onToggle,
    showLoador,
    showNotification,
    getRegionListFromYearAndMonth,
    getZoneStatewiseListFromYearAndMonth,
    getStateRegionwiseListFromYearAndMonth,
    getDistrictListFromYearAndMonth,
    getTalukaListFromYearAndMonth,
    getAreaListFromTalukaYearAndMonth,
};
export default connect(mapStateToProps, mapDispatchToProps)(HierarchyDropDownOld);
