import React from "react";
import { connect } from "react-redux";
import { batchwiseOutletBillingJSON } from "../../DynamicFormsJson/Transaction/batchwiseOutletBillingForm";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatsByAreaIds } from "../../Slice/beat.slice";
import {
  getOutletByBeatIds,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import { getBillingHistoryByOutletAndDate, getBillingHistory, setBillingHistory } from "../../Slice/billingHistory.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import {
  cancelledMsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import { BatchWiseOutletBillingListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

class BatchWiseOutletBillingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      dynamicMasterData: {
        // areaDetail: this.props.areaList.area,
        // beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
      },
      outletIds: [],
      selection: [],
    };
  }

  async componentDidMount() {
    const { getRegion, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody
      , getBeatByArea, getOutletByBeatIds, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.region) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zone) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka) {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area) {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat) {
        showLoador({ loador: true });
        await getOutletByBeatIds({ beatIds: data.beat }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchList = (data) => {
    const { getBillingHistoryByOutletAndDate, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({
        formData: data,
      });


      let billingHistoryData = {
        outletIds: data.outletIds,
        fromDate: data.fromdate,
        toDate: data.todate,
      };
      showLoador({ loador: true });
      getBillingHistoryByOutletAndDate({
        billingHistoryDataForPost: billingHistoryData,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowPDF = (rowData) => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      downloadF({
        url:
          endpoint.outletBillHeader +
          "/report-by-id?id=" +
          rowData.id +
          "&documentType=1",
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        this.props.showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    }
  };

  onDeleteStatic = async (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.outletBillHeader + "/update?id=" + rowData.id + "&status=1",
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          showNotification({ msg: cancelledMsg, severity: "success" });
        }
      });
      showLoador({ loador: false });
      if (this.onSearchList(this.state.formData)) {
        showNotification({ msg: cancelledMsg, severity: "success" });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { billingHistoryList, setBillingHistory } = this.props;
    return (
      <>
        <DynamicMainScreen
          showViewButtonInTable={false}
          getListById={this.getListById}
          showDeleteIcon={true}
          dynamicMasterData={dynamicMasterData}
          formPath={batchwiseOutletBillingJSON.formPath}
          screenTitle={batchwiseOutletBillingJSON.screenTitle}
          searchList={batchwiseOutletBillingJSON.searchList}
          onSearchData={this.onSearchList}
          fieldMeta={batchwiseOutletBillingJSON.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          tableColumns={BatchWiseOutletBillingListColumns}
          tableData={billingHistoryList?.billingHistory}
          apiBaseURL={batchwiseOutletBillingJSON.apiBaseURL}
          showSearchBox={false}
          deleteURLStatic={true}
          callApi={false}
          showEditIcon={false}
          showAddButton={batchwiseOutletBillingJSON.showAddButton}
          showPdf={true}
          rowPDF={this.rowPDF}
          onDeleteStatic={this.onDeleteStatic}
          deleteConditionName="status"
          deleteConditionValue="0"
          showPegination={false}
          getTableData={setBillingHistory}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  billingHistoryList: state.billingHistory,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegion,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getZoneByState,
  getBeatByArea,
  getBeatsByAreaIds,
  getOutletByBeatIds,
  getBillingHistory,
  setBillingHistory,
  getBillingHistoryByOutletAndDate,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchWiseOutletBillingList);
