import CloseIcon from "@mui/icons-material/Close";
import { Grid, Table, TableCell, TableHead, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { LabelCompo } from "../../components/Comman/Label";
import { cyanBlue, labelColor, redColor, titleColor } from "../../config/ColorObj";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import OfferDetail from "./OfferDetail";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TimelineDetailsPopup = ({
  handleCloses,
  open,
  columns,
  rows,
  rowsOffer,
  rowsPastOrder,
  rowsPastOrderOffer,
  DataToSet,
  dataToSetPastOrder,
  companyFlag,
}) => {

  const [offerDetailFlag, setOfferDetailFlag] = useState(false);
  const [currentOrderOfferFlag, setCurrentOrderOfferFlag] = useState(false);
  const [currentOrderOfferId, setCurrentOrderOfferId] = useState("");
  const [pastOrderOfferId, setPastOrderOfferId] = useState("");

  const handleClose = () => {
    setCurrentOrderOfferFlag(false);
    handleCloses();
  };

  const currentOrderHandleCellClick = (data) => {
    setCurrentOrderOfferId(data.id)
    setCurrentOrderOfferFlag(true);
    setOfferDetailFlag(true)
  }

  const pastOrderHandleCellClick = (data) => {
    setPastOrderOfferId(data.id)
    setOfferDetailFlag(true)
  }


  const handleCloseOfferDetail = () => {
    setOfferDetailFlag(false)
    setCurrentOrderOfferFlag(false)
  }

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >

        {companyFlag == 2 ? (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Order Details
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <DynamicDetails DataToSet={DataToSet} />
              <DynamicTable
                data={rows}
                tableHead={columns}
                showPegination={false}
                isActionColActive={false}
              />
              <br />
              {rowsOffer.length !== 0 && (
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "5.5%" }}
                        >
                          #
                        </TableCell>
                        <TableCell align="center" sx={{ width: "80%" }}>
                          Offer Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {rowsOffer.map(
                      (offerAppliedData, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="center">
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ width: "80%", cursor: "pointer", color: cyanBlue }} // Adding cursor to indicate it's clickable
                                onClick={() => currentOrderHandleCellClick(offerAppliedData)} // onClick event handler
                              >
                                {offerAppliedData !== null ? offerAppliedData.name : ""}
                              </TableCell>

                            </TableRow>
                          </>
                        );
                      }
                    )}
                  </Table>
                </>
              )}


              <br />
            </DialogContent>
            <br />

            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Past Order Detail
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <DynamicDetails DataToSet={dataToSetPastOrder} />
              <DynamicTable
                data={rowsPastOrder}
                tableHead={columns}
                showPegination={false}
                isActionColActive={false}
              />
              <br />
              {rowsPastOrderOffer.length !== 0 && (
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "5.5%" }}
                        >
                          #
                        </TableCell>
                        <TableCell align="center" sx={{ width: "80%" }}>
                          Offer Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {rowsPastOrderOffer.map(
                      (offerAppliedData, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="center">
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ width: "80%", cursor: "pointer", color: cyanBlue }} // Adding cursor to indicate it's clickable
                                onClick={() => pastOrderHandleCellClick(offerAppliedData)}                              >
                                {offerAppliedData !== null
                                  ? offerAppliedData.name
                                  : ""}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }
                    )}
                  </Table>
                </>
              )}
            </DialogContent>

            {offerDetailFlag && (
              <OfferDetail
                open={offerDetailFlag}
                currentOrderOfferId={currentOrderOfferId}
                pastOrderOfferId={pastOrderOfferId}
                currentOrderOfferFlag={currentOrderOfferFlag}
                handleCloseOfferDetail={handleCloseOfferDetail}
              />
            )}

          </>
        ) : (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Order Details
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <DynamicDetails DataToSet={DataToSet} />
              <DynamicTable
                data={rows}
                tableHead={columns}
                showPegination={false}
                isActionColActive={false}
              />
              <br />
            </DialogContent>

          </>
        )}


      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineDetailsPopup);
