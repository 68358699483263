import endpoint from "../../config/endpoints";

export const subAdminUserJson = {
  formPath: "/sub-admin-user-form",
  formPathEdit: "/sub-admin-user-edit",
  apiBaseURL: endpoint.developerAdminUser,
  screenTitle: "Sub User",
  openFormPathStatus: "/submit-access-role",
  isEditURL: endpoint.developerAdminUser + "/update",
  isActiveURL: endpoint.developerAdminUser + "/update-is-active",
  showAddButton: true,

  fieldMeta: [
    {
      label: "Region",
      controlType: "multiSelect",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "regionIds",
      getListId: "regionIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "stateIds",
      getListFrom: "regionIds",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "stateIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "districtIds",
      getListFrom: "zoneIds",
      getListId: "districtIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "talukaIds",
      getListFrom: "districtIds",
      getListId: "talukaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "talukaIds",
      getListId: "areaIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "Email Id",
      controlType: "textfield",
      placeHolder: "Email Id",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      valid: true,
      validName: "email",
      isMandatory: true,
    },
  ],
};
