import config from "../config/config";
const endpoint = {
  auth: "/api/auth/login",
  room: "/api/acdmc/v1/rooms",
  floor: "/api/acdmc/v1/floor",
  zone: config.urlStart + "/v1/zone",
  unitOfMesurement: config.urlStart + "/v1/uom",
  document: config.urlStart + "/v1/document",
  category: config.urlStart + "/v1/category",
  categoryLatest: "/api/distributionreport/v1/category",
  subCategory: config.urlStart + "/v1/sub-category",
  salesExecutive: config.urlStart + "/v1/sales-team",
  stockStakeHolderType: config.urlStart + "/v1/stock-stake-holder-type",
  tax: config.urlStart + "/v1/tax-percentage",
  area: config.urlStart + "/v1/area",
  product: config.urlStart + "/v1/product",
  region: config.urlStart + "/v1/region",
  regionLatest: "/api/distributionreport/v1/region",
  stackholder: config.urlStart + "/v1/stock-stake-holder",
  stackholderDocument:
    config.urlStart + "/v1/stock-stake-holder-document-table",
  uploadImage: config.urlStart + "/v1/file-upload",
  orderConfiguration: config.urlStart + "/v1/order-configuration",
  mrpConfiguration: config.urlStart + "/v1/product-mrp-configuration",
  manager: config.urlStart + "/v1/sales-team",
  forgotPassword: "/api/auth/user",
  userAccess: config.urlStart + "/v1/access-role",
  module: config.urlStart + "/v1/module",
  productRateConfiguration: config.urlStart + "/v1/product-rate-configuration",
  checkPublicationDate:
    config.urlStart + "/v1/product-rate-configuration-applicable-configuration",
  outletRateStructure:
    config.urlStart + "/v1/product-rate-configuration-outlet",
  stackHolderTypeDocument:
    config.urlStart + "/v1/stock-stake-holder-document-table",
  productRateConfOutlet:
    config.urlStart + "/v1/product-rate-applicable-configuration-outlet",
  assignRateConfigurationToStakeholder:
    config.urlStart + "/v1/stock-stake-holder-product-rate-configuration",
  productRateConfigurationDetails:
    config.urlStart + "/v1/product-rate-configuration-details",
  productRateConfigurationOutletDetails:
    config.urlStart + "/v1/product-rate-configuration-details-outlet",
  range: config.urlStart + "/v1/range",
  grievanceCategory: config.urlStart + "/v1/grivience-category",
  outletType: config.urlStart + "/v1/outlet-type",
  beat: config.urlStart + "/v1/beat",
  year: config.urlStart + "/v1/year",
  outlet: config.urlStart + "/v1/outlet",
  productRateConfigurationDetailsOutlet:
    config.urlStart + "/v1/product-rate-configuration-details-outlet",
  audit: config.urlStart + "/v1/audit-head",
  auditQuestion: config.urlStart + "/v1/audit-question",
  dayWiseBitAssign: config.urlStart + "/v1/beat-assign-daywise-header",
  target: config.urlStart + "/v1/target",
  regionAndTarget: config.urlStart + "/v1/region-target-percentage",
  assignBeat: config.urlStart + "/v1/beat-assign",
  auditOption: config.urlStart + "/v1/audit-question-option",
  projection: config.urlStart + "/v1/sales-excutive-beat-projection-header",
  grievance: config.urlStart + "/v1/t-grivience",
  companyTarget: config.urlStart + "/v1/company-target",
  adminDashboard: config.urlStart + "/v1/order",
  adminDashboardLatest: "/api/distributionreport/v1/order",
  outletOrderDetail: config.urlStart + "/v1/outlet-order-detail",
  outletOrderDetailLatest: "/api/distributionreport/v1/outlet-order-detail",
  outletOrderHeader: config.urlStart + "/v1/outlet-order-header",
  outletOrderHeaderLatest: "/api/distributionreport/v1/outlet-order-header",
  outletBillHeader: config.urlStart + "/v1/outlet-bill-header",
  outletBillHeaderLastest: "/api/distributionreport/v1/outlet-bill-header",
  outletBillDetail: config.urlStart + "/v1/outlet-bill-detail",
  manualOrder: config.urlStart + "/v1/manual-order",
  manualOrderLatest: "/api/distributionreport/v1/manual-order",
  paymentReceiptOutlet: config.urlStart + "/v1/payment-reciept-outlet",
  paymentReceipt: config.urlStart + "/v1/payment-reciept",
  creditNote: config.urlStart + "/v1/credit-note-header",
  creaditNoteHeader: config.urlStart + "/v1/holder-credit-note-header",
  grnGVNHeader: config.urlStart + "/v1/grn-gvn-header",
  grnGVNDetail: config.urlStart + "/v1/grn-gvn-detail",
  stockStakeholderOrderHeader:
    config.urlStart + "/v1/stock-stake-holder-order-header",
  stockStakeholderBillHeader:
    config.urlStart + "/v1/stock-stake-holder-bill-header",
  creditNoteHeader: config.urlStart + "/v1/holder-credit-note-header",
  paymentReceipt: config.urlStart + "/v1/payment-reciept",
  manageStockHeader: config.urlStart + "/v1/stock-consumption-header",
  manageStockHeaderLatest:
    "/api/distributionreport/v1/stock-consumption-header",
  manageStockDetail: config.urlStart + "/v1/stock-consumption-detail",
  noticeMsg: config.urlStart + "/v1/notice-msg",
  multilingual: config.urlStart + "/v1/language",
  languageJson: config.urlStart + "/v1/language-json",
  state: config.urlStart + "/v1/state",
  paymentReceiptDetail: config.urlStart + "/v1/payment-reciept-detail",
  distributorDashbourd: "/api/distributionreport/v1/dashboard-for-stock-holder",
  monthWiseTotalAmount: "/api/distributionreport/v1/month-wise-total-amount",
  checkin: config.urlStart + "/v1/check-in-out",
  location: config.urlStart + "/v1/location-detail",
  yearEndHeader: config.urlStart + "/v1/year-end-header",
  salesTeamOrderConfig: config.urlStart + "/v1/sales-team-order-configuration",
  company: config.urlStart + "/v1/company",
  offer: config.urlStart + "/v1/offer-header",
  setting: config.urlStart + "/v1/setting",
  report: config.urlStart + "/v1/report-master",
  matrixReport: config.urlStart + "/v1/matrix-report-master",
  type: config.urlStart + "/v1/report-type-master",
  companyCategoryConfiguration:
    config.urlStart + "/v1/company-category-configuration",
  packagingType: config.urlStart + "/v1/packaging-type",
  district: config.urlStart + "/v1/district",
  group: config.urlStart + "/v1/group",
  country: config.urlStart + "/v1/country",
  taluka: config.urlStart + "/v1/taluka",
  brand: config.urlStart + "/v1/brand",
  tag: config.urlStart + "/v1/tag",
  stockHolderAndProductConfiguration:
    config.urlStart + "/v1/stock-holder-and-product-configuration",
  productRateStructure: config.urlStart + "/v1/product-rate-structure",
  stockHolderOrderingConfiguration:
    config.urlStart + "/v1/stock-holder-ordering-time",
  stockDocumentDetail: config.urlStart + "/v1/stock-document-detail",
  salesTeamDocumentTable: config.urlStart + "/v1/sales-team-document-detail",
  productRateStructureAssignation:
    config.urlStart + "/v1/product-rate-structure-assignation",
  userType: config.urlStart + "/v1/user-type",
  beatTarget: config.urlStart + "/v1/beat-target",
  importData: config.urlStart + "/v1/import",
  exportData: config.urlStart + "/v1/export",
  exportDataNew: "/api/distributionreport/v1/export",
  transaction: config.urlStart + "/v1/transaction",
  transactionOutlet: config.urlStart + "/v1/transaction-outlet",
  paymentRecieptOutletDetail:
    config.urlStart + "/v1/payment-reciept-outlet-detail",
  developerAdminUser: config.urlStart + "/v1/devoloper-admin-user",
  user: config.urlStart + "/v1/user",
  dashboardAdmin: "/api/distributionreport/v1/dashboard-for-admin",
  vehicle: config.urlStart + "/v1/vehicle",
  vehicleLatest: "/api/distributionreport/v1/vehicle",
  transporter: config.urlStart + "/v1/transporter",
  monthlySalesAnalysis: config.urlStart + "/v1/monthly-sales-analysis",
  monthlySalesBillAnalysis: config.urlStart + "/v1/monthly-sale-bill-analysis",
  holderManualOrder: config.urlStart + "/v1/holder-manual-order",
  bankDetails: config.urlStart + "/v1/bank-details",
  paymentTerm: config.urlStart + "/v1/payment-term",
  sapCreditLimit: config.urlStart + "/v1/sap-credit-limit",
  holderBillSync: config.urlStart + "/v1/holder-bill-sync",
  eInvoice: config.urlStart + "/v1/e-invoice",
  reportForExcelReports: config.urlStart + "/v1/report",
  timeSlot: config.urlStart + "/v1/time-slot",
  workingType: config.urlStart + "/v1/working-type",
  otherTask: config.urlStart + "/v1/other-task",
  managerDashboard: config.urlStart + "/v1/dashboard-for-sales-team",
  auditTransaction: config.urlStart + "/v1/audit-transaction",
  leaveSummary: config.urlStart + "/v1/leave-summary",
  leaveType: config.urlStart + "/v1/leave-type",
  leaveStructure: config.urlStart + "/v1/leave-structure-header",
  claimType: config.urlStart + "/v1/claim-type",
  claimStructure: config.urlStart + "/v1/claim-structure-header",
  claimStructureAllotment: config.urlStart + "/v1/claim-structure-allotment",
  leaveStructureAllotment: config.urlStart + "/v1/leave-structure-allotment",
  assets: config.urlStart + "/v1/assets",
  vender: config.urlStart + "/v1/vender",
  assetsChecklist: config.urlStart + "/v1/assets-checklist",
  assetsChecklistDetails: config.urlStart + "/v1/assets-checklist-details",
  productCatelogue: config.urlStart + "/v1/product-catelogue",
  assetsRequestDetails: config.urlStart + "/v1/assets-request-details",
  assetsImplementationDetails:
    config.urlStart + "/v1/assets-implementaion-details",
  typeOfBusiness: config.urlStart + "/v1/type-of-business",
  investedAmount: config.urlStart + "/v1/invested-amount",
  annualTurnover: config.urlStart + "/v1/annual-turnover",
  noOfStaff: config.urlStart + "/v1/no-of-staff",
  distributorInquiry: config.urlStart + "/v1/distributor-inquiry",
  distributorInquiryWatcher:
    config.urlStart + "/v1/distributor-inquiry-watcher",
  assetsImplementationTracking:
    config.urlStart + "/v1/assets-implementation-details-tracking",
  minutesOfMeetingHeader: config.urlStart + "/v1/minutes-of-meeting-header",
  distributorInquiryDocumentDetails:
    config.urlStart + "/v1/distributor-inquiry-document-details",
  questionaryTransaction: config.urlStart + "/v1/questionary-transaction",
  webLogin: "/api/auth/login/web-login",
  logOut: "/api/auth/app-log-out",
  salesTeamCheckInImage: config.urlStart + "/v1/sales-team-check-in-image",
  managerLogs: config.urlStart + "/v1/manager-logs",
  weeklyOff: config.urlStart + "/v1/weekly-off",
};

export default endpoint;
