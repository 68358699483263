import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { RateConfigurationEditingForStockholderColumns } from "../../tableColumns/table-columns";
import { RateConfigurationEditingForStockHolderJson } from "../../DynamicFormsJson/Transaction/stockholderProduct";
import { getStockHolderAndProductConfigurationByUserRegions } from "../../Slice/rateConfigurationEditingForStockholder.slice";
import {
  deleteConfirmMsg,
  deleteFailedMsg,
  deletemsg,
  noInternetMsg,
} from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
class RateConfigurationEditingForStockholderList extends React.Component {
  constructor(props) {
    super(props);
  }

  rowView = (row) => {
    this.props.navigate(
      "/rate-configuration-editing-for-stock-holder-view/" +
        row.stockStakeHolder
    );
  };

  rowEdit = (row) => {
    this.props.navigate(
      "/rate-configuration-editing-for-stock-holder-form/" +
        row.stockStakeHolder
    );
  };
  onDelete = (row) => {
    const {
      showLoador,
      showNotification,
      getStockHolderAndProductConfigurationByUserRegions,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url:
          endpoint.stockHolderAndProductConfiguration +
          "/by-stock-stake-holder?stockStakeHolderId=" +
          row.stockStakeHolder,
      }).then(({ success }) => {
        if (success) {
          showLoador({ loador: false });
          showNotification({ msg: deletemsg });
          getStockHolderAndProductConfigurationByUserRegions();
        } else {
          showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });
          showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const {
      rateConfigurationEditingForStockholderList,
      getStockHolderAndProductConfigurationByUserRegions,
    } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={RateConfigurationEditingForStockHolderJson.formPath}
          screenTitle={RateConfigurationEditingForStockHolderJson.screenTitle}
          showViewButtonInTable={
            RateConfigurationEditingForStockHolderJson.formPathView
          }
          fieldMeta={RateConfigurationEditingForStockHolderJson.fieldMeta}
          showAddButton={
            RateConfigurationEditingForStockHolderJson.showAddButton
          }
          tableColumns={RateConfigurationEditingForStockholderColumns}
          tableData={
            rateConfigurationEditingForStockholderList?.stockHolderAndProductConfiguration
          }
          getTableData={getStockHolderAndProductConfigurationByUserRegions}
          apiBaseURL={RateConfigurationEditingForStockHolderJson.apiBaseURL}
          baseIdColumn={RateConfigurationEditingForStockHolderJson.baseIdColumn}
          showEditIcon={true}
          showPdfDownload={false}
          showExcelDownload={false}
          formPathView={RateConfigurationEditingForStockHolderJson.formPathView}
          onDeleteStatic={this.onDelete}
          rowView={this.rowView}
          deleteURLStatic={true}
          staticView={true}
          rowEdit={this.rowEdit}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  rateConfigurationEditingForStockholderList:
    state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  getStockHolderAndProductConfigurationByUserRegions,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RateConfigurationEditingForStockholderList);
