import React from "react";
import { connect } from "react-redux";

import { targetJson } from "../../DynamicFormsJson/Transaction/Targrt";
import { getActiveYear } from "../../Slice/year.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTargetByStakeHolderTypeIdAndYearIdWithFilterPost } from "../../Slice/target.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import AutoComplete from "../../components/Comman/AutoComplete";
import { withRouter } from "../../components/withRouter";
import {

  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import { compose } from "@reduxjs/toolkit";
import { titleColor, redColor } from "../../config/ColorObj";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody, getAreaByUserType } from "../../Slice/area.slice";
import NewMultipleSelect from "../../components/Comman/NewMultipleSelect";

class TargetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,
        yearDetail: this.props.yearList?.activeYear,
      },
      targetList: [],
      year: "",
      freezFlag: false,
      region: "",
      state: "",
      zone: [],
      district: [],
      taluka: [],
      area: [],
      stockStakeHolderType: "",
      formError: {},
      adminFlag: false,
      error: false,
    };
  }
  async componentDidMount() {
    const { getRegionNew, getAreaByUserType, getStockStakeHolderType, getActiveYear, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        })

        showLoador({ loador: true });
        getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      } else {
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      }

      const isFreezTarget = localStorage.getItem("isFreezTarget");
      this.setState({
        freezFlag: isFreezTarget === "1" ? true : false,
      });


      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
      showLoador({ loador: true });
      await getActiveYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList
    ) {
      if (
        this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stockStakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.yearList !== nextProps.yearList) {
      if (this.props.yearList?.activeYear !== nextProps.yearList?.activeYear) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.yearList?.activeYear,
          },
        });
      }
    }
  }

  checkValidationForAdmin = () => {
    const { year, stockStakeHolderType, region, state, zone, district, taluka, area } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (year === "" || year === null) {
      formIsValid = false;
      formErrors["yearError"] = "*Year required";
    }
    if (stockStakeHolderType === "" || stockStakeHolderType === null) {
      formIsValid = false;
      formErrors["stockStakeHolderError"] = "*Stock StakeHolder Type required";
    }
    if (region === "" || region === null) {
      formIsValid = false;
      formErrors["regionError"] = "*Region required";
    }
    if (state === "" || state === null) {
      formIsValid = false;
      formErrors["stateError"] = "*State required";
    }
    if (zone.length === 0 || zone === null) {
      formIsValid = false;
      formErrors["zoneError"] = "*Zone required";
    }
    if (district.length === 0 || district === null) {
      formIsValid = false;
      formErrors["districtError"] = "*District required";
    }
    if (taluka.length === 0 || taluka === null) {
      formIsValid = false;
      formErrors["talukaError"] = "*Taluka required";
    }
    if (area.length === 0 || area === null) {
      formIsValid = false;
      formErrors["areaError"] = "*Area required";
    }

    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };

  checkValidation = () => {
    const { year, stockStakeHolderType, area } = this.state;
    console.log(area);
    let formErrors = {};
    let formIsValid = true;

    if (year === "" || year === null) {
      formIsValid = false;
      formErrors["yearError"] = "*Year required";
    }
    if (stockStakeHolderType === "" || stockStakeHolderType === null) {
      formIsValid = false;
      formErrors["stockStakeHolderError"] = "*Stock StakeHolder Type required";
    }

    if (area.length === 0 || area === null) {
      formIsValid = false;
      formErrors["areaError"] = "*Area required";
    }

    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };

  onSearchData = () => {
    const { freezFlag, adminFlag } = this.state;
    if (adminFlag ? this.checkValidationForAdmin() : this.checkValidation()) {
      const {
        getTargetByStakeHolderTypeIdAndYearIdWithFilterPost,
        showLoador,
        showNotification,
      } = this.props;
      const { area, year, stockStakeHolderType, } = this.state;
      if (navigator.onLine) {


        let jsonDataByPost = {
          stockStakeHolderTypeId: stockStakeHolderType,
          yearId: year,
          areaIds: area,
          isFreez: freezFlag ? 1 : 0,
        }

        showLoador({ loador: true });
        getTargetByStakeHolderTypeIdAndYearIdWithFilterPost({ jsonDataByPost }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  onAddButtonClick = () => {
    this.props.navigate(targetJson.formPath);
  };
  onAutocompleteChange = (name, newValue) => {
    const { getStateByRegion, getZoneByState, showLoador, showNotification } = this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }

    if (name === "region") {
      this.setState({
        region: newValue,
      });
      showLoador({ loador: true });
      getStateByRegion({ regionIds: newValue }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
    } else if (name === "state") {
      this.setState({
        state: newValue,
      });
      showLoador({ loador: true });
      getZoneByState({ stateIds: newValue }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
    }

  };

  onMultiSelectChange = (value, name) => {
    const { getDistrictByZone, districtList, getTalukaByDistrict, talukaList, getAreaByTalukaPostBody, zoneList, areaList, showLoador, showNotification, } = this.props;

    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      if (name === "zone") {
        let zoneListAll = [];
        zoneList.zoneByState.map((data) => {
          if (data.id !== "all") {
            zoneListAll.push(data.id);
          }
        });
        this.setState({
          zone: zoneListAll,
        });

        showLoador({ loador: true });
        getDistrictByZone({ zoneIds: zoneListAll }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });

      } else if (name === "district") {
        let districtListAll = [];
        districtList.districtByZone.map((data) => {
          if (data.id !== "all") {
            districtListAll.push(data.id);
          }
        });
        this.setState({
          district: districtListAll,
        });

        showLoador({ loador: true });
        getTalukaByDistrict({ districtIds: districtListAll }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });

      } else if (name === "taluka") {
        let talukaListAll = [];
        talukaList.talukaByDistrict.map((data) => {
          if (data.id !== "all") {
            talukaListAll.push(data.id);
          }
        });
        this.setState({
          taluka: talukaListAll,
        });

        let talukaIdsByPost = {
          talukaIds: talukaListAll,
        };

        showLoador({ loador: true });
        getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });

      } else if (name === "area") {
        let areaListALL = [];
        areaList.areaByTaluka.map((data) => {
          if (data.id !== "all") {
            areaListALL.push(data.id);
          }
        });
        this.setState({
          area: areaListALL,
        });

      }

    } else {
      if (name === "zone") {
        this.setState({
          zone: value,
        });

        if (value != "") {
          showLoador({ loador: true });
          getDistrictByZone({ zoneIds: value }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
        }

      } else if (name === "district") {
        this.setState({
          district: value,
        });
        if (value != "") {
          showLoador({ loador: true });
          getTalukaByDistrict({ districtIds: value }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
        }

      } else if (name === "taluka") {
        this.setState({
          taluka: value,
        });
        if (value != "") {
          let talukaIdsByPost = {
            talukaIds: value,
          };
          showLoador({ loador: true });
          getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
        }

      } else if (name === "area") {
        this.setState({
          area: value,
        });
      }
    }
  };

  render() {
    const { targetList, yearList, stockStakeHolderTypeList, regionList, stateList, zoneList, districtList, talukaList, areaList } = this.props;
    const { year, stockStakeHolderType, region, state, zone, district, taluka, area, formError, adminFlag } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={targetJson.screenTitle}
            showTable={false}
            showBackButton={false}
            showAddButton={false}
            onAddButtonClick={this.onAddButtonClick}
          />
          <br />
          {adminFlag ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Region :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"region"}
                    value={region}
                    options={regionList?.region}
                    placeHolder="Select Region"
                    onChange={this.onAutocompleteChange}
                    isError={formError.regionError ? true : false}
                    errorText={
                      formError.regionError
                        ? formError.regionError
                        : " "
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="State :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"state"}
                    value={state}
                    options={stateList?.stateByRegion}
                    placeHolder="Select State"
                    onChange={this.onAutocompleteChange}
                    isError={formError.stateError ? true : false}
                    errorText={formError.stateError ? formError.stateError : " "}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Zone :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <NewMultipleSelect
                    er={formError["zoneError"] ? formError["zoneError"] : ""}
                    label={"Select Zone"}
                    name1="zone"
                    value1={zone}
                    names={[{ id: "all", name: "All" }, ...zoneList?.zoneByState]}
                    onChange={this.onMultiSelectChange}
                  />
                </Grid>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="District :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <NewMultipleSelect
                    er={formError["districtError"] ? formError["districtError"] : ""}
                    label={"Select District"}
                    name1="district"
                    value1={district}
                    names={[{ id: "all", name: "All" }, ...districtList?.districtByZone]}
                    onChange={this.onMultiSelectChange}
                  />
                </Grid>
              </Grid>


              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Taluka :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <NewMultipleSelect
                    er={formError["talukaError"] ? formError["talukaError"] : ""}
                    label={"Select Taluka"}
                    name1="taluka"
                    value1={taluka}
                    names={[{ id: "all", name: "All" }, ...talukaList?.talukaByDistrict]}
                    onChange={this.onMultiSelectChange}
                  />
                </Grid>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Area :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <NewMultipleSelect
                    er={formError["areaError"] ? formError["areaError"] : ""}
                    label={"Select Area"}
                    name1="area"
                    value1={area}
                    names={[{ id: "all", name: "All" }, ...areaList?.areaByTaluka]}
                    onChange={this.onMultiSelectChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Stock Holder Type :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"stockStakeHolderType"}
                    value={stockStakeHolderType}
                    options={stockStakeHolderTypeList?.stockStakeHolderType}
                    placeHolder="Select Stock Holder Type"
                    onChange={this.onAutocompleteChange}
                    isError={formError.stockStakeHolderError ? true : false}
                    errorText={
                      formError.stockStakeHolderError
                        ? formError.stockStakeHolderError
                        : " "
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Year :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"year"}
                    value={year}
                    options={yearList?.activeYear}
                    placeHolder="Select Year"
                    onChange={this.onAutocompleteChange}
                    isError={formError.yearError ? true : false}
                    errorText={formError.yearError ? formError.yearError : " "}
                  />
                </Grid>
                <Grid container sm={1} xs={12} md={1} lg={1}>
                  <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Search"}
                      onClick={this.onSearchData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>


          ) : (

            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Area :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <NewMultipleSelect
                    er={formError["areaError"] ? formError["areaError"] : ""}
                    label={"Select Area"}
                    name1="area"
                    value1={area}
                    names={[{ id: "all", name: "All" }, ...areaList?.area]}
                    onChange={this.onMultiSelectChange}
                  />
                </Grid>

                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Stock Holder Type :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"stockStakeHolderType"}
                    value={stockStakeHolderType}
                    options={stockStakeHolderTypeList?.stockStakeHolderType}
                    placeHolder="Select Stock Holder Type"
                    onChange={this.onAutocompleteChange}
                    isError={formError.stockStakeHolderError ? true : false}
                    errorText={
                      formError.stockStakeHolderError
                        ? formError.stockStakeHolderError
                        : " "
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Year :"
                  />
                </Grid>
                <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"year"}
                    value={year}
                    options={yearList?.activeYear}
                    placeHolder="Select Year"
                    onChange={this.onAutocompleteChange}
                    isError={formError.yearError ? true : false}
                    errorText={formError.yearError ? formError.yearError : " "}
                  />
                </Grid>
                <Grid container sm={1} xs={12} md={1} lg={1}>
                  <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Search"}
                      onClick={this.onSearchData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
          }
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "3%" }}>
                    Sr. No
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Region
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    State
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Zone
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Area
                  </TableCell>
                  {targetList?.headerListForTarget.map(
                    (headerObject, indexData) => {
                      return (
                        <TableCell align="center" sx={{ width: "6%" }}>
                          {headerObject.monthYear}
                        </TableCell>
                      );
                    }
                  )}
                  <TableCell align="center" sx={{ width: "6%" }}>
                    Total Target
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {targetList?.targetNew.map((yearObjectDataRow, indexRow) => {
                  return (
                    <TableRow>
                      <TableCell width="2%" align="right">
                        {indexRow + 1}
                      </TableCell>
                      <TableCell>{yearObjectDataRow.name}</TableCell>
                      <TableCell>{yearObjectDataRow.regionName}</TableCell>
                      <TableCell>{yearObjectDataRow.stateName}</TableCell>
                      <TableCell>{yearObjectDataRow.zoneName}</TableCell>
                      <TableCell>{yearObjectDataRow.areaName}</TableCell>
                      {targetList?.headerListForTarget.map(
                        (headerObject, indexData) => {
                          if (
                            headerObject.month == "January" &&
                            yearObjectDataRow.jan
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.jan}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "February" &&
                            yearObjectDataRow.feb
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.feb}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "March" &&
                            yearObjectDataRow.march
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.march}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "April" &&
                            yearObjectDataRow.april
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.april}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "May" &&
                            yearObjectDataRow.may
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.may}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "June" &&
                            yearObjectDataRow.jun
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.jun}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "July" &&
                            yearObjectDataRow.july
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.july}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "August" &&
                            yearObjectDataRow.aug
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.aug}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "September" &&
                            yearObjectDataRow.sep
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.sep}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "October" &&
                            yearObjectDataRow.oct
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.oct}
                              </TableCell>
                            );
                          } else if (
                            headerObject.month == "November" &&
                            yearObjectDataRow.nov
                          ) {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.nov}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell align="right">
                                {yearObjectDataRow.dec}
                              </TableCell>
                            );
                          }
                        }
                      )}
                      <TableCell align="right">
                        {yearObjectDataRow.totalTarget}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  targetList: state.target,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  yearList: state.year,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getActiveYear,
  getTargetByStakeHolderTypeIdAndYearIdWithFilterPost,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  showLoador,
  showNotification,
  getAreaByUserType,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TargetTable);
