import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { dragDropTableJson } from "../../DynamicFormsJson/MastersJSON/dragDropTable";
import { getAreaByTaluka, getAreaByUserType, setAreaByTaluka, setArea } from "../../Slice/area.slice";
import { getBeat, setBeat, getBeatByArea } from "../../Slice/beat.slice";
import { getDistrictUpdated, setDistrictByZone, getDistrictByZone } from "../../Slice/district.slice";
import { getRegion, setRegion } from "../../Slice/region.slice";
import { getStateList, setState, getStateByRegion } from "../../Slice/state.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTalukaUpdated, setTalukabyDistrict, getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZone, setZone, getZoneByState } from "../../Slice/zone.slice";
import { getDocument, setDocument } from "../../Slice/document.slice";
import { getOutletType, setOutletType } from "../../Slice/outletType.slice";
import { getYear, setYear } from "../../Slice/year.slice";
import { getActiveNoticeMsg } from "../../Slice/noticeMsg.slice";
import { getBrand, setBrand } from "../../Slice/brand.slice";
import { getCategory, setCategory } from "../../Slice/category.slice";
import { getSubCategory, setSubCategory } from "../../Slice/subCategory.slice";
import { getTax, setTax } from "../../Slice/Tax.slice";
import { getUom, setUom } from "../../Slice/uom.slice";
import { getPackagingType, setPackagingType } from "../../Slice/packagingType.slice";
import { getTag, setTag } from "../../Slice/tag.slice";
import { getGroupProductList, setGroupProduct } from "../../Slice/groupProduct.slice";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import ButtonCompo from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
    exportmsg,
    noInternetMsg,
    serverMsg,
} from "../../config/messageconstant";
import { apiPostForDownload, apiPostForImage } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadExcel } from "../PDF/PDFDownload";
import DragDropTableComponent from "./DragDropTableComponent"
import { getOutletByBeatId, getOutletByUserType, setOutletByBeatId } from "../../Slice/outlet.slice";

class DragDropTable extends React.Component {
    constructor(props) {
        const userTypeId = localStorage.getItem("userTypeId");
        super(props);
        this.state = {
            option: userTypeId == 1 || userTypeId == 6 ? "0" : "5",
            dropdownId: "0",
            salesTeamId: "",
            stakeholderId: "",
            areaIds: "",
            beatIds: "",
            adminFlag: false,
            regionFlag: false,
            stateFlag: false,
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetail: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
                areaDetail: this.props.areaList?.areaByTaluka,
                areabyUserType: this.props.areaList?.area,
                beatDetail: this.props.beatList?.beat,
                salesTeamDetail: this.props.stakeHolderTypeList?.stockStakeHolderType,
            },
            optionList: [
                {
                    id: "0",
                    name: "Region",
                },
                {
                    id: "1",
                    name: "State",
                },
                {
                    id: "2",
                    name: "Zone",
                },
                {
                    id: "3",
                    name: "District",
                },
                {
                    id: "4",
                    name: "Taluka",
                },
                {
                    id: "5",
                    name: "Area",
                },
                {
                    id: "18",
                    name: "Beat  ",
                },
                {
                    id: "19",
                    name: "Outlet  ",
                },
                // {
                //     id: "6",
                //     name: "Document",
                // },
                // {
                //     id: "7",
                //     name: "Outlet Type",
                // },
                // {
                //     id: "8",
                //     name: "Year",
                // },
                {
                    id: "10",
                    name: "Brand",
                },
                {
                    id: "11",
                    name: "Category",
                },
                {
                    id: "12",
                    name: "Sub Category",
                },
                // {
                //     id: "13",
                //     name: "Tax",
                // },
                // {
                //     id: "14",
                //     name: "UOM",
                // },
                {
                    id: "15",
                    name: "Packaging Type",
                },
                // {
                //     id: "16",
                //     name: "Tag",
                // },
                {
                    id: "17",
                    name: "Product  ",
                },

            ],

            optionListForDistributor: [
                {
                    id: "5",
                    name: "Area",
                },
                {
                    id: "18",
                    name: "Beat  ",
                },
                {
                    id: "19",
                    name: "Outlet  ",
                },
            ],

            fileName: "",
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.area !== nextProps.areaList?.area
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areabyUserType: nextProps.areaList?.area,
                    },
                });
            }
        }

        if (this.props.beatList !== nextProps.beatList) {
            if (
                this.props.beatList?.beat !== nextProps.beatList?.beat
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        beatDetail: nextProps.beatList?.beat,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const userTypeId = localStorage.getItem("userTypeId");
        const { getRegion, getAreaByUserType, showLoador, showNotification } = this.props;
        const { adminFlag } = this.state;
        if (navigator.onLine) {

            if (userTypeId == 1 || userTypeId == 6) {
                this.setState({
                    adminFlag: true,
                });

                showLoador({ loador: true });
                await getRegion().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });

            } else {
                showLoador({ loador: true });
                await getAreaByUserType().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });
            }

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTaluka,
            showLoador,
            showNotification,
            getBeatByArea,
            getOutletByBeatId,
        } = this.props;
        const { option } = this.state;
        if (navigator.onLine) {
            console.log(data);


            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                showLoador({ loador: true });
                await getAreaByTaluka({ talukaIds: data.taluka }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.area && data.area != "") {
                this.setState({
                    areaIds: data.area,
                });
                showLoador({ loador: true });
                await getBeatByArea({ areaId: data.area }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.beat && data.beat != "") {
                showLoador({ loador: true });
                await getOutletByBeatId({ beatId: data.beat }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onAutocompleteChange = () => async (name, newValue) => {
        console.log(newValue);
        const { showLoador,
            getRegion,
            getZone,
            getStateList,
            getDistrictUpdated,
            getTalukaUpdated,
            getAreaByUserType,
            getOutletByUserType,
            getBeat,
            getDocument,
            getOutletType,
            getYear,
            getActiveNoticeMsg,
            getBrand,
            getCategory,
            getSubCategory,
            getTax,
            getUom,
            getPackagingType,
            getTag,
            getGroupProductList,
            showNotification,
        } = this.props;
        const { option, adminFlag } = this.state;
        console.log(option);
        if (newValue != null) {
            this.setState({
                [name]: newValue,
                fileName: "",
            });
            if (navigator.onLine) {

                if (newValue == "0") {
                    showLoador({ loador: true });
                    await getRegion().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "1") {
                    showLoador({ loador: true });
                    await getStateList().then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }

                if (newValue == "2") {
                    showLoador({ loador: true });
                    await getZone().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                // if (newValue == "3") {
                //     showLoador({ loador: true });
                //     await getDistrictUpdated().then(({ success }) => {
                //         showLoador({ loador: false });
                //         if (!success) {
                //             showNotification({ msg: serverMsg, severity: "error" });
                //         }
                //     });
                // }
                // if (newValue == "4") {
                //     showLoador({ loador: true });
                //     await getTalukaUpdated().then(({ success }) => {
                //         showLoador({ loador: false });
                //         if (!success) {
                //             showNotification({ msg: serverMsg, severity: "error" });
                //         }
                //     });
                // }
                // if (newValue == "5") {
                //     showLoador({ loador: true });
                //     await getAreaByUserType().then(({ success }) => {
                //         showLoador({ loador: false });
                //         if (!success) {
                //             showNotification({ msg: serverMsg, severity: "error" });
                //         }
                //     });
                // }
                // if (newValue == "18") {
                //     showLoador({ loador: true });
                //     await getBeat().then(({ success }) => {
                //         showLoador({ loador: false });
                //         if (!success) {
                //             showNotification({ msg: serverMsg, severity: "error" });
                //         }
                //     });
                // }
                // if (newValue == "19") {
                //     showLoador({ loador: true });
                //     await getOutletByUserType().then(({ success }) => {
                //         showLoador({ loador: false });
                //         if (!success) {
                //             showNotification({ msg: serverMsg, severity: "error" });
                //         }
                //     });
                // }
                if (newValue == "6") {
                    showLoador({ loador: true });
                    await getDocument().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "7") {
                    showLoador({ loador: true });
                    await getOutletType().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "8") {
                    showLoador({ loador: true });
                    await getYear().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }

                if (newValue == "10") {
                    showLoador({ loador: true });
                    await getBrand().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "11") {
                    showLoador({ loador: true });
                    await getCategory().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "12") {
                    showLoador({ loador: true });
                    await getSubCategory().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "13") {
                    showLoador({ loador: true });
                    await getTax().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "14") {
                    showLoador({ loador: true });
                    await getUom().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "15") {
                    showLoador({ loador: true });
                    await getPackagingType().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "16") {
                    showLoador({ loador: true });
                    await getTag().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
                if (newValue == "17") {
                    showLoador({ loador: true });
                    await getGroupProductList().then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }

            } else {
                showNotification({
                    msg: noInternetMsg,
                    severity: "error",
                });
            }

        }
    };



    backToList = () => {
        this.props.navigate(-1);
    };






    render() {
        const { dynamicMasterData, adminFlag, option, regionFlag, stateFlag, optionList, optionListForDistributor, fileName } = this.state;
        const { regionList,
            areaList,
            beatList,
            stateList,
            zoneList,
            setRegion,
            setState,
            setZone,
            setYear,
            setDocument,
            setBeat,
            setOutletType,
            setTalukabyDistrict,
            setAreaByTaluka,
            setArea,
            setDistrictByZone,
            districtList,
            talukaList,
            documentList,
            outletTypeList,
            yearList,
            offerList,
            outletList,
            noticeList,
            brandList,
            setBrand,
            setCategory,
            setSubCategory,
            setTax,
            setUom,
            categoryList,
            subCategoryList,
            taxList,
            setTag,
            uomList,
            setPackagingType,
            packagingTypeList,
            setGroupProduct,
            setOutletByBeatId,
            tagList,
            productList,
            showLoador,
            showNotification,
        } = this.props;

        return (
            <>
                <Paper
                    sx={{
                        borderRadius: 2,
                        p: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        screenTitle={"Sorting Table"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={false}
                        onCancel={this.backToList}
                    />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={"Select"}
                            />
                            <AutoComplete
                                keyColName={"id"}
                                value={option}
                                name="option"
                                options={adminFlag ? optionList : optionListForDistributor}
                                onChange={this.onAutocompleteChange()}
                            />
                        </Grid>
                    </Grid>

                    <DynamicFormWithoutSave
                        dynamicMasterData={dynamicMasterData}
                        getListById={this.getListById}
                        searchList={[]}
                        showSaveBtn={false}
                        showSaveBtnMain={false}
                        showTitle={false}
                        screenTitle={dragDropTableJson.screenTitle}
                        fieldMeta={
                            option == "5"
                                ? adminFlag ? dragDropTableJson.fieldMetaForArea : []
                                : option == "4"
                                    ? dragDropTableJson.fieldMetaForTaluka
                                    : option == "3"
                                        ? dragDropTableJson.fieldMetaForDistrict
                                        : option == "18"
                                            ? adminFlag ? dragDropTableJson.fieldMetaForBeat : dragDropTableJson.fieldMetaForBeatForDistributor
                                            : option == "19"
                                                ? adminFlag ? dragDropTableJson.fieldMetaForOutlet : dragDropTableJson.fieldMetaForoutletForDistributor
                                                : []
                        }
                        showCancel={dragDropTableJson.showCancel}
                        show
                        apiBaseURL={dragDropTableJson.apiBaseURL}
                        showSaveNextBtn={dragDropTableJson.showSaveNextBtn}
                    />

                    <DragDropTableComponent
                        data={option == "0" ? regionList?.regionNew
                            : option == "1" ? stateList?.statesNew
                                : option == "2" ? zoneList?.zoneNew
                                    : option == "3" ? districtList?.districtNew
                                        : option == "4" ? talukaList?.talukaNew
                                            : option == "5" ? adminFlag ? areaList?.areaByTalukaNew : areaList?.area
                                                : option == "18" ? beatList?.beatNew
                                                    : option == "19" ? outletList?.outletsNew
                                                        : option == "6" ? documentList?.document
                                                            : option == "7" ? outletTypeList?.outletType
                                                                : option == "8" ? yearList?.year
                                                                    : option == "10" ? brandList?.brandNew
                                                                        : option == "11" ? categoryList?.categoryNew
                                                                            : option == "12" ? subCategoryList?.subCategoryNew
                                                                                : option == "13" ? taxList?.tax
                                                                                    : option == "14" ? uomList?.uom
                                                                                        : option == "15" ? packagingTypeList?.packagingTypeNew
                                                                                            : option == "16" ? tagList?.tag
                                                                                                : option == "17" ? productList?.groupProductNew
                                                                                                    : ""
                        }
                        option={option}
                        adminFlag={adminFlag}
                        set={option == "0" ? setRegion
                            : option == "1" ? setState
                                : option == "2" ? setZone
                                    : option == "3" ? setDistrictByZone
                                        : option == "4" ? setTalukabyDistrict
                                            : option == "5" ? adminFlag ? setAreaByTaluka : setArea
                                                : option == "18" ? setBeat
                                                    : option == "19" ? setOutletByBeatId
                                                        : option == "6" ? setDocument
                                                            : option == "7" ? setOutletType
                                                                : option == "8" ? setYear
                                                                    : option == "10" ? setBrand
                                                                        : option == "11" ? setCategory
                                                                            : option == "12" ? setSubCategory
                                                                                : option == "13" ? setTax
                                                                                    : option == "14" ? setUom
                                                                                        : option == "15" ? setPackagingType
                                                                                            : option == "16" ? setTag
                                                                                                : option == "17" ? setGroupProduct : ""}
                        showLoador={showLoador}
                        showNotification={showNotification}

                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stakeHolderTypeList: state.stockStakeHolderType,
    areaList: state.area,
    regionList: state.region,
    outletList: state.outlet,
    stateList: state.states,
    zoneList: state.zone,
    beatList: state.beat,
    districtList: state.district,
    talukaList: state.taluka,
    documentList: state.document,
    outletTypeList: state.outletType,
    yearList: state.year,
    noticeList: state.noticeMsg,
    brandList: state.brand,
    categoryList: state.category,
    subCategoryList: state.subCategory,
    taxList: state.tax,
    uomList: state.uom,
    packagingTypeList: state.packagingType,
    tagList: state.tag,
    productList: state.groupProduct,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getStockStakeHolderType,
    getStateByRegion,
    downloadExcel,
    getZone,
    getRegion,
    getDistrictByZone,
    getTalukaUpdated,
    getAreaByUserType,
    getStateList,
    getBeatByArea,
    getOutletByBeatId,
    getOutletType,
    setRegion,
    setState,
    setZone,
    setGroupProduct,
    setCategory,
    setPackagingType,
    setTag,
    setSubCategory,
    setUom,
    setTax,
    setTalukabyDistrict,
    setDocument,
    setOutletType,
    setAreaByTaluka,
    setArea,
    setDistrictByZone,
    setYear,
    getYear,
    getActiveNoticeMsg,
    getBrand,
    getCategory,
    getSubCategory,
    getTax,
    getUom,
    getPackagingType,
    getZoneByState,
    getTag,
    getBeat,
    getTalukaByDistrict,
    getAreaByTaluka,
    setBrand,
    getDistrictUpdated,
    getGroupProductList,
    setOutletByBeatId,
    getDocument,
    setBeat,
    getOutletByUserType,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DragDropTable);
