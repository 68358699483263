import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";


import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
    serverMsg,
    noInternetMsg,
    saveWarningMsg,
    saveFailedMsg,
    savemsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { apiGet } from "../../utils/api_service";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrictPost } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../Slice/area.slice";
import { saveDistributorInquiry, setSaveDistributorInquiry } from "../../Slice/distributorInquiry.slice";


class BasicInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldData: {},
            dynamicMasterData: {
                regionDetail: this.props.regionList?.regionNew,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.regionNew !== nextProps.regionList?.regionNew) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.regionNew,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

    }
    async componentDidMount() {
        const {
            getRegionNew,
            showLoador,
            showNotification,
        } = this.props;

        if (navigator.onLine) {

            await getRegionNew().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    getDataById = (id) => {
        const { showLoador, showNotification, setSaveDistributorInquiry } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            return apiGet({
                url: endpoint.distributorInquiry + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;
                        setSaveDistributorInquiry({ rowObject: rowData })
                        return {
                            data: rowData,
                            success,
                        };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false };
                }
                return { response: {}, success: false };
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrictPost,
            getAreaByTalukaPostBodyUpdated,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.currentRegionId && data.currentRegionId != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.currentRegionId }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.currentStateId && data.currentStateId != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.currentStateId }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.currentZoneIds && data.currentZoneIds != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.currentZoneIds }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.currentDistrictIds && data.currentDistrictIds != "") {
                let districtIdJson = {
                    districtIds: typeof data.currentDistrictIds === "string"
                        ? data.currentDistrictIds.split(",")
                        : data.currentDistrictIds,
                }

                showLoador({ loador: true });
                await getTalukaByDistrictPost({ districtIds: districtIdJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.currentTalukaIds && data.currentTalukaIds != "") {
                let talukaJson = {
                    talukaIds: typeof data.currentTalukaIds === "string"
                        ? data.currentTalukaIds.split(",")
                        : data.currentTalukaIds,
                }
                showLoador({ loador: true });
                await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSubmitData = (dataToSave) => {

        console.log(dataToSave);
        const { distributorInquiryList } = this.props;

        dataToSave = {
            ...distributorInquiryList.distributorInquiryData,
            ...dataToSave,
        };

        console.log("dataToSave");
        console.log(dataToSave);
        swal({
            title: "Are you sure?",
            text: msgConstant.saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(dataToSave);
            }
        });
    };



    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveDistributorInquiry, changeTab } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveDistributorInquiry({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                    if (dataToSave.id) {
                        changeTab("1");
                    } else {
                        window.location.replace("/add-distributor-form/" + response.id);
                        localStorage.setItem("activeTab", "1");
                    }
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };




    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>

                <DynamicFormWithoutSave
                    formPath={AddDistributorJson.formPath}
                    fieldMeta={AddDistributorJson.fieldMetaForBasicInfo}
                    tableColumnsToFilter={AddDistributorJson.tableColumnsToFilter}
                    apiBaseURL={AddDistributorJson.apiBaseURL}
                    baseIdColumn={AddDistributorJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtnMain={false}
                    showSaveBtn={true}
                    saveBtnText={"Next"}
                    onSave={this.onSubmitData}
                    callApi={true}
                    getByIdApi={true}
                    getDataById={this.getDataById}
                    getListById={this.getListById}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    distributorInquiryList: state.distributorInquiry,
});
const mapDispatchToProps = {
    saveDistributorInquiry,
    getRegionNew,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrictPost,
    getAreaByTalukaPostBodyUpdated,
    setSaveDistributorInquiry,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BasicInformation);
