import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { langugae } from "../../config/languageJSON";
import { getActiveLanguage } from "../../Slice/multilingual.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import { redColor, titleColor, yellowColor } from "../../config/ColorObj";
import dayjs from "dayjs";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import jsPDF from "jspdf";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PopUpForPdfAfterSubmit = ({
  handleCloses,
  open,
  pdfData,
  multilingualList,
  getActiveLanguage,
  showLoador,
  showNotification,
}) => {
  const [lang, setLang] = React.useState("2");
  const [openPopUp, setOpenPopUp] = React.useState(false);

  React.useEffect(() => {
    apiMethod();
  }, []);

  const apiMethod = async () => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getActiveLanguage().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          setOpenPopUp(true);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onAutocompleteChange = (name, newValue) => {
    console.log(newValue);
    if (newValue != null) {
      setLang(newValue);
    } else {
      setLang("");
    }
  };

  const onPaymentSummary = () => {
    const fromDate = dayjs().format("DD-MM-YYYY");
    const toDate = dayjs().format("DD-MM-YYYY");

    localStorage.setItem("lang", lang);
    localStorage.setItem("paymentsummaryID", pdfData.id);
    window.open("./paymentsummary-pdf/" + fromDate + "/" + toDate, "_blank");
  };

  const onPDFBill = () => {
    localStorage.setItem("lang", lang);
    const showPdfType = localStorage.getItem("showPdf");

    if (showPdfType == 0) {
      localStorage.setItem("billPdfId", pdfData.id);
      window.open("./bill-pdf", "_blank");
    }

    if (showPdfType == 1) {
      localStorage.setItem("billPdfNewId", pdfData.id);
      window.open("./bill-pdf-new", "_blank");
    }
    if (showPdfType == 2) {
      if (navigator.onLine) {
        let headerId = pdfData.id;
        let billPdfNewData = {
          outletBillHeaderIds: headerId.split(","),
          languageId: lang,
        };

        showLoador({ loador: true });
        apiPost({
          url: endpoint.outletBillHeader + "/report-by-ids",
          postBody: billPdfNewData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            console.log(data.data);
            if (data.data.length != 0) {
              generatePDFHeaderOLd(data.data);
            }
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  const generatePDFHeaderOLd = (rowList) => {
    var doc = new jsPDF();

    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Shruti Font-normal.ttf", Aakar);
      doc.addFont("Shruti Font-normal.ttf", "Shruti Font", "normal");
      doc.setFont("Shruti Font");
    }
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font";
    rowList.map((dataToSet, indexMain) => {
      let indexInfoData = 0;
      var line = 10; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var wrapWidth = 120;
      doc.setFontSize(12);
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }

      let ownerName = langugae[lang].ownerName
        ? langugae[lang].ownerName + " : "
        : "";

      doc.setFontSize(10);
      line = line + 2;
      var DelerText = dataToSet.stockStakeHolderOwnerName
        ? ownerName + dataToSet.stockStakeHolderOwnerName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      line = line + 2;

      doc.setFont(fontName, "normal");
      let address = langugae[lang].address
        ? langugae[lang].address + " : "
        : "";

      var addressText = dataToSet.stockStakeHolderAddress
        ? address + dataToSet.stockStakeHolderAddress
        : "-";
      var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
      for (var i = 0, length = splitTextAddr.length; i < length; i++) {
        // loop thru each line and increase
        if (i == 0) {
          doc.text(splitTextAddr[i], leftMargin, line);
        } else {
          doc.text(splitTextAddr[i], leftMargin, line);
        }

        line = lineHeight + line;
      }
      let yAxis = line + 2;
      var pageWidth = doc.internal.pageSize.getWidth();
      var xPos = pageWidth - 50;
      var xPos1 = pageWidth - 55;
      var top = 0;

      let fromBillContactPersonNo = dataToSet.stockStakeHolderContactPersonNo
        ? dataToSet.stockStakeHolderContactPersonNo
        : "-";
      let mobileNo = langugae[lang].mobileNo
        ? langugae[lang].mobileNo + " : "
        : "";
      doc.text(mobileNo + fromBillContactPersonNo, leftMargin, yAxis, {
        maxWidth: wrapWidth,
      });

      yAxis = yAxis + 7;
      let fromBillFssiFdiNo = dataToSet.stockStakeHolderFssiFdiNo
        ? dataToSet.stockStakeHolderFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.stockStakeHolderGstNo
        ? dataToSet.stockStakeHolderGstNo
        : "-";
      let gSTNo = langugae[lang].gSTNo ? langugae[lang].gSTNo + " : " : "";
      let FSSAINo = langugae[lang].FSSAINo
        ? langugae[lang].FSSAINo + " : "
        : "";

      doc.text(
        gSTNo + fromBillGstNo + " , " + FSSAINo + fromBillFssiFdiNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );
      doc.line(150, 5, 150, 50);
      let pdfLogo =
        localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
          ? logo
          : ofc;
      doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);
      yAxis = yAxis + 5;
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      yAxis = yAxis + 7;
      var xPosTax = pageWidth - 57;
      let yAxisTax = yAxis;
      doc.setFontSize(10);
      doc.setFont(fontName, "bold");
      let taxInvoice = langugae[lang].taxInvoice
        ? langugae[lang].taxInvoice + " : "
        : "";
      doc.text("Tax Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;

      let billNoLan = langugae[lang].billNO
        ? langugae[lang].billNO + " : "
        : "";
      doc.text("Bill No.", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billNo = dataToSet.billNo ? dataToSet.billNo : "-";

      doc.text(billNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let date = langugae[lang].date ? langugae[lang].date + " : " : "";
      doc.text("Date", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

      doc.text(billDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let insertDateTime = dataToSet.insertDateTime
        ? dataToSet.insertDateTime.split(" ")
        : [];
      let insertDateTimeData =
        insertDateTime.length == 2 ? insertDateTime[1] : "";
      let time = langugae[lang].time ? langugae[lang].time + " : " : "";
      doc.text("Time", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");

      doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
        maxWidth: wrapWidth,
      });
      line = yAxis;
      top = 10;
      doc.setFontSize(10);
      let billTo = langugae[lang].billTo ? langugae[lang].billTo + " : " : "";
      doc.text(billTo, leftMargin, line);
      let toBill = dataToSet.outLet ? dataToSet.outLet : {};
      var DText = toBill.firmName ? toBill.firmName : "";
      var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
      for (var i = 0, length = splitTextDText.length; i < length; i++) {
        if (i == 0) {
          doc.text(splitTextDText[i], leftMargin + 15, line);
        } else {
          doc.text(splitTextDText[i], leftMargin, line);
        }
        line = lineHeight + line;
        top = top + lineHeight;
      }

      line = line + 2;
      var DelerText = toBill.ownerName ? ownerName + toBill.ownerName : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFont(fontName, "normal");

      doc.setFontSize(10);
      line = line + 2;
      top = top + 2;
      let taluka =
        toBill.beat != null &&
        toBill.beat.area != null &&
        toBill.beat.area.taluka != null
          ? toBill.beat.area.taluka
          : "";
      let talukaName = taluka != null ? taluka.name : "";
      let districtName =
        taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = toBill.address
        ? toBill.address + " - " + talukaName + " - " + districtName
        : "";
      var DAddrText = address + addr;
      var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
      for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitTextDAddrText[i], leftMargin, line);
        line = lineHeight + line;
        top = top + lineHeight;
      }
      yAxis = line + 2;
      top = top + 2;
      let contactPersonNo = toBill.mobileNo ? toBill.mobileNo : "";
      let gstNo = toBill.gstNo != null ? toBill.gstNo : "-";

      let phoneNo = langugae[lang].phoneNo
        ? langugae[lang].phoneNo + " : "
        : "";
      doc.text(
        phoneNo + contactPersonNo + " , " + gSTNo + gstNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );

      yAxis = yAxis + 7;
      top = top + 7;
      let fssiFdiNo =
        toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
          ? toBill.fssiFdiNo
          : "-";

      doc.text(FSSAINo + fssiFdiNo, leftMargin, yAxis, { maxWidth: 100 });

      yAxis = yAxis + 7;
      top = top + 7;
      doc.line(150, 3, 150, yAxis);
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      let toBillState = toBill.state != null ? toBill.state : {};
      let dataToSetState = dataToSet.state != null ? dataToSet.state : {};

      let HSN = langugae[lang].HSN ? langugae[lang].HSN + " : " : "";

      let itemName = langugae[lang].itemName
        ? langugae[lang].itemName + " : "
        : "";

      let uom = langugae[lang].uom ? langugae[lang].uom + " : " : "";

      let qty = langugae[lang].qty ? langugae[lang].qty + " : " : "";

      let rate = langugae[lang].rate ? langugae[lang].rate + " : " : "";

      let CGST = langugae[lang].CGST ? langugae[lang].CGST + " : " : "";

      let SGST = langugae[lang].SGST ? langugae[lang].SGST + " : " : "";

      let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";

      let IGST = langugae[lang].IGST ? langugae[lang].IGST + " : " : "";

      let total = langugae[lang].total ? langugae[lang].total + " : " : "";

      let headData = [
        { content: "#" },
        { content: HSN },
        { content: itemName },
        { content: uom },
        { content: qty },
        { content: rate },
      ];

      var align = {
        1: { halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      };
      let headData2 = [];
      if (toBillState.id == dataToSetState.id) {
        headData = [
          ...headData,
          { content: CGST, colSpan: 2 },
          { content: SGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
        };
      } else {
        headData = [
          ...headData,
          { content: IGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        };
      }

      let head = [headData, headData2];
      let stockStakeHolderBillDetails =
        dataToSet.outletBillDetails != null ? dataToSet.outletBillDetails : [];
      let productList = [];
      // dataToSet.packagingTypes.map((packaginType)=>{})

      console.log(productList);
      var data = [];
      let totalAmt = 0;
      let qtyTot = 0;
      dataToSet.packagingTypes.map((packagingType) => {
        data.push([
          {
            content: packagingType.name,
            colSpan: 11,

            styles: { fontWeight: 900, fontSize: 10, fontColor: "#000" },
          },
        ]);
        let indexInfo = 0;
        stockStakeHolderBillDetails.map((stockDetails, indexData) => {
          let packagingTypeDetails =
            stockDetails.product != null &&
            stockDetails.product.packagingType != null
              ? stockDetails.product.packagingType
              : {};

          if (packagingType.id == packagingTypeDetails.id) {
            let productData = [];
            let product =
              stockDetails.product != null ? stockDetails.product : {};
            indexInfo = indexInfo + 1;
            productData.push(indexInfo);
            productData.push(stockDetails.hsnCode);
            productData.push(product.name);
            productData.push(
              stockDetails.uom != null ? stockDetails.uom.name : "-"
            );
            productData.push(stockDetails.qty);
            qtyTot = qtyTot + stockDetails.qty;
            productData.push(stockDetails.rate.toFixed(2));
            if (toBillState.id == dataToSetState.id) {
              productData.push(stockDetails.cgstPer.toFixed(2));
              productData.push(stockDetails.cgstAmt.toFixed(2));
              productData.push(stockDetails.sgstPer.toFixed(2));
              productData.push(stockDetails.sgstAmt.toFixed(2));
            } else {
              productData.push(stockDetails.igstPer.toFixed(2));
              productData.push(stockDetails.igstAmt.toFixed(2));
            }
            totalAmt = totalAmt + stockDetails.totalAmt;
            productData.push(stockDetails.totalAmt.toFixed(2));
            data.push(productData);
          }
        });
      });

      if (toBillState.id == dataToSetState.id) {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          {
            content: qtyTot,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          "",
          "",
          "",
          "",
          "",
          {
            content: totalAmt.toFixed(2),
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ]);
      } else {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: "bold" },
          },
          qtyTot,
          "",
          "",
          "",
          totalAmt.toFixed(2),
        ]);
      }
      var fontSize = 8; // Set your desired font size
      var styles = {
        fontSize: fontSize,
        fontColor: "#000",
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font",
      };

      doc.autoTable({
        head: head,
        body: data,
        startY: yAxis + 5,
        columnStyles: align,
        margin: { right: 5, left: 5, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        styles: styles,
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {
          doc.setTextColor(40);
          var pageWidth = doc.internal.pageSize.getWidth();
          if (data.pageNumber > 1) {
            var xPos1 = pageWidth - 45;
            var line1 = 15;
            var yAxisTax = 10;
            doc.setFontSize(10);
            doc.setFont(fontName);
            let taxInvoice = langugae[lang].taxInvoice
              ? langugae[lang].taxInvoice + " : "
              : "";
            doc.text(taxInvoice, 90, 10);
            var lineHeight1 = 5;
            var wrapWidth1 = 130;
            doc.setFontSize(10);

            let billTO = langugae[lang].billTO
              ? langugae[lang].billTO + " : "
              : "";
            doc.text(billTO, leftMargin, line1);
            doc.setFontSize(10);
            let toBill = dataToSet.outLet ? dataToSet.outLet : {};

            var DText = toBill.ownerName ? toBill.ownerName : "";
            var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
            for (var i = 0, length = splitTextDText.length; i < length; i++) {
              // loop thru each line and increase

              if (i == 0) {
                doc.text(splitTextDText[i], leftMargin + 15, line1);
              } else {
                doc.text(splitTextDText[i], leftMargin, line1);
              }
              line1 = lineHeight1 + line1;
            }
            let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

            let text = dataToSet.billNo
              ? dataToSet.billNo + " (" + billDate + ")"
              : "-";
            var textWidth1 =
              (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;

            var xPos1 = pageWidth - textWidth1 - 10;
            doc.setFont(fontName, "normal");
            doc.text(text, xPos1, 15, { maxWidth: 100 });
          }
          indexInfoData = indexInfoData + 1;
          var str = "Page " + indexInfoData;

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          let pb = "Powered By Bizintel DMS";
          const compName = localStorage.getItem("compName");
          var textWidthCN =
            (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textWidth =
            (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the X position to align to the right of the page

          doc.setLineWidth(0.2);
          if (data.pageNumber > 1) {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          } else {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          }
          // Adjust for a margin if needed
          doc.setFontSize(10);
          var xPosCN = (pageWidth - textWidthCN) / 2;
          var xPos = pageWidth - textWidth - 10;
          doc.text(compName, 10, pageHeight - 10);

          doc.setFontSize(10);
          doc.text(pb, xPos, pageHeight - 10);
          doc.text(str, xPosCN, pageHeight - 10);
        },
      });

      let fillingType = langugae[lang].fillingType
        ? langugae[lang].fillingType + " : "
        : "";

      let productHeadWithPack =
        dataToSet.packagingTypes.length != 0 ? [fillingType] : [];
      let productListWithPack = [];
      let productListalign = { 0: { cellWidth: 50 } };
      dataToSet.categories.map((categoryData, index) => {
        productHeadWithPack.push(categoryData.name);
        productListalign = {
          ...productListalign,
          [index + 1]: { halign: "right" },
        };
      });

      dataToSet.packagingTypes.map((packagingTypeData) => {
        let GruopInfo = [packagingTypeData.name];
        dataToSet.categories.map((categoryData) => {
          let flag = 0;
          dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
            if (
              categoryData.id == rowData.category.id &&
              rowData.packagingType.id == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
          }
        });
        productListWithPack.push(GruopInfo);
      });
      var styles1 = {
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font",
      };
      console.log(productListWithPack);
      console.log(productHeadWithPack);
      doc.autoTable({
        head: [productHeadWithPack],
        body: productListWithPack,
        styles: styles1,
        startY: doc.autoTableEndPosY() + 5,
        columnStyles: productListalign,
        margin: { right: 6, left: 6, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {},
      });
      var line = doc.autoTableEndPosY() + 5; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var leftMarginData = 152;
      var wrapWidth = 180;
      let note = langugae[lang].note ? langugae[lang].note + " : " : "";
      doc.text(note, leftMargin, line);
      var DelerText =
        dataToSet.orderRemarks != null ? dataToSet.orderRemarks : "";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin + 10, line);
        line = lineHeight + line;
      }
      let orderBy = langugae[lang].orderBy
        ? langugae[lang].orderBy + " : "
        : "";
      doc.line(3, line, pageWidth - 3, line);
      let lineData = line;
      var line = line + 5;
      let salesTeamNames = dataToSet.salesTeamNames
        ? dataToSet.salesTeamNames
        : "";
      doc.text("1." + orderBy + salesTeamNames, leftMargin, line);
      let lineOrder = line;
      var wrapWidth = 50;
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMarginData, lineOrder);
        lineOrder = lineHeight + lineOrder;
      }
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      let subjectToJurisdiction = langugae[lang].subjectToJurisdiction
        ? langugae[lang].subjectToJurisdiction + " : "
        : "";
      let distName = localStorage.getItem("districtName");
      let aurthorisedSign = langugae[lang].aurthorisedSign
        ? langugae[lang].aurthorisedSign + " : "
        : "";

      let goodsCheckBeforeReceived = langugae[lang].goodsCheckBeforeReceived
        ? langugae[lang].goodsCheckBeforeReceived + " : "
        : "";

      doc.text("2." + goodsCheckBeforeReceived, leftMargin, line);
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      doc.text("3. " + subjectToJurisdiction + distName, leftMargin, line);

      doc.text(aurthorisedSign, leftMarginData, line);
      var line = line + 5;
      doc.line(90, lineData, 90, line);
      doc.line(150, lineData, 150, line);
      doc.line(3, line, pageWidth - 3, line);
      if (indexMain + 1 != rowList.length) {
        doc.addPage();
      }
    });

    let bill = langugae[lang].bill ? langugae[lang].bill + " : " : "";
    doc.save(bill + moment().format("DD-MM-YYYY") + ".pdf");
  };

  const onLoadSheet = () => {
    let dateInfo = {
      fromdate: dayjs().format("DD-MM-YYYY"),
      todate: dayjs().format("DD-MM-YYYY"),
    };

    localStorage.setItem("loadSheetID", pdfData.id);
    localStorage.setItem("dateForPDF", JSON.stringify(dateInfo));
    localStorage.setItem("lang", lang);

    window.open("./loadsheet-pdf", "_blank");
  };

  const handleClose = () => {
    // handleCloses();
  };

  const onBack = () => {
    window.location.replace("/manual-order");
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={openPopUp}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onBack}
          >
            PDF
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <br />

            <Grid item sm={12} xs={12} md={12} lg={12} container>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label={"*"}
                />
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"Select Language" + " :"}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <AutoComplete
                  name={"Language"}
                  label={"Language"}
                  placeHolder={"Select Language"}
                  keyColName={"id"}
                  value={lang}
                  options={multilingualList.language}
                  onChange={onAutocompleteChange}
                  errorText={""}
                />
              </Grid>
            </Grid>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-between"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Payment Summary"
                onClick={onPaymentSummary}
              />
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Bill PDF"
                onClick={onPDFBill}
              />
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Bill Dispatch Summary"}
                onClick={onLoadSheet}
              />
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name={"Back"}
                onClick={onBack}
                fullWidth={false}
              />
            </Grid>

            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  multilingualList: state.multilingual,
});

const mapDispatchToProps = {
  getActiveLanguage,
  showLoador,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopUpForPdfAfterSubmit);
