import Paper from "@mui/material/Paper";
import React from "react";
import { connect } from "react-redux";
import { OrderListJson } from "../../DynamicFormsJson/Transaction/orderList";
import { OrderListFooterJsons } from "../../DynamicFormsJson/Transaction/orderListFooter";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as myConstClass from "../../config/messageconstant";

import PropTypes from "prop-types";
import { getBeatByUserType } from "../../Slice/beat.slice";
import { getOutletByBeatIdsPost } from "../../Slice/outlet.slice";
import {
  getOutletOrderDetailUpdated,
  getOutletOrderDetailBySalesNew,
  setOutletOrderDetail,
  getTodaysOutletOrderDetails,
} from "../../Slice/outletOrderDetail.slice";
import { getRateEditOrNot } from "../../Slice/rateConfigurationEditingForStockholder.slice";
import {
  getSalesExecutiveByBeatIds,
  getSalesExecutiveByLoginAreas,
} from "../../Slice/salesExecutive.slice";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import RTTable from "../../components/Comman/RT/RTTable";
import dayjs from "dayjs";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BillingWithOffer from "./BillingWithOffer";
import CheckBoxSelectionTable from "./CheckBoxSelectionTable";
import { ButtonCompo } from "../../components/Comman/Button";
import { Grid } from "@mui/material";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import { labelColor, redColor } from "../../config/ColorObj";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
      },
      showCheckRecord: false,
      billwithOffer: false,
      outletIds: [],
      selection: [],
      billDate: dayjs().format("DD-MM-YYYY"),
      billDiscountPercentage: 0,
      dataTotal: 0,
      discountAmt: 0,
      totalAmt: 0,
      offerBillData: [],
      productStockData: {},
      flag: true,
      stockListForValidation: [],
      searchValue: "",
      searchBy: "0",
      searhFlag: 1,
      formData: {},
      fieldData: { flag: "0" },
      openProductList: false,
      isLostOrder: false,
      isLostOrderFlag: false,
      stockProductData: [],
    };
  }
  getData = async () => {
    const {
      getTodaysOutletOrderDetails,
      getBeatByUserType,
      getRateEditOrNot,
      showLoador,
      showNotification,
      getSalesExecutiveByLoginAreas,
      setOutletOrderDetail,
    } = this.props;
    const { fieldData, flag } = this.state;
    let rowData = [];
    if (navigator.onLine) {
      setOutletOrderDetail({ row: [] });

      const userTypeId = localStorage.getItem("userTypeId");
      showLoador({ loador: true });
      await getBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getSalesExecutiveByLoginAreas().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getRateEditOrNot().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      const stockCalAtBill = localStorage.getItem("stockCalAtBill");

      this.setState({
        flag: stockCalAtBill === "1" ? true : false,
      });


      const showFreeQtyPercetage = localStorage.getItem("showFreeQtyPercetage");

      let newObject = {
        ...fieldData,
        flag: showFreeQtyPercetage === "1" ? true : false,
      };
      this.setState({
        fieldData: newObject,
      });



      const lostOrderFromBilling = localStorage.getItem("lostOrderFromBilling");

      this.setState({
        isLostOrder: lostOrderFromBilling === "1" ? true : false,
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      await this.getData();
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {
        let formData = {
          beat:
            nextProps.beatList?.beatByUserType.length != 0
              ? [nextProps.beatList?.beatByUserType[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });

        // if (this.state.flag == 1) {
        //   this.setState({
        //     flag: 2,
        //     formData: formData,
        //   });
        //   if (formData.beat.length != 0) {
        //     this.onSearchList({
        //       ...formData,
        //       flag: 0,
        //       todate: dayjs().format("DD-MM-YYYY"),
        //       fromdate: dayjs().format("DD-MM-YYYY"),
        //     });
        //   }
        // }
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        let formData = {
          outletIds:
            nextProps.outletList?.outlets.length != 0
              ? [nextProps.outletList?.outlets[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
        // if (this.state.searhFlag == 1) {
        //   this.setState({
        //     searhFlag: 2,
        //     formData: formData,
        //   });
        //   if (formData.outletIds.length != 0) {
        //     this.onSearchList({
        //       ...formData,
        //     });
        //   }
        // }
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }
  getListById = async (data) => {
    const {
      getOutletByBeatIdsPost,
      getSalesExecutiveByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          beat: [],
          outletIds: [],
          salesPersonIds: [],
        },
      });
    }
    this.setState({
      mainData: { ...this.state.mainData, ...data },
    });
    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        if (this.state.searchBy == 0) {
          let beatIdsJson = {
            beatIds: data.beat,
          };
          showLoador({ loador: true });
          await getOutletByBeatIdsPost({ beatIds: beatIdsJson }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        } else {
          showLoador({ loador: true });
          await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSearchList = (data) => {
    this.setState({
      fieldData: data,
    });
    const { showNotification } = this.props;
    if (navigator.onLine) {
      if (data.flag == 0) {
        this.getOrderList(data.outletIds, data.fromdate, data.todate);
      } else {
        this.getOrderListBySalesPerson(
          data.salesPersonIds,
          data.fromdate,
          data.todate
        );
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getOrderListBySalesPerson = (salesPersonIds, fromDate, toDate) => {
    if (navigator.onLine) {
      const {
        getOutletOrderDetailBySalesNew,
        showLoador,
        showNotification,
        setOutletOrderDetail,
      } = this.props;
      const { flag } = this.state;
      let rowData = [];
      let dataToPost = {
        salesTeamIds: salesPersonIds,
        fromDate: fromDate,
        toDate: toDate,
      };
      showLoador({ loador: true });
      getOutletOrderDetailBySalesNew({ dataToPost }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (flag) {
              showLoador({ loador: true });
              apiGet({
                url: endpoint.manageStockHeaderLatest + "/stock-by-current-date",
              }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                  let i;
                  let j;
                  let rsLength = response.length;
                  let stockLength = data.data.productStocks.length;
                  for (i = 0; i < rsLength; i++) {
                    if (stockLength != 0) {
                      for (j = 0; j < stockLength; j++) {
                        if (
                          response[i].product ==
                          data.data.productStocks[j].product.id
                        ) {
                          if (
                            data.data.productStocks[j].secondaryClosingQty <= 0
                          ) {
                            let orderObject = {
                              ...response[i],
                              secondaryClosingQty: 0,
                              disabled: true,
                              checked: false,
                              orderQty: 0,
                            };
                            rowData.push(orderObject);
                            break;
                          } else if (
                            data.data.productStocks[j].secondaryClosingQty <=
                            response[i].orderQty
                          ) {
                            let orderObject = {
                              ...response[i],
                              productStock: 0,
                              secondaryClosingQty: 0,
                              disabled: true,
                              checked: true,
                              orderQty:
                                data.data.productStocks[j].secondaryClosingQty,
                            };
                            rowData.push(orderObject);
                            data.data.productStocks[j].secondaryClosingQty = 0;
                            break;
                          } else {
                            let orderObject = {
                              ...response[i],
                              productStock:
                                data.data.productStocks[j].secondaryClosingQty -
                                response[i].orderQty,
                              secondaryClosingQty:
                                data.data.productStocks[j].secondaryClosingQty,
                              disabled: true,
                              checked: true,
                              orderQty: response[i].orderQty,
                            };
                            data.data.productStocks[j].secondaryClosingQty =
                              data.data.productStocks[j].secondaryClosingQty -
                              response[i].orderQty;
                            rowData.push(orderObject);
                            break;
                          }
                        }
                      }
                    } else {
                      let orderObject = {
                        ...response[i],
                        secondaryClosingQty: 0,
                        disabled: true,
                        checked: false,
                        orderQty: 0,
                      };
                      rowData.push(orderObject);
                    }
                  }
                  this.setState({ productStockData: data.data });
                  setOutletOrderDetail({ row: rowData });
                } else {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              });
            } else {
              setOutletOrderDetail({ row: response });
            }
            // this.setState({ outletIds: outletIds });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getOrderList = (outletIds, fromDate, toDate) => {
    if (navigator.onLine) {
      const {
        getOutletOrderDetailUpdated,
        showLoador,
        showNotification,
        setOutletOrderDetail,
      } = this.props;
      const { flag } = this.state;
      let rowData = [];
      showLoador({ loador: true });
      getOutletOrderDetailUpdated({
        outletIds: outletIds,
        fromDate: fromDate,
        toDate: toDate,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (flag) {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.manageStockHeaderLatest + "/stock-by-current-date",
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                let i;
                let j;
                let rsLength = response.length;
                let stockLength = data.data.productStocks.length;
                for (i = 0; i < rsLength; i++) {
                  if (stockLength != 0) {
                    for (j = 0; j < stockLength; j++) {
                      if (
                        response[i].product ==
                        data.data.productStocks[j].product.id
                      ) {
                        if (
                          data.data.productStocks[j].secondaryClosingQty <= 0
                        ) {
                          let orderObject = {
                            ...response[i],
                            secondaryClosingQty: 0,
                            disabled: true,
                            checked: false,
                            orderQty: 0,
                          };
                          rowData.push(orderObject);
                          break;
                        } else if (
                          data.data.productStocks[j].secondaryClosingQty <=
                          response[i].orderQty
                        ) {
                          let orderObject = {
                            ...response[i],
                            productStock: 0,
                            secondaryClosingQty: 0,
                            disabled: true,
                            checked: true,
                            orderQty:
                              data.data.productStocks[j].secondaryClosingQty,
                          };
                          rowData.push(orderObject);
                          data.data.productStocks[j].secondaryClosingQty = 0;
                          break;
                        } else {
                          let orderObject = {
                            ...response[i],
                            productStock:
                              data.data.productStocks[j].secondaryClosingQty -
                              response[i].orderQty,
                            secondaryClosingQty:
                              data.data.productStocks[j].secondaryClosingQty,
                            disabled: true,
                            checked: true,
                            orderQty: response[i].orderQty,
                          };
                          data.data.productStocks[j].secondaryClosingQty =
                            data.data.productStocks[j].secondaryClosingQty -
                            response[i].orderQty;
                          rowData.push(orderObject);
                          break;
                        }
                      }
                    }
                  } else {
                    let orderObject = {
                      ...response[i],
                      secondaryClosingQty: 0,
                      disabled: true,
                      checked: false,
                      orderQty: 0,
                    };
                    rowData.push(orderObject);
                  }
                }
                this.setState({ productStockData: data.data });
                setOutletOrderDetail({ row: rowData });
              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          } else {
            setOutletOrderDetail({ row: response });
          }
          // this.setState({ outletIds: outletIds });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  handleCloses = () => {
    const { showCheckRecord } = this.state;
    this.setState({
      showCheckRecord: !showCheckRecord,
    });
  };
  checkListValidation = () => {
    const { outletOrderDetail, showNotification } = this.props;
    const { fieldData, openProductList, isLostOrder } = this.state;
    const filterCheck = outletOrderDetail.outletOrderDetail.filter(
      (row) => row.checked
    );
    if (filterCheck.length != 0) {
      //commented and changes by akash

      // let error = false;
      // const rowList = outletOrderDetail.outletOrderDetail.map((rowObj) => {
      //   if (rowObj.checked) {
      //     if (
      //       (+rowObj.distcountAmt >= 100 && rowObj.distcountAmt != 100) ||
      //       rowObj.uomConversionRate.toString() == ""
      //     ) {
      //       error = true;
      //     }
      //     if (rowObj.productStock > 0) {
      //       if (
      //         (rowObj.orderQty.toString() != "" &&
      //           (rowObj.orderQty.toString() == "0" ||
      //             !onlyNumber.test(rowObj.orderQty.toString()))) ||
      //         (rowObj.uomConversionRate.toString() != "" &&
      //           (rowObj.uomConversionRate.toString() == "0" ||
      //             !onlyNumber.test(rowObj.uomConversionRate.toString())))
      //       ) {
      //         error = true;
      //       }
      //     }

      //     rowObj = {
      //       ...rowObj,
      //       qtyError:
      //         rowObj.orderQty.toString() == ""
      //           ? 1
      //           : rowObj.orderQty.toString() != "" &&
      //             !onlyNumber.test(rowObj.orderQty.toString())
      //           ? 3
      //           : 0,

      //       freeQtyError:
      //         fieldData.flag == 1 && rowObj.freeQty.toString() == "" ? 1 : 0,
      //       discountError:
      //         fieldData.flag == 1 && rowObj.distcountAmt.toString() == ""
      //           ? 1
      //           : fieldData.flag == 1 &&
      //             rowObj.distcountAmt != 100 &&
      //             +rowObj.distcountAmt >= 100
      //           ? 2
      //           : 0,
      //       conversionValueErr:
      //         rowObj.uomConversionRate.toString() == ""
      //           ? 1
      //           : rowObj.uomConversionRate.toString() != "" &&
      //             rowObj.uomConversionRate.toString() == "0"
      //           ? 2
      //           : rowObj.uomConversionRate.toString() != "" &&
      //             !onlyNumber.test(rowObj.uomConversionRate.toString())
      //           ? 3
      //           : 0,
      //     };
      //   }

      //   return rowObj;
      // });
      // this.props.setOutletOrderDetail({ row: rowList });

      if (!isLostOrder) {
        this.saveBillConfigtation();
      } else if (isLostOrder) {
        this.checkProductList();
        this.setState({
          isLostOrderFlag: true,
          openProductList: !openProductList,
        });
      }
    } else {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    }
  };
  saveBillConfigtation = () => {
    const { showNotification, showLoador } = this.props;
    const {
      openProductList,
      flag,
      productStockData,
      billDate,
      billDiscountPercentage,
      stockProductData,
      isLostOrder,
    } = this.state;

    this.setState({
      openProductList: isLostOrder ? !openProductList : openProductList,
      showCheckRecord: false,
    });
    let billingCheckedList = [];
    let stockList = [];
    let dataToSave = {};
    if (flag) {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked && +row.orderQty > 0
        );
      stockList = productStockData.productStocks.map((stockObject) => {
        let dataToReturn = {
          productId: stockObject.product.id,
          stock: stockObject.secondaryClosingQty,
        };
        return dataToReturn;
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    } else {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked
        );
    }
    let billingDataToSave = billingCheckedList.map((billingObject) => {
      console.log(billingObject);
      let billData = {
        outletOrderDetail: {
          id: billingObject.id,
        },
        outLet: {
          id: billingObject.outlet,
        },
        product: {
          id: billingObject.product,
        },
        rate: +billingObject.rate,
        qty: +billingObject.orderQty,
        freeQty: +billingObject.freeQty,
        itemDiscPer: +billingObject.distcountAmt,
        conversionValue: +billingObject.uomConversionRate,
      };
      return billData;
    });

    dataToSave = {
      dtoOutletBillDetails: billingDataToSave,
      productAndStocks: stockList,
    };

    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: myConstClass.closedOrderWarning,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.outletBillHeader +
            "/generate-with-applied-offer?billDate=" +
            billDate +
            "&billDiscountPercentage=" +
            billDiscountPercentage,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (Object.keys(data).length !== 0 && data.data.length !== 0) {
              this.handleCloses();
              let newObjectToSave = data.data.map((billObject) => {
                let newBillObject = {
                  ...billObject.outletBillsAfterApplyingOffers,
                  outletBillDetails:
                    billObject.outletBillsAfterApplyingOffers.outletBillDetails.map(
                      (orderObject) => {
                        let productWithoutBillObject =
                          billObject.outletBillHeader.outletBillDetails.filter(
                            (row) => row.id == orderObject.id
                          );
                        if (productWithoutBillObject.length !== 0) {
                          orderObject = {
                            ...orderObject,
                            taxableAmtWithoutOffer:
                              productWithoutBillObject[0].taxableAmt,
                            totalAmtWithoutOffer:
                              productWithoutBillObject[0].totalAmt,
                          };
                          return orderObject;
                        } else {
                          orderObject = {
                            ...orderObject,
                            taxableAmtWithoutOffer: 0.0,
                            totalAmtWithoutOffer: 0.0,
                          };
                          return orderObject;
                        }
                      }
                    ),
                };
                billObject = {
                  ...billObject,
                  outletBillsAfterApplyingOffers: newBillObject,
                };
                return billObject;
              });

              this.setState({
                billwithOffer: true,
                offerBillData: newObjectToSave,
              });
            }
          } else {
            showNotification({
              msg: saveFailedMsg,
              severity: "error",
            });
          }
        });
      }
    });
  };
  onCancel = () => {
    this.setState({
      billwithOffer: false,
      showCheckRecord: false,
      offerBillData: [],
    });
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.outletOrderDetail.outletOrderDetail.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return {
          ...rowData,
          checked: filterData.length != 0 ? true : false,
        };
      }
    );
    this.props.setOutletOrderDetail({ row: rowList });
  };

  outletOrderDetails = ({ row }) => {
    const { setOutletOrderDetail } = this.props;
    setOutletOrderDetail({ row: row });
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName = currentRow.productName;
      let firmName = currentRow.firmName;
      let beatName = currentRow.beatName;
      let orderNo = currentRow.orderNo;
      let orderDate = currentRow.orderDate;
      let outletUom = currentRow.outletUom;
      let rate = currentRow.rate;
      if (
        productName &&
        productName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        firmName &&
        firmName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        beatName &&
        beatName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        orderNo &&
        orderNo.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        orderDate &&
        orderDate.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        outletUom &&
        outletUom.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        rate &&
        rate.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  checkProductList = () => {
    const { outletOrderDetail } = this.props;
    let row = [];
    outletOrderDetail.outletOrderDetail.map((orderData) => {
      if (!orderData.checked || orderData.billQty > orderData.orderQty) {
        let rowData = {
          index: row.length + 1,
          id: orderData.id,
          firmName: orderData.firmName,
          beatName: orderData.beatName,
          orderNo: orderData.orderNo,
          orderDate: orderData.orderDate,
          productName: orderData.productName,
          outletUom: orderData.outletUom,
          billQty: orderData.billQty - orderData.orderQty,
        };
        row.push(rowData);
      }
    });
    this.openProductListHandler(row);
  };

  openProductListHandler = (row) => {
    const { openProductList } = this.state;
    this.setState({
      isLostOrderFlag: false,
      openProductList: !openProductList,
      stockProductData: row,
    });
  };

  setList = () => {
    this.props.setOutletOrderDetail({ row: [] });
  };
  render() {
    const {
      dynamicMasterData,
      selection,
      billwithOffer,
      offerBillData,
      billDate,
      flag,
      formData,
      searchBy,
      fieldData,
      openProductList,
      stockProductData,
      isLostOrder,
      isLostOrderFlag,
    } = this.state;
    const { outletOrderDetail, stockHolderAndProductConfiguration } =
      this.props;
    return (
      <>
        {!billwithOffer && (
          <>
            <Paper
              sx={{
                p: 2,
                borderRadius: 2,
              }}
              elevation={0}
            >
              <DynamicMainScreen
                formData={formData}
                callApi={false}
                showPdfDownload={false}
                showExcelDownload={false}
                showAddButton={false}
                getListById={this.getListById}
                dynamicMasterData={dynamicMasterData}
                showTitle={OrderListJson.showTitle}
                screenTitle={OrderListJson.screenTitle}
                searchList={
                  searchBy == 0
                    ? OrderListJson.fieldMeta
                    : OrderListJson.fieldMetaBySalsPerson
                }
                searchTrue={false}
                onFilterData={this.onSearch}
                apiBaseURL={OrderListJson.apiBaseURL}
                showSaveNextBtn={false}
                showSaveBtnMain={false}
                showSaveBtn={true}
                saveBtnText={"Search"}
                onSearchData={this.onSearchList}
                padding={true}
                paddingTop={true}
                resetAfterSave={false}
                getTableData={this.getData}
              />

              <CheckBoxSelectionTable
                stockHolderAndProductConfiguration={
                  stockHolderAndProductConfiguration
                }
                selection={selection}
                onSelectionChange={this.getSelectedList}
                freeQtyFlag={fieldData.flag}
                columns={
                  flag && fieldData.flag == 1
                    ? [
                      {
                        title: "Sr.No.",
                        name: "index",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "center",
                      },
                      {
                        title: "Outlet Name",
                        name: "firmName",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "left",
                      },

                      {
                        title: "Beat Name",
                        name: "beatName",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "Order No",
                        name: "orderNo",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      {
                        title: "Order Date",
                        name: "orderDate",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                      },
                      {
                        title: "Product Name",
                        name: "productName",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "UOM",
                        name: "outletUom",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                      },
                      {
                        title: "Rate",
                        name: "rate",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      {
                        title: "Existing Stock",
                        name: "secondaryClosingQty",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      {
                        title: "Remaining Stock",
                        name: "productStock",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      {
                        title: "Order Quantity",
                        name: "billQty",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      {
                        title: "Bill Quantity",
                        name: "orderQty",
                        placeHolder: "Enter Bill Quantity",
                        textFieldError: "percentageErr",
                        error: "Percentage",
                        textField: true,
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                      // {
                      //   title: "Free Quantity",
                      //   name: "freeQty",
                      //   placeHolder: "Enter Free Quantity",
                      //   textFieldError: "percentageErr",
                      //   error: "Percentage",
                      //   textField: true,
                      //   positionCenter: true,
                      //   showInscreen: true,
                      //   alignCenter: "right",
                      // },
                      // {
                      //   title: "Discount Percentage",
                      //   name: "distcountAmt",
                      //   placeHolder: "Enter Discount Percentage",
                      //   textFieldError: "discountError",
                      //   error: "Discount Percentage",
                      //   textField: true,
                      //   positionCenter: false,
                      //   showInscreen: true,
                      //   alignCenter: "right",
                      // },
                      // {
                      //   title: "UOM Conversion Value",
                      //   name: "uomConversionRate",
                      //   placeHolder: "Enter UOM Conversion Value",
                      //   textFieldError: "conversionValueErr",
                      //   error: "ConversionValue",
                      //   textField: true,
                      //   positionCenter: true,
                      //   showInscreen: true,
                      //   alignCenter: "right",
                      // },
                      {
                        title: "Total",
                        name: "total",
                        positionCenter: true,
                        showInscreen: true,
                        alignCenter: "right",
                      },
                    ]
                    : flag && fieldData.flag != 1
                      ? [
                        {
                          title: "Sr.No.",
                          name: "index",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Outlet Name",
                          name: "firmName",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Beat Name",
                          name: "beatName",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Order No",
                          name: "orderNo",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Order Date",
                          name: "orderDate",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product Name",
                          name: "productName",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "outletUom",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Rate",
                          name: "rate",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Existing Stock",
                          name: "secondaryClosingQty",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Remaining Stock",
                          name: "productStock",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Order Quantity",
                          name: "billQty",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Bill Quantity",
                          name: "orderQty",
                          placeHolder: "Enter Bill Quantity",
                          textFieldError: "percentageErr",
                          error: "Percentage",
                          textField: true,
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        // {
                        // title: "Free Quantity",
                        // name: "freeQty",
                        // placeHolder: "Enter Free Quantity",
                        // textFieldError: "percentageErr",
                        // error: "Percentage",
                        // textField: true,
                        // positionCenter: true,
                        // showInscreen: true,
                        // alignCenter: "right",
                        // },
                        // {
                        // title: "Discount Percentage",
                        // name: "distcountAmt",
                        // placeHolder: "Enter Discount Percentage",
                        // textFieldError: "discountError",
                        // error: "Discount Percentage",
                        // textField: true,
                        // positionCenter: false,
                        // showInscreen: true,
                        // alignCenter: "right",
                        // },
                        // {
                        //   title: "UOM Conversion Value",
                        //   name: "uomConversionRate",
                        //   placeHolder: "Enter UOM Conversion Value",
                        //   textFieldError: "conversionValueErr",
                        //   error: "ConversionValue",
                        //   textField: true,
                        //   positionCenter: true,
                        //   showInscreen: true,
                        //   alignCenter: "right",
                        // },
                        {
                          title: "Total",
                          name: "total",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                      ]
                      : !flag && fieldData.flag == 1
                        ? [
                          {
                            title: "Sr.No.",
                            name: "index",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Outlet Name",
                            name: "firmName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "Beat Name",
                            name: "beatName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "Order No",
                            name: "orderNo",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          {
                            title: "Order Date",
                            name: "orderDate",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Product Name",
                            name: "productName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "UOM",
                            name: "outletUom",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Rate",
                            name: "rate",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },

                          {
                            title: "Order Quantity",
                            name: "billQty",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          {
                            title: "Bill Quantity",
                            name: "orderQty",
                            placeHolder: "Enter Bill Quantity",
                            textFieldError: "percentageErr",
                            error: "Percentage",
                            textField: true,
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          // {
                          //   title: "Free Quantity",
                          //   name: "freeQty",
                          //   placeHolder: "Enter Free Quantity",
                          //   textFieldError: "percentageErr",
                          //   error: "Percentage",
                          //   textField: true,
                          //   positionCenter: true,
                          //   showInscreen: true,
                          //   alignCenter: "right",
                          // },
                          // {
                          //   title: "Discount Percentage",
                          //   name: "distcountAmt",
                          //   placeHolder: "Enter Discount Percentage",
                          //   textFieldError: "discountError",
                          //   error: "Discount Percentage",
                          //   textField: true,
                          //   positionCenter: false,
                          //   showInscreen: true,
                          //   alignCenter: "right",
                          // },
                          // {
                          //   title: "UOM Conversion Value",
                          //   name: "uomConversionRate",
                          //   placeHolder: "Enter UOM Conversion Value",
                          //   textFieldError: "conversionValueErr",
                          //   error: "ConversionValue",
                          //   textField: true,
                          //   positionCenter: true,
                          //   showInscreen: true,
                          //   alignCenter: "right",
                          // },
                          {
                            title: "Total",
                            name: "total",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                        ]
                        : [
                          {
                            title: "Sr.No.",
                            name: "index",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Outlet Name",
                            name: "firmName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "Beat Name",
                            name: "beatName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "Order No",
                            name: "orderNo",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          {
                            title: "Order Date",
                            name: "orderDate",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Product Name",
                            name: "productName",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "left",
                          },
                          {
                            title: "UOM",
                            name: "outletUom",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                          },
                          {
                            title: "Rate",
                            name: "rate",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },

                          {
                            title: "Order Quantity",
                            name: "billQty",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          {
                            title: "Bill Quantity",
                            name: "orderQty",
                            placeHolder: "Enter Bill Quantity",
                            textFieldError: "percentageErr",
                            error: "Percentage",
                            textField: true,
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                          // {
                          // title: "Free Quantity",
                          // name: "freeQty",
                          // placeHolder: "Enter Free Quantity",
                          // textFieldError: "percentageErr",
                          // error: "Percentage",
                          // textField: true,
                          // positionCenter: true,
                          // showInscreen: true,
                          // alignCenter: "right",
                          // },
                          // {
                          // title: "Discount Percentage",
                          // name: "distcountAmt",
                          // placeHolder: "Enter Discount Percentage",
                          // textFieldError: "discountError",
                          // error: "Discount Percentage",
                          // textField: true,
                          // positionCenter: false,
                          // showInscreen: true,
                          // alignCenter: "right",
                          // },
                          // {
                          //   title: "UOM Conversion Value",
                          //   name: "uomConversionRate",
                          //   placeHolder: "Enter UOM Conversion Value",
                          //   textFieldError: "conversionValueErr",
                          //   error: "ConversionValue",
                          //   textField: true,
                          //   positionCenter: true,
                          //   showInscreen: true,
                          //   alignCenter: "right",
                          // },
                          {
                            title: "Total",
                            name: "total",
                            positionCenter: true,
                            showInscreen: true,
                            alignCenter: "right",
                          },
                        ]
                }
                rows={this.getFilteredTableData(
                  outletOrderDetail.outletOrderDetail
                )}
                setList={this.outletOrderDetails}
                isActionColActive={false}
                flag={flag}
                newRowData={outletOrderDetail.outletOrderDetail}
              />
              {outletOrderDetail.outletOrderDetail.length != 0 && (
                <>
                  <br />
                  <DynamicFormWithoutSave
                    showBackToList={false}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtn={OrderListFooterJsons.showSaveBtn}
                    showTitle={OrderListFooterJsons.showTitle}
                    screenTitle={OrderListFooterJsons.screenTitle}
                    fieldMeta={OrderListFooterJsons.fieldMeta}
                    showCancel={OrderListFooterJsons.showCancel}
                    apiBaseURL={OrderListFooterJsons.apiBaseURL}
                    showSaveNextBtn={OrderListFooterJsons.showSaveNextBtn}
                    saveBtnText={"Proceed"}
                    onSave={this.checkListValidation}
                  />
                </>
              )}
              {outletOrderDetail.outletOrderDetail.length != 0 && flag && (
                <>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="right"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Negative Product List"
                      onClick={this.checkProductList}
                    />
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
        {billwithOffer && (
          <>
            <BillingWithOffer
              offerBillData={offerBillData}
              onCancel={this.onCancel}
              billDate={billDate}
              flag={flag}
              redirect={false}
              stockProductData={stockProductData}
            />
          </>
        )}

        {openProductList && outletOrderDetail.outletOrderDetail.length != 0 && (
          <>
            <BootstrapDialog
              onClose={this.openProductListHandler}
              aria-labelledby="customized-dialog-title"
              open={true}
              fullWidth
              maxWidth="500px"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={this.openProductListHandler}
              >
                Product List
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-between"
                >
                  <LandingScreenHeader
                    screenTitle={""}
                    showSearchBox={true}
                    showTable={true}
                    showFilter={false}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    onSearch={this.onSearch}
                  />
                  <RTTable
                    showPegination={false}
                    columns={[
                      {
                        name: "index",
                        title: "Sr. No.",
                        align: "center",
                      },
                      {
                        title: "Outlet Name",
                        name: "firmName",
                        alignCenter: "left",
                      },

                      {
                        title: "Beat Name",
                        name: "beatName",
                        alignCenter: "left",
                      },
                      {
                        title: "Order No",
                        name: "orderNo",
                        align: "center",
                      },
                      {
                        title: "Order Date",
                        name: "orderDate",
                        align: "center",
                      },
                      {
                        name: "productName",
                        title: "Product",
                        align: "left",
                      },
                      {
                        name: "outletUom",
                        title: "UOM",
                        align: "center",
                      },
                      {
                        title: "Order Quantity",
                        name: "billQty",
                        align: "right",
                      },
                    ]}
                    tableData={this.getFilteredTableData(stockProductData)}
                    isActionColActive={false}
                  />
                </Grid>
                {isLostOrder && isLostOrderFlag && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 32, color: redColor }}
                        label={
                          "Note: " + myConstClass.closedOrderWarningInGujrati
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 32, color: redColor }}
                        label={
                          "Note: " + myConstClass.closedOrderWarningInHindi
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 32, color: redColor }}
                        label={"Note: " + myConstClass.closedOrderWarning}
                      />
                    </Grid>

                    <br />
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="right"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Lost and Proceed"
                        onClick={this.saveBillConfigtation}
                      />
                    </Grid>
                  </>
                )}
              </BootstrapDialogTitle>
            </BootstrapDialog>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  stockHolderAndProductConfiguration: state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByUserType,
  getOutletByBeatIdsPost,
  getSalesExecutiveByBeatIds,
  setOutletOrderDetail,
  getOutletOrderDetailUpdated,
  getRateEditOrNot,
  getOutletOrderDetailBySalesNew,
  getTodaysOutletOrderDetails,
  getSalesExecutiveByLoginAreas,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
