import endpoint from "../../config/endpoints";

export const AddDistributorJson = {
    formPath: "/add-distributor",
    apiBaseURL: endpoint.accountLedger,
    screenTitle: "Add Distributor",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,

    fieldMetaForBasicInfo: [


        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "currentRegionId",
            getListId: "currentRegionId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "currentStateId",
            getListFrom: "currentRegionId",
            getListId: "currentStateId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "currentZoneIds",
            getListFrom: "currentStateId",
            getListId: "currentZoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "currentDistrictIds",
            getListFrom: "currentZoneIds",
            getListId: "currentDistrictIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "currentTalukaIds",
            getListFrom: "currentDistrictIds",
            getListId: "currentTalukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "currentAreaIds",
            getListFrom: "currentTalukaIds",
            getListId: "currentAreaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessName",
            isMandatory: true,

        },
        {
            label: "Owner Name",
            controlType: "textfield",
            placeHolder: "Owner Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fullName",
            isMandatory: true,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "contactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: true,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "emailId",
            valid: true,
            validName: "email",
            isMandatory: true,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessAddress",
            isMandatory: true,
        },
        {
            label: "City",
            controlType: "textfield",
            placeHolder: "City",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "city",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "udyamAadharNumber",
            isMandatory: true,
            valid:true,
            validName:"validUdyamAadhar"
        },
    ],


    fieldMetaForPartnerDetails: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "partnerCurrentRegionId",
            getListId: "partnerCurrentRegionId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "partnerCurrentStateId",
            getListFrom: "partnerCurrentRegionId",
            getListId: "partnerCurrentStateId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "partnerCurrentZoneIds",
            getListFrom: "partnerCurrentStateId",
            getListId: "partnerCurrentZoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "partnerCurrentDistrictIds",
            getListFrom: "partnerCurrentZoneIds",
            getListId: "partnerCurrentDistrictIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "partnerCurrentTalukaIds",
            getListFrom: "partnerCurrentDistrictIds",
            getListId: "partnerCurrentTalukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "partnerCurrentAreaIds",
            getListFrom: "partnerCurrentTalukaIds",
            getListId: "partnerCurrentAreaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessName",
            isMandatory: true,

        },
        {
            label: "Contact Person Name",
            controlType: "textfield",
            placeHolder: "Contact Person Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerFullName",
            isMandatory: true,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerContactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: true,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerEmailId",
            valid: true,
            validName: "email",
            isMandatory: true,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessAddress",
            isMandatory: true,
        },
        {
            label: "City",
            controlType: "textfield",
            placeHolder: "City",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerCity",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerUdyamAadharNumber",
            isMandatory: true,
            valid:true,
            validName:"validUdyamAadhar"
        },
    ],


    fieldMetaForOther: [
        {
            label: "PAN No.",
            controlType: "textfield",
            placeHolder: "PAN No",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "panNo",
            maxLength: 10,
            valid: true,
            validName: "panNo",
            isMandatory: true,

        },
        {
            label: "GST Number",
            controlType: "textfield",
            placeHolder: "22AAAAA0000A1Z5",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "gstNo",
            valid: true,
            validName: "gstNo",
            error: "GST Number",
            isMandatory: true,
        },
        {
            label: "FSSAI Number",
            controlType: "textfield",
            placeHolder: "FSSAI Number",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fssiFdiNo",
            isMandatory: true,
            maxLength: 14,
        },
        {
            label: "FSSAI Expiry Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fssiFdiNoExpiryDate",
            isMandatory: true,
        },
    ],

};
