import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { WeeklyOffJson } from "../../../DynamicFormsJson/MastersJSON/weeklyOff";
import { getWeeklyOff } from "../../../Slice/weeklyOff.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { withRouter } from "../../../components/withRouter";
import { weeklyOffColumns } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class WeeklyOffList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {},
        };
    }

    render() {
        const { weeklyOffList, getWeeklyOff } = this.props;
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicMainScreen
                    dynamicMasterData={dynamicMasterData}
                    formPath={WeeklyOffJson.formPath}
                    screenTitle={WeeklyOffJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={false}
                    showAddButton={WeeklyOffJson.showAddButton}
                    tableColumnsToFilter={WeeklyOffJson.tableColumnsToFilter}
                    tableColumns={weeklyOffColumns}
                    tableData={weeklyOffList?.weeklyOff}
                    getTableData={getWeeklyOff}
                    getTableDataById={true}
                    apiBaseURL={WeeklyOffJson.apiBaseURL}
                    showDeleteIcon={true}
                    showEditIcon={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    weeklyOffList: state.weeklyOff,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getWeeklyOff,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WeeklyOffList);
