import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import DrawerNav from "../../components/Drawer";
import Header from "../../components/Header";
import config from "../../config/config";
import PublishRateConfigurationOutletForm from "../../Pages/PublishRateConfigurationOutlet/PublishRateConfigurationOutletForm";
import PublishRateConfigurationOutletTable from "../../Pages/PublishRateConfigurationOutlet/PublishRateConfigurationOutletTable";
import PrivateRoute from "../../routes/index";
import AssignRateConfigurationToStakeholderForm from "../AssignRateConfigurationToStakeholder/AssignRateConfigurationToStakeholderForm";
import AssignRateConfigurationToStakeholderTable from "../AssignRateConfigurationToStakeholder/AssignRateConfigurationToStakeholderTable";
import Dashboard from "../Dashboard/index";
import AreaForm from "../Master/Area/AreaForm";
import AreaTable from "../Master/Area/AreaTable";
import AreaMasterList from "../Master/Area Master/AreaMasterList";
import AreaMasterForm from "../Master/Area Master/AreaMasterForm";
import CategoryForm from "../Master/Category/CategoryForm";
import CategoryTable from "../Master/Category/CategoryTable";
import DocumentForm from "../Master/Document/DocumentForm";
import DocumentTable from "../Master/Document/DocumentTable";
import ManagerForm from "../Master/Manager/ManagerForm";
import ManagerTable from "../Master/Manager/ManagerTable";
import OrderConfigurationForm from "../Master/Order Configuration/OrderConfigurationForm";
import OrderConfigurationTable from "../Master/Order Configuration/OrderConfigurationTable";
import ProductForm from "../Master/Product/ProductForm";
import ProductTable from "../Master/Product/ProductTable";
import SingleMrpConfigurationByItemList from "../Master/Product/SingleMrpConfigurationByItemList";
import RegionForm from "../Master/Region/RegionForm";
import RegionTable from "../Master/Region/RegionTable";
import SalesExecutiveForm from "../Master/Sales Executive/SalesExecutiveForm";
import SalesExecutiveTable from "../Master/Sales Executive/SalesExecutiveTable";
import SelectProductDocument from "../Master/SelectProductDocument";
import StackHolderForm from "../Master/StackHolder/StackHolderForm";
import StackHolderTable from "../Master/StackHolder/StackHolderTable";
import StackHolderDocument from "../Master/StackHolder/StakeHolderDocument";
import SubCategoryForm from "../Master/SubCategory/SubCategoryForm";
import SubCategoryTable from "../Master/SubCategory/SubCategoryTable";
import TaxForm from "../Master/Tax/TaxForm";
import TaxTable from "../Master/Tax/TaxTable";
import UomForm from "../Master/Uom/UomForm";
import UomTable from "../Master/Uom/UomTable";
import ZoneForm from "../Master/Zone/ZoneForm";
import ZoneTable from "../Master/Zone/ZoneTable";
import MRPConfiguration from "../MRPConfiguration/index";
import OutletRateStructureFormEdit from "../OutletRateStructure/OutletRateStructureFormEdit";
import OutletRateStructureForm from "../OutletRateStructure/OutletRateStructureForm";
import OutletRateStructureTable from "../OutletRateStructure/OutletRateStructureTable";
import PublishRateConfigurationForm from "../PublishRateConfiguration/PublishRateConfigurationForm";
import PublishRateConfigurationTable from "../PublishRateConfiguration/PublishRateConfigurationTable";
import RateConfigurationForm from "../RateConfiguration/RateConfigurationForm";
import RateConfigrationFormEdit from "../RateConfiguration/RateConfigrationFormEdit";
import DayWiseBeatAssignForm from "../DayWiseBeatAssign/DayWiseBeatAssignForm";
import DayWiseBeatAssignTable from "../DayWiseBeatAssign/DayWiseBeatAssignTable";
import DayWiseBeatAssignView from "../DayWiseBeatAssign/DayWiseBeatAssignView";
import RateConfigurationTable from "../RateConfiguration/RateConfigurationTable";
import UserAccessForm from "../UserAccess/UserAccessForm";
import UserAccessTable from "../UserAccess/UserAccessTable";
import { closeNotification } from "./Landing.slice";
import RangeTable from "../Master/Range/RangeTable";
import RangeForm from "../Master/Range/RangeForm";
import GrievanceCategoryTable from "../Master/GrievanceCategory/GrievanceCategoryTable";
import GrievanceCategoryForm from "../Master/GrievanceCategory/GrievanceCategoryForm";
import OutletTypeForm from "../Master/Outlet Type/OutletTypeForm";
import OutletTypeTable from "../Master/Outlet Type/OutletTypeTable";
import BeatTable from "../Master/Beat/BeatTable";
import BeatForm from "../Master/Beat/BeatForm";
import YearTable from "../Master/Year/YearTable";
import YearForm from "../Master/Year/YearForm";
import OutletForm from "../../Pages/Master/Outlet/OutletForm";
import OutletTable from "../Master/Outlet/OutletTable";
import AddRateConfigurationForm from "../Master/Product/AddRateConfigurationForm";
import AddOutletRateConfigurationForm from "../Master/Product/AddOutletRateConfigurationForm";
import AuditForm from "../Audit/AuditForm";
import TargetTable from "../Target/TargetTable";
import TargetUnfreeze from "../Target/TargetUnfreeze";
import TargetForm from "../Target/TargetForm";
import RegionAndTargetTable from "../Region And Target/RegionAndTargetTable";
import RegionAndTargetForm from "../Region And Target/RegionAndTargetForm";
import RegionAndTargetEdit from "../Region And Target/RegionAndTargetEdit";
import AssignBeatForm from "../AssignBeat/AssignBeatForm";
import MatrixForm from "../Roaster/roaster";
import AuditTable from "../Audit/AuditTable";
import ProjectionForm from "../Projection/ProjectionForm";
import GrievanceTable from "../Grievance/GrievanceTable";
import CompanyAndTargetTable from "../Company Target/CompanyAndTargetTable";
import CompanyAndTargetForm from "../Company Target/CompanyAndTargetForm";
import DistributionReports from "../Reports/Reports";
import ExcelReports from "../Reports/ExcelReports";
import MainDashboard from "../Dashboard/MainDashboard";
import SalesDashboard from "../Dashboard/SalesDashboard";
import InactiveOutlet from "../InactiveOutlet/InactiveOutlet";
import PaymentForm from "../Payment/PaymentForm";
import OrderList from "../OrderList/OrderList";
import OrderHistoryTable from "../Order History/OrderHistoryTable";
import GoogleMap from "../Master/Outlet/GoogleMap";
import PaymentModeDialog from "../Payment/PaymentModeDialog";
import PaymentList from "../Payment/PaymentList";
import ManualOrder from "../Manual Order/ManualOrder";
import OrderClosing from "../OrderClosing/OrderClosing";
import BillingHistoryTable from "../Billing History/BillingHistoryTable";
import SettleBillForm from "../SettleBill/SettleBillForm";
import OrderHistoryEdit from "../Order History/OrderHistoryEdit";
import CreditNoteList from "../Credit Note/CreditNoteList";
import CreditNoteForm from "../Credit Note/CreditNoteForm";
import OrderHistoryView from "../Order History/OrderHistoryView";
import BillingHistoryView from "../Billing History/BillingHistoryView";
import PlacedOrderForm from "../PlacedOrder/PlacedOrderForm";
import ReceivedOrderList from "../ReceivedOrderList/ReceivedOrderList";
import BillingHistoryEdit from "../Billing History/BillingHistoryEdit";
import PlaceOrderTable from "../PlaceOrder/PlaceOrderTable";
import PlaceOrderEdit from "../PlaceOrder/PlaceOrderEdit";
import ShowOrderList from "../Show Order List/ShowOrderList";
import ViewOrderList from "../Show Order List/ViewOrderList";
import ReceivedOrderBillForm from "../ReceivedOrderList/ReceivedOrderBillForm";
import ListOfGeneratedBill from "../List Of Bill Generated/ListOfGeneratedBill";
import ShowMyBillList from "../Show My Bill List/showMyBillList";
import ShowMyBillView from "../Show My Bill List/showMyBillListView";
import RequestGRNList from "../Request GRN/RequestGRNList";
import RequestGRNView from "../Request GRN/RequestGRNView";
import RequestGRNEdit from "../Request GRN/RequestGRNEdit";
import RequestGRNForm from "../Request GRN/RequestGRNForm";
import RequestGRNHeaderForm from "../Request GRN/RequestGRNHeaderForm";
import ListOfGeneratedBillView from "../List Of Bill Generated/ListOfGeneratedBillView";
import ListOfGeneratedBillEdit from "../List Of Bill Generated/ListOfGeneratedBillEdit";
import PlaceOrderView from "../PlaceOrder/PlaceOrderView";
import PendingGrnList from "../Pending GRN List/PendingGrnList";
import HistoryOfcreditNote from "../History of credit note/HistoryOfcreditNote";
import HistoryOfCreditNoteView from "../History of credit note/HistoryOfCreditNoteView";
import CreditNoteTable from "../Credit Note Holder/CreditNoteTable";
import GRNHistoryTable from "../GRN History/GRNHistoryTable";
import GrnHistoryView from "../GRN History/GrnHistoryView";
import PendingGrnView from "../Pending GRN List/PendingGrnView";
import PendingGrnDetails from "../Pending GRN List/PendingGrnDetails";
import StockistSettleBillForm from "../StockistSettleBill/StockistSettleBillForm";
import PendingGrnForCreditNote from "../Pending GRN For Credit Note/PendingGrnForCreditNote";
import StockConsumptionHeader from "../stockConsumptionHeader/stockConsumptionHeader";
import PaymentListForStockist from "../Payment List/PaymentListForStockist";
import PaymentFormForStockist from "../Payment List/PaymentFormForStockist";
import ManageStockTable from "../Manage Stock/ManageStockTable";
import ManageStockView from "../Manage Stock/ManageStockView";
import ManageStockForm from "../Manage Stock/ManageStockForm";
import ManageStockEdit from "../Manage Stock/ManageStockEdit";
import StockBetweenDateTable from "../Stock Between Date/StockBetweenDateTable";
import NoticeMessageForm from "../NoticeMessage/NoticeMessageForm";
import NoticeMessageList from "../NoticeMessage/NoticeMessageList";
import BatchwiseOutletBillingForm from "../Batchwise Outlet Billing/BatchwiseOutletBillingForm";
import BatchwiseStakeholderBillingList from "../Batchwise Stakeholder Billing/BatchwiseStakeholderBillingList";
import BatchwiseManageInstockList from "../Batchwise Manage Instock/BatchwiseManageInstockList";
import BatchwiseManageInstockView from "../Batchwise Manage Instock/BatchwiseManageInstockView";
import BatchwiseManageInstockForm from "../Batchwise Manage Instock/BatchwiseManageInstockForm";
import ProductMultilingualForm from "../Multilingual/productMultilingual";
import AreaMultilingualForm from "../Multilingual/areaMultilingual";
import OutletMultilingualForm from "../Multilingual/outletMultilingual";
import BatchwiseManageInstockEdit from "../Batchwise Manage Instock/BatchwiseManageInstockEdit";
import BatchWiseOutletBillingList from "../Batchwise Outlet Billing/BatchWiseOutletBillingList";
import BatchWiseManageOutstock from "../Batch Wise Manage Outstock/BatchWiseManageOutstock";
import BatchWiseManageOutstockView from "../Batch Wise Manage Outstock/BatchWiseManageOutstockView";
import BatchWiseManageOutstockForm from "../Batch Wise Manage Outstock/BatchWiseManageOutstockForm";
import BatchWiseManageOutstockEdit from "../Batch Wise Manage Outstock/BatchWiseManageOutstockEdit";
import BatchwiseOutletBillingStakeholderForm from "../Batchwise Stakeholder Billing/BatchwiseOutletBillingStakeholderForm";
import PaymentDetailsTable from "../Payment Details/PaymentDetailsTable";
import PaymentDetailsView from "../Payment Details/PaymentDetailsView";
import DashbourdChanges from "../DashbourdChanges/index";
import OutletDashbourd from "../DashbourdChanges/OutletDashbourd";
import StockHolderDashbourd from "../DashbourdChanges/StockHolderDashBourd";
import DashMap from "../Dashboard/GMap";
import CreditNoteView from "../Credit Note Holder/CreditNoteView";
import SalesTeamOrderConfigForm from "../SalesTeamOrderConfig/SalesTeamOrderConfigForm";
import MultipleStock from "../Multiple Stock/MultipleStock";
import CompanyForm from "../Company/CompanyForm";
import RequestBatchwiseGRNList from "../Request Batchwise GRN/RequestBatchwiseGRNList";
import RequestBatchwiseGRNForm from "../Request Batchwise GRN/RequestBatchwiseGRNForm";
import RequestBatchwiseGRNHeaderForm from "../Request Batchwise GRN/RequestBatchwiseGRNHeaderForm";
import RequestBatchwiseGRNEdit from "../Request Batchwise GRN/RequestBatchwiseGRNEdit";
import RequestBatchwiseGRNView from "../Request Batchwise GRN/RequestBatchwiseGRNView";
import Offer from "../Master/Offer/Offer";
import OfferList from "../Master/Offer/OfferList";
import OfferView from "../Master/Offer/OfferView";
import BillingWithOffer from "../OrderList/BillingWithOffer";

import NoticeMessageView from "../NoticeMessage/NoticeMessageView";
import PdfComponent from "../../components/PDFGenerateFuction";
// import ExportToExcel from "../../components/ExportToExcel";
import ErrorPage from "../ErrorPage/index";
import ErrorBoundary from "../ErrorPage/ErrorBoundary";
import DynamicReport from "../Reports/DynamicReport";
import DynamicReportWithQuery from "../Reports/DynamicReportWithQuery";
import ReportGenerator from "../ReportGenerator/index";
import ReportTable from "../ReportGenerator/ReportTable";
import ReportGeneratorWithQuery from "../ReportGeneratorWithQuery/index";
import ReportTableWithQuery from "../ReportGeneratorWithQuery/ReportTable";
import MatrixDynamicReport from "../Reports/MatrixDynamicReport";
import MatricReportGenerator from "../MatricReportGenerator/index";
import ReportTableMatrix from "../MatricReportGenerator/ReportTable";
import TypeForm from "../../Pages/Master/Type/TypeForm";
import TypeTable from "../../Pages/Master/Type/TypeTable";
import CompanyCategoryConfigurationForm from "../Company Category Configuration/CompanyCategoryConfigurationForm";
import CompanyCategoryConfigurationTable from "../Company Category Configuration/CompanyCategoryConfigurationTable";
import CompanyCategoryConfigurationView from "../Company Category Configuration/CompanyCategoryConfigurationView";
import NewAdminDashboard from "../Dashboard/NewAdminDashboard";
import DistributorDashboard from "../Dashboard/DistributorDashboard";
import RMDashboard from "../Dashboard/RMDashboard";
import StockHolderDashboard from "../Dashboard/StockHolderDashboard";
import RateConfigurationView from "../RateConfiguration/RateConfigurationView";
import FunctionalDashboardDistributor from "../Functional Dashboard/index";
import PublishRateConfigurationOutletView from "../PublishRateConfigurationOutlet/PublishRateConfigurationOutletView";
import AssignRateConfigurationToStakeholderView from "../AssignRateConfigurationToStakeholder/AssignRateConfigurationToStakeholderView";
import PublishRateConfigurationView from "../PublishRateConfiguration/PublishRateConfigurationView";
import FunctionalFinalDashboardDistributor from "../Functional Dashboard/DistributorDashbourd";
import PackagingTypeList from "../Master/Packaging Type/PackagingTypeList";
import PackagingTypeForm from "../Master/Packaging Type/PackagingTypeForm";
import RateConfigurationEditingForStockHolderForm from "../Rate Configuration Editing For Stock Holder/RateConfigurationEditingForStockHolderForm";
import DistrictTable from "../Master/District/DistrictTable";
import DistrictForm from "../Master/District/DistrictForm";
import GroupProductForm from "../Master/Group Product/GroupProductForm";
import CountryForm from "../Master/Country/CountryForm";
import CountryTable from "../Master/Country/CountryTable";
import StockHolderOrderingConfiguration from "../Stock Holder Ordering Configuration/StockHolderOrderingConfiguration";
import StateTable from "../Master/State/StateTable";
import StateForm from "../Master/State/StateForm";
import TalukaTable from "../Master/Taluka/TalukaTable";
import TalukaForm from "../Master/Taluka/TalukaForm";
import BrandTable from "../Master/Brand/BrandTable";
import BrandForm from "../Master/Brand/BrandForm";
import TagForm from "../Master/Tag/TagForm";
import TagTable from "../Master/Tag/TagTable";
import GroupProductTable from "../Master/Group Product/GroupProductTable";
import GroupProductView from "../Master/Group Product/GroupProductView";
import CreditNoteFormNew from "../Credit Note For Outlet New/CreditNoteFormNew";
import RateConfigurationEditingForStockholderList from "../Rate Configuration Editing For Stock Holder/RateConfigurationEditingForStockholderList";
import RateConfigurationEditingForStockholderView from "../Rate Configuration Editing For Stock Holder/RateConfigurationEditingForStockholderView";
import ProductRateStructure from "../Product Rate Structure/ProductRateStructure";
import ProductRateStructureList from "../Product Rate Structure/ProductRateStructureList";
import ProductRateStructureView from "../Product Rate Structure/ProductRateStructureView";
import StockHolderOrderConfigurationList from "../Stock Holder Ordering Configuration/StockHolderOrderConfigurationList";
import StockHolderOrderingConfigurationView from "../Stock Holder Ordering Configuration/StockHolderOrderingConfigurationView";
import StockHolderOrderConfigurationEdit from "../Stock Holder Ordering Configuration/StockHolderOrderConfigurationEdit";
import CreditNoteByBillWise from "../Credit Note For Outlet New/CreditNoteByBillWise";
import CreditNote from "../Credit Note For Outlet New/index";
import SalesExecutiveDocument from "../Master/Sales Executive/SalesExecutiveDocument";
import Structure from "../Product Rate Structure/index";
import StructureNew from "../Product Rate Structure/indexNew";

import AssignStructure from "../Product Rate Structure/AssignStructure";
import StakeholderCollapsibleCheck from "../Credit Note/StakeholderCollapibleCheck";
// import CreditNote from "../Credit Note For Outlet New/index";
import OutletMoving from "../Outlet Moving/OutletMoving";
import StockHolderTarget from "../Stock Holder Target/StockHolderTarget";
import StockHolderTargetList from "../Stock Holder Target/StockHolderTargetList";
import StockHolderTargetView from "../Stock Holder Target/StockHolderTargetView";
import UserAccessAssignationForStockHolder from "../UserAccess/UserAccessAssignationForStockHolder";
import ImportData from "../Import/index";
// import RMDashboard from "../Dashboard/RMDashboard";
import PaymentFormStatic from "../PaymentFormStatic/PaymentFormStatic";
import OpeningForStockHolder from "../Opening For Stock Holder And Outlet/OpeningForStockHolder";
import OpeningForOutlet from "../Opening For Stock Holder And Outlet/OpeningForOutlet";
import PurchaseReturnForm from "../Purchase Return/PurchaseReturnForm";
import Ladger from "../Ladger";
import StackHolderActiveStatus from "../Master/StackHolder/StackHolderActiveStatus";
import PaymentListView from "../Payment/PaymentListView";
import StackHolderAccessRole from "../Master/StackHolder/StackHolderAccessRole";
import NoticeMessageEditForm from "../NoticeMessage/NoticeMessageEditForm";
import StockHolderEdit from "../Master/StackHolder/StockHolderEdit";
import CreditNoteOutletView from "../Credit Note/CreditNoteOutletView";
import StructureAssignation from "../Product Rate Structure/StructureAssignation";
import ManageStockCategoryWise from "../Manage Stock/ManageStockCategoryWise";
import ManualOrderAdmin from "../Manual Order For Admin/ManualOrderAdmin";
import UserAccessView from "../UserAccess/UserAccessView";
// import RMDashboard from "../Dashboard/RMDashboard";
import Timeline from "../Dashboard/Timeline";
import SubAdminUserTable from "../Master/Sub Admin User/SubAdminUserTable";
import SubAdminUserForm from "../Master/Sub Admin User/SubAdminUserForm";
import VideoLinkStatic from "../Video Link/VideoLinkStatic";
import SubAdminUserAccessRole from "../Master/Sub Admin User/SubAdminUserAccessRole";
import SubAdminUserEdit from "../Master/Sub Admin User/SubAdminUserEdit";
import Token from "../Token/Token";
import HiearchyDropDown from "../Dashboard/HiearchyDropDown";
import StockHolderTargetEdit from "../Stock Holder Target/StockHolderTargetEdit";
import OrderClosingList from "../OrderClosing/OrderClosingList";
import RecivedOrderEdit from "../ReceivedOrderList/RecivedOrderEdit";
import ChangePassword from "../Change Password/ChangePassword";
import UserProfile from "../User Profile/UserProfile";
import DragDropTable from "../DragDropTable/DragDropTable";
import VehicleForm from "../Master/Vehicle/VehicleForm";
import VehicleList from "../Master/Vehicle/VehicleList";
import TransporterList from "../Master/Transporter/TransporterList";
import TransporterForm from "../Master/Transporter/TransporterForm";
import PurchaseReturn from "../Purchase Return";
import OutletPayment from "../PaymentFormStatic/index";
import OutletLanguage from "../Master/Outlet/OutletLanguage";
import BankForm from "../Master/Bank/BankForm";
import BankTable from "../Master/Bank/BankTable";
import TokenForStockholder from "../Token For Stockholder/TokenForStockholder";
import PaymentTermTable from "../Master/PaymentTerm/PaymentTermTable";
import PaymentTermForm from "../Master/PaymentTerm/PaymentTermForm";
import BatchWiseGRNList from "../BatchWiseGRN/BatchWiseGRNList";
import BatchWiseGRNForm from "../BatchWiseGRN/BatchWiseGRNForm";
import BatchWiseGRNView from "../BatchWiseGRN/BatchWiseGRNView";
import BatchWiseGRNEdit from "../BatchWiseGRN/BatchWiseGRNEdit";
import ManageStockProductWise from "../Manage Stock/ManageStockProductWise";
import ManualOrderAdminProductWise from "../Manual Order For Admin/ManualOrderAdminProductWise";
import AuditView from "../Audit/AuditView";
import TimeSlotForm from "../Master/Time Slot/TimeSlotForm";
import TimeSlotTable from "../Master/Time Slot/TimeSlotTable";
import WorkingTypeForm from "../Master/Working Type/WorkingTypeForm";
import WorkingTypeTable from "../Master/Working Type/WorkingTypeTable";
import OtherTaskTable from "../Master/Other Task/OtherTaskTable";
import OtherTaskForm from "../Master/Other Task/OtherTaskForm";
import AuditSurveyData from "../Audit/AuditSurveyData";
import AuditQuestionAnswer from "../Audit/AuditQuestionAnswer";
import ExcelReportsForInactive from "../Reports/ExcelReportsForInactive";
import LeaveTypeTable from "../Master/Leave Type/LeaveTypeList";
import LeaveTypeForm from "../Master/Leave Type/LeaveTypeForm";
import LeaveStructureTable from "../Master/Leave Structure/LeaveStructureTable";
import LeaveStructureForm from "../Master/Leave Structure/LeaveStructureForm";
import LeaveAllotmentTable from "../Leave Structure Allotment/LeaveStructureAllotmentTable";
import ClaimTypeList from "../Master/Claim Type/ClaimTypeList";
import ClaimTypeForm from "../Master/Claim Type/ClaimTypeForm";
import ClaimStructureList from "../Master/Claim Structure/ClaimStructureList";
import ClaimStructureForm from "../Master/Claim Structure/ClaimStructureForm";
import ClaimStructureAllotmentList from "../Master/Claim Structre Allotment/ClaimStructureAllotmentList";
import WelcomePage from "../Dashboard/WelcomePage";
import AssetsList from "../Master/Assets/AssetsList";
import AssetsForm from "../Master/Assets/AssetsForm";
import VenderList from "../Master/Vender/VenderList";
import VenderForm from "../Master/Vender/VenderForm";
import AssetsChecklistTable from "../Master/AssetsChecklist/AssetsChecklistTable";
import AssetsChecklistForm from "../Master/AssetsChecklist/AssetsChecklistForm";
import ProductCatelogueList from "../Master/ProductCatelogue/ProductCatelogueList";
import AssetsImplementationForm from "../Master/AssetsImplementation/AssetsImplementationForm";
import ProductCatelogueForm from "../Master/ProductCatelogue/ProductCatelogueForm";
import TypeOfBusinessList from "../Master/Type Of Business/TypeOfBusinessList";
import TypeOfBusinessForm from "../Master/Type Of Business/TypeOfBusinessForm";
import InvestedAmountList from "../Master/Invested Amount/InvestedAmountList";
import InvestedAmountForm from "../Master/Invested Amount/InvestedAmountForm";
import AnnualTurnoverList from "../Master/Annual Turnover/AnnualTurnoverList";
import AnnualTurnoverForm from "../Master/Annual Turnover/AnnualTurnoverForm";
import NoOfStaffList from "../Master/NumberOfStaff/NoOfStaffList";
import NoOfStaffForm from "../Master/NumberOfStaff/NoOfStaffForm";
import AssetsRequestDetails from "../Master/AssetsRequestDetails/AssetsRequestDetails";
import RequestForDistributor from "../Request For Distributor/RequestForDistributor";
import AddDistributorForm from "../Request For Distributor/AddDistributorForm";
import AnalyticalAdminDashboard from "../Dashboard/AnalyticalAdminDashboard";
import AuditDetails from "../Audit/AuditDetails";
import SurveyDetails from "../Audit/SurveyDetails";
import AnalyticalDistributorDashboard from "../Dashboard/AnalyticalDistributorDashboard";
import ProductRateStructureOptimisedList from "../Product Rate Structure/ProductRateStructureOptimisedList";
import OfferDetail from "../Dashboard/OfferDetail";
import ProductRateStructureForApplyingMultiple from "../Product Rate Structure/ProductRateStructureForApplyingMultiple";
import WeeklyOffList from "../Master/Weekly Off/WeeklyOffList";
import WeeklyOffForm from "../Master/Weekly Off/WeeklyOffForm";
import NewDatabaseImport from "../Import/NewDatabaseImport";
import Calendar from "../Master/Calendar/Canlendar";
import SalesPersonDataForManager from "../Master/SalesPersonData/SalesPersonDataForManager";
import MapNetwork from "../DistributorBeatNetwork/MapNetwork";
import LeaveSummaryTable from "../Master/Leave Summary/LeaveSummaryTable";
import LeaveSummaryForm from "../Master/Leave Summary/LeaveSummaryForm";
import ProductCatelogueView from "../Master/ProductCatelogue/ProductCatelogueView";
import OfferEditForm from "../Master/Offer/OfferEditForm";
import SalesTeamCategoryWiseData from "../Sales Team Category Wise Data";
import UserSummary from "../User Summary/UserSummaryNew";
import FloatingTabs from "../Comman Dashboard";
import RUCreationAndAssignation from "../RUCreationAndAssignation";
import RUCreationList from "../RUCreationAndAssignation/RUCreationList";
import AssignationForm from "../RUCreationAndAssignation/AssignationForm";
import RUCreationView from "../RUCreationAndAssignation/RUCreationView";
import AssignBeatAllowanceWiseForm from "../AssignBeatAllowanceWise/AssignBeatAllowanceWiseForm";
import StakeholderTypeAllowanceConfig from "../StakeholderTypeAllowanceConfig";
import ProductWiseRUConfigList from "../Product Wise RU Configuration/ProductWiseRUConfigList";
import ProductWiseRUConfigView from "../Product Wise RU Configuration/ProductWiseRUConfigView";
import CentralizedRateStructureList from "../Centralized Rate Structure/CentralizedRateStructureList";
import CentralizedRateStructureForm from "../Centralized Rate Structure/CentralizedRateStructureForm";
import CentralizedRateStructureAssignation from "../Centralized Rate Structure/CentralizedRateStructureAssignation";
import CentralizedRateStructureView from "../Centralized Rate Structure/CentralizedRateStructureView";
import OutletOpeningForm from "../OuletOpening/OutletOpeningForm";
import ClaimPdfMain from "../Claim PDF/ClaimPdfMain";
import GRNToCreditNote from "../Pending GRN For Credit Note/GRNToCreditNote";
import OpeningManageDistributor from "../OpeningManage/OpeningManageDistributor";
import OpeningManageDistributorForm from "../OpeningManage/OpeningManageDistributorForm";
import OpeningManageOutlet from "../OpeningManage/OpeningManageOutlet";
import OpeningManageOutletForm from "../OpeningManage/OpeningManageOutletForm";
import OutletLedgerReport from "../LedgerReport/OutletLedgerReport";
import DistributorLedgerReport from "../LedgerReport/DistributorLedgerReport";
import ProductWiseRUConfigForm from "../Product Wise RU Configuration/ProductWiseRUConfigForm";
import ProductWiseRUConfigFormForDisabledRU from "../Product Wise RU Configuration/ProductWiseRUConfigFormForDisabledRU";
import ClaimForm from "../ClaimForm/ClaimForm";
import ImportAndExport from "../ImportAndExport";
import ChannelTable from "../Master/Channel/ChannelTable";
import ChannelForm from "../Master/Channel/ChannelForm";
import SubChannelTable from "../Master/Sub Channel/SubChannelTable";
import SubChannelForm from "../Master/Sub Channel/SubChannelForm";
import SettingKey from "../Master/SettingKey/SettingKey";
import ConfigurationPending from "../Master/Configuration Pending/ConfigurationPending";
import OutletMovingForStockHolder from "../OutletMovingForStockHolder/OutletMovingForStockHolder";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    minHeight: "calc(100vh - 64px)",
    padding: theme.spacing(1),
    // borderRadius: `${ theme.spacing( 1.5 ) } ${ theme.spacing( 1.5 ) } 0px 0px`,
  },
  typo: {
    color: theme.palette.primary.contrastText,
  },
});

class Landing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBar: false,
    };
  }

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  render() {
    const { classes, common, alertInfo } = this.props;
    const { moduleList } = this.state;
    return (
      <>
        <Header />
        <DrawerNav />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: common.toggleNav
              ? `calc(100% - ${config.drawerWidth}px)`
              : "100%",
            ml: common.toggleNav ? `${config.drawerWidth}px` : "0px",
          }}
          className={classes.root}
        >
          <Routes>
            <Route
              path="/document"
              element={
                <PrivateRoute>
                  <DocumentTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/document-form/:id"
              element={
                <PrivateRoute>
                  <DocumentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/document-form"
              element={
                <PrivateRoute>
                  <DocumentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/uom"
              element={
                <PrivateRoute>
                  <UomTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/uom-form"
              element={
                <PrivateRoute>
                  <UomForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/uom-form/:id"
              element={
                <PrivateRoute>
                  <UomForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/zone"
              element={
                <PrivateRoute>
                  <ErrorBoundary>
                    <ZoneTable />
                  </ErrorBoundary>
                </PrivateRoute>
              }
            />
            <Route
              path="/zone-form"
              element={
                <PrivateRoute>
                  <ZoneForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/zone-form/:id"
              element={
                <PrivateRoute>
                  <ZoneForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/category"
              element={
                <PrivateRoute>
                  <CategoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/category-form"
              element={
                <PrivateRoute>
                  <CategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/category-form/:id"
              element={
                <PrivateRoute>
                  <CategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/tax"
              element={
                <PrivateRoute>
                  <TaxTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/tax-form"
              element={
                <PrivateRoute>
                  <TaxForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/tax-form/:id"
              element={
                <PrivateRoute>
                  <TaxForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area"
              element={
                <PrivateRoute>
                  <AreaTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-form"
              element={
                <PrivateRoute>
                  <AreaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-form/:id"
              element={
                <PrivateRoute>
                  <AreaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-category"
              element={
                <PrivateRoute>
                  <SubCategoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-category-form"
              element={
                <PrivateRoute>
                  <SubCategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-category-form/:id"
              element={
                <PrivateRoute>
                  <SubCategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/mrp-configuration-form"
              element={
                <PrivateRoute>
                  <MRPConfiguration />
                </PrivateRoute>
              }
            />
            <Route
              path="/select-product-document"
              element={
                <PrivateRoute>
                  <SelectProductDocument />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-master"
              element={
                <PrivateRoute>
                  <AreaMasterList />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-master-form"
              element={
                <PrivateRoute>
                  <AreaMasterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-master-form/:id"
              element={
                <PrivateRoute>
                  <AreaMasterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-form"
              element={
                <PrivateRoute>
                  <AreaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-form/:id"
              element={
                <PrivateRoute>
                  <AreaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-executive"
              element={
                <PrivateRoute>
                  <SalesExecutiveTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-executive-form"
              element={
                <PrivateRoute>
                  <SalesExecutiveForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-executive-form/:id"
              element={
                <PrivateRoute>
                  <SalesExecutiveForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product"
              element={
                <PrivateRoute>
                  <ProductTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-form"
              element={
                <PrivateRoute>
                  <ProductForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-form/:id"
              element={
                <PrivateRoute>
                  <ProductForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/region"
              element={
                <PrivateRoute>
                  <RegionTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/region-form"
              element={
                <PrivateRoute>
                  <RegionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/region-form/:id"
              element={
                <PrivateRoute>
                  <RegionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/single-mrp-configuration-by-itemList/:id"
              element={
                <PrivateRoute>
                  <SingleMrpConfigurationByItemList />
                </PrivateRoute>
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
            <Route
              path="/order-configuration"
              element={
                <PrivateRoute>
                  <OrderConfigurationTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/stake-holder"
              element={
                <PrivateRoute>
                  <StackHolderTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-configuration-form"
              element={
                <PrivateRoute>
                  <OrderConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-configuration-form/:id"
              element={
                <PrivateRoute>
                  <OrderConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/stackholder-form"
              element={
                <PrivateRoute>
                  <StackHolderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/stackholder-form/:id"
              element={
                <PrivateRoute>
                  <StackHolderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/manager"
              element={
                <PrivateRoute>
                  <ManagerTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/manager-form"
              element={
                <PrivateRoute>
                  <ManagerForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/manager-form/:id"
              element={
                <PrivateRoute>
                  <ManagerForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/stackholder-form-document/:id"
              element={
                <PrivateRoute>
                  <StackHolderDocument />
                </PrivateRoute>
              }
            />
            <Route
              path="/ndashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-form"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-form/:id"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-form"
              element={
                <PrivateRoute>
                  <UserAccessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-form/:id"
              element={
                <PrivateRoute>
                  <UserAccessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-table"
              element={
                <PrivateRoute>
                  <UserAccessTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-view/:id"
              element={
                <PrivateRoute>
                  <UserAccessView />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration"
              element={
                <PrivateRoute>
                  <RateConfigurationTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-form"
              element={
                <PrivateRoute>
                  <RateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-view/:id"
              element={
                <PrivateRoute>
                  <RateConfigurationView />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-rate-structure-form/:id"
              element={
                <PrivateRoute>
                  <OutletRateStructureFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-rate-structure-form"
              element={
                <PrivateRoute>
                  <OutletRateStructureForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-rate-structure"
              element={
                <PrivateRoute>
                  <OutletRateStructureTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-form/:id"
              element={
                <PrivateRoute>
                  <RateConfigrationFormEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-outlet"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationOutletTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-outlet-form"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationOutletForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-outlet-form/:id"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationOutletForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/assign-rate-configuration-to-stakeholder"
              element={
                <PrivateRoute>
                  <AssignRateConfigurationToStakeholderTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/assign-rate-configuration-to-stakeholder-form"
              element={
                <PrivateRoute>
                  <AssignRateConfigurationToStakeholderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/assign-rate-configuration-to-stakeholder-form/:id"
              element={
                <PrivateRoute>
                  <AssignRateConfigurationToStakeholderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/range"
              element={
                <PrivateRoute>
                  <RangeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/range-form"
              element={
                <PrivateRoute>
                  <RangeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/range-form/:id"
              element={
                <PrivateRoute>
                  <RangeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/grievance-category"
              element={
                <PrivateRoute>
                  <GrievanceCategoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/grievance-category-form"
              element={
                <PrivateRoute>
                  <GrievanceCategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/grievance-category-form/:id"
              element={
                <PrivateRoute>
                  <GrievanceCategoryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-type-form"
              element={
                <PrivateRoute>
                  <OutletTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-type-form/:id"
              element={
                <PrivateRoute>
                  <OutletTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-type"
              element={
                <PrivateRoute>
                  <OutletTypeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/beat-form"
              element={
                <PrivateRoute>
                  <BeatForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/beat-form/:id"
              element={
                <PrivateRoute>
                  <BeatForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/beat"
              element={
                <PrivateRoute>
                  <BeatTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/year"
              element={
                <PrivateRoute>
                  <YearTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/year-form"
              element={
                <PrivateRoute>
                  <YearForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/year-form/:id"
              element={
                <PrivateRoute>
                  <YearForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-form"
              element={
                <PrivateRoute>
                  <OutletForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-form/:id"
              element={
                <PrivateRoute>
                  <OutletForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet"
              element={
                <PrivateRoute>
                  <OutletTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-language/:id"
              element={
                <PrivateRoute>
                  <OutletLanguage />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-config-by-product"
              element={
                <PrivateRoute>
                  <AddRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-config-by-product/:id"
              element={
                <PrivateRoute>
                  <AddRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-config-by-product"
              element={
                <PrivateRoute>
                  <AddOutletRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-config-by-product/:id"
              element={
                <PrivateRoute>
                  <AddOutletRateConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/day-wise-beat-assign-form"
              element={
                <PrivateRoute>
                  <DayWiseBeatAssignForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/day-wise-beat-assign-form/:id"
              element={
                <PrivateRoute>
                  <DayWiseBeatAssignForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/day-wise-beat-assign-table"
              element={
                <PrivateRoute>
                  <DayWiseBeatAssignTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/day-wise-beat-assign-view/:id"
              element={
                <PrivateRoute>
                  <DayWiseBeatAssignView />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-form"
              element={
                <PrivateRoute>
                  <AuditForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit"
              element={
                <PrivateRoute>
                  <AuditTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-form/:id"
              element={
                <PrivateRoute>
                  <AuditForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-form/:id/:copyToFlag"
              element={
                <PrivateRoute>
                  <AuditForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/roaster"
              element={
                <PrivateRoute>
                  <MatrixForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/region-and-target"
              element={
                <PrivateRoute>
                  <RegionAndTargetTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/region-and-target-form"
              element={
                <PrivateRoute>
                  <RegionAndTargetForm />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/region-and-target-form/:id"
              element={
                <PrivateRoute>
                  <RegionAndTargetForm />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/region-and-target-edit-form/:id"
              element={
                <PrivateRoute>
                  <RegionAndTargetEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/target"
              element={
                <PrivateRoute>
                  <TargetTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/target-form"
              element={
                <PrivateRoute>
                  <TargetForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/assign-beat-form"
              element={
                <PrivateRoute>
                  <AssignBeatForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/projection-form"
              element={
                <PrivateRoute>
                  <ProjectionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/grievance"
              element={
                <PrivateRoute>
                  <GrievanceTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-survey-data/:id"
              element={
                <PrivateRoute>
                  <AuditSurveyData />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-and-target"
              element={
                <PrivateRoute>
                  <CompanyAndTargetTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-and-target-form"
              element={
                <PrivateRoute>
                  <CompanyAndTargetForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-and-target-form/:id"
              element={
                <PrivateRoute>
                  <CompanyAndTargetForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/distribution-reports"
              element={
                <PrivateRoute>
                  <DistributionReports />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <MainDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-dashboard"
              element={
                <PrivateRoute>
                  <NewAdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/distributor-dashboard"
              element={
                <PrivateRoute>
                  <DistributorDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/rm-dashboard"
              element={
                <PrivateRoute>
                  <RMDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-dashboard"
              element={
                <PrivateRoute>
                  <SalesDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/inactive-outlet"
              element={
                <PrivateRoute>
                  <InactiveOutlet />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment/:id"
              element={
                <PrivateRoute>
                  <PaymentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-list"
              element={
                <PrivateRoute>
                  <OrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment"
              element={
                <PrivateRoute>
                  <PaymentModeDialog />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-list"
              element={
                <PrivateRoute>
                  <PaymentList />
                </PrivateRoute>
              }
            />
            <Route
              path="/settle-bill"
              element={
                <PrivateRoute>
                  <SettleBillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/stockist-settle-bill"
              element={
                <PrivateRoute>
                  <StockistSettleBillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-history"
              element={
                <PrivateRoute>
                  <OrderHistoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-history-edit/:id"
              element={
                <PrivateRoute>
                  <OrderHistoryEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-history-view/:id"
              element={
                <PrivateRoute>
                  <OrderHistoryView />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales/:id/:userTypeId"
              element={
                <PrivateRoute>
                  <DashMap />
                </PrivateRoute>
              }
            />
            <Route
              path="/manual-order"
              element={
                <PrivateRoute>
                  <ManualOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="/received-order-list"
              element={
                <PrivateRoute>
                  <ReceivedOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/received-order-bill/:id"
              element={
                <PrivateRoute>
                  <ReceivedOrderBillForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-grn-list"
              element={
                <PrivateRoute>
                  <RequestGRNList />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-grn-view/:id"
              element={
                <PrivateRoute>
                  <RequestGRNView />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-grn-edit/:id"
              element={
                <PrivateRoute>
                  <RequestGRNEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-grn-form"
              element={
                <PrivateRoute>
                  <RequestGRNForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-grn-header-form"
              element={
                <PrivateRoute>
                  <RequestGRNHeaderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/notice-message-form"
              element={
                <PrivateRoute>
                  <NoticeMessageForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/notice-message-form/:id"
              element={
                <PrivateRoute>
                  <NoticeMessageEditForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/notice-message-list"
              element={
                <PrivateRoute>
                  <NoticeMessageList />
                </PrivateRoute>
              }
            />
            <Route
              path="/generated-bills"
              element={
                <PrivateRoute>
                  <ListOfGeneratedBill />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-closing"
              element={
                <PrivateRoute>
                  <OrderClosing />
                </PrivateRoute>
              }
            />
            <Route
              path="/billing-history"
              element={
                <PrivateRoute>
                  <BillingHistoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-list"
              element={
                <PrivateRoute>
                  <CreditNoteList />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note"
              element={
                <PrivateRoute>
                  <CreditNoteForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/billing-history-view/:id"
              element={
                <PrivateRoute>
                  <BillingHistoryView />
                </PrivateRoute>
              }
            />
            <Route
              path="/billing-history-edit/:id"
              element={
                <PrivateRoute>
                  <BillingHistoryEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/placed-order-form"
              element={
                <PrivateRoute>
                  <PlacedOrderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/PdfComponent"
              element={
                <PrivateRoute>
                  <PdfComponent />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/export-excel"
              element={
                <PrivateRoute>
                  <ExportToExcel />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/show-order-list"
              element={
                <PrivateRoute>
                  <ShowOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/place-order"
              element={
                <PrivateRoute>
                  <PlaceOrderTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/placed-order-form/:id"
              element={
                <PrivateRoute>
                  <PlaceOrderEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/place-order-view/:id"
              element={
                <PrivateRoute>
                  <PlaceOrderView />
                </PrivateRoute>
              }
            />
            <Route
              path="/order-list-view/:id"
              element={
                <PrivateRoute>
                  <ViewOrderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-Bill-List"
              element={
                <PrivateRoute>
                  <ShowMyBillList />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-Bill-List-view/:id"
              element={
                <PrivateRoute>
                  <ShowMyBillView />
                </PrivateRoute>
              }
            />
            <Route
              path="/generated-bill-list-view/:id"
              element={
                <PrivateRoute>
                  <ListOfGeneratedBillView />
                </PrivateRoute>
              }
            />
            <Route
              path="/genrated-bill-edit/:id"
              element={
                <PrivateRoute>
                  <ListOfGeneratedBillEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-header-list"
              element={
                <PrivateRoute>
                  <CreditNoteTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-view/:id"
              element={
                <PrivateRoute>
                  <CreditNoteView />
                </PrivateRoute>
              }
            />
            <Route
              path="/grn-history"
              element={
                <PrivateRoute>
                  <GRNHistoryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/grn-history-view/:id"
              element={
                <PrivateRoute>
                  <GrnHistoryView />
                </PrivateRoute>
              }
            />
            <Route
              path="/pending-grn-list"
              element={
                <PrivateRoute>
                  <PendingGrnList />
                </PrivateRoute>
              }
            />
            <Route
              path="/history-of-credit-note"
              element={
                <PrivateRoute>
                  <HistoryOfcreditNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/history-of-credit-note-view/:id"
              element={
                <PrivateRoute>
                  <HistoryOfCreditNoteView />
                </PrivateRoute>
              }
            />
            <Route
              path="/pending-grn-list-view/:id"
              element={
                <PrivateRoute>
                  <PendingGrnView />
                </PrivateRoute>
              }
            />
            <Route
              path="/pending-grn-details/:id"
              element={
                <PrivateRoute>
                  <PendingGrnDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-stock"
              element={
                <PrivateRoute>
                  <ManageStockTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-list-stockist"
              element={
                <PrivateRoute>
                  <PaymentListForStockist />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-form-stockist"
              element={
                <PrivateRoute>
                  <PaymentFormForStockist />
                </PrivateRoute>
              }
            />
            <Route
              path="/pending-grn-for-credit-note"
              element={
                <PrivateRoute>
                  <PendingGrnForCreditNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-consumption-header"
              element={
                <PrivateRoute>
                  <StockConsumptionHeader />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-stock-form"
              element={
                <PrivateRoute>
                  <ManageStockForm />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/manage-stock-form/:id"
              element={
                <PrivateRoute>
                  <ManageStockEdit />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/manage-stock-categorywise-form/:id"
              element={
                <PrivateRoute>
                  <ManageStockEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-stock-view/:id"
              element={
                <PrivateRoute>
                  <ManageStockView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-between-date"
              element={
                <PrivateRoute>
                  <StockBetweenDateTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-outlet-billing-form"
              element={
                <PrivateRoute>
                  <BatchwiseOutletBillingForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-stakeholder-bill"
              element={
                <PrivateRoute>
                  <BatchwiseStakeholderBillingList />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-stakeholder-bill-form"
              element={
                <PrivateRoute>
                  <BatchwiseOutletBillingStakeholderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-team-order-config-form"
              element={
                <PrivateRoute>
                  <SalesTeamOrderConfigForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-instock"
              element={
                <PrivateRoute>
                  <BatchwiseManageInstockList />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-instock-view/:id"
              element={
                <PrivateRoute>
                  <BatchwiseManageInstockView />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-stock-form"
              element={
                <PrivateRoute>
                  <BatchwiseManageInstockForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-multilingual-form"
              element={
                <PrivateRoute>
                  <ProductMultilingualForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/area-multilingual-form"
              element={
                <PrivateRoute>
                  <AreaMultilingualForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-multilingual-form"
              element={
                <PrivateRoute>
                  <OutletMultilingualForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-stock-form/:id"
              element={
                <PrivateRoute>
                  <BatchwiseManageInstockEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-outlet-billing-list"
              element={
                <PrivateRoute>
                  <BatchWiseOutletBillingList />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-outstock"
              element={
                <PrivateRoute>
                  <BatchWiseManageOutstock />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-outstock-view/:id"
              element={
                <PrivateRoute>
                  <BatchWiseManageOutstockView />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-outstock-form"
              element={
                <PrivateRoute>
                  <BatchWiseManageOutstockForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-manage-outstock-form/:id"
              element={
                <PrivateRoute>
                  <BatchWiseManageOutstockEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-details"
              element={
                <PrivateRoute>
                  <PaymentDetailsTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-details-view/:id"
              element={
                <PrivateRoute>
                  <PaymentDetailsView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-dashbourd"
              element={
                <PrivateRoute>
                  <StockHolderDashbourd />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-dashbourd"
              element={
                <PrivateRoute>
                  <OutletDashbourd />
                </PrivateRoute>
              }
            />
            <Route
              path="/multiple-stock"
              element={
                <PrivateRoute>
                  <MultipleStock />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-form"
              element={
                <PrivateRoute>
                  <CompanyForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-batchwise-grn-list"
              element={
                <PrivateRoute>
                  <RequestBatchwiseGRNList />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-batchwise-grn-form"
              element={
                <PrivateRoute>
                  <RequestBatchwiseGRNForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-batchwise-grn-header-form"
              element={
                <PrivateRoute>
                  <RequestBatchwiseGRNHeaderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-batchwise-grn-view/:id"
              element={
                <PrivateRoute>
                  <RequestBatchwiseGRNView />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-batchwise-grn-edit/:id"
              element={
                <PrivateRoute>
                  <RequestBatchwiseGRNEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-creation-assignation"
              element={
                <PrivateRoute>
                  <RUCreationAndAssignation />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-creation-assignation-view/:id"
              element={
                <PrivateRoute>
                  <RUCreationView />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-assign-form"
              element={
                <PrivateRoute>
                  <AssignationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-creation-assignation/:id"
              element={
                <PrivateRoute>
                  <RUCreationAndAssignation />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-creation-assignation/:id/:copyFlag"
              element={
                <PrivateRoute>
                  <RUCreationAndAssignation />
                </PrivateRoute>
              }
            />
            <Route
              path="/ru-assignation-list"
              element={
                <PrivateRoute>
                  <RUCreationList />
                </PrivateRoute>
              }
            />

            <Route
              path="/ru-creation-list"
              element={
                <PrivateRoute>
                  <RUCreationList />
                </PrivateRoute>
              }
            />
            <Route
              path="/stakeholder-type-allowance-config"
              element={
                <PrivateRoute>
                  <StakeholderTypeAllowanceConfig />
                </PrivateRoute>
              }
            />

            <Route
              path="/offer"
              element={
                <PrivateRoute>
                  <Offer />
                </PrivateRoute>
              }
            />

            <Route
              path="/offer-edit-form/:id"
              element={
                <PrivateRoute>
                  <OfferEditForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/offer-list"
              element={
                <PrivateRoute>
                  <OfferList />
                </PrivateRoute>
              }
            />
            <Route
              path="/offer-view/:id"
              element={
                <PrivateRoute>
                  <OfferView />
                </PrivateRoute>
              }
            />
            <Route
              path="/billing-with-offer"
              element={
                <PrivateRoute>
                  <BillingWithOffer />
                </PrivateRoute>
              }
            />
            <Route
              path="/notice-message-view/:id"
              element={
                <PrivateRoute>
                  <NoticeMessageView />
                </PrivateRoute>
              }
            />
            <Route
              path="/errorPage"
              element={
                <PrivateRoute>
                  <ErrorPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-report/:id"
              element={
                <PrivateRoute>
                  <DynamicReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate"
              element={
                <PrivateRoute>
                  <ReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <ReportTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate/:id"
              element={
                <PrivateRoute>
                  <ReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-report-query/:id"
              element={
                <PrivateRoute>
                  <DynamicReportWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate-query"
              element={
                <PrivateRoute>
                  <ReportGeneratorWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-with-query"
              element={
                <PrivateRoute>
                  <ReportTableWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/report-generate-query/:id"
              element={
                <PrivateRoute>
                  <ReportGeneratorWithQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-dynamic-report-query/:id"
              element={
                <PrivateRoute>
                  <MatrixDynamicReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-report-generate-query"
              element={
                <PrivateRoute>
                  <MatricReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/ledger"
              element={
                <PrivateRoute>
                  <Ladger />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-report-with-query"
              element={
                <PrivateRoute>
                  <ReportTableMatrix />
                </PrivateRoute>
              }
            />
            <Route
              path="/matrix-report-generate-query/:id"
              element={
                <PrivateRoute>
                  <MatricReportGenerator />
                </PrivateRoute>
              }
            />
            <Route
              path="/type-form"
              element={
                <PrivateRoute>
                  <TypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/type"
              element={
                <PrivateRoute>
                  <TypeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-category-configuration-list"
              element={
                <PrivateRoute>
                  <CompanyCategoryConfigurationTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-category-configuration-form"
              element={
                <PrivateRoute>
                  <CompanyCategoryConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-category-configuration-form/:id"
              element={
                <PrivateRoute>
                  <CompanyCategoryConfigurationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/company-category-configuration-view/:id"
              element={
                <PrivateRoute>
                  <CompanyCategoryConfigurationView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-dashboard"
              element={
                <PrivateRoute>
                  <StockHolderDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/functional-dashboard-distributor-old"
              element={
                <PrivateRoute>
                  <FunctionalDashboardDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/functional-dashboard-distributor"
              element={
                <PrivateRoute>
                  <FunctionalFinalDashboardDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/target-unfreeze"
              element={
                <PrivateRoute>
                  <TargetUnfreeze />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-outlet-view/:id"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationOutletView />
                </PrivateRoute>
              }
            />
            <Route
              path="/assign-rate-configuration-to-stakeholder-view/:id"
              element={
                <PrivateRoute>
                  <AssignRateConfigurationToStakeholderView />
                </PrivateRoute>
              }
            />
            <Route
              path="/publish-rate-configuration-view/:id"
              element={
                <PrivateRoute>
                  <PublishRateConfigurationView />
                </PrivateRoute>
              }
            />
            <Route
              path="/packaging-type-list"
              element={
                <PrivateRoute>
                  <PackagingTypeList />
                </PrivateRoute>
              }
            />
            <Route
              path="/packaging-type-form"
              element={
                <PrivateRoute>
                  <PackagingTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/packaging-type-form/:id"
              element={
                <PrivateRoute>
                  <PackagingTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-editing-for-stock-holder-form"
              element={
                <PrivateRoute>
                  <RateConfigurationEditingForStockHolderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/district-list"
              element={
                <PrivateRoute>
                  <DistrictTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/district-form"
              element={
                <PrivateRoute>
                  <DistrictForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/district-form/:id"
              element={
                <PrivateRoute>
                  <DistrictForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/group-product-list"
              element={
                <PrivateRoute>
                  <GroupProductTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/group-product-form"
              element={
                <PrivateRoute>
                  <GroupProductForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/group-product-form/:id"
              element={
                <PrivateRoute>
                  <GroupProductForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/group-product-view/:id"
              element={
                <PrivateRoute>
                  <GroupProductView />
                </PrivateRoute>
              }
            />
            <Route
              path="/country-form"
              element={
                <PrivateRoute>
                  <CountryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/country-form/:id"
              element={
                <PrivateRoute>
                  <CountryForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/country"
              element={
                <PrivateRoute>
                  <CountryTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-Holder-ordering-configuration"
              element={
                <PrivateRoute>
                  <StockHolderOrderingConfiguration />
                </PrivateRoute>
              }
            />
            <Route
              path="/state"
              element={
                <PrivateRoute>
                  <StateTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/state-form"
              element={
                <PrivateRoute>
                  <StateForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/state-form/:id"
              element={
                <PrivateRoute>
                  <StateForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/taluka"
              element={
                <PrivateRoute>
                  <TalukaTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/taluka-form"
              element={
                <PrivateRoute>
                  <TalukaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/taluka-form/:id"
              element={
                <PrivateRoute>
                  <TalukaForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/brand"
              element={
                <PrivateRoute>
                  <BrandTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/brand-form"
              element={
                <PrivateRoute>
                  <BrandForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/brand-form/:id"
              element={
                <PrivateRoute>
                  <BrandForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/tag"
              element={
                <PrivateRoute>
                  <TagTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/tag-form"
              element={
                <PrivateRoute>
                  <TagForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/tag-form/:id"
              element={
                <PrivateRoute>
                  <TagForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-form"
              element={
                <PrivateRoute>
                  <CreditNoteFormNew />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-editing-for-stock-holder-list"
              element={
                <PrivateRoute>
                  <RateConfigurationEditingForStockholderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-editing-for-stock-holder-form/:id"
              element={
                <PrivateRoute>
                  <RateConfigurationEditingForStockHolderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/rate-configuration-editing-for-stock-holder-view/:id"
              element={
                <PrivateRoute>
                  <RateConfigurationEditingForStockholderView />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure-list"
              element={
                <PrivateRoute>
                  <ProductRateStructureList />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure"
              element={
                <PrivateRoute>
                  <Structure />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure-new"
              element={
                <PrivateRoute>
                  <StructureNew />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure/:id"
              element={
                <PrivateRoute>
                  <Structure copyFlag={0} />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure-new/:id"
              element={
                <PrivateRoute>
                  <StructureNew copyFlag={0} />
                </PrivateRoute>
              }
            />
            <Route
              path="/copy-product-rate-structure/:id"
              element={
                <PrivateRoute>
                  <StructureNew copyFlag={1} />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure-view/:id"
              element={
                <PrivateRoute>
                  <ProductRateStructureView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-ordering-configuration-list"
              element={
                <PrivateRoute>
                  <StockHolderOrderConfigurationList />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-ordering-configuration-view/:id"
              element={
                <PrivateRoute>
                  <StockHolderOrderingConfigurationView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-ordering-configuration-edit/:id"
              element={
                <PrivateRoute>
                  <StockHolderOrderConfigurationEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-for-stakeholder"
              element={
                <PrivateRoute>
                  <CreditNote />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/sales-executive-document/:id"
              element={
                <PrivateRoute>
                  <SalesExecutiveDocument />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/assign-structure/:id"
              element={
                <PrivateRoute>
                  <AssignStructure />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/credit-note-billwise-form"
              element={
                <PrivateRoute>
                  <CreditNote />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/stakeholder-collapsible-check"
              element={
                <PrivateRoute>
                  <StakeholderCollapsibleCheck />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-moving"
              element={
                <PrivateRoute>
                  <OutletMoving />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-target"
              element={
                <PrivateRoute>
                  <StockHolderTarget />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-target-list"
              element={
                <PrivateRoute>
                  <StockHolderTargetList />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-target/:id"
              element={
                <PrivateRoute>
                  <StockHolderTarget />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-target-view/:id"
              element={
                <PrivateRoute>
                  <StockHolderTargetView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-target-edit/:id"
              element={
                <PrivateRoute>
                  <StockHolderTargetEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-access-assignation-for-stockholder/:id"
              element={
                <PrivateRoute>
                  <UserAccessAssignationForStockHolder />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-executive-document/:id"
              element={
                <PrivateRoute>
                  <SalesExecutiveDocument />
                </PrivateRoute>
              }
            />
            <Route
              path="/import"
              element={
                <PrivateRoute>
                  <ImportData />
                </PrivateRoute>
              }
            />
            <Route
              path="/import-and-export"
              element={
                <PrivateRoute>
                  <ImportAndExport />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/payment-outlet"
              element={
                <PrivateRoute>
                  <PaymentFormStatic />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/outlet-and-vehiclewise-payment-for-outlet"
              element={
                <PrivateRoute>
                  <OutletPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/opening-for-stockholder"
              element={
                <PrivateRoute>
                  <OpeningForStockHolder />
                </PrivateRoute>
              }
            />
            <Route
              path="/opening-for-outlet"
              element={
                <PrivateRoute>
                  <OpeningForOutlet />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-return-form"
              element={
                <PrivateRoute>
                  <PurchaseReturnForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/stackholder-change-status/:id"
              element={
                <PrivateRoute>
                  <StackHolderActiveStatus />
                </PrivateRoute>
              }
            />
            <Route
              path="/stackholder-access-role/:id"
              element={
                <PrivateRoute>
                  <StackHolderAccessRole />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-list/:id"
              element={
                <PrivateRoute>
                  <PaymentListView />
                </PrivateRoute>
              }
            />
            <Route
              path="/stock-holder-edit/:id"
              element={
                <PrivateRoute>
                  <StockHolderEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-note-outlet-view/:id"
              element={
                <PrivateRoute>
                  <CreditNoteOutletView />
                </PrivateRoute>
              }
            />
            <Route
              path="/structure-assignation/:id"
              element={
                <PrivateRoute>
                  <StructureAssignation />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-stock-categorywise-form"
              element={
                <PrivateRoute>
                  <ManageStockCategoryWise />
                </PrivateRoute>
              }
            />
            <Route
              path="/manual-order-for-admin-form"
              element={
                <PrivateRoute>
                  <ManualOrderAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="/timeline-for-salesperson"
              element={
                <PrivateRoute>
                  <Timeline />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-admin-user"
              element={
                <PrivateRoute>
                  <SubAdminUserTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-admin-user-form"
              element={
                <PrivateRoute>
                  <SubAdminUserForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-admin-user-edit/:id"
              element={
                <PrivateRoute>
                  <SubAdminUserEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/video-link"
              element={
                <PrivateRoute>
                  <VideoLinkStatic />
                </PrivateRoute>
              }
            />
            <Route
              path="/submit-access-role/:id"
              element={
                <PrivateRoute>
                  <SubAdminUserAccessRole />
                </PrivateRoute>
              }
            />
            <Route
              path="/excel-reports"
              element={
                <PrivateRoute>
                  <ExcelReports />
                </PrivateRoute>
              }
            />
            <Route
              path="/excel-reports-for-inactive"
              element={
                <PrivateRoute>
                  <ExcelReportsForInactive />
                </PrivateRoute>
              }
            />
            <Route
              path="/token"
              element={
                <PrivateRoute>
                  <Token />
                </PrivateRoute>
              }
            />
            <Route
              path="/hieararchy-dropdown"
              element={
                <PrivateRoute>
                  <HiearchyDropDown />
                </PrivateRoute>
              }
            />
            <Route
              path="/lost-order-list"
              element={
                <PrivateRoute>
                  <OrderClosingList />
                </PrivateRoute>
              }
            />
            <Route
              path="/received-order-bill-edit/:id"
              element={
                <PrivateRoute>
                  <RecivedOrderEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/drag-drop-table"
              element={
                <PrivateRoute>
                  <DragDropTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/vehicle-list"
              element={
                <PrivateRoute>
                  <VehicleList />
                </PrivateRoute>
              }
            />
            <Route
              path="/vehicle-form"
              element={
                <PrivateRoute>
                  <VehicleForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/vehicle-form/:id"
              element={
                <PrivateRoute>
                  <VehicleForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/transporter-list"
              element={
                <PrivateRoute>
                  <TransporterList />
                </PrivateRoute>
              }
            />
            <Route
              path="/transporter-form"
              element={
                <PrivateRoute>
                  <TransporterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/transporter-form/:id"
              element={
                <PrivateRoute>
                  <TransporterForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-return"
              element={
                <PrivateRoute>
                  <PurchaseReturn />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-form"
              element={
                <PrivateRoute>
                  <BankForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-form/:id"
              element={
                <PrivateRoute>
                  <BankForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/bank-detail"
              element={
                <PrivateRoute>
                  <BankTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/token-for-stockholder"
              element={
                <PrivateRoute>
                  <TokenForStockholder />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-term"
              element={
                <PrivateRoute>
                  <PaymentTermTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-term-form"
              element={
                <PrivateRoute>
                  <PaymentTermForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-term-form/:id"
              element={
                <PrivateRoute>
                  <PaymentTermForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-grn-list"
              element={
                <PrivateRoute>
                  <BatchWiseGRNList />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-grn-form"
              element={
                <PrivateRoute>
                  <BatchWiseGRNForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-grn-view/:id"
              element={
                <PrivateRoute>
                  <BatchWiseGRNView />
                </PrivateRoute>
              }
            />
            <Route
              path="/batchwise-grn-edit/:id"
              element={
                <PrivateRoute>
                  <BatchWiseGRNEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-stock-product-wise"
              element={
                <PrivateRoute>
                  <ManageStockProductWise />
                </PrivateRoute>
              }
            />
            <Route
              path="/manual-order-admin-product-wise"
              element={
                <PrivateRoute>
                  <ManualOrderAdminProductWise />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-view/:id"
              element={
                <PrivateRoute>
                  <AuditView />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-question-answer/:id/:areaId"
              element={
                <PrivateRoute>
                  <AuditQuestionAnswer />
                </PrivateRoute>
              }
            />
            <Route
              path="/time-slot"
              element={
                <PrivateRoute>
                  <TimeSlotTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/time-slot-form"
              element={
                <PrivateRoute>
                  <TimeSlotForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/time-slot-form/:id"
              element={
                <PrivateRoute>
                  <TimeSlotForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/working-type"
              element={
                <PrivateRoute>
                  <WorkingTypeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/working-type-form"
              element={
                <PrivateRoute>
                  <WorkingTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/working-type-form/:id"
              element={
                <PrivateRoute>
                  <WorkingTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/other-task"
              element={
                <PrivateRoute>
                  <OtherTaskTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/other-task-form"
              element={
                <PrivateRoute>
                  <OtherTaskForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/other-task-form/:id"
              element={
                <PrivateRoute>
                  <OtherTaskForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-type"
              element={
                <PrivateRoute>
                  <LeaveTypeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-type-form"
              element={
                <PrivateRoute>
                  <LeaveTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-type-form/:id"
              element={
                <PrivateRoute>
                  <LeaveTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-structure"
              element={
                <PrivateRoute>
                  <LeaveStructureTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-structure-form"
              element={
                <PrivateRoute>
                  <LeaveStructureForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-structure-form/:id"
              element={
                <PrivateRoute>
                  <LeaveStructureForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave-structure-allotment"
              element={
                <PrivateRoute>
                  <LeaveAllotmentTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-type-list"
              element={
                <PrivateRoute>
                  <ClaimTypeList />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-type-form"
              element={
                <PrivateRoute>
                  <ClaimTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-type-form/:id"
              element={
                <PrivateRoute>
                  <ClaimTypeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-structure-list"
              element={
                <PrivateRoute>
                  <ClaimStructureList />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-structure-form"
              element={
                <PrivateRoute>
                  <ClaimStructureForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-structure-form/:id"
              element={
                <PrivateRoute>
                  <ClaimStructureForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-structure-allotment"
              element={
                <PrivateRoute>
                  <ClaimStructureAllotmentList />
                </PrivateRoute>
              }
            />
            <Route
              path="/claim-structure-allotment/:id"
              element={
                <PrivateRoute>
                  <ClaimStructureAllotmentList />
                </PrivateRoute>
              }
            />
            <Route
              path="/welcome-page"
              element={
                <PrivateRoute>
                  <WelcomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <PrivateRoute>
                  <AssetsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets-form"
              element={
                <PrivateRoute>
                  <AssetsForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets-form/:id"
              element={
                <PrivateRoute>
                  <AssetsForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendor"
              element={
                <PrivateRoute>
                  <VenderList />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendor-form"
              element={
                <PrivateRoute>
                  <VenderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/vendor-form/:id"
              element={
                <PrivateRoute>
                  <VenderForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/parameters-checklist"
              element={
                <PrivateRoute>
                  <AssetsChecklistTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/parameters-checklist-form"
              element={
                <PrivateRoute>
                  <AssetsChecklistForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/parameters-checklist-form/:id"
              element={
                <PrivateRoute>
                  <AssetsChecklistForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-catelogue"
              element={
                <PrivateRoute>
                  <ProductCatelogueList />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-catelogue-view/:id"
              element={
                <PrivateRoute>
                  <ProductCatelogueView />
                </PrivateRoute>
              }
            />

            <Route
              path="/assets-implementation"
              element={
                <PrivateRoute>
                  <AssetsImplementationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-catelogue-form"
              element={
                <PrivateRoute>
                  <ProductCatelogueForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-catelogue-form/:id"
              element={
                <PrivateRoute>
                  <ProductCatelogueForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/type-of-business"
              element={
                <PrivateRoute>
                  <TypeOfBusinessList />
                </PrivateRoute>
              }
            />
            <Route
              path="/type-of-business-form"
              element={
                <PrivateRoute>
                  <TypeOfBusinessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/type-of-business-form/:id"
              element={
                <PrivateRoute>
                  <TypeOfBusinessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/invested-amount"
              element={
                <PrivateRoute>
                  <InvestedAmountList />
                </PrivateRoute>
              }
            />
            <Route
              path="/invested-amount-form"
              element={
                <PrivateRoute>
                  <InvestedAmountForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/invested-amount-form/:id"
              element={
                <PrivateRoute>
                  <InvestedAmountForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/annual-turnover"
              element={
                <PrivateRoute>
                  <AnnualTurnoverList />
                </PrivateRoute>
              }
            />
            <Route
              path="/annual-turnover-form"
              element={
                <PrivateRoute>
                  <AnnualTurnoverForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/annual-turnover-form/:id"
              element={
                <PrivateRoute>
                  <AnnualTurnoverForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/no-of-staff"
              element={
                <PrivateRoute>
                  <NoOfStaffList />
                </PrivateRoute>
              }
            />
            <Route
              path="/no-of-staff-form"
              element={
                <PrivateRoute>
                  <NoOfStaffForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/no-of-staff-form/:id"
              element={
                <PrivateRoute>
                  <NoOfStaffForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets-request-details"
              element={
                <PrivateRoute>
                  <AssetsRequestDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/request-for-distributor"
              element={
                <PrivateRoute>
                  <RequestForDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-distributor-form"
              element={
                <PrivateRoute>
                  <AddDistributorForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-distributor-form/:id"
              element={
                <PrivateRoute>
                  <AddDistributorForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/analytical-admin-dashboard"
              element={
                <PrivateRoute>
                  <AnalyticalAdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit-details/:id"
              element={
                <PrivateRoute>
                  <AuditDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/survey-details/:id"
              element={
                <PrivateRoute>
                  <SurveyDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/analytical-distributor-dashboard"
              element={
                <PrivateRoute>
                  <AnalyticalDistributorDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/product-rate-structure-optimised-list"
              element={
                <PrivateRoute>
                  <ProductRateStructureOptimisedList />
                </PrivateRoute>
              }
            />

            <Route
              path="/offer-detail/:id"
              element={
                <PrivateRoute>
                  <OfferDetail />
                </PrivateRoute>
              }
            />

            <Route
              path="/multiple-product-rate-structure"
              element={
                <PrivateRoute>
                  <ProductRateStructureForApplyingMultiple />
                </PrivateRoute>
              }
            />

            <Route
              path="/weekly-off"
              element={
                <PrivateRoute>
                  <WeeklyOffList />
                </PrivateRoute>
              }
            />

            <Route
              path="/weekly-off-form"
              element={
                <PrivateRoute>
                  <WeeklyOffForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/weekly-off-form/:id"
              element={
                <PrivateRoute>
                  <WeeklyOffForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/new-db-import"
              element={
                <PrivateRoute>
                  <NewDatabaseImport />
                </PrivateRoute>
              }
            />

            <Route
              path="/calendar"
              element={
                <PrivateRoute>
                  <Calendar />
                </PrivateRoute>
              }
            />

            <Route
              path="/sales-person-details"
              element={
                <PrivateRoute>
                  <SalesPersonDataForManager />
                </PrivateRoute>
              }
            />

            <Route
              path="/map-network"
              element={
                <PrivateRoute>
                  <MapNetwork />
                </PrivateRoute>
              }
            />

            <Route
              path="/leave-summary"
              element={
                <PrivateRoute>
                  <LeaveSummaryTable />
                </PrivateRoute>
              }
            />

            <Route
              path="/leave-summary-form"
              element={
                <PrivateRoute>
                  <LeaveSummaryForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/leave-summary-form/:id"
              element={
                <PrivateRoute>
                  <LeaveSummaryForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/sales-team-category-wise-data"
              element={
                <PrivateRoute>
                  <SalesTeamCategoryWiseData />
                </PrivateRoute>
              }
            />

            <Route
              path="/user-summary"
              element={
                <PrivateRoute>
                  <UserSummary />
                </PrivateRoute>
              }
            />

            <Route
              path="/analytics"
              element={
                <PrivateRoute>
                  <FloatingTabs />
                </PrivateRoute>
              }
            />

            <Route
              path="/assign-beat-allowance-wise"
              element={
                <PrivateRoute>
                  <AssignBeatAllowanceWiseForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config-disabled-ru"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigFormForDisabledRU />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config-disabled-ru/:id"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigFormForDisabledRU />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config/:id"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config-list"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigList />
                </PrivateRoute>
              }
            />

            <Route
              path="/product-wise-ru-config-view/:id"
              element={
                <PrivateRoute>
                  <ProductWiseRUConfigView />
                </PrivateRoute>
              }
            />

            <Route
              path="/centralized-rate-structure-list"
              element={
                <PrivateRoute>
                  <CentralizedRateStructureList />
                </PrivateRoute>
              }
            />

            <Route
              path="/centralized-rate-structure-form"
              element={
                <PrivateRoute>
                  <CentralizedRateStructureForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/centralized-rate-structure-form/:id"
              element={
                <PrivateRoute>
                  <CentralizedRateStructureForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/centralized-rate-structure-assignation/:id"
              element={
                <PrivateRoute>
                  <CentralizedRateStructureAssignation />
                </PrivateRoute>
              }
            />

            <Route
              path="/centralized-rate-structure-view/:id"
              element={
                <PrivateRoute>
                  <CentralizedRateStructureView />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-opening-form"
              element={
                <PrivateRoute>
                  <OutletOpeningForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/claim-pdf-main"
              element={
                <PrivateRoute>
                  <ClaimPdfMain />
                </PrivateRoute>
              }
            />

            <Route
              path="/grn-to-crn"
              element={
                <PrivateRoute>
                  <GRNToCreditNote />
                </PrivateRoute>
              }
            />

            <Route
              path="/manage-opening-distributor-list"
              element={
                <PrivateRoute>
                  <OpeningManageDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-opening-distributor-form"
              element={
                <PrivateRoute>
                  <OpeningManageDistributorForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/manage-opening-outlet-list"
              element={
                <PrivateRoute>
                  <OpeningManageOutlet />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-opening-outlet-form"
              element={
                <PrivateRoute>
                  <OpeningManageOutletForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/ledger-report-outlet"
              element={
                <PrivateRoute>
                  <OutletLedgerReport />
                </PrivateRoute>
              }
            />

            <Route
              path="/ledger-report-distributor"
              element={
                <PrivateRoute>
                  <DistributorLedgerReport />
                </PrivateRoute>
              }
            />

            <Route
              path="/claim-form"
              element={
                <PrivateRoute>
                  <ClaimForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/channel-list"
              element={
                <PrivateRoute>
                  <ChannelTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/channel-form"
              element={
                <PrivateRoute>
                  <ChannelForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/channel-form/:id"
              element={
                <PrivateRoute>
                  <ChannelForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-channel-list"
              element={
                <PrivateRoute>
                  <SubChannelTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-channel-form"
              element={
                <PrivateRoute>
                  <SubChannelForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/sub-channel-form/:id"
              element={
                <PrivateRoute>
                  <SubChannelForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/setting-key-form"
              element={
                <PrivateRoute>
                  <SettingKey />
                </PrivateRoute>
              }
            />
            <Route
              path="/configuration-pending-list"
              element={
                <PrivateRoute>
                  <ConfigurationPending />
                </PrivateRoute>
              }
            />
            <Route
              path="/outlet-moving-for-stockHolder"
              element={
                <PrivateRoute>
                  <OutletMovingForStockHolder />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = { closeNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Landing));
