import { Checkbox, FormHelperText, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import swal from "sweetalert";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import {
  getModuleAndSubModule,
  setModuleList,
} from "../../Slice/accessRole.slice";
import { getStockStakeHolderTypeAll } from "../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class UserAccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      stackHolderType: "",
      userType: "",
      stackHolderTypeName: "",
      accessRoleName: "",
      shortName: "",
      formError: {},
      error: false,
    };
  }

  handleClick = (e, name, moduleSubs, row) => {
    const { setModuleList, accessRole } = this.props;
    const moduleandSubModuleList = accessRole.moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const subModuleList = module.moduleSubs.map((submodule) => {
          if (submodule.subModuleId === moduleSubs.subModuleId) {
            submodule = {
              ...submodule,
              [name]: e.target.checked ? 1 : 0,
            };
            return submodule;
          }
          return submodule;
        });
        module = {
          ...module,
          moduleSubs: subModuleList,
        };
        return module;
      }
      return module;
    });
    setModuleList({ row: moduleandSubModuleList });
  };
  handleClickAll = (e, row) => {
    const { setModuleList, accessRole } = this.props;
    const moduleandSubModuleList = accessRole.moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const submoduleList = module.moduleSubs.map((submodule) => {
          submodule = {
            ...submodule,
            editReject: e.target.checked ? 1 : 0,
            addApproveConfig: e.target.checked ? 1 : 0,
            deleteRejectApprove: e.target.checked ? 1 : 0,
            view: e.target.checked ? 1 : 0,
          };
          return submodule;
        });
        module = {
          ...module,
          checked: e.target.checked,
          moduleSubs: submoduleList,
        };
        return module;
      }

      return module;
    });
    setModuleList({ row: moduleandSubModuleList });
  };
  async componentDidMount() {
    const {
      getModuleAndSubModule,
      getStockStakeHolderTypeAll,
      setModuleList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const moduleResp = await getModuleAndSubModule().then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { response, success };
        }
      );
      if (!this.props.params.id) {
        showLoador({ loador: true });
        await getStockStakeHolderTypeAll().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (this.props.params.id) {
        const { showLoador } = this.props;
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.userAccess + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                id: data.data.id,
                accessRoleName: data.data.roleName,
                shortName: data.data.roleShortName,
                stackHolderType:
                  data.data.stockStakeHolderType != null &&
                    data.data.stockStakeHolderType.id != null
                    ? data.data.stockStakeHolderType.id
                    : "",
                stackHolderTypeName:
                  data.data.stockStakeHolderType != null &&
                    data.data.stockStakeHolderType.name != null
                    ? data.data.stockStakeHolderType.name
                    : "",
                userType:
                  data.data.stockStakeHolderType != null &&
                    data.data.stockStakeHolderType.userType != null
                    ? data.data.stockStakeHolderType.userType.id
                    : "",
              });
              if (moduleResp.success) {
                const moduleListUpdated = moduleResp.response.map((module) => {
                  let filteredModuleList = data.data.modules.filter(
                    (moduleData) => moduleData.moduleId === module.moduleId
                  );
                  if (filteredModuleList.length !== 0) {
                    const moduleSubs = module.moduleSubs.map((moduleSubs) => {
                      let filteredSubModuleList =
                        filteredModuleList[0].moduleSubs.filter(
                          (moduleData) =>
                            moduleData.subModuleId === moduleSubs.subModuleId
                        );
                      if (filteredSubModuleList.length !== 0) {
                        moduleSubs = {
                          ...moduleSubs,
                          addApproveConfig:
                            filteredSubModuleList[0].addApproveConfig,
                          deleteRejectApprove:
                            filteredSubModuleList[0].deleteRejectApprove,
                          editReject: filteredSubModuleList[0].editReject,
                          view: filteredSubModuleList[0].view,
                        };
                        return moduleSubs;
                      }
                      return moduleSubs;
                    });
                    module = {
                      ...module,
                      checked: 0,
                      moduleSubs: moduleSubs,
                    };
                    return module;
                  }
                  return module;
                });
                setModuleList({ row: moduleListUpdated });
              }
            }
          }

          return success;
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  checkValidation = () => {
    const { accessRoleName, stackHolderType, shortName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (accessRoleName === "" || accessRoleName === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.accessRoleMsg;
    }

    if (shortName === "" || shortName === null) {
      formIsValid = false;
      formErrors["shortNameError"] = myConstClass.shortNameMsg;
    }
    if (stackHolderType === "" || stackHolderType === null) {
      formIsValid = false;
      formErrors["stackHolderTypeError"] = myConstClass.stackHolderTypeMsg;
    }
    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };
  submitHandler = () => {
    const { accessRole, showLoador, showNotification } = this.props;
    const {
      accessRoleName,
      stackHolderType,
      userType,
      shortName,
      formError,
      id,
      error,
    } = this.state;

    let moduleListToSave = [];
    accessRole.moduleList.map((module) => {
      let submoduleList = [];
      if (module.checked == 1) {
        module.moduleSubs.map((submodule) => {
          submoduleList.push(submodule);
        });
      } else {
        module.moduleSubs.map((submodule) => {
          if (
            submodule.addApproveConfig == 1 ||
            submodule.editReject == 1 ||
            submodule.deleteRejectApprove == 1 ||
            submodule.view == 1
          ) {
            submoduleList.push(submodule);
          }
        });
      }
      if (submoduleList.length !== 0) {
        module = {
          ...module,
          moduleSubs: submoduleList,
        };
        delete module.checked;
        moduleListToSave.push(module);
      }
    });
    let accessRoleToSave = {
      stockStakeHolderType: { id: stackHolderType },
      roleName: accessRoleName,
      roleShortName: shortName,
      modules: moduleListToSave,
    };
    if (id !== 0) {
      accessRoleToSave = {
        ...accessRoleToSave,
        id: id,
      };
    }
    if (this.checkValidation() && accessRoleToSave.modules.length !== 0) {
      if (navigator.onLine) {
        swal({
          title: "Are you sure?",
          text: myConstClass.saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.userAccess,
              postBody: accessRoleToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: true });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.props.navigate(-1);
              }
            });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else if (accessRole.moduleList.length === 0) {
      this.setState({
        error: true,
      });
    }
  };

  updateHandler = () => {
    const { accessRole, showLoador, showNotification } = this.props;
    const {
      accessRoleName,
      stackHolderType,
      userType,
      shortName,
      formError,
      id,
      error,
    } = this.state;
    let moduleListToSave = [];
    accessRole.moduleList.map((module) => {
      let submoduleList = [];
      if (module.checked == 1) {
        module.moduleSubs.map((submodule) => {
          submoduleList.push(submodule);
        });
      } else {
        module.moduleSubs.map((submodule) => {
          if (
            submodule.addApproveConfig == 1 ||
            submodule.editReject == 1 ||
            submodule.deleteRejectApprove == 1 ||
            submodule.view == 1
          ) {
            submoduleList.push(submodule);
          }
        });
      }
      if (submoduleList.length !== 0) {
        module = {
          ...module,
          moduleSubs: submoduleList,
        };
        delete module.checked;
        moduleListToSave.push(module);
      }
    });
    let accessRoleToUpdate = {
      stockStakeHolderType: { id: stackHolderType },
      roleName: accessRoleName,
      roleShortName: shortName,
      type: 1,
      modules: moduleListToSave,
    };
    if (id !== 0) {
      accessRoleToUpdate = {
        ...accessRoleToUpdate,
        id: id,
      };
    }
    if (this.checkValidation() && accessRoleToUpdate.modules.length !== 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiPost({
          url:
            userType == "1" || userType == "6"
              ? endpoint.userAccess + "/update-with-devoloper-admin-user"
              : endpoint.userAccess + "/update-with-stock-holder",
          postBody: accessRoleToUpdate,
        }).then(({ data, success }) => {
          showLoador({ loador: true });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            showNotification({ msg: myConstClass.updateMsg });
            this.props.navigate(-1);
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else if (accessRole.moduleList.length === 0) {
      this.setState({
        error: true,
      });
    }
  };

  submitAndUpdateHandler = () => {
    swal({
      title: "Are you sure?",
      text: myConstClass.updateData,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.updateHandler();
      }
    });
  };

  goBack = () => {
    this.props.navigate(-1);
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  render() {
    const { accessRole, stockStakeHolderType } = this.props;
    const {
      accessRoleName,
      shortName,
      formError,
      stackHolderType,
      stackHolderTypeName,
      error,
      id,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              xs={12}
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={this.goBack}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              spacing={1}
              justifyContent="space-evenly"
            >
              {" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" *Stock Holder Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {id == 0 ? (
                    <AutoComplete
                      keyColName={"id"}
                      name={"stackHolderType"}
                      value={stackHolderType}
                      options={stockStakeHolderType.stockStakeHolderTypeAll}
                      placeHolder="Stock Holder Type"
                      onChange={this.onAutocompleteChange}
                      isError={formError.stackHolderTypeError ? true : false}
                      errorText={
                        formError.stackHolderTypeError
                          ? formError.stackHolderTypeError
                          : " "
                      }
                    />
                  ) : (
                    <TextFieldCompo
                      color="primary"
                      name="stackHolderType"
                      placeHolder="Stock Holder Type"
                      size="small"
                      value={stackHolderTypeName}
                      disabled={true}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Access Role Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextFieldCompo
                    color="primary"
                    name="accessRoleName"
                    placeHolder="Access Role Name"
                    size="small"
                    value={accessRoleName}
                    onChange={this.changeHandler}
                    errorText={formError.nameError ? formError.nameError : ""}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Short Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextFieldCompo
                    color="primary"
                    name="shortName"
                    placeHolder="Short Name"
                    size="small"
                    value={shortName}
                    onChange={this.changeHandler}
                    errorText={
                      formError.shortNameError ? formError.shortNameError : ""
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid>
          <br />
          <FormHelperText error>
            {error ? "*Please select atlest one checkbox" : ""}
          </FormHelperText>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Sr. No.</TableCell>
                  <TableCell>Module Name</TableCell>
                  <TableCell align="center" width="10%">
                    Add
                  </TableCell>
                  <TableCell align="center" width="10%">
                    Edit
                  </TableCell>
                  <TableCell align="center" width="10%">
                    Delete
                  </TableCell>
                  <TableCell align="center" width="10%">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessRole.moduleList.map((row, index) => (
                  <>
                    <TableRow key={row.name}>
                      <TableCell align="center">
                        {index + 1}
                        <Checkbox
                          color="primary"
                          onClick={(event) => this.handleClickAll(event, row)}
                          checked={row.checked ? true : false}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" colSpan={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, fontWeight: "bold" }}
                          label={row.moduleName}
                        />
                      </TableCell>
                    </TableRow>
                    {row.moduleSubs.map((submoduleData, index) => {
                      return (
                        <TableRow key={submoduleData.name}>
                          <TableCell align="center"></TableCell>
                          <TableCell component="th" scope="row">
                            &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                            {submoduleData.subModuleName}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "addApproveConfig",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={
                                submoduleData.addApproveConfig ? true : false
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "editReject",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={submoduleData.editReject ? true : false}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "deleteRejectApprove",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={
                                submoduleData.deleteRejectApprove ? true : false
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "view",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={submoduleData.view ? true : false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
            &nbsp;&nbsp;
            {!id == 0 && (
              <ButtonCompo
                size="button"
                type="button"
                variant="contained"
                name="Submit and Update"
                onClick={this.submitAndUpdateHandler}
              />
            )}
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accessRole: state.accessRole,
  stockStakeHolderType: state.stockStakeHolderType,
});
const mapDispatchToProps = {
  getModuleAndSubModule,
  showLoador,
  showNotification,
  setModuleList,
  getStockStakeHolderTypeAll,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserAccessForm);
