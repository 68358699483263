import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getActiveVehicleNoListByStockholder } from "../../Slice/veichleSlice.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { blackColor, checkboxShadowColor, cyanBlue, redColor, titleColor, yellowColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  cdMsg,
  discountPercentageErrorMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  vehicleNoMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { pink } from "@mui/material/colors";
import OfferDetailsOnBillingPreview from "./OfferDetailsOnBillingPreview";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class OrderBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dataList: [],
      vehicleNo: "",
      vehicleInfo: "",
      name: "",
      formErrors: {},
      stockChangedList: [],
      checkboxAllData: [],
      offerDetailPopUpFlag: false,
      orderOfferId: "",
    };
  }

  componentDidMount() {
    const { getActiveVehicleNoListByStockholder, offerBillOutletData, stockFlag } =
      this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });

      getActiveVehicleNoListByStockholder().then(({ success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: false });


      const stockCurrentList = localStorage.getItem("stockList");
      const valuesArray = JSON.parse(stockCurrentList);


      this.setState({
        dataList: offerBillOutletData,
      });

      if (stockFlag) {
        let i;
        let j;
        let stockDummyNewList = valuesArray;
        for (i = 0; i < stockDummyNewList.length; i++) {
          offerBillOutletData.map((billObject) => {
            for (
              j = 0;
              j <
              billObject.outletBillsAfterApplyingOffers.outletBillDetails.length;
              j++
            ) {
              if (
                stockDummyNewList[i].productId ==
                billObject.outletBillsAfterApplyingOffers.outletBillDetails[j]
                  .product.id
              ) {
                stockDummyNewList[i].stock =
                  stockDummyNewList[i].stock -
                  billObject.outletBillsAfterApplyingOffers.outletBillDetails[j]
                    .freeQty;
              }
            }
          });
        }
        this.setState({ stockChangedList: stockDummyNewList });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  checkValidation = () => {
    const { vehicleNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (vehicleNo === "") {
      formIsValid = false;
      formErrors["vehicleNoError"] = vehicleNoMsg;
    }


    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  onCancelAfterSubmit = () => {
    const { onCancel } = this.props;

    onCancel();
  };



  onChangeTextFieldItemDiscount = (dataKey, id) => (event) => {
    const { dataList } = this.state;
    const { name, value } = event.target;
    console.log("value");
    console.log(value);
    const onChangeList = dataList.map((dataObj) => {
      if (
        Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
        dataObj.outletBillsAfterApplyingOffers.outletBillDetails.length !== 0
      ) {
        const updatedDetails = dataObj.outletBillsAfterApplyingOffers.outletBillDetails.map(
          (detailsObj) => {
            if (id === detailsObj.id && value <= 100) {
              let newRate =
                (detailsObj.rate * 100) /
                (100 +
                  detailsObj.sgstPer +
                  detailsObj.cgstPer +
                  detailsObj.igstPer);

              let newTaxableAmt = newRate * detailsObj.qty;

              let offerDiscountAmt = detailsObj.discountOnBillOfferAmt;

              let offerDiscoutPer = detailsObj.discountOnBillOfferPer;

              let newTaxableOfferAmt = newTaxableAmt - offerDiscountAmt;

              let newItemWiseDiscount = (newTaxableOfferAmt / 100) * value;

              let newTaxableWithItemWiseDiscount =
                newTaxableOfferAmt - newItemWiseDiscount;

              let newBillWiseDiscount =
                (newTaxableWithItemWiseDiscount / 100) *
                dataObj.outletBillsAfterApplyingOffers.discountOnBillPer;

              let newTaxableWithBillWiseDiscount =
                newTaxableWithItemWiseDiscount - newBillWiseDiscount;

              let newSgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.sgstPer;

              let newCgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.cgstPer;

              let newIgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.igstPer;
              return {
                ...detailsObj,
                cgstAmt: newSgstAmt,
                sgstAmt: newCgstAmt,
                igstAmt: newIgstAmt,
                itemDiscAmt: newItemWiseDiscount,
                taxableAmt: newTaxableWithBillWiseDiscount,
                totalAmt:
                  newTaxableWithBillWiseDiscount +
                  newSgstAmt +
                  newCgstAmt +
                  newIgstAmt,
                [name]: value,
              };
            }
            return detailsObj;
          }
        );

        console.log("updatedDetailsItemDis");
        console.log(updatedDetails);
        return {
          ...dataObj,
          outletBillsAfterApplyingOffers: {
            ...dataObj.outletBillsAfterApplyingOffers,
            taxableAmt: updatedDetails.reduce(
              (sum, row) => sum + row.taxableAmt,
              0
            ),
            totalAmt: updatedDetails.reduce(
              (sum, row) => sum + row.totalAmt,
              0
            ),
            cgstAmt: updatedDetails.reduce((sum, row) => sum + row.cgstAmt, 0),
            sgstAmt: updatedDetails.reduce((sum, row) => sum + row.sgstAmt, 0),
            igstAmt: updatedDetails.reduce((sum, row) => sum + row.igstAmt, 0),
            itemDistcountAmt: updatedDetails.reduce(
              (sum, row) => sum + row.itemDiscAmt,
              0
            ),
            outletBillDetails: updatedDetails,
          },
        };
      }
      return dataObj;
    });


    console.log("onChangeListItemDis");
    console.log(onChangeList);

    this.setState({
      dataList: onChangeList,
    });
  };

  changeHandlerTextFieldBillDiscount = (dataKey, id) => (event) => {
    const { dataList } = this.state;
    const { name, value } = event.target;

    const onChangeList = dataList.map((dataObj) => {
      if (Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0) {
        if (id === dataObj.outletBillsAfterApplyingOffers.id && value <= 100) {
          const updatedDetails = dataObj.outletBillsAfterApplyingOffers.outletBillDetails.map(
            (detailsObj) => {
              let newRate =
                (detailsObj.rate * 100) /
                (100 +
                  detailsObj.sgstPer +
                  detailsObj.cgstPer +
                  detailsObj.igstPer);

              let newTaxableAmt = newRate * detailsObj.qty;

              let offerDiscountAmt = detailsObj.discountOnBillOfferAmt;

              let offerDiscoutPer = detailsObj.discountOnBillOfferPer;

              let newTaxableOfferAmt = newTaxableAmt - offerDiscountAmt;

              let newItemWiseDiscount =
                (newTaxableOfferAmt / 100) * detailsObj.itemDiscPer;

              let newTaxableWithItemWiseDiscount =
                newTaxableOfferAmt - newItemWiseDiscount;

              let newBillWiseDiscount =
                (newTaxableWithItemWiseDiscount / 100) * value;

              let newTaxableWithBillWiseDiscount =
                newTaxableWithItemWiseDiscount - newBillWiseDiscount;

              let newSgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.sgstPer;

              let newCgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.cgstPer;

              let newIgstAmt =
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.igstPer;
              return {
                ...detailsObj,
                cgstAmt: newSgstAmt,
                sgstAmt: newCgstAmt,
                igstAmt: newIgstAmt,
                // itemDiscAmt:
                //   (newTaxableWithBillWiseDiscount / 100) *
                //   detailsObj.itemDiscPer,
                itemDiscAmt: newItemWiseDiscount,
                discountOnBillAmt: newBillWiseDiscount,
                taxableAmt: newTaxableWithBillWiseDiscount,
                totalAmt:
                  newTaxableWithBillWiseDiscount +
                  newSgstAmt +
                  newCgstAmt +
                  newIgstAmt,
              };
            }
          );
          return {
            ...dataObj,
            outletBillsAfterApplyingOffers: {
              ...dataObj.outletBillsAfterApplyingOffers,
              [name]: value,
              taxableAmt: updatedDetails.reduce(
                (sum, row) => sum + row.taxableAmt,
                0
              ),
              totalAmt: updatedDetails.reduce(
                (sum, row) => sum + row.totalAmt,
                0
              ),
              cgstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.cgstAmt,
                0
              ),
              sgstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.sgstAmt,
                0
              ),
              igstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.igstAmt,
                0
              ),
              itemDistcountAmt: updatedDetails.reduce(
                (sum, row) => sum + row.itemDiscAmt,
                0
              ),
              discountOnBillAmt: updatedDetails.reduce((sum, row) => sum + row.discountOnBillAmt, 0),
              outletBillDetails: updatedDetails,
            },
          };
        }
      }
      return dataObj;
    });

    console.log("onChangeListBillDis");
    console.log(onChangeList);

    this.setState({
      dataList: onChangeList,
    });
  };

  changeHandler = (e, value) => {
    const selectedVehicles = this.props.vehicleList?.vehicle.filter(
      (row) => row.id === value
    );
    if (selectedVehicles && selectedVehicles.length > 0) {
      const selectedVehicle = selectedVehicles[0];
      const { name } = selectedVehicle;
      this.setState({
        vehicleNo: value,
        vehicleInfo: name,
      });
    }
  };


  clickSubmitHandler = () => {
    const { checkboxAllData, dataList, billOfferId, stockChangedList } =
      this.state;
    if (dataList.length !== 0) {

      let dataToSave = [];
      dataList.map((dataObj) => {
        if (
          Object.keys(dataObj.outletBillHeader).length !== 0 &&
          dataObj.outletBillHeader.id == billOfferId
        ) {
          let billCheckedOfferData = [];
          if (dataObj.offerHeaders.length !== 0) {
            dataObj.offerHeaders.map((offerData) => {
              if (checkboxAllData.length !== 0) {
                checkboxAllData.map((checkedData) => {
                  if (checkedData.id == offerData.id) {
                    billCheckedOfferData.push(checkedData);
                  }
                });
              }
            });
          }


          let billObjectData = {
            outletBillHeader: dataObj.outletBillHeader,
            outletOrderHerader: dataObj.outletOrderHerader,
            outletOrderHeraderWithApplyingOffers: dataObj.outletOrderHeraderWithApplyingOffers,
            outletBillsAfterApplyingOffers: dataObj.outletBillsAfterApplyingOffers,
            offerHeaders: billCheckedOfferData,
            productAndStocks: stockChangedList,
          };
          dataToSave.push(billObjectData);
        }
      });

      this.onSave(dataToSave[0]);
    }
  };

  onSave = (dataToSave) => {
    console.log(" offer submit json");
    console.log(dataToSave);
    const { dataList, openDialog } = this.state;
    const { showLoador, showNotification, stockFlag } = this.props;
    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);


    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.outletBillHeader + "/generate-with-apply-offer",
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {

            let newOfferData = dataList.map((offerNewObject) => {
              if (
                offerNewObject.outletBillHeader.id == data.data.outletBillHeader.id
              ) {
                return data.data;
              } else {
                return offerNewObject;
              }
            });


            if (stockFlag) {
              let i;
              let j;
              let stockOriginalDummyList = valuesArray;
              for (i = 0; i < stockOriginalDummyList.length; i++) {
                newOfferData.map((offerObject) => {
                  for (
                    j = 0;
                    j <
                    offerObject.outletBillsAfterApplyingOffers.outletBillDetails
                      .length;
                    j++
                  ) {
                    if (
                      stockOriginalDummyList[i].productId ==
                      offerObject.outletBillsAfterApplyingOffers.outletBillDetails[
                        j
                      ].product.id
                    ) {
                      stockOriginalDummyList[i].stock =
                        stockOriginalDummyList[i].stock -
                        offerObject.outletBillsAfterApplyingOffers
                          .outletBillDetails[j].freeQty;
                    }
                  }
                });
              }
              this.setState({ stockChangedList: stockOriginalDummyList });
            }



            let newObjectToSave = newOfferData.map((billObject) => {
              let newBillObject = {
                ...billObject.outletBillsAfterApplyingOffers,
                outletBillDetails:
                  billObject.outletBillsAfterApplyingOffers.outletBillDetails.map(
                    (orderObject) => {
                      let productWithoutBillObject =
                        billObject.outletBillHeader.outletBillDetails.filter(
                          (row) => row.id == orderObject.id
                        );
                      if (productWithoutBillObject.length !== 0) {
                        orderObject = {
                          ...orderObject,
                          taxableAmtWithoutOffer:
                            productWithoutBillObject[0].taxableAmt,
                          totalAmtWithoutOffer:
                            productWithoutBillObject[0].totalAmt,
                        };
                        return orderObject;
                      } else {
                        orderObject = {
                          ...orderObject,
                          taxableAmtWithoutOffer: 0.0,
                          totalAmtWithoutOffer: 0.0,
                        };
                        return orderObject;
                      }
                    }
                  ),
              };
              billObject = {
                ...billObject,
                outletBillsAfterApplyingOffers: newBillObject,
              };
              return billObject;
            });
            showNotification({ msg: savemsg });


            console.log("newObjectToSave");
            console.log(newObjectToSave);

            this.setState({
              dataList: newObjectToSave,
              openDialog: !openDialog,
            });
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        });
      }
    });
  };



  onCheckBoxClick = (checkedValue, headerId) => {
    const { checkboxAllData, dataList } = this.state;
    let checkboxData = [];
    if (dataList.length !== 0) {
      dataList.map((offerData) => {
        if (
          Object.keys(offerData.outletBillsAfterApplyingOffers).length !== 0 &&
          offerData.outletBillsAfterApplyingOffers.id === headerId
        ) {
          checkboxAllData.map((allOffer) => {
            if (
              allOffer.level == 0 &&
              allOffer.type == 0 &&
              checkedValue.level == 0 &&
              checkedValue.type == 0
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 0,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 0 &&
              allOffer.type == 1 &&
              checkedValue.level == 0 &&
              checkedValue.type == 1
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 0,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 1 &&
              allOffer.type == 0 &&
              checkedValue.level == 1 &&
              checkedValue.type == 0
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 1 &&
              allOffer.type == 1 &&
              checkedValue.level == 1 &&
              checkedValue.type == 1
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied,
                };
                checkboxData.push(allOfferData);
              }
            } else {
              checkboxData.push(allOffer);
            }
          });
        }
      });
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  clickHandler = (billId) => {
    console.log(billId);
    const { openDialog, dataList } = this.state;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (dataList.length !== 0) {
      dataList.map((offerData) => {
        if (
          Object.keys(offerData.outletBillsAfterApplyingOffers).length !== 0 &&
          offerData.outletBillsAfterApplyingOffers.id == billId &&
          offerData.offerHeaders.length !== 0
        ) {
          offerData.offerHeaders.map((allOffer) => {
            let allOfferData = {
              ...allOffer,
              appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
            };
            checkboxData.push(allOfferData);
          });
        }
      });
    }

    console.log(checkboxData);
    this.setState({
      checkboxAllData: checkboxData,
    });
  };


  clickSubmitOfferHandler = () => {
    const { showLoador, showNotification, onCancel } = this.props;
    const { dataList, vehicleInfo, stockChangedList } = this.state;


    console.log(dataList);

    if (this.checkValidation()) {
      let billHeaderListToSave = [];
      let outletBillHeader = {};
      let discountOnBillPerTotal = 0;
      let billDate = "";

      dataList.map((dataObj) => {
        (outletBillHeader = {
          outletOrderHerader: dataObj.outletOrderHerader,
          outletOrderHeraderWithApplyingOffers: dataObj.outletOrderHeraderWithApplyingOffers,
          outletBillsAfterApplyingOffers: dataObj.outletBillsAfterApplyingOffers,
          offerHeaders: dataObj.offerHeaders,
          outletBillHeader: {
            ...dataObj.outletBillHeader,
            discountOnBillPer: parseFloat(dataObj.outletBillsAfterApplyingOffers.discountOnBillPer),
            outletBillDetails:
              dataObj.outletBillHeader.outletBillDetails.length != 0 &&
              dataObj.outletBillHeader.outletBillDetails.map(
                (billDetailsObject, index) => {
                  billDetailsObject = {
                    ...billDetailsObject,
                    itemDiscPer: dataObj.outletBillsAfterApplyingOffers.outletBillDetails[index]?.itemDiscPer,
                  };
                  return billDetailsObject;
                }
              ),
            vehicleNo: vehicleInfo,
          },
          productAndStocks: stockChangedList,
          vehicleNo: vehicleInfo,
        }),
          billHeaderListToSave.push(outletBillHeader);
      });
      console.log(billHeaderListToSave);

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiPost({
            url:
              endpoint.outletBillHeader +
              "/save-list-after-appying-offers-and-discount",
            postBody: billHeaderListToSave,
          }).then(({ data, success }) => {
            if (success) {
              showNotification({ msg: savemsg });
              this.onCancelAfterSubmit();
              window.location.reload();
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            showLoador({ loador: false });
          });
        }
      });
    }
  };


  offerHandleCellClick = (data) => {
    this.setState({ orderOfferId: data.id, offerDetailPopUpFlag: true })
  }


  toggle = () => {
    this.setState({ offerDetailPopUpFlag: false })
  }

  render() {
    const { vehicleNo, formErrors, dataList, checkboxAllData, openDialog, offerDetailPopUpFlag, orderOfferId } =
      this.state;
    const { vehicleList, onCancel } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 1.5,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />

          {dataList.length !== 0 &&
            dataList.map((dataObj, index) => {
              { console.log("dataObj") }
              { console.log(dataObj) }
              return (
                <>
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "No",
                        value: index + 1,
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Outlet Name",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.outLet &&
                            dataObj.outletBillsAfterApplyingOffers.outLet.firmName !== null
                            ? dataObj.outletBillsAfterApplyingOffers.outLet.firmName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Owner Name",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.outLet &&
                            dataObj.outletBillsAfterApplyingOffers.outLet.ownerName !== null
                            ? dataObj.outletBillsAfterApplyingOffers.outLet.ownerName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill No",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.billNo !== null
                            ? dataObj.outletBillsAfterApplyingOffers.billNo
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Taxable Amount",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.taxableAmt !== null
                            ? dataObj.outletBillsAfterApplyingOffers.taxableAmt.toFixed(2)
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Total Tax",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.sgstAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.cgstAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.igstAmt !== null
                            ? (
                              dataObj.outletBillsAfterApplyingOffers.sgstAmt +
                              dataObj.outletBillsAfterApplyingOffers.cgstAmt +
                              dataObj.outletBillsAfterApplyingOffers.igstAmt
                            ).toFixed(2)
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill Discount Amt",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.discountOnBillAmt !== null
                            ? dataObj.outletBillsAfterApplyingOffers.discountOnBillAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Item Discount Amt",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.itemDistcountAmt !== null
                            ? dataObj.outletBillsAfterApplyingOffers.itemDistcountAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Offer Discount Amt",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.discountOnBillOfferAmt !== null
                            ? dataObj.outletBillsAfterApplyingOffers.discountOnBillOfferAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill Amount",
                        value:
                          dataObj.outletBillsAfterApplyingOffers &&
                            Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                            dataObj.outletBillsAfterApplyingOffers.taxableAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.sgstAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.cgstAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.igstAmt !== null &&
                            dataObj.outletBillsAfterApplyingOffers.discountOnBillOfferAmt !== null
                            ? (
                              dataObj.outletBillsAfterApplyingOffers.taxableAmt +
                              dataObj.outletBillsAfterApplyingOffers.sgstAmt +
                              dataObj.outletBillsAfterApplyingOffers.cgstAmt +
                              dataObj.outletBillsAfterApplyingOffers.igstAmt
                            ).toFixed(2)
                            : "",
                      },
                    ]}
                  />


                  {Object.keys(dataObj).length !== 0 &&
                    dataObj.offerHeaders.length !== 0 && (
                      <>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          justifyContent="flex-end"
                        >
                          <ButtonCompo
                            sx={{
                              bgcolor: "dodgerblue", // theme.palette.primary.main
                              color: yellowColor,
                              opacity: "0.9",
                              transform: "scale(0.9)",
                              ":hover": {
                                display: "inline-block",
                                bgcolor: yellowColor, // theme.palette.primary.main
                                color: "dodgerblue",
                                opacity: "0.9",
                                transform: "scale(1.5)",
                                transitionDuration: "3s",
                                backfaceVisibility: "hidden",
                                transform:
                                  "translate(2px, 2px) rotate(0deg)  scale(2)",
                                config: {
                                  tension: "300",
                                  friction: "10",
                                },
                              },
                            }}
                            size="medium"
                            type="button"
                            variant="contained"
                            name={"Offer"}
                            onClick={(e) =>
                              this.clickHandler(
                                dataObj.outletBillsAfterApplyingOffers.id
                              )
                            }
                            fullWidth={false}
                          />
                        </Grid>
                      </>
                    )}

                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ width: "5%" }}>
                            Sr. No
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Product Name
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Quantity
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Free Quantity
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Tax (%)
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Tax Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Discount (%)
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Discount Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Taxable Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Total Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <>
                        {Object.keys(dataObj.outletBillsAfterApplyingOffers).length !== 0 &&
                          dataObj.outletBillsAfterApplyingOffers.outletBillDetails.length !==
                          0 &&
                          dataObj.outletBillsAfterApplyingOffers.outletBillDetails.map(
                            (detailObj, index) => (
                              <TableRow key={index}>
                                <TableCell align="center" sx={{ width: "5%" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                  {detailObj.product.name}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {detailObj.qty}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {detailObj.freeQty}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {(
                                    detailObj.cgstPer +
                                    detailObj.sgstPer +
                                    detailObj.igstPer
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {(
                                    detailObj.cgstAmt +
                                    detailObj.sgstAmt +
                                    detailObj.igstAmt
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  <TextFieldCompo
                                    placeHolder={"Discount %"}
                                    size="small"
                                    color="primary"
                                    name="itemDiscPer"
                                    id={"1"}
                                    value={detailObj.itemDiscPer}
                                    onChange={this.onChangeTextFieldItemDiscount(
                                      "itemDiscPer",
                                      detailObj.id
                                    )}
                                    fullWidth={false}
                                    disabled={detailObj.freeQty > 0 ? true : false}
                                    errorText={detailObj.itemDiscPer > 100 ? discountPercentageErrorMsg : ""}
                                  />
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.itemDiscAmt.toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.taxableAmt.toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.totalAmt.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </>
                    </Table>
                    {Object.keys(dataObj.outletBillsAfterApplyingOffers)
                      .length !== 0 &&
                      dataObj.outletBillsAfterApplyingOffers.appliedOffers
                        .length !== 0 && (
                        <>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ width: "5.5%" }}
                                >
                                  #
                                </TableCell>
                                <TableCell align="center" sx={{ width: "80%" }}>
                                  Offer Name
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {dataObj.outletBillsAfterApplyingOffers
                              .appliedOffers.length !== 0 &&
                              dataObj.outletBillsAfterApplyingOffers.appliedOffers.map(
                                (offerAppliedData, index) => {
                                  return (
                                    <>
                                      <TableRow>
                                        <TableCell align="center">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{ width: "80%", cursor: "pointer", color: cyanBlue }}
                                          onClick={() => this.offerHandleCellClick(offerAppliedData)}
                                        >
                                          {offerAppliedData !== null
                                            ? offerAppliedData.name
                                            : ""}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                }
                              )}
                          </Table>
                        </>
                      )}
                  </TableContainer>
                  <br />
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={8}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-between"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label="CD :"
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <TextFieldCompo
                          placeHolder={"Enter CD"}
                          color="primary"
                          name="discountOnBillPer"
                          value={dataObj.outletBillsAfterApplyingOffers.discountOnBillPer}
                          fullWidth
                          onChange={this.changeHandlerTextFieldBillDiscount(
                            "discountOnBillPer",
                            dataObj.outletBillsAfterApplyingOffers.id
                          )}
                          errorText={dataObj.outletBillsAfterApplyingOffers.discountOnBillPer > 100 ? discountPercentageErrorMsg : dataObj.outletBillHeader.discountOnBillPer === "" ? cdMsg : ""}

                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          <br />
          <br />
          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Vehicle No :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <AutoComplete
                  keyColName={"id"}
                  placeHolder="Select Vehicle No"
                  value={vehicleNo}
                  name={"vehicleNo"}
                  options={vehicleList?.vehicle}
                  errorText={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  isError={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  onChange={this.changeHandler}
                />
              </Grid>
            </Grid>
          </Grid>





          {openDialog && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.5rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: yellowColor,
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}


          {offerDetailPopUpFlag && (
            <OfferDetailsOnBillingPreview
              orderOfferId={orderOfferId}
              onClose={this.toggle}
            />
          )}

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: yellowColor,
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleList: state.vehicle,
});
const mapDispatchToProps = {
  getActiveVehicleNoListByStockholder,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderBilling);
