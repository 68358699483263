import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { AddDistributorJson } from "../../DynamicFormsJson/Transaction/addDistributor";

import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
    serverMsg,
    noInternetMsg,
    saveWarningMsg,
    saveFailedMsg,
    savemsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { apiGet } from "../../utils/api_service";
import dayjs from "dayjs";
import { saveDistributorInquiry, setSaveDistributorInquiry } from "../../Slice/distributorInquiry.slice";


class OtherDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldData: {},
            dynamicMasterData: {
                locationDetail: this.props.accountLedger?.location,
                accountLedgerGroupMasterDetail:
                    this.props.accountLedger?.accountLedgerGroupMaster,
            },
        };
    }


    onSubmitData = (dataToSave) => {
        console.log(dataToSave);

        const { distributorInquiryList } = this.props;

        dataToSave = {
            ...distributorInquiryList.distributorInquiryData,
            ...dataToSave,
        };


        swal({
            title: "Are you sure?",
            text: msgConstant.saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(dataToSave);
            }
        });
    };

    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveDistributorInquiry } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveDistributorInquiry({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: msgConstant.savemsg,
                    });
                    this.props.changeTab("3");

                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getDataById = (id) => {
        const { showLoador, setSaveDistributorInquiry, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: endpoint.distributorInquiry + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;
                        setSaveDistributorInquiry({ rowObject: rowData });

                        return {
                            data: rowData,
                            success,
                        };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false };
                }
                return { response: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicFormWithoutSave
                    formPath={AddDistributorJson.formPath}
                    fieldMeta={AddDistributorJson.fieldMetaForOther}
                    tableColumnsToFilter={AddDistributorJson.tableColumnsToFilter}
                    apiBaseURL={AddDistributorJson.apiBaseURL}
                    baseIdColumn={AddDistributorJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtnMain={false}
                    showSaveBtn={true}
                    saveBtnText={"Next"}
                    onSave={this.onSubmitData}
                    callApi={true}
                    getByIdApi={true}
                    getDataById={this.getDataById}
                    getListById={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    distributorInquiryList: state.distributorInquiry,
});
const mapDispatchToProps = {
    saveDistributorInquiry,
    setSaveDistributorInquiry,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(OtherDetails);
