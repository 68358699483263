import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import {
  blackColor,
  checkboxShadowColor,
  redColor,
  titleColor,
  yellowColor,
} from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  vehicleNoMsg,
} from "../../config/messageconstant";
import { getActiveVehicleNoListByStockholder } from "../../Slice/veichleSlice.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import PopUpForPdfAfterSubmit from "./PopUpForPdfAfterSubmit";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingWithOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      checkboxAllData: [],
      offerBillNewData: {},
      outletBillHeaderData: {},
      billOfferId: "",
      outletHeader: "",
      vehicleInfo: "",
      stockChangedList: [],
      companyFlagTwo: false,
      vehicleNo: "",
      name: "",
      formErrors: {},
      openPdfPopUpFlag: false,
      pdfData: "",
    };
  }
  componentDidMount() {
    const { offerBillOutletData, flag, getActiveVehicleNoListByStockholder } =
      this.props;

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");
    console.log(showCompanyFlag);
    if (showCompanyFlag == 2) {
      this.setState({ companyFlagTwo: true });
    }

    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    if (flag) {
      let i;
      let j;
      let stockDummyNewList = valuesArray;
      for (i = 0; i < stockDummyNewList.length; i++) {
        for (
          j = 0;
          j < offerBillOutletData.outletBillHeader.outletBillDetails.length;
          j++
        ) {
          if (
            stockDummyNewList[i].productId ==
            offerBillOutletData.outletBillHeader.outletBillDetails[j].product.id
          ) {
            stockDummyNewList[i].stock =
              stockDummyNewList[i].stock -
              offerBillOutletData.outletBillHeader.outletBillDetails[j].freeQty;
          }
        }
      }
      this.setState({ stockChangedList: stockDummyNewList });
    }
    this.setState({
      offerBillNewData: offerBillOutletData,
      outletBillHeaderData: offerBillOutletData.outletBillHeader,
      outletHeader: offerBillOutletData.outletOrderHerader,
    });

    showLoador({ loador: true });
    getActiveVehicleNoListByStockholder().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  }

  clickSubmitOfferHandler = () => {
    const { offerBillNewData, outletHeader, name } = this.state;
    const { salesPersonId, showLoador, showNotification } = this.props;
    let offerBillNewDataJSON = {
      ...offerBillNewData.outletBillsAfterApplyingOffers,
      vehicleNo: name,
    };
    let dataToSave = {
      outletBillsAfterApplyingOffers: offerBillNewDataJSON,
      outletOrderHerader: outletHeader,
    };

    console.log("dataToSave")
    console.log(dataToSave)

    if (this.checkValidation()) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {

        if (willDelete) {
          showLoador({ loador: true });
          apiPost({
            url:
              endpoint.manualOrder +
              "/save-after-appying-offers?salesPersonId=" +
              salesPersonId,
            postBody: dataToSave,
          }).then(({ data, success }) => {
            showLoador({ loador: false });

            if (success) {
              showNotification({ msg: savemsg });
              this.setState({ openPdfPopUpFlag: true, pdfData: data.data });
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        }
      });
    }
  };

  clickSubmitHandler = () => {
    const { checkboxAllData, offerBillNewData, billOfferId, stockChangedList } =
      this.state;
    const { offerBillOutletData } = this.props;

    if (offerBillNewData.length !== 0) {
      let dataToSave = [];
      if (
        Object.keys(offerBillNewData.outletBillHeader).length !== 0 &&
        offerBillNewData.outletBillHeader.id == billOfferId
      ) {
        let billCheckedOfferData = [];
        if (offerBillNewData.offerHeaders.length !== 0) {
          offerBillNewData.offerHeaders.map((offerData) => {
            if (checkboxAllData.length !== 0) {
              checkboxAllData.map((checkedData) => {
                if (checkedData.id == offerData.id) {
                  billCheckedOfferData.push(checkedData);
                }
              });
            }
          });
        }
        let billObjectData = {
          outletBillHeader: offerBillNewData.outletBillHeader,
          offerHeaders: billCheckedOfferData,
          productAndStocks: stockChangedList,
        };
        dataToSave.push(billObjectData);
      }
      this.onSave(dataToSave[0]);
    }
  };

  onSave = (dataToSave) => {
    const { offerBillNewData, openDialog } = this.state;
    const { flag, showLoador, showNotification } = this.props;
    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    showLoador({ loador: true });
    apiPost({
      url: endpoint.outletBillHeader + "/apply-offer",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (flag) {
          let i;
          let j;
          let stockDummyNewList = valuesArray;
          for (i = 0; i < stockDummyNewList.length; i++) {
            for (
              j = 0;
              j < data.data.outletBillHeader.outletBillDetails.length;
              j++
            ) {
              if (
                stockDummyNewList[i].productId ==
                data.data.outletBillHeader.outletBillDetails[j].product.id
              ) {
                stockDummyNewList[i].stock =
                  stockDummyNewList[i].stock -
                  data.data.outletBillHeader.outletBillDetails[j].freeQty;
              }
            }
          }
          this.setState({ stockChangedList: stockDummyNewList });
        }

        let newObjectToSave = {
          ...data.data.outletBillsAfterApplyingOffers,
          outletBillDetails:
            data.data.outletBillsAfterApplyingOffers.outletBillDetails.map(
              (orderObject) => {
                let productWithoutBillObject =
                  data.data.outletBillHeader.outletBillDetails.filter(
                    (row) => row.id == orderObject.id
                  );
                if (productWithoutBillObject.length !== 0) {
                  orderObject = {
                    ...orderObject,
                    taxableAmtWithoutOffer:
                      productWithoutBillObject[0].taxableAmt,
                    totalAmtWithoutOffer: productWithoutBillObject[0].totalAmt,
                  };
                  return orderObject;
                } else {
                  orderObject = {
                    ...orderObject,
                    taxableAmtWithoutOffer: 0.0,
                    totalAmtWithoutOffer: 0.0,
                  };
                  return orderObject;
                }
              }
            ),
        };
        let newData = {
          ...data.data,
          outletBillsAfterApplyingOffers: newObjectToSave,
        };
        this.setState({
          offerBillNewData: newData,
          openDialog: !openDialog,
        });
      } else {
        showNotification({
          msg: saveFailedMsg,
          severity: "error",
        });
      }
    });
  };

  onCheckBoxClick = (checkedValue, headerId) => {
    const { checkboxAllData, offerBillNewData } = this.state;
    const { offerBillOutletData } = this.props;

    let checkboxData = [];
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.outletBillsAfterApplyingOffers).length !==
        0 &&
        offerBillNewData.outletBillsAfterApplyingOffers.id === headerId
      ) {
        checkboxAllData.map((allOffer) => {
          if (
            allOffer.level == 0 &&
            allOffer.type == 0 &&
            checkedValue.level == 0 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 0 &&
            allOffer.type == 1 &&
            checkedValue.level == 0 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 0 &&
            checkedValue.level == 1 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 1 &&
            checkedValue.level == 1 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
              };
              checkboxData.push(allOfferData);
            }
          } else {
            checkboxData.push(allOffer);
          }
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  clickHandler = (billId) => {
    const { openDialog, offerBillNewData } = this.state;

    const { offerBillOutletData } = this.props;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.outletBillsAfterApplyingOffers).length !==
        0 &&
        offerBillNewData.outletBillsAfterApplyingOffers.id == billId &&
        offerBillNewData.offerHeaders.length !== 0
      ) {
        offerBillNewData.offerHeaders.map((allOffer) => {
          let allOfferData = {
            ...allOffer,
            appliedOfferId: offerBillNewData.outletBillsAfterApplyingOffers.id,
          };
          checkboxData.push(allOfferData);
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  checkValidation = () => {
    const { vehicleNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (vehicleNo === "") {
      formIsValid = false;
      formErrors["vehicleNoError"] = vehicleNoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  changeHandler = (e, value) => {
    const selectedVehicles = this.props.vehicleList?.vehicle.filter(
      (row) => row.id === value
    );
    if (selectedVehicles && selectedVehicles.length > 0) {
      const selectedVehicle = selectedVehicles[0];
      const { name } = selectedVehicle;
      this.setState({
        vehicleNo: value,
        name: name,
        vehicleInfo: name,
      });
    }
  };

  onChangeTextField = (dataKey, id) => (event) => {
    const { outletBillHeaderData } = this.state;
    const { name, value } = event.target;

    let offerBillNewDataObj = {};

    if (
      Object.keys(outletBillHeaderData).length !== 0 &&
      outletBillHeaderData.outletBillDetails.length !== 0
    ) {
      const updatedDetails = outletBillHeaderData.outletBillDetails.map(
        (detailsObj) => {
          if (id === detailsObj.id && value <= 100) {
            let newRate =
              (detailsObj.rate * 100) /
              (100 +
                detailsObj.sgstPer +
                detailsObj.cgstPer +
                detailsObj.igstPer);
            let newTaxableAmt = newRate * detailsObj.qty;
            let newItemWiseDiscount = (newTaxableAmt / 100) * value;
            let newTaxableWithItemWiseDiscount =
              newTaxableAmt - newItemWiseDiscount;
            let newBillWiseDiscount =
              (newTaxableWithItemWiseDiscount / 100) *
              outletBillHeaderData.discountOnBillPer;
            let newTaxableWithBillWiseDiscount =
              newTaxableWithItemWiseDiscount - newBillWiseDiscount;
            let newSgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.sgstPer;
            let newCgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.cgstPer;
            let newIgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.igstPer;
            console.log(newTaxableWithItemWiseDiscount);
            console.log(newTaxableWithBillWiseDiscount);
            console.log(newSgstAmt);
            console.log(newCgstAmt);
            console.log(newIgstAmt);
            return {
              ...detailsObj,
              cgstAmt: newSgstAmt,
              sgstAmt: newCgstAmt,
              igstAmt: newIgstAmt,
              itemDiscAmt: (newTaxableWithBillWiseDiscount / 100) * value,
              taxableAmt: newTaxableWithBillWiseDiscount,
              totalAmt:
                newTaxableWithBillWiseDiscount +
                newSgstAmt +
                newCgstAmt +
                newIgstAmt,
              [name]: value,
            };
          }
          return detailsObj;
        }
      );

      offerBillNewDataObj = {
        ...outletBillHeaderData,
        taxableAmt: updatedDetails.reduce(
          (sum, row) => sum + row.taxableAmt,
          0
        ),
        totalAmt: updatedDetails.reduce((sum, row) => sum + row.totalAmt, 0),
        cgstAmt: updatedDetails.reduce((sum, row) => sum + row.cgstAmt, 0),
        sgstAmt: updatedDetails.reduce((sum, row) => sum + row.sgstAmt, 0),
        igstAmt: updatedDetails.reduce((sum, row) => sum + row.igstAmt, 0),
        itemDistcountAmt: updatedDetails.reduce(
          (sum, row) => sum + row.itemDiscAmt,
          0
        ),
        outletBillDetails: updatedDetails,
      };
    }

    this.setState({ outletBillHeaderData: offerBillNewDataObj });
  };

  changeHandlerTextField = (dataKey, id) => (event) => {
    const { outletBillHeaderData } = this.state;
    const { name, value } = event.target;

    let offerBillNewDataObj = {};

    if (Object.keys(outletBillHeaderData).length !== 0) {
      if (id === outletBillHeaderData.id && value <= 100) {
        const updatedDetails = outletBillHeaderData.outletBillDetails.map(
          (detailsObj) => {
            let newRate =
              (detailsObj.rate * 100) /
              (100 +
                detailsObj.sgstPer +
                detailsObj.cgstPer +
                detailsObj.igstPer);
            let newTaxableAmt = newRate * detailsObj.qty;
            let newItemWiseDiscount =
              (newTaxableAmt / 100) * detailsObj.itemDiscPer;
            let newTaxableWithItemWiseDiscount =
              newTaxableAmt - newItemWiseDiscount;
            let newBillWiseDiscount =
              (newTaxableWithItemWiseDiscount / 100) * value;
            let newTaxableWithBillWiseDiscount =
              newTaxableWithItemWiseDiscount - newBillWiseDiscount;
            let newSgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.sgstPer;
            let newCgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.cgstPer;
            let newIgstAmt =
              (newTaxableWithItemWiseDiscount / 100) * detailsObj.igstPer;
            console.log(newTaxableWithItemWiseDiscount);
            console.log(newTaxableWithBillWiseDiscount);
            console.log(newSgstAmt);
            console.log(newCgstAmt);
            console.log(newIgstAmt);
            return {
              ...detailsObj,
              cgstAmt: newSgstAmt,
              sgstAmt: newCgstAmt,
              igstAmt: newIgstAmt,
              itemDiscAmt:
                (newTaxableWithBillWiseDiscount / 100) * detailsObj.itemDiscPer,
              taxableAmt: newTaxableWithBillWiseDiscount,
              totalAmt:
                newTaxableWithBillWiseDiscount +
                newSgstAmt +
                newCgstAmt +
                newIgstAmt,
            };
          }
        );

        offerBillNewDataObj = {
          ...outletBillHeaderData,
          [name]: value,
          taxableAmt: updatedDetails.reduce(
            (sum, row) => sum + row.taxableAmt,
            0
          ),
          totalAmt: updatedDetails.reduce((sum, row) => sum + row.totalAmt, 0),
          cgstAmt: updatedDetails.reduce((sum, row) => sum + row.cgstAmt, 0),
          sgstAmt: updatedDetails.reduce((sum, row) => sum + row.sgstAmt, 0),
          igstAmt: updatedDetails.reduce((sum, row) => sum + row.igstAmt, 0),
          itemDistcountAmt: updatedDetails.reduce(
            (sum, row) => sum + row.itemDiscAmt,
            0
          ),
          discountOnBillAmt:
            (updatedDetails.reduce((sum, row) => sum + row.taxableAmt, 0) /
              100) *
            value,
          outletBillDetails: updatedDetails,
        };
      }
    }

    this.setState({ outletBillHeaderData: offerBillNewDataObj });
  };

  clickSubmitOfferHandlerForCompanyFlagTwo = () => {
    const { showLoador, showNotification, salesPersonId } = this.props;
    const { outletBillHeaderData, vehicleInfo } = this.state;

    if (this.checkValidation()) {
      let stockListForSave = localStorage.getItem("stockList");
      let outletBillHeader = {};

      outletBillHeader = {
        ...outletBillHeaderData,
        discountOnBillPer: parseFloat(outletBillHeaderData.discountOnBillPer),

        salesTeamId: salesPersonId,
        outletBillDetails:
          outletBillHeaderData.outletBillDetails.length != 0 &&
          outletBillHeaderData.outletBillDetails.map((billDetailsObject) => {
            billDetailsObject = {
              ...billDetailsObject,
              itemDiscPer: parseFloat(billDetailsObject.itemDiscPer),
            };
            return billDetailsObject;
          }),
        vehicleNo: vehicleInfo,
      };

      const dataToSaveWithStock = {
        outletBillHeader: outletBillHeader,
        productAndStocks: JSON.parse(stockListForSave),
      };

      console.log(dataToSaveWithStock);

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiPost({
            url:
              endpoint.manualOrder +
              "/save-outlet-order-and-bill?salesPersonId=" +
              salesPersonId,
            postBody: dataToSaveWithStock,
          }).then(({ data, success }) => {
            if (success) {
              showNotification({ msg: savemsg });
              window.location.replace("/manual-order");
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            showLoador({ loador: false });
          });
        }
      });
    }
  };

  render() {
    const {
      openDialog,
      checkboxAllData,
      offerBillNewData,
      vehicleNo,
      formErrors,
      companyFlagTwo,
      outletBillHeaderData,
      outletHeader,
      openPdfPopUpFlag,
      pdfData,
    } = this.state;
    const { offerBillOutletData, vehicleList, onCancel, totalQty } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 1.5,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />

          {companyFlagTwo
            ? Object.keys(outletBillHeaderData).length !== 0 && (
              <>
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet No.",
                      value: 1,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet Name",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.outLet !== null
                          ? outletBillHeaderData.outLet.firmName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Owner Name",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.outLet !== null
                          ? outletBillHeaderData.outLet.ownerName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill No",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.billNo !== null
                          ? outletBillHeaderData.billNo
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Taxable Amount",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.taxableAmt !== null
                          ? outletBillHeaderData.taxableAmt.toFixed(2)
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Tax",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.sgstAmt !== null &&
                          outletBillHeaderData.cgstAmt !== null &&
                          outletBillHeaderData.igstAmt !== null
                          ? (
                            outletBillHeaderData.sgstAmt +
                            outletBillHeaderData.cgstAmt +
                            outletBillHeaderData.igstAmt
                          ).toFixed(2)
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill Discount Amt",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.discountOnBillAmt !== null
                          ? outletBillHeaderData.discountOnBillAmt.toFixed(2)
                          : "",
                    },

                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Item Discount Amt",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.itemDistcountAmt !== null
                          ? outletBillHeaderData.itemDistcountAmt.toFixed(2)
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill Amount",
                      value:
                        outletBillHeaderData &&
                          Object.keys(outletBillHeaderData).length !== 0 &&
                          outletBillHeaderData.totalAmt !== null
                          ? outletBillHeaderData.totalAmt.toFixed(2)
                          : "",
                    },
                  ]}
                />
              </>
            )
            : Object.keys(offerBillNewData).length !== 0 &&
            Object.keys(offerBillNewData.outletBillsAfterApplyingOffers)
              .length !== 0 && (
              <>
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet No.",
                      value: 1,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet.firmName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Owner Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet.ownerName
                          : "",
                    },

                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "State Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet !== null &&
                          offerBillNewData.outletBillsAfterApplyingOffers.outLet
                            .state !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                            .outLet.state.name
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill No",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                            .billNo
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Quantity",
                      value: totalQty ? totalQty : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Taxable Amount",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.outletBillsAfterApplyingOffers.taxableAmt.toFixed(
                            2
                          )
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Amount",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.outletBillsAfterApplyingOffers.totalAmt.toFixed(
                            2
                          )
                          : "",
                    },
                  ]}
                />
              </>
            )}

          {Object.keys(offerBillNewData).length !== 0 &&
            offerBillNewData.offerHeaders.length !== 0 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-end"
                >
                  <ButtonCompo
                    sx={{
                      bgcolor: "dodgerblue", // theme.palette.primary.main
                      color: yellowColor,
                      opacity: "0.9",
                      transform: "scale(0.9)",
                      ":hover": {
                        display: "inline-block",
                        bgcolor: yellowColor, // theme.palette.primary.main
                        color: "dodgerblue",
                        opacity: "0.9",
                        transform: "scale(1.5)",
                        transitionDuration: "3s",
                        backfaceVisibility: "hidden",
                        transform: "translate(2px, 2px) rotate(0deg) scale(2)",
                        config: {
                          tension: "300",
                          friction: "10",
                        },
                      },
                    }}
                    size="medium"
                    type="button"
                    variant="contained"
                    name={"Offer"}
                    onClick={(e) =>
                      this.clickHandler(
                        offerBillNewData.outletBillsAfterApplyingOffers.id
                      )
                    }
                    fullWidth={false}
                  />
                </Grid>
              </>
            )}

          <>
            <TableContainer>
              {companyFlagTwo
                ? Object.keys(outletBillHeaderData).length !== 0 && (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ width: "5%" }}>
                          Sr. No
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Product Name
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Quantity
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Tax (%)
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Tax Amount
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Discount (%)
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Discount Amount
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Taxable Amount
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Total Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {outletBillHeaderData.outletBillDetails.length !== 0 &&
                      outletBillHeaderData.outletBillDetails.map(
                        (billDetails, index) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ width: "5%" }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null &&
                                    billDetails.product !== null
                                    ? billDetails.product.name
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.qty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? (
                                      billDetails.cgstPer +
                                      billDetails.sgstPer +
                                      billDetails.igstPer
                                    ).toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? (
                                      billDetails.cgstAmt +
                                      billDetails.sgstAmt +
                                      billDetails.igstAmt
                                    ).toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  <TextFieldCompo
                                    placeHolder={"Discount %"}
                                    size="small"
                                    color="primary"
                                    name="itemDiscPer"
                                    id={"1"}
                                    value={billDetails.itemDiscPer}
                                    onChange={this.onChangeTextField(
                                      "itemDiscPer",
                                      billDetails.id
                                    )}
                                    fullWidth={false}
                                    errorText={""}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.itemDiscAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.taxableAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.totalAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      )}
                  </Table>
                )
                : Object.keys(offerBillNewData).length !== 0 && (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ width: "5%" }}>
                          Sr. No
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Product Name
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Quantity
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Free Quantity
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Taxable Amount
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          Total Amount
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Taxable Amount with offer
                        </TableCell>
                        <TableCell align="center" sx={{ width: "10%" }}>
                          Total Amount with offer
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Object.keys(
                      offerBillNewData.outletBillsAfterApplyingOffers
                    ).length !== 0 &&
                      offerBillNewData.outletBillsAfterApplyingOffers
                        .outletBillDetails.length !== 0 &&
                      offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.map(
                        (billDetails, index) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ width: "5%" }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null &&
                                    billDetails.product !== null
                                    ? billDetails.product.name
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.qty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.freeQty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.taxableAmtWithoutOffer.toFixed(
                                      2
                                    )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "10%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.totalAmtWithoutOffer.toFixed(
                                      2
                                    )
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.taxableAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ width: "15%" }}
                                >
                                  {billDetails !== null
                                    ? billDetails.totalAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      )}
                  </Table>
                )}

              {Object.keys(offerBillNewData).length !== 0 &&
                Object.keys(offerBillNewData.outletBillsAfterApplyingOffers)
                  .length !== 0 &&
                offerBillNewData.outletBillsAfterApplyingOffers.appliedOffers
                  .length !== 0 && (
                  <>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ width: "5.5%" }}>
                            Sr. No
                          </TableCell>
                          <TableCell align="center" sx={{ width: "80%" }}>
                            Offer Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {offerBillNewData.outletBillsAfterApplyingOffers
                        .appliedOffers.length !== 0 &&
                        offerBillNewData.outletBillsAfterApplyingOffers.appliedOffers.map(
                          (offerAppliedData, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: "80%" }}>
                                    {offerAppliedData !== null
                                      ? offerAppliedData.name
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          }
                        )}
                    </Table>
                  </>
                )}
            </TableContainer>
          </>

          <br />

          {companyFlagTwo && (
            <Grid
              item
              sm={6}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-between"
            >
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-between"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="CD(%) :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Enter CD"}
                    color="primary"
                    name="discountOnBillPer"
                    value={outletBillHeaderData.discountOnBillPer}
                    fullWidth
                    onChange={this.changeHandlerTextField(
                      "discountOnBillPer",
                      outletBillHeaderData.id
                    )}
                    errorText={
                      formErrors["nameError"] ? formErrors["nameError"] : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <br />

          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Vehicle No :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <AutoComplete
                  keyColName={"id"}
                  placeHolder="Select Vehicle No"
                  value={vehicleNo}
                  name={"vehicleNo"}
                  options={vehicleList?.vehicle}
                  errorText={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  isError={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  onChange={this.changeHandler}
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          {openDialog && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.5rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: yellowColor,
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: yellowColor,
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={
                companyFlagTwo
                  ? this.clickSubmitOfferHandlerForCompanyFlagTwo
                  : this.clickSubmitOfferHandler
              }
              fullWidth={false}
            />
          </Grid>

          {openPdfPopUpFlag && (
            <PopUpForPdfAfterSubmit
              open={openPdfPopUpFlag}
              pdfData={pdfData}
            />
          )}


        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleList: state.vehicle,
});
const mapDispatchToProps = {
  getActiveVehicleNoListByStockholder,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingWithOffer);
