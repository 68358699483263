import CloseIcon from "@mui/icons-material/Close";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";

import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { getMinutesOfMeeting, setMinutesOfMeetings } from "../../Slice/minutesOfMeeting.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import AddMomPopUp from "./AddMomPopUp";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue } from "../../config/ColorObj";
import ViewAndAddMomPopUp from "./ViewAndAddMomPopUp";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

class MinutesOfMeetingPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminFlag: false,
            areaIds: "",
            companyFlag: "",
            watcherList: [],
            formData: {},
            formErrors: {},
            fieldData: {},
            salesTeamValue: "",
            searchValue: "",
            stockStakeholderTypeValue: "",
            addMomFlag: false,
            viewAndAddMomPopUpFlag: false,
            meetingHeaderData: "",
            momOpenPopUpFlag: false,
            apiFetchAgainFlag: false,
        };
    }

    async componentDidMount() {
        const {
            getMinutesOfMeeting,
            showLoador,
            showNotification,
            dataRow,
            setMinutesOfMeetings,
        } = this.props;
        if (navigator.onLine) {

            // setMinutesOfMeetings({ row: [] });

            showLoador({ loador: true });
            await getMinutesOfMeeting({ distributorInquiryId: dataRow.id }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            this.momOpenPopUp();

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    momOpenPopUp = () => {
        this.setState({ momOpenPopUpFlag: true })
    }

    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }

    toggle = () => {
        const { getMinutesOfMeeting, showLoador, showNotification, dataRow } = this.props;
        const { apiFetchAgainFlag } = this.state;

        this.setState({
            addMomFlag: false,
            viewAndAddMomPopUpFlag: false,
        });

        if (apiFetchAgainFlag) {
            showLoador({ loador: true });
            getMinutesOfMeeting({ distributorInquiryId: dataRow.id }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        }

    };


    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    onAddHandler = () => {
        this.setState({ addMomFlag: true });
    }

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["meetingPurpose"] &&
                    currentRow["meetingPurpose"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };

    meetingPurpose = (meetingData) => {
        this.setState({ meetingHeaderData: meetingData, viewAndAddMomPopUpFlag: true })
    }


    apiFetchAgainMethodMain = () => {
        this.setState({ apiFetchAgainFlag: true })
    }

    render() {
        const { minutesOfMeetingList, open, dataRow } = this.props;
        const { addMomFlag, meetingHeaderData, momOpenPopUpFlag, viewAndAddMomPopUpFlag } = this.state;
        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={momOpenPopUpFlag}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Minutes Of Meeting
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <BootstrapDialogTitle
                            style={{
                                fontSize: 18,
                                paddingLeft: 2,
                            }}
                        >
                            {"Name : " + dataRow.fullName}
                        </BootstrapDialogTitle>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            justifyContent="right"
                        >
                            <ButtonCompo
                                size="medium"
                                type="Submit"
                                variant="contained"
                                name="ADD"
                                onClick={this.onAddHandler}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <LandingScreenHeader
                                screenTitle=""
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearch}
                            />

                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width="10%">#</TableCell>
                                            <TableCell align="center" width="45%">MOM</TableCell>
                                            <TableCell align="center" width="25%">Date</TableCell>
                                            <TableCell align="center" width="30%">Seen By</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(minutesOfMeetingList?.minutesOfMeeting).map((row, index) => (
                                            <TableRow key={row.id}>

                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell width="left" >
                                                    <div
                                                        onClick={(e) =>
                                                            this.meetingPurpose(row)
                                                        }
                                                        style={{
                                                            color: cyanBlue,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {row.meetingPurpose}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{row.minutesOfMeetingDate}</TableCell>
                                                <TableCell align="center">{row.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog >


                {addMomFlag && (
                    <AddMomPopUp
                        open={addMomFlag}
                        handleCloses={this.toggle}
                        dataRow={dataRow}
                        apiFetchAgainMethodMain={this.apiFetchAgainMethodMain}
                    />
                )}

                {viewAndAddMomPopUpFlag && (
                    <ViewAndAddMomPopUp
                        open={viewAndAddMomPopUpFlag}
                        handleCloses={this.toggle}
                        meetingHeaderData={meetingHeaderData}
                        dataRow={dataRow}
                    />
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    minutesOfMeetingList: state.minutesOfMeeting,
});

const mapDispatchToProps = {
    getMinutesOfMeeting,
    setMinutesOfMeetings,
    showLoador,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(MinutesOfMeetingPopUp);
