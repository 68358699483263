import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText, Paper } from "@mui/material";

import { saveReport, setSaveReport } from '../../Slice/report.slice'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import * as msgConstant from "../../config/messageconstant";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
    Grid, RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import RTTable from '../../components/Comman/RT/RTTable'
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import AutoComplete from "../../components/Comman/AutoComplete";
import { validText } from "../../components/Comman/Util/Validations";
import NewMultipleSelect from "../../components/Comman/NewMultipleSelect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const AddQueryParameter = ({
    openDialog, setOpenDialouge,
    report,
    saveReport,
    showLoador,
    showNotification,
    rowData = {}
}) => {
    const [localList, setLocalList] = React.useState([]);
    const [formDataLocal, setFormDataLocal] = React.useState({});

    const [formData, setFormData] = React.useState({ isIn: "0" });
    const [formErrors, setFormError] = React.useState({});


    React.useEffect(() => {
        if (Object.keys(rowData).length != 0) {
            console.log(rowData)
            setFormData({ ...rowData})

        } else {
            setFormData({ isIn: "0" })
        }
        setFormError({})
    }, [])
    const handleClose = () => {
        setOpenDialouge(false)

    };

    const checkValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (formData["parameterValue"] === undefined || formData["parameterValue"] == "") {
            formIsValid = false;
            formErrors["parameterValue"] = msgConstant.parameterValueMsg;
        }
        if (formData["parameterKey"] === undefined || formData["parameterKey"] == "") {
            formIsValid = false;
            formErrors["parameterKey"] = msgConstant.parameterKeyMsg;
        }

        setFormError(formErrors)
        return formIsValid;
    };

    const onSubmitData = () => {
        if (checkValidation()) {
            console.log(formData)
            console.log(report.reportData)
            let parameterList = JSON.parse(report.reportData.queryParam) ? JSON.parse(report.reportData.queryParam) : []
            let dataToSave = {
                parameterKey: formData.parameterKey,
                parameterValue: formData.parameterValue,
                isIn: formData.isIn,
            }
            let parameter = ""
            if (formData.index) {
                const parameterInfoList = parameterList.map((parameterInfo, index) => {
                    const indexData = index + 1
                    if (indexData == formData.index) {
                        return dataToSave
                    }
                    return parameterInfo
                })
                console.log(parameterInfoList)
                parameter = JSON.stringify([
                    ...parameterInfoList
                ])
            } else {
                parameter = JSON.stringify([
                    ...parameterList,
                    dataToSave
                ])
            }
            dataToSave = {
                ...report.reportData,
                queryParam: parameter
            }
            console.log(dataToSave)
            onSave(dataToSave)
        }
    };
    const onSave = (dataToSave) => {

        if (navigator.onLine) {

            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                    handleClose()
                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };


    const onRadioChange = (name) => (event) => {
        setFormData(
            {
                ...formData,
                [name]: event.target.value
            }
        )
    }
    const onTextFieldDataChange = (event) => {
        if (
            event.target.value === "" ||
            validText.test(event.target.value)
        ) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };
    const onTextFieldLocalDataChange = (event) => {

        setFormDataLocal({ ...formDataLocal, [event.target.name]: event.target.value });

    };
    const onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {
            setFormData(
                {
                    ...formData,
                    [name]: newValue,
                    isLocal: newValue == "radiogroup" ? "1" : "0"
                }
            )
        } else {
            setFormData(
                {
                    ...formData,
                    [name]: ""
                }
            )
        }
    };
    const onMultiSelectChange = (value, name) => {
        let dataValue = typeof value === "string" ? value.split(",") : value;

        setFormData(
            {
                ...formData,
                [name]: dataValue
            }
        )
    };
    const rowDelete = (rowData) => {
        const filterData = localList.filter((row) => row.index != rowData.index)
        setLocalList(filterData.map((data, index) => {
            return { ...data, index: index + 1 }
        }))
    }
    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Add Filter
                    </BootstrapDialogTitle>
                    <DialogContent dividers>

                        <Grid container sx={{ marginTop: 2 }} spacing={1}>

                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Parameter Key :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Parameter Key"}
                                        size="small"
                                        color="primary"
                                        name={"parameterKey"}
                                        id={"parameterKey"}
                                        value={formData["parameterKey"] ? formData["parameterKey"] : ""}
                                        onChange={onTextFieldDataChange}
                                        fullWidth
                                        errorText={formErrors["parameterKey"] ? formErrors["parameterKey"] : ""}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Parameter Value :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Parameter Value"}
                                        size="small"
                                        color="primary"
                                        name={"parameterValue"}
                                        id={"parameterValue"}
                                        value={formData["parameterValue"] ? formData["parameterValue"] : ""}
                                        onChange={onTextFieldDataChange}
                                        fullWidth
                                        errorText={formErrors["parameterValue"] ? formErrors["parameterValue"] : ""}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Is In:"}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >

                                    <RadioGroup
                                        row
                                        aria-label={"isIn"}
                                        name={"isIn"}
                                        id={"isIn"}
                                        value={formData["isIn"] ? formData["isIn"] : 0}

                                        onChange={onRadioChange("isIn")}
                                        size="small"
                                    >
                                        <FormControlLabel
                                            value={0}
                                            control={<Radio />}
                                            label={"Normal"}
                                        />
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label={"Is In"}
                                        />
                                        <FormControlLabel
                                            value={2}
                                            control={<Radio />}
                                            label={"Multiple Comma Seprated"}
                                        />
                                    </RadioGroup>

                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            rowSpacing={2}
                            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                            container
                            justifyContent="right"
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="submit"
                                onClick={onSubmitData}
                            />
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({ report: state.report });

const mapDispatchToProps = { showLoador, showNotification, setSaveReport, saveReport };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddQueryParameter);
