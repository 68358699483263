import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { subAdminUserJson } from "../../../DynamicFormsJson/Transaction/subAdminUser";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrictPost } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBodyUpdated } from "../../../Slice/area.slice";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class SubAdminUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {

    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 6 }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );


      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrictPost,
      getAreaByTalukaPostBodyUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      console.log(data);

      if (data.regionIds && data.regionIds !== "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.regionIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stateIds && data.stateIds != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds && data.zoneIds != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.districtIds && data.districtIds != "") {
        let districtIdsByPost = {
          districtIds: data.districtIds,
        };
        showLoador({ loador: true });
        await getTalukaByDistrictPost({ districtIds: districtIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.talukaIds && data.talukaIds != "") {
        let talukaIdsByPost = {
          talukaIds: data.talukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          formPath={subAdminUserJson.formPath}
          getListById={this.getListById}
          screenTitle={subAdminUserJson.screenTitle}
          fieldMeta={subAdminUserJson.fieldMeta}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={subAdminUserJson.apiBaseURL}
          showSaveNextBtn={subAdminUserJson.showSaveNextBtn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getAreaByTalukaPostBodyUpdated,
  getTalukaByDistrictPost,
  getDistrictByZone,
  getZoneByState,
  getStateByRegion,
  getRegionNew,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubAdminUserForm);
