import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { getSurveyPendingDetails } from "../../Slice/audit.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class PendingSurveyDetailsPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            currentPage: 0,
            rowsPerPage: 20,
            pendingSurveyOpenPopUpFlag: false,
        };
    }

    async componentDidMount() {
        const {
            getSurveyPendingDetails,
            showLoador,
            showNotification,
            dataRow,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getSurveyPendingDetails({ auditHeadId: dataRow.auditHeadId }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {

                }
            });
            this.pendingSurveyOpenPopUp();
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    pendingSurveyOpenPopUp = () => {
        this.setState({ pendingSurveyOpenPopUpFlag: true })
    }

    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }


    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue, currentPage: 0,
        });
    };



    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;

        return searchData
            .map((row, index) => ({
                ...row,
                originalIndex: index + 1,
            }))
            .filter((currentRow) => {
                return (
                    currentRow["firmName"] &&
                    currentRow["firmName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase())
                );
            });
    };

    handleChangePage = (event, newPage) => {
        console.log(newPage);
        this.setState({ currentPage: newPage });
    };



    render() {
        const { auditList, open } = this.props;
        const { pendingSurveyOpenPopUpFlag, currentPage, rowsPerPage } = this.state;

        const filteredData = this.getFilteredTableData(auditList?.surveyPendingDetails || []);
        const paginatedData = filteredData.slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
        );

        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={pendingSurveyOpenPopUpFlag}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Pending Survey
                    </BootstrapDialogTitle>
                    <DialogContent dividers>


                        <LandingScreenHeader
                            screenTitle=""
                            showSearchBox={true}
                            showTable={true}
                            showFilter={false}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            onSearch={this.onSearch}
                        />

                        <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ minWidth: "70px" }}>Sr. No.</TableCell>
                                        <TableCell align="center" style={{ minWidth: "150px" }}>Firm Name</TableCell>
                                        <TableCell align="center" style={{ minWidth: "150px" }}>Owner Name</TableCell>
                                        <TableCell align="center" style={{ minWidth: "150px" }}>Outlet Code</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {paginatedData.map((rowData, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell align="center">{rowData.originalIndex}</TableCell>
                                            <TableCell align="left">{rowData.firmName}</TableCell>
                                            <TableCell align="left">{rowData.ownerName}</TableCell>
                                            <TableCell align="left">{rowData.outletCode}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={filteredData.length}
                            page={currentPage}
                            onPageChange={this.handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                        />
                    </DialogContent>
                </BootstrapDialog >



            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});

const mapDispatchToProps = {
    getSurveyPendingDetails,
    showLoador,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSurveyDetailsPopUp);
