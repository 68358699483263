
import CloseIcon from "@mui/icons-material/Close";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useState } from "react"; import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue } from "../../config/ColorObj";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const HierarchyForPackagingTypePopUp = ({ handleCloses,
    HierarchyPopUpFlag,
    popUpData,
    flag,
    showLoador, showNotification,
    packagingTypeList,
    stateHandler,
    zoneHandler,
    districtHandler,
    talukaHandler,
    tableName,
    stateListFromYearAndMonthFlag,
    zoneByStateDataFlag,
    districtByZoneDataFlag,
    talukaByDistrictDataFlag,
    areaByTalukaDataFlag,
    targetList,
}) => {

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {

        // getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.locationId, dynamicMasterData.fromDate, dynamicMasterData.toDate, popUpData.groupId, popUpData.groupName)

    }, []);

    const handlerMethod = (e, id, flag) => {
        switch (flag) {
            case 3:
                stateHandler(e, id, flag);
                break;
            case 4:
                zoneHandler(e, id, flag);
                break;
            case 5:
                districtHandler(e, id, flag);
                break;
            case 6:
                talukaHandler(e, id, flag);
                break;
            default:
                break;
        }
    };

    const onSearch = (searchValue) => {
        setSearchValue(searchValue);
    }

    const getFilteredTableData = (searchData) => {
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["entityName"] &&
                    currentRow["entityName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };



    return (

        <>

            <BootstrapDialog
                onClose={handleCloses}
                aria-labelledby="customized-dialog-title"
                open={HierarchyPopUpFlag}
                fullWidth
                maxWidth="lg"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                // onClose={handleCloses}
                >
                    <MyComponentWithIconProps
                        statusImage={KeyboardBackspaceIcon}
                        color="primary"
                        fontSize="medium"
                        title="Back"
                        onClick={handleCloses}
                    /> &nbsp;&nbsp;
                    {tableName}  </BootstrapDialogTitle>
                <DialogContent dividers>
                    <LandingScreenHeader
                        screenTitle=""
                        showTable={true}
                        showBackToList={false}
                        showSaveBtnMain={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        onSearch={onSearch}
                    />
                    <TableContainer
                        sx={{
                            maxHeight: 440,
                            marginTop: 0,
                            padding: 0,
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead
                                style={{
                                    backgroundColor: "#fff",
                                }}
                            >
                                <TableRow>
                                    <TableCell
                                        color="#000"
                                        align="center"
                                        sx={{ width: "2%", color: "#000" }}
                                    >
                                        Sr.No
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ width: "8%", color: "#000" }}
                                    >
                                        Name
                                    </TableCell>
                                    {packagingTypeList.map((packagingTypeObj, index) => (
                                        <TableCell key={index} align="center" style={{ minWidth: "150px" }}>
                                            {packagingTypeObj.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getFilteredTableData(popUpData && popUpData).map(
                                    (dataObj, index) => {
                                        return (
                                            <TableRow key={dataObj.index}>
                                                <TableCell align="center">
                                                    {index + 1}
                                                </TableCell>
                                                {areaByTalukaDataFlag ? (
                                                    <TableCell width="2%">
                                                        {dataObj.entityName}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell width="2%">
                                                        <div
                                                            onClick={(e) =>
                                                                handlerMethod(e, dataObj.entityId, zoneByStateDataFlag ? 4 : districtByZoneDataFlag ? 5 : talukaByDistrictDataFlag ? 6 : 3)
                                                            }

                                                            style={{
                                                                color: cyanBlue,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {dataObj.entityName}
                                                        </div>
                                                    </TableCell>

                                                )
                                                }

                                                {dataObj.packagingAmounts.map((amt, idx) => (
                                                    <TableCell align="center" key={idx}>{amt !== '' ? amt : '-'}</TableCell>
                                                ))}

                                            </TableRow>
                                        );
                                    })
                                }
                                <TableRow>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

            </BootstrapDialog >

        </>
    );
};

const mapStateToProps = (state) => ({
    targetList: state.target,
});
const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(HierarchyForPackagingTypePopUp);

