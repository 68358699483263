import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { productCatelogueJson } from "../../../DynamicFormsJson/MastersJSON/productCatelogue";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { imageUrl } from "../../../config/Url";
import { LabelCompo } from "../../../components/Comman/Label";
import { titleColor } from "../../../config/ColorObj";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { apiGet } from "../../../utils/api_service";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";

class ProductCatelogueView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            headerData: {},
        };
    }
    async componentDidMount() {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                apiGet({
                    url: productCatelogueJson.apiBaseURL + "/by-id-with-offer-info?id=" + this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        let dataField = data.data;
                        this.setState({
                            headerData: dataField,
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Product Name",
                                    value:
                                        dataField.productName !== null
                                            ? dataField.productName
                                            : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Product Description",
                                    value: dataField.productDescription !== null ? dataField.productDescription : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Product Mrp",
                                    value:
                                        dataField.productMrp !== null ? dataField.productMrp : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Product Retail Rate",
                                    value: dataField.productRetailRate !== null ? dataField.productRetailRate : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Trending Sequence",
                                    value: dataField.trendingSequence !== null ?
                                        dataField.trendingSequence == 0 ? "No" : "Yes" : "",

                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Offer Applicable",
                                    value:
                                        dataField.offerApplicable !== null
                                            ? dataField.offerApplicable == 0 ? "No" : "Yes"
                                            : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Offer Names",
                                    value:
                                        dataField.offerNames !== null && dataField.offerApplicable !== null
                                            ? (dataField.offerApplicable == 1 ? dataField.offerNames : "-") : "-",

                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "From Date",
                                    value:
                                        dataField.fromDate !== null ? dataField.fromDate : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "To Date",
                                    value: dataField.toDate !== null ? dataField.toDate : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Is Label Applicable",
                                    value: dataField.isLabelApplicable !== null ?
                                        dataField.isLabelApplicable == 0 ? "No" : "Yes" : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Label Name",
                                    value: dataField.labelName !== null ? dataField.labelName : "-"
                                }
                            ],
                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    onClickViewPreview = (e, row) => {
        window.open(imageUrl + row, '_blank')
    };

    onCancel = () => {
        window.location.replace("/product-catelogue");
    }

    render() {
        const { dynamicMasterData, DataToSet, headerData } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 1.5,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >

                    <LandingScreenHeader
                        screenTitle={"Product Catelogue View"}
                        showTable={false}
                        showBackButton={true}
                        showAddButton={false}
                        onCancel={this.onCancel}
                    />



                    <DynamicFormWithoutSave
                        showBackToList={false}
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        showSaveBtn={productCatelogueJson.showSaveBtn}
                        showTitle={false}
                        screenTitle={""}
                        showCancel={productCatelogueJson.showCancel}
                        apiBaseURL={productCatelogueJson.apiBaseURL}
                        showSaveNextBtn={productCatelogueJson.showSaveNextBtn}
                        saveBtnText={"Search"}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />
                    {headerData && Object.keys(headerData).length !== 0 && (
                        <>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                justifyContent="space-between"
                            >
                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    container
                                    justifyContent="space-between"
                                >
                                    <Grid item xs={12} md={4.2} sm={4.2}>
                                        <LabelCompo
                                            className="text-black"
                                            style={{
                                                fontSize: 16,
                                                color: titleColor,
                                                marginLeft: "25px",
                                            }}
                                            label="Image :"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={7.8} sm={7.8}>
                                        <MyComponentWithIconProps
                                            statusImage={VisibilityIcon}
                                            sx={{ marginRight: "45px" }}
                                            color="primary"
                                            fontSize="medium"
                                            title="View"
                                            onClick={(e) => this.onClickViewPreview(e, headerData.productImage)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )

                    }

                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ProductCatelogueView);
