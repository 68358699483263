import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Avatar, Checkbox, TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";

import * as React from "react";
import { ChipCompo, MyComponentWithIconProps } from "../IconButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LandingScreenHeader from "../LandingScreenHeader";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectAllFlag,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell width={10} padding="checkbox">
          {selectAllFlag && (
            <Checkbox
              color="error"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          )}
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            style={{
              minWidth:
                headCell.name === "index"
                  ? 50
                  : headCell.minWidth
                    ? headCell.minWidth
                    : 100,
            }}
          >
            <TableSortLabel
              active={true}
              direction={orderBy === headCell.name ? order : "asc"}
              onClick={createSortHandler(headCell.name)}
              className="tableCellHeaderColor"
            >
              {headCell.name == "index" ? "#" : headCell.title}
              {orderBy === headCell.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  columns,
  tableHead,
  rowViewData,
  rowAppliedList,
  rowDelete,
  rowStatus,
  showView,
  showDelete,
  showDetailsIcon,
  rowPrint,
  selection,
  SummarizeRoundedShow,
  isActionColActive = true,
  credentialConditionName = "",
  credentialConditionValue = "",
  detailConditionName = "",
  detailConditionValue = "",
  showApply,
  showApplyTitle,
  showAddTitle,
  rowApply,
  showViewButtonInTable = false,
  rowView,
  onSelectionChange,
  showDownload,
  rowDownload,
  showAdd,
  rowAdd,
  docType = 1,
  handleCheckFlag = false,
  disabled = false,
  selectAllFlag = true,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState(selection);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchValue, setSearch] = React.useState("");
  const [fieldDataForModule, setFieldDataForModule] = React.useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabled) {
        let newSelecteds = [];
        rows.map((n) => {
          if (!n.disabled) {
            newSelecteds.push(n.id);
          }
        });
        // setSelected(newSelecteds);
        onSelectionChange(newSelecteds);
      } else {
        const newSelecteds = rows.map((n) => n.id);
        // setSelected(newSelecteds);
        onSelectionChange(newSelecteds);
      }

      return;
    }
    onSelectionChange([]);

    // setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    if (handleCheckFlag) {
      onSelectionChange(event, name);
    } else {
      onSelectionChange(newSelected);
    }
    // setSelected(newSelected);
  };

  const isSelected = (name) => selection.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const tableStyle = {
    maxHeight: 500, // Set your desired height here
    overflowY: "scroll",
  };
  const onSearch = (searchValue) => {
    setSearch(searchValue);
  };
  const getFilteredTableData = () => {
    const columsFil = columns.filter((item) => {
      return item.canSearch;
    });
    return rows.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };
  React.useEffect(() => {
    const moduleObjData = localStorage.getItem("moduleObj");
    if (moduleObjData) {
      const fieldDataForModule =
        moduleObjData != null ? JSON.parse(moduleObjData) : {};
      setFieldDataForModule(fieldDataForModule);
    }
  }, []);
  return (
    <>
      <LandingScreenHeader
        screenTitle={""}
        showTable={true}
        showBackButton={false}
        showAddButton={false}
        showPdfDownload={false}
        showExcelDownload={false}
        onSearch={onSearch}
      />
      <Box sx={{ width: "100%" }}>
        <TableContainer style={tableStyle}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              selectAllFlag={selectAllFlag}
              headCells={columns}
              numSelected={selection.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getFilteredTableData().length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(getFilteredTableData(), getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      key={row.id}
                      row={row}
                      rowIndex={index}
                      tableHead={columns}
                      showView={showView}
                      showDelete={
                        showDelete &&
                          fieldDataForModule.deleteRejectApprove == 1
                          ? true
                          : false
                      }
                      showDetailsIcon={
                        showDetailsIcon && fieldDataForModule.editReject == 1
                          ? true
                          : false
                      }
                      detailConditionValue={detailConditionValue}
                      detailConditionName={detailConditionName}
                      rowViewData={rowViewData}
                      rowAppliedList={rowAppliedList}
                      rowDelete={rowDelete}
                      rowStatus={rowStatus}
                      handleClick={handleClick}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      isActionColActive={isActionColActive}
                      credentialConditionName={credentialConditionName}
                      credentialConditionValue={credentialConditionValue}
                      showApply={
                        showApply && fieldDataForModule.editReject == 1
                          ? true
                          : false
                      }
                      showApplyTitle={showApplyTitle}
                      showAddTitle={showAddTitle}
                      rowApply={rowApply}
                      showViewButtonInTable={
                        showViewButtonInTable && fieldDataForModule.view == 1
                          ? true
                          : false
                      }
                      rowView={rowView}
                      docType={docType}
                      SummarizeRoundedShow={
                        SummarizeRoundedShow && fieldDataForModule.editReject == 1
                          ? true
                          : false
                      }
                      rowPrint={rowPrint}
                      showDownload={showDownload}
                      rowDownload={rowDownload}
                      showAdd={
                        showAdd && fieldDataForModule.editReject == 1
                          ? true
                          : false
                      }
                      rowAdd={rowAdd}
                      fieldDataForModule={fieldDataForModule}
                    //   tableSubHead={tableSubHead}
                    //   tableSubList={tableSubList}
                    />
                    // <TableRow
                    //   hover

                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   <TableCell padding="checkbox">
                    //     <Checkbox
                    //       color="primary"
                    //       onClick={(event) => handleClick(event, row.id)}
                    //       checked={isItemSelected}
                    //       inputProps={{
                    //         "aria-labelledby": labelId,
                    //       }}
                    //     />
                    //   </TableCell>
                    //   <TableCell
                    //     component="th"
                    //     id={labelId}
                    //     scope="row"
                    //     padding="none"
                    //   >
                    //     {row.index}
                    //   </TableCell>

                    // </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function Row({
  row,
  tableHead,
  rowStatus,
  rowDelete,
  rowAppliedList,
  rowPrint,
  rowId,
  rowView,
  rowViewData,
  SummarizeRoundedShow,
  rowIndex,
  showHeadAdd,
  showView,
  showDownload,
  showDelete,
  showDetailsIcon,
  labelId,
  isItemSelected,
  handleClick,
  rounds,
  isActionColActive,
  credentialConditionName = "",
  credentialConditionValue = "",
  detailConditionName = "",
  detailConditionValue = "",
  showApply,
  showApplyTitle,
  showAddTitle,
  rowApply,
  showPaymentDetails,
  rowFeesPaid,
  showViewButtonInTable,
  rowDownload,
  showAdd,
  rowAdd,
  docType = 1,
  fieldDataForModule,
}) {
  const changeStatus = (e, row) => {
    if (fieldDataForModule.editReject == 1) {
      e.stopPropagation();
      rowStatus(row.id);
    }
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const appliedList = (e, params) => {
    e.stopPropagation();
    rowAppliedList(params);
  };

  const onClickPrint = (e, id) => {
    e.stopPropagation();
    rowPrint(id);
  };

  const onClickViewPreview = (e, data) => {
    e.stopPropagation();
    rowViewData(data);
  };

  const feesPaid = (e, params) => {
    e.stopPropagation();
    rowFeesPaid(params);
  };
  const onClickView = (e, row) => {
    e.stopPropagation();
    rowView(row);
  };
  const onClickDownload = (e, row) => {
    e.stopPropagation();
    rowDownload(row);
  };
  const onClickApply = (e, row) => {
    e.stopPropagation();
    rowApply(row);
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox" style={{ width: "1%" }}>
        <Checkbox
          color="primary"
          onClick={(event) => handleClick(event, row.id)}
          checked={isItemSelected}
          disabled={row.disabled}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter((row) => row.name === key);
        if (filteredList.length !== 0) {
          if (filteredList[0].name === "index")
            return (
              <TableCell align={filteredList[0].align} style={{ width: "5%" }}>
                {rowIndex + 1}
              </TableCell>
            );
          else if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell align={filteredList[0].align} style={{ width: "9%" }}>
                {row[key]}
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell style={{ width: "15%" }}>
                {row[key] == 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
      })}
      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "5" }}>
            {showAdd && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddIcon}
                  color="primary"
                  fontSize="medium"
                  title={showAddTitle ? showAddTitle : "Add Details"}
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}
            {SummarizeRoundedShow && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={SummarizeRoundedIcon}
                  color="error"
                  fontSize="medium"
                  title="PDF"
                  onClick={(e) => onClickPrint(e, row)}
                />
              </>
            )}
            {showView && docType == "1" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickViewPreview(e, row)}
                />
              </>
            )}
            {showDelete && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showDetailsIcon &&
              detailConditionName != "" &&
              row[detailConditionName] == detailConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={ListAltIcon}
                    color="primary"
                    fontSize="medium"
                    title="Show Details"
                    onClick={(e) => appliedList(e, row)}
                  />
                </>
              )}
            {showApply && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={showApplyTitle ? showApplyTitle : "Send Credential"}
                  onClick={(e) => onClickApply(e, row)}
                />
              </>
            )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={showApplyTitle ? showApplyTitle : "Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}
            {showDownload && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DownloadIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickDownload(e, row)}
                />
              </>
            )}

            {showViewButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickView(e, row)}
                />
              </>
            )}
          </TableCell>
          {docType == "0" && (
            <TableCell align={"center"} style={{ width: "5" }}>
              <>
                <Avatar
                  src={row.fileName}
                  sx={{ width: 80, height: 80 }}
                  variant="rounded"
                  onClick={(e) => onClickViewPreview(e, row)}
                />
              </>
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
}
