import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

import swal from "sweetalert";
import {
  checkedListEmptyMsg,
  dateMsg,
  deleteConfirmMsg,
  deleteFailedMsg,
  deletemsg,
  noInternetMsg,
  publishDateMsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";
import { showNotification, showLoador } from "../Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import dayjs from "dayjs";
import moment from "moment";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AssignationPopUp = ({
  handleCloses,
  open,
  showNotification,
  showLoador,
  rows,
  DataToSet,
  productRateStructureIdFlag,
}) => {
  const [publishDate, setPublishDate] = React.useState("");
  const [rowData, setRowData] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [applyDate, setApplyDate] = React.useState("");
  const [allChecked, setAllChecked] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    setRowData(rows);
  }, []);

  const handleClose = () => {
    handleCloses(1);
  };
  const handleAllCheckedClick = (event) => {
    const { checked } = event.target;
    setAllChecked(checked);
    console.log(checked);
    let data = rowData.map((publishObject) => {
      return {
        ...publishObject,
        isChecked: true,
      };
    });
    console.log(data);
    setRowData(data);
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (applyDate === "") {
      formIsValid = false;
      formErrors["applyDateError"] = dateMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const onChangeApplyToAll = () => {
    let productRateStructureIds = [];
    let publishStructure = {};
    let dtoStockHolderAndAssignations = [];

    productRateStructureIds = rowData.map(
      (publishObject) => publishObject.productRateStructureId
    );

    let data = rowData.map((publishObject) => {
      console.log(publishObject);
      return {
        ...publishObject,
        publishDate: dayjs(applyDate).format("DD-MM-YYYY"),
      };
    });

    setRowData(data);

    dtoStockHolderAndAssignations = rowData.map((publishObject) => {
      return {
        productRateStructureAssignationId: publishObject.id,
        stockStakeHolderId: publishObject.stockStakeHolderId,
      };
    });

    rowData.map((publishObject) => {
      publishStructure = {
        productRateStructureId: publishObject.productRateStructureId,
        publishDate: dayjs(applyDate).format("DD-MM-YYYY"),
        dtoStockHolderAndAssignations: dtoStockHolderAndAssignations,
      };
    });


    let publishStructureDataJson = {
      productRateStructureIds: productRateStructureIds,
      date: dayjs(applyDate).format("DD-MM-YYYY"),
    };

    if (navigator.onLine) {
      if (checkValidation()) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.productRateStructureAssignation +
            "/by-publlish-date-and-product-rate-structure",
          postBody: publishStructureDataJson,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (data.data.length !== 0) {
              showNotification({
                msg: publishDateMsg,
                severity: "error",
              });
            } else {
              onSaveAll(publishStructure);
            }
          } else {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onDateChange = (row, name) => (date) => {
    if (name == "applyToAll") {
      setApplyDate(date);
    } else {
      setPublishDate(date);
      let data = rowData.map((publishObject) => {
        if (publishObject.id == row.id) {
          return {
            ...publishObject,
            publishDate: date,
            publishDateToSave: date.format("DD-MM-YYYY"),
          };
        } else {
          return publishObject;
        }
      });
      setRowData(data);
    }
  };

  const handleClick = (e, id) => {
    console.log(e);
    let data = rowData.map((publishObject) => {
      if (publishObject.id == id) {
        return {
          ...publishObject,
          isChecked: e.target.checked,
        };
      } else {
        return publishObject;
      }
    });
    setRowData(data);
  };

  const onUpdateData = () => {
    let publishStructure = {};
    let dtoStockHolderAndAssignations = [];
    let checkedList = [];

    if (rowData.length !== 0) {
      rowData.map((publishObject) => {
        if (publishObject.isChecked) {
          dtoStockHolderAndAssignations.push({
            productRateStructureAssignationId: publishObject.id,
            stockStakeHolderId: publishObject.stockStakeHolderId,
          });

          publishStructure = {
            productRateStructureId: publishObject.productRateStructureId,
            publishDate: dayjs().format("DD-MM-YYYY"),
            dtoStockHolderAndAssignations: dtoStockHolderAndAssignations,
          };
        }
      });
    }

    console.log(publishStructure);

    if (dtoStockHolderAndAssignations.length !== 0) {
      checkedList.push(publishStructure);
      onSaveAll(publishStructure);
    } else {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    }
  };



  const onSaveAll = (checkedValue) => {
    console.log(checkedValue);
    swal({
      title: "Are you sure?",
      text: updateData,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(checkedValue);
        showLoador({ loador: true });
        apiPost({
          url: endpoint.productRateStructureAssignation + "/save",
          postBody: checkedValue,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({
              msg: updateMsg,
            });
            handleCloses(0);
            window.location.reload("/product-rate-structure-list");
          }
        });
      }
    });
  };

  const onDeleteIds = () => {
    if (rowData.length !== 0) {
      rowData.map((deleteObject) => {
        if (deleteObject.isChecked) {
          swal({
            title: "Are you sure?",
            text: updateData,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(checkedList);
              showLoador({ loador: true });
              apiDelete({
                url:
                  endpoint.productRateStructureAssignation +
                  "/delete-by-ids?ids=" +
                  deleteObject.id,
              }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                  showNotification({ msg: deletemsg });
                  window.location.replace("/product-rate-structure-list");
                } else {
                  showNotification({
                    msg: deleteFailedMsg,
                    severity: "error",
                  });
                }
              });
            }
          });
        } else {
          showNotification({
            msg: checkedListEmptyMsg,
            severity: "error",
          });
        }
      });
    }
  };

  const onClickDelete = (e, row) => {
    console.log(row);
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiDelete({
          url: endpoint.productRateStructureAssignation + "/" + row.id,
        }).then(({ success }) => {
          if (success) {
            showLoador({ loador: false });
            showNotification({ msg: deletemsg });
          } else {
            showNotification({
              msg: deleteFailedMsg,
              severity: "error",
            });
          }
        });
      }
    });
  };


  const onSearchBox = (searchValue) => {
    setSearchValue(searchValue);
  };

  const getFilteredTableData = (searchData) => {
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["firmName"] &&
          currentRow["firmName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          currentRow["ownerName"] &&
          currentRow["ownerName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          currentRow["areaName"] &&
          currentRow["areaName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())
        )
      ) {
        isValid = true;
        return true;
      }
    });
  };



  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Assigned List
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <DynamicDetails DataToSet={DataToSet} />

          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={onSearchBox}
          />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      color="error"
                      onClick={(event) => handleAllCheckedClick(event)}
                      checked={allChecked}
                      disabled={false}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    /> */}
                  </TableCell>
                  <TableCell align="center">Sr. No.</TableCell>
                  <TableCell align="center">Stock Holder</TableCell>
                  <TableCell align="center">Area Name</TableCell>
                  <TableCell align="center" width="300">
                    Publish Date
                  </TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getFilteredTableData(rowData)?.map((row, index) => (
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        checked={row.isChecked}
                        disabled={false}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="left">
                      {row.firmName + " - " + row.ownerName}
                    </TableCell>
                    <TableCell align="left">{row.areaName}</TableCell>
                    <TableCell>
                      <BasicDatePicker
                        color="primary"
                        name="publishDate"
                        size="small"
                        value={dayjs(row.publishDate)}
                        // value={dayjs("12-01-2023")}
                        disabled={false}
                        onChange={onDateChange(row, "publishDate")}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <MyComponentWithIconProps
                        statusImage={DeleteIcon}
                        color="error"
                        fontSize="medium"
                        title="Delete"
                        onClick={(e) => onClickDelete(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="right"
            ></Grid>
            <Grid
              item
              sm={12}
              xs={2}
              md={2}
              lg={2}
              container
              justifyContent="right"
            >
              <BasicDatePicker
                color="primary"
                name="applyToAll"
                size="small"
                // value={new Date(row.publishDate)}
                value={applyDate}
                disabled={false}
                onChange={onDateChange([], "applyToAll")}
                isError={
                  formErrors["applyDateError"]
                    ? formErrors["applyDateError"]
                    : ""
                }
                errorText={
                  formErrors["applyDateError"]
                    ? formErrors["applyDateError"]
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12} xs={4} md={4} lg={4} justifyContent="right">
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Update All"
                onClick={onChangeApplyToAll}
              />
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Update"
                onClick={onUpdateData}
              />
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Delete"
                onClick={onDeleteIds}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  publishList: state.stockholder,
});

const mapDispatchToProps = {
  showNotification,
  showLoador,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignationPopUp);
