import React, { useState } from "react";
import "../../../CSS/customCalendar.css";
import { Paper } from "@mui/material";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";

const Calendar = () => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const [selectedDate, setSelectedDate] = useState(today.getDate());
    const [dataList, setDataList] = useState([
        {
            uuid: "1686453c-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-01",
            date: "01-09-24",
            flag: 4,
            checkInTime: "-",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
        {
            uuid: "16864547-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-02",
            date: "02-09-24",
            flag: 2,
            checkInTime: "10:55 AM",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
        {
            uuid: "16864552-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-03",
            date: "03-09-24",
            flag: 2,
            checkInTime: "07:50 AM",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
        {
            uuid: "1686446a-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-04",
            date: "04-09-24",
            flag: 1,
            checkInTime: "07:41 PM",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
        {
            uuid: "16864475-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-05",
            date: "05-09-24",
            flag: 0,
            checkInTime: "-",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
        {
            uuid: "1686447f-8f9a-11ef-8a4f-000c29c9f32f",
            id: "855f6dd6-3a41-411f-b43b-d27829c1633b",
            userId: "5450f117-a024-4c42-9cd7-b6bd0253e485",
            userName: "SH6G7MC8",
            stockStakeHolderTypeId: "4c7e5e78-a826-4ecc-82e6-a29853edc27a",
            name: "Abhijeet warghat",
            selectedDate: "2024-09-06",
            date: "06-09-24",
            flag: 3,
            checkInTime: "01:21 PM",
            checkOutTime: "-",
            workingHrs: "00:00"
        },
    ])

    const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const goToNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const goToPrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const getFirstDayOfMonth = (month, year) => {
        return new Date(year, month, 1).getDay();
    };

    const getDateFlag = (day, month, year) => {
        const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const dataItem = dataList.find(item => item.selectedDate === dateString);
        return dataItem ? dataItem.flag : null;
    };

    const renderDays = () => {
        const daysInMonth = getDaysInMonth(currentMonth, currentYear);
        const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);
        const daysArray = [];

        for (let i = 0; i < firstDayOfMonth; i++) {
            daysArray.push(<div className="calendar-day empty" key={`empty-${i}`}></div>);
        }

        for (let day = 1; day <= daysInMonth; day++) {
            const isSelected = selectedDate === day && today.getMonth() === currentMonth && today.getFullYear() === currentYear;
            const isToday = day === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear();
            const flag = getDateFlag(day, currentMonth, currentYear);

            let bgColor = "";
            switch (flag) {
                case 0: // Absent
                    bgColor = "red";
                    break;
                case 1: // Late Check-In
                    bgColor = "orange";
                    break;
                case 2: // In-Time Check-In
                    bgColor = "lightgreen";
                    break;
                case 3: // On Leave
                    bgColor = "grey";
                    break;
                case 4: // Weekly Off
                    bgColor = "lightblue";
                    break;
                default:
                    bgColor = ""; // No flag, default background
            }

            daysArray.push(
                <div
                    key={day}
                    className={`calendar-day ${isSelected ? "selected" : ""} ${isToday ? "current-day-highlight" : ""}`}
                    style={{ backgroundColor: bgColor }}
                    onClick={() => setSelectedDate(day)}
                >
                    {day}
                </div>
            );
        }

        return daysArray;
    };

    return (
        <>
            <Paper
                sx={{
                    padding: 2,
                }}
                elevation={0}
            >
                <LandingScreenHeader
                    screenTitle={"Calendar"}
                    showTable={false}
                    showBackButton={true}
                    showAddButton={false}
                />

                <br />
                <div className="calendar-container">
                    <div className="calendar-header">
                        <div className="current-day">{today.toLocaleDateString("en-US", { weekday: "long" })}</div>
                        <div className="current-date">
                            {today.toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                            })}
                        </div>
                    </div>

                    <div className="calendar-navigation">
                        <button onClick={goToPrevMonth}>{"<"}</button>
                        <div className="month-year">
                            {new Date(currentYear, currentMonth).toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                            })}
                        </div>
                        <button onClick={goToNextMonth}>{">"}</button>
                    </div>

                    <div className="calendar-grid">
                        {daysOfWeek.map((day, index) => (
                            <div className="calendar-day-header" key={index}>
                                {day}
                            </div>
                        ))}
                        {renderDays()}
                    </div>
                </div>
            </Paper>
        </>
    );
};

export default Calendar;
