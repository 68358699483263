import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { redColor, titleColor } from "../../config/ColorObj";
import { userSummaryJson } from "../../DynamicFormsJson/Transaction/userSummary";
import CategoryWise from "./categoryWise";
import SubCategoryWise from "./subCategoryWise";

const subCatListWithValue = [
  {
    id: "1",
    name: "sales person 1",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 3,
          },
          {
            subCatId: 2,
            subCatName: "sub category 2",
            value: 32,
          },
        ],
      },
      {
        catId: 2,
        name: "category 2",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 3,
          },
          {
            subCatId: 2,
            subCatName: "sub category 2",
            value: 343,
          },
          {
            subCatId: 3,
            subCatName: "sub category 3",
            value: 3,
          },
        ],
      },
      {
        catId: 3,
        name: "category 3",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 555,
          },
        ],
      },
    ],
  },
  {
    id: "2",
    name: "sales person 2",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 3,
          },
          {
            subCatId: 2,
            subCatName: "sub category 2",
            value: 3,
          },
        ],
      },
      {
        catId: 2,
        name: "category 2",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 3,
          },
          {
            subCatId: 2,
            subCatName: "sub category 2",
            value: 11,
          },
          {
            subCatId: 3,
            subCatName: "sub category 3",
            value: 22,
          },
        ],
      },
      {
        catId: 3,
        name: "category 3",
        subCategory: [
          {
            subCatId: 1,
            subCatName: "sub category 1",
            value: 576,
          },
        ],
      },
    ],
  },
];

const subCatList = [
  {
    catId: 1,
    name: "category 1",
    subCategory: [
      {
        subCatId: 1,
        subCatName: "sub category 1",
      },
      {
        subCatId: 2,
        subCatName: "sub category 2",
      },
    ],
  },
  {
    catId: 2,
    name: "category 2",
    subCategory: [
      {
        subCatId: 1,
        subCatName: "sub category 1",
      },
      {
        subCatId: 2,
        subCatName: "sub category 2",
      },
      {
        subCatId: 3,
        subCatName: "sub category 3",
      },
    ],
  },
  {
    catId: 3,
    name: "category 3",
    subCategory: [
      {
        subCatId: 1,
        subCatName: "sub category 1",
      },
    ],
  },
];

const categoryList = [
  {
    catId: 1,
    name: "category 1",
  },
  {
    catId: 2,
    name: "category 2",
  },
  {
    catId: 3,
    name: "category 3",
  },
  {
    catId: 4,
    name: "category 4",
  },
  {
    catId: 5,
    name: "category 5",
  },
  {
    catId: 6,
    name: "category 6",
  },
  {
    catId: 7,
    name: "category 7",
  },
  {
    catId: 8,
    name: "category 8",
  },
  {
    catId: 9,
    name: "category 9",
  },
  {
    catId: 10,
    name: "category 10",
  },
];

const salesteamList = [
  {
    id: "1",
    name: "sales person 1",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "2",
    name: "sales person 2",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 2400.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "3",
    name: "sales person 3",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "4",
    name: "sales person 4",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "5",
    name: "sales person 5",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "6",
    name: "sales person 6",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "7",
    name: "sales person 7",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "8",
    name: "sales person 8",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "9",
    name: "sales person 9",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 1200.0,
      },
    ],
  },
  {
    id: "10",
    name: "sales person 10",
    categoryList: [
      {
        catId: 1,
        name: "category 1",
        value: 1200.0,
      },
      {
        catId: 2,
        name: "category 2",
        value: 1200.0,
      },
      {
        catId: 3,
        name: "category 3",
        value: 1200.0,
      },
      {
        catId: 4,
        name: "category 4",
        value: 1200.0,
      },
      {
        catId: 5,
        name: "category 5",
        value: 1200.0,
      },
      {
        catId: 6,
        name: "category 6",
        value: 1200.0,
      },
      {
        catId: 7,
        name: "category 7",
        value: 1200.0,
      },
      {
        catId: 8,
        name: "category 8",
        value: 1200.0,
      },
      {
        catId: 9,
        name: "category 9",
        value: 1200.0,
      },
      {
        catId: 10,
        name: "category 10",
        value: 30003.0,
      },
    ],
  },
];
class UserSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: "0",
    };
  }

  async componentDidMount() {}

  onSearch = (data) => {
    const { radioValue } = this.state;
    console.log(data);
    console.log(radioValue);
  };

  handleChange = (event, newValue) => {
    this.setState({
      radioValue: newValue,
    });
  };
  render() {
    const {} = this.props;
    const { radioValue } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={[]}
            getListById={this.getListById}
            searchList={userSummaryJson.searchList}
            showSaveBtn={false}
            showSaveBtnMain={true}
            showTitle={userSummaryJson.showTitle}
            screenTitle={userSummaryJson.screenTitle}
            fieldMeta={userSummaryJson.fieldMeta}
            showCancel={userSummaryJson.showCancel}
            apiBaseURL={userSummaryJson.apiBaseURL}
            showSaveNextBtn={userSummaryJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
            <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Type"}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioValue}
                  onChange={this.handleChange}
                  row
                  defaultValue={radioValue}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Category Wise"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Sub-Category Wise"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          {radioValue == "0" ? (
            <CategoryWise
              categoryList={categoryList}
              salesteamList={salesteamList}
            />
          ) : (
            <SubCategoryWise
              subCatList={subCatList}
              subCatListWithValue={subCatListWithValue}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(UserSummary);
