import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import {
  beatAssignedMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { assignBeatJson } from "../../DynamicFormsJson/Transaction/assignBeat";
import { getAreaByUserType } from "../../Slice/area.slice";
import {
  getBeatBySalesTeamIdAreaIdUpdated,
  setBeatBySalesTeamIdAreaId,
} from "../../Slice/beat.slice";
import { getOutletByBeatId } from "../../Slice/outlet.slice";
import { getSalesExecutiveTypeByAreaId } from "../../Slice/salesExecutive.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OutletListTable from "./OutletListTable";
import { getRegionUpdated } from "../../Slice/region.slice";
const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Assign To",
    name: "salesTeamName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    canSearch: true,
  },
  {
    title: "Number of Outlet",
    name: "noOfOutlet",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch: true,
  },
  {
    title: "Action",
    name: "action",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
  },
];
class AssignBeatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesTeamId: "",
      areaId: "",
      uncheckedBeatIdsFromSelectedList: "",
      setList: [],
      checkList: [],
      isError: false,
      areaName: "",
      salesTeamName: "",
      open: false,
      DataToSet: [],
      beatName: "",
      adminFlag: false,
      fieldDataForModule: {},
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
        beatDetail: this.props.beatList.beat,
      },
      openOutletDialog: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        let totalOutlet = 0;
        let totalAssignOutlet = 0;
        let totalNotAssignOutlet = 0;
        nextProps.beatList?.beat.map((beatList) => {
          totalOutlet = totalOutlet + +beatList.noOfOutlet;
        });

        nextProps.beatList?.beat.map((beatList) => {
          if (beatList.flag == 0 || beatList.flag == 2) {
            totalAssignOutlet = totalAssignOutlet + 1;
          } else {
            totalNotAssignOutlet = totalNotAssignOutlet + 1;
          }
        });

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
          },
          DataToSet: [
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats ",
              value: nextProps.beatList?.beat.length,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Outlets ",
              value: totalOutlet,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats Assign",
              value: totalAssignOutlet,
            },
            {
              xs: 12,
              sm: 3,
              md: 3,
              lg: 3,
              label: "Number of Beats Not Assign",
              value: totalNotAssignOutlet,
            },
          ],
        });
      }
    }
  }

  async componentDidMount() {
    const { getAreaByUserType, setBeatBySalesTeamIdAreaId, getRegionUpdated, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");
      console.log(userTypeId);
      if (userTypeId == 1 || userTypeId == 6) {
        showLoador({ loador: true });
        getRegionUpdated().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

        this.setState({
          adminFlag: true,
        });
      }

      setBeatBySalesTeamIdAreaId({ row: [] });


      if (userTypeId != 1 && userTypeId != 6) {
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }


      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      getSalesExecutiveTypeByAreaId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getSalesExecutiveTypeByAreaId({ areaId: data.area.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    const {
      getBeatBySalesTeamIdAreaIdUpdated,
      areaList,
      salesExecutive,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: data.salesExecutive.id,
        areaId: data.area.id,
      });
      showLoador({ loador: true });
      getBeatBySalesTeamIdAreaIdUpdated({
        salesTeamId: data.salesExecutive.id,
        areaId: data.area.id,
      }).then(({ response, success, selectedList }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            setList: selectedList,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    const filterArea = areaList?.areaByTaluka.filter(
      (rowData) => rowData.id == data.area.id
    );
    if (filterArea.length != 0) {
      this.setState({
        areaName: filterArea[0].name,
      });
    }

    const filtersalesExecutive = salesExecutive?.salesExecutive.filter(
      (rowData) => rowData.id == data.salesExecutive.id
    );

    if (filtersalesExecutive.length != 0) {
      this.setState({
        salesTeamName: filtersalesExecutive[0].name,
        beatName: filtersalesExecutive[0].beatName,
      });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  getRow = (e) => {
    const { beatList, setBeatBySalesTeamIdAreaId } = this.props;
    this.setState({ setList: e });

    const setBeatList = beatList.beat.map((beatListObject) => {
      let filterData = e.filter((row) => row == beatListObject.id);
      if (filterData.length != 0) {
        beatListObject = {
          ...beatListObject,
          checked: true,
        };
        return beatListObject;
      }
      beatListObject = {
        ...beatListObject,
        checked: false,
      };
      return beatListObject;
    });

    const filteredBeatIds = setBeatList
      .filter((beat) => beat.flag === 0 && !beat.checked)
      .map((beat) => beat.id);

    this.setState({
      uncheckedBeatIdsFromSelectedList: filteredBeatIds,
    });

    setBeatBySalesTeamIdAreaId({ row: setBeatList });
  };
  onSubmit = () => {
    const { beatList, showLoador, showNotification } = this.props;
    const { salesTeamId, areaId, uncheckedBeatIdsFromSelectedList } = this.state;
    let filterBeatList = [];
    beatList.beat.map((beatListObject) => {
      if (beatListObject.checked) {
        const beatListObjectToSave = {
          beat: {
            id: beatListObject.id,
          },
          salesTeam: {
            id: salesTeamId,
          },
        };
        filterBeatList.push(beatListObjectToSave);
      }
    });
    let beatNewObjectToSave = {
      salesTeamId: salesTeamId,
      areaId: areaId,
      beatAssign: filterBeatList,
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {

        showLoador({ loador: true });
        if (uncheckedBeatIdsFromSelectedList.length !== 0) {
          apiPost({
            url:
              endpoint.dayWiseBitAssign +
              "/check-beat-assign-or-not-by-sales-team-and-beats?salesTeamId=" +
              salesTeamId +
              "&beatIds=" +
              uncheckedBeatIdsFromSelectedList,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (data.data.statusCode === 500) {
                showNotification({ msg: beatAssignedMsg, severity: "error" });
              } else {
                this.onSave(beatNewObjectToSave);
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          this.onSave(beatNewObjectToSave);
        }
      }
    });
  };
  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.assignBeat + "/save-list-new",
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/assign-beat-form");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowAppliedList = (rowData) => {
    this.setState({
      beatName: rowData.beatName,
    });
    const { getOutletByBeatId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getOutletByBeatId({
        beatId: rowData.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            openOutletDialog: true,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleClosesForOutlet = () => {
    const { openOutletDialog } = this.state;
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };

  render() {
    const { beatList, outletList } = this.props;
    const {
      dynamicMasterData,
      setList,
      areaName,
      salesTeamName,
      beatName,
      openOutletDialog,
      DataToSet,
      fieldDataForModule,
      adminFlag,
    } = this.state;
    console.log(adminFlag);
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={assignBeatJson.searchList}
            showSaveBtn={false}
            showSaveBtnMain={true}
            showTitle={assignBeatJson.showTitle}
            screenTitle={assignBeatJson.screenTitle}
            fieldMeta={
              adminFlag
                ? assignBeatJson.fieldMetaAdmin
                : assignBeatJson.fieldMeta
            }
            showCancel={assignBeatJson.showCancel}
            apiBaseURL={assignBeatJson.apiBaseURL}
            showSaveNextBtn={assignBeatJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />
          <DynamicDetails DataToSet={DataToSet} screenTitle={"Assign Beat"} />

          <CheckBoxSelectionTable
            disabled={true}
            rows={beatList.beat}
            columns={columns}
            selection={setList}
            showDetailsIcon={true}
            rowAppliedList={this.rowAppliedList}
            isActionColActive={true}
            onSelectionChange={this.getRow}
          />
          {beatList?.beat.length != 0 && fieldDataForModule.editReject == 1 ? (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Paper>
        {openOutletDialog && (
          <OutletListTable
            areaName={areaName}
            salesTeamName={salesTeamName}
            beatName={beatName}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Outlet Name",
                name: "firmName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
            ]}
            open={openOutletDialog}
            handleCloses={this.handleClosesForOutlet}
            onFormSave={this.rowAppliedList}
            rows={outletList?.outlets}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  managerList: state.manager,
  areaList: state.area,
  salesExecutive: state.salesExecutive,
  beatList: state.beat,
  outletList: state.outlet,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getAreaByUserType,
  getRegionUpdated,
  getSalesExecutiveTypeByAreaId,
  getBeatBySalesTeamIdAreaIdUpdated,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getOutletByBeatId,
  showLoador,
  showNotification,
  setBeatBySalesTeamIdAreaId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignBeatForm);
