import endpoint from "../../config/endpoints";

export const grievanceJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.grievance,
  formPath: "/grievance-form",
  screenTitle: "Grievance",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  changeExcel: endpoint.exportData,
  excelUrl: "t-grivience",


  tableColumnsToFilter: [
    {
      columnDisplayName: "Firm Name",
      columnKeyName: "firmName",
      isChecked: true,
    }, {
      columnDisplayName: "Category",
      columnKeyName: "categoryName",
      isChecked: true,
    }, {
      columnDisplayName: "Complaint By",
      columnKeyName: "userName",
      isChecked: true,
    },
    {
      columnDisplayName: "Complaint",
      columnKeyName: "remark",
      isChecked: true,
    },
    {
      columnDisplayName: "Date",
      columnKeyName: "insertDateTime",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "fromdate",
      isMandatory: true,

    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "todate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "type",
      tableDataKey: "type",
      isRootLevelKey: true,
      masterName: "typeList",
      isMandatory: true,
    },
    {
      label: "Status",
      controlType: "autocomplete",
      placeHolder: "Select Status",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "status",
      tableDataKey: "status",
      isRootLevelKey: true,
      masterName: "statusList",
      isMandatory: true,
    },
  ],
};
