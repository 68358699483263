import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PaidIcon from "@mui/icons-material/Paid";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SchoolIcon from "@mui/icons-material/School";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import * as React from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../IconButton";
import LandingScreenHeader from "../LandingScreenHeader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    isActionColActive,
    hiddenColumnNames,
    order,
    tableHead,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          const hiddenColumn = hiddenColumnNames.filter(
            (hiddenCol) => hiddenCol === headCell.name
          );
          if (
            headCell.showInscreen &&
            headCell.title !== "Action" &&
            hiddenColumn.length == 0
          ) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                // sortDirection={orderBy === headCell.name ? order : false}
              >
                <TableSortLabel
                  active={true}
                  direction={orderBy === headCell.name ? order : "asc"}
                  onClick={createSortHandler(headCell.name)}
                  className="tableCellHeaderColor"
                >
                  {headCell.name == "index" ? "#" : headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else if (
            headCell.showInscreen &&
            isActionColActive &&
            headCell.title === "Action" &&
            hiddenColumn.length == 0
          ) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                // sortDirection={orderBy === headCell.name ? order : false}
              >
                <TableSortLabel
                  active={true}
                  direction={orderBy === headCell.name ? order : "asc"}
                  onClick={createSortHandler(headCell.name)}
                  className="tableCellHeaderColor"
                >
                  {headCell.name == "index" ? "#" : headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableHead,
  rowStatus,
  rowAdd,
  showAddButtonInTable,
  statusChange,
  rowDelete,
  rowEdit,
  rowId,
  rowView,
  rowApply,
  rowTransfer,
  rowCopy,
  rowPDF,
  acceptDetails,
  rejectDetails,
  generateBill,
  rowViewData,
  rowLinkToShowData,
  showHeadEdit,
  showHeadDelete,
  studListEmpty = false,
  msg,
  showHeadAdd,
  showApply,
  showApplyCredintial,
  showTransferButtonInTable = false,
  showCopyIcon = false,
  copyConditionName = "",
  copyConditionValue = "",
  showView,
  showPdf = false,
  showRoundDetails,
  showRoundDetailsNew,
  rounds,
  isActionColActive,
  showPayFees,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showSchoolIcon,
  showLinkIcon,
  showPersonAdd,
  rowPersonAddDetails,
  rowFeesPaid,
  rowAppliedList,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPaymentDetails,
  rowLink,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  showAcceptIcon,
  acceptConditionName,
  acceptConditionValue,
  showGenerateBillIcon,
  generateBillConditionName,
  generateBillConditionValue,
  rejectConditionValue,
  rejectConditionName,
  viewConditionValue,
  viewConditionName,
  showCloseIcon,
  condition = [],
  pdfDetailsId,
  excelDetailsId,
  showFillMarks,
  rowFillMarks,
  showComment,
  rowComment,
  alertMail,
  hiddenColumnNames,
  credentialConditionName = "",
  credentialConditionValue = "",
  showApplyTitle = "",
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row);
  };
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };
  const onClickFillMarks = (e, row) => {
    e.stopPropagation();
    rowFillMarks(row);
  };

  const changeRowStatus = (e, row) => {
    e.stopPropagation();
    statusChange(row);
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };
  const pdfDetails = (e, row) => {
    e.stopPropagation();
    pdfDetailsId(row);
  };
  const excelDetails = (e, row) => {
    e.stopPropagation();
    excelDetailsId(row);
  };
  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickRowId = (e, id) => {
    e.stopPropagation();
    rowId(id);
  };
  const onClickApply = (e, id) => {
    e.stopPropagation();
    rowView(id);
  };

  const onClickViewPreview = (e, row) => {
    e.stopPropagation();
    rowViewData(row);
  };

  const onClickLinkToShowData = (e, row, tableRow) => {
    e.stopPropagation();
    rowLinkToShowData(row, tableRow);
  };

  const onClickApplySend = (e, row) => {
    e.stopPropagation();
    rowApply(row);
  };

  const onClickTransfer = (e, row) => {
    e.stopPropagation();
    rowTransfer(row);
  };

  const onClickCopy = (e, row) => {
    e.stopPropagation();
    rowCopy(row);
  };

  const generatePDF = (e, row) => {
    e.stopPropagation();
    rowPDF(row);
  };

  const onClickComment = (e, row) => {
    e.stopPropagation();
    rowComment(row);
  };

  const roundForm = (e, id) => {
    e.stopPropagation();
    rounds(id);
  };

  const feesPaid = (e, params) => {
    e.stopPropagation();
    rowFeesPaid(params);
  };
  const appliedList = (e, params) => {
    e.stopPropagation();
    rowAppliedList(params);
  };

  const details = (e, params) => {
    e.stopPropagation();
    rowDetails(params);
  };
  const schoolDetails = (e, params) => {
    e.stopPropagation();
    rowSchoolDetails(params);
  };
  const linkDetails = (e, params) => {
    e.stopPropagation();
    generateDetails(params);
  };

  const onClickAccept = (e, params) => {
    e.stopPropagation();
    acceptDetails(params);
  };

  const onCLickGenerateBill = (e, params) => {
    e.stopPropagation();
    generateBill(params);
  };

  const onClickReject = (e, rows) => {
    console.log(e);
    console.log(rows);
    e.stopPropagation();
    rejectDetails(rows);
  };

  const personAddDetails = (e, params) => {
    e.stopPropagation();
    rowPersonAddDetails(params);
  };

  const paymentDetails = (e, params) => {
    e.stopPropagation();
    rowPaymentDetails(params);
  };
  const onClickLink = (e, params) => {
    e.stopPropagation();
    rowLink(params);
  };

  let conditionApplied = condition.filter((rowData) => {
    if (row[rowData.title]) {
      return row;
    } else {
      return [];
    }
  });
  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );
        const hiddenColumn = hiddenColumnNames.filter(
          (hiddenCol) => hiddenCol === key
        );
        if (filteredList.length !== 0 && hiddenColumn.length == 0) {
          if (
            filteredList[0].positionCenter &&
            key !== "isActive" &&
            key !== "isStatus"
          )
            return (
              <TableCell>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell>
                {row[key] === 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (key === "isStatus") {
            return (
              <TableCell>
                {row[key] === "Completed" ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (key === "isStatus") {
            return (
              <TableCell>
                {row[key] === "Completed" ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell
                align={key == "index" ? "center" : filteredList[0].alignCenter}
              >
                {filteredList[0].linkFlag ? (
                  <Link
                    component="button"
                    color="secondary"
                    onClick={(e) => onClickLink(e, row)}
                  >
                    {row[key]}
                  </Link>
                ) : (
                  row[key]
                )}
              </TableCell>
            );
          if (filteredList[0].linkOpen)
            return (
              <TableCell
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                <div
                  onClick={(e) =>
                    onClickLinkToShowData(e, row, filteredList[0])
                  }
                  style={{
                    color: cyanBlue,
                    cursor: "pointer",
                  }}
                >
                  {row[key]}
                </div>
              </TableCell>
            );
          else
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {row[key]}
              </TableCell>
            );
        }
        return null;
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"}>
            {role !== "parent" &&
            condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide === "showHeadEdit" &&
            showHeadEdit &&
            role !== "parent" &&
            row[conditionApplied[0].title] !== conditionApplied[0].value ? (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  sx={{ marginTop: marginTop }}
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            ) : role !== "parent" &&
              condition.length !== 0 &&
              showHeadEdit &&
              conditionApplied.length !== 0 &&
              role !== "parent" &&
              conditionApplied[0].iconHide !== "showHeadEdit" ? (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showHeadEdit" &&
              row[conditionApplied[0].title] === conditionApplied[0].value && (
                <> &nbsp;&nbsp;&nbsp;&emsp;</>
              )
            )}
            {role !== "parent" && condition.length === 0 && showHeadEdit && (
              <MyComponentWithIconProps
                statusImage={EditIcon}
                color="primary"
                fontSize="medium"
                sx={{ marginTop: marginTop }}
                title="Edit"
                onClick={(e) => onClickEdit(e, row)}
              />
            )}
            {showFillMarks && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Fill Marks"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickFillMarks(e, row)}
                />
              </>
            )}
            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide === "showHeadDelete" &&
            showHeadDelete &&
            role !== "parent" &&
            row[conditionApplied[0].title] !== conditionApplied[0].value ? (
              <>
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  sx={{ marginTop: marginTop }}
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            ) : condition.length !== 0 &&
              showHeadDelete &&
              conditionApplied.length !== 0 &&
              role !== "parent" &&
              conditionApplied[0].iconHide !== "showHeadDelete" ? (
              <>
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showHeadDelete" &&
              row[conditionApplied[0].title] === conditionApplied[0].value && (
                <> &nbsp;&nbsp;&nbsp;&emsp;</>
              )
            )}
            {condition.length === 0 && showHeadDelete && role !== "parent" && (
              <MyComponentWithIconProps
                statusImage={DeleteIcon}
                color="error"
                fontSize="medium"
                sx={{ marginTop: marginTop }}
                title="Delete"
                onClick={(e) => onClickDelete(e, row)}
              />
            )}
            {showHeadAdd && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddIcon}
                  color="primary"
                  fontSize="medium"
                  title="Add"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickRowId(e, row)}
                />
              </>
            )}
            {showApply && role !== "parent" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={alertMail ? "Send Mail" : "Generate Bill"}
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickApply(e, row)}
                  // onClick={onClickView}
                />
              </>
            )}
            {showView && viewConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickViewPreview(e, row)}
                />
              </>
            )}
            {showView &&
              viewConditionName != "" &&
              row[viewConditionName] == viewConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={VisibilityIcon}
                    color="primary"
                    fontSize="medium"
                    title="View"
                    sx={{ marginTop: marginTop }}
                    onClick={(e) => onClickViewPreview(e, row)}
                  />
                </>
              )}
            {showPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDF(e, row)}
                />
              </>
            )}
            {showApplyCredintial && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={showApplyTitle ? showApplyTitle : "Send Credential"}
                  onClick={(e) => onClickApplySend(e, row)}
                />
              </>
            )}
            {showTransferButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MoveUpIcon}
                  color="primary"
                  fontSize="medium"
                  title="Transfer"
                  onClick={(e) => onClickTransfer(e, row)}
                />
              </>
            )}
            {showCopyIcon && copyConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={CopyAllIcon}
                  color="primary"
                  fontSize="medium"
                  title="Copy To Save"
                  onClick={(e) => onClickCopy(e, row)}
                />
              </>
            )}
            {showCopyIcon &&
              copyConditionName != "" &&
              row[copyConditionName] == copyConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={CopyAllIcon}
                    color="primary"
                    fontSize="medium"
                    title="Copy To Save"
                    onClick={(e) => onClickCopy(e, row)}
                  />
                </>
              )}

            {showApplyCredintial &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={showApplyTitle ? showApplyTitle : "Send Credential"}
                    onClick={(e) => onClickApplySend(e, row)}
                  />
                </>
              )}
            {showApplyCredintial &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}
            {showComment && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={CommentIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => onClickComment(e, row)}
                />
              </>
            )}

            {showRoundDetails && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ListTwoToneIcon}
                  fontSize="medium"
                  color="primary"
                  title="Round Details"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => roundForm(e, row)}
                />
              </>
            )}

            {showRoundDetailsNew && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ListTwoToneIcon}
                  fontSize="medium"
                  color="primary"
                  title="Add Schedule"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => roundForm(e, row)}
                />
              </>
            )}
            {showAddButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  color="primary"
                  fontSize="medium"
                  title="Add Document"
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}
            {showPayFees && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={PaidIcon}
                  color="primary"
                  fontSize="medium"
                  title="Pay Fees"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => feesPaid(e, row)}
                  // onClick={feesPaid}
                />
              </>
            )}
            {showPaymentDetails && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReceiptIcon}
                  color="primary"
                  fontSize="medium"
                  title="Payment Details"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => paymentDetails(e, row)}
                  // onClick={paymentDetails}
                />
              </>
            )}

            {showAppliedList && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ListAltIcon}
                  color="primary"
                  fontSize="medium"
                  sx={{ marginTop: marginTop }}
                  title="Applied List"
                  onClick={(e) => appliedList(e, row)}
                />
              </>
            )}

            {showAppliedListNew && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ListAltIcon}
                  color="primary"
                  fontSize="medium"
                  sx={{ marginTop: marginTop }}
                  title="Applied List"
                  onClick={(e) => appliedList(e, row)}
                />
              </>
            )}

            {showDetails && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={InfoIcon}
                  color="primary"
                  fontSize="medium"
                  title="Details"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => details(e, row)}
                />
              </>
            )}
            {showSchoolIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={SchoolIcon}
                  color="primary"
                  fontSize="medium"
                  title="Details"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => schoolDetails(e, row)}
                />
              </>
            )}
            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            showLinkIcon &&
            row[conditionApplied[0].title] === conditionApplied[0].value ? (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={LinkIcon}
                  color="primary"
                  fontSize="medium"
                  title="Link"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => linkDetails(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              showLinkIcon && <> &nbsp;&nbsp;&nbsp;&emsp;</>
            )}
            {condition.length === 0 && showLinkIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={LinkIcon}
                  color="primary"
                  fontSize="medium"
                  title="Link"
                  sx={{ marginTop: marginTop }}
                  onClick={(e) => linkDetails(e, row)}
                />
              </>
            )}
            {showPersonAdd && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={PersonAddAlt1Icon}
                  color="primary"
                  fontSize="medium"
                  sx={{ marginTop: marginTop }}
                  title="Add"
                  onClick={(e) => personAddDetails(e, row)}
                />
              </>
            )}

            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide === "showHeadPdf" &&
            showHeadPdf &&
            row[conditionApplied[0].title] !== conditionApplied[0].value ? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            ) : condition.length !== 0 &&
              showHeadPdf &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide !== "showHeadPdf" ? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showHeadPdf" &&
              row[conditionApplied[0].title] === conditionApplied[0].value && (
                <> &nbsp;&nbsp;&emsp;</>
              )
            )}
            {condition.length === 0 && showHeadPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            )}
            {showHeadExcel && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  color="primary"
                  title="Excel"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => excelDetails(e, row)}
                />
              </>
            )}
            {showAcceptIcon &&
              acceptConditionName != "" &&
              row[acceptConditionName] == acceptConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={CheckIcon}
                    color="success"
                    fontSize="medium"
                    title="Accept"
                    sx={{ marginTop: marginTop }}
                    onClick={(e) => onClickAccept(e, row)}
                  />
                </>
              )}

            {showGenerateBillIcon &&
              generateBillConditionName != "" &&
              row[generateBillConditionName] == generateBillConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={ReceiptIcon}
                    color="primary"
                    fontSize="medium"
                    title="Show Details"
                    sx={{ marginTop: marginTop }}
                    onClick={(e) => onCLickGenerateBill(e, row)}
                  />
                </>
              )}
            {showCloseIcon &&
              rejectConditionName != "" &&
              row[rejectConditionName] == rejectConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={CloseIcon}
                    fontSize="medium"
                    title="Reject"
                    sx={{ marginTop: marginTop, color: "#ff0000" }}
                    onClick={(e) => onClickReject(e, row)}
                  />
                </>
              )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  tableHead,
  rowStatus,
  rowAdd,
  showAddButtonInTable,
  statusChange,
  rowDelete,
  showHeadDelete,
  rowEdit,
  data,
  hiddenColumnNames = [],
  studListEmpty = false,
  msg,
  showHeadEdit,
  rowId,
  showHeadAdd,
  rowView,
  acceptDetails,
  rejectDetails,
  generateBill,
  rowViewData,
  rowLinkToShowData,
  rowApply,
  rowTransfer,
  rowCopy,
  rowPDF,
  showApply,
  showApplyCredintial = false,
  showTransferButtonInTable = false,
  showCopyIcon = false,
  copyConditionName = "",
  copyConditionValue = "",
  showView,
  showPdf = false,
  showRoundDetails,
  showRoundDetailsNew,
  showPegination = true,
  rounds,
  isActionColActive = true,
  rowFeesPaid,
  rowAppliedList,
  rowPaymentDetails,
  showPayFees,
  rowLink,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showLinkIcon,
  showSchoolIcon,
  showPersonAdd,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPersonAddDetails,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  showAcceptIcon,
  acceptConditionName,
  acceptConditionValue,
  showGenerateBillIcon,
  generateBillConditionName,
  generateBillConditionValue,
  rejectConditionValue,
  rejectConditionName,
  viewConditionValue,
  viewConditionName,
  showCloseIcon,
  pdfDetailsId,
  excelDetailsId,
  condition,
  rowFillMarks,
  showFillMarks,
  showComment,
  rowComment,
  alertMail,
  credentialConditionName = "",
  credentialConditionValue = "",
  showApplyTitle = "",
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [searchValue, setSearch] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getFilteredTableData = () => {
    const columsFil = tableHead.filter((item) => {
      return item.canSearch;
    });
    return data.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - getFilteredTableData().length)
      : 0;
  const onSearch = (searchValue) => {
    setSearch(searchValue);
  };
  return (
    <>
      <LandingScreenHeader
        screenTitle={""}
        showTable={true}
        showBackButton={false}
        showAddButton={false}
        showPdfDownload={false}
        showExcelDownload={false}
        onSearch={onSearch}
      />
      <Box sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          {studListEmpty === true && (
            <FormHelperText error>{msg}</FormHelperText>
          )}
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              tableHead={tableHead}
              numSelected={selected.length}
              isActionColActive={isActionColActive}
              hiddenColumnNames={hiddenColumnNames}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getFilteredTableData().length}
            />
            <TableBody>
              {showPegination
                ? stableSort(
                    getFilteredTableData(),
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Row
                          key={row.id}
                          row={row}
                          tableHead={tableHead}
                          hiddenColumnNames={hiddenColumnNames}
                          showHeadDelete={showHeadDelete}
                          showHeadEdit={showHeadEdit}
                          rowEdit={rowEdit}
                          rowStatus={rowStatus}
                          rowAdd={rowAdd}
                          statusChange={statusChange}
                          rowDelete={rowDelete}
                          showHeadAdd={showHeadAdd}
                          showHeadPdf={showHeadPdf}
                          marginTop={marginTop}
                          showHeadExcel={showHeadExcel}
                          showAcceptIcon={showAcceptIcon}
                          acceptConditionName={acceptConditionName}
                          acceptConditionValue={acceptConditionValue}
                          showGenerateBillIcon={showGenerateBillIcon}
                          generateBillConditionName={generateBillConditionName}
                          generateBillConditionValue={
                            generateBillConditionValue
                          }
                          rejectConditionValue={rejectConditionValue}
                          rejectConditionName={rejectConditionName}
                          viewConditionValue={viewConditionValue}
                          viewConditionName={viewConditionName}
                          showCloseIcon={showCloseIcon}
                          condition={condition}
                          rowId={rowId}
                          rowView={rowView}
                          acceptDetails={acceptDetails}
                          rejectDetails={rejectDetails}
                          generateBill={generateBill}
                          rowViewData={rowViewData}
                          rowLinkToShowData={rowLinkToShowData}
                          showApply={showApply}
                          showApplyCredintial={showApplyCredintial}
                          showTransferButtonInTable={showTransferButtonInTable}
                          showCopyIcon={showCopyIcon}
                          copyConditionName={copyConditionName}
                          copyConditionValue={copyConditionValue}
                          rowApply={rowApply}
                          rowTransfer={rowTransfer}
                          rowCopy={rowCopy}
                          rowPDF={rowPDF}
                          alertMail={alertMail}
                          showView={showView}
                          showPdf={showPdf}
                          showComment={showComment}
                          rowComment={rowComment}
                          showRoundDetails={showRoundDetails}
                          showRoundDetailsNew={showRoundDetailsNew}
                          showAddButtonInTable={showAddButtonInTable}
                          showPegination={showPegination}
                          rounds={rounds}
                          isActionColActive={isActionColActive}
                          rowFeesPaid={rowFeesPaid}
                          rowAppliedList={rowAppliedList}
                          rowDetails={rowDetails}
                          rowPaymentDetails={rowPaymentDetails}
                          rowLink={rowLink}
                          showPayFees={showPayFees}
                          showPaymentDetails={showPaymentDetails}
                          showAppliedList={showAppliedList}
                          showAppliedListNew={showAppliedListNew}
                          showDetails={showDetails}
                          showSchoolIcon={showSchoolIcon}
                          showLinkIcon={showLinkIcon}
                          rowSchoolDetails={rowSchoolDetails}
                          generateDetails={generateDetails}
                          pdfDetailsId={pdfDetailsId}
                          excelDetailsId={excelDetailsId}
                          showPersonAdd={showPersonAdd}
                          rowPersonAddDetails={rowPersonAddDetails}
                          rowFillMarks={rowFillMarks}
                          showFillMarks={showFillMarks}
                          credentialConditionName={credentialConditionName}
                          credentialConditionValue={credentialConditionValue}
                          showApplyTitle={showApplyTitle}
                          //   tableSubHead={tableSubHead}
                          //   tableSubList={tableSubList}
                        />
                      );
                    })
                : stableSort(
                    getFilteredTableData(),
                    getComparator(order, orderBy)
                  ).map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        tableHead={tableHead}
                        hiddenColumnNames={hiddenColumnNames}
                        showHeadDelete={showHeadDelete}
                        showHeadEdit={showHeadEdit}
                        rowEdit={rowEdit}
                        rowStatus={rowStatus}
                        rowAdd={rowAdd}
                        statusChange={statusChange}
                        rowDelete={rowDelete}
                        showHeadAdd={showHeadAdd}
                        showHeadPdf={showHeadPdf}
                        marginTop={marginTop}
                        showHeadExcel={showHeadExcel}
                        showAcceptIcon={showAcceptIcon}
                        acceptConditionName={acceptConditionName}
                        acceptConditionValue={acceptConditionValue}
                        showGenerateBillIcon={showGenerateBillIcon}
                        generateBillConditionName={generateBillConditionName}
                        generateBillConditionValue={generateBillConditionValue}
                        rejectConditionValue={rejectConditionValue}
                        rejectConditionName={rejectConditionName}
                        viewConditionValue={viewConditionValue}
                        viewConditionName={viewConditionName}
                        generateBill={generateBill}
                        showCloseIcon={showCloseIcon}
                        condition={condition}
                        rowId={rowId}
                        rowView={rowView}
                        acceptDetails={acceptDetails}
                        rejectDetails={rejectDetails}
                        rowViewData={rowViewData}
                        rowLinkToShowData={rowLinkToShowData}
                        showApply={showApply}
                        showApplyCredintial={showApplyCredintial}
                        showTransferButtonInTable={showTransferButtonInTable}
                        showCopyIcon={showCopyIcon}
                        copyConditionName={copyConditionName}
                        copyConditionValue={copyConditionValue}
                        alertMail={alertMail}
                        showView={showView}
                        showPdf={showPdf}
                        rowApply={rowApply}
                        rowTransfer={rowTransfer}
                        rowCopy={rowCopy}
                        rowPDF={rowPDF}
                        showComment={showComment}
                        rowComment={rowComment}
                        showRoundDetails={showRoundDetails}
                        showRoundDetailsNew={showRoundDetailsNew}
                        showAddButtonInTable={showAddButtonInTable}
                        showPegination={showPegination}
                        rounds={rounds}
                        isActionColActive={isActionColActive}
                        rowFeesPaid={rowFeesPaid}
                        rowAppliedList={rowAppliedList}
                        rowDetails={rowDetails}
                        rowPaymentDetails={rowPaymentDetails}
                        rowLink={rowLink}
                        showPayFees={showPayFees}
                        showPaymentDetails={showPaymentDetails}
                        showAppliedList={showAppliedList}
                        showDetails={showDetails}
                        showSchoolIcon={showSchoolIcon}
                        showLinkIcon={showLinkIcon}
                        rowSchoolDetails={rowSchoolDetails}
                        generateDetails={generateDetails}
                        pdfDetailsId={pdfDetailsId}
                        excelDetailsId={excelDetailsId}
                        showPersonAdd={showPersonAdd}
                        rowPersonAddDetails={rowPersonAddDetails}
                        rowFillMarks={rowFillMarks}
                        showFillMarks={showFillMarks}
                        credentialConditionName={credentialConditionName}
                        credentialConditionValue={credentialConditionValue}
                        showApplyTitle={showApplyTitle}
                        //   tableSubHead={tableSubHead}
                        //   tableSubList={tableSubList}
                      />
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={tableHead.length} />
                </TableRow>
              )}
              {getFilteredTableData().length === 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * 1,
                  }}
                >
                  <TableCell align="center" colSpan={tableHead.length}>
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPegination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={getFilteredTableData().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>
  );
}
