import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    weeklyOff: [],

};
let URL = endpoints.weeklyOff;
const weeklyOffSlice = createSlice({
    name: "weeklyOff",
    initialState,
    reducers: {
        weeklyOffSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                weeklyOff: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    weeklyOffSuccess,
    resetState,
} = weeklyOffSlice.actions;

export default weeklyOffSlice.reducer;

export const getWeeklyOff = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((yearObject, index) => {
                    let yearData = {
                        index: index + 1,
                        id: yearObject.id === null ? "" : yearObject.id,
                        day: yearObject.day === null ? "" : yearObject.day,
                        dayName: yearObject.day === null ? "" :
                            yearObject.day == 0 ? "Monday" :
                                yearObject.day == 1 ? "Tuesday" :
                                    yearObject.day == 2 ? "Wednesday" :
                                        yearObject.day == 3 ? "Thursday" :
                                            yearObject.day == 4 ? "Friday" :
                                                yearObject.day == 5 ? "Saturday" : "Sunday",
                        isActive: yearObject.isActive === null ? "" : yearObject.isActive,
                    };
                    return yearData;
                });
                dispatch(weeklyOffSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


