import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Hidden } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import dayjs from "dayjs";
import { apiGet } from "../../utils/api_service";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { imageUrl } from "../../config/Url";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const SalesPersonCheckInImage = ({ handleCloses, open, showLoador, showNotification, columns, rows, id, itemObject }) => {

    const [dataToSet, setDataToSet] = React.useState([])
    const [popUpOpenFlag, setPopUpOpenFlag] = React.useState(false)
    const [image, setImage] = React.useState("");
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [googleApiKey, setGoogleApiKey] = React.useState("AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k");
    const [address, setAddress] = React.useState("");

    const handleClose = () => {
        handleCloses();
    };

    React.useEffect(() => {
        apiMethod();
    }, [open, id, itemObject.date]);




    const apiMethod = async () => {

        if (navigator.onLine) {
            let dataJson = {};
            showLoador({ loador: true });
            await apiGet({
                url: endpoint.salesTeamCheckInImage + "/by-sales-team-id?salesTeamId=" + id + "&date=" + itemObject.date,
            }).then(({ data, success }) => {
                showLoador({ loador: false });

                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    if (data && data.data && data.data.length !== 0) {
                        const imageData = data.data.map((dataObj) => dataObj.image);
                        setImage(imageData);

                        data.data.map((dataObj) => {
                            dataJson = {
                                date: dataObj.date,
                                name: dataObj.name,
                                stockHolderType: dataObj.stockStakeHolderType.name,
                            }
                        });
                        setDataToSet(dataJson);
                    }
                    setAddressData();
                }
            });

            popUpFlagMethod();

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }



    const setAddressData = () => {

        if (googleApiKey !== null) {
            if (itemObject?.managerStartLat && itemObject?.managerStartLong) {

                showLoador({ loador: true });
                const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${itemObject.managerStartLat},${itemObject.managerStartLong}&key=${googleApiKey}`;

                fetch(apiUrl)
                    .then((response) => response.json())
                    .then((data) => {
                        showLoador({ loador: false });

                        if (data.status === "OK" && data.results.length !== 0) {
                            const fetchedAddress = data.results[0].formatted_address;
                            setAddress(fetchedAddress);
                        } else {
                            console.error("Error:", data.status);
                        }
                    })
                    .catch((error) => {
                        showLoador({ loador: false });
                        console.error("Error:", error);
                    });
            }
        }
    };



    const popUpFlagMethod = () => {
        setPopUpOpenFlag(true);
    }


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={popUpOpenFlag}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Sales Team Details
                </BootstrapDialogTitle>

                <DialogContent style={{ display: 'flex', overflowX: 'hidden', width: '100%' }} dividers>

                    <div style={{
                        width: '70%'
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '25%'
                            }}>
                                {"Date :"}
                            </div>
                            <div>
                                {dataToSet.date}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '25%'
                            }}>
                                {"Name :"}
                            </div>
                            <div>
                                {dataToSet.name}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '25%'

                            }}>
                                {"Stock Holder Type :"}
                            </div>
                            <div>
                                {dataToSet.stockHolderType}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '15px',
                                width: '36%'
                            }}>
                                {"Address :"}
                            </div>
                            <div>
                                {address}
                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '25%'
                            }}>
                                {"Lattitude :"}
                            </div>
                            <div>
                                {itemObject.managerStartLat}
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                            <div style={{
                                color: titleColor,
                                marginRight: '10px',
                                width: '25%'
                            }}>
                                {"Longitude :"}
                            </div>
                            <div>
                                {itemObject.managerStartLong}
                            </div>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                        height: '100%',
                        textAlign: 'right'
                    }}>
                        {image && (
                            <img
                                src={imageUrl + image}
                                onLoad={() => setImageLoaded(true)}
                                style={{
                                    display: imageLoaded ? 'block' : 'none',
                                    width: '250px',
                                    height: '380px',
                                }}
                            />
                        )}
                        {!imageLoaded && (
                            <div
                                style={{
                                    width: '250px',
                                    height: '380px',
                                    backgroundColor: '#f0f0f0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <span>Loading image...</span>
                            </div>
                        )}
                    </div>
                </DialogContent>

            </BootstrapDialog>

        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    showLoador,
    showNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesPersonCheckInImage);
