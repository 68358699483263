import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import MonthCalender from "../../components/MonthCalender";
import SearchBar from "../../components/Search";
import { cyanBlue } from "../../config/ColorObj";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";

import HierarchyForPackagingTypePopUp from "./HierarchyForPackagingTypePopUp";
import {
    getPackagingTypeAmountsByFlag,
} from "../../Slice/target.slice";


class HierarchyForPackagingType extends Component {
    toggleDrawer = () => {
        this.props.onToggle();
    };
    state = {
        HierarchyPopUpFlag: false,
        tableName: "",
        regionId: "",
        showZone: false,
        showArea: false,
        date: "",
        saleMonth: dayjs(),
        month: "",
        year: "",
        searchValue: "",
        searchValueForSalesRegion: "",
        stateListFromYearAndMonth: [],
        zoneDetailsRegionwiseData: [],
        districtDetailsZoneWiseData: [],
        talukaDetailsData: [],
        areaDetailsListData: [],
        regionListFromYearAndMonthFlag: false,
        stateListFromYearAndMonthFlag: false,
        zoneByStateDataFlag: false,
        districtByZoneDataFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,
        matrixDataList: [],
        matrixDataListRegion: [],
        matrixDataListState: [],
        matrixDataListZone: [],
        matrixDataListDistrict: [],
        matrixDataListTaluka: [],
        matrixDataListArea: [],
    };

    async componentDidMount() {
        const {
            showNotification,
            getPackagingTypeAmountsByFlag,
            showLoador,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 1,
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                id: "",
            }).then(({ response, success }) => {
                showLoador({ loador: false });

                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.matrixData(response, 1);
                }
            });
        }
    }


    regionHandler = (e, regionId, flag) => {
        const {
            getPackagingTypeAmountsByFlag,
            showLoador,
            showNotification,
        } = this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 2,
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                id: regionId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.matrixData(response, flag);
                    this.setState({
                        tableName: "State Wise Achievement",
                        regionId: regionId,
                        flag: flag,
                        HierarchyPopUpFlag: true,
                        stateListFromYearAndMonthFlag: true,
                        zoneByStateDataFlag: false,
                        districtByZoneDataFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaByTalukaDataFlag: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    stateHandler = (e, stateId, flag) => {

        const {
            getPackagingTypeAmountsByFlag,
            showLoador,
            showNotification,
            targetList,
        } = this.props;
        if (e !== 0) {

            const { month, year, saleMonth } = this.state;
            if (navigator.onLine) {


                showLoador({ loador: true });
                getPackagingTypeAmountsByFlag({
                    flag: 3,
                    month: month !== "" ? month : saleMonth.format("MM"),
                    year: year !== "" ? year : saleMonth.format("YYYY"),
                    id: stateId,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {

                        this.matrixData(response, flag);

                        this.setState({
                            tableName: "Zone Wise Achievement",
                            flag: flag,
                            zoneDetailsRegionwiseData: response.length !== 0 ? response : [],
                            zoneByStateDataFlag: true,
                            stateListFromYearAndMonthFlag: false,
                            districtByZoneDataFlag: false,
                            talukaByDistrictDataFlag: false,
                            areaByTalukaDataFlag: false,
                        });
                    }
                });
            } else {
                showNotification({ msg: noInternetMsg, severity: "error" });
            }
        } else {
            this.setState({
                tableName: "State Wise Achievement",
                flag: 1,
                stateListFromYearAndMonth: targetList?.packagingTypeAmountsByFlag,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: true,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,

            });
        }

    };

    zoneHandler = (e, zoneId, flag) => {
        const { getPackagingTypeAmountsByFlag, showLoador, showNotification } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {

            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 4,
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                id: zoneId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {

                    this.matrixData(response, flag);

                    this.setState({
                        tableName: "District Wise Achievement",
                        flag: flag,
                        districtDetailsZoneWiseData: response.length !== 0 ? response : [],
                        districtByZoneDataFlag: true,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaByTalukaDataFlag: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    districtHandler = (e, districtId, flag) => {
        const { getPackagingTypeAmountsByFlag, showLoador, showNotification } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {

            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 5,
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                id: districtId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {

                    this.matrixData(response, flag);

                    this.setState({
                        tableName: "Taluka Wise Achievement",
                        flag: flag,
                        talukaDetailsData: response.length !== 0 ? response : [],
                        talukaByDistrictDataFlag: true,
                        districtByZoneDataFlag: false,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        areaByTalukaDataFlag: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    talukaHandler = (e, talukaId, flag) => {
        const { getPackagingTypeAmountsByFlag, showLoador, showNotification } =
            this.props;
        const { month, year, saleMonth } = this.state;
        if (navigator.onLine) {

            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 6,
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                id: talukaId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {

                    this.matrixData(response, flag);

                    this.setState({
                        tableName: "Area Wise Achievement",
                        flag: flag,
                        areaByTalukaDataFlag: true,
                        districtByZoneDataFlag: false,
                        zoneByStateDataFlag: false,
                        stateListFromYearAndMonthFlag: false,
                        talukaByDistrictDataFlag: false,
                        areaDetailsListData: response.length !== 0 ? response : [],
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onDateChange = (date, name) => {
        this.setState({
            month: date.format("MM"),
            year: date.format("YYYY"),
            saleMonth: date,
            showZone: false,
            showArea: false,
        });

        const { getPackagingTypeAmountsByFlag, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getPackagingTypeAmountsByFlag({
                flag: 1,
                month: date.format("MM"),
                year: date.format("YYYY"),
                id: "",
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.matrixData(response, 1);

                    this.setState({
                        regionListFromYearAndMonthFlag: true,
                    });
                }

            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    matrixData = (data, flag) => {
        const { entityList, packagingTypes, packagingTypesWithAmts } = data;

        const structuredData = entityList.map((entity, index) => {
            const packagingAmounts = packagingTypes.map((packaging) => {
                const match = packagingTypesWithAmts.find((item) =>
                    item.entityId == entity.id &&
                    item.packagingTypeId == packaging.id
                );
                return match ? match.amt.toFixed(2) : '';
            });
            return {
                index: index + 1,
                entityId: entity.id,
                entityName: entity.name,
                packagingAmounts
            };
        });

        this.setState({ matrixDataList: structuredData });

        if (flag == 1) {
            this.setState({ matrixDataListRegion: structuredData });
        } else if (flag == 2) {
            this.setState({ matrixDataListState: structuredData });
        } else if (flag == 3) {
            this.setState({ matrixDataListZone: structuredData });
        } else if (flag == 4) {
            this.setState({ matrixDataListDistrict: structuredData });
        } else if (flag == 5) {
            this.setState({ matrixDataListTaluka: structuredData });
        } else if (flag == 6) {
            this.setState({ matrixDataListArea: structuredData });
        }
    };






    onSearchForSalesRegion = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    }

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;

        if (!searchData || searchData.length === 0) {
            return [];
        }

        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                currentRow["entityName"] &&
                currentRow["entityName"]
                    .toString()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase())
            ) {
                isValid = true;
                return true;
            }
        });
    };



    handleClosesHierarchyPopUp = () => {
        const {
            matrixDataList,
            matrixDataListRegion,
            matrixDataListState,
            matrixDataListZone,
            matrixDataListDistrict,
            matrixDataListTaluka,
            matrixDataListArea,
            flag
        } = this.state;

        if (flag == 1) {
            this.setState({
                matrixDataList: matrixDataListRegion,
                HierarchyPopUpFlag: false,
            });
        } else if (flag == 2) {
            this.setState({
                tableName: "State Wise Achievement",
                flag: 1,
                matrixDataList: matrixDataListState,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: true,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,

            });
        } else if (flag == 3) {
            this.setState({
                tableName: "Zone Wise Achievement",
                flag: 2,
                matrixDataList: matrixDataListZone,
                zoneByStateDataFlag: true,
                stateListFromYearAndMonthFlag: false,
                districtByZoneDataFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,
            });
        } else if (flag == 4) {
            this.setState({
                tableName: "District Wise Achievement",
                flag: 3,
                matrixDataList: matrixDataListDistrict,
                districtByZoneDataFlag: true,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: false,
                talukaByDistrictDataFlag: false,
                areaByTalukaDataFlag: false,
            });
        } else if (flag == 5) {
            this.setState({
                tableName: "Taluka Wise Achievement",
                flag: 4,
                matrixDataList: matrixDataListTaluka,
                talukaByDistrictDataFlag: true,
                districtByZoneDataFlag: false,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: false,
                areaByTalukaDataFlag: false,
            });
        } else if (flag == 6) {
            this.setState({
                tableName: "Area Wise Achievement",
                flag: 5,
                matrixDataList: matrixDataListArea,
                talukaByDistrictDataFlag: false,
                districtByZoneDataFlag: false,
                zoneByStateDataFlag: false,
                stateListFromYearAndMonthFlag: false,
                areaByTalukaDataFlag: true,
            });
        }
    };

    render() {
        const { targetList } = this.props;
        const {
            saleMonth,
            stateListFromYearAndMonth,
            zoneDetailsRegionwiseData,
            districtDetailsZoneWiseData,
            talukaDetailsData,
            areaDetailsListData,
            searchValueForSalesRegion,
            regionListFromYearAndMonthFlag,
            stateListFromYearAndMonthFlag,
            zoneByStateDataFlag,
            districtByZoneDataFlag,
            talukaByDistrictDataFlag,
            areaByTalukaDataFlag,
            HierarchyPopUpFlag,
            tableName,
            flag,
            regionId,
            matrixDataList,
        } = this.state;

        return (
            <>
                <div
                    id="layout-wrapper"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="vertical-overlay" />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Region Wise Achievement
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForSalesRegion={
                                                                searchValueForSalesRegion
                                                            }
                                                            filteredRows={this.onSearchForSalesRegion}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                padding: 0,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    {Object.keys(targetList?.packagingTypeAmountsByFlag).length !== 0 &&
                                                        targetList?.packagingTypeAmountsByFlag?.packagingTypes.length !== 0 &&
                                                        (
                                                            <TableRow>
                                                                <TableCell
                                                                    color="#000"
                                                                    align="center"
                                                                    sx={{ width: "2%", color: "#000" }}
                                                                >
                                                                    Sr.No
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    sx={{ width: "8%", color: "#000" }}
                                                                >
                                                                    Name
                                                                </TableCell>
                                                                {targetList?.packagingTypeAmountsByFlag?.packagingTypes.map((packagingTypeObj, index) => (
                                                                    <TableCell key={index} align="center" style={{ minWidth: "150px" }}>
                                                                        {packagingTypeObj.name}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        )
                                                    }
                                                </TableHead>
                                                <TableBody>
                                                    {regionListFromYearAndMonthFlag
                                                        ? matrixDataList.length !== 0 &&
                                                        this.getFilteredTableData(matrixDataList).map(
                                                            (dataObj, index) => (
                                                                <TableRow key={dataObj.index}>
                                                                    <TableCell align="center">{index + 1}</TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) => this.regionHandler(e, dataObj.entityId, 2)}
                                                                            style={{ color: cyanBlue, cursor: "pointer" }}
                                                                        >
                                                                            {dataObj.entityName}
                                                                        </div>
                                                                    </TableCell>
                                                                    {dataObj.packagingAmounts.map((amt, idx) => (
                                                                        <TableCell align="center" key={idx}>{amt !== '' ? amt : '-'}</TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            )
                                                        )
                                                        : this.getFilteredTableData(
                                                            matrixDataList &&
                                                            matrixDataList
                                                        ).map((dataObj, index) => (
                                                            <TableRow key={dataObj.index}>
                                                                <TableCell align="center">{index + 1}</TableCell>
                                                                <TableCell width="2%">
                                                                    <div
                                                                        onClick={(e) => this.regionHandler(e, dataObj.entityId, 2)}
                                                                        style={{ color: cyanBlue, cursor: "pointer" }}
                                                                    >
                                                                        {dataObj.entityName}
                                                                    </div>
                                                                </TableCell>
                                                                {dataObj.packagingAmounts.map((amt, idx) => (
                                                                    <TableCell align="center" key={idx}>{amt !== '' ? amt : '-'}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    <TableRow>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {HierarchyPopUpFlag && (
                    <HierarchyForPackagingTypePopUp
                        HierarchyPopUpFlag={HierarchyPopUpFlag}
                        handleCloses={this.handleClosesHierarchyPopUp}
                        popUpData={matrixDataList}
                        packagingTypeList={targetList?.packagingTypeAmountsByFlag?.packagingTypes}
                        flag={flag}
                        stateHandler={this.stateHandler}
                        zoneHandler={this.zoneHandler}
                        districtHandler={this.districtHandler}
                        talukaHandler={this.talukaHandler}
                        tableName={tableName}
                        stateListFromYearAndMonthFlag={stateListFromYearAndMonthFlag}
                        zoneByStateDataFlag={zoneByStateDataFlag}
                        districtByZoneDataFlag={districtByZoneDataFlag}
                        talukaByDistrictDataFlag={talukaByDistrictDataFlag}
                        areaByTalukaDataFlag={areaByTalukaDataFlag}

                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    common: state.common,
    stockStakeHolderTypeList: state.stockStakeHolderType,
    zoneList: state.zone,
    areaList: state.area,
    targetList: state.target,
});

const mapDispatchToProps = {
    onToggle,
    showLoador,
    showNotification,
    getPackagingTypeAmountsByFlag,

};
export default connect(mapStateToProps, mapDispatchToProps)(HierarchyForPackagingType);
