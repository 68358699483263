import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { UserActionTypes } from ".././CommonActions/User.action";
import commonSlice from "../CommonActions/common.slice";
import LandingSlice from "../Pages/Landing/Landing.slice";
import loginSlice from "../Pages/Login/login.slice";
import { localStorageMiddleware } from "../middleware/storage";
import paymentRecieptSlice from "../Slice/paymentReciept.slice";
import orderHeaderListSlice from "../Slice/OrderHeaderList.slice";
import StackHolderSlice from "../Slice/StackHolder.slice";
import taxSlice from "../Slice/Tax.slice";
import accessRoleSlice from "../Slice/accessRole.slice";
import adminDashboardSlice from "../Slice/adminDashboard.slice";
import areaSlice from "../Slice/area.slice";
import assignRateConfigurationToStakeholderSlice from "../Slice/assignRateConfigurationToStakeholder.slice";
import auditSlice from "../Slice/audit.slice";
import beatSlice from "../Slice/beat.slice";
import billingHistorySlice from "../Slice/billingHistory.slice";
import categorySlice from "../Slice/category.slice";
import companyTargetSlice from "../Slice/companyTarget.slice";
// import creditNoteListSlice from "../Slice/creditNotHeader.slice";
import creditNoteHeaderSlice from "../Slice/creditNote.slice";
import dayWiseBitAssignSlice from "../Slice/dayWiseBitAssign.slice";
import documentSlice from "../Slice/document.slice";
import forgotPasswordSlice from "../Slice/forgotPassword.slice";
import genratedBillListSlice from "../Slice/genratedBillList.slice";
import grievanceSlice from "../Slice/grievance.slice";
import grievanceCategorySlice from "../Slice/grievanceCategory.slice";
import manageStockListSlice from "../Slice/manageStock.slice";
import managerSlice from "../Slice/manager.slice";
import mrpConfigrationSlice from "../Slice/mrpConfigration.slice";
import billHeaderListSlice from "../Slice/orderBillList.slice";
import orderConfigurationSlice from "../Slice/orderConfiguration.slice";
import orderHistorySlice from "../Slice/orderHistory.slice";
import outletSlice from "../Slice/outlet.slice";
import outletOrderDetailSlice from "../Slice/outletOrderDetail.slice";
import outletRateStructureSlice from "../Slice/outletRateStructure.slice";
import outletTypeSlice from "../Slice/outletType.slice";
import paymentSlice from "../Slice/payment.slice";
import productSlice from "../Slice/product.slice";
import productRateConfigurationSlice from "../Slice/productRateConfiguration.slice";
import projectionSlice from "../Slice/projection.slice";
import publishDateCheckSlice from "../Slice/publishDateCheck.slice";
import publishRateConfigOultletSlice from "../Slice/publishRateConfigOultlet.slice";
import rangeSlice from "../Slice/range.slice";
import receivedOrderListSlice from "../Slice/receivedOrderList.slice";
import regionSlice from "../Slice/region.slice";
import regionAndTargetSlice from "../Slice/regionAndTarget.slice";
import requestGRNSlice from "../Slice/requestGRN.slice";
import salesDashboardSlice from "../Slice/salesDashboard.slice";
import salesExecutiveSlice from "../Slice/salesExecutive.slice";
import settleBillSlice from "../Slice/settleBill.slice";
import stockStakeHolderTypeSlice from "../Slice/stockStakeHolderType.slice";
import stockistSettleBillSlice from "../Slice/stockistSettleBill.slice";
import subCategorySlice from "../Slice/subCategory.slice";
import targetSlice from "../Slice/target.slice";
import uomSlice from "../Slice/uom.slice";
import yearSlice from "../Slice/year.slice";
import zoneSlice from "../Slice/zone.slice";
import noticeMsgSlice from "../Slice/noticeMsg.slice";
import multilingualSlice from "../Slice/multilingual.slice";
import stateSlice from "../Slice/state.slice";
import distributorDashbourdSlice from "../Slice/distributorDashbourd.slice";
import locationSlice from "../Slice/location.slice";
import salesTeamOrderConfigSlice from "../Slice/salesTeamOrderConfig.slice";
import offerSlice from "../Slice/offer.slice";
import reportSlice from "../Slice/report.slice";
import matrixReportSlice from "../Slice/matrixReport.slice";
import typeSlice from "../Slice/type.slice";
import companyCategoryConfigurationSlice from "../Slice/companyCategoryConfiguration.slice";
import stakeHolderSlice from "../Slice/StackHolder.slice";
import publishRateConfigurationSlice from "../Slice/publishDateCheck.slice";
import functionalDashboardDistributorSlice from "../Slice/functionalDashboardDistributor.slice";
import packagingTypeSlice from "../Slice/packagingType.slice";
import districtSlice from "../Slice/district.slice";
import claimSlice from "../Slice/claim.slice";
import countrySlice from "../Slice/country.slice";
import talukaSlice from "../Slice/taluka.slice";
import brandSlice from "../Slice/brand.slice";
import tagSlice from "../Slice/tag.slice";
import groupProductSlice from "../Slice/groupProduct.slice";
import stockHolderAndProductConfigurationSlice from "../Slice/rateConfigurationEditingForStockholder.slice";
import productRateStructureSlice from "../Slice/productRateStructure.slice";
import stockHolderOrderConfigurationSlice from "../Slice/stockHolderOrderConfiguration.slice";
import beatTargetSlice from "../Slice/beatTarget.slice";
import ladgerSlice from "../Slice/ladger.slice";
import subAdminUserSlice from "../Slice/subAdminUser.slice";
import dashboardAdminSlice from "../Slice/dashboard.slice";
import activeLoginUserSlice from "../Slice/activeLoginUser.slice";
import vehicleSlice from "../Slice/veichleSlice.slice";
import transporterSlice from "../Slice/transporter.slice";
import bankDetailsSlice from "../Slice/bankDetails.slice";
import paymentTermSlice from "../Slice/paymentTerm.Slice";
import timeSlotSlice from "../Slice/timeSlot.slice";
import workingTypeSlice from "../Slice/workingType.slice";
import otherTaskSlice from "../Slice/otherTask.slice";
import managerDashboardSlice from "../Slice/managerDashboard.slice";
import leaveTypeSlice from "../Slice/LeaveType.slice"
import leaveStructureSlice from "../Slice/leaveStructure.slice"
import assetsSlice from "../Slice/assets.slice";
import venderSlice from "../Slice/vender.slice";
import assetsChecklistSlice from "../Slice/assetsChecklist.slice";
import productCatelogueSlice from "../Slice/productCatelogue.slice";
import typeOfBusinessSlice from "../Slice/typeOfBusiness.slice";
import investedAmountSlice from "../Slice/investedAmount.slice";
import annualTurnoverSlice from "../Slice/annualTurnover.slice";
import noOfStaffSlice from "../Slice/noOfStaff.slice";
import distributorInquirySlice from "../Slice/distributorInquiry.slice";
import assetsImplementationTrackingSlice from "../Slice/assetsImplementationTracking.slice";
import minutesOfMeetingSlice from "../Slice/minutesOfMeeting.slice";
import distributorInquiryDocumentDetailsSlice from "../Slice/distributorInquiryDocumentDetails.slice";
import managerLogsSlice from "../Slice/managerLogs.slice";
import distributorInquiryWatcherSlice from "../Slice/distributorInquiryWatcher.slice";
import weeklyOffSlice from "../Slice/weeklyOff.slice";

const reducer = combineReducers({
  user: loginSlice,
  common: commonSlice,
  landing: LandingSlice,
  zone: zoneSlice,
  uom: uomSlice,
  document: documentSlice,
  tax: taxSlice,
  area: areaSlice,
  category: categorySlice,
  subCategory: subCategorySlice,
  mrpConfigration: mrpConfigrationSlice,
  salesExecutive: salesExecutiveSlice,
  product: productSlice,
  region: regionSlice,
  stockStakeHolderType: stockStakeHolderTypeSlice,
  orderConfiguration: orderConfigurationSlice,
  stackholder: StackHolderSlice,
  stockholder: stakeHolderSlice,
  manager: managerSlice,
  forgotPassword: forgotPasswordSlice,
  accessRole: accessRoleSlice,
  productRateConfiguration: productRateConfigurationSlice,
  outletRateStructure: outletRateStructureSlice,
  publishRateConfigCurrent: publishDateCheckSlice,
  productRateConfOutlet: publishRateConfigOultletSlice,
  assignRateConfigurationToStakeholder:
    assignRateConfigurationToStakeholderSlice,
  range: rangeSlice,
  grievanceCategory: grievanceCategorySlice,
  outletType: outletTypeSlice,
  beat: beatSlice,
  year: yearSlice,
  dayWiseBitAssign: dayWiseBitAssignSlice,
  outlet: outletSlice,
  target: targetSlice,
  regionAndTarget: regionAndTargetSlice,
  audit: auditSlice,
  projection: projectionSlice,
  grievance: grievanceSlice,
  companyTarget: companyTargetSlice,
  adminDashboard: adminDashboardSlice,
  salesDashboard: salesDashboardSlice,
  outletOrderDetail: outletOrderDetailSlice,
  orderHistory: orderHistorySlice,
  billingHistory: billingHistorySlice,
  payment: paymentSlice,
  settleBill: settleBillSlice,
  creditNoteHeader: creditNoteHeaderSlice,
  orderHeaderList: orderHeaderListSlice,
  genratedBill: genratedBillListSlice,
  receivedOrderList: receivedOrderListSlice,
  billHeaderList: billHeaderListSlice,
  requestGRN: requestGRNSlice,
  // creditNoteByDate: creditNoteListSlice,
  stockistSettleBill: stockistSettleBillSlice,
  paymentReciept: paymentRecieptSlice,
  manageStockByDate: manageStockListSlice,
  noticeMsg: noticeMsgSlice,
  multilingual: multilingualSlice,
  states: stateSlice,
  distributorDashbourd: distributorDashbourdSlice,
  location: locationSlice,
  salesTeamOrderConfig: salesTeamOrderConfigSlice,
  offer: offerSlice,
  report: reportSlice,
  matrixReport: matrixReportSlice,
  type: typeSlice,
  companyCateoryConfiguration: companyCategoryConfigurationSlice,
  publishRateConfiguration: publishRateConfigurationSlice,
  functionalDashboardDistributor: functionalDashboardDistributorSlice,
  packagingType: packagingTypeSlice,
  district: districtSlice,
  claim: claimSlice,
  country: countrySlice,
  taluka: talukaSlice,
  brand: brandSlice,
  tag: tagSlice,
  groupProduct: groupProductSlice,
  stockHolderAndProductConfiguration: stockHolderAndProductConfigurationSlice,
  productRateStructure: productRateStructureSlice,
  groupProduct: groupProductSlice,
  stockHolderOrderConfiguration: stockHolderOrderConfigurationSlice,
  beatTarget: beatTargetSlice,
  ladger: ladgerSlice,
  subAdminUser: subAdminUserSlice,
  dashboard: dashboardAdminSlice,
  activeLoginUser: activeLoginUserSlice,
  vehicle: vehicleSlice,
  transporter: transporterSlice,
  bankDetails: bankDetailsSlice,
  paymentTerm: paymentTermSlice,
  timeSlot: timeSlotSlice,
  workingType: workingTypeSlice,
  otherTask: otherTaskSlice,
  managerDashboard: managerDashboardSlice,
  leaveType: leaveTypeSlice,
  leaveStructure: leaveStructureSlice,
  assets: assetsSlice,
  vender: venderSlice,
  assetsChecklist: assetsChecklistSlice,
  productCatelogue: productCatelogueSlice,
  typeOfBusiness: typeOfBusinessSlice,
  investedAmount: investedAmountSlice,
  annualTurnover: annualTurnoverSlice,
  noOfStaff: noOfStaffSlice,
  distributorInquiry: distributorInquirySlice,
  assetsImplementationTracking: assetsImplementationTrackingSlice,
  minutesOfMeeting: minutesOfMeetingSlice,
  distributorInquiryDocumentDetails: distributorInquiryDocumentDetailsSlice,
  managerLogs: managerLogsSlice,
  distributorInquiryWatcher: distributorInquiryWatcherSlice,
  weeklyOff: weeklyOffSlice,
});
// const store = createStore(reducers, applyMiddleware(thunk));

const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.LOGOUT) {
    state = undefined;
  }
  if (action.type === UserActionTypes.ReserRedux) {
    state = undefined;
  } else {
  }
  return reducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
