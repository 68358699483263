import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import MonthCalender from "../../components/MonthCalender";
import SearchBar from "../../components/Search";
import { cyanBlue } from "../../config/ColorObj";
import { serverMsg } from "../../config/messageconstant";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import {
  showLoador,
  showNotification,
} from "../Landing/Landing.slice";
import HierarchyPopUp from "./HierarchyPopUp";
import {
  getAttendanceTargetAchievmentForRegion,
  getAttendanceTargetAchievmentForState,
  getAttendanceTargetAchievmentForZone,
  getAttendanceTargetAchievmentForDistrict,
  getAttendanceTargetAchievmentForTaluka,
  getAttendanceTargetAchievmentForArea
} from "../../Slice/target.slice";

class HiearchyDropDown extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    openFlag: false,
    showFeedback: false,
    showPreview: false,
    HierarchyPopUpFlag: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    tableName: "",
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    regionId: "",
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    productArea: "",
    productZone: "",
    outletArea: "",
    outletZone: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    saleYear: "",
    month: "",
    year: "",
    searchValue: "",
    searchValueForZone: "",
    searchValueForSalesRegion: "",
    searchValueForZoneDetails: "",
    searchValueForAreaDetails: "",
    searchValuePersonWiseSales: "",
    searchValueStateList: "",
    searchValueForProduct: "",
    searchValueForOutlets: "",
    regionListTotal: 0,
    regionListFromYearAndMonthData: [],
    regionListFromYearAndMonthDataForZone: [],
    regionListFromYearAndMonthDataForSalesRegion: [],
    stateListFromYearAndMonth: [],
    zoneDetailsSalesData: [],
    talukaDetailsDistrictWiseData: [],
    stateDetailsData: [],
    zoneDetailsRegionwiseData: [],
    districtDetailsZoneWiseData: [],
    districtDetailsData: [],
    talukaDetailsData: [],
    areaDetailsListData: [],
    salesGraphData: [],
    salesMonthData: [],
    areaDetailsData: [],
    personWiseSalesData: [],
    topPerformingProductData: [],
    topPerformingOutletData: [],
    salesTeamData: [],
    formErrors: {},
    graphList: [],
    radioValue: [],
    lineGraphData: [],
    salesGraphList: [],
    areaDetailTalukaWiseData: [],
    salesRegionName: "",
    regionListFromYearAndMonthFlag: false,
    stateListFromYearAndMonthFlag: false,
    zoneByStateDataFlag: false,
    districtByZoneDataFlag: false,
    talukaByDistrictDataFlag: false,
    areaByTalukaDataFlag: false,

  };
  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getAttendanceTargetAchievmentForRegion,
      targetList,
    } = this.props;
    if (navigator.onLine) {
      const regionListToSet = localStorage.getItem("regionListFromYearAndMonthTargetWise");
      const regionListFromYearAndMonthLocalStorage = JSON.parse(regionListToSet);

      let regionTotalViaMap = 0;
      if (!regionListFromYearAndMonthLocalStorage) {
        showLoador({ loador: true });
        getAttendanceTargetAchievmentForRegion({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {

            const regionListFromYearAndMonthList = response.length !== 0 ? response : [];
            localStorage.setItem("regionListFromYearAndMonthTargetWise", JSON.stringify(regionListFromYearAndMonthList));
            localStorage.setItem("regionId", response.length !== 0 ? response[0].id : "");


            regionListFromYearAndMonthList.map((regionObj) => {
              regionTotalViaMap = regionObj.finalTotal.toFixed(2)
            })
            localStorage.setItem("regionListFromYearAndMonthTotalTargetWise", regionTotalViaMap)

            this.setState({
              regionListTotal: targetList?.attendanceTargetAchievmentForRegionTotal,
              regionListFromYearAndMonthData: response,
              regionListFromYearAndMonthDataForZone: response,
              regionListFromYearAndMonthDataForSalesRegion: response,
              salesRegionName: response.length !== 0 ? response[0].name : "",
            });

          }
        });
      }
    }
  }
  regionHandler = (e, regionId, flag) => {
    const {
      getAttendanceTargetAchievmentForState,
      showLoador,
      showNotification,
      rigionIdHandler,
    } = this.props;
    const { month, year, saleMonth } = this.state;
    if (navigator.onLine) {
      let stateTotalViaMap = 0;

      rigionIdHandler(regionId);
      showLoador({ loador: true });
      getAttendanceTargetAchievmentForState({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        regionId: regionId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const stateListFromYearAndMonthList = response.length !== 0 ? response : [];

          stateListFromYearAndMonthList.map((regionObj) => {
            stateTotalViaMap = regionObj.finalTotal.toFixed(2)
          })

          localStorage.setItem("stateTotalFromRegionTargetWise", stateTotalViaMap);

          this.setState({
            tableName: "State",
            regionId: regionId,
            flag: flag,
            HierarchyPopUpFlag: true,
            stateListFromYearAndMonthFlag: true,
            zoneByStateDataFlag: false,
            districtByZoneDataFlag: false,
            talukaByDistrictDataFlag: false,
            areaByTalukaDataFlag: false,
            stateListFromYearAndMonth: stateListFromYearAndMonthList,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  stateHandler = (e, stateId, flag) => {

    const {
      getAttendanceTargetAchievmentForZone,
      showLoador,
      showNotification,
      targetList,
    } = this.props;
    if (e !== 0) {

      const { month, year, saleMonth } = this.state;
      if (navigator.onLine) {

        let zoneTotalViaMap = 0;

        showLoador({ loador: true });
        getAttendanceTargetAchievmentForZone({
          month: month !== "" ? month : saleMonth.format("MM"),
          year: year !== "" ? year : saleMonth.format("YYYY"),
          stateId: stateId,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {

            const zoneListFromYearAndMonthList = response.length !== 0 ? response : [];

            zoneListFromYearAndMonthList.map((regionObj) => {
              zoneTotalViaMap = regionObj.finalTotal.toFixed(2)
            })

            localStorage.setItem("zoneTotalFromStateTargetWise", zoneTotalViaMap);

            this.setState({
              tableName: "Zone",
              flag: flag,
              zoneDetailsRegionwiseData: response.length !== 0 ? response : [],
              zoneByStateDataFlag: true,
              stateListFromYearAndMonthFlag: false,
              districtByZoneDataFlag: false,
              talukaByDistrictDataFlag: false,
              areaByTalukaDataFlag: false,

            });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      this.setState({
        tableName: "State",
        flag: 0,
        stateListFromYearAndMonth: targetList?.attendanceTargetAchievmentForState,
        zoneByStateDataFlag: false,
        stateListFromYearAndMonthFlag: true,
        districtByZoneDataFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,

      });
    }

  };

  zoneHandler = (e, zoneId, flag) => {
    const { getAttendanceTargetAchievmentForDistrict, showLoador, showNotification } =
      this.props;
    const { month, year, saleMonth } = this.state;
    if (navigator.onLine) {

      let districtTotalViaMap = 0;

      showLoador({ loador: true });
      getAttendanceTargetAchievmentForDistrict({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        zoneId: zoneId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const districtListFromYearAndMonthList = response.length !== 0 ? response : [];

          districtListFromYearAndMonthList.map((regionObj) => {
            districtTotalViaMap = regionObj.finalTotal.toFixed(2)
          })


          localStorage.setItem("districtTotalFromZoneTargetWise", districtTotalViaMap);

          this.setState({
            tableName: "District",
            flag: flag,
            districtDetailsZoneWiseData: response.length !== 0 ? response : [],
            districtByZoneDataFlag: true,
            zoneByStateDataFlag: false,
            stateListFromYearAndMonthFlag: false,
            talukaByDistrictDataFlag: false,
            areaByTalukaDataFlag: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  districtHandler = (e, districtId, flag) => {
    const { getAttendanceTargetAchievmentForTaluka, showLoador, showNotification } =
      this.props;
    const { month, year, saleMonth } = this.state;
    if (navigator.onLine) {

      let talukaTotalViaMap = 0;

      showLoador({ loador: true });
      getAttendanceTargetAchievmentForTaluka({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        districtId: districtId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const talukaListFromYearAndMonthList = response.length !== 0 ? response : [];

          talukaListFromYearAndMonthList.map((regionObj) => {
            talukaTotalViaMap = regionObj.finalTotal.toFixed(2)
          })

          localStorage.setItem("talukaTotalFromDistrictTargetWise", talukaTotalViaMap);

          this.setState({
            tableName: "Taluka",
            flag: flag,
            talukaDetailsData: response.length !== 0 ? response : [],
            talukaByDistrictDataFlag: true,
            districtByZoneDataFlag: false,
            zoneByStateDataFlag: false,
            stateListFromYearAndMonthFlag: false,
            areaByTalukaDataFlag: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  talukaHandler = (e, talukaId, flag) => {
    const { getAttendanceTargetAchievmentForArea, showLoador, showNotification } =
      this.props;
    const { month, year, saleMonth } = this.state;
    if (navigator.onLine) {

      let areaTotalViaMap = 0;

      showLoador({ loador: true });
      getAttendanceTargetAchievmentForArea({
        month: month !== "" ? month : saleMonth.format("MM"),
        year: year !== "" ? year : saleMonth.format("YYYY"),
        talukaId: talukaId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const areaListFromYearAndMonthList = response.length !== 0 ? response : [];

          areaListFromYearAndMonthList.map((regionObj) => {
            areaTotalViaMap = regionObj.finalTotal.toFixed(2)
          })


          localStorage.setItem("areaTotalFromTalukaTargetWise", areaTotalViaMap);

          this.setState({
            tableName: "Area",
            flag: flag,
            areaByTalukaDataFlag: true,
            districtByZoneDataFlag: false,
            zoneByStateDataFlag: false,
            stateListFromYearAndMonthFlag: false,
            talukaByDistrictDataFlag: false,
            areaDetailsListData: response.length !== 0 ? response : [],
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  onDateChange = (date, name) => {
    this.setState({
      month: date.format("MM"),
      year: date.format("YYYY"),
      saleMonth: date,
      showZone: false,
      showArea: false,
    });
    const { type } = this.state;
    const { getAttendanceTargetAchievmentForRegion, showLoador, showNotification, targetList } =
      this.props;
    if (navigator.onLine) {
      let regionTotalViaMap = 0;
      showLoador({ loador: true });
      getAttendanceTargetAchievmentForRegion({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const regionListFromYearAndMonthList = response.length !== 0 ? response : [];

          regionListFromYearAndMonthList.map((regionObj) => {
            regionTotalViaMap = regionObj.finalTotal.toFixed(2)
          })


          localStorage.setItem("regionListFromYearAndMonthTotalTargetWise", regionTotalViaMap);

          this.setState({
            regionListFromYearAndMonthFlag: true,
          });
        }

      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  onSearchForSalesRegion = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  }

  getFilteredTableData = (searchData) => {
    const { searchValue } = this.state;

    if (!searchData || searchData.length === 0) {
      return [];
    }

    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["name"] &&
        currentRow["name"]
          .toString()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };



  handleClosesHierarchyPopUp = () => {
    const { stateListFromYearAndMonth, zoneDetailsRegionwiseData, flag, districtDetailsZoneWiseData, talukaDetailsData } = this.state;
    if (flag == 0) {
      this.setState({
        HierarchyPopUpFlag: false,
      });
    } else if (flag == 1) {
      this.setState({
        tableName: "State",
        flag: 0,
        stateListFromYearAndMonth: stateListFromYearAndMonth,
        zoneByStateDataFlag: false,
        stateListFromYearAndMonthFlag: true,
        districtByZoneDataFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,

      });
    } else if (flag == 2) {
      this.setState({
        tableName: "Zone",
        flag: 1,
        zoneDetailsRegionwiseData: zoneDetailsRegionwiseData,
        zoneByStateDataFlag: true,
        stateListFromYearAndMonthFlag: false,
        districtByZoneDataFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,
      });
    } else if (flag == 3) {
      this.setState({
        tableName: "District",
        flag: 2,
        districtDetailsZoneWiseData: districtDetailsZoneWiseData,
        districtByZoneDataFlag: true,
        zoneByStateDataFlag: false,
        stateListFromYearAndMonthFlag: false,
        talukaByDistrictDataFlag: false,
        areaByTalukaDataFlag: false,
      });
    } else if (flag == 4) {
      this.setState({
        tableName: "Taluka",
        flag: 3,
        talukaDetailsData: talukaDetailsData,
        talukaByDistrictDataFlag: true,
        districtByZoneDataFlag: false,
        zoneByStateDataFlag: false,
        stateListFromYearAndMonthFlag: false,
        areaByTalukaDataFlag: false,
      });
    }
  };

  render() {
    const { targetList } = this.props;
    const {
      saleMonth,
      regionListFromYearAndMonthDataForSalesRegion,
      stateListFromYearAndMonth,
      zoneDetailsSalesData,
      zoneDetailsRegionwiseData,
      districtDetailsData,
      districtDetailsZoneWiseData,
      talukaDetailsDistrictWiseData,
      talukaDetailsData,
      areaDetailsListData,
      searchValueForSalesRegion,
      searchValueForZoneDetails,
      searchValueForAreaDetails,
      searchValueStateList,
      regionListFromYearAndMonthFlag,
      stateListFromYearAndMonthFlag,
      zoneByStateDataFlag,
      districtByZoneDataFlag,
      talukaByDistrictDataFlag,
      areaByTalukaDataFlag,
      areaDetailTalukaWiseData,
      HierarchyPopUpFlag,
      tableName,
      flag,
      regionId,
      regionListTotal,
    } = this.state;

    const regionListToSet = localStorage.getItem("regionListFromYearAndMonthTargetWise");
    const regionListFromYearAndMonthLocalStorage = JSON.parse(regionListToSet);

    const stateListFromYearAndMonthData = localStorage.getItem("stateListFromYearAndMonth");
    const stateListFromYearAndMonthLocalStorage = JSON.parse(stateListFromYearAndMonthData);

    const zoneListFromYearAndMonthData = localStorage.getItem("zoneDetailsRegionwiseData");
    const zoneListFromYearAndMonthLocalStorage = JSON.parse(zoneListFromYearAndMonthData);

    const districtDetailsListZoneWiseData = localStorage.getItem("districtDetailsZoneWiseData");
    const districtListFromYearAndMonthLocalStorage = JSON.parse(districtDetailsListZoneWiseData);

    const talukaDetailsListDistrictWiseData = localStorage.getItem("talukaDetailsDistrictWiseData");
    const talukaDetailsDistrictWiseLocalStorage = JSON.parse(talukaDetailsListDistrictWiseData);

    const areaDetailsTalukaListWiseData = localStorage.getItem("areaDetailTalukaWiseData");
    const areaDetailTalukaWiseLocalStorage = JSON.parse(areaDetailsTalukaListWiseData);

    const regionListFromYearAndMonthTotalTargetWiseLocalStorage = localStorage.getItem("regionListFromYearAndMonthTotalTargetWise");

    const stateListFromYearAndMonthTotalLocalStorage = localStorage.getItem("stateTotalFromRegionTargetWise");
    const zoneListFromYearAndMonthTotalLocalStorage = localStorage.getItem("zoneTotalFromStateTargetWise");
    const districtListFromYearAndMonthTotalLocalStorage = localStorage.getItem("districtTotalFromZoneTargetWise");
    const talukaDetailsDistrictWiseTotalLocalStorage = localStorage.getItem("talukaTotalFromDistrictTargetWise");
    const areaDetailTalukaWiseTotalLocalStorage = localStorage.getItem("areaTotalFromTalukaTargetWise");

    return (
      <>
        <div
          id="layout-wrapper"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="vertical-overlay" />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div
                  className="row"
                  style={{
                    gap: 8,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "white",
                      padding: 5,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: -2,
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <div
                        className="card-header align-items-center d-flex"
                        style={{
                          background: "white",
                          borderRadius: 8,
                          border: "0 #E6E6E6 solid",
                          overflow: "scroll",
                        }}
                      >
                        <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                          Region
                          <div
                            style={{
                              marginLeft: -25,
                            }}
                          >
                            <SearchBar
                              placeHolder="Search..."
                              inputProps={{ "aria-label": "search" }}
                              searchValueForSalesRegion={
                                searchValueForSalesRegion
                              }
                              filteredRows={this.onSearchForSalesRegion}
                            ></SearchBar>
                          </div>
                        </h4>
                        <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{
                            textAlign: "end",
                            width: 180,
                          }}
                        >
                          <MonthCalender
                            title=""
                            name="saleMonth"
                            value={saleMonth}
                            onChange={this.onDateChange}
                            sx={{
                              paddingBottom: 0,
                            }}
                          />
                        </h4>
                      </div>
                    </div>
                    <TableContainer
                      sx={{
                        maxHeight: 440,
                        marginTop: 0,
                        padding: 0,
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          style={{
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              color="#000"
                              align="center"
                              sx={{ width: "2%", color: "#000" }}
                            >
                              Sr.No
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Region Name
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Total Staff
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Present
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Target Amount
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Achieved Amount
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Differents
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "8%", color: "#000" }}
                            >
                              Primary Amount
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "5%", color: "#000" }}
                            >
                              Secondary Amount
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "5%", color: "#000" }}
                            >
                              Differents
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ width: "5%", color: "#000" }}
                            >
                              Contribution in total(%)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {regionListFromYearAndMonthFlag
                            ? targetList.attendanceTargetAchievmentForRegion.length !== 0 &&
                            this.getFilteredTableData(targetList.attendanceTargetAchievmentForRegion).map(
                              (regionObject, index) => (
                                <TableRow key={regionObject.id}>
                                  <TableCell align="center">{index + 1}</TableCell>
                                  <TableCell width="2%">
                                    <div
                                      onClick={(e) => this.regionHandler(e, regionObject.id, 0)}
                                      style={{ color: cyanBlue, cursor: "pointer" }}
                                    >
                                      {regionObject.name}
                                    </div>
                                  </TableCell>
                                  <TableCell align="right">{regionObject.totalSalesTeam}</TableCell>
                                  <TableCell align="right">{regionObject.totalPresentSalesTeam}</TableCell>
                                  <TableCell align="right">{regionObject.target}</TableCell>
                                  <TableCell align="right">{regionObject.totalAchieve.toFixed(2)}</TableCell>
                                  <TableCell align="right">{regionObject.diffAchieveTarget.toFixed(2)}</TableCell>
                                  <TableCell align="right">{regionObject.primaryTotalAmt.toFixed(2)}</TableCell>
                                  <TableCell align="right">{regionObject.secondaryTotalAmt.toFixed(2)}</TableCell>
                                  <TableCell align="right">{regionObject.diffPrimarySecond.toFixed(2)}</TableCell>
                                  <TableCell align="right">{regionObject.contribution}</TableCell>
                                </TableRow>
                              )
                            )
                            : this.getFilteredTableData(
                              regionListFromYearAndMonthLocalStorage &&
                              regionListFromYearAndMonthLocalStorage
                            ).map((regionObject, index) => (
                              <TableRow key={regionObject.id}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell width="2%">
                                  <div
                                    onClick={(e) => this.regionHandler(e, regionObject.id, 0)}
                                    style={{ color: cyanBlue, cursor: "pointer" }}
                                  >
                                    {regionObject.name}
                                  </div>
                                </TableCell>
                                <TableCell align="right">{regionObject.totalSalesTeam}</TableCell>
                                <TableCell align="right">{regionObject.totalPresentSalesTeam}</TableCell>
                                <TableCell align="right">{regionObject.target}</TableCell>
                                <TableCell align="right">{regionObject.totalAchieve.toFixed(2)}</TableCell>
                                <TableCell align="right">{regionObject.diffAchieveTarget.toFixed(2)}</TableCell>
                                <TableCell align="right">{regionObject.primaryTotalAmt.toFixed(2)}</TableCell>
                                <TableCell align="right">{regionObject.secondaryTotalAmt.toFixed(2)}</TableCell>
                                <TableCell align="right">{regionObject.diffPrimarySecond.toFixed(2)}</TableCell>
                                <TableCell align="right">{regionObject.contribution}</TableCell>
                              </TableRow>
                            ))}
                          <TableRow>
                            <TableCell align="center" width="2%" colSpan={5}>
                              Total
                            </TableCell>
                            <TableCell align="right">
                              {regionListFromYearAndMonthTotalTargetWiseLocalStorage ?
                                regionListFromYearAndMonthTotalTargetWiseLocalStorage :
                                targetList.attendanceTargetAchievmentForRegionTotal}
                            </TableCell>
                            <TableCell align="center">-</TableCell>
                            <TableCell align="center">-</TableCell>
                            <TableCell align="center">-</TableCell>
                            <TableCell align="center">-</TableCell>
                            <TableCell align="center">-</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* Sales Table 1 end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {HierarchyPopUpFlag && (
          <HierarchyPopUp
            HierarchyPopUpFlag={HierarchyPopUpFlag}
            handleCloses={this.handleClosesHierarchyPopUp}
            popUpData={flag == 0 ? stateListFromYearAndMonth
              : flag == 1 ? zoneDetailsRegionwiseData
                : flag == 2 ? districtDetailsZoneWiseData
                  : flag == 3 ? talukaDetailsData
                    : targetList?.attendanceTargetAchievmentForArea}
            flag={flag}
            stateHandler={this.stateHandler}
            zoneHandler={this.zoneHandler}
            districtHandler={this.districtHandler}
            talukaHandler={this.talukaHandler}
            tableName={tableName}
            stateListFromYearAndMonthFlag={stateListFromYearAndMonthFlag}
            stateListFromYearAndMonthTotalLocalStorage={stateListFromYearAndMonthTotalLocalStorage}
            zoneListFromYearAndMonthTotalLocalStorage={zoneListFromYearAndMonthTotalLocalStorage}
            districtListFromYearAndMonthTotalLocalStorage={districtListFromYearAndMonthTotalLocalStorage}
            talukaDetailsDistrictWiseTotalLocalStorage={talukaDetailsDistrictWiseTotalLocalStorage}
            areaDetailTalukaWiseTotalLocalStorage={areaDetailTalukaWiseTotalLocalStorage}
            zoneByStateDataFlag={zoneByStateDataFlag}
            districtByZoneDataFlag={districtByZoneDataFlag}
            talukaByDistrictDataFlag={talukaByDistrictDataFlag}
            areaByTalukaDataFlag={areaByTalukaDataFlag}

          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  zoneList: state.zone,
  areaList: state.area,
  targetList: state.target,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  showNotification,
  getAttendanceTargetAchievmentForRegion,
  getAttendanceTargetAchievmentForState,
  getAttendanceTargetAchievmentForZone,
  getAttendanceTargetAchievmentForDistrict,
  getAttendanceTargetAchievmentForTaluka,
  getAttendanceTargetAchievmentForArea,
};
export default connect(mapStateToProps, mapDispatchToProps)(HiearchyDropDown);
