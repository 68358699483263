import axios from "axios";
//import { ShowToast, toastType } from "./toast-service";
import { decodeData } from "../components/Comman/Util/Base64";
import { serverMsg } from "../config/messageconstant";
import { showNotification } from "../Pages/Landing/Landing.slice";

export function getApiHeader() {
  return {
    authorization: `${decodeData(localStorage.getItem("token"))}`,
    shouldrefresh: "true",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export function cancelAllOngoingRequest() {
  if (source) {
    source.cancel();
  }
}

export function apiRequest({ url, params = {} }) {
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params },
      cancelToken: source.token,
    })
    .then((responses) => {
      return responses.data;
    })
    .catch(({ response }) => {
      // react on errors.
      //errorHandler(response);
      return response;
    });
}

export function apiBatchRequest(apiList) {
  const apiAxiosArr = [];

  apiList.forEach(({ url, params = {} }) => {
    apiAxiosArr.push(
      axios.get(url, {
        headers: getApiHeader(),
        ...{ params },
        cancelToken: source.token,
      })
    );
  });

  const response = genericAPI(apiAxiosArr, apiList);
  return response;
}

function genericAPI(apiAxiosArr, apiList) {
  const responseData = {};
  return axios
    .all(apiAxiosArr)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res, i) => {
          responseData[apiList[i].key] = res.data;
        });

        return responseData;
      })
    )
    .catch(({ response }) => {
      //errorHandler(response);
      return response;
    });
}

export function apiPut({ url, postBody, params = {} }) {
  return axios
    .put(url, postBody, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      return { data, success: statusHandler(status) };
    })
    .catch(({ response }) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}
export const apiPostForImage = async ({ url, postBody, params = {} }) => {
  const headers = {
    authorization: `${decodeData(localStorage.getItem("token"))}`,
    // shouldrefresh: "true",
    "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "*",
    shouldrefresh: "true",

    "Access-Control-Allow-Origin": "*",
  };
  return axios
    .post(url, postBody, {
      headers,
      ...{ params },
    })
    .then(({ data, status }) => {
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch((error) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
};
export function apiPost({ url, postBody, params = {} }) {
  return axios
    .post(url, postBody, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      if (error) {
        if (error.response.status == "401") {
          localStorage.clear();
          localStorage.setItem("loginCheck", "0");
          window.location.replace("/login");
        } else {
          localStorage.setItem("loginCheck", "1");
        }
        return { success: statusHandler(400), data: {} };
      } else {
        return { success: error, data: {} };
      }
    });
}

export function apiPostWithoutHeader({ url, postBody, params = {} }) {
  return axios
    .post(url, postBody, {
      ...{ params },
    })
    .then(({ data, status }) => {
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}

export function apiGetWithoutHeader({ url, params = {} }) {
  return axios
    .get(url, {
      ...{ params },
    })
    .then(({ data, status }) => {
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}
export function apiGet({ url, params = {} }) {
  console.log(url);
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error) {
        window.location.replace("/login");
        return null;
      }
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}
export function apiDelete({ url, params = {} }) {
  return axios
    .delete(url, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error) {
        window.location.replace("/login");
        return null;
      }
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}

export function apiPostForDownload({ url, postBody, params = {} }) {
  return axios
    .post(url, postBody, {
      headers: {
        Authorization: `${decodeData(localStorage.getItem("token"))}`,
        shouldrefresh: "true",
      },
      responseType: "blob",
    })
    .then(({ data, status }) => {
      if (data.error && status === 200) {
        window.location.replace("/login");
        return null;
      }
      localStorage.setItem("loginCheck", "1");
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      if (error.response.status == "401") {
        localStorage.clear();
        localStorage.setItem("loginCheck", "0");
        window.location.replace("/login");
      } else {
        localStorage.setItem("loginCheck", "1");
      }
      return { success: statusHandler(400), data: {} };
    });
}

function statusHandler(status) {
  if (!status) return;
  switch (status) {
    case 200:
      return true;

    default:
      return false;
  }
}
