// import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import { visuallyHidden } from "@mui/utils";
// import PropTypes from "prop-types";
// import * as React from "react";
// import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
// import { onlyNumber } from "../../components/Comman/Util/Validations";
// import { Checkbox } from "@mui/material";

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

// function EnhancedTableHead(props) {
//   const { order, tableHead, orderBy } = props;

//   return (
//     <TableHead>
//       <TableRow>
//         {tableHead.map((headCell) => {
//           if (headCell.showInscreen && headCell.title !== "Action") {
//             return (
//               <TableCell
//                 key={headCell.name}
//                 align={"center"}
//                 style={{ minWidth: headCell.width }}
//                 // padding={headCell.disablePadding ? 'none' : 'normal'}
//                 // sortDirection={orderBy === headCell.name ? order : false}
//               >
//                 {headCell.title}
//                 {orderBy === headCell.name ? (
//                   <Box component="span" sx={visuallyHidden}>
//                     {order === "desc"
//                       ? "sorted descending"
//                       : "sorted ascending"}
//                   </Box>
//                 ) : null}
//               </TableCell>
//             );
//           }
//           return null;
//         })}
//       </TableRow>
//     </TableHead>
//   );
// }
// function Row({ row, index, rowList = [], setList, flag }) {
//   const onTextFieldDataChange = (dataKey, productId) => (event) => {
//     const { value } = event.target;
//     if (
//       value === "" ||
//       onlyNumber.test(value) ||
//       (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
//     ) {
//       const ListToSet = rowList.map((rowD) => {
//         if (rowD.product.id == productId) {
//           if (+row.billQty < +event.target.value) {
//             rowD = {
//               ...rowD,
//               [dataKey]: value,
//               qty: event.target.value,
//               qtyError: true,
//               qtyErrorMsg: "Quantity Should be less than Bill Quantity",
//             };
//           } else {
//             rowD = {
//               ...rowD,
//               qty: event.target.value,
//               qtyError: false,
//               qtyErrorMsg: "",
//             };
//           }
//           return rowD;
//         } else {
//           return rowD;
//         }
//       });
//       setList({ row: ListToSet });
//       console.log(row);
//     }
//   };

//   const handleClick = (event, id) => {
//     const { checked } = event.target;
//     const ListToSet = rowList.map((rowD) => {
//       if (rowD.id == id) {
//         rowD = {
//           ...rowD,
//           checked: checked,
//         };
//         return rowD;
//       } else {
//         return rowD;
//       }
//     });
//     setList({ row: ListToSet });
//   };

//   console.log(row);
//   return (
//     <TableRow key={row.name}>
//       <TableCell padding="checkbox">
//         <Checkbox
//           color="primary"
//           onClick={(event) => handleClick(event, row.id)}
//           checked={row.checked}
//           disabled={false}
//           inputProps={{
//             "aria-labelledby": `enhanced-table-checkbox-${index}`,
//           }}
//         />
//       </TableCell>
//       <TableCell>
//         <center>{index + 1}</center>
//       </TableCell>
//       <TableCell style={{ width: "20%" }}>
//         {" "}
//         {row.product != null ? row.product.name : "-"}
//       </TableCell>
//       <TableCell style={{ width: "20%" }}>
//         <center>
//           {" "}
//           {row.product != null && row.product.outletUom != null
//             ? row.product.outletUom.name
//             : "-"}
//         </center>
//       </TableCell>

//       <TableCell align="right" style={{ width: "10%" }}>
//         {row.rate}
//       </TableCell>

//       <TableCell align="right" style={{ width: "10%" }}>
//         {row.billQty}
//       </TableCell>

//       <TableCell style={{ width: "10%" }}>
//         <TextFieldCompo
//           placeHolder={"Quantity"}
//           size="small"
//           color="primary"
//           type="number"
//           id={"1"}
//           value={row.qty}
//           onChange={onTextFieldDataChange("qty", row.product.id)}
//           fullWidth={false}
//           error={row.qtyError ? true : false}
//           errorText={row.qtyErrorMsg !== "" ? row.qtyErrorMsg : ""}
//           disabled={false}
//         />
//       </TableCell>
//       <TableCell align="right">{row.total}</TableCell>
//     </TableRow>
//   );
// }
// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(["asc", "desc"]).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// export default function MaterialUITableWithTextField({
//   tableHead = [],
//   setList,
//   rowList = [],
//   data = [],
//   flag,
// }) {
//   const [order, setOrder] = React.useState("asc");
//   const [orderBy, setOrderBy] = React.useState("calories");
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

//   return (
//     <Box sx={{ width: "100%" }}>
//       <TableContainer sx={{ maxHeight: 500 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <EnhancedTableHead
//             tableHead={tableHead}
//             numSelected={selected.length}
//             order={order}
//             orderBy={orderBy}
//             rowCount={data.length}
//           />
//           <TableBody>
//             {stableSort(data, getComparator(order, orderBy)).map(
//               (row, index) => {
//                 return (
//                   <Row
//                     rowList={rowList}
//                     row={row}
//                     setList={setList}
//                     index={index}
//                     flag={flag}
//                   />
//                 );
//               }
//             )}
//             {emptyRows > 0 && (
//               <TableRow
//                 style={{
//                   height: 53 * emptyRows,
//                 }}
//               >
//                 <TableCell colSpan={tableHead.length} />
//               </TableRow>
//             )}
//             {data.length === 0 && (
//               <TableRow
//                 style={{
//                   height: 53 * 1,
//                 }}
//               >
//                 <TableCell align="center" colSpan={tableHead.length}>
//                   No Record Found
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyNumber } from "../../components/Comman/Util/Validations";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    flag,
    selectAllFlag,
  } = props;

  return (
    <>
      <TableHead>
        <TableRow>
          <>
            <TableCell width={10} padding="checkbox">
              {selectAllFlag && (
                <Checkbox
                  color="error"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              )}

            </TableCell>
          </>

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              width={headCell.minWidth}
            >
              {headCell.title}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  tableHead,
  stockHolderAndProductConfiguration,
  selection,
  onSelectionChange,
  disabled = false,
  isCheckBox = true,
  setList,
  flag,
  freeQtyFlag,
  rowList,
  selectAllFlag = true,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabled) {
        let newSelecteds = [];
        rows.map((n) => {
          if (!n.disabled) {
            newSelecteds.push(n.id);
          }
        });
        onSelectionChange(newSelecteds);
      } else {
        const newSelecteds = rows.map((n) => n.id);
        onSelectionChange(newSelecteds);
      }

      return;
    }
    onSelectionChange([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    onSelectionChange(newSelected);
  };

  const isSelected = (name) => selection.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            selectAllFlag={selectAllFlag}
            headCells={tableHead}
            numSelected={selection.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            isCheckBox={isCheckBox}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    tableData={rows}
                    setList={setList}
                    rowList={rowList}
                    row={row}
                    stockHolderAndProductConfiguration={
                      stockHolderAndProductConfiguration
                    }
                    handleClick={handleClick}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    isCheckBox={isCheckBox}
                    flag={flag}
                    freeQtyFlag={freeQtyFlag}
                  />
                );
              }
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {rows.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length + 1}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row({
  row,
  index,
  rowList = [],
  setList,
  flag,
  labelId,
  isItemSelected,
}) {
  const onTextFieldDataChange = (dataKey, productId) => (event) => {
    const { value } = event.target;
    if (
      value === "" ||
      onlyNumber.test(value) ||
      (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
    ) {
      const ListToSet = rowList.map((rowD) => {
        if (rowD.product.id == productId) {
          if (+row.billQty < +event.target.value) {
            rowD = {
              ...rowD,
              [dataKey]: value,
              qty: event.target.value,
              qtyError: true,
              qtyErrorMsg: "Quantity Should be less than Bill Quantity",
            };
          } else {
            rowD = {
              ...rowD,
              qty: event.target.value,
              qtyError: false,
              qtyErrorMsg: "",
            };
          }
          return rowD;
        } else {
          return rowD;
        }
      });
      setList({ row: ListToSet });
      console.log(row);
    }
  };

  const handleClick = (event, id) => {
    const { checked } = event.target;
    const ListToSet = rowList.map((rowD) => {
      if (rowD.id == id) {
        rowD = {
          ...rowD,
          checked: checked,
        };
        return rowD;
      } else {
        return rowD;
      }
    });
    setList({ row: ListToSet });
  };
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={(event) => handleClick(event, row.id)}
          checked={row.checked}
          disabled={flag ? true : false}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>

      <TableCell>
        <center>{row.index}</center>
      </TableCell>
      <TableCell style={{ width: "20%" }}>
        {" "}
        {row.product != null ? row.product.name : "-"}
      </TableCell>
      <TableCell style={{ width: "20%" }}>
        <center>
          {" "}
          {row.product != null && row.product.outletUom != null
            ? row.product.outletUom.name
            : "-"}
        </center>
      </TableCell>

      <TableCell align="right" style={{ width: "10%" }}>
        {row.rate}
      </TableCell>

      <TableCell align="right" style={{ width: "10%" }}>
        {row.billQty}
      </TableCell>

      <TableCell style={{ width: "10%" }}>
        <TextFieldCompo
          placeHolder={"Quantity"}
          size="small"
          color="primary"
          type="number"
          id={"1"}
          value={row.qty}
          onChange={onTextFieldDataChange("qty", row.product.id)}
          fullWidth={false}
          error={row.qtyError ? true : false}
          errorText={row.qtyErrorMsg !== "" ? row.qtyErrorMsg : ""}
          disabled={false}
        />
      </TableCell>
      <TableCell align="right">{row.total}</TableCell>
    </TableRow>
  );
}
