import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";

class SubCategoryWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { subCatListWithValue, subCatList } = this.props;

    return (
      <>
        <TableContainer>
          <Table aria-label="simple table" size="medium">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ minWidth: "30px" }}
                ></TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: "70px" }}
                ></TableCell>
                {subCatList.map((subCategoryObject) => {
                  return (
                    <>
                      {" "}
                      <TableCell
                        align="center"
                        style={{ minWidth: "150px" }}
                        colspan={subCategoryObject.subCategory.length}
                      >
                        {subCategoryObject.name}
                      </TableCell>{" "}
                    </>
                  );
                })}
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Total
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ minWidth: "30px" }}>
                  Sr. No.
                </TableCell>
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Sales Team
                </TableCell>
                {subCatList.map((categoryObject) => {
                  return (
                    <>
                      {categoryObject.subCategory.map((subCategoryObject) => {
                        return (
                          <>
                            <TableCell
                              align="center"
                              style={{ minWidth: "150px" }}
                            >
                              {subCategoryObject.subCatName}
                            </TableCell>
                          </>
                        );
                      })}
                    </>
                  );
                })}
                <TableCell align="center" style={{ minWidth: "70px" }}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subCatListWithValue.map((salesPersonObject) => {
                return (
                  <>
                    <TableRow>
                      <TableCell align="center" component="td" scope="row">
                        {salesPersonObject.id}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "70px" }}
                        component="td"
                        scope="row"
                      >
                        {salesPersonObject.name}
                      </TableCell>
                      {salesPersonObject.categoryList.map((categoryObject) => {
                        return (
                          <>
                            {categoryObject.subCategory.map((subCatObject) => {
                              return (
                                <>
                                  <TableCell
                                    align="center"
                                    style={{ minWidth: "150px" }}
                                  >
                                    {subCatObject.value}
                                  </TableCell>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                      <TableCell component="td" scope="row">
                        {10000}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryWise);
