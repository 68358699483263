import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  showLoador,
  showNotification,
} from "../../src/Pages/Landing/Landing.slice";
import {
  getDashBourdMain,
  getDataForLineGraph,
} from "../../src/Slice/distributorDashbourd.slice";
import "../CSS/NewLanding.css";
import { onToggle } from "../CommonActions/common.slice";
import image8 from "../Images/image8.png";
import noti from "../Images/noti.png";
import sync from "../Images/sync.png";
import { logOut } from "../Pages/Login/login.slice";
import {
  getCategorywiseBillNew,
  getPackagingTypeNew,
  getTopOutletOrderListFromYearAndMonthByBillWise,
  getTopProductOrderListFromYearAndMonthBillWise,
} from "../Slice/billingHistory.slice";
import { getAdminDashboard } from "../Slice/dashboard.slice";
import { getPendingOrderByMonthYear } from "../Slice/outletOrderDetail.slice";
import { blackColor } from "../config/ColorObj";
import { imageUrl } from "../config/Url";
import endpoint from "../config/endpoints";
import { noInternetMsg, serverMsg } from "../config/messageconstant";
import { apiGet } from "../utils/api_service";
import {
  getAreaListFromTalukaYearAndMonth,
  getDistrictListFromYearAndMonth,
  getMonthYearTarget,
  getRegionListFromYearAndMonth,
  getSalesGraph,
  getSalesListFromYearAndMonth,
  getStateRegionwiseListFromYearAndMonth,
  getTalukaListFromYearAndMonth,
  getZoneListFromYearAndMonth,
  getZoneStatewiseListFromYearAndMonth,
} from "../Slice/adminDashboard.slice";
import { getAttendanceTargetAchievmentForRegion } from "../Slice/target.slice";
import { getStockStakeHolderType } from "../Slice/stockStakeHolderType.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(9),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "21px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    // border: "1px solid #ccc",
    borderRadius: "5px",
  },
}));
const breakpoint = 600;
const NavbarLanding = ({
  logOut,
  noticeList,
  onToggle,
  getDashBourdMain,
  getTopOutletOrderListFromYearAndMonthByBillWise,
  getAdminDashboard,
  getSalesListFromYearAndMonth,
  getRegionListFromYearAndMonth,
  getAttendanceTargetAchievmentForRegion,
  getDistrictListFromYearAndMonth,
  getPackagingTypeNew,
  getMonthYearTarget,
  getZoneListFromYearAndMonth,
  getZoneStatewiseListFromYearAndMonth,
  getCategorywiseBillNew,
  getStateRegionwiseListFromYearAndMonth,
  getSalesGraph,
  getStockStakeHolderType,
  getTopProductOrderListFromYearAndMonthBillWise,
  getPendingOrderByMonthYear,
  getDataForLineGraph,
  showLoador,
  showNotification,
  getTalukaListFromYearAndMonth,
  getAreaListFromTalukaYearAndMonth,
  dashboardData,
}) => {
  const toggleDrawer = () => {
    onToggle();
  };
  let newLocation = useLocation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [noticeMspPopUp, setNoticeMspPopUp] = React.useState(false);
  const [licensePopUp, setLicensePopUp] = React.useState(false);
  const [formStatus, setFormStatus] = React.useState("");
  const [denominationList, setDenominationList] = React.useState([
    {
      id: "K",
      name: "K",
    },
    {
      id: "L",
      name: "L",
    },
    {
      id: "Cr",
      name: "Cr",
    },
  ]);

  React.useEffect(() => {
    if (navigator.onLine) {
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

    let deno = localStorage.getItem("denomination");
    if (deno == null) {
      localStorage.setItem("denomination", "K");
    }
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onAutocompleteChange = (name, newValue) => {
    if (newValue !== "") {
      window.location.replace(newLocation.pathname);
      localStorage.setItem("denomination", newValue);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenPopup = () => {
    setNoticeMspPopUp(true);
  };

  const handleLink = async () => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url: endpoint.setting + "/by-key?key=video_link",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success || data.error) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (data !== null && Object.keys(data.data).length !== 0) {
            window.open(data.data.settingValue, "_blank");
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const getDashbardData = async () => {
    const userTypeId = localStorage.getItem("userTypeId");

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");

    if (userTypeId == 1 || userTypeId == 6) {
      if (navigator.onLine) {
        const showAttendanceTargetAchievement = localStorage.getItem(
          "showAttendanceTargetAchievement"
        );

        showLoador({ loador: true });
        await getAdminDashboard().then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (data !== null && Object.keys(data).length !== 0) {
              localStorage.setItem("adminDashboard", JSON.stringify(data));
            } else {
              localStorage.setItem("adminDashboard", JSON.stringify([]));
            }
          }
        });

        showLoador({ loador: true });
        await getStockStakeHolderType({ userTypeIds: "4" }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const stockStakeHolderTypeList =
                response.length !== 0 ? response : [];
              localStorage.setItem(
                "stockStakeHolderTypeListData",
                JSON.stringify(stockStakeHolderTypeList)
              );
              localStorage.setItem(
                "stockStakeHolderTypeId",
                response.length !== 0 ? response[0].id : ""
              );

              showLoador({ loador: true });
              getSalesListFromYearAndMonth({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                stockStakeHolderTypeId: response[0].id,
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  const salesList = response ? response : [];
                  localStorage.setItem(
                    "salesListFromYearAndMonth",
                    JSON.stringify(salesList)
                  );
                }
              });
            }
          }
        );

        if (showAttendanceTargetAchievement == 1) {
          let regionTotal = 0;
          showLoador({ loador: true });
          await getAttendanceTargetAchievmentForRegion({
            month: dayjs().format("MM"),
            year: dayjs().format("YYYY"),
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const regionListFromYearAndMonthList =
                response.length !== 0 ? response : [];
              localStorage.setItem(
                "regionListFromYearAndMonthTargetWise",
                JSON.stringify(regionListFromYearAndMonthList)
              );

              localStorage.setItem(
                "regionId",
                response.length !== 0 ? response[0].id : ""
              );

              regionListFromYearAndMonthList.map((regionObj) => {
                regionTotal = regionObj.finalTotal.toFixed(2);
              });
              localStorage.setItem(
                "regionListFromYearAndMonthTotalTargetWise",
                regionTotal
              );

              showLoador({ loador: true });
              getSalesGraph({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                regionId: response.length !== 0 ? response[0].id : "",
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  let graphData = [];
                  let monthData = [];

                  response.length !== 0
                    ? response.map((responseObject) => {
                      graphData.push(responseObject.achivedAmt);
                      monthData.push(responseObject.monthString);
                    })
                    : [];

                  localStorage.setItem("salesGraphData", graphData);
                  localStorage.setItem("salesMonthData", monthData);
                }
              });

              showLoador({ loador: true });
              getZoneListFromYearAndMonth({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                regionId: response[0].id,
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  localStorage.setItem(
                    "zoneDetailsSalesData",
                    response.length !== 0 ? response : []
                  );
                }
              });
            }
          });
        } else {
          let regionTotalViaMap = 0;
          showLoador({ loador: true });
          await getRegionListFromYearAndMonth({
            month: dayjs().format("MM"),
            year: dayjs().format("YYYY"),
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const regionListFromYearAndMonthList =
                response.length !== 0 ? response : [];
              localStorage.setItem(
                "regionListFromYearAndMonth",
                JSON.stringify(regionListFromYearAndMonthList)
              );

              localStorage.setItem(
                "regionId",
                response.length !== 0 ? response[0].id : ""
              );

              regionListFromYearAndMonthList.map((regionObj) => {
                regionTotalViaMap = regionObj.finalTotal;
              });
              localStorage.setItem(
                "regionListFromYearAndMonthTotal",
                regionTotalViaMap
              );

              showLoador({ loador: true });
              getSalesGraph({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                regionId: response.length !== 0 ? response[0].id : "",
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  let graphData = [];
                  let monthData = [];

                  response.length !== 0
                    ? response.map((responseObject) => {
                      graphData.push(responseObject.achivedAmt);
                      monthData.push(responseObject.monthString);
                    })
                    : [];

                  localStorage.setItem("salesGraphData", graphData);
                  localStorage.setItem("salesMonthData", monthData);
                }
              });

              showLoador({ loador: true });
              getZoneListFromYearAndMonth({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
                regionId: response[0].id,
              }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                } else {
                  localStorage.setItem(
                    "zoneDetailsSalesData",
                    response.length !== 0 ? response : []
                  );
                }
              });
            }
          });
        }

        showLoador({ loador: true });
        await getMonthYearTarget().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              let totalTargetNewList = [];
              let totalAchievedNewList = [];
              for (var i = 0; i < response.length; i++) {
                totalAchievedNewList.push(+response[i].achivedAmt);
                totalTargetNewList.push(+response[i].targetAmt);
              }

              let radioValue = [
                {
                  name: "Target",
                  data: totalTargetNewList,
                },
                {
                  name: "Achieved",
                  data: totalAchievedNewList,
                },
              ];

              const monthYearTargetList =
                radioValue.length !== 0 ? radioValue : [];
              localStorage.setItem(
                "monthYearTargetData",
                JSON.stringify(monthYearTargetList)
              );
            }
          }
        });

        if (showCompanyFlag == 3) {
          window.location.replace("/analytical-admin-dashboard");
        } else {
          window.location.replace("/admin-dashboard");
        }

      } else {
        showNotification({
          msg: myConstClass.noInternetMsg,
          severity: "error",
        });
      }
    } else {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getTopOutletOrderListFromYearAndMonthByBillWise({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const topOutletOrderList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "topOutletOrderListData",
              JSON.stringify(topOutletOrderList)
            );
          }
        });

        showLoador({ loador: true });
        await getTopProductOrderListFromYearAndMonthBillWise({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const topProductOrderList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "topProductOrderListData",
              JSON.stringify(topProductOrderList)
            );
          }
        });

        showLoador({ loador: true });
        await getPendingOrderByMonthYear({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            const outletOrderPendingList =
              response.length !== 0 ? response : [];
            localStorage.setItem(
              "outletOrderPendingListData",
              JSON.stringify(outletOrderPendingList)
            );

            let totalOutletPendingAmount = 0;
            response.map((outletObject) => {
              totalOutletPendingAmount =
                totalOutletPendingAmount + +outletObject.totalAmount;
            });
            const tableRowForOutletPending = response.length;
            localStorage.setItem(
              "tableRowForOutletPending",
              tableRowForOutletPending
            );
            localStorage.setItem(
              "totalOutletPendingAmount",
              totalOutletPendingAmount.toFixed(2)
            );
          }
        });

        showLoador({ loador: true });
        apiGet({
          url: endpoint.stackholder + "/check-license-expiration",
        }).then(({ data, success }) => {
          console.log(data);
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (data && data.data && Object.keys(data.data).length !== 0) {
              if (data.data.error) {
                let status = data.data.message;
                localStorage.setItem("licenseData", status);
                setFormStatus(status);
                setLicensePopUp(true);
              }
            }
          }
        });

        showLoador({ loador: true });
        await getDashBourdMain().then(({ response, success }) => {
          if (!success) {
            showLoador({ loador: false });
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            if (response !== null && Object.keys(response).length !== 0) {
              localStorage.setItem("distributorDash", JSON.stringify(response));

              let totalOfRemainingAmt = 0;
              response.dtoOutletPendingBill.map((dtoOutletPendingBillObj) => {
                totalOfRemainingAmt =
                  totalOfRemainingAmt + +dtoOutletPendingBillObj.remainingAmt;
              });

              localStorage.setItem(
                "totalOfRemainingAmt",
                totalOfRemainingAmt.toFixed(2)
              );
            } else {
              localStorage.setItem("distributorDash", JSON.stringify([]));
            }
          }
        });

        await getDataForLineGraph({ fromDate: "", toDate: "" }).then(
          ({ response, success }) => {
            console.log(response);
            if (!success) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            } else {
              if (response !== null && Object.keys(response).length !== 0) {
                localStorage.setItem("lineGraphData", JSON.stringify(response));
              } else {
                localStorage.setItem("lineGraphData", JSON.stringify([]));
              }
            }
            showLoador({ loador: false });
          }
        );

        if (showCompanyFlag == 3) {
          window.location.replace("/analytical-distributor-dashboard");
        } else {
          window.location.replace("/distributor-dashboard");
        }
      } else {
        showNotification({
          msg: myConstClass.noInternetMsg,
          severity: "error",
        });
      }
    }
  };

  const handleClosePopup = () => {
    setNoticeMspPopUp(false);
  };

  const handleCloseForLicense = () => {
    setLicensePopUp(false);
  };

  const logOutHandler = () => {
    showLoador({ loador: true });
    apiGet({
      url: endpoint.logOut,
    }).then(({ data, success }) => {
      if (success) {
        localStorage.clear();
        logOut();
        window.location.reload(true); // Hard refresh
        window.location.replace("/login");
      }
      showLoador({ loador: false });
    });
  };
  const changePassword = () => {
    window.location.replace("/change-password");
  };
  const userProfile = () => {
    window.location.replace("/user-profile");
  };

  const noticeMsgListDataList = localStorage.getItem("noticeMsgListData");
  const noticeMsgListDataLocalStorage = JSON.parse(noticeMsgListDataList);

  const licenseDataFlag = localStorage.getItem("licenseData");

  return (
    <>
      {width > breakpoint ? (
        <>
          <div
            className="navbar"
            style={{
              boxShadow: "2px 2px #ededed",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="col-lg-12 flex-wrap"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <div className="main_menu">
                  <ul>
                    <li>
                      <IconButton
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2, mt: 1 }}
                        onClick={toggleDrawer}
                      >
                        <MenuIcon color="primary-white" />
                      </IconButton>
                    </li>
                  </ul>
                </div>
                <p
                  style={{ justifyContent: "flex-start" }}
                  className="welcomelabel"
                >
                  <img
                    className="Image10"
                    style={{ width: 70, height: 40 }}
                    src={imageUrl + localStorage.getItem("complogo")}
                  />
                  &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;
                </p>
              </div>
              <div style={{ float: "right" }} className="login_r">
                <div
                  className="login_area"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "cener",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Button
                    sx={{
                      width: 20,
                      height: 35,
                      color: blackColor,
                      // ml: "1000px",
                      minWidth: "250px",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    startIcon={<PersonOutlineIcon />}
                    endIcon={<ExpandMoreIcon />}
                  >
                    {localStorage.getItem("firmName")}
                  </Button>
                  <img
                    className="Image10"
                    style={{ width: 32, height: 32 }}
                    src={sync}
                    onClick={getDashbardData}
                  />
                  <button type="button" className="icon-button">
                    <img
                      className="Image10"
                      style={{ width: 32, height: 32 }}
                      src={noti}
                      onClick={handleClickOpenPopup}
                    />
                    <span className="icon-button__badge">
                      {noticeMsgListDataLocalStorage
                        ? noticeMsgListDataLocalStorage.length
                        : noticeList.noticeMsg.length}
                    </span>
                  </button>
                  {/* <img
                      className="Image10"
                      style={{ width: 32, height: 32, marginTop: 15 }}
                      src={noti}
                      onClick={handleClickOpenPopup}
                    /> */}
                  <img
                    className="Image10"
                    style={{ width: 32, height: 32, marginLeft: "8px" }}
                    src={image8}
                    onClick={handleLink}
                  />
                  <Menu
                    id="basic-menu1"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={logOutHandler}> Logout </MenuItem>
                    <MenuItem onClick={userProfile}> User Profile </MenuItem>
                    <MenuItem onClick={changePassword}>
                      {" "}
                      Change Password{" "}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
            {/* <div className="clr"></div> */}
          </div>
        </>
      ) : (
        <>
          <div className="navbar">
            <div
              className="col-lg-6"
              style={{ justifyContent: "flex-start", flexDirection: "row" }}
            >
              <div className="main_menu">
                <ul>
                  <li>
                    <IconButton
                      size="medium"
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{ mr: 0, mt: 0.5 }}
                      onClick={toggleDrawer}
                    >
                      <MenuIcon color="primary-white" />
                    </IconButton>
                  </li>
                </ul>
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className="welcomelabel"
              >
                {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                {/* <AutoComplete
                  name={"Denomination"}
                  label={"Denomination"}
                  placeHolder={"Denomination"}
                  keyColName={"id"}
                  value={""
                  }
                  options={denominationList}
                  // onChange={onAutocompleteChange}
                  errorText={""}
                /> */}
                {/* </Grid> */}
              </div>
              <div style={{ justifyContent: "flex-end", flexDirection: "row" }}>
                <div className="login_area">
                  <div
                    style={{ float: "right", marginLeft: -100 }}
                    className="login_r"
                  >
                    <Button
                      sx={{
                        width: "auto",
                        height: 35,
                        mt: 0.7,
                        color: "#d2d2d2",
                        // ml: "1000px",
                        // minWidth: "150px",
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      startIcon={<PersonOutlineIcon />}
                    >
                      {localStorage.getItem("userName")}
                    </Button>
                    <Menu
                      id="basic-menu1"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={logOutHandler}> Logout </MenuItem>
                      <MenuItem onClick={[]}> User Profile </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
        </>
      )}
      {noticeMspPopUp &&
        (noticeMsgListDataLocalStorage
          ? noticeMsgListDataLocalStorage
          : noticeList.noticeMsg
        ).length !== 0 && (
          <BootstrapDialog
            onClose={handleClosePopup}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClosePopup}
            >
              Notification -{" "}
              {
                (noticeMsgListDataLocalStorage
                  ? noticeMsgListDataLocalStorage
                  : noticeList.noticeMsg
                ).length
              }
            </BootstrapDialogTitle>
            <DialogContent dividers>
              {(noticeMsgListDataLocalStorage
                ? noticeMsgListDataLocalStorage
                : noticeList.noticeMsg
              ).map((noticeObject, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="center"
                  >
                    <img
                      className="Image14"
                      style={{ width: 810, height: 700 }}
                      src={`https://bzintel-bucket.s3.ap-south-1.amazonaws.com/distribution/document/${noticeObject.image}`}
                      alt={`Image ${index}`}
                    />
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="center"
                  >
                    <div style={{ fontSize: "large", fontWeight: "550" }}>
                      {noticeObject.noticeHeadline}
                    </div>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="left"
                  >
                    <div style={{ fontSize: "small" }}>
                      {noticeObject.description}
                    </div>
                  </Grid>
                  <br />
                </React.Fragment>
              ))}
            </DialogContent>
          </BootstrapDialog>
        )}
      {licensePopUp && formStatus && (
        <>
          <BootstrapDialog
            onClose={handleCloseForLicense}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseForLicense}
            >
              License
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="center"
                >
                  <div style={{ fontSize: "large" }}>{formStatus}</div>
                </Grid>
                <br />
              </>
            </DialogContent>
          </BootstrapDialog>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  distributorDashbourd: state.distributorDashbourd,
  noticeList: state.noticeMsg,
  billingHistoryList: state.billingHistory,
  salesExecutiveList: state.salesExecutive,
  outletOrderDetailList: state.outletOrderDetail,
  beatList: state.beat,
});

const mapDispatchToProps = {
  onToggle,
  logOut,
  getDashBourdMain,
  getAdminDashboard,
  getSalesListFromYearAndMonth,
  getRegionListFromYearAndMonth,
  getZoneListFromYearAndMonth,
  getZoneStatewiseListFromYearAndMonth,
  getCategorywiseBillNew,
  getPackagingTypeNew,
  getMonthYearTarget,
  getSalesGraph,
  getStateRegionwiseListFromYearAndMonth,
  getStockStakeHolderType,
  getTopOutletOrderListFromYearAndMonthByBillWise,
  getTopProductOrderListFromYearAndMonthBillWise,
  getPendingOrderByMonthYear,
  getDataForLineGraph,
  getDistrictListFromYearAndMonth,
  showLoador,
  showNotification,
  getTalukaListFromYearAndMonth,
  getAreaListFromTalukaYearAndMonth,
  getAttendanceTargetAchievmentForRegion,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarLanding);
