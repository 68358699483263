import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  cashMsg,
  noInternetMsg,
  onlineAmtMsg,
  onlineMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { paymentFormStaticJson } from "../../DynamicFormsJson/Transaction/paymentFormStaticJson";
import {
  getOutletBillDetailFromVehicleAndDate,
  setOutletBillDetailFromVehicleAndDate,
} from "../../Slice/orderBillList.slice";
import { getActiveVehicleNoListByStockholderNew } from "../../Slice/veichleSlice.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class PaymentFormByVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dynamicMasterData: {
        vehicleDetail: this.props.vehicleList.vehicle,
      },
      total: 0,
      cash: "",
      online: "",
      formErrors: {},
    };
  }
  async componentDidMount() {
    const {
      getActiveVehicleNoListByStockholderNew,
      showLoador,
      showNotification,
      setOutletBillDetailFromVehicleAndDate,
    } = this.props;
    if (navigator.onLine) {

      setOutletBillDetailFromVehicleAndDate({ row: [] });

      showLoador({ loador: true });
      getActiveVehicleNoListByStockholderNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSearchData = async (data) => {
    const {
      getOutletBillDetailFromVehicleAndDate,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.vehicleNo && data.fromDate && data.toDate) {
        showLoador({ loador: true });
        await getOutletBillDetailFromVehicleAndDate({
          vehicleNo: data.vehicleNo.id,
          fromDate: data.fromDate,
          toDate: data.toDate,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              let paymentTotal = 0;
              response.map((object) => {
                paymentTotal = paymentTotal + +object.amount;
              });
              this.setState({
                total: paymentTotal.toFixed(2),
              });
            }
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.vehicleList !== nextProps.vehicleList) {
      if (this.props.vehicleList?.vehicle !== nextProps.vehicleList?.vehicle) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            vehicleDetail: nextProps.vehicleList?.vehicle,
          },
        });
      }
    }
  }
  onTextFieldDataChange = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromVehicleAndDate } =
      this.props;
    let outletRow = billHeaderList?.outletBillDetailFromVehicleAndDate.map(
      (object) => {
        if (object.id == row.id) {
          object = {
            ...object,
            remark: event.target.value,
          };
          return object;
        } else {
          return object;
        }
      }
    );
    setOutletBillDetailFromVehicleAndDate({ row: outletRow });
  };
  checkValidation = () => {
    const { total, cash, online } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (cash === "") {
      formIsValid = false;
      formErrors["cashError"] = cashMsg;
    }
    if (online === "") {
      formIsValid = false;
      formErrors["onlineError"] = onlineMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "cash") {
      this.setState({
        cash: value,
      });
    }
    if (name === "online") {
      this.setState({
        online: value,
      });
    }
  };
  onTextFieldDataChangeForNumber = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromVehicleAndDate } =
      this.props;
    let outletRow = billHeaderList?.outletBillDetailFromVehicleAndDate.map(
      (object) => {
        if (object.id == row.id) {
          if (+row.remainingAmt < +event.target.value) {
            object = {
              ...object,
              amount: event.target.value,
              amountError: true,
              amountErrorMsg: "Amount should be less than pending bill amount",
            };
          } else if (+event.target.value == 0) {
            object = {
              ...object,
              amount: event.target.value,
              amountError: true,
              amountErrorMsg: "Amount should be greater than 0",
            };
          } else {
            object = {
              ...object,
              amount: event.target.value,
              amountError: false,
              amountErrorMsg: "",
            };
          }
          return object;
        } else {
          return object;
        }
      }
    );

    let paymentTotal = 0;
    outletRow.map((object) => {
      paymentTotal = paymentTotal + +object.amount;
    });
    this.setState({
      total: paymentTotal.toFixed(2),
    });
    setOutletBillDetailFromVehicleAndDate({ row: outletRow });
  };

  handleChange = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromVehicleAndDate } =
      this.props;
    let outletRow = billHeaderList?.outletBillDetailFromVehicleAndDate.map(
      (object) => {
        if (object.id == row.id) {
          object = {
            ...object,
            payType: event.target.value,
          };
          return object;
        } else {
          return object;
        }
      }
    );
    setOutletBillDetailFromVehicleAndDate({ row: outletRow });
  };

  savePaymentOutlet = () => {
    const { showNotification, showLoador, billHeaderList } = this.props;
    const { cash, online } = this.state;
    let billIds = [];
    let remarks = [];

    billHeaderList?.outletBillDetailFromVehicleAndDate.map((paymentOutletObject) => {
      billIds.push(paymentOutletObject.id);
      remarks.push(paymentOutletObject.remark);
    });

    let paymentOutletData = {
      cashAmount: cash,
      onlineAmount: online,

      dtoOutletBillHeaderAndRemarks: billIds.map((billId, index) => ({
        outletBillHeaderId: billId,
        remark: remarks[index]
      })),

    };
    if (this.checkValidation()) {
      const { cash, online, total } = this.state;

      if (+cash + +online == total) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              apiPost({
                url: endpoint.outletBillHeader + "/get-payments",
                postBody: paymentOutletData,
              }).then(({ success }) => {
                if (success) {
                  showLoador({ loador: false });
                  showNotification({
                    msg: savemsg,
                  });
                  window.location.reload(
                    "/outlet-and-vehiclewise-payment-for-outlet"
                  );
                } else {
                  showNotification({
                    msg: serverMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      } else {
        showNotification({
          msg: onlineAmtMsg,
          severity: "error",
        });
      }
    }
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow.beatName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.firmName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.billNo
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.billDate
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.remainingAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { billHeaderList } = this.props;
    const { dynamicMasterData, total, online, cash, formErrors } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showSaveBtn={false}
            showSaveBtnMain={true}
            dynamicMasterData={dynamicMasterData}
            showTitle={false}
            screenTitle={paymentFormStaticJson.screenTitle}
            fieldMeta={paymentFormStaticJson.fieldMetaByVehicle}
            showCancel={false}
            showBackToList={false}
            showSaveNextBtn={paymentFormStaticJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearchData}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr. No.</TableCell>
                  <TableCell align="center">Beat</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 250,
                    }}
                  >
                    Outlet
                  </TableCell>
                  <TableCell align="center">Bill No.</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 100,
                    }}
                  >
                    Bill Date
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 180,
                    }}
                  >
                    Outstanding Amount
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 200,
                    }}
                  >
                    Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billHeaderList?.outletBillDetailFromVehicleAndDate.length !==
                  0 ? (
                  this.getFilteredTableData(
                    billHeaderList?.outletBillDetailFromVehicleAndDate
                  ).map((row, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{row.beatName}</TableCell>
                      <TableCell align="left">{row.firmName}</TableCell>
                      <TableCell align="center">{row.billNo}</TableCell>
                      <TableCell align="center">{row.billDate}</TableCell>
                      <TableCell align="right">{row.remainingAmt}</TableCell>
                      <TableCell align="right">
                        <Grid item xs={8} md={4} sm={4}>
                          <TextFieldCompo
                            placeHolder={"Remark"}
                            size="small"
                            color="primary"
                            name={"remark"}
                            id={"remark"}
                            value={row.remark}
                            onChange={(e) => this.onTextFieldDataChange(e, row)}
                            fullWidth
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="left"
            >
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label={"Total Outstanding Amount : "}
              />{" "}
              &nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={total}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label={"Cash : "}
              />{" "}
              <Grid item xs={8} md={4} sm={4}>
                <TextFieldCompo
                  placeHolder={"Enter Cash Amount"}
                  size="small"
                  color="primary"
                  name={"cash"}
                  id={"cash"}
                  value={cash}
                  onChange={this.changeHandler}
                  fullWidth
                  errorText={
                    formErrors["cashError"] ? formErrors["cashError"] : ""
                  }
                />
              </Grid>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label={"Online : "}
              />{" "}
              <Grid item xs={8} md={4} sm={4}>
                <TextFieldCompo
                  placeHolder={"Enter Online Amount"}
                  size="small"
                  color="primary"
                  name={"online"}
                  id={"online"}
                  value={online}
                  onChange={this.changeHandler}
                  fullWidth
                  errorText={
                    formErrors["onlineError"] ? formErrors["onlineError"] : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={this.savePaymentOutlet}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleList: state.vehicle,
  billHeaderList: state.billHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getActiveVehicleNoListByStockholderNew,
  setOutletBillDetailFromVehicleAndDate,
  getOutletBillDetailFromVehicleAndDate,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFormByVehicle);
