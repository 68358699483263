import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../../components/Comman/RT/RTTable";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg } from "../../../config/messageconstant";
import { groupProductJson } from "../../../DynamicFormsJson/MastersJSON/groupProduct";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class GroupProductView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      groupProductList: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product",
        name: "productName",
      },
      { name: "code", title: "Code", align: "center" },
      { name: "productCode", title: "Extra Product Code", align: "center" },
      { name: "selfLife", title: "Shelf Life", align: "right" },
    ];
  }
  async componentDidMount() {
    const { showLoador, showNotification, apiBaseURL } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.group +
            "/" +
            this.props.params.id +
            "/with-product-by-group",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            let rowList = data.data.products.map(
              (groupProductObject, index) => {
                let newList = {
                  index: index + 1,
                  productName:
                    groupProductObject.name +
                    " (" +
                    groupProductObject.shortName +
                    ")",
                  code: groupProductObject.code,
                  productCode: groupProductObject.productCode === null ? "-" : groupProductObject.productCode,
                  selfLife: groupProductObject.selfLife,
                };
                return newList;
              }
            );
            this.setState({
              groupProductList: rowList,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Group Name",
                  value:
                    data.data.group.name !== null
                      ? data.data.group.name +
                      " (" +
                      data.data.group.shortName +
                      ")"
                      : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Group Code",
                  value:
                    data.data.group.code !== null ? data.data.group.code : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Category",
                  value:
                    data.data.group.subCategory !== null
                      ? data.data.group.subCategory.category.name
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Brand",
                  value:
                    data.data.group.brand !== null
                      ? data.data.group.brand.name
                      : "",
                },
                {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  labelSM: 2,
                  labelMD: 2,
                  labelLG: 2,
                  valueSM: 10,
                  valueMD: 10,
                  valueLG: 10,
                  label: "Description",
                  value:
                    data.data.group.description !== null
                      ? data.data.group.description
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { DataToSet, groupProductList } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            showSaveBtn={groupProductJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Group-Product View"}
            fieldMeta={[]}
            showCancel={groupProductJson.showCancel}
            apiBaseURL={groupProductJson.apiBaseURL}
            showSaveNextBtn={groupProductJson.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <RTTable
            columns={this.columns}
            tableData={groupProductList}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  groupProductList: state.groupProduct,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupProductView);
