import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import {
    noInternetMsg,
    serverMsg
} from "../../../config/messageconstant";
import { SalesPeronDataForManagerJson } from "../../../DynamicFormsJson/MastersJSON/salesPersonDataForManager";
import { getAreaByUserType, getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getTargetByAreaIdsAndMonth } from "../../../Slice/target.slice";
import RTTable from "../../../components/Comman/RT/RTTable";
import dayjs from "dayjs";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";

class SalesPersonDataForManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                stateDetails: this.props.stateList?.stateByRegion,
                zoneDetail: this.props.zoneList?.zoneByState,
                districtDetail: this.props.districtList?.districtByZone,
                talukaDetail: this.props.talukaList?.talukaByDistrict,
                areaByTalukaDetail: this.props.areaList.areaByTaluka,
            },
            searchValue: "",
        };
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "User Name",
                name: "userName",
            },
            {
                title: "Name",
                name: "name",
            },
            {
                title: "Stock Holder Type",
                name: "stockStakeHolderTypeName",
            },
            {
                title: "Target Amount",
                name: "targetAmt",
                align: "right",
            },
            {
                title: "Achieved Amount",
                name: "achievedAmt",
                align: "right",
            },
        ];
    }
    async componentDidMount() {
        const { getRegionNew, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {

            showLoador({ loador: true });
            getRegionNew().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }

        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

    }


    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBody,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.region && data.region.id) {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region.id }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state) {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone) {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district) {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                let talukaJson = {
                    talukaIds: data.taluka,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSearchData = async (data) => {
        const { getTargetByAreaIdsAndMonth, showLoador, showNotification } = this.props;

        if (navigator.onLine) {

            const currentMonth = dayjs(data.monthYear, ["D-M-YYYY", "D-MM-YYYY", "DD-M-YYYY", "DD-MM-YYYY"]).format("MM-YYYY");

            showLoador({ loador: true });
            await getTargetByAreaIdsAndMonth({ monthYear: currentMonth, areaIds: data.areaIds }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    getFilteredTableData = (targetList) => {
        const { searchValue } = this.state;
        return targetList.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["name"] &&
                    currentRow["name"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase())) ||
                (currentRow["userName"] &&
                    currentRow["userName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
            return isValid;
        });
    };



    render() {
        const { targetList } = this.props;
        const { dynamicMasterData } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={false}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={true}
                        getListById={this.getListById}
                        screenTitle={SalesPeronDataForManagerJson.screenTitle}
                        fieldMeta={SalesPeronDataForManagerJson.fieldMeta}
                        showSaveBtnMain={false}
                        showSaveNextBtn={false}
                        showSaveBtn={true}
                        saveBtnText={"Search"}
                        onSave={this.onSearchData}
                        padding={true}
                        paddingTop={true}
                    />

                    <LandingScreenHeader
                        screenTitle={""}
                        showSearchBox={true}
                        showTable={true}
                        showFilter={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        onSearch={this.onSearch}
                    />

                    <RTTable
                        columns={this.columns}
                        tableData={this.getFilteredTableData(targetList?.targetByAreaAndMonth)}
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    targetList: state.target,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getRegionNew,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTalukaPostBody,
    getAreaByUserType,
    getTargetByAreaIdsAndMonth,

};
export default connect(mapStateToProps, mapDispatchToProps)(SalesPersonDataForManager);
