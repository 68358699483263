import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { getManageStockById } from "../../Slice/manageStock.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { getAuditHeadById } from "../../Slice/audit.slice";
import AuditQuestionAnswer from "./AuditQuestionAnswer";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue } from "../../config/ColorObj";
import PendingSurveyDetailsPopUp from "./PendingSurveyDetailsPopUp";

class AuditSurveyData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            searchValue: "",
            pendingSurveyOpenPopUpFlag: false,
            dataRow: "",
        };

    }

    async componentDidMount() {
        const { getAuditHeadById, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getAuditHeadById({
                    auditHeadId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    onBack = () => {
        this.props.navigate(-1);
    }


    conductedSurvey = (rowData) => {
        console.log(rowData);
        this.props.navigate("/survey-details/" + rowData.auditHeadId);
    }

    pendingSurvey = (rowData) => {

        this.setState({ dataRow: rowData, pendingSurveyOpenPopUpFlag: true })
    }

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["areaName"] &&
                    currentRow["areaName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };

    toggle = () => {
        this.setState({ pendingSurveyOpenPopUpFlag: false })
    }


    onSearchBox = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    render() {
        const { auditList } = this.props;
        const { pendingSurveyOpenPopUpFlag, dataRow } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >


                    <DynamicFormWithoutSave
                        showBackToList={true}
                        showSaveBtn={auditJson.showSaveBtn}
                        showTitle={true}
                        screenTitle={"Survey Details"}
                        fieldMeta={[]}
                        showCancel={auditJson.showCancel}
                        apiBaseURL={auditJson.apiBaseURL}
                        showSaveNextBtn={auditJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />

                    {auditList?.auditHeadById?.length !== 0 && (
                        <>

                            <LandingScreenHeader
                                screenTitle={""}
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearchBox}
                            />
                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell align="center" width="25%">Area Name</TableCell>
                                            <TableCell align="center" width="25%">Conducted Survey</TableCell>
                                            <TableCell align="center" width="25%">Pending Survey</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(auditList?.auditHeadById)?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="left">{row.areaName}</TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        onClick={(e) =>
                                                            this.conductedSurvey(row)
                                                        }
                                                        style={{
                                                            color: cyanBlue,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {row.surveyDoneCount}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        onClick={(e) =>
                                                            this.pendingSurvey(row)
                                                        }
                                                        style={{
                                                            color: cyanBlue,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {row.surveyRemainCount}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </>
                    )
                    }


                    {pendingSurveyOpenPopUpFlag && (
                        <PendingSurveyDetailsPopUp
                            open={pendingSurveyOpenPopUpFlag}
                            dataRow={dataRow}
                            handleCloses={this.toggle}
                        />
                    )
                    }
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditHeadById,
    getManageStockById,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuditSurveyData);
