import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { compose } from "@reduxjs/toolkit";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import dayjs from "dayjs";

import { apiGet } from "../../utils/api_service";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import NewMultipleSelect from "../Comman/NewMultipleSelect";
import { TextFieldCompo } from "../Comman/TextFieldCompo";
import TimePickerCompo from "../Comman/TimePicker";
import {
  onlyNumber,
  validEmail,
  validText,
  validPoint,
  validTextNo,
  gstNo,
  validUdyamAadhar,
} from "../Comman/Util/Validations";
import { withRouter } from "../withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import {
  titleColor,
  redColor,
  labelColor,
  blackColor,
} from "../../config/ColorObj";
class DynamicFormWithoutSave extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      formErrors: {},
      staticFieldData: {},
    };
  }
  setDataToFieldData = (rowData) => {
    const { fieldMeta, dynamicMasterData } = this.props;
    let fieldData = {};

    fieldMeta.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] =
          rowData[tableDataKey] && rowData[tableDataKey] != null
            ? rowData[tableDataKey].split(",")
            : [];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey] &&
              rowData[currentField.dataKey] != null
              ? rowData[currentField.dataKey].split(",")
              : [];
        } else {
          fieldData[currentField.dataKey] = [];
        }
      } else if (
        currentField.controlType === "hideTextfieldNotEqual" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] !== currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] === currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] === currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    console.log(fieldData);
    this.setState({
      fieldData: fieldData,
    });
  };
  componentDidMount() {
    const {
      fieldMeta = [],
      showNotification,
      getDataById,
      defaultData = {},
      getByIdApi = true,
      callApi = false,
    } = this.props;
    const dataToReset = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.defaultValue) {
        dataToReset[currentField.dataKey] = currentField.defaultValue;
      }
    });
    this.setState({
      fieldData: dataToReset,
    });
    if (Object.keys(defaultData).length != 0) {
      this.setDataToFieldData(defaultData);
    }
    if (this.props.params.id && callApi) {
      if (!getByIdApi) {
        const { apiBaseURL, showLoador } = this.props;
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiGet({
            url: apiBaseURL + "/" + this.props.params.id,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (!data.error) {
                let rowData = data.data;

                this.setDataToFieldData(rowData);
              }
            }

            return success;
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      } else {
        getDataById(this.props.params.id).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setDataToFieldData(data);
          }
        });
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.fieldDataStatic !== nextProps.fieldDataStatic) {
      this.setState({
        fieldData: {},
      });
    }
    if (this.props.setFieldData !== nextProps.setFieldData) {
      this.setState({
        fieldData: nextProps.setFieldData,
      });
    }
  }
  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    if (newValue != null) {
      let value = "";
      if (isRootLevelKey) {
        value = newValue;
      } else {
        value = {
          id: newValue,
        };
      }
      this.onDataChange(dataKey, value);
    } else {
      let value = "";
      this.onDataChange(dataKey, value);
    }
  };

  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
    const filterData = dataValue.filter((rowData) => rowData == 0);
    const filterDataWithoutAll = dataValue.filter((rowData) => rowData != 0);
    if (
      filterData.length != 0 &&
      dynamicMasterData[masterName].length != filterDataWithoutAll.length
    ) {
      dataValue = dynamicMasterData[masterName].map((rowData) => {
        return rowData.id;
      });
    } else if (filterData.length != 0) {
      dataValue = [];
    }
    this.onDataChange(dataKey, dataValue);
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldMeta, getDataForDisabled, getDataForCalculate } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    let dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.dataKey === fieldName && currentField.calculateMinus) {
        const dataStatic = getDataForCalculate(newValue, dataToReset);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.props.getListById(dataToSearch);
      }

      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        console.log(currentField.getListFrom);
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        !currentField.disableTrue &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  onTextFieldDataChange = (dataKey, inputType, spValid) => (event) => {
    if (inputType == "number") {
      if (spValid == "float") {
        if (
          event.target.value === "" ||
          (event.target.value !== "e" && +event.target.value >= 0)
        ) {
          this.onDataChange(dataKey, event.target.value);
        }
        // else {
        //   this.onDataChange(dataKey, 0);
        // }
      } else {
        if (event.target.value === "" || onlyNumber.test(event.target.value)) {
          this.onDataChange(dataKey, event.target.value);
        }
        // else {
        //   this.onDataChange(dataKey, 0);
        // }
      }
    } else if (inputType == "numberText") {
      if (event.target.value === "" || validTextNo.test(event.target.value)) {
        this.onDataChange(dataKey, event.target.value);
      }
    } else {
      if (event.target.value === "" || validText.test(event.target.value)) {
        this.onDataChange(dataKey, event.target.value);
      }
    }
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  onFormSubmit = () => () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData } = this.state;
      const { fieldMeta, type = "", resetAfterSave = false } = this.props;

      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };
      if (type !== "") {
        dataToSave["formType"] = type;
      }
      if (fieldData["id"] && this.props.params.copyToFlag != "1") {
        dataToSave["id"] = fieldData["id"];
      }

      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (
          currentField.controlType === "hideTextfieldNotEqual" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] !== currentField.open) {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.props.onSubmit(dataToSave);
      if (resetAfterSave) {
        this.setState({
          fieldData: {},
        });
      }
    }
  };

  onFormSave = () => () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData } = this.state;
      const { fieldMeta, type = "", resetAfterSave = false } = this.props;

      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };
      if (type !== "") {
        dataToSave["formType"] = type;
      }
      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (
          currentField.controlType === "hideTextfieldNotEqual" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] !== currentField.open) {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.props.onSave(dataToSave);
      if (resetAfterSave) {
        const dataToReset = {};
        fieldMeta.forEach((currentField) => {
          if (currentField.defaultValue) {
            dataToReset[currentField.dataKey] = currentField.defaultValue;
          }
        });
        this.setState({
          fieldData: dataToReset,
        });
      }
    }
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "validUdyamAadhar" &&
          !validUdyamAadhar.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "gstNo" &&
          !gstNo.test(fieldData[currentField.dataKey])
        ) {
          if (currentField.controlType != "hideTextfieldNotEqual") {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          } else if (
            currentField.controlType == "hideTextfieldNotEqual" &&
            fieldData[currentField.tableDataKey] !== currentField.open
          ) {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          }
        }
        if (
          (currentField.validName === "validPointName" &&
            fieldData[currentField.dataKey] >= 11) ||
          fieldData[currentField.dataKey] <= 0
        ) {
          console.log(fieldData[currentField.dataKey] < 0);
          console.log(fieldData[currentField.dataKey] > 11);
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} must be between 1-10`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] == 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (currentField.validName === "greater-less") {
          if (currentField.zeroValid && fieldData[currentField.dataKey] == 0) {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          } else {
            const validArr = currentField.validCondition.split("-");
            const errorData =
              validArr[0] == "less"
                ? +fieldData[currentField.dataKey] > +fieldData[validArr[1]] &&
                +fieldData[currentField.dataKey] != +fieldData[validArr[1]]
                : +fieldData[currentField.dataKey] < +fieldData[validArr[1]] &&
                +fieldData[currentField.dataKey] != +fieldData[validArr[1]];
            console.log(errorData);
            if (errorData) {
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText:
                    validArr[0] == "less"
                      ? currentField.label +
                      ` shound be less than ` +
                      currentField.validText
                      : currentField.label +
                      ` shound be greater than ` +
                      currentField.validText,
                },
              };
            }
          }
        }
        if (
          currentField.validName === "greater-thanHundered" &&
          fieldData[currentField.dataKey] > 100
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} should not be gretaer than 100 per.`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfieldNotEqual") {
        if (
          fieldData[currentField.tableDataKey] !== currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  render() {
    const {
      showTitle = true,
      fieldMeta = [],
      dynamicMasterData = {},
      showSaveBtn = false,
      showSubmit = false,
      showSaveBtnMain = true,
      showAddButton = false,
      saveBtnText = "",
      screenTitle = "",
      showCancel = false,
      DataToSet = [],
      showBackToList = false,
      showTable = false,
      paddingTop = false,
      padding = false,
      GridSave = 0,
      disableCondition = false,
      isNote = false,
      isNoteValue = "",
      onSave,
    } = this.props;

    const { formErrors, fieldData } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: padding ? 2 : 0,
            pt: paddingTop ? 2 : 0,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showTitle && (
            <LandingScreenHeader
              screenTitle={screenTitle}
              showTable={showTable}
              showBackButton={showBackToList}
              showAddButton={showAddButton}
              onCancel={this.onCancel}
            />
          )}
          {showTitle && <br />}
          {isNote && (
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 14, color: redColor, paddingLeft: 5 }}
                label={"Note : " + isNoteValue}
              />
            </Grid>
          )}

          {DataToSet.length !== 0 && (
            <>
              <Grid container sx={{ margin: 1 }} spacing={1}>
                {DataToSet.map((dropDownData) => (
                  <Grid
                    container
                    xs={dropDownData.xs}
                    sm={dropDownData.sm}
                    md={dropDownData.md}
                    lg={dropDownData.lg}
                  >
                    <Grid
                      item
                      xs={dropDownData.labelXS ? dropDownData.labelXS : 4}
                      sm={dropDownData.labelSM ? dropDownData.labelSM : 4}
                      md={dropDownData.labelMD ? dropDownData.labelMD : 4}
                      lg={dropDownData.labelLG ? dropDownData.labelLG : 4}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label={dropDownData.label + " :"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={dropDownData.valueXS ? dropDownData.valueXS : 8}
                      sm={dropDownData.valueSM ? dropDownData.valueSM : 8}
                      md={dropDownData.valueMD ? dropDownData.valueMD : 8}
                      lg={dropDownData.valueLG ? dropDownData.valueLG : 8}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={dropDownData.value}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* <br /> */}
            </>
          )}

          <Grid container spacing={1}>
            {fieldMeta.map((currentField) => (
              <Grid
                container
                xs={currentField.xs}
                sm={currentField.sm}
                md={currentField.md}
                sx={{
                  marginTop: currentField.marginTop
                    ? currentField.marginTop
                    : 1,
                }}
              //  rowSpacing={16}
              >
                {currentField.toolTip && currentField.toolTip !== "" && (
                  <>
                    <Tooltip placement="top" title={currentField.toolTip}>
                      <InfoIcon color="disabled" />
                    </Tooltip>
                  </>
                )}
                <Grid item xs={12} sm={4} md={4}>
                  {currentField.controlType !== "hideDatepicker" &&
                    currentField.controlType !== "hideTextfield" &&
                    currentField.controlType !== "hideMultiSelect" &&
                    currentField.controlType !== "hideAutocomplete" &&
                    currentField.controlType !== "hideTextfieldNotEqual" && (
                      <>
                        &nbsp;&nbsp;
                        {currentField.isMandatory && (
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                        )}
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color:
                              currentField.isMandatory ||
                                currentField.controlType === "hideMultiSelect" ||
                                currentField.controlType === "hideTextfield" ||
                                currentField.controlType === "hideAutocomplete" ||
                                currentField.controlType === "hideDatepicker"
                                ? titleColor
                                : titleColor,
                          }}
                          label={currentField.label + " :"}
                        />
                      </>
                    )}
                  {(currentField.controlType === "hideMultiSelect" ||
                    currentField.controlType === "hideAutocomplete" ||
                    currentField.controlType === "hideDatepicker") &&
                    fieldData[currentField.tableDataKey] ===
                    currentField.open && (
                      <>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color:
                              currentField.isMandatory ||
                                currentField.controlType === "hideMultiSelect" ||
                                currentField.controlType === "hideTextfield" ||
                                currentField.controlType === "hideAutocomplete" ||
                                currentField.controlType === "hideDatepicker"
                                ? titleColor
                                : titleColor,
                          }}
                          label={currentField.label + " :"}
                        />
                      </>
                    )}
                  {currentField.controlType == "hideTextfieldNotEqual" &&
                    fieldData[currentField.tableDataKey] !==
                    currentField.open && (
                      <>
                        &nbsp;&nbsp;
                        {currentField.isMandatory && (
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                        )}
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: currentField.isMandatory
                              ? titleColor
                              : titleColor,
                          }}
                          label={currentField.label + " :"}
                        />
                      </>
                    )}
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  {currentField.controlType === "textfield" && (
                    <>
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                              ? true
                              : currentField.disableWithCondition &&
                                disableCondition
                                ? true
                                : false
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                          min: currentField.minNum && currentField.minNum,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey,
                          currentField.inputType,
                          currentField.spValid
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                      {currentField.note &&
                        currentField.space &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 12,
                                  color: labelColor,
                                  marginLeft: 2,
                                }}
                                label={note}
                              />
                              {currentField.space === "br" ? (
                                <br></br>
                              ) : currentField.space === "&nbsp;" ? (
                                " "
                              ) : (
                                ""
                              )}
                              {/* <br></br> */}
                            </>
                          );
                        })}
                    </>
                  )}
                  {currentField.controlType === "textarea" && (
                    <TextFieldCompo
                      placeHolder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      multiline
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey,
                        currentField.inputType,
                        currentField.spValid
                      )}
                      fullWidth
                      errorText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : ""
                      }
                    />
                  )}
                  {currentField.controlType === "hideTextfield" &&
                    fieldData[currentField.tableDataKey] ===
                    currentField.open && (
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                              ? true
                              : false
                        }
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey,
                          currentField.inputType,
                          currentField.spValid
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                    )}
                  {currentField.controlType === "hideTextfieldNotEqual" &&
                    fieldData[currentField.tableDataKey] !==
                    currentField.open && (
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                              ? true
                              : false
                        }
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey,
                          currentField.inputType,
                          currentField.spValid,
                          currentField.valid
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                    )}
                  {currentField.controlType === "datepicker" && (
                    <BasicDatePicker
                      color="primary"
                      name="dob"
                      size="small"
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : null
                      }
                      {...(currentField.isMAxDate && {
                        maxDate: dayjs(),
                      })}
                      {...(currentField.isMinDate && {
                        minDate: currentField.addOne
                          ? dayjs().add(1, "day")
                          : dayjs(),
                      })}
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      fullWidth
                      onChange={this.onDateChange(currentField.dataKey)}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                  {currentField.controlType === "hideDatepicker" &&
                    fieldData[currentField.tableDataKey] ===
                    currentField.open && (
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : null
                        }
                        {...(currentField.isMAxDate && {
                          maxDate: dayjs(),
                        })}
                        {...(currentField.isMinDate && {
                          minDate: dayjs(),
                        })}
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        fullWidth
                        onChange={this.onDateChange(currentField.dataKey)}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "hideAutocomplete" &&
                    fieldData[currentField.tableDataKey] ===
                    currentField.open && (
                      <AutoComplete
                        label={currentField.label}
                        placeHolder={currentField.placeHolder}
                        keyColName={"id"}
                        value={
                          currentField.isRootLevelKey &&
                            fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : !currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey].id
                              : ""
                        }
                        options={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey,
                          currentField.isRootLevelKey
                        )}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "autocomplete" && (
                    <>
                      <AutoComplete
                        label={currentField.label}
                        placeHolder={currentField.placeHolder}
                        keyColName={"id"}
                        value={
                          currentField.isRootLevelKey &&
                            fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : !currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey].id
                              : ""
                        }
                        options={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey,
                          currentField.isRootLevelKey
                        )}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                      {currentField.note &&
                        currentField.space &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 12, color: labelColor }}
                                label={note}
                              />
                              {currentField.space === "br" ? (
                                <br></br>
                              ) : currentField.space === "&nbsp;" ? (
                                " "
                              ) : (
                                ""
                              )}
                              {/* <br></br> */}
                            </>
                          );
                        })}
                    </>
                  )}
                  {currentField.controlType === "autocomplete/textfield" && (
                    <>
                      {fieldData["id"] ? (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKeyName] &&
                              fieldData[currentField.dataKeyName]
                              ? fieldData[currentField.dataKeyName]
                              : ""
                          }
                          disabled={true}
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                            min: currentField.minNum && currentField.minNum,
                          }}
                          type={
                            currentField.inputType
                              ? currentField.inputType
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey,
                            currentField.inputType,
                            currentField.spValid
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      ) : (
                        <>
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey].id
                                  : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={
                              formErrors[currentField.dataKey]?.errorText
                            }
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: labelColor }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                  {/* <br></br> */}
                                </>
                              );
                            })}
                        </>
                      )}
                    </>
                  )}
                  {currentField.controlType === "multiSelect" && (
                    <NewMultipleSelect
                      label={currentField.placeHolder}
                      er={formErrors[currentField.dataKey]?.errorText}
                      name1={currentField.dataKey}
                      value1={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : []
                      }
                      chipCompo={currentField.chipCompo}
                      names={
                        dynamicMasterData[currentField["masterName"]] &&
                          dynamicMasterData[currentField["masterName"]].length !=
                          0
                          ? [
                            { id: 0, name: "All" },
                            ...dynamicMasterData[currentField["masterName"]],
                          ]
                          : []
                      }
                      onChange={this.onMultiSelectChange(
                        currentField.dataKey,
                        currentField["masterName"]
                      )}
                    />
                  )}
                  {currentField.controlType === "hideMultiSelect" &&
                    fieldData[currentField.tableDataKey] ===
                    currentField.open && (
                      <NewMultipleSelect
                        label={currentField.placeHolder}
                        placeHolder={currentField.placeHolder}
                        name1={currentField.dataKey}
                        value1={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : []
                        }
                        chipCompo={currentField.chipCompo}
                        names={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        onChange={this.onMultiSelectChange(
                          currentField.dataKey
                        )}
                        er={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "timePicker" && (
                    <TimePickerCompo
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      onChange={this.timeChangeHandler(currentField.dataKey)}
                    />
                  )}
                  {currentField.controlType === "radiogroup" && (
                    <>
                      <RadioGroup
                        row
                        aria-label={currentField.label}
                        name={currentField.label}
                        id={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.onRadioChange(currentField.dataKey)}
                        size="small"
                      >
                        {currentField.radioGroupItems.map((radioItem) => (
                          <FormControlLabel
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : false
                            }
                            value={radioItem.value}
                            control={<Radio />}
                            label={radioItem.label}
                          />
                        ))}
                      </RadioGroup>
                      {currentField.note &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 12, color: labelColor }}
                                label={note}
                              />
                              <br></br>
                            </>
                          );
                        })}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
            {showSaveBtnMain && (
              <Grid
                container
                sm={GridSave != 0 ? GridSave : 1}
                md={GridSave != 0 ? GridSave : 1}
                lg={GridSave != 0 ? GridSave : 1}
                xs={12}

              //  rowSpacing={16}
              >
                <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={saveBtnText !== "" ? saveBtnText : "Save"}
                    // fullWidth={true}
                    onClick={this.onFormSave(true)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {(showSaveBtn || showCancel) && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                {showSaveBtn && (
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={saveBtnText !== "" ? saveBtnText : "Save"}
                    // fullWidth={true}
                    onClick={this.onFormSave(true)}
                  />
                )}
                {showSubmit && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Submit"}
                      // fullWidth={true}
                      onClick={this.onFormSubmit(true)}
                    />
                  </>
                )}
                {showCancel && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={this.onCancel}
                    // fullWidth={true}
                    />
                  </>
                )}
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicFormWithoutSave);
