import endpoint from "../../config/endpoints";

export const leaveSummaryJSON = {
    showSaveNextBtn: true,
    formPath: "/leave-summary-form",
    apiBaseURL: endpoint.leaveSummary,
    changeExcel: endpoint.leaveSummary,
    screenTitle: "Leave Summary",
    showAddButton: true,
    showPdfDownload: true,
    pdfFileName: "Leave-Summary",
    showExcelDownload: false,
    excelFileName: "Leave-Summary",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Short Name",
            columnKeyName: "shortName",
            isChecked: true,
        },
    ],
    fieldMeta: [
        {
            label: "Leave Summary Name",
            controlType: "textfield",
            placeHolder: "Leave Summary",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
            uniqueValidation: true,
        },
        {
            label: "Short Name",
            controlType: "textfield",
            placeHolder: "Short Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "shortName",
            isMandatory: true,
        },
    ],
};
