import endpoint from "../../config/endpoints";

export const WeeklyOffJson = {
    formPath: "/weekly-off-form",
    apiBaseURL: endpoint.weeklyOff,
    screenTitle: "Weekly Off",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Day",
            columnKeyName: "dayName",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

};
