import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControlLabel, FormHelperText, Grid, Paper, Radio, RadioGroup } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../../components/Comman/AttachFile";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { withRouter } from "../../../components/withRouter";
import { blackColor, redColor, titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import { fromdateMsg, imageMsg, labelMsg, launchDateMsg, noInternetMsg, offerMsg, productDescriptionMsg, productMrpMSG, productNameMSG, saveFailedMsg, saveWarningMsg, savemsg, serverMsg, todateMsg, updateData, updateMsg } from "../../../config/messageconstant";
import { productCatelogueJson } from "../../../DynamicFormsJson/MastersJSON/productCatelogue";
import { setClaimType } from "../../../Slice/claim.slice";
import { getOffer } from "../../../Slice/offer.slice";
import { getActiveProduct } from "../../../Slice/product.slice";
import { apiGet, apiPost, apiPostForImage } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { imageUrl } from "../../../config/Url";

class ProductCatelogueForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            rows: [],
            productId: "",
            productName: "",
            productMrp: "",
            productRetailRate: "",
            launchDate: "",
            trendingSequence: "0",
            offerApplicable: "0",
            offer: "",
            fromDate: "",
            toDate: "",
            isLabelApplicable: "0",
            labelName: "",
            fileName: "",
            productDescription: "",
            imageSelect: false,
        };
    }

    async componentDidMount() {
        const {
            showLoador,
            getActiveProduct,
            getOffer,
            showNotification,
        } = this.props;

        const { offerApplicable } = this.state;

        if (this.props.params.id) {
            this.getDataById();
        }

        showLoador({ loador: true });
        await getActiveProduct().then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });

        showLoador({ loador: true });
        await getOffer().then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });

    }


    getDataById = () => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: productCatelogueJson.apiBaseURL + "/" + this.props.params.id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;
                        this.setState({
                            productId: rowData.productId !== null ? rowData.productId : "",
                            productName: rowData.productName !== null ? rowData.productName : "",
                            productDescription: rowData.productDescription !== null ? rowData.productDescription : "",
                            productMrp: rowData.productMrp !== null ? rowData.productMrp : "",
                            productRetailRate: rowData.productRetailRate !== null ? rowData.productRetailRate : "",
                            trendingSequence: rowData.trendingSequence !== null ? rowData.trendingSequence : "",
                            offerApplicable: rowData.offerApplicable !== null ? rowData.offerApplicable : "",
                            offer: rowData.offerIds !== null ? rowData.offerIds : "",
                            labelName: rowData.labelName !== null ? rowData.labelName : "",
                            productImage: rowData.productImage !== null ? rowData.productImage : "",
                            fileName: {
                                ...this.state.fileName,
                                name: rowData.productImage !== null ? rowData.productImage : "",
                            },
                            isLabelApplicable: rowData.isLabelApplicable !== null ? rowData.isLabelApplicable : "",
                            fromDate: rowData.fromDate !== null ? dayjs(rowData.fromDate, "DD-MM-YYYY") : "",
                            toDate: rowData.toDate !== null ? dayjs(rowData.toDate, "DD-MM-YYYY") : "",
                            launchDate: rowData.launchDate !== null ? dayjs(rowData.launchDate, "DD-MM-YYYY") : "",
                        });
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { response: {}, success: false };
                }
                return { response: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    checkValidation = () => {
        const { productName,
            productDescription,
            productMrp,
            productRetailRate,
            launchDate,
            offerApplicable,
            offer,
            fromDate,
            toDate,
            isLabelApplicable,
            labelName,
            fileName,
        } = this.state;

        let formErrors = {};
        let formIsValid = true;

        console.log(fileName)

        if (productName === "") {
            formIsValid = false;
            formErrors["productNameError"] = productNameMSG;
        }

        if (productDescription === "") {
            formIsValid = false;
            formErrors["productDescriptionError"] = productDescriptionMsg;
        }

        if (productMrp === "") {
            formIsValid = false;
            formErrors["productMrpError"] = productMrpMSG;
        }

        if (productRetailRate === "") {
            formIsValid = false;
            formErrors["productRetailRateError"] = productMrpMSG;
        }

        if (launchDate === "") {
            formIsValid = false;
            formErrors["launchDateError"] = launchDateMsg;
        }

        if (offerApplicable == 1) {
            if (offer === "") {
                formIsValid = false;
                formErrors["offerError"] = offerMsg;
            }
        }


        if (fromDate === "") {
            formIsValid = false;
            formErrors["fromDateError"] = fromdateMsg;
        }

        if (toDate === "") {
            formIsValid = false;
            formErrors["toDateError"] = todateMsg;
        }

        if (isLabelApplicable == 1) {
            if (labelName === "") {
                formIsValid = false;
                formErrors["labelNameError"] = labelMsg;
            }
        }

        if (fileName === "" || fileName === null) {
            formIsValid = false;
            formErrors["imageError"] = imageMsg;
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    };



    changeHandlerForDropdown = (name, value) => {
        const { productList } = this.props;
        console.log(value);
        if (name === "productId") {
            this.setState({
                productId: value,
            });

            productList?.activeProduct.map((obj) => {
                if (obj.id == value) {
                    this.setState({
                        productName: obj.productName,
                        productDescription: obj.description,
                        productMrp: obj.mrp,
                        productRetailRate: obj.rate,
                    });
                }
            });
        }

        if (name === "offer") {
            this.setState({
                offer: value,
            });
        }
    };

    changeHandler = (e) => {
        const { name, value } = e.target
        if (name === "productName") {
            this.setState({
                productName: value,
            });
        }

        if (name === "productDescription") {
            this.setState({
                productDescription: value,
            });
        }

        if (name === "productMrp") {
            this.setState({
                productMrp: value,
            });
        }


        if (name === "productRetailRate") {
            this.setState({
                productRetailRate: value,
            });
        }

        if (name === "labelName") {
            this.setState({
                labelName: value,
            });
        }

    }


    changeDate = (name) => (date) => {
        if (name === "launchDate") {
            this.setState({ launchDate: date });
        } else if (name === "fromDate") {
            this.setState({ fromDate: date });
        } else if (name === "toDate") {
            this.setState({ toDate: date });
        }
    };


    onRadioChange = (e) => {
        const { value, name } = e.target;
        if (name == "trendingSequence") {
            this.setState({
                trendingSequence: value,
            });
        }

        if (name == "offerApplicable") {
            this.setState({
                offerApplicable: value,
            });
        }


        if (name == "isLabelApplicable") {
            this.setState({
                isLabelApplicable: value,
            });
        }
    };


    changeH = (fileName, name, index) => {
        this.setState({
            ...this.state,
            fileName: fileName,
        });

        this.setState({ imageSelect: true });
    };

    onClickViewPreview = (e, row) => {
        console.log(row);
        e.stopPropagation();
        const url = URL.createObjectURL(row);
        window.open(url);
    };



    onAddRow = () => {
        const { fileName, rows } = this.state;
        const { showLoador, showNotification, hideTable } = this.props;
        if (this.checkValidation()) {

            let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

            const formData = new FormData();
            formData.append("file", fileName);
            formData.append("imageName", fileNameData);

            swal({
                title: "Are you sure?",
                text: this.props.params.id ? updateData : saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    apiPostForImage({
                        url: endpoint.uploadImage,
                        postBody: formData,
                    }).then(({ data, success }) => {
                        if (success) {
                            showLoador({ loador: false });
                            const newRow = {
                                index: rows.length + 1,
                                fileName: data,
                                docName: fileName.name,
                            };

                            if (hideTable) {
                                this.setState({
                                    rows: [newRow],
                                });
                            } else {
                                this.setState({
                                    rows: [...rows, newRow],
                                });
                            }
                            this.onSubmitDataAfterSelectingImage();
                        } else {
                            showLoador({ loador: false });
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
            });
        }
    };



    onSubmitDataAfterSelectingImage = () => {
        const { productId,
            productName,
            productDescription,
            productMrp,
            productRetailRate,
            trendingSequence,
            offerApplicable,
            offer,
            fileName,
            fromDate,
            launchDate,
            toDate,
            isLabelApplicable,
            labelName,
            imageSelect,
        } = this.state;
        const { showLoador, showNotification } = this.props;
        let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

        let imageData = imageSelect ? fileNameData : fileName.name;


        let saveDataJson = {};
        if (this.props.params.id) {
            saveDataJson = {
                id: this.props.params.id,
                productId: productId ? productId : null,
                productName: productName,
                productDescription: productDescription,
                productMrp: productMrp,
                productRetailRate: productRetailRate,
                trendingSequence: trendingSequence,
                offerApplicable: offerApplicable,
                offerIds: offer ? offer : null,
                productImage: imageData,
                fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                toDate: dayjs(toDate).format("DD-MM-YYYY"),
                launchDate: dayjs(launchDate).format("DD-MM-YYYY"),
                isLabelApplicable: isLabelApplicable,
                labelName: labelName ? labelName : null,
                type: imageData.toLowerCase().endsWith('.pdf') ? 1 : 0,
            }
        } else {
            saveDataJson = {
                productId: productId ? productId : null,
                productName: productName,
                productDescription: productDescription,
                productMrp: productMrp,
                productRetailRate: productRetailRate,
                trendingSequence: trendingSequence,
                offerApplicable: offerApplicable,
                offerIds: offer ? offer : null,
                launchDate: dayjs(launchDate).format("DD-MM-YYYY"),
                productImage: fileNameData ? fileNameData : null,
                fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                toDate: dayjs(toDate).format("DD-MM-YYYY"),
                isLabelApplicable: isLabelApplicable,
                labelName: labelName ? labelName : null,
                type: fileNameData.toLowerCase().endsWith('.pdf') ? 1 : 0,
            }
        }


        showLoador({ loador: true });
        apiPost({
            url: endpoint.productCatelogue,
            postBody: saveDataJson,
        }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
                showNotification({ msg: this.props.params.id ? updateMsg : savemsg });
                window.location.replace("/product-catelogue");
            } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            return success;
        });
    }


    onSubmitData = () => {
        const { productId,
            productName,
            productDescription,
            productMrp,
            productRetailRate,
            trendingSequence,
            offerApplicable,
            offer,
            fileName,
            fromDate,
            launchDate,
            toDate,
            isLabelApplicable,
            labelName,
            imageSelect,
        } = this.state;
        const { showLoador, showNotification } = this.props;

        if (this.checkValidation()) {

            let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

            let imageData = imageSelect ? fileNameData : fileName.name;


            let saveDataJson = {};
            if (this.props.params.id) {
                saveDataJson = {
                    id: this.props.params.id,
                    productId: productId ? productId : null,
                    productName: productName,
                    productDescription: productDescription,
                    productMrp: productMrp,
                    productRetailRate: productRetailRate,
                    trendingSequence: trendingSequence,
                    offerApplicable: offerApplicable,
                    offerIds: offer ? offer : null,
                    productImage: imageData,
                    fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                    toDate: dayjs(toDate).format("DD-MM-YYYY"),
                    launchDate: dayjs(launchDate).format("DD-MM-YYYY"),
                    isLabelApplicable: isLabelApplicable,
                    labelName: labelName ? labelName : null,
                    type: imageData.toLowerCase().endsWith('.pdf') ? 1 : 0,
                }
            } else {
                saveDataJson = {
                    productId: productId ? productId : null,
                    productName: productName,
                    productDescription: productDescription,
                    productMrp: productMrp,
                    productRetailRate: productRetailRate,
                    trendingSequence: trendingSequence,
                    offerApplicable: offerApplicable,
                    offerIds: offer ? offer : null,
                    launchDate: dayjs(launchDate).format("DD-MM-YYYY"),
                    productImage: fileNameData ? fileNameData : null,
                    fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                    toDate: dayjs(toDate).format("DD-MM-YYYY"),
                    isLabelApplicable: isLabelApplicable,
                    labelName: labelName ? labelName : null,
                    type: fileNameData.toLowerCase().endsWith('.pdf') ? 1 : 0,
                }
            }



            swal({
                title: "Are you sure?",
                text: this.props.params.id ? updateData : saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    apiPost({
                        url: endpoint.productCatelogue,
                        postBody: saveDataJson,
                    }).then(({ data, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            showNotification({ msg: this.props.params.id ? updateMsg : savemsg });
                            window.location.replace("/product-catelogue");
                        } else {
                            showNotification({ msg: saveFailedMsg, severity: "error" });
                        }
                        return success;
                    });
                }
            });
        }
    }


    render() {
        const {
            formErrors,
            productId,
            productName,
            productDescription,
            productMrp,
            productRetailRate,
            launchDate,
            trendingSequence,
            offerApplicable,
            fromDate,
            toDate,
            isLabelApplicable,
            labelName,
            offer,
            fileName,
            imageSelect,
        } = this.state;
        const { productList, offerList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <LandingScreenHeader
                        showTitle={productCatelogueJson.showTitle}
                        screenTitle={"Product Catelogue"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={true}
                        onCancel={() =>
                            window.location.replace("/product-catelogue")
                        }
                    />

                    <br />


                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: blackColor }}
                                    label="Product:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <AutoComplete
                                    keyColName={"id"}
                                    placeHolder="Select Product"
                                    value={productId}
                                    name={"productId"}
                                    options={productList?.activeProduct}
                                    errorText={
                                        formErrors["regionError"]
                                            ? formErrors["regionError"]
                                            : ""
                                    }
                                    isError={
                                        formErrors["regionError"]
                                            ? formErrors["regionError"]
                                            : ""
                                    }
                                    onChange={this.changeHandlerForDropdown}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Product Name:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Product Name"}
                                    color="primary"
                                    name="productName"
                                    value={productName}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    errorText={
                                        formErrors["productNameError"] ? formErrors["productNameError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >

                        <Grid item xs={12} md={2.5} sm={2.5}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label="* "
                            />
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: titleColor }}
                                label="Product Description:"
                            />
                        </Grid>

                        <Grid item xs={12} md={9.5} sm={9.5}>
                            <TextFieldCompo
                                placeHolder={"Product Description"}
                                color="primary"
                                name="productDescription"
                                value={productDescription}
                                multiline={true}
                                fullWidth
                                onChange={this.changeHandler}
                                errorText={
                                    formErrors["productDescriptionError"] ? formErrors["productDescriptionError"] : ""
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Product MRP:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Product MRP"}
                                    color="primary"
                                    name="productMrp"
                                    value={productMrp}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    maxLength={10}
                                    type="number"
                                    errorText={
                                        formErrors["productMrpError"] ? formErrors["productMrpError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Product Retail Rate:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Product Retail Rate"}
                                    color="primary"
                                    name="productRetailRate"
                                    value={productRetailRate}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    maxLength={10}
                                    type="number"
                                    errorText={
                                        formErrors["productRetailRateError"] ? formErrors["productRetailRateError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>



                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Launch Date:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <BasicDatePicker
                                    color="primary"
                                    name="launchDate"
                                    size="small"
                                    value={launchDate}
                                    disabled={false}
                                    fullWidth
                                    onChange={this.changeDate("launchDate")}
                                    errorText={
                                        formErrors["launchDateError"]
                                            ? formErrors["launchDateError"]
                                            : ""
                                    }
                                    isError={
                                        formErrors["launchDateError"]
                                            ? formErrors["launchDateError"]
                                            : ""
                                    }
                                />
                            </Grid>
                        </Grid>


                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Trending Sequence:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <RadioGroup
                                    row
                                    aria-label={"trendingSequence"}
                                    name={"trendingSequence"}
                                    id={"trendingSequence"}
                                    value={trendingSequence}
                                    onChange={this.onRadioChange}
                                    size="small"
                                >
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio />}
                                        label={"Yes"}
                                    />
                                    <FormControlLabel
                                        value={"0"}
                                        control={<Radio />}
                                        label={"No"}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>



                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Offer Applicable:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <RadioGroup
                                    row
                                    aria-label={"offerApplicable"}
                                    name={"offerApplicable"}
                                    id={"offerApplicable"}
                                    value={offerApplicable}
                                    onChange={this.onRadioChange}
                                    size="small"
                                >
                                    <FormControlLabel
                                        value={"0"}
                                        control={<Radio />}
                                        label={"No"}
                                    />
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio />}
                                        label={"Yes"}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        {offerApplicable == 1 && (
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-between"
                            >
                                <Grid item xs={12} md={5} sm={5}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="Offer:"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <AutoComplete
                                        keyColName={"id"}
                                        placeHolder="Select Offer"
                                        value={offer}
                                        name={"offer"}
                                        options={offerList?.offer}
                                        errorText={
                                            formErrors["offerError"]
                                                ? formErrors["offerError"]
                                                : ""
                                        }
                                        isError={
                                            formErrors["offerError"]
                                                ? formErrors["offerError"]
                                                : ""
                                        }
                                        onChange={this.changeHandlerForDropdown}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>



                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="From Date:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <BasicDatePicker
                                    color="primary"
                                    name="fromDate"
                                    size="small"
                                    value={fromDate}
                                    disabled={false}
                                    fullWidth
                                    onChange={this.changeDate("fromDate")}
                                    errorText={
                                        formErrors["fromDateError"]
                                            ? formErrors["fromDateError"]
                                            : ""
                                    }
                                    isError={
                                        formErrors["fromDateError"]
                                            ? formErrors["fromDateError"]
                                            : ""
                                    }
                                />
                            </Grid>
                        </Grid>


                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="To Date:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <BasicDatePicker
                                    color="primary"
                                    name="toDate"
                                    size="small"
                                    value={toDate}
                                    disabled={false}
                                    fullWidth
                                    onChange={this.changeDate("toDate")}
                                    errorText={
                                        formErrors["toDateError"]
                                            ? formErrors["toDateError"]
                                            : ""
                                    }
                                    isError={
                                        formErrors["toDateError"]
                                            ? formErrors["toDateError"]
                                            : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Is Lable Applicable:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <RadioGroup
                                    row
                                    aria-label={"isLabelApplicable"}
                                    name={"isLabelApplicable"}
                                    id={"isLabelApplicable"}
                                    value={isLabelApplicable}
                                    onChange={this.onRadioChange}
                                    size="small"
                                >
                                    <FormControlLabel
                                        value={"0"}
                                        control={<Radio />}
                                        label={"No"}
                                    />
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio />}
                                        label={"Yes"}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        {isLabelApplicable == 1 && (
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-between"
                            >
                                <Grid item xs={12} md={5} sm={5}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="Label Name:"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <TextFieldCompo
                                        placeHolder={"Label Name"}
                                        color="primary"
                                        name="labelName"
                                        value={labelName}
                                        fullWidth
                                        onChange={this.changeHandler}
                                        errorText={
                                            formErrors["labelNameError"] ? formErrors["labelNameError"] : ""
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                    </Grid>



                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Select Image:"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>

                                <AttachFile
                                    name={"file"}
                                    fileName={this.changeH}
                                />
                                &nbsp; {fileName !== "" ? fileName.name : ""}
                                &nbsp;{" "}
                                {fileName !== "" ? (
                                    <MyComponentWithIconProps
                                        statusImage={VisibilityIcon}
                                        color="primary"
                                        fontSize="medium"
                                        title="View"
                                        onClick={
                                            this.props.params.id && imageSelect ? (e) => this.onClickViewPreview(e, fileName) :
                                                this.props.params.id ? () => {
                                                    window.open(imageUrl + fileName.name, '_blank')
                                                } : (e) => this.onClickViewPreview(e, fileName)
                                        }

                                    />
                                ) : (
                                    <FormHelperText error>
                                        {formErrors["imageError"] ? formErrors["imageError"] : ""}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <br />

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="Submit"
                            variant="contained"
                            name="Submit"
                            onClick={imageSelect ? this.onAddRow : this.onSubmitData}
                        />
                    </Grid>
                </Paper >
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    productList: state.product,
    offerList: state.offer,
});
const mapDispatchToProps = {
    getActiveProduct,
    getOffer,
    setClaimType,
    showLoador,
    showNotification,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductCatelogueForm);

