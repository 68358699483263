export const changeActive = "Do you want to change status?";
export const beatsAssign = "Some beats are assigned";
export const sendOrder = "Do you want to send order?";
export const serverMsg = "Something went wrong";
export const creditLimitMsg = "Credit Limit Not Available";
export const generateBillMsg = "Do you want to generate invoice?";
export const cancelBillMsg = "Do you want to cancel invoice?";
export const syncMsg = "Sync Successfully";
export const uomNameMsg = "UOM Name Required";
export const beatAssignedMsg = "Beat assigned to sales person";
export const imageMsg = "* Image not uploaded";
export const pdfMsg = "* PDF not uploaded";
export const savemsg = "Save Successfully";
export const nameAlreadyExistMsg = "Name Already Exist";
export const updateMsg = "Data updated Successfully";
export const tokenMsg = "Token cleared Successfully";
export const saveFailedMsg = "Save Failed";
export const deletemsg = "Delete Successfully";
export const exportmsg = "Export Successfully";
export const importmsg = "Import Successfully";
export const rejectMsg = "Order Rejected Successfully";
export const acceptMsg = "Order Accepted Successfully";
export const statusmsg = "Status updated";
export const statusFailedMsg = "Failed to change status";
export const deleteFailedMsg = "Delete Failed";
export const dobEmptyMsg = "Enter date of birth";
export const userNameEmptyMsg = "Enter username";
export const alreadyCheckedInMsg = "Already Cheked In";
export const enterPasswordMsg = "Enter password";
export const remarkMsg = "Remark Required";
export const confirmPasswordIncorrectMsg = "*Confirm Password does not match";
export const confirmPasswordMsg = "Confirm Password required";
export const otpEmptyMsg = "Enter OTP";
export const otpFormatMsg = "OTP should be 6 digits only";
export const accessRoleMsg = "*Access role name required";
export const shortNameMsg = "Short name required";
export const stackHolderTypeMsg = "*Stack Holder Type required";
export const regionMsg = "*Region required";
export const stateMsg = "*State required";
export const zoneMsg = "*Zone required";
export const districtMsg = "*District required";
export const talukaMsg = "*Taluka required";
export const areaMsg = "*Area required";
export const whoCanTakeMsg = "*Who can take required";
export const questionMsg = "*Question required";
export const sortOrderNoMsg = "*Sort order no required";
export const optionType = "*Select option type";
export const pointsMsg = "*Select points";
export const option = "*Option required";
export const dateMsg = "*Date required";
export const noInternetMsg = "Please check internet connection";
export const saveWarningMsg = "Do you want to save this record?";
export const closeWarningMsg = "Do you want to close this?";
export const saveWarningPlacedOrderMsg =
  "Purchase order amount is greater than credit limit still you want to process?";
export const sendPlacedOrderMsg =
  "Your purchase order amount is greater than credit limit, You can't send order";
export const deleteWarningMsg = "Do you want to delete this record?";
export const saveAndCloseWarningMsg =
  "Do you want to save and close this record?";

export const debitAmountMsg = "*Amount required";
export const checkedListEmptyMsg = "*Select atleast one record";
export const debitAmountNotValidMsg =
  "*Amount should be less or equal to pending amount";
export const debitAmountZeroMsg = "*Amount should be greater than 0";
export const transactionDateMsg = "*Transaction date required";
export const feesStructureHeaderMsg = "*Fees structure required";
export const feesMinimumPayMsg =
  "*Amount should be greater than minimum paid amount";
export const feesMaximumPayMsg = "*Amount should be less than pending amount";
export const feesEqualPayMsg = "*Amount should be equal to pending amount";
export const numberMsg = "*Transaction id required";
export const bankNameMsg = "*Bank name required";
export const bankBranchMsg = "*Bank branch required";
export const ifscCodeMsg = "*IFSC code required";
export const micrCodeMsg = "*MICR code required";
export const sendCredentialMsg = "Do yo like to send credential to user";
export const generateCredentialMsg = "Credential generated successfully";
export const receiveBillSuccess = "Bill received successfully";
export const FailTogenerateCredentialMsg = "Failed to generate credential";
export const modeOfPayMsg = "*Mode of Pay required";
export const deleteConfirmMsg =
  "Once deleted, Information can not be Retrieved. Would you like to continue?";
export const dateExistMsg = "Rate configration already added for this date";
export const beatNotAssignMsg = "Beats not assigned for this sales executive";
export const beatSelectMsg = "Select atleast one beat";
export const cancelledMsg = "Bill Cancelled Successfully";
export const atleastOne = "Please add atleast one product";
export const stockMsg = "Please add stock for proceed";
export const updateData = "Do you want to update this record?";
export const clearToken = "Do you want to clear this token?";
export const enterValueMsg = "Please add some data";
export const yearEndMsg = "Year end successfully";
export const outletInactiveMsg = "Do you want to in-active the outlets?";
export const nameMsg = "Please Enter Name";
export const vehicleNoMsg = "Please Enter Vehicle Number";
export const poNoMsg = "Please Enter Po Number";
export const transporterMsg = "Please Enter Transporter";
export const transporterIdMsg = "Please Enter Transporter Id";
export const lrRrGrNoMsg = "Please Enter LR/RR/GR No";
export const eWayBillNoMsg = "Please Enter E-Way Bill No";
export const poDateMsg = "Please Enter Po Date";
export const lrRrGrDateMsg = "Please Enter LR/RR/GR Date";
export const eWayBillDateMsg = "Please Enter E-Way Bill Date";
export const linkMsg = "Please Enter Link";
export const jsonMsg = "Please Enter Json";
export const contactNoMsg = "Enter Contact Number";
export const emailIdMsg = "Enter Email ID";
export const addressMsg = "Enter Address";
export const gstNumberMsg = "Enter GST Number";
export const panNumberMsg = "Enter PAN Number";
export const cinNumberMsg = "Enter CIN Number";
export const invoicePrefixMsg = "Enter Invoice Prefix";
export const fdiFssiNumberMsg = "Enter FDI FSSI Number";
export const descriptionMsg = "Enter Description";
export const fromdateMsg = "From Date Is Required";
export const todateMsg = "To Date Is Required";
export const levelwiseTypeMsg = "Level-Wise Type Is Required";
export const applicableAmountMsg = "Applicable Amount Is Required";
export const applicableAmountGreaterThanZeroMsg =
  "Applicable Amount should be greater than 0";
export const discountAmountMsg = "Discount Amount Is Required";
export const discountAmountErrorMsg =
  "Discount Amount Must Be Less Than Applicable Amount";
export const discountAmountGreaterThanZeroMsg =
  "Discount Amount should be greater than 0";
export const discountPercentageMsg = "Discount Percentage Is Required";
export const discountPercentageGreaterThanZeroMsg =
  "Discount Percentage should be greater than 0";
export const percentageMsg = "Percentage Is Required";
export const productMsg = "Please Select Product";
export const productRemainingMsg = "Few product are remaining to add";
export const batchMsg = "Please Select Batch";
export const batchEmptyMsg = "Please Enter Batch";
export const productByCategoryMsg = "Please Select Product";
export const qtyMsg = "Quantity Is Required";
export const qtyForItemMsg = "Quantity Is Required";
export const qtyGreaterThanZeroMsg = "Quantity should be greater than 0";
export const qtyGreaterThanBillQtyMsg =
  "Quantity should be less than or equals to bill quantity";
export const discountPercentageErrorMsg =
  "Discount Percentage Must Be Less Than Or Equals 100";

export const ReportMsg = "*Report required";
export const apiNameMsg = "*API Name required";
export const billAlreadyGeneratedMsg = "*Bill is already generated";
export const paramsValueMsg = "*Parameter Value required";
export const paramsKeyMsg = "*Parameter Key required";
export const reportTypeMsg = "*Report Type Required";

export const controlTypeMsg = "*Control Type required";
export const labelMsg = "*Label required";
export const placeHolderMsg = "*Placeholder required";
export const fieldNameMsg = "*Field Name required";
export const claimTypeName = "*Cliam Type Name required";
export const fieldNameUniqueMsg = "*Field Name should be unique";

export const pojoFieldNameMsg = "*Pojo Field Name required";

export const keyMsg = "*Key required";
export const urlMsg = "*URL required";
export const localListMsg = "Please add atlest one object";
export const columnNameMsg = "*Column Name required";
export const widthMsg = "*Width required";
export const sortNoMsg = "*Sort number required";
export const valueMsg = "*Value required";
export const graphTypeMsg = "*Graph Type required";
export const sortOrderMsg = "Sort Order required";
export const parameterKeyMsg = "*Parameter key required";
export const parameterValueMsg = "*Parameter value required";
export const addAtleastProductMsg = "Please add atleast one product";
export const authenticationMsg =
  "You are no longer active, please contact to admin. ";
export const publishDateMsg =
  "Rate Structure is already presented for this date for selected stock holder";
export const rateStructurePesentedMsg =
  "Selected rate structure is already presented for this date";
export const closedOrderWarningInHindi =
  "क्या आप उपरोक्त ऑर्डरों को बंद करना चाहेंगे जो स्टॉक में नहीं हैं और शेष के साथ आगे बढ़ना चाहेंगे ?";
export const closedOrderWarningInGujrati =
  "ઉપરોક્ત products માંથી તમે LOST ORDER કરી ને, બિલ કાઢવા માંગો છો?";
export const closedOrderWarning =
  "Would you like to close above orders that are out of stock and proceed with remaining ?";
export const userOldPassEmptyMsg = "Enter Old Password";
export const previousAmountError =
  "Entered amount is must be greater than previous amount";
export const alreadyLoggedIn = "You are already loggedIn on another device";
export const notCheckInYet = "Sales Person Not Check In Yet";
export const cashMsg = "Please Enter Cash Amount";
export const onlineMsg = "Please Enter Online Amount";
export const cashAmtMsg = "Enter amount must be equal to outstanding amount";
export const onlineAmtMsg = "Enter amount must be equal to outstanding amount";
export const otherTaskMsg = "Other Task Required";
export const addOneRecordMsg = "Add Atleast One Record";
export const dateValidationMsg = "From Date Should Be Less Than To Date";
export const dataNotFoundMsg = "Data Not Found";
export const costRequiredMsg = "Cost Required";
export const costGreaterThanZeroMsg = "Cost Should be greater than zero";
export const venderRequiredMsg = "Vender Required";
export const approvedAssetsQtyMsg = "Approve Assets Quantity Required";
export const productNameMSG = "Product Name Required";
export const pdfNameMSG = "PDF Name Required";
export const productMrpMSG = "Product MRP Required";
export const productRetailMsg = "Product Retail Rate Required";
export const launchDateMsg = "Launch Date Required";
export const offerMsg = "Offer Required";
export const cdMsg = "CD Required";
export const closedMsg = "Closed Successfully";
export const productDescriptionMsg = "Product Descripion Required";
export const watcherMsg = "Add Atleast One Watcher";
export const headerMsg = "Header Required";
export const addAtleastOneMomMsg = "Add Atleast One Mom Details";
export const checkBoxSelectMsg = "Selct atleast one checkbox option";
export const sessionMsg = "Session Expired !";
export const languageNotAvailableMsg = "Language Json not available";
