import {
  Box,
  DialogActions,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  getMapData,
  getTimelineForSalesExecutive,
} from "../../Slice/location.slice";
import {
  getManagerLogsBySalesTeamIds,
  setManagerLogs,
} from "../../Slice/managerLogs.slice";
import { timeLineJson } from "../../DynamicFormsJson/MastersJSON/timeLine";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  blackColor,
  checkboxColor,
  cyanBlue,
  DarkBlue,
  greyColor,
  labelColor,
  LightGreenColor,
  lightVioletColor,
  redColor,
  smoothGreenColor,
  titleColor,
  white,
  yellowColor,
  yellowishBrownColor,
} from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  dataNotFoundMsg,
  fromdateMsg,
  noInternetMsg,
  serverMsg,
  todateMsg,
} from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import TimelineDetailsPopup from "./TimelineDetailsPopup";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { ButtonCompo } from "../../components/Comman/Button";
import dayjs from "dayjs";
import GMap from "./GMap";
import moment from "moment";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import CottageIcon from "@mui/icons-material/Cottage";
import PersonIcon from "@mui/icons-material/Person";
import SalesPersonCheckInImage from "../DashbourdChanges/SalesPersonCheckInImage";
import ZeroOrderImage from "../DashbourdChanges/ZeroOrderImage";
const windowWidth = window.innerWidth;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const Timeline = ({
  showNotification,
  outletDetails,
  location,
  getMapData,
  showLoador,
  getTimelineForSalesExecutive,
  getManagerLogsBySalesTeamIds,
  setManagerLogs,
  managerLogsList,
  saveData,
  dateFlag,
  handleTabData,
  onSearchData,
}) => {
  const params = useParams();
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [date, setDate] = useState("");
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [salesPersonCheckInImageFlag, setSalesPersonCheckInImageFlagFlag] =
    useState(false);
  const [zeroOrderImgOpenFlag, setZeroOrderImgOpenFlag] = useState(false);
  const [itemObj, setItemObj] = useState({});
  const [companyFlag, setCompanyFlag] = useState("");
  const [showTimelineManagerLogs, setShowTimelineManagerLogs] = useState("");
  const [checkInImageFlag, setCheckInImageFlag] = useState("");
  const [zeroOrderImageFlag, setZeroOrderImageFlag] = useState("");
  const [DataToSet, setDataToSet] = useState([]);
  const [dataToSetPastOrder, setDataToSetPastOrder] = useState([]);
  const [outletOrderDetails, setOutletOrderDetails] = useState([]);
  const [appliedOfferDetails, setAppliedOfferDetails] = useState([]);
  const [outletOrderDetailsPastOrder, setOutletOrderDetailsPastOrder] =
    useState([]);
  const [appliedOfferDetailsPastOrder, setAppliedOfferDetailsPastOrder] =
    useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const [showPastOrderInTimeline, setShowPastOrderInTimeline] = useState("");
  const [onSearchLocation, setOnSearchLocation] = useState(false);
  const [salesTeamData, setSalesTeamData] = useState({});
  const [selectedBeatData, setSelectedBeatData] = useState("");

  const navigate = useNavigate();



  useEffect(() => {

    fetchSetting();

  }, []);


  const fetchSetting = async () => {

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");
    setCompanyFlag(showCompanyFlag);

    const zeroOrderImage = localStorage.getItem("zeroOrderImage");
    setZeroOrderImageFlag(zeroOrderImage);

    const checkInImage = localStorage.getItem("checkInImage");
    setCheckInImageFlag(checkInImage);

    const pastOrderInTimeline = localStorage.getItem("showPastOrderInTimeline");
    setShowPastOrderInTimeline(pastOrderInTimeline);

    setManagerLogs({ row: [] });


    const localStorageKey = localStorage.getItem("showTimelineManagerLogsWise");
    let settingValueToShowTimeLine;

    if (localStorageKey !== null) {

      settingValueToShowTimeLine = localStorageKey;
      setShowTimelineManagerLogs(settingValueToShowTimeLine);

      if (settingValueToShowTimeLine == 0) {
        if (rows.length === 0) {
          getTimelineList();
        }
      } else {
        getManagerLogsList();
      }

    } else {
      showLoador({ loador: true });
      apiGet({
        url: endpoint.setting + "/by-key?key=show_timeline_manager_logs_wise",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (data && data.data && Object.keys(data.data).length != 0) {
            if (data.data.settingValue) {
              settingValueToShowTimeLine = data.data.settingValue;

              setShowTimelineManagerLogs(settingValueToShowTimeLine);

              if (settingValueToShowTimeLine == 0) {
                if (rows.length === 0) {
                  getTimelineList();
                }
              } else {
                getManagerLogsList();
              }
            }
          }
        }
      });

    }


  };


  const onCancel = () => {
    navigate(-1);
  };
  const getTimelineList = () => {
    const selectedDay = location.salesTeamTimeline[value]?.day;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getTimelineForSalesExecutive({
        fromDate: "",
        toDate: "",
        salesTeamIds: params.id,
        selectedDay,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          const newRows = response.map((rowData, index) => ({
            ...rowData,
            index,
          }));
          setRows(newRows);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const getManagerLogsList = () => {
    if (navigator.onLine) {

      let salesTeamData = {};
      let beatData = "";

      let managerJson = {
        fromDate: dayjs().format("DD-MM-YYYY"),
        toDate: dayjs().format("DD-MM-YYYY"),
        salesTeamIds: params.id.split(","),
      };
      showLoador({ loador: true });
      getManagerLogsBySalesTeamIds({ managerLogsJson: managerJson }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const managerLogCurrentRow = response.map((rowData, index) => ({
              ...rowData,
              index,
            }));

            setRows(managerLogCurrentRow);

            if (response.length !== 0) {
              response.map((dataObj) => {
                salesTeamData = {
                  salesTeamId: dataObj.salesTeamId,
                  salesTeamName: dataObj.salesTeamName,
                  salesTeamType: dataObj.salesTeamType,
                }
              });

              setSalesTeamData(salesTeamData);
            }




            if (params.userTypeId == "4") {
              if (response.length !== 0) {
                response.map((dataObj) => {
                  if (dataObj.beats.length !== 0) {
                    (beatData = dataObj.beats.map((beatObj) => beatObj.beatName).join(', '))
                  }
                })

                setSelectedBeatData(beatData);
              }

            }

          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const changeDate = (name) => (date) => {
    if (name === "fromdate") {
      setFromdate(date);
    } else if (name === "todate") {
      setTodate(date);
    }
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (fromdate === "") {
      formIsValid = false;
      formErrors["fromdateError"] = fromdateMsg;
    }
    if (todate === "") {
      formIsValid = false;
      formErrors["todateError"] = todateMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const searchData = () => {
    const selectedDay = location.salesTeamTimeline[value]?.day;

    if (navigator.onLine) {
      if (checkValidation()) {
        setRows([]);

        if (showTimelineManagerLogs && showTimelineManagerLogs == 0) {
          showLoador({ loador: true });
          getTimelineForSalesExecutive({
            fromDate: dayjs(fromdate).format("DD-MM-YYYY"),
            toDate: dayjs(todate).format("DD-MM-YYYY"),
            salesTeamIds: params.id,
            selectedDay,
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              const newRowsOnSearch = response.map((rowData, index) => ({
                ...rowData,
                index,
              }));
              setRows(newRowsOnSearch);
            }
          });
        } else {

          let salesTeamData = {};
          let beatData = "";

          showLoador({ loador: true });
          let managerJson = {
            fromDate: dayjs(fromdate).format("DD-MM-YYYY"),
            toDate: dayjs(todate).format("DD-MM-YYYY"),
            salesTeamIds: params.id.split(","),
          };

          getManagerLogsBySalesTeamIds({ managerLogsJson: managerJson }).then(
            ({ response, success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                const managerLogOnSearchRow = response.map(
                  (rowData, index) => ({
                    ...rowData,
                    index,
                  })
                );

                onSearchDataMethod(managerLogOnSearchRow);
                setOnSearchLocation(true);
                setRows(managerLogOnSearchRow);


                if (response.length !== 0) {
                  response.map((dataObj) => {
                    salesTeamData = {
                      salesTeamId: dataObj.salesTeamId,
                      salesTeamName: dataObj.salesTeamName,
                      salesTeamType: dataObj.salesTeamType,
                    }
                  });

                  setSalesTeamData(salesTeamData);
                }

                if (params.userTypeId == "4") {
                  if (response.length !== 0) {
                    if (response[0].beats.length !== 0) {
                      (beatData = response[0].beats.map((beatObj) => beatObj.beatName).join(', '))
                      setSelectedBeatData(beatData);

                    } else {
                      setSelectedBeatData("-");
                    }

                  }

                }
              }
            }
          );
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onSearchDataMethod = (dataList) => {
    onSearchData(dataList);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const selectedManagerLog = managerLogsList.managerLogs[newValue];

    if (selectedManagerLog) {
      if (selectedManagerLog.beats.length !== 0) {
        const beatName = selectedManagerLog.beats.map((beat) => beat.beatName).join(', ');
        setSelectedBeatData(beatName);
      } else {
        setSelectedBeatData("-");
      }
    } else {
      setSelectedBeatData("");
    }
    saveData(newValue + 1, rows);
  };

  const timelineDetails = async (row) => {
    if (navigator.onLine) {
      setDataToSet([]);
      setOutletOrderDetails([]);
      setAppliedOfferDetails([]);

      setDataToSetPastOrder([]);
      setOutletOrderDetailsPastOrder([]);

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      setCompanyFlag(showCompanyFlag);

      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.outletOrderHeader +
          "/get-by-id-new/" +
          row.outletOrderHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const commonData = [
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Order No",
              value: data.data.orderNo !== null ? data.data.orderNo : "-",
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Order Date",
              value: data.data.orderDate !== null ? data.data.orderDate : "-",
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Beat Name",
              value:
                data.data.outLet !== null &&
                  data.data.outLet.beat !== null &&
                  data.data.outLet.beat.beatName !== null
                  ? data.data.outLet.beat.beatName
                  : "-",
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Outlet Name",
              value:
                data.data.outLet !== null && data.data.outLet.firmName !== null
                  ? data.data.outLet.firmName
                  : "-",
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Owner Name",
              value:
                data.data.outLet !== null && data.data.outLet.ownerName !== null
                  ? data.data.outLet.ownerName
                  : "-",
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Mobile No",
              value:
                data.data.outLet !== null && data.data.outLet.mobileNo !== null
                  ? data.data.outLet.mobileNo
                  : "-",
            },
          ];

          const companyFlagData =
            showTimelineManagerLogs == 0
              ? commonData
              : [
                ...commonData,
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "CD",
                  value:
                    data.data.distcountAmt !== null
                      ? data.data.distcountAmt
                      : "-",
                },
              ];

          setDataToSet(companyFlagData);

          const offerDetails = data.data.appliedOffers.map((rowData, index) => {
            return {
              index: index + 1,
              id: rowData.id,
              name: rowData.name,
            };
          });

          setAppliedOfferDetails(offerDetails);

          (outletDetails = data.data.outletOrderDetails.map(
            (rowData, index) => {
              let outletData = {
                index: index + 1,
                productName: rowData.product.name,
                focusProduct: rowData.focusFlag == 1 ? "Yes" : "No",
                uom: rowData.product.outletUom.name,
                rate: rowData.rate.toFixed(2),
                qty: rowData.qty,
                discountPer: rowData.discountPer,
                totalAmt: rowData.totalAmt.toFixed(2),
              };
              return outletData;
            }
          )),
            setOutletOrderDetails(outletDetails);
          setPopUpFlag(true);

          if (showPastOrderInTimeline == 1) {
            showLoador({ loador: true });
            apiGet({
              url:
                endpoint.outletOrderHeader +
                "/last-visit-and-order-new?date=" +
                dateFlag +
                "&outletId=" +
                row.outletId,
            }).then(({ data, success }) => {
              if (success) {
                if (data && data.data && Object.keys(data.data).length !== 0) {
                  if (
                    data.data.isVisitIsOrder !== null &&
                    data.data.outletOrderHerader !== null
                  ) {
                    setDataToSetPastOrder([
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order No",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.orderNo !== null
                            ? data.data.outletOrderHerader.orderNo
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order Date",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.orderDate !== null
                            ? data.data.outletOrderHerader.orderDate
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Outlet Name",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.outLet !== null &&
                            data.data.outletOrderHerader.outLet.firmName !== null
                            ? data.data.outletOrderHerader.outLet.firmName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "CD",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.distcountAmt !== null
                            ? data.data.outletOrderHerader.distcountAmt
                            : "-",
                      },
                    ]);

                    if (
                      data.data.outletOrderHerader &&
                      data.data.outletOrderHerader !== null
                    ) {
                      if (
                        data.data.outletOrderHerader.outletOrderDetails &&
                        data.data.outletOrderHerader.outletOrderDetails
                          .length !== 0
                      ) {
                        (outletDetails =
                          data.data.outletOrderHerader.outletOrderDetails.map(
                            (rowData, index) => {
                              let outletData = {
                                index: index + 1,
                                productName: rowData.product.name,
                                focusProduct:
                                  rowData.focusFlag == 1 ? "Yes" : "No",
                                uom: rowData.product.outletUom.name,
                                rate: rowData.rate.toFixed(2),
                                qty: rowData.qty,
                                discountPer: rowData.discountPer,
                                totalAmt: rowData.totalAmt.toFixed(2),
                              };
                              return outletData;
                            }
                          )),
                          setOutletOrderDetailsPastOrder(outletDetails);

                        setPopUpFlag(true);
                      }

                      const offerDetailsPastOrder =
                        data.data.outletOrderHerader.appliedOffers.map(
                          (rowData, index) => {
                            let offerData = {
                              index: index + 1,
                              id: rowData.id,
                              name: rowData.name,
                            };
                            return offerData;
                          }
                        );
                      setAppliedOfferDetailsPastOrder(offerDetailsPastOrder);
                    }
                    showLoador({ loador: false });
                  } else {
                    showLoador({ loador: false });
                    showNotification({
                      msg: "Past Order " + dataNotFoundMsg,
                      severity: "error",
                    });
                  }
                } else {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              } else {
                showLoador({ loador: false });
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
          showLoador({ loador: false });
        } else {
          if (showPastOrderInTimeline == 1) {
            showLoador({ loador: true });
            apiGet({
              url:
                endpoint.outletOrderHeader +
                "/last-visit-and-order-new?date=" +
                dateFlag +
                "&outletId=" +
                row.outletId,
            }).then(({ data, success }) => {
              if (success) {
                if (data && data.data && Object.keys(data.data).length !== 0) {
                  if (
                    data.data.isVisitIsOrder !== null &&
                    data.data.outletOrderHerader !== null
                  ) {
                    setDataToSetPastOrder([
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order No",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.orderNo !== null
                            ? data.data.outletOrderHerader.orderNo
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order Date",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.orderDate !== null
                            ? data.data.outletOrderHerader.orderDate
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Outlet Name",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.outLet !== null &&
                            data.data.outletOrderHerader.outLet.firmName !== null
                            ? data.data.outletOrderHerader.outLet.firmName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "CD",
                        value:
                          data.data.outletOrderHerader !== null &&
                            data.data.outletOrderHerader.distcountAmt !== null
                            ? data.data.outletOrderHerader.distcountAmt
                            : "-",
                      },
                    ]);

                    if (
                      data.data.outletOrderHerader &&
                      data.data.outletOrderHerader !== null
                    ) {
                      if (
                        data.data.outletOrderHerader.outletOrderDetails &&
                        data.data.outletOrderHerader.outletOrderDetails
                          .length !== 0
                      ) {
                        (outletDetails =
                          data.data.outletOrderHerader.outletOrderDetails.map(
                            (rowData, index) => {
                              let outletData = {
                                index: index + 1,
                                productName: rowData.product.name,
                                focusProduct:
                                  rowData.focusFlag == 1 ? "Yes" : "No",
                                uom: rowData.product.outletUom.name,
                                rate: rowData.rate.toFixed(2),
                                qty: rowData.qty,
                                discountPer: rowData.discountPer,
                                totalAmt: rowData.totalAmt.toFixed(2),
                              };
                              return outletData;
                            }
                          )),
                          setOutletOrderDetailsPastOrder(outletDetails);
                        setPopUpFlag(true);
                      }

                      const offerDetailsPastOrder =
                        data.data.outletOrderHerader.appliedOffers.map(
                          (rowData, index) => {
                            let offerData = {
                              index: index + 1,
                              name: rowData.name,
                            };
                            return offerData;
                          }
                        );
                      setAppliedOfferDetailsPastOrder(offerDetailsPastOrder);
                    }
                    showLoador({ loador: false });
                  } else {
                    showLoador({ loador: false });
                    showNotification({
                      msg: dataNotFoundMsg,
                      severity: "error",
                    });
                  }
                } else {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              } else {
                showLoador({ loador: false });
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          } else {
            showLoador({ loador: false });
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const handleClose = () => {
    setPopUpFlag(false);
  };

  const handleClickOnProductCount = (itemObject) => {
    timelineDetails(itemObject);
  };

  const handleTabClick = (itemObject) => {
    handleTabData(itemObject);
  };

  const handleCheckInClick = (itemObject) => {
    setSalesPersonCheckInImageFlagFlag(true);
    setItemObj(itemObject);
  };

  const handleZeroOrderImage = (itemObject) => {
    setZeroOrderImgOpenFlag(true);
    setItemObj(itemObject);
  };

  const handleClosesSalesPersonImage = () => {
    setSalesPersonCheckInImageFlagFlag(false);
  };

  const handleClosesZeroOrderImage = () => {
    setZeroOrderImgOpenFlag(false);
  };

  return (
    <Paper
      sx={{
        paddingTop: 2,
        borderRadius: 2,
        px: "2%",
      }}
      elevation={0}
      style={{ width: "100%" }}
    >
      <LandingScreenHeader
        screenTitle={"Timeline"}
        showAddButton={false}
        showBackButton={true}
        onCancel={onCancel}
      />

      {showTimelineManagerLogs == 1 && (
        <Grid container marginTop="20px" marginLeft="4px" spacing={4}>

          {/* Sales Team Section */}
          <Grid container

            md={12}
            lg={12}
            xs={12}
            sm={12}
            justifyContent="space-between"
            spacing={2}
          >

            <Grid item
              md={4}
              lg={4}
              xs={4}
              sm={4}
            >

              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                  marginTop: '9px',
                  marginLeft: '14px',
                }}
                label="Sales Team :"
              />
            </Grid>

            <Grid item
              md={8}
              lg={8}
              xs={8}
              sm={8}
            >
              <span
                style={{
                  fontSize: 16,
                  color: blackColor,
                  display: 'block',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: '10px',
                  width: '90%'
                }}
              >
                {salesTeamData !== null ? salesTeamData.salesTeamName : "-"}
              </span>
            </Grid>
          </Grid>


          {/* {Sales Team Type} */}
          <Grid container

            md={12}
            lg={12}
            xs={12}
            sm={12}
            justifyContent="space-between"
            spacing={1}
          >

            <Grid item
              md={4}
              lg={4}
              xs={4}
              sm={4}
            >

              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                  marginTop: '9px',
                  marginLeft: '14px',
                }}
                label="Type :"
              />
            </Grid>

            <Grid item
              md={8}
              lg={8}
              xs={8}
              sm={8}
            >
              <span
                style={{
                  fontSize: 16,
                  color: blackColor,
                  display: 'block',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: '10px',
                }}
              >
                {salesTeamData !== null ? salesTeamData.salesTeamType : "-"}
              </span>
            </Grid>
          </Grid>

          {/* Beat Section */}
          {params.userTypeId == "4" && (
            <Grid container

              md={12}
              lg={12}
              xs={12}
              sm={12}
              justifyContent="space-between"
              spacing={1}
            >

              <Grid item
                md={4}
                lg={4}
                xs={4}
                sm={4}
              >

                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                    marginTop: '9px',
                    marginLeft: '14px',
                  }}
                  label="Beat :"
                />
              </Grid>

              <Grid item
                md={8}
                lg={8}
                xs={8}
                sm={8}
              >
                <span
                  style={{
                    fontSize: 16,
                    color: blackColor,
                    display: 'block',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '10px',
                    width: '90%'
                  }}
                >
                  {selectedBeatData !== null ? selectedBeatData : "-"}
                </span>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}



      <br />
      <Grid display={"flex"} md={12} lg={12} flexDirection={"row"}>
        <Grid
          item
          sm={6}
          xs={7}
          md={6}
          lg={6}
          container
          justifyContent="space-evenly"
        // flexDirection={'row'}
        >
          <Grid item xs={12} md={10} sm={10}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16, color: redColor }}
              label="* "
            />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16, color: titleColor }}
              label="From Date :"
            />
          </Grid>
          <Grid item xs={12} md={10} sm={10}>
            <BasicDatePicker
              color="primary"
              name="fromdate"
              size="small"
              value={fromdate}
              disabled={false}
              fullWidth
              onChange={changeDate("fromdate")}
              errorText={
                formErrors["fromdateError"] ? formErrors["fromdateError"] : ""
              }
              isError={
                formErrors["fromdateError"] ? formErrors["fromdateError"] : ""
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
          xs={7}
          md={6}
          lg={6}
          container
          justifyContent="space-evenly"
        // flexDirection={'row'}
        >
          <Grid item xs={12} md={10} sm={10}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16, color: redColor }}
              label="* "
            />
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16, color: titleColor }}
              label="To Date :"
            />
          </Grid>
          <Grid item xs={12} md={10} sm={10}>
            <BasicDatePicker
              color="primary"
              name="todate"
              size="small"
              value={todate}
              minDate={fromdate}
              maxDate={dayjs().subtract(1, "day")}
              disabled={false}
              fullWidth
              onChange={changeDate("todate")}
              errorText={
                formErrors["todateError"] ? formErrors["todateError"] : ""
              }
              isError={
                formErrors["todateError"] ? formErrors["todateError"] : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <DialogActions> */}
      <Grid
        display={"flex"}
        mt={"2%"}
        justifyContent={"flex-end"}
        item
        sx={{ mr: 3 }}
        xs={12}
        sm={12}
        md={12}
      >
        <ButtonCompo
          size="medium"
          onClick={searchData}
          variant="contained"
          name="Search"
        />
      </Grid>
      {/* </DialogActions> */}

      <Box height={"610px"} overflow={"scroll"}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              variant="scrollable"
              aria-label="scrollable force tabs example"
              value={value}
              onChange={handleChange}
              style={{ overflow: "auto", width: "100%" }}
            >
              {showTimelineManagerLogs == 0
                ? location.salesTeamTimeline.map((salesTeamData, index) => (
                  <Tab key={index} label={salesTeamData.day} />
                ))
                : managerLogsList.managerLogs.map((managerLogData, index) => (
                  <Tab key={index} label={managerLogData.day} />
                ))}
            </Tabs>
            {showTimelineManagerLogs == 0
              ? location.salesTeamTimeline.map((salesTeamData, index) => {
                let totalAmount = 0;
                if (salesTeamData?.timelineData?.length !== 0) {
                  salesTeamData.timelineData.map((data) => {
                    totalAmount += +data.amount;
                  });
                }

                return (
                  <TabPanel key={index} value={value} index={index}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="Beat Names :"
                      />{" "}
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          rows[index]?.timelineData.length !== 0
                            ? rows[index]?.timelineData[0].beatName
                            : "-"
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="Total Amount :"
                      />{" "}
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={totalAmount !== 0 ? totalAmount : "-"}
                      />
                    </Grid>
                    <TableContainer sx={{ width: "maxContent" }}>
                      <Table
                        sx={{ minWidth: "maxContent" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">Time</TableCell>
                            <TableCell align="center">Outlet Name</TableCell>
                            <TableCell align="center">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows[index]?.timelineData.map(
                            (rowData, rowIndex) => (
                              <TableRow key={rowData.index}>
                                <TableCell align="center">
                                  {rowData.index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {rowData.time}
                                </TableCell>
                                <TableCell align="left">
                                  <div
                                    onClick={() => timelineDetails(rowData)}
                                    style={{
                                      color: cyanBlue,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {rowData.outletName}
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {rowData.amount}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                );
              })
              : rows.length !== 0 &&
              rows.map(
                (managerLogHeaderObj, index) =>
                  Array.isArray(managerLogHeaderObj?.managerLogs) &&
                  managerLogHeaderObj?.managerLogs.map(
                    (mnglogObj, subIndex) => {
                      const itemObject = {
                        id: mnglogObj.id,
                        date: managerLogHeaderObj.date,
                        label1: "Amount",
                        label2: "Product Count",
                        onPressLabel2Flag: true,
                        label3: "Qty",
                        outletOrderHeaderId: mnglogObj.extraIds
                          ? mnglogObj.extraIds
                          : "",
                        outletId: mnglogObj.outletId
                          ? mnglogObj.outletId
                          : "",
                        isVisitIsOrder: mnglogObj.isVisitIsOrder,
                        remoteOrder: mnglogObj.remoteOrder,
                        value1: mnglogObj.description
                          ? mnglogObj.description
                          : 0,
                        value2: mnglogObj.extra1 ? mnglogObj.extra1 : 0,
                        value3: mnglogObj.amount,
                        valueColor3: blackColor,
                        headerName:
                          mnglogObj.workingTypeId == 5 &&
                            mnglogObj.otherTaskId == 3
                            ? mnglogObj.description
                            : mnglogObj.otherTaskId == 4
                              ? mnglogObj.salesPersonName
                              : `${mnglogObj.entityName} (${mnglogObj.workingTypeName})`,

                        industryIconFlag:
                          (mnglogObj.otherTaskId == 5 &&
                            (mnglogObj.checkInOut == 0 ||
                              mnglogObj.checkInOut == 1)) ||
                            (mnglogObj.workingTypeId == 5 &&
                              mnglogObj.otherTaskId == 3) ||
                            mnglogObj.otherTaskId == 4 ||
                            mnglogObj.otherTaskId == 6
                            ? false
                            : true,

                        jointWorkIconFlag:
                          mnglogObj.otherTaskId == 4 ||
                            mnglogObj.otherTaskId == 6
                            ? true
                            : false,
                        subHeaderFlag: !(
                          mnglogObj.workingTypeId == 0 ||
                          mnglogObj.otherTaskId == 3
                        ),
                        subHeaderName:
                          mnglogObj.otherTaskId != 5 &&
                            mnglogObj.workingTypeId != 1
                            ? mnglogObj.description
                            : "",
                        timelineFlag: true,
                        color: mnglogObj.color,
                        workTypeFlag: 3,
                        timelineWorkTypeFlag: !(
                          mnglogObj.otherTaskId == 5 &&
                          (mnglogObj.checkInOut == 0 ||
                            mnglogObj.checkInOut == 1)
                        ),
                        workType: mnglogObj.otherTaskId,
                        timelineHeaderFlag: true,
                        timelineHeaderValue: mnglogObj.fromTime,
                        toTime: mnglogObj.toTime,
                        checkInOutStatus:
                          mnglogObj.otherTaskId == 5 &&
                          mnglogObj.checkInOut == 0,
                        checkInOutStatusFlag:
                          mnglogObj.otherTaskId == 5 &&
                          (mnglogObj.checkInOut == 0 ||
                            mnglogObj.checkInOut == 1),
                        detailsFlag:
                          (mnglogObj.otherTaskId == 1 ||
                            mnglogObj.otherTaskId == 2) &&
                            mnglogObj.workingTypeId == 1
                            ? 1
                            : 0,
                        workingTypeId: mnglogObj.workingTypeId,
                        managerStartLat: mnglogObj.managerStartLat,
                        managerStartLong: mnglogObj.managerStartLong,
                      };

                      return (
                        <TabPanel key={index} value={value} index={index}>
                          <div style={cardStyle} key={index - subIndex}>
                            {itemObject.timelineHeaderFlag && (
                              <div style={headerStyle}>
                                {itemObject.checkInOutStatusFlag
                                  ? itemObject.timelineHeaderValue
                                  : itemObject.timelineHeaderValue +
                                  "  " +
                                  "To" +
                                  "  " +
                                  itemObject.toTime}
                              </div>
                            )}

                            {itemObject.checkInOutStatusFlag && (
                              <div
                                style={{
                                  ...statusStyle(itemObject.checkInOutStatus),
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "0 5px",
                                }}
                                onClick={
                                  checkInImageFlag == 1 &&
                                    itemObject.checkInOutStatus
                                    ? () => handleCheckInClick(itemObject)
                                    : () => handleTabClick(itemObject)
                                }
                              >
                                <span>
                                  {itemObject.checkInOutStatus
                                    ? "Check In"
                                    : "Check Out"}
                                </span>
                                {checkInImageFlag == 1 &&
                                  itemObject.checkInOutStatus && (
                                    <>
                                      <MyComponentWithIconProps
                                        statusImage={VisibilityIcon}
                                        color="black"
                                        fontSize="medium"
                                        title="View Selfie"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCheckInClick(itemObject);
                                        }}
                                      />
                                    </>
                                  )}
                              </div>
                            )}

                            {itemObject.timelineWorkTypeFlag && (
                              <>
                                <div
                                  style={{
                                    ...workStyle(itemObject),
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0 5px",
                                  }}
                                  onClick={
                                    zeroOrderImageFlag == 1 &&
                                      itemObject.workType == 1 &&
                                      itemObject.workingTypeId != 5 &&
                                      itemObject.isVisitIsOrder == 1
                                      ? () => handleZeroOrderImage(itemObject)
                                      : () => handleTabClick(itemObject)
                                  }
                                >
                                  {itemObject.workType == 1 &&
                                    itemObject.workingTypeId != 5
                                    ? "Retailing"
                                    : itemObject.workType == 2 &&
                                      itemObject.workingTypeId != 5
                                      ? "Distributor"
                                      : itemObject.workType == 3
                                        ? "Office"
                                        : itemObject.workType == 4
                                          ? "Joint"
                                          : "Other Work"}

                                  {zeroOrderImageFlag == 1 &&
                                    itemObject.workType == 1 &&
                                    itemObject.workingTypeId != 5 &&
                                    itemObject.isVisitIsOrder == 1 && (
                                      <>
                                        <MyComponentWithIconProps
                                          statusImage={VisibilityIcon}
                                          color="black"
                                          fontSize="medium"
                                          title="View Image"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleZeroOrderImage(itemObject);
                                          }}
                                        />
                                      </>
                                    )}
                                </div>

                                <div style={detailBoxStyle}>
                                  <div style={headerSubHeaderStyle}>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {itemObject.industryIconFlag ? (
                                        itemObject.remoteOrder == 1 ? (
                                          <>
                                            <CottageIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: "8px",
                                              }}
                                            />
                                            {itemObject.headerName}
                                            <span
                                              style={{
                                                backgroundColor: "red",
                                                color: "white",
                                                padding: "4px 8px",
                                                marginLeft: "12px",
                                              }}
                                            >
                                              Remote Order
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <CottageIcon
                                              style={{
                                                verticalAlign: "middle",
                                                marginRight: "8px",
                                              }}
                                            />
                                            {itemObject.headerName}
                                          </>
                                        )
                                      ) : itemObject.jointWorkIconFlag ? (
                                        <>
                                          <PersonIcon
                                            style={{
                                              verticalAlign: "middle",
                                              marginRight: "8px",
                                            }}
                                          />
                                          {itemObject.headerName}
                                        </>
                                      ) : (
                                        itemObject.headerName
                                      )}
                                    </div>

                                    {itemObject.subHeaderFlag && (
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "350",
                                          marginTop: "8px",
                                          marginLeft: "32px",
                                        }}
                                      >
                                        {itemObject.subHeaderName}
                                      </div>
                                    )}
                                  </div>

                                  {itemObject.workTypeFlag == 1 ? (
                                    <div style={{ padding: 5 }}>
                                      <div
                                        style={{
                                          backgroundColor: greyColor,
                                          width: "98%",
                                          borderRadius: 2,
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: itemObject.color,
                                            width: "35%",
                                            borderRadius: 2,
                                            padding: 2,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <p
                                            style={{
                                              color: blackColor,
                                              fontSize: 14,
                                              fontWeight: 500,
                                              fontFamily:
                                                "Poppins, sans-serif",
                                              position: "relative",
                                              alignSelf: "flex-start",
                                              marginLeft: 2,
                                            }}
                                          >
                                            {itemObject.workTypeName
                                              ? itemObject.workTypeName
                                              : itemObject.workType}
                                          </p>
                                        </div>

                                        <p
                                          style={{
                                            color: blackColor,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            fontFamily: "poppinsMedium",
                                            position: "relative",
                                            alignSelf: "flex-start",
                                            marginRight: 5,
                                            padding: 2,
                                          }}
                                        >
                                          {itemObject.timeValue !== ""
                                            ? itemObject.timeValue
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  ) : itemObject.workTypeFlag == 0 ? (
                                    <div style={{ padding: 5 }}>
                                      <div
                                        style={{
                                          backgroundColor: yellowColor,
                                          width: "98%",
                                          borderRadius: 2,
                                          padding: 2,
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: white,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            fontFamily: "poppinsMedium",
                                            position: "relative",
                                            alignSelf: "flex-start",
                                            marginLeft: 2,
                                          }}
                                        >
                                          {itemObject.workTypeName
                                            ? itemObject.workTypeName
                                            : itemObject.workType}
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}

                                  {itemObject.timelineFlag &&
                                    itemObject.detailsFlag == 1 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "6px",
                                        }}
                                      >
                                        <div style={dataSectionStyle}>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {itemObject.value1}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {" "}
                                            {itemObject.label1}
                                          </span>
                                        </div>
                                        <div
                                          style={dataSectionStyle}
                                          onClick={() =>
                                            handleClickOnProductCount(
                                              itemObject
                                            )
                                          }
                                        >
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              color: cyanBlue,
                                            }}
                                          >
                                            {itemObject.value2}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              color: cyanBlue,
                                            }}
                                          >
                                            {" "}
                                            {itemObject.label2}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                        </TabPanel>
                      );
                    }
                  )
              )}
          </Grid>
        </Grid>
      </Box>

      {popUpFlag && (
        <TimelineDetailsPopup
          open={popUpFlag}
          handleCloses={handleClose}
          columns={
            showTimelineManagerLogs == 1
              ? [
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Product Name",
                  name: "productName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "Focus Prodct",
                  name: "focusProduct",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "UOM",
                  name: "uom",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Rate",
                  name: "rate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Quantity",
                  name: "qty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Discount(%)",
                  name: "discountPer",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Total Amount",
                  name: "totalAmt",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
              ]
              : [
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Product Name",
                  name: "productName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                  canSearch: true,
                },
                {
                  title: "UOM",
                  name: "uom",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                  canSearch: true,
                },
                {
                  title: "Rate",
                  name: "rate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Quantity",
                  name: "qty",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
                {
                  title: "Total Amount",
                  name: "totalAmt",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                  canSearch: true,
                },
              ]
          }
          rows={outletOrderDetails}
          rowsPastOrder={outletOrderDetailsPastOrder}
          rowsOffer={appliedOfferDetails}
          rowsPastOrderOffer={appliedOfferDetailsPastOrder}
          DataToSet={DataToSet}
          dataToSetPastOrder={dataToSetPastOrder}
          showTimelineManagerLogs={showTimelineManagerLogs}
          showPastOrderInTimeline={showPastOrderInTimeline}
        />
      )}

      {salesPersonCheckInImageFlag && (
        <SalesPersonCheckInImage
          open={salesPersonCheckInImageFlag}
          id={params.id}
          itemObject={itemObj}
          handleCloses={handleClosesSalesPersonImage}
        />
      )}

      {zeroOrderImgOpenFlag && (
        <ZeroOrderImage
          open={zeroOrderImgOpenFlag}
          itemObject={itemObj}
          handleCloses={handleClosesZeroOrderImage}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  location: state.location,
  managerLogsList: state.managerLogs,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMapData,
  getTimelineForSalesExecutive,
  getManagerLogsBySalesTeamIds,
  setManagerLogs,
};
export default connect(mapStateToProps, mapDispatchToProps)(Timeline);

const cardStyle = {
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  boxShadow: "rgba(0, 0, 0, 0.20) 0px 3px 8px",
  borderRadius: "4px",
};

const headerStyle = {
  fontSize: "15px",
  fontWeight: "500",
};

const statusStyle = (isCheckIn) => ({
  fontSize: "12px",
  borderRadius: "6px",
  padding: "10px 6px",
  backgroundColor: isCheckIn ? DarkBlue : redColor,
  minHeight: "27px",
});

const workStyle = (itemObject) => ({
  fontSize: "12px",
  fontWeight: "450",
  borderRadius: "6px",
  padding: "6px",
  minHeight: "27px",
  backgroundColor:
    itemObject.workType == 1 && itemObject.workingTypeId != 5
      ? LightGreenColor // retailing
      : itemObject.workType == 2 && itemObject.workingTypeId != 5
        ? titleColor // distributor
        : itemObject.workType == 3
          ? lightVioletColor // officeWorkColor
          : itemObject.workType == 4
            ? yellowColor
            : smoothGreenColor, //other work
});

const nameStyle = {
  fontSize: "12px",
  borderRadius: "6px",
  padding: "6px",
  backgroundColor: "lightcyan",
};

const detailBoxStyle = {
  borderRadius: "1.5px",
  boxShadow: "rgba(0, 0, 0, 0.10) 0px 3px 8px",
  padding: "1.5px",
  backgroundColor: "white",
};

const entityStyle = {
  display: "flex",
  gap: "8px",
  alignItems: "center",
};

const headerSubHeaderStyle = {
  gap: "8px",
  alignItems: "center",
};

const circleStyle = {
  borderRadius: "25%",
  width: "12px",
  height: "12px",
  backgroundColor: "red",
};

const dataSectionStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  alignItems: "center",
  width: "40%",
};
