import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  stockHolderAndProductConfiguration: [],
  stockHolderAndProductConfigurationView: [],
  stackholderName: [],
  rateEditOrNot: {},
};
let URL = endpoints.stockHolderAndProductConfiguration;
const stockHolderAndProductConfigurationSlice = createSlice({
  name: "stockHolderAndProductConfiguration",
  initialState,
  reducers: {
    stockHolderAndProductConfigurationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockHolderAndProductConfiguration: row,
      };
    },
    stockHolderAndProductConfigurationViewSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockHolderAndProductConfigurationView: row,
      };
    },
    stakeHolderNameSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stackholderName: row,
      };
    },
    RateEditOrNotSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        rateEditOrNot: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  stockHolderAndProductConfigurationSuccess,
  stockHolderAndProductConfigurationViewSuccess,
  stakeHolderNameSuccess,
  RateEditOrNotSuccess,
  resetState,
} = stockHolderAndProductConfigurationSlice.actions;

export default stockHolderAndProductConfigurationSlice.reducer;
export const getStockHolderAndProductConfiguration = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/get-stock-holders",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (stockHolderAndProductConfigurationObject, index) => {
            let stockHolderAndProductConfigurationObjectData = {
              index: index + 1,
              id:
                stockHolderAndProductConfigurationObject.id === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.id,
              stockStakeHolder:
                stockHolderAndProductConfigurationObject.stockStakeHolder ===
                  null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .id,
              stockStakeHolderName:
                stockHolderAndProductConfigurationObject.stockStakeHolder ===
                  null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .ownerName,
              stockStakeHolderFirmName: stockHolderAndProductConfigurationObject.stockStakeHolder ===
                null
                ? ""
                : stockHolderAndProductConfigurationObject.stockStakeHolder
                  .firmName,
              stockStakeHolderType:
                stockHolderAndProductConfigurationObject.stockStakeHolder
                  .stockStakeHolderType === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .stockStakeHolderType.id,
              stockStakeHolderTypeName:
                stockHolderAndProductConfigurationObject.stockStakeHolder
                  .stockStakeHolderType === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .stockStakeHolderType.name,
              productCount:
                stockHolderAndProductConfigurationObject.productCount === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.productCount,
            };
            return stockHolderAndProductConfigurationObjectData;
          }
        );
        dispatch(stockHolderAndProductConfigurationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getStockHolderAndProductConfigurationByUserRegions = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/get-stock-holders-by-user-regions",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (stockHolderAndProductConfigurationObject, index) => {
            let stockHolderAndProductConfigurationObjectData = {
              index: index + 1,
              id:
                stockHolderAndProductConfigurationObject.id === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.id,
              stockStakeHolder:
                stockHolderAndProductConfigurationObject.stockStakeHolder ===
                  null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .id,
              stockStakeHolderName:
                stockHolderAndProductConfigurationObject.stockStakeHolder ===
                  null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .ownerName,
              stockStakeHolderFirmName: stockHolderAndProductConfigurationObject.stockStakeHolder ===
                null
                ? ""
                : stockHolderAndProductConfigurationObject.stockStakeHolder
                  .firmName,
              stockStakeHolderType:
                stockHolderAndProductConfigurationObject.stockStakeHolder
                  .stockStakeHolderType === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .stockStakeHolderType.id,
              stockStakeHolderTypeName:
                stockHolderAndProductConfigurationObject.stockStakeHolder
                  .stockStakeHolderType === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.stockStakeHolder
                    .stockStakeHolderType.name,
              productCount:
                stockHolderAndProductConfigurationObject.productCount === null
                  ? ""
                  : stockHolderAndProductConfigurationObject.productCount,
            };
            return stockHolderAndProductConfigurationObjectData;
          }
        );
        dispatch(stockHolderAndProductConfigurationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockHolderAndProductConfigurationView =
  ({ stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const responseData = apiGet({
          url:
            URL +
            "/by-stock-stake-holder?stockStakeHolderId=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const row = data.data.stockHolderAndProductConfiguration.map(
              (stockHolderAndProductConfigurationObject, index) => {
                let stockHolderAndProductConfigurationObjectData = {
                  index: index + 1,
                  id:
                    stockHolderAndProductConfigurationObject.id === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.id,
                  stockStakeHolder:
                    stockHolderAndProductConfigurationObject.stockStakeHolder ===
                      null
                      ? ""
                      : stockHolderAndProductConfigurationObject.stockStakeHolder
                        .id,
                  stockStakeHolderName:
                    stockHolderAndProductConfigurationObject.stockStakeHolder ===
                      null
                      ? ""
                      : stockHolderAndProductConfigurationObject.stockStakeHolder
                        .ownerName,
                  stockStakeHolderType:
                    stockHolderAndProductConfigurationObject.stockStakeHolder
                      .stockStakeHolderType === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.stockStakeHolder
                        .stockStakeHolderType.id,
                  stockStakeHolderTypeName:
                    stockHolderAndProductConfigurationObject.stockStakeHolder
                      .stockStakeHolderType === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.stockStakeHolder
                        .stockStakeHolderType.name,
                  product:
                    stockHolderAndProductConfigurationObject.product === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product.id,
                  shortName:
                    stockHolderAndProductConfigurationObject.product.shortName ===
                      null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product
                        .shortName,
                  productName:
                    stockHolderAndProductConfigurationObject.product === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product.name +
                      " (" +
                      stockHolderAndProductConfigurationObject.product
                        .shortName +
                      " )",
                  code:
                    stockHolderAndProductConfigurationObject.product.code === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product.code,
                  stakeHolderUom:
                    stockHolderAndProductConfigurationObject.product
                      .stakeHolderUom === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product
                        .stakeHolderUom.id,
                  stakeHolderUomName:
                    stockHolderAndProductConfigurationObject.product
                      .stakeHolderUom.name === null
                      ? ""
                      : stockHolderAndProductConfigurationObject.product
                        .stakeHolderUom.name,
                };
                return stockHolderAndProductConfigurationObjectData;
              }
            );
            dispatch(stockHolderAndProductConfigurationViewSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return responseData;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getStakeHolderNameByStakeholderType =
  ({ stockStakeHolderTypeIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-stock-stake-holder?stockStakeHolderTypeIds=" +
            stockStakeHolderTypeIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.ownerName
                    ? stakeHolderTypeObj.ownerName
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setStakeHolderNameByStakeholderType =
  ({ row }) =>
    async (dispatch) => {
      dispatch(stakeHolderNameSuccess({ row }));
    };

export const getRateEditOrNot = () => async (dispatch) => {
  try {
    let stockStakeHolderId = localStorage.getItem("userId");

    const response = apiGet({
      url:
        URL + "/by-stock-stake-holder?stockStakeHolderId=" + stockStakeHolderId,
    }).then(({ data, success }) => {
      if (success) {
        dispatch(RateEditOrNotSuccess({ row: data.data }));
        return { response: [], success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
