import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import CreditNoteByBillWise from "./CreditNoteByBillWise";
import CreditNoteFormNew from "./CreditNoteFormNew";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { CreditNoteForOutletNewJson } from "../../DynamicFormsJson/Transaction/creditNoteForOutletNew";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
class CreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
      stockData: [],
    };
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.manageStockHeaderLatest + "/stock-by-current-date",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          this.setState({
            stockData: data.data,
          });
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { value, stockData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Credit Note"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <br />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
              defaultValue={value}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="CRN From Category-Wise"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="CRN From Bill-Wise"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
        {value == "0" ? (
          <CreditNoteFormNew stockData={stockData} />
        ) : (
          <CreditNoteByBillWise />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNote);
