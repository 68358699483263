import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicTabwiseTable from "../../../components/dynamicscreens/DynamicTabwiseTable";
import endpoint from "../../../config/endpoints";
import {
  FailTogenerateCredentialMsg,
  beatsAssign,
  changeActive,
  generateCredentialMsg,
  noInternetMsg,
  sendCredentialMsg,
  serverMsg,
  statusmsg,
} from "../../../config/messageconstant";
import { SalesExecutiveJson } from "../../../DynamicFormsJson/MastersJSON/salesExecutive";
import { getSalesExecutiveByArea } from "../../../Slice/salesExecutive.slice";
import { SalesExecutiveColumns } from "../../../tableColumns/table-columns";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { downloadF } from "../../PDF/PDFDownload";

class SalesExecutiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      dynamicMasterData: {
        SalesExecutiveListInActive:
          this.props.salesExecutiveList?.salesExecutive.filter(
            (row) => row.isActive == 0
          ),
        SalesExecutiveListActive:
          this.props.salesExecutiveList?.salesExecutive.filter(
            (row) => row.isActive == 1
          ),
      },
      tabList: [
        {
          label: "Active",
          allyProps: 0,
          columns: SalesExecutiveColumns,
          showExcelDownload: true,
          changeExcelURL: "sales-executive-data",
          isActionColActive: true,
          value: "SalesExecutiveListActive",
        },
        {
          label: "InActive",
          allyProps: 1,
          columns: SalesExecutiveColumns,
          showExcelDownload: true,
          changeExcelURL: "sales-executive-data",
          isActionColActive: true,
          value: "SalesExecutiveListInActive",
        },
      ],
    };
  }
  componentDidMount() {
    this.getListData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
      if (
        this.props.salesExecutiveList?.salesExecutive !==
        nextProps.salesExecutiveList?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            SalesExecutiveListActive:
              nextProps.salesExecutiveList?.salesExecutive
                .filter((row) => row.isActive == 1)
                .map((rowData, index) => {
                  return { ...rowData, index: index + 1 };
                }),
            SalesExecutiveListInActive:
              nextProps.salesExecutiveList?.salesExecutive
                .filter((row) => row.isActive == 0)
                .map((rowData, index) => {
                  return { ...rowData, index: index + 1 };
                }),
          },
        });
      }
    }
  }
  generateStaticExcelMethod = () => {
    const { showLoador, showNotification } = this.props;
    const { tabValue } = this.state;
    showLoador({ loador: true });
    downloadF({
      url: endpoint.exportData + "/sales-executive-data?flag=" + tabValue,
      ext: "xls",
      openNewTab: true,
    }).then((response) => {
      showLoador({ loador: false });
      if (!response) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  onClickApplySend = (rowData) => {
    swal({
      title: "Are you sure?",
      text: sendCredentialMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiGet({
            url: endpoint.salesExecutive + "/" + rowData.id + "/password-reset",
          }).then(({ success }) => {
            if (success) {
              this.props.showLoador({ loador: false });
              this.props.showNotification({
                msg: generateCredentialMsg,
              });
            } else {
              this.props.showNotification({
                msg: FailTogenerateCredentialMsg,
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            }
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };
  getTabValue = (rowValue) => {
    this.setState({
      tabValue: rowValue == 0 ? 1 : 0,
    });
  };
  getListData = async () => {
    const { getSalesExecutiveByArea, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSalesExecutiveByArea({
        userTypeId: 4,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onChangeStatus = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      let status = rowData.isActive == 1 ? 0 : 1;

      showLoador({ loador: true });
      apiGet({
        url: endpoint.assignBeat + "/by-sales-team?salesTeamId=" + rowData.id,
      }).then(({ data, success }) => {
        if (!success || data.error) {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: false });
          if (data && data.data && data.data.length !== 0) {
            showNotification({ msg: beatsAssign, severity: "error" });
          } else {
            swal({
              text: changeActive,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                showLoador({ loador: true });
                apiPost({
                  url:
                    endpoint.salesExecutive +
                    "/update-is-active/?id=" +
                    rowData.id +
                    "&status=" +
                    status,
                }).then(({ data, success }) => {
                  if (success) {
                    showLoador({ loador: false });
                    showNotification({ msg: statusmsg });
                    this.getListData();
                  } else {
                    showLoador({ loador: false });
                    showNotification({ msg: serverMsg, severity: "error" });
                  }
                });
              }
            });
          }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { salesExecutiveList } = this.props;
    const { dynamicMasterData, tabList } = this.state;
    return (
      <>
        <DynamicTabwiseTable
          dynamicMasterData={dynamicMasterData}
          searchList={SalesExecutiveJson.searchList}
          formPath={SalesExecutiveJson.formPath}
          screenTitle={SalesExecutiveJson.screenTitle}
          fieldMeta={SalesExecutiveJson.fieldMeta}
          showPdfDownload={SalesExecutiveJson.showPdfDownload}
          showExcelDownload={true}
          isExcelStaticUrl={true}
          pdfFileName={SalesExecutiveJson.pdfFileName}
          excelFileName={SalesExecutiveJson.excelFileName}
          showAddButton={SalesExecutiveJson.showAddButton}
          tableColumnsToFilter={SalesExecutiveJson.tableColumnsToFilter}
          tableColumns={SalesExecutiveColumns}
          tableData={salesExecutiveList?.salesExecutive}
          apiBaseURL={SalesExecutiveJson.apiBaseURL}
          callApi={false}
          isActiveURL={SalesExecutiveJson.isActiveURL}
          showAddButtonInTable={true}
          onClickAdd={this.onClickAdd}
          openFormPath={SalesExecutiveJson.openFormPath}
          searchButtonGrid={SalesExecutiveJson.searchButtonGrid}
          getTableDataById={true}
          onSearchData={this.getListData}
          showApplyCredintial={true}
          rowStatusTrue={true}
          rowStatus={this.onChangeStatus}
          rowApply={this.onClickApplySend}
          tabList={tabList}
          generateStaticExcelMethod={this.generateStaticExcelMethod}
          changeExcelURL={
            "excel-report-for-sale-executive?stockStakeHolderTypeIds=4"
          }
          isNote={true}
          isNoteValue={"Add Sales Executive & Details"}
          rowList={dynamicMasterData}
          formPathStatic={false}
          showHeadEdit={true}
          staticEdit={false}
          deleteStaticTrue={false}
          getTabValue={this.getTabValue}
          showHeadDelete={false}
          showPegination={true}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  salesExecutiveList: state.salesExecutive,
  stockStakeHolderType: state.stockStakeHolderType,
});
const mapDispatchToProps = {
  getSalesExecutiveByArea,
  showLoador,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesExecutiveTable);
