import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  manager: [],
};
let URL = endpoints.manager;
const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    managerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        manager: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { managerSuccess, resetState } = managerSlice.actions;

export default managerSlice.reducer;

export const getManager =
  ({ areaIds, stockStakeHolderTypeId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-sales-manager-by-areas-and-stock-stake_holder-type?areaIds=" +
            areaIds +
            "&stockStakeHolderTypeId=" +
            stockStakeHolderTypeId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((managerObject, index) => {
              let managerData = {
                index: index + 1,
                id: managerObject.id === null ? "" : managerObject.id,
                userName:
                  managerObject.userName === null ? "" : managerObject.userName,
                name: managerObject.name === null ? "" : managerObject.name,
                address:
                  managerObject.address === null ? "" : managerObject.address,
                region:
                  managerObject.region !== null && managerObject.region.id
                    ? managerObject.region.id
                    : "",
                regionName:
                  managerObject.region !== null && managerObject.region.name
                    ? managerObject.region.name
                    : "",
                zoneNames:
                  managerObject.zoneNames === null ? "" : managerObject.zoneNames,
                areaNames:
                  managerObject.areaNames === null ? "" : managerObject.areaNames,
                emailId:
                  managerObject.emailId === null || managerObject.emailId === "-"
                    ? "-"
                    : managerObject.emailId,
                contactNo:
                  managerObject.contactNo === null ? "" : managerObject.contactNo,
                whatsAppNo:
                  managerObject.whatsAppNo === null
                    ? ""
                    : managerObject.whatsAppNo,
                isActive:
                  managerObject.isActive === null ? "" : managerObject.isActive,
              };
              return managerData;
            });
            dispatch(managerSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getUpdatedManager =
  ({ managerJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          // http://localhost:8088/api/distribution/v1/sales-team/sales-manager-by-areas-and-stock-stake_holder-type
          url:
            URL +
            "/sales-manager-by-areas-and-stock-stake_holder-type",
          postBody: managerJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((managerObject, index) => {
              let managerData = {
                index: index + 1,
                id: managerObject.id === null ? "" : managerObject.id,
                userName:
                  managerObject.userName === null ? "" : managerObject.userName,
                name: managerObject.name === null ? "" : managerObject.name,
                stockStakeHolderType: managerObject.stockStakeHolderType === null ? "" : managerObject.stockStakeHolderType.name,
                address:
                  managerObject.address === null ? "" : managerObject.address,
                region:
                  managerObject.regionId !== null
                    ? managerObject.regionId
                    : "",
                regionName:
                  managerObject.regionName !== null
                    ? managerObject.regionName
                    : "",
                zoneNames:
                  managerObject.zoneNames === null ? "" : managerObject.zoneNames,
                areaNames:
                  managerObject.areaNames === null ? "" : managerObject.areaNames,
                emailId:
                  managerObject.emailId === null || managerObject.emailId === "-"
                    ? "-"
                    : managerObject.emailId,
                contactNo:
                  managerObject.contactNo === null ? "" : managerObject.contactNo,
                whatsAppNo:
                  managerObject.whatsAppNo === null
                    ? ""
                    : managerObject.whatsAppNo,
                isActive:
                  managerObject.isActive === null ? "" : managerObject.isActive,
              };
              return managerData;
            });
            dispatch(managerSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getManagerAll = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-sales-manager-by-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((managerObject, index) => {
          let managerData = {
            index: index + 1,
            id: managerObject.id === null ? "" : managerObject.id,
            userId: managerObject.userId === null ? "" : managerObject.userId,
            userName:
              managerObject.userName === null ? "" : managerObject.userName,
            stockStakeHolderType:
              managerObject.stockStakeHolderType === null
                ? ""
                : managerObject.stockStakeHolderType.name,
            userType:
              managerObject.stockStakeHolderType.userType === null
                ? ""
                : managerObject.stockStakeHolderType.userType.name,
            name: managerObject.name === null ? "" : managerObject.name,
            address:
              managerObject.address === null ? "" : managerObject.address,
            regionName:
              managerObject.region === null ? "" : managerObject.region.name,
            zoneNames:
              managerObject.zoneNames === null ? "" : managerObject.zoneNames,
            areaNames:
              managerObject.areaNames === null ? "" : managerObject.areaNames,
            emailId:
              managerObject.emailId === null ? "" : managerObject.emailId,
            contactNo:
              managerObject.contactNo === null ? "" : managerObject.contactNo,
            whatsAppNo:
              managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,
            employeeCode:
              managerObject.employeeCode === null
                ? ""
                : managerObject.employeeCode,

            accessRoleId:
              managerObject.accessRoleId === null
                ? ""
                : managerObject.accessRoleId,
            isActive:
              managerObject.isActive === null ? "" : managerObject.isActive,
          };
          return managerData;
        });
        dispatch(managerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getManagerAllUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-manager-by-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((managerObject, index) => {
          let managerData = {
            index: index + 1,
            id: managerObject.id === null ? "" : managerObject.id,
            userId: managerObject.userId === null ? "" : managerObject.userId,
            userName:
              managerObject.userName === null ? "" : managerObject.userName,
            stockStakeHolderType:
              managerObject.stockStakeHolderType === null
                ? ""
                : managerObject.stockStakeHolderType.name,
            userType:
              managerObject.stockStakeHolderType.userType === null
                ? ""
                : managerObject.stockStakeHolderType.userType.name,
            name: managerObject.name === null ? "" : managerObject.name,
            address:
              managerObject.address === null ? "" : managerObject.address,
            regionName:
              managerObject.regionName === null ? "" : managerObject.regionName,
            zoneNames:
              managerObject.zoneNames === null ? "" : managerObject.zoneNames,
            areaNames:
              managerObject.areaNames === null ? "" : managerObject.areaNames,
            emailId:
              managerObject.emailId === null ? "" : managerObject.emailId,
            contactNo:
              managerObject.contactNo === null ? "" : managerObject.contactNo,
            whatsAppNo:
              managerObject.whatsAppNo === null ? "" : managerObject.whatsAppNo,
            employeeCode:
              managerObject.employeeCode === null
                ? ""
                : managerObject.employeeCode,

            accessRoleId:
              managerObject.accessRoleId === null
                ? ""
                : managerObject.accessRoleId,
            isActive:
              managerObject.isActive === null ? "" : managerObject.isActive,
          };
          return managerData;
        });
        dispatch(managerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
