import endpoint from "../../config/endpoints";

export const stackHolderJson = {
  showSaveNextBtn: false,
  apiBaseURL: endpoint.stackholder,
  changeExcel: endpoint.exportData,
  isEditURL: endpoint.stackholder + "/update",
  formPathEdit: "/stock-holder-edit",
  openFormPath: "/stackholder-form-document",
  openFormPathStatus: "/stackholder-access-role",
  formPath: "/stackholder-form",
  screenTitle: "Stock Holder",
  searchButtonGrid: 1,
  showAddButton: true,
  showDeleteIcon: false,
  showPdfDownload: false,
  pdfFileName: "Stock Holder",
  showExcelDownload: true,
  excelFileName: "Stock Holder",
  excelUrl: "stock-holder-data-new",
  tableColumnsToFilter: [
    {
      columnDisplayName: "User Name",
      columnKeyName: "userName",
      isChecked: true,
    },
    {
      columnDisplayName: "Firm Name",
      columnKeyName: "firmName",
      isChecked: true,
    },
    {
      columnDisplayName: "Owner Name",
      columnKeyName: "ownerName",
      isChecked: false,
    },

    {
      columnDisplayName: "Order To",
      columnKeyName: "orderToOwnerName",
      isChecked: true,
    },
    {
      columnDisplayName: "State",
      columnKeyName: "stateName",
      isChecked: false,
    },
    {
      columnDisplayName: "Area",
      columnKeyName: "areaNames",
      isChecked: true,
    },
    {
      columnDisplayName: "SH-Code",
      columnKeyName: "shCode",
      isChecked: true,
    },
    {
      columnDisplayName: "SPOC",
      columnKeyName: "contactPersonName",
      isChecked: true,
    },
    {
      columnDisplayName: "Contact Number",
      columnKeyName: "contactPersonNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Email Id",
      columnKeyName: "emailId",
      isChecked: true,
    },
    {
      columnDisplayName: "Stock Holder Type",
      columnKeyName: "stockStakeHolderTypeName",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "state",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "taluka",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetails",
      dataKey: "stateIds",
      getListFrom: "region",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select State For Juridictional",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateIDsDetails",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: false,
      isMandatory: true,
    },

    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "stateIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtIdsDetail",
      dataKey: "districtId",
      getListFrom: "zoneIds",
      getListId: "districtId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "autocomplete",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "districtId",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "zoneIds,taluka",
      dataKey: "areaIds",
      isRootLevelKey: true,
      getListId: "areaIds",
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 6,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "Order To",
      controlType: "autocomplete",
      placeHolder: "Select Order ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "orderList",
      dataKey: "orderTo",
      getListFrom: "stockStakeHolderType,areaIds",
      getListId: "orderTo",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "contactPersonName",
      isMandatory: true,
    },

    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "contactPersonNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },

    {
      label: "Firm Address",
      controlType: "textfield",
      placeHolder: " Firm Address",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "address",
      inputType: "numberText",
      isMandatory: true,
      maxLength: 100,
    },

    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: true,
      valid: true,
      validName: "email",
    },
    {
      label: "Bill Name",
      controlType: "textfield",
      placeHolder: "Enter Bill Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
    {
      label: "Credit Limit",
      controlType: "textfield",
      placeHolder: "Enter Credit Limit",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditLimit",
      isMandatory: true,
    },
    {
      label: "Payment term",
      controlType: "autocomplete",
      placeHolder: "Select Payment term",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentTermDetail",
      dataKey: "paymentTerm",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Aadhar Number",
      controlType: "textfield",
      placeHolder: "123456789111",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "adhaarNo",
      isMandatory: false,
      maxLength: 12,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      inputType: "numberText",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
    },
    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "panNo",
      isMandatory: true,
      valid: true,
      validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "12216017000287",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
      maxLength: 14,
    },
    {
      label: "FSSAI Expiry Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expiryDate",
      isMandatory: true,
    },
    {
      label: "UPI",
      controlType: "textfield",
      placeHolder: "abcd@bank",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "upi",
      isMandatory: false,
      valid: true,
      validName: "upiNo",
    },
    {
      label: "Brand",
      controlType: "autocomplete",
      placeHolder: "Select Brand",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "brandDetail",
      dataKey: "brand",
      getListFrom: "orderTo",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Sequence-Wise Outlet Order",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sequenceWiseOrder",
      tableDataKey: "sequenceWiseOrder",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Outlet Edit",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletEdit",
      tableDataKey: "outletEdit",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Bill Number Sequence",
      controlType: "textfield",
      placeHolder: "Enter Bill Number Sequence",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billNoSequence",
      isMandatory: true,
    },
  ],

  fieldMetaForMultipleArea: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetails",
      dataKey: "stateIds",
      getListFrom: "region",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select State For Juridictional",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateIDsDetails",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "stateIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtIdsDetail",
      dataKey: "districtId",
      getListFrom: "zoneIds",
      getListId: "districtId",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "districtId",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "zoneIds,taluka",
      dataKey: "areaIds",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 6,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "Order To",
      controlType: "autocomplete",
      placeHolder: "Select Order ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "orderList",
      dataKey: "orderTo",
      getListFrom: "stockStakeHolderType,areaIds",
      isRootLevelKey: true,
      getListId: "orderTo",
      isMandatory: true,
    },

    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "contactPersonName",
      isMandatory: true,
    },

    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "contactPersonNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },

    {
      label: "Firm Address",
      controlType: "textfield",
      placeHolder: " Firm Address",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
      inputType: "numberText",
      maxLength: 100,
    },

    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: true,
      valid: true,
      validName: "email",
    },
    {
      label: "Bill Name",
      controlType: "textfield",
      placeHolder: "Enter Bill Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
    {
      label: "Credit Limit",
      controlType: "textfield",
      placeHolder: "Enter Credit Limit",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditLimit",
      isMandatory: true,
    },
    {
      label: "Payment term",
      controlType: "autocomplete",
      placeHolder: "Select Payment term",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentTermDetail",
      dataKey: "paymentTerm",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Aadhar Number",
      controlType: "textfield",
      placeHolder: "123456789111",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "adhaarNo",
      isMandatory: false,
      maxLength: 12,
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      inputType: "numberText",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
    },
    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "panNo",
      isMandatory: true,
      valid: true,
      validName: "panNo",
    },
    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "12216017000287",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
      maxLength: 14,
    },
    {
      label: "FSSAI Expiry Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expiryDate",
      isMandatory: true,
    },
    {
      label: "UPI",
      controlType: "textfield",
      placeHolder: "abcd@bank",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "upi",
      isMandatory: false,
      valid: true,
      validName: "upiNo",
    },
    {
      label: "Brand",
      controlType: "autocomplete",
      placeHolder: "Select Brand",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "brandDetail",
      dataKey: "brand",
      isRootLevelKey: false,
      getListFrom: "orderTo",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Sequence-Wise Outlet Order",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sequenceWiseOrder",
      tableDataKey: "sequenceWiseOrder",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Outlet Edit",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletEdit",
      tableDataKey: "outletEdit",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Bill Number Sequence",
      controlType: "textfield",
      placeHolder: "Enter Bill Number Sequence",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billNoSequence",
      isMandatory: true,
    },
  ],
  fieldMetaForActiveStatus: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "state",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "autocomplete",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "zoneIds,taluka",
      dataKey: "areaIds",
      isRootLevelKey: true,
      getListId: "areaIds",
      isMandatory: true,
    },
  ],
  fieldMetaForEdit: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Region ",
      controlType: "autocomplete",
      placeHolder: "Select Region ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "region",
    },
    {
      label: "State For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select State For Juridictional",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateIDsDetails",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetails",
      dataKey: "stateIds",
      getListFrom: "region",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      getListFrom: "stateIds",
      dataKey: "zoneIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtIdsDetail",
      dataKey: "districtId",
      getListFrom: "zoneIds",
      getListId: "districtId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "stockStakeHolderType,zoneIds,districtId",
      dataKey: "areaIds",
      isRootLevelKey: true,
      getListId: "areaIds",
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 6,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "Order To",
      controlType: "autocomplete",
      placeHolder: "Select Order ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "orderList",
      dataKey: "orderTo",
      getListFrom: "areaIds,stockStakeHolderType",
      isRootLevelKey: true,
      getListId: "orderTo",
      isMandatory: true,
    },

    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "contactPersonName",
      isMandatory: true,
    },

    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactPersonNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },

    {
      label: "Firm Address",
      controlType: "textfield",
      placeHolder: " Person Address",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
      inputType: "numberText",
      maxLength: 100,
    },

    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: true,
      valid: true,
      validName: "email",
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      inputType: "numberText",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
    },
    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: true,
      valid: true,
      validName: "panNo",
    },

    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
      maxLength: 14,
    },

    {
      label: "Bill Name",
      controlType: "textfield",
      placeHolder: "Enter Bill Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
    {
      label: "Credit Limit",
      controlType: "textfield",
      placeHolder: "Enter Credit Limit",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditLimit",
      isMandatory: true,
    },
    {
      label: "Payment term",
      controlType: "autocomplete",
      placeHolder: "Select Payment term",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentTermDetail",
      dataKey: "paymentTerm",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "UPI",
      controlType: "textfield",
      placeHolder: "abcd@bank",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "upi",
      isMandatory: false,
      valid: true,
      validName: "upiNo",
    },
    {
      label: "FSSAI Expiry Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expiryDate",
      isMandatory: true,
    },
    {
      label: "Brand",
      controlType: "autocomplete",
      placeHolder: "Select Brand",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "brandDetail",
      dataKey: "brand",
      getListFrom: "orderTo",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Sequence-Wise Outlet Order",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sequenceWiseOrder",
      tableDataKey: "sequenceWiseOrder",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Outlet Edit",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletEdit",
      tableDataKey: "outletEdit",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Bill Number Sequence",
      controlType: "textfield",
      placeHolder: "Enter Bill Number Sequence",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billNoSequence",
      isMandatory: true,
    },
  ],

  fieldMetaForEditMultipleArea: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Region ",
      controlType: "autocomplete",
      placeHolder: "Select Region ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "region",
    },
    {
      label: "State For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select State For Juridictional",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateIDsDetails",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetails",
      dataKey: "stateIds",
      getListFrom: "region",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "stateIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District For Juridictional",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtIdsDetail",
      dataKey: "districtId",
      getListFrom: "zoneIds",
      getListId: "districtId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "stockStakeHolderType,zoneIds,districtId",
      dataKey: "areaIds",
      isRootLevelKey: true,
      getListId: "areaIds",
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 6,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "Order To",
      controlType: "autocomplete",
      placeHolder: "Select Order ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "orderList",
      dataKey: "orderTo",
      getListFrom: "areaIds,stockStakeHolderType",
      isRootLevelKey: true,
      getListId: "orderTo",
      isMandatory: true,
    },

    {
      label: "Owner Name",
      controlType: "textfield",
      placeHolder: "Owner Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "ownerName",
      isMandatory: true,
    },
    {
      label: "Firm Name",
      controlType: "textfield",
      placeHolder: "Firm Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "firmName",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "contactPersonName",
      isMandatory: true,
    },

    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactPersonNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },

    {
      label: "Firm Address",
      controlType: "textfield",
      placeHolder: " Person Address",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
      inputType: "numberText",
      maxLength: 100,
    },

    {
      label: "Email Id ",
      controlType: "textfield",
      placeHolder: "Email Id ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      isMandatory: true,
      valid: true,
      validName: "email",
    },
    {
      label: "GST Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstType",
      tableDataKey: "gstType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Non Register",
          value: "1",
        },
        {
          label: "Composite ",
          value: "2",
        },
        {
          label: "Regular",
          value: "3",
        },
      ],
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "hideTextfieldNotEqual",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      tableDataKey: "gstType",
      openDataKey: "gstType",
      open: "1",
      valid: true,
      validName: "gstNo",
      inputType: "numberText",
      error: "GST Number",
      saveDataKey: true,
      setTextHide: true,
    },
    {
      label: "PAN Number ",
      controlType: "textfield",
      placeHolder: "ABCTY1234D",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      isMandatory: true,
      valid: true,
      validName: "panNo",
    },

    {
      label: "FSSAI Number",
      controlType: "textfield",
      placeHolder: "Enter FSSAI Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiFdiNo",
      isMandatory: false,
      maxLength: 14,
    },

    {
      label: "Bill Name",
      controlType: "textfield",
      placeHolder: "Enter Bill Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "billName",
      isMandatory: true,
    },
    {
      label: "Credit Limit",
      controlType: "textfield",
      placeHolder: "Enter Credit Limit",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditLimit",
      isMandatory: true,
    },
    {
      label: "Payment term",
      controlType: "autocomplete",
      placeHolder: "Select Payment term",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "paymentTermDetail",
      dataKey: "paymentTerm",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "UPI",
      controlType: "textfield",
      placeHolder: "abcd@bank",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "upi",
      isMandatory: false,
      valid: true,
      validName: "upiNo",
    },
    {
      label: "FSSAI Expiry Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expiryDate",
      isMandatory: true,
    },
    {
      label: "Brand",
      controlType: "autocomplete",
      placeHolder: "Select Brand",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "brandDetail",
      dataKey: "brand",
      getListFrom: "orderTo",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Sequence-Wise Outlet Order",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sequenceWiseOrder",
      tableDataKey: "sequenceWiseOrder",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Outlet Edit",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletEdit",
      tableDataKey: "outletEdit",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Bill Number Sequence",
      controlType: "textfield",
      placeHolder: "Enter Bill Number Sequence",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "billNoSequence",
      isMandatory: true,
    },
  ],
};
