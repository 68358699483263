import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    productCatelogue: [],
};
let URL = endpoints.productCatelogue;
const productCatelogueSlice = createSlice({
    name: "productCatelogue",
    initialState,
    reducers: {
        productCatelogueSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                productCatelogue: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    productCatelogueSuccess,
    resetState,
} = productCatelogueSlice.actions;

export default productCatelogueSlice.reducer;

export const getproductCatelogue = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((productCatObj, index) => {
                    let productCatData = {
                        index: index + 1,
                        id: productCatObj.id === null ? "" : productCatObj.id,

                        productName: productCatObj.productName !== null
                            ? productCatObj.productName
                            : "",

                        productRetailRate:
                            productCatObj.productRetailRate !== null
                                ? productCatObj.productRetailRate
                                : "",
                        productMrp:
                            productCatObj.productMrp === null ? "" : productCatObj.productMrp,

                        fromDate:
                            productCatObj.fromDate === null ? "" : productCatObj.fromDate,

                        toDate:
                            productCatObj.toDate === null ? "" : productCatObj.toDate,

                        trendingSequence:
                            productCatObj.trendingSequence === null ? "" : productCatObj.trendingSequence,

                        isLabelApplicable:
                            productCatObj.isLabelApplicable === null ? "" : productCatObj.isLabelApplicable,

                        labelName:
                            productCatObj.labelName === null ? "" : productCatObj.labelName,

                        launchDate:
                            productCatObj.launchDate === null ? "" : productCatObj.launchDate,

                        offerApplicable:
                            productCatObj.offerApplicable === null ? "" : productCatObj.offerApplicable,

                        product:
                            productCatObj.product === null ? "" : productCatObj.product,

                        productId:
                            productCatObj.productId !== null ? productCatObj.productId : "",
                        type: productCatObj.type === null ? "" : productCatObj.type,
                        typeName: productCatObj.type === null ? "" : productCatObj.type == 0 ? "Image" : "PDF",
                        isActive:
                            productCatObj.isActive === null ? "" : productCatObj.isActive,
                        imageMandatory:
                            productCatObj.imageMandatory === null ? "" : productCatObj.imageMandatory,
                    };
                    return productCatData;
                });
                dispatch(productCatelogueSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};










