import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { leaveSummaryColumns } from "../../../tableColumns/table-columns";
import { leaveSummaryJSON } from "../../../DynamicFormsJson/MastersJSON/leaveSummary";
import { getLeaveSummary } from "../../../Slice/LeaveType.slice";
class LeaveSummaryTable extends React.Component {
    render() {
        const { leaveSummaryList, getLeaveSummary } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={leaveSummaryJSON.formPath}
                    screenTitle={leaveSummaryJSON.screenTitle}
                    fieldMeta={leaveSummaryJSON.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    pdfFileName={leaveSummaryJSON.pdfFileName}
                    excelFileName={leaveSummaryJSON.excelFileName}
                    showAddButton={leaveSummaryJSON.showAddButton}
                    tableColumnsToFilter={leaveSummaryJSON.tableColumnsToFilter}
                    tableColumns={leaveSummaryColumns}
                    tableData={leaveSummaryList?.leaveSummary}
                    getTableData={getLeaveSummary}
                    apiBaseURL={leaveSummaryJSON.apiBaseURL}
                    isNote={true}
                    showDeleteIcon={true}
                    isNoteValue={"Add Leave Summary"}
                />

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    leaveSummaryList: state.leaveType,
});
const mapDispatchToProps = {
    getLeaveSummary,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveSummaryTable);
