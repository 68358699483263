import { Grid, Paper, Switch, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import {
  FontAwesomeIconCompo, MyComponentWithIconProps
} from "../Comman/IconButton";
import { ButtonCompo } from "./Button";
import RTSelectMultiple from "./RT/RTSelectMultiple";
import { withRouter } from "../withRouter";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

class LandingScreenHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      fieldDataForModule: {},
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role) {
      this.setState({
        role: role,
      });
    }

    const moduleObjData = localStorage.getItem("moduleObj");
    this.setState({
      fieldDataForModule:
        moduleObjData != null ? JSON.parse(moduleObjData) : {},
    });
  }
  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };


  render() {
    const {
      screenTitle,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      showTable,
      showSearchBox = true,
      showSearch = true,
      showSwitchButton = false,
      handleChange,
      checked,
      title,
      addTitle = "",
      showBackButton = false,
      fullWidthButton = false,
      onCancel,
      onOpenFilter,
      showFilterIcon = false,
      showFilter = false
    } = this.props;
    const { fieldDataForModule } = this.state;
    return (
      <>
        <div>
          {screenTitle !== "" && (
            <Paper sx={{ borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                <TitleAndBreadCrum title={screenTitle} />
                {
                  !showAddButton && !showBackButton && showFilterIcon && <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-end"
                    >
                      <ButtonCompo
                        sx={{ color: 'white', backgroundColor: '#0f3f6f' }}
                        size="medium"
                        type="button"
                        variant="contained"
                        name={"Filter"}
                        onClick={onOpenFilter}
                        fullWidth={fullWidthButton}
                      />
                      &nbsp;&nbsp;</Grid>
                  </>

                }
                {
                  showAddButton &&

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="flex-end"
                    sx={{ marginTop: 5 }}
                  >
                    {/* <MyComponentWithIconProps
                                                        statusImage={FilterAltIcon}
                                                        color="secondary"
                                                        fontSize="medium"
                                                        title="Filter"
                                                        onClick={() => {
                                                            this.onClickFilter(tableInfoData)
                                                        }}
                                                    /> */}
                    {
                      showFilterIcon && <>
                        <ButtonCompo
                          sx={{ color: 'white', backgroundColor: '#0f3f6f' }}
                          size="medium"
                          type="button"
                          variant="contained"
                          name={"Filter"}
                          onClick={onOpenFilter}
                          fullWidth={fullWidthButton}
                        />
                        &nbsp;&nbsp;
                      </>
                    }

                    <ButtonCompo
                      sx={{ color: 'white', backgroundColor: '#0f3f6f' }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={addTitle === "" ? "Add " + screenTitle : "Add " + addTitle}
                      onClick={this.onAddButtonClick}
                      fullWidth={fullWidthButton}
                    />
                  </Grid>
                }
                {
                  showBackButton &&

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      size="small"
                      type="button"
                      variant="outlined"
                      name=" Back to List"
                      onClick={onCancel}
                    />
                  </Grid>
                }
              </Grid>
            </Paper>
          )}

          {showTable && (
            <>
              {showSearch && (
                <>
                  {screenTitle !== "" && <br></br>}
                  <Paper sx={{ borderRadius: 2 }} elevation={0}>
                    <Grid container spacing={3}>
                      <Grid
                        container
                        sx={{ ml: -2 }}
                        item
                        xs={12}
                        md={4}
                        lg={3}
                      >
                        {showSearchBox && (
                          <Search
                            sx={{ width: "100%" }}
                            searchValue={searchValue}
                            filteredRows={this.onSearch}
                          />
                        )}
                      </Grid>

                      <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        md={8}
                        lg={9}
                      >
                        <Grid container justifyContent="flex-end" item md xs>
                          {showSwitchButton && (
                            <div style={{ padding: "10px" }}>
                              <Tooltip placement="bottom" title={title}>
                                <Switch
                                  checked={checked}
                                  onChange={handleChange}
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{ m: -1 }}
                                />
                              </Tooltip>
                            </div>
                          )}
                          {/* {showAddButton && this.state.role !== "parent" && (
                            <div style={{ padding: "10px" }}>
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                sx={{ m: -1 }}
                                color="primary"
                                title="Add"
                                fontSize="large"
                                onClick={this.onAddButtonClick}
                              />
                            </div>
                          )} */}
                          {showFilter && (
                            <div style={{ padding: "10px" }}>
                              {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={pdfFileName}
                        type="pdf"
                      /> */}

                              <FontAwesomeIconCompo
                                color="primary"
                                fontSize="small"
                                title="Filter"
                                baseClassName="fas"
                                className="fa-sharp fa-filter"
                                onClick={onOpenFilter}
                              />
                            </div>
                          )}
                          {showPdfDownload && fieldDataForModule.editReject == 1 && (
                            <div style={{ padding: "10px" }}>
                              <FontAwesomeIconCompo
                                color="error"
                                fontSize="small"
                                title="PDF"
                                baseClassName="fas"
                                className="fa-file-pdf"
                                onClick={generatePDF}
                              />
                            </div>
                          )}
                          {showExcelDownload && fieldDataForModule.editReject == 1 && (
                            <div style={{ padding: "10px" }}>
                              <FontAwesomeIconCompo
                                color="secondary"
                                fontSize="small"
                                title="Excel"
                                baseClassName="fas"
                                className="fa-file-excel"
                                onClick={generateExcel}
                              />
                            </div>
                          )}
                        </Grid>
                        {tableColumnsToFilter?.length > 0 && (
                          <Grid
                            item
                            justifyContent="flex-end"
                            xs={6}
                            md={6}
                            lg={3}
                          >
                            <RTSelectMultiple
                              options={tableColumnsToFilter}
                              onChange={onRTSelectMultipleChange}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default LandingScreenHeader;