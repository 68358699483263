import endpoint from "../../config/endpoints";

export const ManualOrderJson = {
  // formPath: "/region-and-target-form",
  apiBaseURL: endpoint.area,
  screenTitle: "Manual Order",
  showSaveBtn: false,
  showBackToList: false,

  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "area",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Sales Person",
      controlType: "autocomplete",
      placeHolder: "Select Person",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "assignedSalesTeam",
      getListFrom: "beat",
      getListId: "assignedSalesTeam",
      masterName: "assignedSalesTeamDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "beat",
      getListId: "beat,outletIds",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Discount %",
      controlType: "hideTextfield",
      placeHolder: "Discount %",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "DiscountPercentage",
      tableDataKey: "flag",
      open: "1",
      showLabel: true,
      isMandatory: false,
      error: "Discount %",
      isMandotoryCheck: false,
    },
  ],

  fieldMetaWithoutSalesPersonDropDown: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "area",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "beat",
      getListId: "beat,outletIds",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Discount %",
      controlType: "hideTextfield",
      placeHolder: "Discount %",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "DiscountPercentage",
      tableDataKey: "flag",
      open: "1",
      showLabel: true,
      isMandatory: false,
      error: "Discount %",
      isMandotoryCheck: false,
    },
  ],
};