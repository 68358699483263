import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Grid, Paper, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { placedOrderJson } from "../../DynamicFormsJson/Transaction/placedOrder";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import endpoint from "../../config/endpoints";
import { DarkBlue, redColor, titleColor } from "../../config/ColorObj";
import {
  atleastOne,
  creditLimitMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  saveWarningPlacedOrderMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import ManualOrderTable from "./PlacedOrderTable";
import SubmitOrder from "./SubmitOrder";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { LabelCompo } from "../../components/Comman/Label";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class PlacedOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      searchValue: "",
      dynamicMasterData: {
        orderDetail: this.props.stackHolderList.stackholder,
      },
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 0,
      open: false,
      orderList: [],
      qty: 0,
      flag: false,
      creditLimit: 0,
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    let dataTotal = 0;
    let qtyTotal = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          qtyTotal = qtyTotal + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
      qty: qtyTotal,
    });
    setCategoryAndItem({ row });
  };
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {


      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      this.setState({
        companyFlag: showCompanyFlag,
      });

      if (showCompanyFlag == 1) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.sapCreditLimit,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: creditLimitMsg,
              severity: "error",
            });
          } else {
            if (Object.keys(data.data).length !== 0) {
              if (data.error) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.setState({
                  creditLimit: data.data.balance * -1,
                });
              }
            }
          }
        });
      }


      showLoador({ loador: true });
      await apiGet({
        url: endpoint.stackholder + "/get-to-order",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success && data.data != null) {
          const rowData = data.data;
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order To",
                value: rowData.name !== null ? rowData.name : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Date",
                value: dayjs().format("DD-MM-YYYY"),
              },
            ],
          });
        } else {
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order To",
                value: " - ",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "order Date",
                value: dayjs().format("DD-MM-YYYY"),
              },
            ],
          });
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      const stockCalAtBill = localStorage.getItem("stockCalAtBill");

      this.setState({
        flag: stockCalAtBill === "1" ? true : false,
      });


      showLoador({ loador: true });
      await apiGet({
        url: endpoint.categoryLatest + "/and-items-with-margin-and-mrp-for-stake-holder",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success && data.data != null) {
          const rowData = data.data;
          if (rowData.errorMessage.error) {
            showNotification({
              msg: rowData.errorMessage.message,
              severity: "error",
            });
          } else {
            const row = rowData.categoryAndItems.map((rowObj) => {
              return {
                ...rowObj,
                total: 0,
                qty: 0,
                productWithMarginAndMrps: rowObj.productWithMarginAndMrps.map(
                  (product) => {
                    return {
                      ...product,
                      uomConversionValue: product.products.uomConversionValue,
                      qty: "",
                      freeQty: 0,
                      itemDiscPer: 0,
                      productStock: (
                        product.productStockForMobile.secondaryClosingQty /
                        product.products.uomConversionValue
                      ).toFixed(2),
                      total: 0,
                      stockError: 0,
                    };
                  }
                ),
              };
            });
            this.setCatList({ row });
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.stackholder !==
        nextProps.stackHolderList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderDetail: nextProps.stackHolderList?.stackholder,
          },
        });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
              product: rowData.products !== null ? rowData.products : "",
              subCategory:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.id
                  ? rowData.products.group.subCategory.id
                  : "",
              category:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.id
                  ? rowData.products.group.subCategory.category.id
                  : "",
              categoryName:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.name
                  ? rowData.products.group.subCategory.category.name
                  : "",
              categoryShortName:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.shortName
                  ? rowData.products.group.subCategory.category.shortName
                  : "",
              categorySortOrder:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.sortOrder
                  ? rowData.products.group.subCategory.category.sortOrder
                  : "",
              isActive:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.isActive
                  ? rowData.products.group.subCategory.category.isActive
                  : "",
              insertDateTime:
                rowData.products !== null &&
                  rowData.products.group !== null &&
                  rowData.products.group.subCategory !== null &&
                  rowData.products.group.subCategory.category !== null &&
                  rowData.products.group.subCategory.category.insertDateTime
                  ? rowData.products.group.subCategory.category.insertDateTime
                  : "",
              taxPercentage:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.id
                  ? rowData.products.taxPercentage.id
                  : "",
              taxName:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.name
                  ? rowData.products.taxPercentage.name
                  : "",
              taxSgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.sgst
                  ? rowData.products.taxPercentage.sgst
                  : "",
              taxCgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.cgst
                  ? rowData.products.taxPercentage.cgst
                  : "",
              taxIgst:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.igst
                  ? rowData.products.taxPercentage.igst
                  : "",
              taxCess:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.cess
                  ? rowData.products.taxPercentage.cess
                  : "",
              hsnCode:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.hsnCode
                  ? rowData.products.taxPercentage.hsnCode
                  : "",
              taxInsertDateTime:
                rowData.products !== null &&
                  rowData.products.taxPercentage !== null &&
                  rowData.products.taxPercentage.insertDateTime
                  ? rowData.products.taxPercentage.insertDateTime
                  : "",
              stakeHolderUom:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.id
                  : "",
              stakeHolderUom:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.id
                  ? rowData.products.stakeHolderUom.id
                  : "",
              productName:
                rowData.products !== null && rowData.products.name
                  ? rowData.products.name
                  : "",
              outletName:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.name
                  ? rowData.products.stakeHolderUom.name
                  : "",
              outletisActive:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.isActive
                  ? rowData.products.stakeHolderUom.isActive
                  : "",
              outletInsertDateTime:
                rowData.products !== null &&
                  rowData.products.stakeHolderUom !== null &&
                  rowData.products.stakeHolderUom.insertDateTime
                  ? rowData.products.stakeHolderUom.insertDateTime
                  : "",
              outletUom:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.id
                  ? rowData.products.outletUom.id
                  : "",
              outletUomName:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.name
                  ? rowData.products.outletUom.name
                  : "",
              outletUomIsActive:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.isActive
                  ? rowData.products.outletUom.isActive
                  : "",
              outletUomInsertDateTime:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.insertDateTime
                  ? rowData.products.outletUom.insertDateTime
                  : "",
              uomConversionValue:
                rowData.products !== null && rowData.products.uomConversionValue
                  ? rowData.products.uomConversionValue
                  : 0,
              productCode:
                rowData.products !== null && rowData.products.code
                  ? rowData.products.code
                  : "",

              shortName:
                rowData.products !== null && rowData.products.shortName
                  ? rowData.products.shortName
                  : "",
              weight:
                rowData.products !== null && rowData.products.weight
                  ? rowData.products.weight
                  : "",
              description:
                rowData.products !== null && rowData.products.description
                  ? rowData.products.description
                  : "",
              selfLife:
                rowData.products !== null && rowData.products.selfLife
                  ? rowData.products.selfLife
                  : "",
              image:
                rowData.products !== null && rowData.products.image
                  ? rowData.products.image
                  : "",
              sortOrder:
                rowData.products !== null && rowData.products.sortOrder
                  ? rowData.products.sortOrder
                  : "",
              isActive:
                rowData.products !== null && rowData.products.isActive
                  ? rowData.products.isActive
                  : "",
              insertDateTime:
                rowData.products !== null && rowData.products.insertDateTime
                  ? rowData.products.insertDateTime
                  : "",
              mrp:
                rowData.products !== null && rowData.products.mrp
                  ? rowData.products.mrp
                  : "",
              marginPercentage:
                rowData.marginPercentage !== null
                  ? (+rowData.marginPercentage).toFixed(2)
                  : 0,
              mrp: rowData.mrp !== null ? (+rowData.mrp).toFixed(2) : 0,

              qty: rowData.qty !== null ? rowData.qty : 0,
              rate: rowData.rate !== null ? (+rowData.rate).toFixed(2) : 0,
              total: rowData.total.toFixed(2),
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: placedOrderJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: placedOrderJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, fieldData, comment, total, creditLimit } = this.state;
    const outletBillDetails = orderList.map((orderData) => {
      return {
        product: orderData.product,
        qty: orderData.qty,
      };
    });
    const dataToSave = {
      orderDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      stockStakeHolderOrderDetails: outletBillDetails,
      remark: comment,
    };

    if (+total > +creditLimit) {
      swal({
        title: "Are you sure?",
        text: saveWarningPlacedOrderMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    } else {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderOrderHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/place-order");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });

          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    placedOrderJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let stakeHolderUom =
        currentRow.products.stakeHolderUom != null
          ? currentRow.products.stakeHolderUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }
      if (qty && qty.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      this.setState({ comment: value });
    }
  };

  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      qty,
      comment,
      flag,
      creditLimit,
    } = this.state;
    const { category } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            showBackToList={true}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={placedOrderJson.showTitle}
            screenTitle={placedOrderJson.screenTitle}
            fieldMeta={placedOrderJson.fieldMeta}
            apiBaseURL={placedOrderJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={placedOrderJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            DataToSet={DataToSet}
          />

          {category.category.length != 0 && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <ManualOrderTable
                      total={total}
                      qty={qty}
                      tableHead={
                        flag
                          ? [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: true,
                              showInscreen: true,
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },

                            {
                              title: "Product Stock",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ]
                          : [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: true,
                              showInscreen: true,
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },

                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ]
                      }
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      // data={catData.productWithMarginAndMrps}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={flag}
                    />
                  </TabPanel>
                );
              })}
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Credit Limit",
                    value: creditLimit,
                  },

                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Amount",
                    value: total.toFixed(2),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Quantity",
                    value: qty,
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Balance",
                    value: creditLimit - +total.toFixed(2),
                  },
                ]}
              />
              <Grid container alignItems="center">
                <Grid item xs={12} md={2.5} sm={2.5}>
                  {/* <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  /> */}
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                    label=" Add Comment :"
                  />
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5}>
                  <TextFieldCompo
                    placeholder="Add Comment"
                    color="primary"
                    name="comment"
                    size="small"
                    value={comment}
                    fullWidth
                    onChange={this.changeHandler}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        {open && (
          <SubmitOrder
            formData={{
              total: total,
              creditLimit: creditLimit,
              qty: qty,
              discount: fieldData.DiscountPercentage
                ? fieldData.DiscountPercentage
                : 0,
              totalAmt: totalAmt,
            }}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
              {
                title: "UOM",
                name: "outletName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
                canSearch: true,
              },

              {
                title: "Quantity",
                name: "qty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },

              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
            ]}
            open={open}
            handleCloses={this.toggle}
            rows={orderList}
            getOrder={this.getOrder}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlacedOrder);
