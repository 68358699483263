import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { stackHolderJson } from "../../../DynamicFormsJson/MastersJSON/StackHolder";
import {
  getAreaByStockStakeHolderTypeZoneAndTaluka,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
} from "../../../Slice/StackHolder.slice";
import { getPaymentTerm } from "../../../Slice/paymentTerm.Slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  savemsg,
  saveFailedMsg,
  saveWarningMsg,
} from "../../../config/messageconstant";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { getOrderToListPost } from "../../../Slice/StackHolder.slice";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../../utils/api_service";
import { getBrand } from "../../../Slice/brand.slice";
import { filter } from "dom-helpers";
import { rowDataBound } from "@syncfusion/ej2-grids";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper } from "@mui/material";
import endpoint from "../../../config/endpoints";
class StackHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockStakeHolder: "",
      stockStakeHolderName: "",
      dynamicMasterData: {
        stateDetails: this.props.stateList?.stateByRegion,
        stateIDsDetails: this.props.stateList?.stateByRegion,
        regionDetail: this.props.regionList?.region,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        districtIdsDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.stackHolderList?.areaByBeatTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        orderList: this.props.stackHolderList?.orderTo,
        brandDetail: this.props.brandList?.brand,
        paymentTermDetail: this.props.paymentTermList?.paymentTerm,
        zoneIds: "",
        transporterFlag: 0,
        stockholderSavedId: "",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.brandList !== nextProps.brandList) {
      if (this.props.brandList?.brand !== nextProps.brandList?.brand) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            brandDetail: nextProps.brandList?.brand,
          },
        });
      }
    }

    if (this.props.paymentTermList !== nextProps.paymentTermList) {
      if (this.props.paymentTermList?.paymentTerm !== nextProps.paymentTermList?.paymentTerm) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            paymentTermDetail: nextProps.paymentTermList?.paymentTerm,
          },
        });
      }
    }

    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetails: nextProps.stateList?.stateByRegion,
            stateIDsDetails: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
            districtIdsDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.orderToAdmin !==
        nextProps.stackHolderList?.orderToAdmin ||
        this.props.stackHolderList?.orderTo !==
        nextProps.stackHolderList?.orderTo
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderList:
              nextProps.stackHolderList?.orderToAdmin == "1"
                ? [
                  { id: "0", name: "Admin" },
                  ...nextProps.stackHolderList?.orderTo,
                ]
                : nextProps.stackHolderList?.orderTo,
          },
        });
      }

      if (
        this.props.stackHolderList?.areaByBeatTaluka !==
        nextProps.stackHolderList?.areaByBeatTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.stackHolderList?.areaByBeatTaluka,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, getPaymentTerm, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      showLoador({ loador: true });
      getPaymentTerm().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.stackHolderList?.orderToAdmin) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderList:
              this.props.stackHolderList?.orderToAdmin == "1"
                ? [
                  { id: "0", name: "Admin" },
                  ...this.props.stackHolderList?.orderTo,
                ]
                : this.props.stackHolderList?.orderTo,
          },
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    const {
      stockStakeHolder,
      stockStakeHolderName,
      zoneIds,
      dynamicMasterData,
    } = this.state;
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByStockStakeHolderTypeZoneAndTaluka,
      getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
      showLoador,
      getOrderToListPost,
      getBrand,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.zoneIds) {
        this.setState({
          zoneIds: data.zoneIds,
        });
      }

      if (data.region && data.region.id && data.region.id != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.stateIds != "" && data.stateIds) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.zoneIds != "" && data.zoneIds) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.districtId && data.districtId != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.districtId }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }

      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        const filterData = dynamicMasterData.stakeHolderTypeDetail.filter(
          (row) => row.id == data.stockStakeHolderType.id
        );
        this.setState({
          stockStakeHolder: data.stockStakeHolderType.id,
          stockStakeHolderName:
            filterData.length != 0 ? filterData[0].name.toLowerCase() : "",
        });
      }

      if (this.props.params.id) {
        showLoador({ loador: true });
        await getAreaByStockStakeHolderTypeIdAndZoneIdsEdit({
          saleExcId: this.props.params.id,
          StockStakeHolderTypeId: stockStakeHolder,
          zoneIds: zoneIds,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        if (data.taluka && data.taluka != "") {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeZoneAndTaluka({
            StockStakeHolderTypeId: stockStakeHolder,
            zoneIds: zoneIds,
            talukaIds: data.taluka,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      }

      if (data.areaIds && data.areaIds != "") {

        let dataToSend = {
          stockStakeHolderTypeId: stockStakeHolder,
          areaIds: stockStakeHolderName === "distributor" ? data.areaIds.split(",") : data.areaIds,
        }

        showLoador({ loador: true });
        await getOrderToListPost({ jsonData: dataToSend }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.orderTo) {
        showLoador({ loador: true });
        await getBrand().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  callBeforeSaveMethod = async (dataToSave, editData) => {
    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.billName != dataToSave.billName)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            stackHolderJson.apiBaseURL +
            "/bill-name-exist-or-not?billName=" +
            dataToSave.billName,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };
  // onSaveTransporter = (dataToSave) => {
  //   const { showLoador, showNotification } = this.props;
  //   if (navigator.onLine) {
  //     swal({
  //       title: "Are you sure?",
  //       text: saveWarningMsg,
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         if (dataToSave.billName) {
  //           showLoador({ loador: true });
  //           const response = apiGet({
  //             url:
  //               stackHolderJson.apiBaseURL +
  //               "/bill-name-exist-or-not?billName=" +
  //               dataToSave.billName,
  //           }).then(({ data, success }) => {
  //             showLoador({ loador: false });
  //             if (success) {
  //               if (!data.data.error) {
  //                 showNotification({
  //                   msg: data.data.message,
  //                   severity: "error",
  //                 });
  //               }
  //               return data.data.error;
  //             }
  //             return false;
  //           });
  //           showLoador({ loador: true });
  //           apiPost({
  //             url: endpoint.stackholder,
  //             postBody: dataToSave,
  //           }).then(({ data, success }) => {
  //             showLoador({ loador: false });
  //             if (!success) {
  //               showNotification({ msg: serverMsg, severity: "error" });
  //             } else {
  //               this.transpoterHandler(data.data.id);
  //             }
  //           });
  //           return response;
  //           console.log(response);
  //         } else {
  //           showNotification({ msg: serverMsg, severity: "error" });
  //         }
  //       }
  //     });
  //   } else {
  //     this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  //   }
  // };
  // transpoterHandler = (stakeholderId) => {
  //   const { showLoador, showNotification } = this.props;
  //   showLoador({ loador: true });
  //   apiGet({
  //     url: endpoint.setting + "/by-key?key=transporter_inserted",
  //   }).then(({ data, success }) => {
  //     showLoador({ loador: false });
  //     if (!success) {
  //       showNotification({ msg: serverMsg, severity: "error" });
  //     } else {
  //       if (data !== null && Object.keys(data.data).length !== 0) {
  //         this.setState({
  //           transporterFlag: data.data.settingValue,
  //         });
  //       }
  //       if (data.data.settingValue == 0) {
  //         showLoador({ loador: true });
  //         apiGet({
  //           url:
  //             endpoint.transporter +
  //             "/save-from-satock-stake-holder?stockStakeHolderId=" +
  //             stakeholderId,
  //         }).then(({ data, success }) => {
  //           showLoador({ loador: false });
  //           if (!success) {
  //             showNotification({ msg: serverMsg, severity: "error" });
  //           } else {
  //             showNotification({ msg: savemsg });
  //             this.props.navigate(-1);
  //           }
  //         });
  //       } else {
  //         this.props.navigate(-1);
  //       }
  //     }
  //   });
  // };

  onSaveTransporter = async (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      console.log(dataToSave);
      const willDelete = await swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (willDelete) {
        if (dataToSave.billName) {
          showLoador({ loador: true });
          const { data, success } = await apiGet({
            url:
              stackHolderJson.apiBaseURL +
              "/bill-name-exist-or-not?billName=" +
              dataToSave.billName,
          });
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({ msg: data.data.message, severity: "error" });
              return data.data.error;
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
            return;
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
          return;
        }
        showLoador({ loador: true });
        const { data, success } = await apiPost({
          url: endpoint.stackholder,
          postBody: dataToSave,
        });
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.transporterHandler(data.data.id);
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  transporterHandler = async (stakeholderId) => {
    const { showLoador, showNotification } = this.props;

    const transporterInserted = localStorage.getItem("transporterInserted");

    this.setState({
      transporterFlag: transporterInserted,
    });

    if (transporterInserted == 0) {
      showLoador({ loador: true });
      const { data, success } = await apiGet({
        url:
          endpoint.transporter +
          "/save-from-satock-stake-holder?stockStakeHolderId=" +
          stakeholderId,
      });
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showNotification({ msg: savemsg });
        this.props.navigate(-1);
      }
    } else {
      this.props.navigate(-1);
    }

  };

  render() {
    const { dynamicMasterData, stockStakeHolderName } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            callBeforeSave={true}
            callBeforeSaveMethod={this.callBeforeSaveMethod}
            formPath={stackHolderJson.formPath}
            showPdfDownload={false}
            showExcelDownload={false}
            showBackToList={true}
            getListById={this.getListById}
            screenTitle={stackHolderJson.screenTitle}
            fieldMeta={
              stockStakeHolderName === "distributor"
                ? stackHolderJson.fieldMeta
                : stackHolderJson.fieldMetaForMultipleArea
            }
            dynamicMasterData={dynamicMasterData}
            apiBaseURL={stackHolderJson.apiBaseURL}
            showSaveNextBtn={stackHolderJson.showSaveNextBtn}
            isEditURL={stackHolderJson.isEditURL}
            showSaveBtnMain={false}
            showSaveBtn={true}
            onSave={this.onSaveTransporter}
            showCancel={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stackHolderList: state.stockholder,
  stakeHolderTypeList: state.stockStakeHolderType,
  orderToList: state.orderTo,
  brandList: state.brand,
  paymentTermList: state.paymentTerm,
});
const mapDispatchToProps = {
  getAreaByStockStakeHolderTypeZoneAndTaluka,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
  getStockStakeHolderType,
  getRegionNew,
  getOrderToListPost,
  getStateByRegion,
  getPaymentTerm,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  showLoador,
  showNotification,
  getBrand,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StackHolder);
